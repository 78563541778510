import React, { useEffect, useState } from "react";
import Input from "../Input";
import FillInput from "../FillOption";
import FillSelect from "../FillSelect";
import { useDispatch, useSelector } from "react-redux";
import { setFields } from "../../store/builder/reducer";
import FillPhoto from "../FillPhoto";
import {toast} from "react-toastify";
import Tooltip from "../Tooltip";
import FillDryRating from "../FillDryRating";
import FillDate from "../FillDate";

export default function FIllField({
  item,
  readOnly = false,
  setSendData,
  sendData,
  sheet,
  inspection_id,
  currentItem,
  setCurrentItem
}) {
  const dispatch = useDispatch();
  const [checkManual, setCheckManual] = useState(false);
  const [radioOptions, setRadioOptions] = useState([]);
  const fields = useSelector((state) => state.rootReducer.reducer.fields);
  const [showOther, setShowOther] = useState(false);
  const [fsiiPercentage, setFsiiPercentage] = useState(null)
  const flowRateGlobal = fields?.filter((item) => item?.data_type === "flow_rate")[0];

  useEffect(() => {
    if (item?.type === "radio") {
      let data = [];
      for (let i = 0; i < item?.options.length; i++) {
        data.push({ ...item?.options[i], checked: false });
      }
      setRadioOptions(data);
    }
  }, []);

  useEffect(() => {
    if (item?.type === "sump_sample_optional") {
      if (
        item?.answers.length > 0 &&
        item?.answers[0] !== "1A" &&
        item?.answers[0] !== "N/A" &&
        item?.answers[0] !== "N/U"
      ) {
        setShowOther(true);
      } else {
        setShowOther(false);
      }
    }
  }, [item.type]);

  const [fullResult, setFullResult] = useState('0');
  const formCorrectedDP = (
    observed,
    actualFlowRate,
    flowRate,
    corrected_dp
  ) => {
    const s = (Number(actualFlowRate) / Number(flowRate)) * 100;
    const res = Number(observed) * (2.237 * 2.718 ** (-0.00896 * s) + 9.638 * 2.718 ** (-0.04719 * s));
    setFullResult(String(Math.round(res * 10) / 10));
  };

  const checkFields = () => {
    const observed = fields?.filter((item) => item?.data_type === "observed_dp")[0];
    const actualFlowRate = fields?.filter((item) => item?.data_type === "actual_flow_rate")[0];
    const flowRate = fields?.filter((item) => item?.data_type === "flow_rate")[0];
    const correctedDp = fields?.filter((item) => item?.data_type === "corrected_dp")[0];
    if  (observed?.answers[0] && actualFlowRate?.answers[0] && flowRate?.answers[0]) {
      formCorrectedDP(
          observed?.answers[0],
          actualFlowRate?.answers[0],
          flowRate?.answers[0],
          correctedDp
      )
    } if (!observed?.answers[0]) {
      toast.error('Observed DP is required for calculation')
    } if (!actualFlowRate?.answers[0]) {
      toast.error('Actual Flow Rate is required for calculation')
    } if (!flowRate?.answers[0]) {
      toast.error('Flow Rate is required for calculation, change flow rate value in Equipment')
    }
  };

  useEffect(() => {
    if (item?.data_type === "corrected_dp") {
      dispatch(
        setFields({
          id: fields?.filter((item) => item?.data_type === "corrected_dp")[0]
            ?.id,
          answers: [fullResult ? fullResult + "" : "0"],
          has_comment: false,
          comment_text: "",
          comment_files: fields?.filter(
            (item) => item?.data_type === "corrected_dp"
          )[0]?.comment_files,
        })
      );
    }
  }, [fullResult]);

  return (
    <>
      {
        item?.data_type === "fsii_percentage" ||
        item?.data_type === "fsii_condition" ||
        item?.data_type === "dp_record" ||
        item?.data_type === "method_corrected_dp" ||
        item?.data_type === "observed_dp"  ||
        item?.data_type === "actual_flow_rate" ||
        item?.data_type === "corrected_dp" ?
          fields?.filter((item) => item?.data_type === "fsii_available" || item?.data_type === "dp_available" || item?.data_type === "corrected_dp_available")[0]?.answers[0] === 'Y' ?
            <div className="fillInspection__field">
              {(item?.data_type === "corrected_dp" && flowRateGlobal?.answers[0]) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: 10,
                  }}
                >
                  <p className="fillInspection__field-name">
                    {item?.label}
                  </p>
                  <div
                    onClick={() => {
                      checkFields();
                    }}
                    className="header__btn"
                    style={{
                      fontSize: 14,
                      padding: 10,
                      paddingTop: 5,
                      paddingBottom: 5,
                      margin: 0,
                    }}
                  >
                    Calculate
                  </div>
                </div>
              ) : (
                <p className="fillInspection__field-name">{item?.label}
                  {item?.data_type === "fsii_percentage" && <Tooltip tooltipText={'Acceptable FSII % is 0.10-0.15%'} />}
                </p>
              )}
              <div className="fillInspection__field-options">
                <div className="fillInspection__field-option">
                  {item?.type === "radio" &&
                    item?.options?.map((option, index) => {
                      return (
                        <FillInput
                          readOnly={readOnly}
                          radioOptions={radioOptions}
                          setRadioOptions={setRadioOptions}
                          option={option}
                          key={index}
                          type={"radio"}
                          item={item}
                          disabled={currentItem?.is_completed}
                          index={index}
                          sheet={sheet}
                          inspection_id={inspection_id}
                        />
                      );
                    })}
                  {item?.type === "photo" && (
                    <FillPhoto
                      readOnly={readOnly}
                      disabled={currentItem?.is_completed}
                      item={item}
                      sheet={sheet}
                      inspection_id={inspection_id}
                      category_id={currentItem?.id}
                    />
                  )}
                  {item?.type === "date" && (
                    <FillDate
                      readOnly={readOnly}
                      disabled={currentItem?.is_completed}
                      item={item}
                      sheet={sheet}
                      inspection_id={inspection_id}
                      category_id={currentItem?.id}
                    />
                  )}
                  {item?.type === "checkbox" &&
                    item?.options?.map((option, index) => (
                      <FillInput
                        readOnly={readOnly}
                        disabled={currentItem?.is_completed}
                        radioOptions={[]}
                        setRadioOptions={setRadioOptions}
                        option={option}
                        key={index}
                        type={"checkbox"}
                        item={item}
                        index={index}
                        sheet={sheet}
                        inspection_id={inspection_id}
                      />
                    ))}
                  {item?.type === "select" && (
                    <FillSelect
                      item={item}
                      options={item?.options}
                      sheet={sheet}
                      readOnly={readOnly}
                      disabled={currentItem?.is_completed}
                    />
                  )}
                  {item?.type === "numeric" && item?.data_type === "corrected_dp" ? (
                    <Input
                      type={"number"}
                      defaultValue={item?.answers[0]}
                      disabled={sheet || currentItem?.is_completed}
                      value={Number(fullResult)}
                      containerWidth={"100%"}
                      // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                      onChange={(e) => {
                        let inputData = {
                          id: item.id,
                          answers: [`${e.target.value}`],
                          has_comment: false,
                          comment_text: "",
                          comment_files: item.comment_files,
                        };
                        dispatch(setFields(inputData));
                      }}
                      data_type={item?.data_type}
                    />
                  ) : item?.type === "numeric" ? (
                    <Input
                      type={"number"}
                      defaultValue={item?.answers[0]}
                      disabled={sheet || currentItem?.is_completed}
                      containerWidth={"100%"}
                      // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                      onChange={(e) => {
                        setFsiiPercentage(e.target.value)
                        let inputData = {
                          id: item.id,
                          answers: [`${e.target.value}`],
                          has_comment: false,
                          comment_text: "",
                          comment_files: item.comment_files,
                        };
                        dispatch(setFields(inputData));
                      }}
                      data_type={item?.data_type}
                    />
                  ) : (
                    <></>
                  )}
                  {item?.type === "sump_sample_optional" && (
                    <div className="sump__op">
                      <div className="sump__option">
                        <label className="fillInspection__field-radio">
                          1A - Clean Bright
                          <input
                            type="radio"
                            value="1A"
                            name={item?.id}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0] === "1A"
                                ? true
                                : false
                            }
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample_optional",
                              };
                              dispatch(setFields(inputData));
                              setShowOther(false);
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          Other
                          <input
                            type="radio"
                            value=""
                            name={item?.id}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 &&
                              item?.answers[0] !== "1A" &&
                              item?.answers[0] !== "N/A" &&
                              item?.answers[0] !== "N/U"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: item?.answers,
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample_optional",
                              };
                              dispatch(setFields(inputData));
                              setShowOther(true);
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          Not Applicable
                          <input
                            type="radio"
                            value="N/A"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0] === "N/A"
                                ? true
                                : false
                            }
                            name={item?.id}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample_optional",
                              };
                              dispatch(setFields(inputData));
                              setShowOther(false);
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          Not Used
                          <input
                              type="radio"
                              value="N/U"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0] === "N/U"
                                    ? true
                                    : false
                              }
                              name={item?.id}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample_optional",
                                };
                                dispatch(setFields(inputData));
                                setShowOther(false);
                              }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                      </div>
                      {showOther && (
                        <div className="sump__box">
                          <div className="sump__title">Solids</div>
                          <div className="sump">
                            <label className="fillInspection__field-radio">
                              1. Clean
                              <input
                                type="radio"
                                value="1"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                name={item?.id + "Solids"}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][0] === "1"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              2. Slight
                              <input
                                type="radio"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][0] === "2"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                value={"2"}
                                name={item?.id + "Solids"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              3. Particulate
                              <input
                                type="radio"
                                value={"3"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][0] === "3"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              4. Dirty
                              <input
                                type="radio"
                                value={"4"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][0] === "4"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                          </div>
                        </div>
                      )}
                      {showOther && (
                        <div className="sump__box">
                          <div className="sump__title">Water</div>
                          <div className="sump">
                            <label className="fillInspection__field-radio">
                              A. Bright
                              <input
                                type="radio"
                                value={"A"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][1] === "A"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Water"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              B. Hazy
                              <input
                                type="radio"
                                value={"B"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][1] === "B"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Water"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              C. Cloudy
                              <input
                                type="radio"
                                value={"C"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][1] === "C"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              D. Wet
                              <input
                                type="radio"
                                value={"D"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][1] === "D"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              E. Surfactants
                              <input
                                type="radio"
                                value={"E"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 &&
                                  item?.answers[0][1] === "E"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {item?.type === "sump_sample" && (
                    <>
                      <div className="sump__box">
                        <div className="sump__title">Solids</div>
                        <div className="sump">
                          <label className="fillInspection__field-radio">
                            1. Clean
                            <input
                              type="radio"
                              value="1"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][0] === "1"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            2. Slight
                            <input
                              type="radio"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              value={"2"}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][0] === "2"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            3. Particulate
                            <input
                              type="radio"
                              value={"3"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][0] === "3"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            4. Dirty
                            <input
                              type="radio"
                              value={"4"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][0] === "4"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                        </div>
                      </div>
                      <div className="sump__box">
                        <div className="sump__title">Water</div>
                        <div className="sump">
                          <label className="fillInspection__field-radio">
                            A. Bright
                            <input
                              type="radio"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              value={"A"}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][1] === "A"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Water"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            B. Hazy
                            <input
                              type="radio"
                              value={"B"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][1] === "B"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Water"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            C. Cloudy
                            <input
                              type="radio"
                              value={"C"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][1] === "C"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            D. Wet
                            <input
                              type="radio"
                              value={"D"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][1] === "D"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            E. Surfactants
                            <input
                              type="radio"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              value={"E"}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0][1] === "E"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  {item?.type === "textarea" && (
                    <textarea
                      defaultValue={item?.answers[0]}
                      disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                      onChange={(e) => {
                        let inputData = {
                          id: item.id,
                          answers: [`${e.target.value}`],
                          has_comment: false,
                          comment_text: "",
                          comment_files: [],
                        };
                        dispatch(setFields(inputData));
                      }}
                      className="fillInspection__field-textarea"
                    ></textarea>
                  )}
                  {item?.type !== "textarea" &&
                    item?.type !== "radio" &&
                    item?.type !== "date" &&
                    item?.type !== "checkbox" &&
                    item?.type !== "numeric" &&
                    item?.type !== "select" &&
                    item?.type !== "sump_sample" &&
                    item?.type !== "photo" &&
                    item?.type !== "sump_sample_optional" && (
                      <Input
                        type={"text"}
                        defaultValue={item?.answers[0]}
                        disabled={sheet}
                        readOnly={readOnly || currentItem?.is_completed}
                        onChange={(e) => {
                          let inputData = {
                            id: item.id,
                            answers: [`${e.target.value}`],
                            has_comment: false,
                            comment_text: "",
                            comment_files: item.comment_files,
                          };
                          dispatch(setFields(inputData));
                        }}
                      />
                    )}
                </div>
              </div>
              {item.data_type === 'fsii_percentage' && (fsiiPercentage > -1 || item?.answers[0] > -1) && (
                <div className="fillInspection__field-options" style={{marginTop: 20}}>
                  <div className="fillInspection__field-option">
                    <FillInput
                      readOnly={true}
                      radioOptions={[{checked: currentItem?.is_completed ? (item?.answers[0] >= 0.1 && item?.answers[0] <= 0.15) : (fsiiPercentage >= 0.1 && fsiiPercentage <= 0.15)}, {} ]}
                      setRadioOptions={setRadioOptions}
                      option={{value: 0, label: 'Satisfactory'}}
                      key={'0'}
                      type={"radio"}
                      item={item}
                      disabled={true}
                      index={0}
                      sheet={sheet}
                      inspection_id={inspection_id}
                      fsiiSelect={true}
                    />
                    <FillInput
                      disabled={true}
                      radioOptions={[{}, {checked: currentItem?.is_completed ? item?.answers[0] == 0 || item?.answers[0] === 0.15 || item?.answers[0] > 0.15 ? true : false :  fsiiPercentage == 0 || fsiiPercentage > 0.15 ? true : false}]}
                      setRadioOptions={setRadioOptions}
                      option={{value: 1, label: 'Comment'}}
                      key={'0'}
                      type={"radio"}
                      item={item}
                      disabled={true}
                      index={1}
                      sheet={sheet}
                      inspection_id={inspection_id}
                      fsiiSelect={true}
                    />
                  </div>
                </div>
              )}
              {item.data_type === 'fsii_percentage' && ((fsiiPercentage > 0.15) || (item?.answers[0] > 0.15)) && (
                <div className={"fillInspection__field-options"} style={{marginTop: 20}}>
                  <div className={'fillInspection__field-option'}>
                    <Input
                      type={"text"}
                      disabled={sheet}
                      containerWidth={"100%"}
                      required={true}
                      defaultValue={currentItem?.is_completed ? item?.comment_text : ''}
                      disabled={currentItem?.is_completed}
                      onChange={(e) => {
                        let inputData = {
                          id: item.id,
                          answers: [`${fsiiPercentage}`],
                          has_comment: false,
                          comment_text: e.target.value,
                          comment_files: item.comment_files,
                        };
                        dispatch(setFields(inputData));
                      }}
                    />
                  </div>
                </div>

              )}
            </div> :
            fields?.filter((item) => item?.data_type === "fsii_available" || item?.data_type === "dp_available" || item?.data_type === "corrected_dp_available").length === 0 ?
              <div className="fillInspection__field">
                {(item?.data_type === "corrected_dp" && flowRateGlobal?.answers[0]) ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingBottom: 10,
                    }}
                  >
                    <p className="fillInspection__field-name">{item?.label} </p>
                    <div
                      onClick={() => {
                        checkFields();
                      }}
                      className="header__btn"
                      style={{
                        fontSize: 14,
                        padding: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        margin: 0,
                      }}
                    >
                      Calculate
                    </div>
                  </div>
                ) : (
                  <p className="fillInspection__field-name">{item?.label} </p>
                )}
                <div className="fillInspection__field-options">
                  <div className="fillInspection__field-option">
                    {item?.type === "radio" &&
                      item?.options?.map((option, index) => {
                        return (
                          <FillInput
                            readOnly={readOnly || currentItem?.is_completed}
                            radioOptions={radioOptions}
                            setRadioOptions={setRadioOptions}
                            option={option}
                            key={index}
                            type={"radio"}
                            item={item}
                            index={index}
                            sheet={sheet}
                            inspection_id={inspection_id}
                          />
                        );
                      })}
                    {item?.type === "photo" && (
                      <FillPhoto
                        readOnly={readOnly || currentItem?.is_completed}
                        item={item}
                        sheet={sheet}
                        inspection_id={inspection_id}
                        category_id={currentItem?.id}
                      />
                    )}
                    {item?.type === "date" && (
                      <FillDate
                        readOnly={readOnly}
                        disabled={currentItem?.is_completed}
                        item={item}
                        sheet={sheet}
                        inspection_id={inspection_id}
                        category_id={currentItem?.id}
                      />
                    )}
                    {item?.type === "checkbox" &&
                      item?.options?.map((option, index) => (
                        <FillInput
                          readOnly={readOnly || currentItem?.is_completed}
                          radioOptions={[]}
                          setRadioOptions={setRadioOptions}
                          option={option}
                          key={index}
                          type={"checkbox"}
                          item={item}
                          index={index}
                          sheet={sheet}
                          inspection_id={inspection_id}
                        />
                      ))}
                    {item?.type === "select" && (
                      <FillSelect
                        item={item}
                        options={item?.options}
                        sheet={sheet}
                        readOnly={readOnly || currentItem?.is_completed}
                      />
                    )}
                    {item?.type === "numeric" && item?.data_type === "corrected_dp" ? (
                      <>
                        <Input
                          type={"number"}
                          defaultValue={item?.answers[0]}
                          disabled={sheet || currentItem?.is_completed}
                          value={Number(fullResult)}
                          containerWidth={"100%"}
                          // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                          onChange={(e) => {
                            let inputData = {
                              id: item.id,
                              answers: [`${e.target.value}`],
                              has_comment: false,
                              comment_text: "",
                              comment_files: item.comment_files,
                            };
                            dispatch(setFields(inputData));
                          }}
                          data_type={item?.data_type}
                        />
                      </>
                    ) : item?.type === "numeric" ? (
                      <Input
                        type={"number"}
                        defaultValue={item?.answers[0]}
                        disabled={sheet || currentItem?.is_completed}
                        containerWidth={"100%"}
                        // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                        onChange={(e) => {
                          let inputData = {
                            id: item.id,
                            answers: [`${e.target.value}`],
                            has_comment: false,
                            comment_text: "",
                            comment_files: item.comment_files,
                          };
                          dispatch(setFields(inputData));
                        }}
                        data_type={item?.data_type}
                      />
                    ) : (
                      <></>
                    )}
                    {item?.type === "sump_sample_optional" && (
                      <div className="sump__op">
                        <div className="sump__option">
                          <label className="fillInspection__field-radio">
                            1A - Clean Bright
                            <input
                              type="radio"
                              value="1A"
                              name={item?.id}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0] === "1A"
                                  ? true
                                  : false
                              }
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample_optional",
                                };
                                dispatch(setFields(inputData));
                                setShowOther(false);
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            Other
                            <input
                              type="radio"
                              value=""
                              name={item?.id}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0] !== "1A" &&
                                item?.answers[0] !== "N/A" &&
                                item?.answers[0] !== "N/U"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: item?.answers,
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample_optional",
                                };
                                dispatch(setFields(inputData));
                                setShowOther(true);
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            Not Applicable
                            <input
                              type="radio"
                              value="N/A"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 && item?.answers[0] === "N/A"
                                  ? true
                                  : false
                              }
                              name={item?.id}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample_optional",
                                };
                                dispatch(setFields(inputData));
                                setShowOther(false);
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            Not Used
                            <input
                                type="radio"
                                value="N/U"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0] === "N/U"
                                      ? true
                                      : false
                                }
                                name={item?.id}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample_optional",
                                  };
                                  dispatch(setFields(inputData));
                                  setShowOther(false);
                                }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                        </div>
                        {showOther && (
                          <div className="sump__box">
                            <div className="sump__title">Solids</div>
                            <div className="sump">
                              <label className="fillInspection__field-radio">
                                1. Clean
                                <input
                                  type="radio"
                                  value="1"
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  name={item?.id + "Solids"}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][0] === "1"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                2. Slight
                                <input
                                  type="radio"
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][0] === "2"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                  value={"2"}
                                  name={item?.id + "Solids"}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                3. Particulate
                                <input
                                  type="radio"
                                  value={"3"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][0] === "3"
                                      ? true
                                      : false
                                  }
                                  name={item?.id + "Solids"}
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                4. Dirty
                                <input
                                  type="radio"
                                  value={"4"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][0] === "4"
                                      ? true
                                      : false
                                  }
                                  name={item?.id + "Solids"}
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                            </div>
                          </div>
                        )}
                        {showOther && (
                          <div className="sump__box">
                            <div className="sump__title">Water</div>
                            <div className="sump">
                              <label className="fillInspection__field-radio">
                                A. Bright
                                <input
                                  type="radio"
                                  value={"A"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][1] === "A"
                                      ? true
                                      : false
                                  }
                                  name={item?.id + "Water"}
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                B. Hazy
                                <input
                                  type="radio"
                                  value={"B"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][1] === "B"
                                      ? true
                                      : false
                                  }
                                  name={item?.id + "Water"}
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                C. Cloudy
                                <input
                                  type="radio"
                                  value={"C"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][1] === "C"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                  name={item?.id + "Water"}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                D. Wet
                                <input
                                  type="radio"
                                  value={"D"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][1] === "D"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                  name={item?.id + "Water"}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                              <label className="fillInspection__field-radio">
                                E. Surfactants
                                <input
                                  type="radio"
                                  value={"E"}
                                  disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                  defaultChecked={
                                    item?.answers.length > 0 &&
                                    item?.answers[0][1] === "E"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let inputData = {
                                      id: item.id,
                                      answers: [`${e.target.value}`],
                                      has_comment: false,
                                      comment_text: "",
                                      comment_files: item.comment_files,
                                      type: "sump_sample",
                                    };
                                    dispatch(setFields(inputData));
                                  }}
                                  name={item?.id + "Water"}
                                />
                                <span className={`checkmark`}></span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {item?.type === "sump_sample" && (
                      <>
                        <div className="sump__box">
                          <div className="sump__title">Solids</div>
                          <div className="sump">
                            <label className="fillInspection__field-radio">
                              1. Clean
                              <input
                                type="radio"
                                value="1"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][0] === "1"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              2. Slight
                              <input
                                type="radio"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                value={"2"}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][0] === "2"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              3. Particulate
                              <input
                                type="radio"
                                value={"3"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][0] === "3"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              4. Dirty
                              <input
                                type="radio"
                                value={"4"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][0] === "4"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Solids"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                          </div>
                        </div>
                        <div className="sump__box">
                          <div className="sump__title">Water</div>
                          <div className="sump">
                            <label className="fillInspection__field-radio">
                              A. Bright
                              <input
                                type="radio"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                value={"A"}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][1] === "A"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Water"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              B. Hazy
                              <input
                                type="radio"
                                value={"B"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][1] === "B"
                                    ? true
                                    : false
                                }
                                name={item?.id + "Water"}
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              C. Cloudy
                              <input
                                type="radio"
                                value={"C"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][1] === "C"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              D. Wet
                              <input
                                type="radio"
                                value={"D"}
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][1] === "D"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                            <label className="fillInspection__field-radio">
                              E. Surfactants
                              <input
                                type="radio"
                                disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                                value={"E"}
                                defaultChecked={
                                  item?.answers.length > 0 && item?.answers[0][1] === "E"
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let inputData = {
                                    id: item.id,
                                    answers: [`${e.target.value}`],
                                    has_comment: false,
                                    comment_text: "",
                                    comment_files: item.comment_files,
                                    type: "sump_sample",
                                  };
                                  dispatch(setFields(inputData));
                                }}
                                name={item?.id + "Water"}
                              />
                              <span className={`checkmark`}></span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    {item?.type === "textarea" && (
                      <textarea
                        defaultValue={item?.answers[0]}
                        disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                        onChange={(e) => {
                          let inputData = {
                            id: item.id,
                            answers: [`${e.target.value}`],
                            has_comment: false,
                            comment_text: "",
                            comment_files: [],
                          };
                          dispatch(setFields(inputData));
                        }}
                        className="fillInspection__field-textarea"
                      ></textarea>
                    )}
                    {item?.type !== "textarea" &&
                      item?.type !== "radio" &&
                      item?.type !== "date" &&
                      item?.type !== "checkbox" &&
                      item?.type !== "numeric" &&
                      item?.type !== "select" &&
                      item?.type !== "sump_sample" &&
                      item?.type !== "photo" &&
                      item?.type !== "sump_sample_optional" && (
                        <Input
                          type={"text"}
                          defaultValue={item?.answers[0]}
                          disabled={sheet}
                          readOnly={readOnly || currentItem?.is_completed}
                          onChange={(e) => {
                            let inputData = {
                              id: item.id,
                              answers: [`${e.target.value}`],
                              has_comment: false,
                              comment_text: "",
                              comment_files: item.comment_files,
                            };
                            dispatch(setFields(inputData));
                          }}
                        />
                      )}
                  </div>
                </div>
              </div> : null
          :
          <div className="fillInspection__field">
            {(item?.data_type === "corrected_dp" && flowRateGlobal?.answers[0]) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                }}
              >
                <p className="fillInspection__field-name">{item?.label} </p>
                <div
                  onClick={() => {
                    checkFields();
                  }}
                  className="header__btn"
                  style={{
                    fontSize: 14,
                    padding: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    margin: 0,
                  }}
                >
                  Calculate
                </div>
              </div>
            ) : (
              <p className="fillInspection__field-name">
                {item?.label}
                {item?.tooltip ? <Tooltip tooltipText={item?.tooltip} /> : null}
              </p>
            )}
            <div className="fillInspection__field-options">
              <div className="fillInspection__field-option">
                {item?.type === "radio" &&
                  item?.options?.map((option, index) => {
                    return (
                      <FillInput
                        readOnly={readOnly || currentItem?.is_completed}
                        radioOptions={radioOptions}
                        setRadioOptions={setRadioOptions}
                        option={option}
                        key={index}
                        type={"radio"}
                        item={item}
                        index={index}
                        sheet={sheet}
                        inspection_id={inspection_id}
                        currentItem={currentItem}
                        setCurrentItem={setCurrentItem}
                      />
                    );
                  })}
                {item?.type === "photo" && (
                  <FillPhoto
                    readOnly={readOnly || currentItem?.is_completed}
                    item={item}
                    sheet={sheet}
                    inspection_id={inspection_id}
                    category_id={currentItem?.id}
                  />
                )}
                {item?.type === "date" && (
                  <FillDate
                    readOnly={readOnly}
                    disabled={currentItem?.is_completed}
                    item={item}
                    sheet={sheet}
                    inspection_id={inspection_id}
                    category_id={currentItem?.id}
                  />
                )}
                {item?.type === "checkbox" &&
                  item?.options?.map((option, index) => (
                    <FillInput
                      readOnly={readOnly || currentItem?.is_completed}
                      radioOptions={[]}
                      setRadioOptions={setRadioOptions}
                      option={option}
                      key={index}
                      type={"checkbox"}
                      item={item}
                      index={index}
                      sheet={sheet}
                      inspection_id={inspection_id}
                    />
                  ))}
                {item?.type === "select" && (
                  <FillSelect
                    item={item}
                    options={item?.options}
                    sheet={sheet}
                    readOnly={readOnly || currentItem?.is_completed}
                  />
                )}
                {item?.type === "numeric" && item?.data_type === "corrected_dp" ? (
                  <>
                    <Input
                      type={"number"}
                      defaultValue={item?.answers[0]}
                      disabled={sheet || currentItem?.is_completed}
                      value={Number(fullResult)}
                      containerWidth={"100%"}
                      // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                      onChange={(e) => {
                        let inputData = {
                          id: item.id,
                          answers: [`${e.target.value}`],
                          has_comment: false,
                          comment_text: "",
                          comment_files: item.comment_files,
                        };
                        dispatch(setFields(inputData));
                      }}
                      data_type={item?.data_type}
                    />
                  </>
                ) : item?.type === "numeric" ? (
                  <Input
                    type={"number"}
                    defaultValue={item?.answers[0]}
                    disabled={sheet || currentItem?.is_completed}
                    containerWidth={"100%"}
                    // readOnly={item?.data_type === 'corrected_dp' ? true : readOnly}
                    onChange={(e) => {
                      let inputData = {
                        id: item.id,
                        answers: [`${e.target.value}`],
                        has_comment: false,
                        comment_text: "",
                        comment_files: item.comment_files,
                      };
                      dispatch(setFields(inputData));
                    }}
                    data_type={item?.data_type}
                  />
                ) : item?.type === "dry_rating" ? (
                  <FillDryRating item={item} readOnly={readOnly || currentItem?.is_completed} />
                ) : (
                  <></>
                )}
                {item?.type === "sump_sample_optional" && (
                  <div className="sump__op">
                    <div className="sump__option">
                      <label className="fillInspection__field-radio">
                        1A - Clean Bright
                        <input
                          type="radio"
                          value="1A"
                          name={item?.id}
                          defaultChecked={
                            item?.answers.length > 0 && item?.answers[0] === "1A"
                              ? true
                              : false
                          }
                          disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                          onChange={(e) => {
                            let inputData = {
                              id: item.id,
                              answers: [`${e.target.value}`],
                              has_comment: false,
                              comment_text: "",
                              comment_files: item.comment_files,
                              type: "sump_sample_optional",
                            };
                            dispatch(setFields(inputData));
                            setShowOther(false);
                          }}
                        />
                        <span className={`checkmark`}></span>
                      </label>
                      <label className="fillInspection__field-radio">
                        Other
                        <input
                          type="radio"
                          value=""
                          name={item?.id}
                          disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                          defaultChecked={
                            item?.answers.length > 0 &&
                            item?.answers[0] !== "1A" &&
                            item?.answers[0] !== "N/A" &&
                            item?.answers[0] !== "N/U"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            let inputData = {
                              id: item.id,
                              answers: item?.answers,
                              has_comment: false,
                              comment_text: "",
                              comment_files: item.comment_files,
                              type: "sump_sample_optional",
                            };
                            dispatch(setFields(inputData));
                            setShowOther(true);
                          }}
                        />
                        <span className={`checkmark`}></span>
                      </label>
                      <label className="fillInspection__field-radio">
                        Not Applicable
                        <input
                          type="radio"
                          value="N/A"
                          disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                          defaultChecked={
                            item?.answers.length > 0 && item?.answers[0] === "N/A"
                              ? true
                              : false
                          }
                          name={item?.id}
                          onChange={(e) => {

                            let inputData = {
                              id: item.id,
                              answers: [`${e.target.value}`],
                              has_comment: false,
                              comment_text: "",
                              comment_files: item.comment_files,
                              type: "sump_sample_optional",
                            };
                            dispatch(setFields(inputData));
                            setShowOther(false);
                          }}
                        />
                        <span className={`checkmark`}></span>
                      </label>
                      <label className="fillInspection__field-radio">
                        Not Used
                        <input
                            type="radio"
                            value="N/U"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0] === "N/U"
                                  ? true
                                  : false
                            }
                            name={item?.id}
                            onChange={(e) => {

                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample_optional",
                              };
                              dispatch(setFields(inputData));
                              setShowOther(false);
                            }}
                        />
                        <span className={`checkmark`}></span>
                      </label>
                    </div>
                    {showOther && (
                      <div className="sump__box">
                        <div className="sump__title">Solids</div>
                        <div className="sump">
                          <label className="fillInspection__field-radio">
                            1. Clean
                            <input
                              type="radio"
                              value="1"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              name={item?.id + "Solids"}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][0] === "1"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            2. Slight
                            <input
                              type="radio"
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][0] === "2"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              value={"2"}
                              name={item?.id + "Solids"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            3. Particulate
                            <input
                              type="radio"
                              value={"3"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][0] === "3"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            4. Dirty
                            <input
                              type="radio"
                              value={"4"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][0] === "4"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Solids"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                        </div>
                      </div>
                    )}
                    {showOther && (
                      <div className="sump__box">
                        <div className="sump__title">Water</div>
                        <div className="sump">
                          <label className="fillInspection__field-radio">
                            A. Bright
                            <input
                              type="radio"
                              value={"A"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][1] === "A"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Water"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            B. Hazy
                            <input
                              type="radio"
                              value={"B"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][1] === "B"
                                  ? true
                                  : false
                              }
                              name={item?.id + "Water"}
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            C. Cloudy
                            <input
                              type="radio"
                              value={"C"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][1] === "C"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            D. Wet
                            <input
                              type="radio"
                              value={"D"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][1] === "D"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                          <label className="fillInspection__field-radio">
                            E. Surfactants
                            <input
                              type="radio"
                              value={"E"}
                              disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                              defaultChecked={
                                item?.answers.length > 0 &&
                                item?.answers[0][1] === "E"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                let inputData = {
                                  id: item.id,
                                  answers: [`${e.target.value}`],
                                  has_comment: false,
                                  comment_text: "",
                                  comment_files: item.comment_files,
                                  type: "sump_sample",
                                };
                                dispatch(setFields(inputData));
                              }}
                              name={item?.id + "Water"}
                            />
                            <span className={`checkmark`}></span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {item?.type === "sump_sample" && (
                  <>
                    <div className="sump__box">
                      <div className="sump__title">Solids</div>
                      <div className="sump">
                        <label className="fillInspection__field-radio">
                          1. Clean
                          <input
                            type="radio"
                            value="1"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][0] === "1"
                                ? true
                                : false
                            }
                            name={item?.id + "Solids"}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          2. Slight
                          <input
                            type="radio"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                            value={"2"}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][0] === "2"
                                ? true
                                : false
                            }
                            name={item?.id + "Solids"}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          3. Particulate
                          <input
                            type="radio"
                            value={"3"}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][0] === "3"
                                ? true
                                : false
                            }
                            name={item?.id + "Solids"}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          4. Dirty
                          <input
                            type="radio"
                            value={"4"}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][0] === "4"
                                ? true
                                : false
                            }
                            name={item?.id + "Solids"}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                      </div>
                    </div>
                    <div className="sump__box">
                      <div className="sump__title">Water</div>
                      <div className="sump">
                        <label className="fillInspection__field-radio">
                          A. Bright
                          <input
                            type="radio"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            value={"A"}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][1] === "A"
                                ? true
                                : false
                            }
                            name={item?.id + "Water"}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          B. Hazy
                          <input
                            type="radio"
                            value={"B"}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][1] === "B"
                                ? true
                                : false
                            }
                            name={item?.id + "Water"}
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          C. Cloudy
                          <input
                            type="radio"
                            value={"C"}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][1] === "C"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                            name={item?.id + "Water"}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          D. Wet
                          <input
                            type="radio"
                            value={"D"}
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][1] === "D"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                            name={item?.id + "Water"}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                        <label className="fillInspection__field-radio">
                          E. Surfactants
                          <input
                            type="radio"
                            disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                            value={"E"}
                            defaultChecked={
                              item?.answers.length > 0 && item?.answers[0][1] === "E"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let inputData = {
                                id: item.id,
                                answers: [`${e.target.value}`],
                                has_comment: false,
                                comment_text: "",
                                comment_files: item.comment_files,
                                type: "sump_sample",
                              };
                              dispatch(setFields(inputData));
                            }}
                            name={item?.id + "Water"}
                          />
                          <span className={`checkmark`}></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
                {item?.type === "textarea" && (
                  <textarea
                    defaultValue={item?.answers[0]}
                    disabled={readOnly || currentItem?.is_completed ? true : sheet ? true : false}
                    onChange={(e) => {
                      let inputData = {
                        id: item.id,
                        answers: [`${e.target.value}`],
                        has_comment: false,
                        comment_text: "",
                        comment_files: [],
                      };
                      dispatch(setFields(inputData));
                    }}
                    className="fillInspection__field-textarea"
                  ></textarea>
                )}
                {item?.type !== "textarea" &&
                  item?.type !== "radio" &&
                  item?.type !== "date" &&
                  item?.type !== "checkbox" &&
                  item?.type !== "numeric" &&
                  item?.type !== "select" &&
                  item?.type !== "sump_sample" &&
                  item?.type !== "photo" &&
                  item?.type !== "dry_rating" &&
                  item?.type !== "sump_sample_optional" && (
                    <Input
                      type={"text"}
                      defaultValue={item?.answers[0]}
                      disabled={sheet}
                      readOnly={readOnly || currentItem?.is_completed}
                      onChange={(e) => {
                        let inputData = {
                          id: item.id,
                          answers: [`${e.target.value}`],
                          has_comment: false,
                          comment_text: "",
                          comment_files: item.comment_files,
                        };
                        dispatch(setFields(inputData));
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
      }
    </>

  );
}

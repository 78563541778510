import React, { useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Loader from "../Loader";
import Input from "../Input";
import { useUpdateBillingMutation } from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import {setLoader} from "../../store/builder/reducer";

const ShippingAddressModal = ({ setShowPaymentModal, getUserIndex }) => {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [state, setState] = useState({
    city: userInfo?.address?.city,
    line1: userInfo?.address?.line1,
    line2: userInfo?.address?.line2,
    state: userInfo?.address?.state,
    country: userInfo?.address?.country,
    postal_code: userInfo?.address?.postal_code,
    type: "shipping"
  });
  const [update, { data, error, isLoading, isSuccess, isError }] =
    useUpdateBillingMutation();

  const handleChange = (e) => {
    setState((old) => ({ ...old, [e.target.id]: e.target.value }));
  };

  const updateBillingAddress = () => {
    if (
      state?.city?.length &&
      state?.line1?.length &&
      state?.state?.length &&
      state?.country?.length &&
      state?.postal_code?.length
    ) {
      dispatch(setLoader(true))
      update(state).then((res) => {
        toast?.success(res.data.message);
        getUserIndex && getUserIndex();
        setShowPaymentModal(false);
        dispatch(setLoader(false))
      });
    } else {
      toast?.error("Fill all fields!");
    }
  };

  return (
    <div className="modal payment__main">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Update shipping address</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => setShowPaymentModal(false)}
          />
        </div>
        <div className="modal__content-body user">
          <Input
            onChange={handleChange}
            label={"Address line 1"}
            placeholder={"Address line 1"}
            id="line1"
            defaultValue={userInfo?.address?.line1}
          />
          <Input
            onChange={handleChange}
            label={"Address line 2"}
            placeholder={"Address line 2"}
            id="line2"
            defaultValue={userInfo?.address?.line2}
          />
          <Input
            onChange={handleChange}
            label={"City"}
            placeholder="City"
            id="city"
            defaultValue={userInfo?.address?.city}
          />
          <Input
            onChange={handleChange}
            label={"State/ Province/ Region"}
            placeholder={"State/ Province/ Region"}
            id="state"
            defaultValue={userInfo?.address?.state}
          />
          <Input
            onChange={handleChange}
            label={"Country"}
            placeholder={"Country"}
            id="country"
            defaultValue={userInfo?.address?.country}
          />
          <Input
            onChange={handleChange}
            label={"Postal Code"}
            placeholder={"Postal Code"}
            id="postal_code"
            defaultValue={userInfo?.address?.postal_code}
          />
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={() => setShowPaymentModal(false)}>
            Close
          </button>
          <button className="save" onClick={updateBillingAddress}>
            Save
          </button>
        </div>
      </div>
      <div
        className="modal__bg"
      ></div>
    </div>
  );
};

export default ShippingAddressModal;

import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from '../../components/DashboardHeader';
import './index.scss'
import Select from "react-select";
import {Helmet} from 'react-helmet';
import {
  useGetAgencyQuery,
  useGetFacilityQuery,
  useGetFuelReceiptQuery, useLazyGetEquipmentQuery, useLazyGetFacilityQuery,
  useLazyGetFuelReceiptQuery, useLazyGetReportListQuery, useRemoveReportMutation
} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {useNavigate} from "react-router-dom";
import {useErrorHandler} from "../../global/ErrorsHandler";
import moment from "moment";
import ReportModal from "../../components/ReportModal";
import Input from "../../components/Input";
import Datepicker from "../../components/Datepicker";
import {useDebounce} from "../LogSheets/hooks";
import {toast} from "react-toastify";

export default function FuelReceiptForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const pageTab = urlParams.get("tab");
  const [currentTab, setCurrentTab] = useState(
    pageTab && pageTab !== "" ? pageTab : 1
  );
  const currentFbo = localStorage.getItem('current-fbo')
  const [search, setSearch] = useState('')
  const [searchFuel, setSearchFuel] = useState('')
  const [fuelFilters, setFuelFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
  })
  const [reportFilters, setReportFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
  })
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [agencyOptions, setAgencyOptions] = useState([])
  const [chosenAgency, setChosenAgency] = useState(agencyOptions[0])
  const [reportModalStatus, setReportModalStatus] = useState(false)
  const [facilityOptions, setFacilityOptions] = useState([])
  const [equipmentOptions, setEquipmentOptions] = useState([])
  const [currentReport, setCurrentReport] = useState(null)
  const [dangerShow, setDangerShow] = useState(false)
  const [getReceipt, {
    data: receiptData,
    error: receiptError,
    isFetching: receiptIsLoading,
    isSuccess: receiptIsSuccess,
    isError: receiptIsError
  }] = useLazyGetFuelReceiptQuery();
  const {
    data: agencyData,
    error: agencyError,
    isLoading: agencyIsLoading,
    isSuccess: agencyIsSuccess,
    isError: agencyIsError
  } = useGetAgencyQuery();
  const [getFacility, {
    data: facilityData,
    isError: facilityIsError,
    isFetching: facilityIsLoading,
    isSuccess: facilityIsSuccess,
    error: facilityError,
  }] = useLazyGetFacilityQuery();

  const [getEquipment,
    {
      data: equipmentData,
      isError: equipmentIsError,
      isSuccess: equipmentIsSuccess,
      isFetching: equipmentIsLoading,
      error: equipmentError
    }] = useLazyGetEquipmentQuery();

  const [getReportData, {
    data: reportData,
    isError: reportIsError,
    isFetching: reportIsLoading,
    isSuccess: reportIsSuccess,
    error: reportError,
  }] = useLazyGetReportListQuery();

  const [removeReport, {
    data: removeReportData,
    isError: removeReportIsError,
    isFetching: removeReportIsLoading,
    isSuccess: removeReportIsSuccess,
    error: removeReportError,
  }] = useRemoveReportMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    agencyError,
    receiptError,
  ]);
  const debouncedSearchValue = useDebounce(search, 500);
  const debouncedSearchFuelValue = useDebounce(searchFuel, 500);

  useEffect(() => {
    getFacility()
    getEquipment({active: true})
  }, [])

  useEffect(() => {
    if (facilityData) {
      setFacilityOptions(facilityData?.map(item => ({value: item.id, label: item.name})))
    }
    if (equipmentData) {
      setEquipmentOptions(equipmentData?.data?.map(item => ({value: item.id, label: item.title})))
    }
  }, [facilityData, equipmentData])

  useEffect(() => {
    if (currentTab == 2) {
      getReportData({
        facility_id: reportFilters?.facility_id,
        date_from: reportFilters?.date_from,
        date_to: reportFilters?.date_to,
        search: debouncedSearchFuelValue,
        type: "pdf-fuel"
      })
    } else {
      getReceipt({
        agency: currentFbo,
        isCompleted: currentTab,
        facility_id: fuelFilters?.facility_id,
        equipment_id: fuelFilters?.equipment_id,
        date_from: fuelFilters?.date_from,
        date_to: fuelFilters?.date_to,
        search: debouncedSearchValue,
      })
    }
  }, [currentTab, fuelFilters, debouncedSearchValue, reportFilters, debouncedSearchFuelValue])

  useEffect(() => {
    const filteredArr = reportData?.data?.filter(el => el.file_name === "wait")
    const interval = setInterval(() => {
      if (filteredArr?.length === 0) {
        clearInterval(interval);
      } else {
        getReportData({
          facility_id: reportFilters?.facility_id,
          date_from: reportFilters?.date_from,
          date_to: reportFilters?.date_to,
          search: debouncedSearchFuelValue,
          type: "pdf-fuel"
        });
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [reportData]);

  useEffect(() => {
    setFuelFilters(old => ({
      ...old,
      facility_id: urlParams.get('facility_id'),
      equipment_id: urlParams.get('equipment_id'),
      date_from: urlParams.get('date_from'),
      date_to: urlParams.get('date_to'),
    }))

    setReportFilters(old => ({
      ...old,
      facility_id: urlParams.get('facility_id_fuel'),
      equipment_id: urlParams.get('equipment_id_fuel'),
      date_from: urlParams.get('date_from_fuel'),
      date_to: urlParams.get('date_to_fuel'),
    }))
  }, [])


  useEffect(() => {
    if (receiptIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [receiptIsLoading])

  // useEffect(() => {
  //   if (reportIsLoading) {
  //     dispatch(setLoader(true))
  //   } else {
  //     dispatch(setLoader(false))
  //   }
  // }, [reportIsLoading])

  useEffect(() => {
    if (removeReportIsLoading) {
      dispatch(setLoader(true));
    }
    if (removeReportIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Report successfully deleted')
      setDangerShow(false)
    }
  }, [ removeReportIsLoading, removeReportIsSuccess])

  useEffect(() => {
    navigate(`/receipt-form?tab=${currentTab}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${moment(urlParams.get('date_from'))}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
  }, [currentTab])

  const changeTab = (tabId) => {
    setCurrentTab(tabId)
    setReportFilters({
      date_from: null,
      date_to: null,
      facility_id: null,
      equipment_id: null,
    })
  }
  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleSearchFuel = (e) => {
    setSearchFuel(e.target.value)
  }

  const handleFilterDateFrom = (date) => {
    setFuelFilters(old => ({...old, date_from: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
  }
  const handleFilterDateTo = (date) => {
    setFuelFilters(old => ({...old, date_to: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${date ? `&date_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
  }

  const handleFilterDateFromFuel = (date) => {
    setReportFilters(old => ({...old, date_from: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${urlParams.get('facility_id_fuel') ? `&facility_id_fuel=${urlParams.get('facility_id_fuel')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_from_fuel=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_to_fuel') ? `&date_to_fuel=${urlParams.get('date_to_fuel')}` : ""}`)
  }

  const handleFilterDateToFuel = (date) => {
    setReportFilters(old => ({...old, date_to: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${urlParams.get('facility_id_fuel') ? `&facility_id_fuel=${urlParams.get('facility_id_fuel')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from_fuel') ? `&date_from_fuel=${urlParams.get('date_from_fuel')}` : ""}${date ? `&date_to_fuel=${moment(date).format('YYYY-MM-DD')}` : ""}`)
  }
  const renderDanger = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Delete report</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure want to remove this report?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setDangerShow(false)}>
              Cancel
            </button>
            <button className="save danger" onClick={() => {
              removeReport(currentReport)
            }}>
              Delete
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <Wrapper>
      {reportModalStatus && (
        <ReportModal
          title={"Create Report"}
          exit={() => setReportModalStatus(false)}
          facility={facilityData?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          type={'fuel'}
        />
      )}
      {
        dangerShow && renderDanger()
      }
      <Helmet>
        <title>Fuel Receipt Form | NDX</title>
      </Helmet>
      <DashboardHeader title={'Fuel Receipt Form'} type={currentTab == 2 ? 'receiptForm' : ""}
                       action={() => setReportModalStatus(true)}/>
      <div className="receipt">
        <div className="receipt__header">
          <div className={`receipt__header-tab ${currentTab == 1 ? 'active' : ''}`}
               onClick={() => changeTab(1)}>Completed
          </div>
          <div className={`receipt__header-tab ${currentTab == 0 ? 'active' : ''}`}
               onClick={() => changeTab(0)}>In progress
          </div>
          <div className={`receipt__header-tab ${currentTab == 2 ? 'active' : ''}`}
               onClick={() => changeTab(2)}>Reports
          </div>
        </div>
        {
          currentTab == 2 ?
            <div className="receipt__filters">
              <Input type="text" name={'search'} id={'search'}
                     onChange={handleSearchFuel}
                     image="/assets/icons/search.svg"
                     placeholder="Search"
                     defaultValue={search} maxwidth={200}
              />
              <Datepicker
                type={'from'}
                setValue={handleFilterDateFromFuel}
                value={urlParams.get('date_from_fuel')}
              />
              <Datepicker
                type={'to'}
                setValue={handleFilterDateToFuel}
                value={urlParams.get('date_to_fuel')}
              />
              <Select styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                  borderRadius: 8,
                  width: 200,
                  height: 42,
                  backgroundColor: '#F4F5F6'
                }),
              }} isClearable
                      key={JSON.stringify(facilityOptions) + "1"}
                      placeholder={'Facility'} options={facilityOptions}
                      defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id_fuel'))[0]}
                      onChange={(e) => {
                        setReportFilters(old => ({...old, facility_id: e?.value}))
                        navigate(`/receipt-form?${e?.value ? `&facility_id_fuel=${e?.value}` : ""}${urlParams.get('date_from_fuel') ? `&date_from_fuel=${urlParams.get('date_from_fuel')}` : ""}${urlParams.get('date_to_fuel') ? `&date_to_fuel=${urlParams.get('date_to_fuel')}` : ""}`)
                      }}
              />
            </div>
            :
            <div className="receipt__filters">
              <Input type="text" name={'search'} id={'search'}
                     onChange={handleSearch}
                     image="/assets/icons/search.svg"
                     placeholder="Search"
                     defaultValue={search} maxwidth={200}
              />
              <Datepicker
                type={'from'}
                setValue={handleFilterDateFrom}
                value={urlParams.get('date_from')}
              />
              <Datepicker
                type={'to'}
                setValue={handleFilterDateTo}
                value={urlParams.get('date_to')}
              />
              <Select styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                  borderRadius: 8,
                  width: 200,
                  height: 42,
                  backgroundColor: '#F4F5F6'
                }),
              }} isClearable
                      key={JSON.stringify(facilityOptions) + "1"}
                      placeholder={'Facility'} options={facilityOptions}
                      defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                      onChange={(e) => {
                        setFuelFilters(old => ({...old, facility_id: e?.value}))
                        navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
                      }}
              />
              <Select styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                  borderRadius: 8,
                  width: 200,
                  height: 42,
                  backgroundColor: '#F4F5F6'
                }),
              }} isClearable
                      key={JSON.stringify(equipmentOptions) + "2"}
                      placeholder={'Equipment'} options={equipmentOptions}
                      defaultValue={equipmentOptions?.filter(item => item?.value == urlParams.get('equipment_id'))[0]}
                      onChange={(e) => {
                        setFuelFilters(old => ({...old, equipment_id: e?.value}))
                        navigate(`/receipt-form?${urlParams.get('tab') ? `tab=${urlParams.get('tab')}` : currentTab}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${e?.value ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
                      }}
              />
            </div>
        }
        <div className="receipt__box">
          <div className="receipt__table">
            {
              currentTab == 2 ?
                <div className="receipt__table-head">
                  <div className="receipt__table-th">Name</div>
                  <div className="receipt__table-th">Timestamp</div>
                  <div className="receipt__table-th">Facility</div>
                  <div className="receipt__table-th">Year</div>
                  <div className="receipt__table-th">Month from</div>
                  <div className="receipt__table-th">Month to</div>
                  <div className="receipt__table-th">Completed by</div>
                  <div className="receipt__table-th">Download</div>
                  <div className="receipt__table-th"></div>
                </div>
                :
                <div className="receipt__table-head seven-cols">
                  <div className="receipt__table-th">Facility</div>
                  <div className="receipt__table-th">Equipment</div>
                  <div className="receipt__table-th">Inspection</div>
                  <div className="receipt__table-th">Period</div>
                  <div className="receipt__table-th">Status</div>
                  <div className="receipt__table-th">Date</div>
                  <div className="receipt__table-th">Submitted by</div>
                </div>
            }

            <div className="receipt__table-body">
              {
                currentTab == 2 ?
                  reportData?.data?.map(report => (
                    <div className="receipt__table-row" key={report?.id}>
                      <div className="receipt__table-bodyTh">{report.name}</div>
                      <div className="receipt__table-bodyTh">
                        {moment(report?.updated_at).format("MM/DD/YY HH:mm")}
                      </div>
                      <div className="receipt__table-bodyTh">{report?.facility?.name}</div>
                      <div className="receipt__table-bodyTh">{report?.year}</div>
                      <div className="receipt__table-bodyTh">
                        {/*{moment(*/}
                        {/*  moment("2014-" + report?.month_from + "-28").format(*/}
                        {/*    "YYYY/MM/DD"*/}
                        {/*  )*/}
                        {/*).format("MMMM")}*/}
                        {report?.month_from}
                      </div>
                      <div className="receipt__table-bodyTh">
                        {/*{moment(*/}
                        {/*  moment("2014-" + report?.month_to + "-28").format(*/}
                        {/*    "YYYY/MM/DD"*/}
                        {/*  )*/}
                        {/*).format("MMMM")}*/}
                        {report?.month_to}

                      </div>
                      <div className="receipt__table-bodyTh">{report?.created_by?.name}</div>
                      {
                        report?.file_name === "wait" ?
                          <div className="report__td small">Generating <br/> Report</div>
                          :
                          report?.file_name && report?.file_name?.length > 0 ? (
                            <a
                              href={report?.file_name}
                              target="_blank"
                              className="report__td img"
                              rel="noreferrer"
                            >
                              <img src="/assets/icons/Download.svg" alt="icon" />
                            </a>
                          ) : (
                            <div className="report__td small">NO DATA</div>
                          )}
                      <div className="receipt__table-bodyTh delete" onClick={() => {
                        setDangerShow(true)
                        setCurrentReport(report.id)
                      }}>
                        <img src="/assets/icons/trash-2.svg" alt="icon" />
                      </div>
                    </div>
                  ))
                  :
                    receiptData?.data?.length ? receiptData?.data?.map(receipt => (
                    <div className="receipt__table-row seven-cols" key={receipt?.id} onClick={() => navigate(`/inspections/${receipt?.id}`, {state: {readOnly: true}})}>
                    <div className="receipt__table-bodyTh">{receipt?.facility?.name ? receipt?.facility?.name : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.equipment?.title ? receipt?.equipment?.title : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.inspection_form?.name ? receipt?.inspection_form?.name : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.period ? receipt?.period : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.status ? receipt?.status : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.updated_at ? moment(receipt?.updated_at).format("MM/DD/YY") : "---"}</div>
                    <div className="receipt__table-bodyTh">{receipt?.submitted_user?.name ? receipt?.submitted_user?.name : "---"}</div>
                    </div>
                    )) : (
                    <div className='emptyList'>
                    <img src="/assets/empty-list.png" alt="empty"/>
                    <p>No fuel receipt form data yet</p>
                    </div>
                    )

              }
            </div>
          </div>
        </div>
      </div>
    </Wrapper>

  );
};

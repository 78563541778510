import React, {PureComponent, useEffect, useState} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './../chart.scss';
import Select from "react-select";
import {
  useLazyGetEquipmentTypeQuery,
  useLazyGetFacilityByAgencyQuery,
  useLazyGetUsersByAgencyIdQuery
} from "../../../store/builder/inspections.api";
import {useSelector} from "react-redux";
import Loader from "../../Loader";

const AverageTimeBar = ({size = 'small', data, title, handlePeriodChange, setAverageFilters}) => {
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const currentFbo = localStorage.getItem("current-fbo");
  const [newData, setNewData] = useState([]);
  const [filterPeriodOptions, setFilterPeriodOptions] = useState([
    {value: "week", label: "Last Week"},
    {value: "month", label: "Last Month"},
    {value: "quarter", label: "Last Quarter"},
    {value: "semiannual", label: "Last 6 Months"},
    {value: "annual", label: "TTM"},
  ])
  const [unit, setUnit] = useState(' seconds');
  const [usersOptions, setUsersOptions] = useState([])
  const [facilityOptions, setFacilityOptions] = useState([])
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([])

  const [getUsers, {
    data: getUsersData,
    error: getUsersError,
    isFetching: getUsersIsLoading,
    isSuccess: getUsersIsSuccess,
    isError: getUsersIsError,
  }] = useLazyGetUsersByAgencyIdQuery();

  const [getFacility, {
    data: getFacilityData,
    error: getFacilityError,
    isFetching: getFacilityIsLoading,
    isSuccess: getFacilityIsSuccess,
    isError: getFacilityIsError,
  }] = useLazyGetFacilityByAgencyQuery();

  const [getEquipmentType, {
    data: getEquipmentTypeData,
    error: getEquipmentTypeError,
    isFetching: getEquipmentTypeIsLoading,
    isSuccess: getEquipmentTypeIsSuccess,
    isError: getEquipmentTypeIsError,
  }] = useLazyGetEquipmentTypeQuery();

  useEffect(() => {
    if (currentFbo) {
      getUsers(currentFbo)
      getFacility({id: currentFbo})
      getEquipmentType()
    }
  }, [currentFbo])

  useEffect(() => {
    if (getUsersIsSuccess) {
      setUsersOptions(getUsersData?.map(item => ({label: item?.name, value: item?.id})))
    }
    if (getFacilityIsSuccess) {
      setFacilityOptions(getFacilityData?.data?.map(item => ({label: item?.name, value: item?.id})))
    }
    if (getEquipmentTypeIsSuccess) {
      setEquipmentTypeOptions(getEquipmentTypeData.map(item => ({label: item?.title, value: item?.id})))
    }
  }, [getUsersIsSuccess, getFacilityIsSuccess, getEquipmentTypeIsSuccess])

  useEffect(() => {
    if (data?.some((item) => item?.value)) {
      const timeData = [...data]
      const highestValueObject = timeData?.reduce((prev, current) => {
        return (prev.value > current.value) ? prev : current;
      });
      if (highestValueObject?.value > 1440) {
        setNewData(timeData?.map(item => ({...item, value: Math.round(((item.value / 60) / 24) * 10) / 10})))
        setUnit(' hours')
      } else if (highestValueObject?.value > 60) {
        setNewData(timeData?.map(item => ({...item, value: Math.round((item.value / 60) * 10) / 10})))
        setUnit(' minutes')
      } else {
        setNewData(timeData?.map(item => ({...item, value: Math.round(item.value * 10) / 10})))
        setUnit(' seconds')
      }
    } else {
      setNewData([])
    }
  }, [data])

  return (
    <div className="chart mid">
      {/*{loader && <Loader/>}*/}
      <div className="chart__header row">
        <p className="chart__header-title">{title}</p>
        <div className="chart__header-filters">
          <Select
            defaultValue={filterPeriodOptions[0]}
            options={filterPeriodOptions}
            placeholder={"Period"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 135,
                height: 30,
                fontSize: 14,
                backgroundColor: "#FFFFFF",
                color: "#718096",
              }),
            }}
            onChange={(selected) => {
              handlePeriodChange({type: "filter_period", value: selected.value})
            }}
          />
        <Select
          options={facilityOptions}
          placeholder={"Facility"}
          isClearable={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: 135,
              height: 30,
              fontSize: 14,
              backgroundColor: "#FFFFFF",
              color: "#718096",
            }),
          }}
          onChange={(selected) => {
            handlePeriodChange({type: "facility_id", value: selected?.value})
          }}
        />
        <Select
          options={equipmentTypeOptions}
          placeholder={"Equipment type"}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: 165,
              height: 30,
              fontSize: 14,
              backgroundColor: "#FFFFFF",
              color: "#718096",
            }),
          }}
          isClearable={true}
          onChange={(selected) => {
            handlePeriodChange({type: "equipment_type_id", value: selected?.value})
          }}
        />
        <Select
          options={usersOptions}
          placeholder={"Users"}
          isClearable={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: 135,
              height: 30,
              fontSize: 14,
              backgroundColor: "#FFFFFF",
              color: "#718096",
            }),
          }}
          onChange={(selected) => {
            handlePeriodChange({type: "employee_id", value: selected?.value})
          }}
        />
        </div>
      </div>
      {
        newData?.length ? (
          <ResponsiveContainer height={size === "small" ? 206 : 303} width='100%'>
          <BarChart height={size === "small" ? 206 : 303} data={newData} barGap={size === 'small' ? 32 : 49}>
            <CartesianGrid strokeDasharray="1" vertical={size === 'small' ? false : true} horizontal={size === 'small'} />
            <Tooltip />
            <XAxis dataKey="key" stroke="rgba(0, 0, 0, 0.40)" tick={{fontSize: 14}} />
            <YAxis stroke="rgba(0, 0, 0, 0.40)" />
            <Bar dataKey="value" unit={unit} name={title} fill="#548CC5" barSize={size === 'small' ? 18 : 40} radius={4} />
          </BarChart>
            </ResponsiveContainer>
        ) : (
          <div style={{width: '100%', height: '205px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <div className="expired__box-img" style={{margin: '0'}}>
                <img src="/assets/no-data.svg" alt="no-data"/>
              </div>
              <p className="no-payment-text">
                There is no Data!
              </p>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default AverageTimeBar;

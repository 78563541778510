import React, {useEffect, useState} from "react";
import { validationEmail } from "../../global/validate";
import axios from "axios";
import "./index.scss";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {useGetTimezonesQuery} from "../../store/builder/inspections.api";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deactivatedStatus, setDeactivatedStatus] = useState(false)
  const [state, setState] = useState({
    email: {
      id: "email",
      value: null,
      errorShow: false,
      error: "Please enter correct email",
    },
    password: {
      id: "password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
  });

  const [eye, setEye] = useState(false);

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      state?.email?.value &&
      validationEmail(state?.email?.value) &&
      state?.password?.value?.length > 4
    ) {
      dispatch(setLoader(true));
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}auth/login`,
          {email: state.email.value, password: state.password.value, isMobile: false},
          config
        )
        .then((res) => {
          dispatch(setLoader(false));
          dispatch(setUser(res.data.data));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          localStorage.setItem("ndx_user_token", res.data.access_token);
          if (!res?.data?.data?.is_corporate) {
            localStorage.setItem("out_of_service", 1);
          }
          if (res?.data?.data?.is_global) {
            localStorage.setItem('current-fbo', res?.data?.data?.agency?.id)
          }
          if (res?.data?.data?.agency?.trial_status === 'equipment') {
            if (res.data?.data?.agency?.is_settings_complete) {
              navigate("/equipment?active=0");
            } else {
              navigate("/fbo-settings");
            }
          } else if (res?.data?.data?.is_corporate) {
            navigate("/corporate-dashboard");
            localStorage.setItem('current-fbo', res?.data?.data?.corporate?.agencies[0]?.id)
          } else {
            navigate("/");
          }
        })
        .catch((err) => {
          dispatch(setLoader(false));
          if (err.response.status == 423) {
            setDeactivatedStatus(true)
          } else {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        });
    } else {
      setState((old) => ({
        ...old,
        email: { ...old.email, errorShow: true },
        password: { ...old.password, errorShow: true },
      }));
    }
  };

  const renderDeactivated = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head no-bottom">
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivatedStatus(false)}
            />
          </div>
          <div className="modal__content-body">
            <img className="deactivated-img" src="/assets/rafiki.png" alt="bg"/>
            <p className="deactivated">
              The "{state?.email?.value}" has been deactivated and you need to contact NDX to resolve the issue
            </p>
          </div>
          <div className="modal__content-bottom">
            <button className="save full">
              <a target="_blank" href="https://www.ndxcentral.com/#contact">
                Contact NDX
              </a>
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <div className="auth">
      <Helmet>
        <title>Login | NDX</title>
      </Helmet>
      {deactivatedStatus && renderDeactivated()}
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/auth-image.png" alt="bg"/>
        </div>
        <div className="auth__right">
          <div className="auth__content">
            <h1 className="auth__title">Login to getting started</h1>
            <form className="auth__form" onSubmit={submitHandler}>
              <div className="auth__field">
                <label htmlFor="email" className="auth__label">
                  Email
                </label>
                <input
                  id="email"
                  placeholder="Enter email"
                  type="text"
                  className="auth__input"
                  onChange={inputHandler}
                />
                {state?.email?.errorShow && (
                  <span className="auth__error">{state?.email?.error}</span>
                )}
              </div>
              <div className="auth__field">
                <label htmlFor="password" className="auth__label">
                  Password
                </label>
                <input
                  placeholder="Enter password"
                  id="password"
                  type={eye ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={
                    eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
                  }
                  alt="eye"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
                {state?.password?.errorShow && (
                  <span className="auth__error">{state?.password?.error}</span>
                )}
              </div>
              <div className="auth__links">
                <div className="remember">
                  <div className="auth__checkbox">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember me</label>
                  </div>
                </div>
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
              <button className="auth__submit">Login</button>
              <div className="auth__other">
                <span>Don’t have an account?</span>
                <a href="https://www.ndxcentral.com/#contact" target="_blank">Sign up</a>
              </div>
              <div className="auth__terms">
                <Link to="/terms">Terms of use</Link>
                <Link to="/terms">Privacy policy</Link>
                {/*<Link to="/terms">Contact us</Link>*/}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

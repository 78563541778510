import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  agencies: [],
};

const agenciesListSlice = createSlice({
  name: "agencyList",
  initialState,
  reducers: {
    setAgenciesList(state, action) {
      state.agencies = action.payload;
    },
  },
});

export const {
  setAgenciesList,
} = agenciesListSlice.actions;

export default agenciesListSlice.reducer;

import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import {
  useDeleteInspectionMutation,
  useGetInspectionQuery,
  useLazyGetInspectionsPaginationQuery
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import Modal from "../../components/Modal";
import {useDrop} from "react-dnd";
import './index.scss'
import {useErrorHandler} from '../../global/ErrorsHandler';
import {Helmet} from 'react-helmet';
import CustomPaginate from "../../components/PaginateComponent";
import Trash from "../../SVG/Trash";
import {toast} from "react-toastify";

export default function InspectionsForm() {
  const [createModalStatus, setCreateModalStatus] = useState(false)
  const [search, setSearch] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [chosenInspection, setChosenInspection] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {data, isError, isFetching, error, isSuccess} = useGetInspectionQuery()

  const [getInspectionsPagination, {
    data: inspectionsPaginationData,
    isError: inspectionsPaginationIsError,
    isFetching: inspectionsPaginationIsLoading,
    error: inspectionsPaginationError,
    isSuccess: inspectionsPaginationIsSuccess
  }] = useLazyGetInspectionsPaginationQuery();

  const [deleteInspection, {
    data: deleteInspectionData,
    isError: deleteInspectionIsError,
    isLoading: deleteInspectionIsLoading,
    error: deleteInspectionError,
    isSuccess: deleteInspectionIsSuccess
  }] = useDeleteInspectionMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([error, deleteInspectionError, inspectionsPaginationError])

  useEffect(() => {
    if (createModalStatus || confirmModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [createModalStatus, confirmModal])

  useEffect(() => {
    getInspectionsPagination(currentPage)
  }, [])
  useEffect(() => {
    dispatch(setLoader(inspectionsPaginationIsLoading))
  }, [inspectionsPaginationIsLoading])

  useEffect(() => {
    if (deleteInspectionIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }

    if (deleteInspectionIsSuccess) {
      dispatch(setLoader(false))
      setConfirmModal(false)
      toast.success(deleteInspectionData?.message)
    }
  }, [deleteInspectionIsLoading, deleteInspectionIsSuccess])

  const filteredData = inspectionsPaginationData?.data?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.name?.toLowerCase()?.includes(search.toLowerCase());
    }
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    getInspectionsPagination({ page: page.selected + 1 });
    window.scrollTo(0, 0);
  };

  const deleteInspectionS = () => {
    if (chosenInspection) {
      deleteInspection({id: chosenInspection})
    }
  }

  const renderConfirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => {
                setConfirmModal(false)
                setChosenInspection(null)
              }}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to delete this inspection?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setConfirmModal(false)
              setChosenInspection(null)
            }}>
              Close
            </button>
            <button className="save" onClick={deleteInspectionS}>
              Yes
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }


  return (
    <Wrapper>
      <Helmet>
        <title>Inspections Form | NDX</title>
      </Helmet>
      {createModalStatus && <Modal type={"inspection-form"} title={'Create Inspection'} exit={() => setCreateModalStatus(false)}/>}
      {confirmModal && renderConfirmModal()}
      <DashboardHeader title={'Inspection Form'} showBtn={true} type='inspection-form'
                       action={() => setCreateModalStatus(true)}/>
      <div className="inspections">
        <div className="inspections__filters">
          <Input type='text' placeholder="Search by name" onChange={handleSearch} name="inspectionsSearch"
                 id="inspectionsSearch" image={'/assets/icons/search.svg'} maxwidth={204}/>
        </div>
        <div className="inspectionsForm__box">
          <div className="inspectionsForm__table">
            <div className="inspectionsForm__table-head form">
              <div className="inspectionsForm__table-th">Name</div>
              <div className="inspectionsForm__table-th">Period</div>
              <div className="inspectionsForm__table-th">Type</div>
              <div className="inspectionsForm__table-th"></div>
            </div>
            {
              filteredData?.length ? filteredData?.map(inspection => (
                <div className="inspectionsForm__table-row form" key={inspection.id}>
                  <div className="inspectionsForm__table-bodyTh" onClick={() => navigate('/inspection-form/edit/' + inspection.id)}>{inspection.name}</div>
                  <div className="inspectionsForm__table-bodyTh" onClick={() => navigate('/inspection-form/edit/' + inspection.id)}>{inspection.period.charAt(0).toUpperCase() + inspection.period.slice(1)}</div>
                  <div className="inspectionsForm__table-bodyTh" onClick={() => navigate('/inspection-form/edit/' + inspection.id)}>{inspection.is_for_equipment ? "Equipment" : "Facility"}</div>
                  <div className="inspectionsForm__table-bodyTh" onClick={() => {
                    setConfirmModal(true)
                    setChosenInspection(inspection.id)
                  }}>
                    <Trash color={"#DA6767"} />
                  </div>
                </div>
              )) : (
                <div className='emptyList'>
                  <img src="/assets/empty-list.png" alt="empty" />
                  <p>No inspection form data yet</p>
                </div>
              )
            }

          </div>
          <CustomPaginate
            pageCount={inspectionsPaginationData?.pages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Wrapper>
  );
};


import React from 'react';

const Report = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5C3 2.79086 4.79086 1 7 1H15.3431C16.404 1 17.4214 1.42143 18.1716 2.17157L19.8284 3.82843C20.5786 4.57857 21 5.59599 21 6.65685V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5ZM19 8V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14V5C14 6.65685 15.3431 8 17 8H19ZM18.8891 6C18.7909 5.7176 18.6296 5.45808 18.4142 5.24264L16.7574 3.58579C16.5419 3.37035 16.2824 3.20914 16 3.11094V5C16 5.55228 16.4477 6 17 6H18.8891Z" fill={color} />
      <path d="M10 1C9.44771 1 9 1.44772 9 2V15H4C3.44772 15 3 15.4477 3 16C3 16.5523 3.44772 17 4 17H9V22C9 22.5523 9.44771 23 10 23C10.5523 23 11 22.5523 11 22V17H20C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15H11V2C11 1.44772 10.5523 1 10 1Z" fill={color} />
    </svg>
  );
};

export default Report;
import React from 'react';

const CorporateIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 18V11C20 9.89543 19.1046 9 18 9H6C4.89543 9 4 9.89543 4 11V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18ZM6 7C3.79086 7 2 8.79086 2 11V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V11C22 8.79086 20.2091 7 18 7H6Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 4C8.44772 4 8 4.44772 8 5V8C8 8.55228 7.55228 9 7 9C6.44772 9 6 8.55228 6 8V5C6 3.34315 7.34315 2 9 2H15C16.6569 2 18 3.34315 18 5V8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8V5C16 4.44772 15.5523 4 15 4H9Z" fill={color} />
    </svg>
  );
};

export default CorporateIcon;
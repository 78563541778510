import React from "react";
import {PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend} from "recharts";
import './index.scss';
import {formatToCamelCase} from "../../../global/utils";


const CustomPieChart = ({data, total, persentage, isPercent = false, legend, width = '', height = '', level = '', legendHeight = ''}) => {

  const renderLegend = () => {
    return (
      <div className={`pie__legend ${legendHeight}`}>
        {
          data?.map((entry, index) => (
            entry.key && (
              entry.color && (
                <div className="pie__legend-elem" key={`item-${index}`}>
                  {entry.key && (
                    <div className="pie__legend-left">
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="10" height="10" rx="5" fill={entry.color}/>
                      </svg>
                      <p className="pie__legend-name">{entry?.key ? formatToCamelCase(entry?.key) : entry?.key}</p>
                    </div>
                  )}
                  <div className="pie__legend-right">
                    {entry.value}
                  </div>
                </div>
              )
            )
          ))
        }
      </div>
    );
  }

  return (
    <div className={`pie ${legend ? 'legend' : ''} ${height ? 'height-shortened' : ''} ${width ? `${width}` : ''}`}>
      <div className={`pie__number ${legend ? 'legend' : ''} ${level ? level : 'legend'}`}>
        {total ? total : '0'} {total ? isPercent ? '%' : '' : ''}
        {persentage && <span className="pie__number-total">Total</span>}
      </div>
      <ResponsiveContainer>
        <PieChart width={290} height={290}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={10}
            dataKey="value"
            cornerRadius={3}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry?.color} />
            ))}
          </Pie>
          {
            legend && <Legend content={renderLegend} align="right" />
          }
        </PieChart>
      </ResponsiveContainer>

    </div>

  );
};

export default CustomPieChart;
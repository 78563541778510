import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import CustomPieChart from "../CustomPieChart";

const TotalPieChart = ( {data} ) => {
  const [inService, setInService] = useState({});
  const [outOfService, setOutOfService] = useState({});

  const [colors, setColors] = useState([
    "#548CC5",
    "#2AA58D",
    "#DF9800",
    "#E9EAEB",
  ])
  const [totalCount, setTotalCount] = useState(0);
  const [pieData, setPieData] = useState([])
  useEffect(() => {
    if (data) {
      let count = 0;
      let newPieData = [...data?.data]
      const index = newPieData.findIndex(item => item.key === "Out of service(Current)");
      if (index !== -1) {
        const outOfServiceItem = newPieData.splice(index, 1)[0];
        newPieData.unshift(outOfServiceItem);
      }
      data?.data?.map(item => {
        if(item.key === 'In service') {
          setInService(item);
        } else if(item.key === 'Out of service(Current)') {
          setOutOfService(item);
        }
        count += item.value;
      })
      setTotalCount(count);

      for (let i = 0; i <= newPieData.length; i++) {
        for (let j = 0; j <= colors.length; j++) {
          if (i === j) {
            newPieData[i] = {...newPieData[i], color: colors[j]}
          }
        }
      }
      setPieData(newPieData)
    }
  }, [data]);

  return (
    <div className="chart mid-dashboard mid">
      <div className="chart__header">
        <p className="chart__header-title">Equipment Overview</p>
        {/*<Select*/}
        {/*  placeholder={"Month"}*/}
        {/*  styles={{*/}
        {/*    control: (baseStyles, state) => ({*/}
        {/*      ...baseStyles,*/}
        {/*      borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",*/}
        {/*      borderRadius: 8,*/}
        {/*      width: 90,*/}
        {/*      height: 30,*/}
        {/*      fontSize: 14,*/}
        {/*      backgroundColor: "#FFFFFF",*/}
        {/*      color: "#718096",*/}
        {/*    }),*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
      {
        totalCount !== 0 ?
          <div className="chart__box total-pie" style={{ height: '90%'}}>
            <CustomPieChart width={'mx-200'} level={'lower-more'} data={pieData} total={totalCount} persentage={true} height={'180px'} />
            <div className="chart__total">
              <div className="chart__total-head">
                <div className="chart__total-elem green">
                  <div className="chart__total-left">
                    <p className="chart__total-title">In service</p>
                    <p className="chart__total-count"><span>.</span>{inService?.value || 0}</p>
                  </div>
                  <div className="chart__total-right">
                    {/*<img src="/assets/icons/warning.svg" alt="warning"/>*/}
                  </div>
                </div>
                <div className="chart__total-elem blue">
                  <div className="chart__total-left">
                    <p className="chart__total-title">Out of Service(Current)</p>
                    <p className="chart__total-count"><span>.</span>{outOfService?.value || 0}</p>
                  </div>
                  <div className="chart__total-right">
                    {/*<img src="/assets/icons/warning.svg" alt="warning"/>*/}
                  </div>
                </div>
              </div>
              <div className="chart__total-elem yellow">
                <div className="chart__total-left">
                  <div className="chart__total-left-inner">
                    <img className="chart__total-nfc" src="/assets/icons/nfc.svg" alt="nfc"/>
                    <p className="chart__total-count">{data?.nfc_enabled}</p>
                  </div>

                  <p className="chart__total-title">Percent of equipment enabled with NFC tags</p>
                </div>
                <div className="chart__total-right">
                  {/*<img src="/assets/icons/warning.svg" alt="warning"/>*/}
                </div>
              </div>
            </div>
          </div>
          :
          <div style={{width: '100%', height: '303px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <div className="expired__box-img" style={{margin: '0'}}>
                <img src="/assets/no-data.svg" alt="no-data"/>
              </div>
              <p className="no-payment-text">
                There is no Data!
              </p>
            </div>
          </div>
      }
    </div>
  );
};

export default TotalPieChart;
import React from 'react';
import {Stripe} from "../../components/Stripe";

export default function Terms () {
  return (
    <div className="payment">
      <div className="payment__header">
        <p className="payment__header-title">Terms</p>
      </div>
      <div className="payment__body">
        Terms
        <br/>
        <br/>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias aperiam consectetur consequuntur, dolores earum ipsum nihil quia similique sint.
        <br/>
        <br/>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        A aspernatur assumenda consequuntur cum eligendi maiores optio quam, soluta tenetur voluptatibus.
        Ab architecto consequatur deleniti dolore eum labore laboriosam libero nam non nostrum pariatur
        quasi quibusdam quisquam, repellat sint suscipit tenetur.
        <br/>
        <br/>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias asperiores delectus dolorem facilis
        fugit inventore laboriosam laborum maxime minus nesciunt nulla quas quo temporibus vel velit vitae, voluptate.
        Aspernatur atque consectetur enim hic non quis recusandae rerum sapiente vitae voluptas! Doloremque eum iste
        molestiae molestias similique! Amet, quas, repellat.
      </div>
    </div>
  );
};


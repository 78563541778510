import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment/moment";
import Coin from "../../SVG/Coin";
import Cancel from "../../SVG/Cancel";
import Trash from "../../SVG/Trash";
import AssignWithCorporate from "../AssignCorporate";
import Plus from "../../SVG/Plus";
import UnAssignIcon from "../../SVG/UnAssignIcon";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {
  useAgencyDeactivateMutation,
  useAgencySetSubscriptionMutation,
  useClearInspectionsFboMutation,
  useReactivateFboEquipmentsMutation,
  useUnAssignFboMutation
} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {useDispatch} from "react-redux";
import ClearInspection from "../../SVG/ClearInspection";
import RefreshEquipment from "../../SVG/RefreshEquipment";
import SwitchIcon from "../../SVG/SwitchIcon";
import Datepicker from "../Datepicker";
import '../AgencyTableCard/index.scss';

const TrialRow = ( {trial, setCurrentItem, setCreateModalStatus, setStartTrialStatus, setList, setEditPrisingModal, setDeleteModal, getAgencies, currentItem, corporateData, setTurnOffStatus} ) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [unAssignModal, setUnAssignModal] = useState(false);
  const [dangerClearShow, setDangerClearShow] = useState(false);
  const [actionsStatus, setActionsStatus] = useState(false);
  const [reactivateStatus, setReactivateStatus] = useState(false);
  const [setSubscStatus, setSetSubscStatus] = useState(false);
  const [deactivateStatus, setDeactivateStatus] = useState(false);
  const [subscribtionDates, setSubscribtionDates] = useState({
    end_date: null,
    start_date: null,
  });

  const [unAssignAction, {
    data: unAssignActionData,
    error: unAssignActionError,
    isFetching: unAssignActionIsLoading,
    isSuccess: unAssignActionIsSuccess,
    isError: unAssignActionIsError
  }] = useUnAssignFboMutation();

  const [clearInspection, {
    data: clearInspectionData,
    error: clearInspectionError,
    isFetching: clearInspectionIsLoading,
    isSuccess: clearInspectionIsSuccess,
    isError: clearInspectionIsError
  }] = useClearInspectionsFboMutation();

  const [reactivate, {
    data: reactivateData,
    error: reactivateError,
    isFetching: reactivateIsLoading,
    isSuccess: reactivateIsSuccess,
    isError: reactivateIsError
  }] = useReactivateFboEquipmentsMutation();

  const [setSubscription, {
    data: setSubscriptionData,
    error: setSubscriptionError,
    isFetching: setSubscriptionIsLoading,
    isSuccess: setSubscriptionIsSuccess,
    isError: setSubscriptionIsError,
  }] = useAgencySetSubscriptionMutation();

  const [deactivate, {
    data: deactivateData,
    error: deactivateError,
    isFetching: deactivateIsLoading,
    isSuccess: deactivateIsSuccess,
    isError: deactivateIsError
  }] = useAgencyDeactivateMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    unAssignActionError,
    clearInspectionError,
    reactivateError,
    setSubscriptionError,
    deactivateError
  ]);

  useEffect(() => {
    if (assignModal ||
      unAssignModal ||
    dangerClearShow ||
    reactivateStatus ||
    setSubscStatus ||
    deactivateStatus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [assignModal,
    unAssignModal,
    dangerClearShow,
    reactivateStatus,
    setSubscStatus,
    deactivateStatus]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".trial__settings") === null && e.target.closest(".trial__dropdown") === null) {
        setList(old => {
          let data = [...old]
          data?.map(item => {
            item.action_status = false
          })
          return data
        })
      }
    });
  }, [location]);

  useEffect(() => {
    setSubscribtionDates(old => ({...old, start_date: moment(trial.subscription_start_date).format('YYYY-MM-DD'), end_date: moment(trial.subscription_end_date).format('YYYY-MM-DD')}))
  }, [trial])

  const handleSubscribtionSubmit = (e) => {
    e.preventDefault();
    if (subscribtionDates.start_date === 'Invalid date') {
      toast.error('Please select start date');
      return;
    }
    if (subscribtionDates.start_date !== 'Invalid date') {
      dispatch(setLoader(true))
      setSubscription({
        agency_id: trial?.id?.toString(),
        start_date: subscribtionDates.start_date === 'Invalid date' ? '' : moment(subscribtionDates.start_date).format('YYYY-MM-DD'),
        end_date: subscribtionDates.end_date === 'Invalid date' ? '' : moment(subscribtionDates.end_date).format('YYYY-MM-DD')
      })
        .unwrap()
        .then(res => {
          toast.success(res.message);
          setSetSubscStatus(false);
          dispatch(setLoader(false))
          getAgencies();
          setSubscribtionDates({
            end_date: null,
            start_date: null,
          });
        })
        .catch(err => {
          dispatch(setLoader(false))
        });
    } else {
      toast.error('Please select start date');
    }

  }

  const renderUnAssignModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Unassign</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUnAssignModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure want to unassign?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setUnAssignModal(false)
            }}>
              Cancel
            </button>
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              unAssignAction({agency_id: trial?.id})
                .then(res => {
                  dispatch(setLoader(false));
                  toast.success(res.data.message);
                  setUnAssignModal(false);
                  getAgencies();
                })
                .catch(err => {
                  dispatch(setLoader(false));
                  setUnAssignModal(false);
                })
            }}>
              Unassign
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderDangerClear = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to clear all inspections?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerClearShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            If you click "Clear", all inspections for this FBO will be deleted permanently.
            This includes all historical inspections.
            This action cannot be undone, so please make sure that you want to delete all inspections before proceeding.
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              clearInspection({confirmed: true, agency_id: trial?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerClearShow(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setDangerClearShow(false)
                })
            }}>
              Clear
            </button>
            <button className="close almost" onClick={() => {
              setDangerClearShow(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderReactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to reactivate equipment?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setReactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Reactivate" will reactivate on the equipment and make it available for further use. Are you sure you want to reactive the equipment?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              reactivate({confirmed: true, agency_id: trial?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("Equipments successfully reactivated")
                  setReactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setReactivateStatus(false)
                })
            }}>
              Reactivate
            </button>
            <button className="close almost" onClick={() => {
              setReactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderSubscriptionDates = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>
              {
                trial?.subscription_start_date
                 ? "Edit subscription dates"
                  : "Set subscription dates"
              }
            </p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setSetSubscStatus(false)}
            />
          </div>
          <div className="modal__content-body">
            <div className="modal__content-subscriptionDates">
              <div>
                <p className="trial__modal-title">Start date</p>
                <Datepicker
                  width={195}
                  setValue={(e) => setSubscribtionDates(old => ({...old, start_date: moment(e).format('YYYY-MM-DD')}))}
                  value={subscribtionDates?.start_date !== "Invalid date" ? subscribtionDates?.start_date : null}
                />
              </div>
              <div className="divider"></div>
              <div>
                <p className="trial__modal-title">End date</p>
                <Datepicker
                  width={195}
                  setValue={(e) => setSubscribtionDates(old => ({...old, end_date: moment(e).format('YYYY-MM-DD')}))}
                  value={subscribtionDates?.end_date !== "Invalid date" ? subscribtionDates?.end_date : null}
                />
              </div>
            </div>
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setSetSubscStatus(false);
            }}>
              Close
            </button>
            <button className="save" onClick={handleSubscribtionSubmit}>
              Confirm
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>

    )
  }

  const renderDeactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to deactivate this FBO?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Deactivate" will deactivate this FBO and make it unavailable for further use. Are you sure you want to deactivate?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              deactivate(trial?.id)
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("FBO successfully deactivated")
                  setDeactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setReactivateStatus(false)
                })
            }}>
              Deactivate
            </button>
            <button className="close almost" onClick={() => {
              setDeactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <div className="agencyCard">
      {assignModal &&
        <AssignWithCorporate exit={() => setAssignModal(false)} getAgencies={getAgencies} currentItem={currentItem}
                             corporateData={corporateData}/>}
      {unAssignModal && renderUnAssignModal()}
      {dangerClearShow && renderDangerClear()}
      {reactivateStatus && renderReactivate()}
      {setSubscStatus && renderSubscriptionDates()}
      {deactivateStatus && renderDeactivate()}
      {
        trial?.action_status &&
        <div className="trial__dropdown">
          <div className="trial__dropdown-elem" onClick={() => {
            setCurrentItem(trial)
            setSetSubscStatus(true)
          }}>
            <Coin color={"#999999"}/>
            <span>
              {
                trial?.subscription_start_date
                  ? "Edit subscription dates"
                  : "Set subscription dates"
              }
            </span>
          </div>

          <div className="trial__dropdown-elem" onClick={() => navigate(`/fbo/${trial?.id}`)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M7.5 4.20996L12 6.80996L16.5 4.20996" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M7.5 19.79V14.6L3 12" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M21 12L16.5 14.6V19.79" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M12 22.08V12" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              </path>
            </svg>
            <span>FBO Details</span>
          </div>
          <div className="trial__dropdown-elem" onClick={() => {
            setCurrentItem(trial)
            setAssignModal(true)
          }}>
            <Plus color={"#999999"}/>
            <span>{trial?.is_assigned_to_corporate ? "Reassign with Corporate" : "Assign with Corporate"}</span>
          </div>
          {
            trial?.is_assigned_to_corporate &&
            <div className="trial__dropdown-elem" onClick={() => {
              setCurrentItem(trial)
              setUnAssignModal(true)
            }}>
              <UnAssignIcon color={"#999999"}/>
              <span>Unassign</span>
            </div>
          }
          {
            trial?.can_be_cleared &&
            <div className="trial__dropdown-elem" onClick={() => {
              setDangerClearShow(true)
              setActionsStatus(false)
            }}>
              <ClearInspection color="#999999"/>
              <span>Clear inspection</span>
            </div>
          }
          <div className="trial__dropdown-elem" onClick={() => {
            setReactivateStatus(true)
            setActionsStatus(false)
          }}>
            <RefreshEquipment color={"#999999"}/>
            <span>Reactivate Equipment</span>
          </div>

          <div className="trial__dropdown-elem" onClick={() => {
            setCurrentItem(trial);
            setCreateModalStatus(true)
          }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.02276 18.3974L2.74741 15.1365C2.91243 14.3938 3.28581 13.7137 3.82373 13.1758L13.6717 3.32782C15.2338 1.76573 17.7664 1.76572 19.3285 3.32782L20.6717 4.67097C22.2338 6.23307 22.2338 8.76572 20.6717 10.3278L10.8237 20.1758C10.2858 20.7137 9.60564 21.087 8.86302 21.2521L5.60211 21.9767C3.45862 22.4531 1.54643 20.5409 2.02276 18.3974ZM4.69978 15.5703L3.97514 18.8312C3.81636 19.5457 4.45375 20.1831 5.16825 20.0243L8.42916 19.2997C8.78626 19.2203 9.11448 19.0446 9.37833 18.7921L5.20743 14.6212C4.95485 14.885 4.77914 15.2132 4.69978 15.5703ZM6.62131 13.2066L10.7929 17.3782L16.3787 11.7924L12.2071 7.62082L6.62131 13.2066ZM19.2575 8.91361L17.7929 10.3782L13.6213 6.2066L15.0859 4.74204C15.8669 3.96099 17.1333 3.96099 17.9143 4.74203L19.2575 6.08518C20.0385 6.86623 20.0385 8.13256 19.2575 8.91361Z"
                    fill="#999999">
              </path>
            </svg>
            <span>Edit FBO</span>
          </div>
          <div className="trial__dropdown-elem" onClick={() => {
            if (trial?.inspection_enabled) {
              setTurnOffStatus(true);
              setCurrentItem(trial);
            } else {
              setStartTrialStatus(true);
              setCurrentItem(trial);
            }
          }}>
            <SwitchIcon color={'#999999'}/>
            <span>Turn {trial?.inspection_enabled ? "OFF" : "ON"} inspections</span>
          </div>
          <div className="trial__dropdown-elem" onClick={() => setDeactivateStatus(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.92993 4.92993L19.0699 19.0699" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
            <span>Deactivate</span>
          </div>

          <div className="trial__dropdown-elem" onClick={() => {
            setCurrentItem(trial);
            setDeleteModal(true)
          }}>
            <Trash color={"#DA6767"}/>
            <span>Delete FBO</span>
          </div>
        </div>
      }
      <div className="agencyCard__left">
        <div className="agencyCard__left-agency">
          <div className="agencyCard__left-photo">
            <img className="agencyCard__left-logo" src={trial.logo} alt="logo"/>
          </div>
          <p className="agencyCard__left-id">#{trial?.id}</p>
        </div>
        <div className="agencyCard__left-info">
          <div className="agencyCard__left-text">
            <p className="agencyCard__left-name">{trial?.title}</p>
            <p className="agencyCard__left-airport">
              Airport ID: <span>{trial?.airport_id}</span>
            </p>
            <p className="agencyCard__left-airport">
              Corporate: <span>{trial?.corporate ? trial?.corporate : 'No corporate'}</span>
            </p>
          </div>

        </div>
      </div>
      <div className="agencyCard__mid border">
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Inspection status:</p>
          <span
            className={`agencyCard__mid-status ${trial?.inspection_enabled ? 'on' : 'off'}`}>{trial?.inspection_enabled ? 'on' : 'off'}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Inspections start date:</p>
          <span
            className="agencyCard__mid-subtext">{trial?.inspection_start_date ? moment(trial?.inspection_start_date).format('D MMMM YYYY') : '---'}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Subscription:</p>
          <span
            className="agencyCard__mid-subtext">{trial?.subscription_start_date ? moment(trial?.subscription_start_date).format('MM/DD/YYYY') : '-'} - {trial?.subscription_end_date ? moment(trial?.subscription_end_date).format('MM/DD/YYYY') : '-'}</span>
        </div>
      </div>
      <div className="agencyCard__mid">
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Timezone:</p>
          <span className="agencyCard__mid-subtext">{trial?.timezone ? trial?.timezone : "---"}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Facilities enabled:</p>
          <span
            className="agencyCard__mid-subtext">{trial?.facilities_enabled ? trial?.facilities_enabled : "---"}</span>
        </div>
      </div>
      <div className="agencyCard__action trial__settings new">
        <img className="agencyCard__action-btn" src="/assets/icons/dots.svg" alt="dots"
             onClick={() => {
               setList(old => {
                 let data = [...old]
                 data?.map(item => {
                   if (item?.id === trial?.id) {
                     if (item.action_status) {
                       item.action_status = false
                     } else {
                       item.action_status = true
                     }
                   } else {
                     item.action_status = false
                   }
                 })
                 return data
               })
             }}
        />
      </div>
    </div>
  );
};

export default TrialRow;
import React from 'react';

const CircleLockIcon = ({active}) => {
  return (
    // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
    //
    //   <path d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z" stroke={active ? "#464646" : "#FFFFFF"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    //   <path d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11" stroke={active ? "#464646" : "#FFFFFF"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 23C22.2274 23 20.6484 23.0646 19.3584 23.1466C18.1347 23.2243 17.1938 24.158 17.106 25.373C17.0435 26.2373 17 27.1481 17 28C17 28.8519 17.0435 29.7627 17.106 30.627C17.1938 31.842 18.1347 32.7757 19.3584 32.8534C20.6484 32.9354 22.2274 33 24 33C25.7726 33 27.3516 32.9354 28.6416 32.8534C29.8653 32.7757 30.8062 31.842 30.894 30.627C30.9565 29.7627 31 28.8519 31 28C31 27.1481 30.9565 26.2373 30.894 25.373C30.8062 24.158 29.8653 23.2243 28.6416 23.1466C27.3516 23.0646 25.7726 23 24 23ZM19.2315 21.1506C17.0138 21.2916 15.2714 23.0124 15.1112 25.2288C15.0465 26.1234 15 27.085 15 28C15 28.915 15.0465 29.8766 15.1112 30.7712C15.2714 32.9876 17.0138 34.7084 19.2315 34.8494C20.5578 34.9337 22.1795 35 24 35C25.8205 35 27.4422 34.9337 28.7685 34.8494C30.9862 34.7084 32.7286 32.9876 32.8888 30.7712C32.9535 29.8766 33 28.915 33 28C33 27.085 32.9535 26.1234 32.8888 25.2288C32.7286 23.0124 30.9862 21.2916 28.7685 21.1506C27.4422 21.0663 25.8205 21 24 21C22.1795 21 20.5578 21.0663 19.2315 21.1506Z" fill={active ? "#464646" : "#FFFFFF"}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25 28.7324C25.5978 28.3866 26 27.7403 26 27C26 25.8954 25.1046 25 24 25C22.8954 25 22 25.8954 22 27C22 27.7403 22.4022 28.3866 23 28.7324V30C23 30.5523 23.4477 31 24 31C24.5523 31 25 30.5523 25 30V28.7324Z" fill={active ? "#464646" : "#FFFFFF"}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 18C19 15.2386 21.2386 13 24 13C26.7614 13 29 15.2386 29 18V22C29 22.5523 28.5523 23 28 23C27.4477 23 27 22.5523 27 22V18C27 16.3431 25.6569 15 24 15C22.3431 15 21 16.3431 21 18V22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22V18Z" fill={active ? "#464646" : "#FFFFFF"}/>
  </svg>
  );
};

export default CircleLockIcon;
import React, {useEffect, useState} from 'react';
import ViewImageModal from "../../CorrectiveActionModal/ViewImageModal";

const ReportHistory = ({exit, data, title}) => {
  const [viewModalStatus, setViewModalStatus] = useState(false);
  const [activeImage, setActiveImage] = useState(null)

  const openViewImage = (cat) => {
    setActiveImage(cat?.fields?.[4]?.photo?.url);
    setViewModalStatus(true);
  }

  return (
    <div className="modal">
      {viewModalStatus && (
        <ViewImageModal
          exit={() => setViewModalStatus(false)}
          activeImage={activeImage}
          images={[activeImage]}
          setActiveImage={setActiveImage}
        />
      )}
      <div className="modal__content reports-modal">
        <div className="modal__content-head">
          <p>{title} history</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <div className="tools__table">
            <div className={`tools__table-head reports-modal`}>
              <div className="tools__table-th">Date of {title}</div>
              <div className="tools__table-th">{title === 'Calibration' ? 'Calibrated' : 'Verified'} by</div>
              <div className="tools__table-th">Comment</div>
              <div className="tools__table-th">Image</div>
            </div>
            <div className="tools__table-body" style={{margin: '0'}}>
              {
                data?.categories?.map((cat, index) => (
                  <div key={index} className="tools__table-row reports-modal">
                    <div className="tools__table-bodyTh">
                      <p className="tools__table-subtitle">{cat?.fields?.[0]?.answers?.[0]}</p>
                    </div>
                    <div className="tools__table-bodyTh">
                      <p className="tools__table-subtitle">{cat?.fields?.[1]?.answers?.[0]}</p>
                    </div>
                    <div className="tools__table-bodyTh longText">
                      <p className="tools__table-subtitle">{cat?.fields?.filter(el => el.label === 'Comment')[0]?.answers[0]}</p>
                    </div>
                    {/*<div className="tools__table-bodyTh" onClick={() => openViewImage(null)}>*/}
                    <div className={`tools__table-bodyTh ${cat?.fields?.[4]?.photo?.url ? 'small-image' : ''}`}>
                      {
                        cat?.fields?.[4]?.photo?.url ? (
                          <div className="tools__table__small-image" onClick={() => openViewImage(cat)}>
                            <img alt="img" className='small-image' src={cat?.fields?.[4]?.photo?.url} />
                          </div>
                        )
                          : <p className="tools__table-subtitle">No image!</p>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="modal__content-bottom">
          <button className="save full" onClick={exit}>
            Got it
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default ReportHistory;
import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import Datepicker from "../Datepicker";
import moment from "moment";
import Checkbox from "../Checkbox";
import {
  useCreateFiltersChangeMutation,
  useCreateFuelQualityRecordMutation, useCreateFuelReceiptInspectionMutation,
  useCreatePeriodInspectionMutation
} from "../../store/builder/inspections.api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import Tooltip from "../Tooltip";
import Select from "react-select";
import log from "../../SVG/Log";

const CreateFiltersChangeModal = ({exit, data, getAgenciesWithEquipment, createFilterType, agenciesWithEquipmentLoading}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [chosenEquipment, setChosenEquipment] = useState(null);
  const [chosenAgency, setChosenAgency] = useState(null);
  const [chosenEquipmentType, setChosenEquipmentType] = useState(null);
  const [chosenEquipmentTypeForSelect, setChosenEquipmentTypeForSelect] = useState(null);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);

  const [createFilters,
    {
      data: createFiltersData,
      isError: createFiltersIsError,
      error: createFiltersError,
      isSuccess: createFiltersIsSuccess,
      isLoading: createFiltersIsLoading,
    },] = useCreateFiltersChangeMutation();

  const [createFuelQuality,
    {
      data: createFuelQualityData,
      isError: createFuelQualityIsError,
      error: createFuelQualityError,
      isSuccess: createFuelQualityIsSuccess,
      isLoading: createFuelQualityIsLoading,
    },] = useCreateFuelQualityRecordMutation();

  const [createFuelReceipt,
    {
      data: createFuelReceiptData,
      isError: createFuelReceiptIsError,
      error: createFuelReceiptError,
      isSuccess: createFuelReceiptIsSuccess,
      isLoading: createFuelReceiptIsLoading,
    },] = useCreateFuelReceiptInspectionMutation();

  useEffect(() => {
    if (agenciesWithEquipmentLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [agenciesWithEquipmentLoading])

  useEffect(() => {
    if (createFilterType) {
      getAgenciesWithEquipment(createFilterType === 'filter_change' ? '103.09B.element_change' : createFilterType === 'fuel_receipt' ? '103.02.fuel' : '103.08A.other')
    }
  }, [createFilterType])

  useEffect(() => {
    if (chosenAgency) {
      setChosenEquipmentType([])
      setChosenEquipment(null)
      setEquipmentOptions([])
      setEquipmentTypeOptions(data.filter(el => el.id === chosenAgency)[0]?.equipment_types)
    }
  }, [chosenAgency])

  useEffect(() => {
    if (chosenEquipmentType) {
      setEquipmentOptions(equipmentTypeOptions?.filter(el => el.id === chosenEquipmentType?.value)[0]?.equipments?.map(el => ({value: el.id, label: el.title})))
      setChosenEquipmentTypeForSelect(data.filter(el => el.id === chosenAgency)[0]?.equipment_types?.filter(el => el.id === chosenEquipmentType)[0])
    } else {
      setChosenEquipmentTypeForSelect("Select Equipment Type")
    }

  }, [chosenEquipmentType])

  const handleAgency = (e) => {
    setChosenAgency(e.target.value)
  }
  const handleEquipmentType = (e) => {
    setChosenEquipmentType(e)
  }

  const handleEquipment = (e) => {
    setChosenEquipment(e)
  }

  const submitChangeFilters = () => {
    if (chosenEquipment?.value) {
      dispatch(setLoader(true))
      if (createFilterType === 'filter_change') {
        createFilters({'equipment_id': chosenEquipment?.value})
          .then((res) => {
            toast.success('Inspections created successfully')
            navigate(`/inspections/${res.data.inspection_id}`)
            dispatch(setLoader(false))
          })
          .catch(e => {
            toast.error('Something went wrong')
            dispatch(setLoader(false))
          })
      } else if (createFilterType === 'fuel_receipt') {
        createFuelReceipt({'equipment_id': chosenEquipment?.value})
          .then((res) => {
            toast.success('Inspections created successfully')
            navigate(`/inspections/${res.data.inspection_id}`)
            dispatch(setLoader(false))
          })
          .catch(e => {
            toast.error('Something went wrong')
            dispatch(setLoader(false))
          })
      } else {
        createFuelQuality({'equipment_id': chosenEquipment?.value})
          .then((res) => {
            toast.success('Inspections created successfully')
            navigate(`/inspections/${res.data.inspection_id}`)
            dispatch(setLoader(false))
          })
          .catch(e => {
            toast.error('Something went wrong')
            dispatch(setLoader(false))
          })
      }

    } else {
      toast.error('Choose equipment!')
    }
  }

  return (
    <div className="modal modal-fbo">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{createFilterType === 'filter_change' ? "Create Filters Change" : createFilterType === 'fuel_receipt' ? "Record of Fuel Receipt by Transport Truck" : "Fuel Quality Test Record"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between", marginBottom: 20}}>
            <CustomSelect
              maxwidth={495}
              id="facility"
              name="facility"
              placeholder="Select facility"
              label={"Facility"}
              options={data?.map(agency => ({value: agency.id, label: agency?.name}))}
              onChange={handleAgency}
              // defaultValue={currentItem ? waterRating.filter(el => el.value == currentItem?.sump_water_rating)[0] : ""}
            />
          </div>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between", marginBottom: 20}}>
            <div className={`customSelect`}>
              <span className="customSelect__label">
                Equipment Type
              </span>
                      <Select options={equipmentTypeOptions?.map(equipmentType => ({value: equipmentType?.id, label: equipmentType?.title}))}
                              styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                          borderRadius: 8,
                          height: 42,
                          backgroundColor: '#F4F5F6',
                          marginTop: 8,
                          width: 495
                        }),
                      }}
                        isMulti={false}
                        value={chosenEquipmentType}
                        // defaultValue={defaultValue ? defaultValue : 'Select...'}
                        name={"equipment_type"}
                        onChange={handleEquipmentType}
                      />
            </div>
          </div>
          <div style={{height: 1, background: "#E9EAEB", marginBottom: 20}}></div>
          <p className="modal__regions-equipmentTitle">Select Equipment</p>
          {
            equipmentOptions?.length > 0 ? (
              // <div className="modal__regions-equipmentList">
              //   {
              //     equipmentOptions?.map((equipment) => (
              //       <div className={`modal__regions-equipmentElem ${chosenEquipment === equipment.id ? 'active' : ''}`} key={equipment?.id} onClick={() => setChosenEquipment(equipment?.id)}>{equipment?.title}</div>
              //     ))
              //   }
              // </div>
                <Select options={equipmentOptions}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                            borderRadius: 8,
                            height: 42,
                            backgroundColor: '#F4F5F6',
                            marginTop: 8,
                            width: 495
                          }),
                        }}
                        isMulti={false}
                        value={chosenEquipment}
                  // defaultValue={defaultValue ? defaultValue : 'Select...'}
                        name={"equipment"}
                        onChange={handleEquipment}
                />
            ) :
              <div className="modal__regions-equipmentEmpty">
                There is no equipment available
              </div>
          }
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Cancel
          </button>
          <button className="save" onClick={submitChangeFilters}>
            Select
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default CreateFiltersChangeModal;
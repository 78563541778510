import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import Input from "../../components/Input";
import Select from "react-select";
import ManagementRow from "../../components/ManagementRow";
import {
  useCreateUserMutation,
  useGetAgencyQuery,
  useGetUsersQuery,
  useGetUsersRolesQuery, useInviteFboMutation, useLazyGetUsersQuery,
} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import CustomSelect from "../../components/CustomSelect";
import { toast } from "react-toastify";
import { validationEmail } from "../../global/validate";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";

export default function UserManagement() {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const currentTabId = localStorage.getItem("current-fbo");
  const [search, setSearch] = useState("");
  const [currentUser, setCurrentUser] = useState(null)
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [rolesOptions, setRolesOptions] = useState([])
  const [userCreateData, setUserCreateData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role_ids: [],
    agency_ids: [],
  });
  const [userInviteData, setUserInviteData] = useState({
    email: "",
    role_id: null,
    agency_id: null
  });

  const [dataErrors, setDataErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    passwordError: false,
    rolesError: false,
    agencyError: false,
  });
  const [chosenRoles, setChosenRoles] = useState([]);
  const [chosenAgency, setChosenAgency] = useState(null);
  const [filteredData, setFilteredData] = useState([])
  const [getUsersData, {data: userData,
    isSuccess: userIsSuccess,
    isFetching: userIsLoading,
    isError: userIsError,
    error: userError}] = useLazyGetUsersQuery();

  const {
    data: agencyData,
    error: agencyError,
    isLoading: agencyIsLoading,
    isSuccess: agencyIsSuccess,
  } = useGetAgencyQuery();

  const {
    data: userRolesData,
    isSuccess: userRolesIsSuccess,
    isLoading: userRolesIsLoading,
    isError: userRolesIsError,
    error: userRolesError,
  } = useGetUsersRolesQuery();

  const [
    createUser,
    {
      data: createUserData,
      isSuccess: createUserIsSuccess,
      isLoading: createUserIsLoading,
      isError: createUserIsError,
      error: createUserError,
    },
  ] = useCreateUserMutation();

  const [invite,
    {
      data: inviteData,
      isLoading: inviteIsLoading,
      isError: inviteIsError,
      error: inviteError,
      isSuccess: inviteIsSuccess,
    },] = useInviteFboMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    createUserError,
    userRolesError,
    userError,
    agencyError,
    inviteError
  ]);

  useEffect(() => {
    if (userRolesIsLoading || agencyIsLoading) {
      dispatch(setLoader(true));
    }
    if (userRolesIsSuccess || agencyIsSuccess) {
      dispatch(setLoader(false));
    }
    if (createUserIsSuccess) {
      setCreateModal(false);
      toast.success(createUserData?.message);
    }
  }, [
    userIsSuccess,
    userIsLoading,
    userRolesIsSuccess,
    userRolesIsLoading,
    createUserIsLoading,
    agencyIsSuccess,
    agencyIsLoading,
  ]);
  useEffect(() => {
    getUsersData(chosenAgency?.value);
  }, [chosenAgency])

  useEffect(() => {
    if (userInfo?.is_corporate) {
      setRolesOptions(userRolesData?.map(role => ({
        value: role.id,
        label: role.name,
      })))
    } else {
      setRolesOptions(userRolesData?.filter(el => el?.key !== 'corporate')?.map(role => ({
        value: role.id,
        label: role.name,
      })))
    }
  }, [userRolesData])

  useEffect(() => {
    if (userIsLoading) {
      dispatch(setLoader(true))
    }
    if (userIsSuccess) {
      dispatch(setLoader(false))
    }
  }, [userIsLoading, userIsSuccess])
  useEffect(() => {
    if (createUserIsLoading) {
      dispatch(setLoader(true));
    }
    if (createUserIsSuccess) {
      dispatch(setLoader(false));
    }
  }, [createUserIsLoading, createUserIsSuccess]);

  useEffect(() => {
    if (createModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [createModal])

  useEffect(() => {
    if (dataErrors.nameError) {
      toast.error("Please enter correct name");
    }
    if (dataErrors.emailError) {
      toast.error("Please enter correct email");
    }
    if (dataErrors.passwordError) {
      toast.error("Please enter correct password and confirm password");
    }
    if (dataErrors.rolesError) {
      toast.error("Please choose any role");
    }
    if (dataErrors.agencyError) {
      toast.error("Please choose any FBO");
    }
  }, [dataErrors]);


  useEffect(() => {
    const filter = userData?.filter((el) => {
      if (search === "") {
        return el
      } else {
        return el?.name?.toLowerCase()?.includes(search.toLowerCase()) || el?.email?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredData(filter?.map(item => ({...item, settings: false})))
  }, [search, userIsSuccess, userData])

  useEffect(() => {
    if (inviteIsLoading) {
      dispatch(setLoader(true));
    }
    if (inviteIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Invite successfully sent!');
      setCreateModal(false);
      setUserInviteData({
        email: "",
        role_id: null,
        agency_id: null
      })
    }
    if (inviteIsError) {
      dispatch(setLoader(false));
      toast.error(inviteError?.data?.email?.[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError]);

  const validateCreate = () => {
    if (!userCreateData?.first_name && userCreateData?.first_name?.length < 3) {
      setDataErrors((old) => ({ ...old, firstNameError: true }));
    } else {
      setDataErrors((old) => ({ ...old, firstNameError: false }));
    }
    if (!userCreateData?.last_name && userCreateData?.last_name?.length < 3) {
      setDataErrors((old) => ({ ...old, lastNameError: true }));
    } else {
      setDataErrors((old) => ({ ...old, lastNameError: false }));
    }
    if (!userCreateData?.email && !validationEmail(userCreateData?.email)) {
      setDataErrors((old) => ({ ...old, emailError: true }));
    } else {
      setDataErrors((old) => ({ ...old, emailError: false }));
    }
    if (userCreateData?.password !== userCreateData?.confirm_password) {
      setDataErrors((old) => ({ ...old, passwordError: true }));
    } else {
      setDataErrors((old) => ({ ...old, passwordError: false }));
    }
    if (chosenRoles?.length === 0) {
      setDataErrors((old) => ({ ...old, rolesError: true }));
    } else {
      setDataErrors((old) => ({ ...old, rolesError: false }));
    }
    if (chosenAgency?.length === 0) {
      setDataErrors((old) => ({ ...old, agencyError: true }));
    } else {
      setDataErrors((old) => ({ ...old, agencyError: false }));
    }
  };

  const handleNewCreateEmail = (e) => {
    setUserInviteData((old) => ({...old, email: e.target.value}))
  }
  const handleNewCreateRole = (e) => {
    setUserInviteData((old) => ({...old, role_id: e.value}))
  }
  const handleNewCreateAgency = (e) => {
    setUserInviteData((old) => ({...old, agency_id: e.value}))
  }

  const submitNewCreate = (e) => {
    e.preventDefault();
    if (!validationEmail(userInviteData?.email)) {
      toast.error('Please enter a correct email');
      return;
    }
    if (!userInviteData?.role_id) {
      toast.error('Please choose a role');
      return
    }
    if (userInfo?.is_global && !userInviteData?.agency_id) {
      toast.error('Please choose FBO');
      return
    }
    if (userInfo?.is_corporate) {
      if (userInviteData?.role_id !== 8) {
        if (!userInviteData?.agency_id) {
          toast.error('Please choose FBO');
          return
        }
      }
    }

    if (userInfo?.is_global) {
        invite({email: userInviteData?.email, role_id: userInviteData?.role_id, agency_id: userInviteData?.agency_id})
    }
    else if (userInfo?.is_corporate) {
      if (userInviteData?.role_id === 8) {
        invite({email: userInviteData?.email, role_id: userInviteData?.role_id})
      } else {
        invite({email: userInviteData?.email, role_id: userInviteData?.role_id, agency_id: userInviteData?.agency_id})
      }
    } else {
      if (userInviteData?.role_id === 8) {
        invite({email: userInviteData?.email, role_id: userInviteData?.role_id})
      } else {
        invite({email: userInviteData?.email, role_id: userInviteData?.role_id, agency_id: Number(currentTabId)})
      }
    }
  }

  const handleCreateChange = (e) => {
    setUserCreateData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };
  const handleRoles = (e) => {
    setChosenRoles(e);
  };

  const handleAgency = (e) => {
    setChosenAgency(e);
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    validateCreate();
    if (
      !dataErrors?.firstNameError &&
      !dataErrors?.lastNameError &&
      !dataErrors?.emailError &&
      !dataErrors?.rolesError &&
      !dataErrors?.passwordError &&
      !dataErrors?.agencyError &&
      userCreateData?.password === userCreateData?.confirm_password
    ) {
      createUser({
        first_name: userCreateData.first_name,
        last_name: userCreateData.last_name,
        email: userCreateData.email,
        password: userCreateData.password,
        role_id: chosenRoles?.value,
        agency_id: chosenAgency?.value,
      });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const renderNewCreateModal = () => {
    return (
      <form className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Invite User</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setCreateModal(false)}
            />
          </div>
          <div className="modal__content-body">
            <Input
              containerWidth={"100%"}
              id="email"
              name="email"
              placeholder="Email"
              label={"Email"}
              type={"email"}
              onChange={handleNewCreateEmail}
              required={true}
            />
            <div className="customSelect">
              <span className="customSelect__label">User Role</span>
              <Select
                options={rolesOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    height: 42,
                    backgroundColor: "#F4F5F6",
                    marginTop: 8,
                  }),
                }}
                name={"role_id"}
                onChange={handleNewCreateRole}
              />
            </div>
            {(userInfo?.is_global) && (
              <div className="customSelect">
                <span className="customSelect__label">FBO</span>
                <Select
                  options={agencyData?.map((role) => ({
                    value: role.id,
                    label: role.title,
                  }))}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                      borderRadius: 8,
                      height: 42,
                      backgroundColor: "#F4F5F6",
                      marginTop: 8,
                    }),
                  }}
                  name={"agency_ids"}
                  onChange={handleNewCreateAgency}
                />
              </div>
            )}
            {
              (userInfo?.is_corporate && userInviteData?.role_id !== 8) &&
              <div className="customSelect">
                <span className="customSelect__label">FBO</span>
                <Select
                  options={agencyData?.map((role) => ({
                    value: role.id,
                    label: role.title,
                  }))}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                      borderRadius: 8,
                      height: 42,
                      backgroundColor: "#F4F5F6",
                      marginTop: 8,
                    }),
                  }}
                  name={"agency_ids"}
                  onChange={handleNewCreateAgency}
                />
              </div>
            }
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setCreateModal(false)}>
              Close
            </button>
            <button className="save" onClick={submitNewCreate}>
              Invite
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </form>
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>User Management | NDX</title>
      </Helmet>
      {createModal && renderNewCreateModal()}
      <DashboardHeader
        title={"User Management"}
        showBtn={true}
        type="management"
        action={() => setCreateModal(true)}
      />
      <div className="management">
        <div className="management__header">
          <Input
            type="text"
            placeholder="Search by name or email"
            image="/assets/icons/search.svg"
            maxwidth={264}
            onChange={handleSearch}
          />
          {
            (userInfo?.is_global || userInfo?.is_corporate) && (
              <Select styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                  borderRadius: 8,
                  width: 200,
                  height: 42,
                  backgroundColor: '#F4F5F6'
                }),
              }}
                      isClearable={true}
                      options={agencyData?.map((role) => ({
                        value: role.id,
                        label: role.title,
                      }))}
                      onChange={handleAgency}
                      placeholder={"By FBO"}
              />
            )
          }
        </div>
        <div className="management__box">
          <div className="management__table">
            <div className="management__table-head">
              <div className="management__table-th">Profile Picture</div>
              <div className="management__table-th">Name</div>
              <div className="management__table-th">Email</div>
              <div className="management__table-th">FBO</div>
              <div className="management__table-th">Role</div>
              <div className="management__table-th">Ein</div>
              <div className="management__table-th">Initials</div>
              <div className="management__table-th">Signature</div>
              <div className="management__table-th"></div>
            </div>
            <div className="management__table-rows">
              {
                userIsLoading ?
                  <div className="skeleton__box">
                    <div className="skeleton__row"></div>
                    <div className="skeleton__row"></div>
                    <div className="skeleton__row"></div>
                    <div className="skeleton__row"></div>
                  </div>
                  :
                filteredData?.length ?
                filteredData?.map((user, index) => (
                <ManagementRow
                  key={user.id}
                  user={user}
                  userRolesData={userRolesData}
                  userAgencyData={agencyData}
                  lengthArray={filteredData?.length}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  indexElem={index}
                  setCurrentUser={setCurrentUser}
                  currentUser={currentUser}
                />
              )) : (
                <div className='emptyList'>
                  <img src="/assets/empty-list.png" alt="empty" />
                  <p>No management data yet</p>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

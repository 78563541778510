import React from 'react';
import Select from "react-select";
import moment from "moment";

const MetricsTable = React.memo((
  {showSelect,
    showSecondSelect,
    secondOptions,
    secondSelectTitle,
    secondSelectDefault = null,
    handleSecondSelect,
    title,
    width,
    tableHeight,
    rowHeight,
    cols,
    headers,
    body,
    options,
    handleSelect,
    selectTitle,
    isClearableSelect = false,
    rowAction,
    rowPointer = false,
  }
) => {

  function formatString(inputString) {
    let words = inputString.split('_');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  return (
    <div className={`chart ${width}`}>
      <div className="chart__header">
        <p className="chart__header-title">{title}</p>
        <div className="chart__filters">
          {
            showSecondSelect &&
            <Select
              defaultValue={secondSelectDefault}
              options={secondOptions}
              placeholder={secondSelectTitle}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                  borderRadius: 8,
                  width: 250,
                  height: 30,
                  fontSize: 14,
                  backgroundColor: "#FFFFFF",
                  color: "#718096",
                }),
              }}
              isClearable={isClearableSelect}
              onChange={(selected) => {handleSecondSelect(selected)}}
            />
          }
          {
            showSelect &&
            <Select
              // defaultValue={options[0]}
              options={options}
              placeholder={selectTitle}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                  borderRadius: 8,
                  width: 250,
                  height: 30,
                  fontSize: 14,
                  backgroundColor: "#FFFFFF",
                  color: "#718096",
                }),
              }}
              isClearable={isClearableSelect}
              onChange={(selected) => {handleSelect(selected)}}
            />
          }
        </div>
      </div>

      <div className="dashboard__table">
        <div
          className="dashboard__table-head"
          style={{gridTemplateColumns: cols}}
        >
          {
            headers?.map((head, colIndex) => (
              <div key={colIndex} className="dashboard__table-headCell">{head.label}</div>
            ))
          }
        </div>
        <div className={`dashboard__table-body ${tableHeight}`}>
          {
            body?.length > 0 ?
            body?.map((row) => (
              <div
                key={row.id}
                className={`dashboard__table-row ${rowHeight} ${rowPointer ? 'pointer' : ''}`}
                style={{gridTemplateColumns: cols}}
                onClick={() => rowAction(row?.id)}
              >
                {
                  headers?.map((head, colIndex) => (
                    <div key={colIndex} className="dashboard__table-cell">
                      {
                        head.image
                        ?
                          row[head.value] ? <img style={{objectFit: 'contain'}} src="/assets/icons/nfc-mid.png" alt="nfc" /> : <img style={{objectFit: 'contain'}} src="/assets/icons/nfc-inactive.png" alt="nfc" />
                        : <span style={{display: 'flex', alignItems: 'center', gap: 8}}>
                            {row[head.value] !== null && row[head.value] !== '' ?
                            head.is_date ?
                              moment(row[head.value]).format('MM/DD/YY') :
                                head.is_role ? formatString(row[head.value]?.name) :
                                  head.with_avatar ?
                                    <>
                                      <img style={{width: 40, height: 40, objectFit: 'cover', borderRadius: 12, background: '#F2F2F2'}} src={row.avatar} alt="avatar" />
                                      {row[head.value]}
                                    </> :
                                    head.is_uppercase ? formatString(row[head.value]) :
                                      head.is_percent ? row[head.value] + ' %' :
                                      row[head.value]
                            : '---'
                        }</span>
                      }
                    </div>
                  ))
                }
              </div>
            )) :
              <div className='emptyList small'>
                <img src="/assets/no-data.svg" alt="empty" />
                <p className="no-payment-text">There is no data!</p>
              </div>
          }
        </div>
      </div>
    </div>
  );
});

export default MetricsTable;
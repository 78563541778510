import moment from "moment";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "./index.scss";

export default function Datepicker({ value, setValue, type, width, keys }) {
  const [date, setDate] = useState(value ? moment(value)?.toDate() : null);
  useEffect(() => {
    setDate(value ? moment(value)?.toDate() : null)
  }, [value])
  return (
    <div className="c-datepicker" style={{ width: width }}>
      <DatePicker
        selected={date}
        isClearable
        key={keys}
        onChange={(selected) => {
          setValue(selected);
          setDate(selected);
        }}
        placeholderText={
          type === "from"
            ? "Date From"
            : type === "to"
            ? "Date To"
            : "Select Date"
        }
      />
      <span>
        <img src="/assets/icons/calendar.svg" alt="calendar" />
      </span>
    </div>
  );
}

import React, {useState} from 'react';
import './index.scss'
import {Helmet} from "react-helmet";
import {Link, useNavigate} from "react-router-dom";
import {validationEmail} from "../../global/validate";
import {toast} from "react-toastify";
import axios from "axios";
import {setLoader} from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import {useDispatch} from "react-redux";
import {setVerifyRegister, setVerifyRegisterTime} from "../../store/builder/registerSlice";
import log from "../../SVG/Log";

export default function Registration () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [state, setState] = useState({
    first_name: {
      id: "first_name",
      value: null,
      errorShow: false,
      error: "Please enter correct first name (min length 3)",
    },
    last_name: {
      id: "last_name",
      value: null,
      errorShow: false,
      error: "Please enter correct last name (min length 3)",
    },
    email: {
      id: "email",
      value: null,
      errorShow: false,
      error: "Please enter correct email",
    },
    fbo_name: {
      id: "fbo_name",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO name",
    },
    fbo_phone: {
      id: "fbo_phone",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO phone",
    },
    fbo_email: {
      id: "fbo_email",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO email",
    },
    airport_id: {
      id: "airport_id",
      value: null,
      errorShow: false,
      error: "Please enter correct Airport ID (min length 3)",
    },
    password: {
      id: "password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
    terms: {
      id: "terms",
      value: null,
      errorShow: false,
      error: "You must agree to the terms of use",
    },
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);
  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };

  const handleChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      state?.email?.value &&
      validationEmail(state?.email?.value) &&
      state?.fbo_email?.value &&
      validationEmail(state?.fbo_email?.value) &&
      state?.first_name?.value?.length > 2 &&
      state?.last_name?.value?.length > 2 &&
      state?.fbo_name?.value?.length > 2 &&
      phoneNumber?.length > 9 &&
      state?.airport_id?.value?.length > 2 &&
      state?.password?.value?.length > 5 &&
      termsAgree
    ) {
      dispatch(setLoader(true));
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_URL}auth/register`,
          {
            email: state.email.value,
            first_name: state.first_name.value,
            last_name: state.last_name.value,
            fbo_name: state.fbo_name.value,
            fbo_email: state.fbo_email.value,
            fbo_phone: "+1" + phoneNumber?.replace(/\D/g, ''),
            airport_id: state.airport_id.value,
            password: state.password.value,
            password_confirmation: state.password.value,
          },
          config
        )
        .then((res) => {
          dispatch(setLoader(false));
          dispatch(setUser(res?.data?.data));
          localStorage.setItem('token_for_register', res?.data?.email_verify_token)
          dispatch(setVerifyRegister(res?.data?.email_verify_token))
          dispatch(setVerifyRegisterTime(res?.data?.email_verify_token_expires_at))
          toast.success("Code has been sent to your email", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          localStorage.removeItem('ndx_user_token')
          localStorage.setItem("ndx_user_token", res?.data?.access_token);
          setTimeout(() => {
            navigate("/confirm-registration");
          }, 500)
        })
        .catch((err) => {
          dispatch(setLoader(false));
          if (err?.response?.data?.email) {
            toast.error(err?.response?.data?.email[0]);
          }
          if (err?.response?.data?.first_name) {
            toast.error(err?.response?.data?.first_name[0]);
          }
          if (err?.response?.data?.last_name) {
            toast.error(err?.response?.data?.last_name[0]);
          }
          if (err?.response?.data?.fbo_name) {
            toast.error(err?.response?.data?.fbo_name[0]);
          }
          if (err?.response?.data?.fbo_phone) {
            toast.error(err?.response?.data?.fbo_phone[0]);
          }
          if (err?.response?.data?.fbo_phone) {
            toast.error(err?.response?.data?.fbo_phone[0]);
          }
          if (err?.response?.data?.airport_id) {
            toast.error(err?.response?.data?.airport_id[0]);
          }
          if (err?.response?.data?.password) {
            toast.error(err?.response?.data?.password[0]);
          }
        });
    }
    if (!state?.first_name?.value || state?.first_name?.value?.length < 3) {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: true}}))
    } else {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: false}}))
    }

    if (!state?.last_name?.value || state?.last_name?.value?.length < 3) {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: true}}))
    } else {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: false}}))
    }

    if (!validationEmail(state?.email?.value)) {
      setState(old => ({...old, email: {...old.email, errorShow: true}}))
    } else {
      setState(old => ({...old, email: {...old.email, errorShow: false}}))
    }

    if (!state?.fbo_name?.value || state?.fbo_name?.value?.length < 3) {
      setState(old => ({...old, fbo_name: {...old.fbo_name, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_name: {...old.fbo_name, errorShow: false}}))
    }

    if (!validationEmail(state?.fbo_email?.value)) {
      setState(old => ({...old, fbo_email: {...old.fbo_email, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_email: {...old.fbo_email, errorShow: false}}))
    }

    if (!phoneNumber || phoneNumber?.length < 10) {
      setState(old => ({...old, fbo_phone: {...old.fbo_phone, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_phone: {...old.fbo_phone, errorShow: false}}))
    }

    if (!state?.airport_id?.value || state?.airport_id?.value?.length < 3) {
      setState(old => ({...old, airport_id: {...old.airport_id, errorShow: true}}))
    } else {
      setState(old => ({...old, airport_id: {...old.airport_id, errorShow: false}}))
    }

    if (!state?.password?.value || state?.password?.value?.length < 3) {
      setState(old => ({...old, password: {...old.password, errorShow: true}}))
    } else {
      setState(old => ({...old, password: {...old.password, errorShow: false}}))
    }

    if (!termsAgree) {
      setState(old => ({...old, terms: {...old.terms, errorShow: true}}))
    } else {
      setState(old => ({...old, terms: {...old.terms, errorShow: false}}))
    }

  }

    return (
    <div className="auth">
      <Helmet>
        <title>Registration | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/register-bg.png" alt="bg" />
        </div>
        <div className="auth__right s">
          <div className="auth__content regist">
            <h1 className="auth__title">Sign Up</h1>
            <form className="auth__form" autoComplete="off">
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="first_name" className="auth__label">
                    First name
                  </label>
                  <input
                    id="first_name"
                    placeholder="First name"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.first_name?.errorShow && (
                    <span className="auth__error">{state?.first_name?.error}</span>
                  )}
                </div>
                <div className="auth__field">
                  <label htmlFor="last_name" className="auth__label">
                    Last name
                  </label>
                  <input
                    id="last_name"
                    placeholder="Last name"
                    type="text"
                    name={`last_name_${Math.random().toString(36).substring(7)}`}
                    className="auth__input"
                    autoComplete="new-lastname"
                    onChange={inputHandler}
                  />
                  {state?.last_name?.errorShow && (
                    <span className="auth__error">{state?.last_name?.error}</span>
                  )}
                </div>
              </div>
              <div className="auth__field">
                <label htmlFor="email" className="auth__label">
                  Email
                </label>
                <input
                  id="email"
                  placeholder="Email"
                  type="text"
                  className="auth__input"
                  onChange={inputHandler}
                />
                {state?.email?.errorShow && (
                  <span className="auth__error">{state?.email?.error}</span>
                )}
              </div>
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="fbo_name" className="auth__label">
                    FBO Name
                  </label>
                  <input
                    id="fbo_name"
                    placeholder="Enter FBO Name"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.fbo_name?.errorShow && (
                    <span className="auth__error">{state?.fbo_name?.error}</span>
                  )}
                </div>
                <div className="auth__field">
                  <label htmlFor="fbo_email" className="auth__label">
                    FBO Email
                  </label>
                  <input
                    id="fbo_email"
                    placeholder="FBO Email"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.fbo_email?.errorShow && (
                    <span className="auth__error">{state?.fbo_email?.error}</span>
                  )}
                </div>
              </div>
              <div className="auth__box">
                <div className="auth__field phone">
                  <label htmlFor="fbo_phone" className="auth__label">
                    FBO Phone
                  </label>
                  <span className="phone__num">+1</span>
                  <input
                    id="fbo_phone"
                    placeholder="Enter phone number"
                    type="tel"
                    value={phoneNumber}
                    // pattern="\+\d\s\d{3}\s\d{3}-\d{4}"
                    className="auth__input"
                    onChange={handleChange}
                  />
                  {state?.fbo_phone?.errorShow && (
                    <span className="auth__error">{state?.fbo_phone?.error}</span>
                  )}
                </div>
                <div className="auth__field">
                  <label htmlFor="airport_id" className="auth__label">
                    Airport ID
                  </label>
                  <input
                    id="airport_id"
                    placeholder="Enter Airport ID"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.airport_id?.errorShow && (
                    <span className="auth__error">{state?.airport_id?.error}</span>
                  )}
                </div>
              </div>

              <div className="auth__field">
                <label htmlFor="password" className="auth__label">
                  Password
                </label>
                <input
                  placeholder="Enter password"
                  id="password"
                  type={eye ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={
                    eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
                  }
                  alt="eye"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
                {state?.password?.errorShow && (
                  <span className="auth__error">{state?.password?.error}</span>
                )}
              </div>
              {/*<div className="auth__field">*/}
              {/*  <label htmlFor="confirm_password" className="auth__label">*/}
              {/*    Confirm password*/}
              {/*  </label>*/}
              {/*  <input*/}
              {/*    placeholder="Confirm password"*/}
              {/*    id="confirm_password"*/}
              {/*    type={eye ? "text" : "password"}*/}
              {/*    className="auth__input"*/}
              {/*    onChange={inputHandler}*/}
              {/*  />*/}
              {/*  <img*/}
              {/*    className="eye"*/}
              {/*    src={*/}
              {/*      eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"*/}
              {/*    }*/}
              {/*    alt="eye"*/}
              {/*    onClick={() => {*/}
              {/*      setEye(!eye);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*  {state?.password?.errorShow && (*/}
              {/*    <span className="auth__error">{state?.password?.error}</span>*/}
              {/*  )}*/}
              {/*</div>*/}

              <div className="auth__links">
                <div className="remember">
                  <div className="auth__checkbox">
                    <input type="checkbox" id="remember" defaultValue={termsAgree}
                      onClick={(e) => setTermsAgree(e.target.checked)}
                    />
                    <label htmlFor="remember">Agree with Terms of use</label>
                  </div>
                  {state?.terms?.errorShow && (
                    <span className="auth__error">{state?.terms?.error}</span>
                  )}
                </div>
              </div>
              <button className="auth__submit s" onClick={submitHandler}>Sign Up</button>
              <div className="auth__other">
                <span>Already have an account?</span>
                <Link to="/login">Login</Link>
              </div>
              <div className="auth__terms">
                <Link to="/terms">Terms of use</Link>
                <Link to="/terms">Privacy policy</Link>
                {/*<Link to="/terms">Contact us</Link>*/}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};


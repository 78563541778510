import React from "react";
import ReactPaginate from "react-paginate";
import "./index.scss";

export default function CustomPaginate({ pageCount, handlePageClick }) {

  return (
    <ReactPaginate
      containerClassName="c-paginate"
      breakLabel="..."
      nextLabel={<img src="/assets/icons/paginate-arrow.svg" alt="arrow" />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      breakClassName="custom"
      pageCount={pageCount}
      previousLabel={<img src="/assets/icons/paginate-arrow.svg" alt="arrow" />}
      renderOnZeroPageCount={null}
    />
  );
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import Wrapper from "../../components/Wrapper";
import { useLazyGetOrdersListQuery } from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import CustomPaginate from "../../components/PaginateComponent";
import OrderCreateModal from "../../components/Orders/OrderCreateModal";
import {Helmet} from "react-helmet";
import {useErrorHandler} from "../../global/ErrorsHandler";

const optionsByStatus = [
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "processing",
    label: "Processing",
  },
  {
    value: "confirmed",
    label: "Confirmed",
  },
  {
    value: "failed",
    label: "Failed",
  },
];

const optionsBySaleStatus = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "seen",
    label: "Seen",
  },
  {
    value: "processing",
    label: "Processing",
  },
  {
    value: "delivered",
    label: "Delivered",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];

export default function OrdersPage() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(undefined);
  const [requiresAction, setRequiresAction] = useState(undefined);
  const [orderBy, setOrderBy] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [saleStatus, setSaleStatus] = useState(undefined);
  const [createModal, setCreateModal] = useState(false)
  const [
    getOrders,
    {
      data: ordersData,
      error: ordersError,
      isFetching: ordersIsLoading,
      isSuccess: ordersIsSuccess,
      isError: ordersIsError,
    },
  ] = useLazyGetOrdersListQuery();
  const getOrdersList = () => {
    getOrders(`page=${currentPage}`);
  }

  useEffect(() => {
    if (createModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [createModal])

  useEffect(() => {
    getOrders(`page=${currentPage}`);
  }, [currentPage]);

  useEffect(() => {
    if (!ordersData) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [ordersIsLoading, ordersData]);

  const [errorHandler, setErrorHandler] = useErrorHandler([
    ordersError,
  ]);

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    getOrders(`page=${page.selected + 1}`);
    window.scrollTo(0, 0);
  };

  const paramsForApi = () => {
    const params = {
      page: currentPage,
      agency: search,
      status,
      requires_action: requiresAction,
      order_by: orderBy,
      order,
      post_sale_status: saleStatus,
    };
    let paramsString = "";
    const paramsArray = Object.entries(params).forEach((item, index) => {
      if (item[1] !== undefined && item[1] !== "") {
        paramsString += `${index == 0 ? "" : "&"}${item[0]}=${item[1]}`;
      }
    });

    return paramsString;
  };

  const orderBySort = (key) => {
    if (orderBy === key && (order === "desc" || order === undefined)) {
      setOrderBy(key);
      setOrder("asc");
    } else {
      setOrderBy(key);
      setOrder("desc");
    }
  };

  const arrowPos = (key) => {
    return orderBy === key && (order === "desc" || order === undefined) ? (
      <>&#8595;</>
    ) : (
      <>&#8593;</>
    );
  };

  useEffect(() => {
    getOrders(paramsForApi());
  }, [currentPage, search, status, requiresAction, orderBy, order, saleStatus]);

  return (
    <Wrapper>
      <Helmet>
        <title>Orders | NDX</title>
      </Helmet>
      {createModal && <OrderCreateModal exit={() => setCreateModal(false)} getOrdersList={getOrdersList} />}
      <DashboardHeader title={"Orders"} type={"order"} action={() => setCreateModal(true)} />
      <div className="inspections">
        <div className="inspectionsForm__box">
          <div className="inspections__filters">
            <Input
              type="text"
              placeholder="Search by FBO"
              name="ordersSearch"
              id="ordersSearch"
              image={"/assets/icons/search.svg"}
              maxwidth={204}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <div>
              <Select
                // defaultValue={facilityOptions[0]}
                options={optionsByStatus}
                placeholder={"By Status"}
                isClearable
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    width: 240,
                    height: 42,
                    backgroundColor: "#F4F5F6",
                  }),
                }}
                onChange={(selected) => {
                  setStatus(selected?.value);
                }}
              />
            </div>
            <div>
              <Select
                // defaultValue={facilityOptions[0]}
                options={optionsBySaleStatus}
                placeholder={"By Status Sale"}
                isClearable
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    width: 240,
                    height: 42,
                    backgroundColor: "#F4F5F6",
                  }),
                }}
                onChange={(selected) => {
                  setSaleStatus(selected?.value);
                }}
              />
            </div>
          </div>
          <div className="inspectionsForm__table">
            <div className="inspectionsForm__table-head">
              <div
                style={{
                  width: "18%",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("orders.id")}
                  style={{ cursor: "pointer" }}
                >
                  ID {arrowPos("orders.id")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("created_at")}
                  style={{ cursor: "pointer" }}
                >
                  Date {arrowPos("created_at")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("created_at")}
                  style={{ cursor: "pointer" }}
                >
                  End Date {arrowPos("created_at")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("title")}
                  style={{ cursor: "pointer" }}
                >
                  FBO {arrowPos("title")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("status")}
                  style={{ cursor: "pointer" }}
                >
                  Status {arrowPos("status")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("updated_at")}
                  style={{ cursor: " pointer" }}
                >
                  Last updated {arrowPos("updated_at")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("post_sale_status")}
                  style={{ cursor: "pointer" }}
                >
                  Sale Status {arrowPos("post_sale_status")}
                </span>
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="inspectionsForm__table-th"
              >
                <span
                  onClick={() => orderBySort("amount")}
                  style={{ cursor: "pointer" }}
                >
                  Total {arrowPos("amount")}
                </span>
              </div>
            </div>
            {ordersData?.data?.length ? ordersData?.data?.map((item) => (
              <NavLink
                to={`/orders/${item?.id}`}
                className="inspectionsForm__table-row"
                key={item.id}
                // style={{
                //   borderColor: ,
                //   borderWidth: 3,
                // }}
              >
                <div
                  style={{ width: "18%", paddingRight: "0px" }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.id}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {moment(item?.created_at).format("MM/DD/YY")}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.subscription_end_date ?
                    moment(item?.subscription_end_date).format("MM/DD/YY")
                    : <span className="status-new">Expired</span>
                  }
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.agency_title}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  <p className={item?.status}>{item?.status}</p>
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {moment(item?.status_updated_at).format("MM/DD/YY")}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {"  "}
                  <span
                    className={`${
                      item?.post_sale_status === "new"
                        ? "status-new"
                        : item?.post_sale_status === "seen"
                        ? "status-seen"
                        : item?.post_sale_status === "processing"
                        ? "status-processing"
                        : item?.post_sale_status === "canceled"
                        ? "status-canceled"
                        : item?.post_sale_status === "delivered"
                        ? "status-delivered"
                        : ""
                    }`}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {item?.post_sale_status ?? "-"}
                  </span>
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  $ {item?.total}
                </div>
              </NavLink>
            )) : (
              <div className='emptyList'>
                <img src="/assets/empty-list.png" alt="empty" />
                <p>No orders data yet</p>
              </div>
            )}
          </div>
          <CustomPaginate
            pageCount={ordersData?.pages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Wrapper>
  );
}

import React, {useEffect, useState} from 'react';
import moment from "moment";
import SwitchIcon from "../../SVG/SwitchIcon";
import {useLocation, useNavigate} from "react-router-dom";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useAgencyReactivateMutation, useUnAssignFboMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {useDispatch} from "react-redux";
import Trash from "../../SVG/Trash";
import AssignWithCorporate from "../AssignCorporate";
import Plus from "../../SVG/Plus";
import UnAssignIcon from "../../SVG/UnAssignIcon";

const InactiveRow = ({inactive, setList, getAgencies, setCurrentItem, setCreateModalStatus, setDeleteModal, corporateData, currentItem}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [deactivateStatus, setDeactivateStatus] = useState(false);
  const [assignModal, setAssignModal] = useState(false)
  const [unAssignModal, setUnAssignModal] = useState(false)

  const [reactivate, {
    data: deactivateData,
    error: deactivateError,
    isFetching: deactivateIsLoading,
    isSuccess: deactivateIsSuccess,
    isError: deactivateIsError
  }] = useAgencyReactivateMutation();

  const [unAssignAction, {
    data: unAssignActionData,
    error: unAssignActionError,
    isFetching: unAssignActionIsLoading,
    isSuccess: unAssignActionIsSuccess,
    isError: unAssignActionIsError
  }] = useUnAssignFboMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    deactivateError,
    unAssignActionError
  ]);

  useEffect(() => {
    if (deactivateStatus || assignModal || unAssignModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [deactivateStatus, assignModal, unAssignModal]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".inactive__settings") === null && e.target.closest(".inactive__dropdown") === null) {
        setList(old => {
          let data = [...old]
          data?.map(item => {
            item.action_status = false
          })
          return data
        })
      }
    });
  }, [location]);

  const renderDeactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to reactivate this FBO?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivateStatus(false)}
            />
          </div>
          {/*<div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>*/}
          {/*  Clicking "Deactivate" will deactivate this FBO and make it unavailable for further use. Are you sure you want to deactivate?*/}
          {/*</div>*/}
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              reactivate(inactive?.id)
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("FBO successfully reactivated")
                  setDeactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                })
            }}>
              Reactivate
            </button>
            <button className="close almost" onClick={() => {
              setDeactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderUnAssignModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Unassign</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUnAssignModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure want to unassign?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setUnAssignModal(false)
            }}>
              Cancel
            </button>
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              unAssignAction({agency_id: inactive?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setUnAssignModal(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setUnAssignModal(false)
                })
            }}>
              Unassign
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }



  return (
    <div className="inactive__table-row">
      {deactivateStatus && renderDeactivate()}
      {unAssignModal && renderUnAssignModal()}
      {assignModal &&
        <AssignWithCorporate exit={() => setAssignModal(false)} getAgencies={getAgencies} currentItem={currentItem}
                             corporateData={corporateData}/>}
      {
        inactive?.action_status &&
        <div className="inactive__dropdown">
          <div className="inactive__dropdown-elem" onClick={() => navigate(`/fbo/${inactive?.id}`)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M7.5 4.20996L12 6.80996L16.5 4.20996" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M7.5 19.79V14.6L3 12" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M21 12L16.5 14.6V19.79" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M3.27002 6.95996L12 12.01L20.73 6.95996" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"></path>
              <path d="M12 22.08V12" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              </path>
            </svg>
            <span>FBO Details</span>
          </div>

          <div className="inactive__dropdown-elem" onClick={() => {
            setCurrentItem(inactive)
            setAssignModal(true)
          }}>
            <Plus color={"#999999"}/>
            <span>{inactive?.is_assigned_to_corporate ? "Reassign with Corporate" : "Assign with Corporate"}</span>
          </div>

          {
            inactive?.is_assigned_to_corporate &&
            <div className="inactive__dropdown-elem" onClick={() => {
              setCurrentItem(inactive)
              setUnAssignModal(true)
            }}>
              <UnAssignIcon color={"#999999"}/>
              <span>Unassign</span>
            </div>
          }

          <div className="inactive__dropdown-elem" onClick={() => {
            setCurrentItem(inactive);
            setCreateModalStatus(true);
          }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.02276 18.3974L2.74741 15.1365C2.91243 14.3938 3.28581 13.7137 3.82373 13.1758L13.6717 3.32782C15.2338 1.76573 17.7664 1.76572 19.3285 3.32782L20.6717 4.67097C22.2338 6.23307 22.2338 8.76572 20.6717 10.3278L10.8237 20.1758C10.2858 20.7137 9.60564 21.087 8.86302 21.2521L5.60211 21.9767C3.45862 22.4531 1.54643 20.5409 2.02276 18.3974ZM4.69978 15.5703L3.97514 18.8312C3.81636 19.5457 4.45375 20.1831 5.16825 20.0243L8.42916 19.2997C8.78626 19.2203 9.11448 19.0446 9.37833 18.7921L5.20743 14.6212C4.95485 14.885 4.77914 15.2132 4.69978 15.5703ZM6.62131 13.2066L10.7929 17.3782L16.3787 11.7924L12.2071 7.62082L6.62131 13.2066ZM19.2575 8.91361L17.7929 10.3782L13.6213 6.2066L15.0859 4.74204C15.8669 3.96099 17.1333 3.96099 17.9143 4.74203L19.2575 6.08518C20.0385 6.86623 20.0385 8.13256 19.2575 8.91361Z"
                    fill="#999999">
              </path>
            </svg>
            <span>Edit FBO</span>
          </div>

          <div className="inactive__dropdown-elem" onClick={() => {
            setDeactivateStatus(true)
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M22 4L12 14.01L9 11.01" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
            <span>Reactivate</span>
          </div>

          <div className="trial__dropdown-elem" onClick={() => {
            setCurrentItem(inactive);
            setDeleteModal(true)
          }}>
            <Trash color={"#DA6767"}/>
            <span>Delete FBO</span>
          </div>
        </div>
      }
      <div className="inactive__table-bodyTh">
        <span>{inactive?.id}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.title}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.airport_id}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.timezone}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.corporate ? inactive?.corporate : '---'}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <img className="inactive__table-logo" src={inactive?.logo} alt="logo"/>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.trial_first_date ? moment(inactive?.trial_first_date).format('MM/DD/YY') : '---'}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.trial_start_date ? moment(inactive?.trial_start_date).format('MM/DD/YY') : '---'}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <span>{inactive?.inactive_date ? moment(inactive?.inactive_date).format('MM/DD/YY') : '---'}</span>
      </div>
      <div className="inactive__table-bodyTh">
        <img
          src="/assets/icons/dots.svg"
          alt="dots"
          className="inactive__settings"
          onClick={() => {
            setList(old => {
              let data = [...old]
              data.map(item => {
                if (item?.id === inactive?.id) {
                  if (item.action_status) {
                    item.action_status = false
                  } else {
                    item.action_status = true
                  }
                } else {
                  item.action_status = false
                }
              })
              return data
            })
          }}
        />
      </div>
    </div>
  );
};

export default InactiveRow;
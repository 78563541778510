import React from 'react';

const AddComment = ({color}) => {
  return (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5558 5.38889H4.44466C4.06113 5.38889 3.75022 5.6998 3.75022 6.08333V14.4167C3.75022 14.8002 4.06113 15.1111 4.44466 15.1111H15.5558C15.9393 15.1111 16.2502 14.8002 16.2502 14.4167V6.08333C16.2502 5.6998 15.9393 5.38889 15.5558 5.38889ZM4.44466 4C3.29407 4 2.36133 4.93274 2.36133 6.08333V14.4167C2.36133 15.5673 3.29407 16.5 4.44466 16.5H15.5558C16.7064 16.5 17.6391 15.5673 17.6391 14.4167V6.08333C17.6391 4.93274 16.7064 4 15.5558 4H4.44466Z" fill={color}/>
    <path d="M10.0003 9.55566C9.61674 9.55566 9.30583 9.86658 9.30583 10.2501C9.30583 10.6336 9.61674 10.9446 10.0003 10.9446H14.1669C14.5505 10.9446 14.8614 10.6336 14.8614 10.2501C14.8614 9.86658 14.5505 9.55566 14.1669 9.55566H10.0003Z" fill={color} />
    <path d="M5.8336 12.3334C5.45007 12.3334 5.13916 12.6444 5.13916 13.0279C5.13916 13.4114 5.45007 13.7223 5.8336 13.7223H10.0003C10.3838 13.7223 10.6947 13.4114 10.6947 13.0279C10.6947 12.6444 10.3838 12.3334 10.0003 12.3334H5.8336Z" fill={color} />
    <path d="M12.0836 13.0279C12.0836 12.6444 12.3945 12.3334 12.778 12.3334H14.1669C14.5505 12.3334 14.8614 12.6444 14.8614 13.0279C14.8614 13.4114 14.5505 13.7223 14.1669 13.7223H12.778C12.3945 13.7223 12.0836 13.4114 12.0836 13.0279Z" fill={color} />
    <path d="M5.8336 9.55566C5.45007 9.55566 5.13916 9.86658 5.13916 10.2501C5.13916 10.6336 5.45007 10.9446 5.8336 10.9446H7.22249C7.60602 10.9446 7.91694 10.6336 7.91694 10.2501C7.91694 9.86658 7.60602 9.55566 7.22249 9.55566H5.8336Z" fill={color} />
  </svg>

  );
};

export default AddComment;
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  category: {},
  sidebar: true,
};

const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    // addInspectionInfo(state, action) {
    //   state.inspection_title = action.payload.inspection_title;
    // },
    // addSteps(state, action) {
    //   state.steps = action.payload;
    // },
    initCategories(state, action) {
      state.category = action.payload;
    },
    setSidebar(state, action) {
      state.sidebar = action.payload;
    },
    addParentField(state, action) {
      if (state.category.fields.length === 0) {
        state.category.fields = [
          { id: Math.floor(Math.random() * Date.now()) + "", label: null },
        ];
      }
      if (action.payload?.new_form) {
        state.category.fields = [
          ...state.category.fields,
          { id: Math.floor(Math.random() * Date.now()) + "", label: null },
        ];
      }
    },
    changeParentField(state, action) {
      state.category.fields?.map((elem, index) => {
        if (index === action.payload.index) {
          state.category.fields[action.payload.index] = {};
          state.category.fields[action.payload.index] =
            action.payload.field_data;
        }
        return elem;
      });
    },
    removeField(state, action) {
      state.category.fields = state.category.fields?.filter(
        (_, i) => i !== action.payload
      );
    },
  },
});

export const {
  addParentField,
  changeParentField,
  initCategories,
  removeField,
  setSidebar,
} = inspectionSlice.actions;
export default inspectionSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  client_secret: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setSecret(state, action) {
      state.client_secret = action.payload;
    },
  },
});

export const { setSecret } = paymentSlice.actions;
export default paymentSlice.reducer;

import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import {useInviteFboMutation, useLazyGetInvitedAgencyIndexQuery,} from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Helmet } from "react-helmet";

import CustomPaginate from "../../components/PaginateComponent";
import {useDebounce} from "../LogSheets/hooks";
import InvitedAgencyRow from "../../components/InvitedAgencyRow";
import {validationEmail} from "../../global/validate";
import {toast} from "react-toastify";


export default function InvitedFbo() {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const dispatch = useDispatch();
  const [list, setList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const debouncedSearchValue = useDebounce(search, 500);
  const [inviteModal, setInviteModal] = useState(false);
  const [email, setEmail] = useState('');


  const [getInvitedAgency, {
    data: agencyData,
    isLoading: agencyIsLoading,
    isError: agencyIsError,
    error: agencyError,
    isSuccess: agencyIsSuccess,
  }] = useLazyGetInvitedAgencyIndexQuery();


  useEffect(() => {
    getInvitedAgency({search: debouncedSearchValue, page: '1'})
  }, [debouncedSearchValue])


  useEffect(() => {
    if (agencyData) {
      setList(agencyData?.data?.map(item => ({...item, action_status: false})));
    }
  }, [agencyData]);

  useEffect(() => {
    if (!userInfo?.is_global) {
      navigate("/inspections");
    }
  }, [userInfo]);

  useEffect(() => {
    if (agencyIsLoading) {
      dispatch(setLoader(true));
    }
    if (agencyIsError) {
      dispatch(setLoader(false));
    }
    if (agencyIsSuccess) {
      dispatch(setLoader(false));
    }

  }, [agencyIsLoading, agencyIsError, agencyIsSuccess])

  const [invite, {
    isLoading: inviteIsLoading,
    isError: inviteIsError,
    error: inviteError,
    isSuccess: inviteIsSuccess,
  }] = useInviteFboMutation();

  useEffect(() => {
    if (inviteIsLoading) {
      dispatch(setLoader(true));
    }
    if (inviteIsSuccess) {
      getInvitedAgency({page: '1'});
      dispatch(setLoader(false));
      toast.success('Invite successfully sent!');
      setInviteModal(false);
    }
    if (inviteIsError) {
      dispatch(setLoader(false));
      toast.error(inviteError?.data?.email[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError]);

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmitInvite = () => {
    if (validationEmail(email)) {
      dispatch(setLoader(true));
      invite({email: email, role_id: 2})
    } else {
      toast.error('Please enter correct email')
    }
  }

  const handlePageClick = async (page) => {
    dispatch(setLoader(true));
    setCurrentPage(page.selected + 1);
    await getInvitedAgency({page: ++page.selected})
      .catch(err => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        dispatch(setLoader(false));
      })
    window.scrollTo(0, 0);
  };


  return (
    <Wrapper>
      <Helmet>
        <title>FBO | NDX</title>
      </Helmet>

      <DashboardHeader
        title={"Invited FBOs"}
        showBtn={true}
        type="agancy-invited"
        action={() => setInviteModal(true)}
      />
      {
        inviteModal &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Invite FBO</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setInviteModal(false)}
              />
            </div>
            <div className={`modal__content-body`}>
              <Input
                containerWidth={'100%'}
                id="enail"
                name="emal"
                placeholder="Email"
                label={"Email"}
                type={"email"}
                onChange={handleEmail}
                defaultValue=""
              />
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => setInviteModal(false)}>
                Cancel
              </button>
              <button className="save" onClick={handleSubmitInvite}>
                Send Link
              </button>
            </div>
          </div>
          <div className="modal__bg"></div>
        </div>
      }
      <div className="inspections agency">
        <div className="inspections__filters">
          <Input
            type="text"
            placeholder="Search by name"
            name="reportsSearch"
            id="reportsSearch"
            image={"/assets/icons/search.svg"}
            maxwidth={204}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
        <div className="inspectionsForm__box">
          <div className="inspectionsForm__table">
            <div className="inspectionsForm__table-head invited">
              <div
                style={{ width: "50%" }}
                className="inspectionsForm__table-th"
              >
                Email
              </div>
              <div
                style={{ width: "50%", textAlign: "right" }}
                className="inspectionsForm__table-th"
              >Action</div>
            </div>

            {list?.length ? list?.map((item) => (
              <InvitedAgencyRow item={item} key={item?.id} getInvitedAgency={getInvitedAgency} />
            )) : (
              <div className='emptyList'>
                <img src="/assets/empty-list.png" alt="empty" />
                <p>No FBO data yet</p>
              </div>
            )}
          </div>
          {
            list?.length ?
            <CustomPaginate
              pageCount={agencyData?.pages}
              handlePageClick={handlePageClick}
            />
               : null
          }
        </div>
      </div>
    </Wrapper>
  );
}

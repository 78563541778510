import moment from "moment";
import React from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Tooltip from "../../Tooltip";

export default function DateField({ id, label, setFields, field, tooltip = '' }) {
  const [currentDate, setCurrentDate] = useState(field?.value ? moment(field?.value).toDate() : null);

  return (
    <div className="equipment__container">
      <div className="equipment__date">
      <span className="customSelect__label">
        <div className="title">{label}</div>
          {tooltip && <Tooltip tooltipText={tooltip}/>}
      </span>
        <DatePicker
          selected={currentDate}
          onChange={(date) => {
            setFields((old) => {
              setCurrentDate(date)
              let data = [...old.map(item => {
                let newArray = {...item}
                if (newArray?.key === id) {
                  newArray.value = moment(date).format("YYYY-MM-DD")
                }
                return newArray
              })];
              return data
            })
          }}
        />
      </div>
    </div>
  );
}

import React, {useEffect, useState} from "react";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {setFields} from "../../store/builder/reducer";
import Tooltip from "../Tooltip/index";

export default function Input({
  type = "text",
  placeholder = "",
  name,
  id,
  image = "",
  maxwidth,
  label,
  onChange,
  onInput,
  defaultValue,
  value,
  required,
  minLength,
  disabled,
  readOnly,
  innerRef,
  keyProp,
  tooltip = false,
  tooltipText,
  containerWidth,
  bg
}) {

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <label className={`customInput ${image ? "search" : ""} ${bg === 'white' ? 'new' : ''}`} htmlFor={name} style={{width: containerWidth}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {label && <span>{label}</span>}
        {tooltip &&
          <Tooltip tooltipText={tooltipText} />
        }
      </div>

      {image && (
        <img className="customInput__search" src={image} alt="search" />
      )}
      {value ? (
        <input
          ref={innerRef}
          style={{ width: maxwidth }}
          type={type}
          name={name}
          id={id}
          key={keyProp}
          placeholder={placeholder}
          onChange={onChange}
          onInput={onInput && onInput}
          defaultValue={defaultValue}
          value={value}
          required={required}
          maxLength={190}
          minLength={minLength}
          autoComplete="off"
          disabled={readOnly ? true : disabled ? true : false}
          onWheel={type === "number" ? numberInputOnWheelPreventChange : null}
        />
      ) : ( 
        <input
          ref={innerRef}
          style={{ width: maxwidth }}
          type={type}
          name={name}
          id={id}
          key={keyProp}
          placeholder={placeholder}
          onChange={onChange}
          onInput={onInput && onInput}
          defaultValue={defaultValue}
          required={required}
          maxLength={190}
          minLength={minLength}
          autoComplete="off"
          disabled={readOnly ? true : disabled ? true : false}
          onWheel={type === "number" ? numberInputOnWheelPreventChange : null}
        />
      )}
    </label>
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {useGetAgencyQuery, useLazyGetAgencyQuery} from "../../store/builder/inspections.api";

import "./index.scss";
import FBOList from "../FBOList";
import log from "../../SVG/Log";

export default function AgencyDropdown({dropdown, setDropdown}) {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(null);
  const [agencyAvatar, setAgencyAvatar] = useState(null);
  const [agencyTitle, setAgencyTitle] = useState(null);
  const [agencyLoaded, setAgencyLoaded] = useState(false)
  const navigate = useNavigate();
  const currentTabId = localStorage.getItem("current-fbo");
  const currentTabTitle = localStorage.getItem("current-fbo-title");
  const sidebarStatus = useSelector(
    (state) => state?.rootReducer?.inspectionSlice?.sidebar
  );
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);

  const {
      data: agencyData,
      isLoading: agencyIsLoading,
      isError: agencyIsError,
      error: agencyError,
      isSuccess: agencyIsSuccess
    } = useGetAgencyQuery();

  useEffect(() => {
    if (agencyIsSuccess) {
      const element = agencyData?.filter(el => el.id == currentTabId)[0]
      setAgencyAvatar(element?.logo)
      setAgencyTitle(element?.title)
    }
  }, [agencyIsSuccess])

  useEffect(() => {
    if (userInfo && agencyIsSuccess) {
      setAgencyLoaded(true)
    }
  }, [agencyIsSuccess, userInfo])

  return (
    <div className={`agency-dropdown ${sidebarStatus ? "" : "min"}`}>
      <div
        onClick={() => {
          sidebarStatus && setDropdown(!dropdown);
        }}
        className="view agency__dropdown-view"
      >
        <div className="left">
          {
            userInfo?.is_corporate ?
              userInfo?.corporate?.agencies?.length > 0 ?
                 <>
                   <img
                     style={{borderRadius: '50%', width: '32px', height: '32px'}}
                     src={`${agencyAvatar ? agencyAvatar : userInfo?.agency?.logo}`}
                     alt="air" />
                   <div>
                     <span className="skeleton-loader fbo-avatar"></span>
                   </div>
                 </>
                :
                null
              :
            agencyLoaded ?
              <img
                style={{
                  borderRadius: '50%',
                }}
                src={`${(userInfo?.is_global) ? agencyAvatar : userInfo?.agency?.logo}`}
                alt="air" />
              :
              <div>
                <span className="skeleton-loader fbo-avatar"></span>
              </div>
          }
          <p>{agencyTitle ? agencyTitle : userInfo?.is_corporate ? userInfo?.corporate?.agencies[0]?.title : userInfo?.agency?.title}</p>
        </div>
        {(userInfo?.is_global || userInfo?.is_corporate) && (
          <div className={`right ${dropdown ? 'active' : ''}`}>
            <img src="/assets/icons/agency-arrow.svg" alt="arrow" />
          </div>
        )}
      </div>
    </div>
  );
}

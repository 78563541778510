import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import CommentsEmptyIcon from "../../SVG/CommentsEmptyIcon";
import moment from "moment";
import {useAddCommentsMutation, useUploadFileMutation} from "../../store/builder/inspections.api";
import {toast} from "react-toastify";
import CommentsEnterIcon from "../../SVG/CommentsEnterIcon";
import CommentsFilesIcon from "../../SVG/CommentsFilesIcon";
import {setFields, setLoader} from "../../store/builder/reducer";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../Loader";
import Gallery from "../Gallery";

const CommentsInspections = ({exit, getCommentsAction, data, inspectionId}) => {
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [filesToSend, setFilesToSend] = useState([]);
  const [galleryStatus, setGalleryStatus] = useState(false);
  const [photosToShow, setPhotosToShow] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [addComment, {data: addCommentData, isLoading: addCommentIsLoading, isError: addCommentIsError, isSuccess: addCommentIsSuccess, error: addCommentError}] = useAddCommentsMutation()
  const [uploadFile, {data: uploadFileData, isSuccess: uploadFileIsSuccess, isLoading: uploadFileIsLoading, isError: uploadFileIsError }] = useUploadFileMutation();
  const commentContainer = useRef();
  const loader = useSelector((state) => state.rootReducer.reducer.loader);

  useEffect(() => {
    getCommentsAction();
  }, [])

  useEffect(() => {
    if (uploadFileIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [uploadFileIsLoading])

  useEffect(() => {
    if (addCommentIsSuccess) {
      getCommentsAction();
      toast.success("Comment successfully added");
      setFilesToSend([])
    }
  }, [addCommentIsLoading, addCommentIsSuccess])

  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4());
  };

  const handleComment = (e) => {
    setCommentText(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (commentText.length > 0) {
      if (filesToSend?.length > 0) {
        const form = new FormData();
        form.append("inspection_id", inspectionId);
        form.append("comment_text", commentText);
        for (let i = 0; i < filesToSend.length; i++) {
          form.append(`files[${i}]`, filesToSend[i]?.uuid);
        }
        addComment(form)
      } else {
        addComment({
          inspection_id: inspectionId,
          comment_text: commentText
        })
      }
      setCommentText('')
    } else {
      toast.error('Comment cannot be empty')
    }
  }

  const handleFiles = (e) => {
    dispatch(setLoader(true))
    if (e.target.files?.length > 10) {
      dispatch(setLoader(false))
      toast.error("The count of files cannot be more than 10")
    } else {
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array?.from(selectedFiles);
      const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      for (let i = 0; i < e.target.files.length; i++) {
        const form = new FormData();
        form.append("file", e.target.files[i]);
        form.append("type", "comment");
        form.append("uuid", guid());
        uploadFile(form)
          .then((res) => {
            setFilesToSend(old => ([...old, res?.data?.data]))
          });
        if (i === e.target.files.length - 1) {
          dispatch(setLoader(false))
        }
      }
    }
  }

  const removeFile = (uuid) => {
    setFilesToSend(old => old?.filter((item) => item.uuid !== uuid))
  }

  return (
    <div className="comments">
      {galleryStatus && <Gallery images={photosToShow} chosenPhoto={currentPhoto} exit={() => setGalleryStatus(false)} />}
      <div className="comments__content">
        <div className="comments__header">
          <p>Comments</p>
          <img src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className={`comments__list ${!data?.length > 0 ? 'center' : ''}`} ref={commentContainer}>
          {
            data?.length > 0 ?
              data?.map(item => (
                <div className="comments__element" key={item?.id}>
                  <div className="comments__element-top">
                    <div className="comments__element-user">
                      <img src={item?.user?.avatar} alt="photo"/>
                      <p>{item?.user?.name}</p>
                    </div>
                    <p className="comments__element-date">
                      {moment(item?.created_at).format('MMMM D') + " at " + moment(item?.created_at).format('h:mm')}
                    </p>
                  </div>
                  <div className="comments__element-content">
                    <p className="comments__element-text">{item?.comment_text}</p>
                    {
                      item?.files?.length > 0 &&
                      <div className="comments__element-files">
                        {
                          item?.files?.map((file, index) => {
                            if (index < 2) {
                              return (
                                <div className="comments__element-file"
                                     key={index}
                                     onMouseEnter={() => setPhotosToShow(item?.files?.map(item => item.url))}
                                     onClick={() => {
                                       setCurrentPhoto(file.url);
                                       setGalleryStatus(true)
                                     }}
                                >
                                  <img src={file?.url} alt="file"/>
                                </div>
                              )
                            }
                          })
                        }
                        {
                          item?.files?.length > 3 ?
                            <div className="comments__element-file"
                                 onMouseEnter={() => setPhotosToShow(item?.files?.map(item => item.url))}
                                 onClick={() => {
                                   setCurrentPhoto(item?.files[0]?.url);
                                   setGalleryStatus(true)
                                 }}
                            >
                              <img src={item?.files[2]?.url} alt="file"/>
                              <div className="items"></div>
                              <p className="items-text">{"+" + (item?.files?.length - 3)}</p>
                            </div>
                            : null
                        }
                      </div>
                    }
                  </div>
                </div>
              ))
              :
              <div className="comments__empty">
                <CommentsEmptyIcon/>
                <p className="comments__empty-title">No comments</p>
                <p className="comments__empty-subtitle">Enter your first comment</p>
              </div>
          }
        </div>
        <form className="comments__bottom" onSubmit={handleSubmit}>
          <p>Comments are displayed in the PDF</p>
          {
            filesToSend?.length > 0 &&
              <div className="comments__files">
                {filesToSend?.map(el => (
                  <div className="comments__files-elem">
                    <div className="comments__files-remove" onClick={() => removeFile(el?.uuid)}>
                      <img src="/assets/icons/file-remove.svg" alt="x"/>
                    </div>
                    <img src={el?.url} alt="some"/>
                  </div>
                ))
                }
              </div>
          }
          <label htmlFor="comment_text" className="comments__input">
            <input
              type="file"
              id="comment_files"
              name="comment_files"
              className={"comments__input-file"}
              multiple={true}
              accept="image/png, image/gif, image/jpeg"
              onChange={handleFiles}
            />
            <input
              type="text"
              id="comment_text"
              placeholder="Enter comment"
              name="comment_text"
              value={commentText}
              onChange={handleComment}
            />
            <div className="comments__input-images">
              <label htmlFor="comment_files">
                <CommentsFilesIcon />
              </label>
              <div onClick={handleSubmit}>
                <CommentsEnterIcon/>
              </div>
            </div>
          </label>
        </form>
      </div>
      <div className="comments__bg"></div>
    </div>
  );
};

export default CommentsInspections;
import React from 'react';

const CommentsFilesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
      <path
        d="M25.014 12.8917L14.2923 23.6134C12.9788 24.9269 11.1974 25.6648 9.33983 25.6648C7.48228 25.6648 5.70081 24.9269 4.38733 23.6134C3.07385 22.2999 2.33594 20.5184 2.33594 18.6609C2.33594 16.8034 3.07385 15.0219 4.38733 13.7084L15.109 2.98674C15.9847 2.11108 17.1723 1.61914 18.4107 1.61914C19.649 1.61914 20.8367 2.11108 21.7123 2.98674C22.588 3.86239 23.0799 5.05004 23.0799 6.2884C23.0799 7.52677 22.588 8.71441 21.7123 9.59007L10.979 20.3117C10.5412 20.7496 9.94735 20.9955 9.32816 20.9955C8.70898 20.9955 8.11516 20.7496 7.67733 20.3117C7.2395 19.8739 6.99353 19.2801 6.99353 18.6609C6.99353 18.0417 7.2395 17.4479 7.67733 17.0101L17.5823 7.11674"
        stroke="#A7AEC1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CommentsFilesIcon;
import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import {toast} from "react-toastify";
import {validationEmail} from "../../global/validate";
import {useInviteFboMutation} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import {useErrorHandler} from "../../global/ErrorsHandler";

const FBOManagementModal = ({exit}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    fbo_name: null,
    email: null,
    has_corporate_settings: null,
  })

  const [invite, {
    isLoading: inviteIsLoading,
    isError: inviteIsError,
    error: inviteError,
    isSuccess: inviteIsSuccess,
  }] = useInviteFboMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([inviteError]);

  useEffect(() => {
    if (inviteIsLoading) {
      dispatch(setLoader(true))
    }
    if (inviteIsSuccess) {
      dispatch(setLoader(false))
      toast.success('Invite successfully sent!');
      exit();
    }
    if (inviteIsError) {
      dispatch(setLoader(false))
      toast.error(inviteError?.data?.email[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError])

  const handleChange = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }

  const submit = (e) => {
    e.preventDefault();
    if (state?.fbo_name?.length > 0 && validationEmail(state?.email) && state?.has_corporate_settings !== null) {
      invite({
        fbo_name: state.fbo_name,
        email: state.email,
        has_corporate_settings: state.has_corporate_settings,
        role_id: 2
      })
    } else {
      toast.error('Fill all fields!')
    }
  }

  return (
    <div className="modal modal-fbo">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Create FBO</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <Input
            containerWidth={'100%'}
            id="fbo_name"
            name="fbo_name"
            placeholder="FBO name"
            label={"FBO name"}
            type={"text"}
            onChange={handleChange}
            // defaultValue={currentItem ? currentItem?.title : ""}
          />
          <Input
            containerWidth={'100%'}
            id="email"
            name="email"
            placeholder="Enter email"
            label={"Admin email"}
            type={"text"}
            onChange={handleChange}
            // defaultValue={currentItem ? currentItem?.title : ""}
          />
          <CustomSelect
            label={"Standardize FBO Settings"}
            name={"has_corporate_settings"}
            onChange={handleChange}
            options={[
              {value: "1", label: "Default settings"},
              {value: "0", label: "Custom settings"}
            ]}
          />
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Close
          </button>
          <button className="save" onClick={submit}>
            Save
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default FBOManagementModal;
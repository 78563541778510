import React, {useEffect, useMemo, useState} from 'react';
import Input from "../../Input";
import Checkbox from "../../Checkbox";
import {useDebounce} from "../../../pages/LogSheets/hooks";
import {useCreateRegionMutation, useDeleteRegionMutation} from "../../../store/builder/inspections.api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setLoader} from "../../../store/builder/reducer";
import {useErrorHandler} from "../../../global/ErrorsHandler";

const CorporateRegionsModal = ({exit, agencies, currentRegion, setCurrentRegion, getRegionsList}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('')
  const [regionName, setRegionName] = useState('')
  const [agenciesList, setAgenciesList] = useState([])
  const [chosenAgencies, setChosenAgencies] = useState([]);
  const [filteredAgencies, setFilteredAgencies] = useState([])
  const debouncedSearchValue = useDebounce(search, 500);

  const [createNewRegion,
    {
      data: createNewRegionData,
      isLoading: createNewRegionIsLoading,
      isError: createNewRegionIsError,
      error: createNewRegionError,
      isSuccess: createNewRegionIsSuccess,
    }] = useCreateRegionMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    createNewRegionError,
  ]);

  useEffect(() => {
    if (createNewRegionIsLoading) {
      dispatch(setLoader(true));
    }
    if (createNewRegionIsSuccess) {
      dispatch(setLoader(false));
      if (currentRegion !== null) {
        getRegionsList()
        toast.success('Region successfully updated!');
        exit()
      } else {
        getRegionsList()
        toast.success('Region successfully created!');
        exit()
      }
    }
  }, [createNewRegionIsLoading])

  useEffect(() => {
    if (agencies?.length > 0) {
      const s = [...agencies]
      setAgenciesList(s.map(item => ({...item, checked: false})));
    }
  }, [agencies])

  useEffect(() => {
    const filter = agenciesList?.filter((el) => {
      if (debouncedSearchValue === "") {
        return el
      } else {
        return el?.title?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredAgencies(filter)
  }, [debouncedSearchValue, agenciesList])

  const updateCheckedStatus = (firstArray, secondArray) => {
    const idSet = new Set(firstArray.map(item => item.id));
    return secondArray.map(item => ({
      ...item,
      checked: idSet.has(item.id)
    }));
  };

  useEffect(() => {
    if (currentRegion !== null) {
      setChosenAgencies(currentRegion?.agencies?.map((item => ({...item, checked: true}))))
      setRegionName(currentRegion?.name)
      setAgenciesList(updateCheckedStatus(currentRegion?.agencies, agencies))
    }
  }, [currentRegion, agencies])

  const selectAgency = ({checked, item}) => {
    if (checked) {
      setChosenAgencies([...chosenAgencies, {...item, checked: true}]);
      setAgenciesList(old => old.map(el => {
        if (el.id === item.id) {
          el.checked = true
        }
        return el
      }))
    } else {
      setChosenAgencies(chosenAgencies.filter(agency => agency.id!== item.id))
      setAgenciesList(old => old.map(el => {
        if (el.id === item.id) {
          el.checked = false
        }
        return el
      }))
    }
  }

  const renderAgenciesList = useMemo(() => {
    return (
      filteredAgencies?.map((item) => (
        <div className="modal__regions-elem" key={item?.id}>
          <Checkbox checked={item?.checked} onChange={(e) => selectAgency({checked: e.target.checked, item: item})} />
          <img className="modal__regions-elemImg" src={item?.logo} alt="icon"/>
          <div className="modal__regions-elemText">
            <p className="modal__regions-elemName">{item?.title}</p>
            <p className="modal__regions-elemId">ID: {item?.id}</p>
          </div>
        </div>
      ))
    )
  }, [filteredAgencies, agenciesList])

  const createRegion = () => {
    if (regionName?.length > 0 && chosenAgencies?.length > 0) {
      if (currentRegion !== null) {
        createNewRegion({
          region_id: currentRegion.id,
          agencies: chosenAgencies?.map(el => el.id),
          name: regionName
        })
      } else {
        createNewRegion({
          agencies: chosenAgencies?.map(el => el.id),
          name: regionName
        })
      }
    }
    if (regionName?.length  === 0) {
      toast.error('Region name is required')
    }
    if (chosenAgencies?.length === 0) {
      toast.error('Please select at least one agency')
    }
  }



  return (
    <div className="modal modal-fbo regions">
      <div className="modal__regions">
        <div className="modal__regions-header">
          <p className="modal__regions-title">{currentRegion !== null ? "Edit Region" : "New Region"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className="modal__regions-body">
          <div className="modal__regions-input">
            <Input
              label={"Region name"}
              onChange={(e) => setRegionName(e.target.value)}
              maxwidth={650}
              defaultValue={currentRegion ? currentRegion?.name : ""}
              placeholder={"Enter region name"} />
          </div>
          <div className="modal__regions-input">
            <p className="modal__regions-inputTitle">FBOs</p>
            {
              chosenAgencies?.length > 0 &&
              <>
                <p className="modal__regions-inputSubTitle">Selected FBOs</p>
                <div className="modal__regions-list selected">
                  {
                    chosenAgencies?.map((item) => (
                      <div className="modal__regions-elem" key={item?.id}>
                        <Checkbox checked={item?.checked} onChange={(e) => selectAgency({checked: e.target.checked, item: item})} />
                        <img className="modal__regions-elemImg" src={item?.logo} alt="icon"/>
                        <div className="modal__regions-elemText">
                          <p className="modal__regions-elemName">{item?.title}</p>
                          <p className="modal__regions-elemId">ID: {item?.id}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </>
            }
            <Input
              name="regionSearch"
              id="regionSearch"
              image={"/assets/icons/search.svg"}
              maxwidth={610}
              placeholder={"Search FBO by name"}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="modal__regions-list">
            {renderAgenciesList}
          </div>
        </div>
        <div className="modal__regions-bottom">
          <div className="modal__regions-btn cancel" onClick={exit}>Cancel</div>
          <div className="modal__regions-btn" onClick={createRegion}>{currentRegion !== null ? "Save" : "Create"}</div>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default CorporateRegionsModal;
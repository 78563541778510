import React from 'react';

const Equipment = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 22C6.55228 22 7 21.5523 7 21H5C5 21.5523 5.44772 22 6 22Z" fill={color}/>
      <path d="M18 22C18.5523 22 19 21.5523 19 21H17C17 21.5523 17.4477 22 18 22Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19 13H5C4.44772 13 4 13.4477 4 14V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V14C20 13.4477 19.5523 13 19 13ZM5 11C3.34315 11 2 12.3431 2 14V18C2 19.6569 3.34315 21 5 21H19C20.6569 21 22 19.6569 22 18V14C22 12.3431 20.6569 11 19 11H5Z" fill={color}/>
      <path d="M17 2C16.4477 2 16 2.44772 16 3V11H18V3C18 2.44772 17.5523 2 17 2Z" fill={color}/>
      <path d="M7 6C6.44772 6 6 6.44772 6 7V11H8V7C8 6.44772 7.55228 6 7 6Z" fill={color}/>
      <path d="M7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17C7.55228 17 8 16.5523 8 16C8 15.4477 7.55228 15 7 15Z" fill={color}/>
      <path d="M11 15C10.4477 15 10 15.4477 10 16C10 16.5523 10.4477 17 11 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15H11Z" fill={color}/>
    </svg>
  );
};

export default Equipment;
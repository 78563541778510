import React from 'react';

const Log = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3C4.79086 3 3 4.79086 3 7V19C3 21.2091 4.79086 23 7 23H17C19.2091 23 21 21.2091 21 19V7C21 4.79086 19.2091 3 17 3V5C18.1046 5 19 5.89543 19 7V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V7C5 5.89543 5.89543 5 7 5V3Z" fill={color}/>
      <path d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11Z" fill={color}/>
      <path d="M12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11H16C16.5523 11 17 10.5523 17 10C17 9.44771 16.5523 9 16 9H12Z" fill={color}/>
      <path d="M12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15H16C16.5523 15 17 14.5523 17 14C17 13.4477 16.5523 13 16 13H12Z" fill={color}/>
      <path d="M11 18C11 17.4477 11.4477 17 12 17H14C14.5523 17 15 17.4477 15 18C15 18.5523 14.5523 19 14 19H12C11.4477 19 11 18.5523 11 18Z" fill={color}/>
      <path d="M9 14C9 14.5523 8.55229 15 8 15C7.44772 15 7 14.5523 7 14C7 13.4477 7.44772 13 8 13C8.55229 13 9 13.4477 9 14Z" fill={color}/>
      <path d="M8 19C8.55229 19 9 18.5523 9 18C9 17.4477 8.55229 17 8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15 3H9C8.44772 3 8 3.44772 8 4C8 4.55228 8.44772 5 9 5H15C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3ZM9 1C7.34315 1 6 2.34315 6 4C6 5.65685 7.34315 7 9 7H15C16.6569 7 18 5.65685 18 4C18 2.34315 16.6569 1 15 1H9Z" fill={color}/>
    </svg>

  );
};

export default Log;
import React, {useEffect, useState} from 'react';
import Select from "react-select";

export default function SelectField({options, label, field, setFields, id}) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (field?.value?.length > 0) {
      const arr = options?.filter(item => {
        if (field.value == item.value) {
          return item;
        }
      })
      setValue(arr[0]);
    } else {
      setValue(null);
    }
  }, [field])


  return (
    <div className="equipment__container">
      <div className="equipment__select">
        <div className="title">{label}</div>
        <Select options={options}
                value={value}
                styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
            borderRadius: 8,
            height: 42,
            backgroundColor: '#F4F5F6',
            marginTop: 8,
          }),
        }}
        onChange={(e) => {
          setFields((old) => {
            let data = [...old.map(item => {
              let newArray = {...item}
              if (newArray?.key === id) {
                newArray.value = e.value
              }
              return newArray
            })];
            return data
          })
        }}
        />
      </div>
    </div>
  );
};


import React, {useState} from 'react';

export default function Tooltip({tooltipText, tooltipWidth = 0}) {
  const [tooltipStatus, setTooltipStatus] = useState(false)
  return (
    <div className="tooltip__container">
        <img className="tooltip__trigger"
             style={{width: 18, height: 18, marginLeft: 5, cursor: 'pointer'}}
             src="/assets/warning-circle.svg"
             alt="icon"
             onMouseOver={() => setTooltipStatus(true)}
             onMouseOut={() => setTooltipStatus(false)}
        />
      {
        tooltipStatus && <p className={`tooltip__text`} style={{width: tooltipWidth ? tooltipWidth : 150}}>{tooltipText}</p>
      }
    </div>
  );
};


import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import Select from "react-select";
import {
  useCreateEquipmentMutation,
  useLazyGetFacilityQuery,
  useLazyGetCurrentEquipmentQuery,
  useUpdateEquipmentMutation,
  useGetFuelTypesQuery,
} from "../../store/builder/inspections.api";
import FacilitySelect from "../../components/EquipmentComponents/FacilitySelect";
import StationsSelect from "../../components/EquipmentComponents/StationsSelect";
import TerminalsSelect from "../../components/EquipmentComponents/TerminalsSelect";

import "./index.scss";
import EquipmentSelect from "../../components/EquipmentComponents/EquipmentSelect";
import TextField from "../../components/EquipmentComponents/TextField";
import RadioField from "../../components/EquipmentComponents/RadioField";
import FileField from "../../components/EquipmentComponents/FileField";
import DateField from "../../components/EquipmentComponents/DateField";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import SelectField from "../../components/EquipmentComponents/SelectField";
import FuelSelect from "../../components/EquipmentComponents/FuelSelect";
import moment from "moment/moment";

export default function CreateEquipment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation()?.state?.equipment ?? params?.id;
  const locationS = useLocation();
  const [
    getEquipment,
    {
      data: getEquipmentData,
      error: getEquipmentError,
      isError: getEquipmentIsError,
      isLoading: getEquipmentIsLoading,
      isSuccess: getEquipmentIsSuccess,
    },
  ] = useLazyGetCurrentEquipmentQuery();
  const [title, setTitle] = useState("");
  const [stationsOptions, setStationsOptions] = useState([]);
  const [terminalsOptions, setTerminalsOptions] = useState([]);
  const [currentFacility, setCurrentFacility] = useState(null);
  const [currentStations, setCurrentStations] = useState(null);
  const [currentTerminals, setCurrentTerminals] = useState(null);
  const [currentEquipment, setCurrentEquipment] = useState(null);
  const [currentFuel, setCurrentFuel] = useState(null);
  const [fields, setFields] = useState([]);
  const [renderFields, setRenderFields] = useState([])
  const [changesFields, setChangesFields] = useState([]);
  const [changed, setChanged] = useState(false)

  const [
    getFacility,
    { data: facilityData, isFetching: facilityIsLoading, error: faclityError },
  ] = useLazyGetFacilityQuery();
  const [
    createEquipment,
    { isFetching: createIsLoading, error: createError, isError: createIsError },
  ] = useCreateEquipmentMutation();
  const [
    updateEquipment,
    { isFetching: updateIsLoading, error: updateError, isError: updateIsError },
  ] = useUpdateEquipmentMutation();

  const {data: fuelData, isFetching: fuelIsLoading, error: fuelError, isError: fuelIsError, isSuccess: fuelIsSuccess }= useGetFuelTypesQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateError,
    createError,
    faclityError,
  ]);
  useEffect(() => {
    getFacility();
    if (params?.id) {
      getEquipment(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (!changed) {
      if ((facilityIsLoading, updateIsLoading, getEquipmentIsLoading)) {
        dispatch(setLoader(true));
      } else {
        dispatch(setLoader(false));
      }

      if (getEquipmentIsSuccess) {
        if (!title?.length) {
          setTitle(getEquipmentData?.data?.title);
        }
        setCurrentFacility({
          value: getEquipmentData?.data?.facility?.id,
          label: getEquipmentData?.data?.facility?.name,
        });
        setCurrentEquipment({
          value: getEquipmentData?.data?.type?.id,
          label: getEquipmentData?.data?.type?.title,
        });
        setCurrentFuel({
          value: getEquipmentData?.data?.fuel_type?.id,
          label: getEquipmentData?.data?.fuel_type?.name,
        });
        let changedArray = getEquipmentData?.data?.fields?.map(item => {
          if (item?.value && item?.type === 'date') {
            return {...item, value: moment(item?.value).format("YYYY-MM-DD")};
          }
          return item;
        })
        setFields(JSON.parse(JSON.stringify(changedArray)));
      }
    }
  }, [
    facilityIsLoading,
    updateIsLoading,
    getEquipmentIsSuccess,
    fuelIsSuccess,
  ]);

  const addTooltips = (fields) => {
    return fields.map(item => {
      if(item?.key === 'last_changed_date') {
        item = {...item, tooltip: 'Applies to the 12/36 months filter change'}
      } else if(item?.key === 'last_interior_date') {
        item = {...item, tooltip: 'Applies to the 12 month filter inspection/cleaning (if applicable) '}
      }
      return item;
    })
  }

  useEffect(() => {
    setRenderFields(addTooltips([...fields]))
  }, [fields])

  // let test = renderFields?.sort(function(a, b) {
  //   let nameA = a?.label?.toUpperCase();
  //   let nameB = b?.label?.toUpperCase();
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // });

  function houseOrder(array) {
    const arr = [...array]; // the original array
    const idxHouseCount = arr.findIndex(obj => obj.key === "hose_count");
    const idxHoseCerts = arr.findIndex(obj => obj.key === "hose_certs");
    if (idxHouseCount > idxHoseCerts) {
      [arr[idxHouseCount], arr[idxHoseCerts]] = [arr[idxHoseCerts], arr[idxHouseCount]];
    }
    return arr
  }

  const submitHandler = () => {
    setChanged(true)
    let s = [];
    let data = {
      id: location?.id ? location?.id : location,
      title: title !== "" ? title : null,
      equipment_type_id: currentEquipment?.value
        ? currentEquipment?.value
        : null,
      facility_id: currentFacility?.value ? currentFacility?.value : null,
      fuel_type_id: currentFuel?.value ? currentFuel?.value : null,
      fields: fields,
    };

    for (let key in data) {
      if (!data[key] && key === data.facility_id) {
        toast?.error("Please enter " + key);
        s.push(false);
      }
    }

    data = {
      ...data,
      station_id: currentStations?.value ? currentStations?.value : null,
      terminal_id: currentTerminals?.value ? currentTerminals?.value : null,
    };

    if (s.length === 0) {
      dispatch(setLoader(true));
      if (!location) {
        createEquipment(data)
          .then((res) => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            dispatch(setLoader(false));
            setChanged(false)
            toast.success(res.data.message);
            setFields([]);
            setTimeout(() => {
              navigate(-1);
            }, 500);
          })
          .catch((err) => {});
      } else {
        updateEquipment({ ...data})?.then((res) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
            dispatch(setLoader(false));
            setChanged(false)
            toast.success(res.data.message);
            setTimeout(() => {
              navigate(-1);
            }, 500);
          })
          .catch((err) =>  toast.error(err?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    // console.log('updateError', updateError)
    // if (updateError) {
    //   toast.error(updateError?.data?.message);
    // }
    if (createError) {
      toast.error(createError?.data?.message);
    }
  }, [ createError]);

  return (
    <Wrapper>
      <Helmet>
        <title>
          {params?.id ? "Edit Equipment" : "Create Equipment"} | NDX
        </title>
      </Helmet>
      <DashboardHeader
        title={location ? "Edit Equipment" : " Create Equipment"}
        type={"create-equipment"}
      />
      <div className="equipment" style={{ padding: 10 }}>
        <div className="equipmentCreate">
          <Input
            placeholder="ID#"
            maxwidth={500}
            label={"Enter ID#"}
            value={title}
            onInput={(e) => {
              setTitle(() => e.target.value)
            }}
          />
          {
              locationS?.pathname?.split('/')[1] === "create-equipment" &&
              (<EquipmentSelect
                  currentEquipment={currentEquipment}
                  setCurrentEquipment={setCurrentEquipment}
                  setFields={setFields}
                  equipment={location}
              />)
          }
          {(!params?.id && currentEquipment?.has_facility !== false) &&  (
            <FacilitySelect
              currentFacility={currentFacility}
              setCurrentFacility={setCurrentFacility}
              facilityData={facilityData?.filter(el => el.can_edit === true)}
              setCurrentStations={setCurrentStations}
              setStationsOptions={setStationsOptions}
              setTerminalsOptions={setTerminalsOptions}
            />
          )}
          <FuelSelect
            currentFacility={currentFuel}
            setCurrentFacility={setCurrentFuel}
            facilityData={fuelData}
          />
          {renderFields &&
            renderFields?.map((field) => {
              if (field?.type === "text" || field?.type === "number") {
                return (
                  <TextField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    type={field?.type}
                    fields={fields}
                    value={field?.value ? field?.value : ""}
                  />
                );
              }
              if (field?.type === "radio") {
                return (
                  <RadioField
                    id={field?.key}
                    key={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    options={field?.options}
                    field={field}
                  />
                );
              }
              if (field?.type === "date") {
                return (
                  <DateField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    field={field}
                    tooltip={field?.tooltip}
                  />
                );
              }
              if (field?.type === "select") {
                return (
                  <SelectField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    field={field}
                    options={field.options}
                  />
                );
              }
              if (field?.type === "file") {
                return (
                  <FileField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    equipmentId={params.id}
                    field={field}
                  />
                );
              }
              if (field?.type === "files") {
                return (
                  <FileField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    equipmentId={params.id}
                    field={field}
                    files={true}
                    fields={fields}
                  />
                );
              }
            })}

          <div className="equipment__container">
            <button onClick={submitHandler} className="equipment__button">
              {location ? "Save" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

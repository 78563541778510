import React, {useEffect, useState} from 'react';
import './index.scss'
import {useLocation} from "react-router-dom";

export default function InspectionDropDown({arr, chosenType, setType, acceptance}){
  const [listStatus, setListStatus] = useState(false)
  const location = useLocation()
  useEffect(() => {
    return window.addEventListener('click', (e) => {
      if (e.target.closest('.dropdown') === null && e.target.closest('.dropdown__input') === null && e.target.closest('.dropdown__list') === null) {
        setListStatus(false)
      }
    })
  }, [location])
  return (
    <div className="dropdown">
      <div className="dropdown__input" onClick={() => setListStatus(!listStatus)}>
        <div className="dropdown__input-name">
          {!chosenType || chosenType === 'condition_code' || chosenType === 'sump_sample' || chosenType === 'sump_sample_optional' || chosenType === 'tank_condition' || chosenType === 'cleaned_status'
            || chosenType === 'microbial_test' || chosenType === 'list' || chosenType === 'range' ? null :
            <img src={
            chosenType?.toLowerCase() === 'text' ? '/assets/icons/text-field.svg'
              : chosenType?.toLowerCase() === 'textarea' ? '/assets/icons/text-field.svg'
              : chosenType?.toLowerCase() === 'checkbox' ? '/assets/icons/checkbox.svg'
                : chosenType?.toLowerCase() === 'radio' ? '/assets/icons/radio-btn.svg'
                : chosenType?.toLowerCase() === 'file' ? '/assets/icons/image.svg'
                    : chosenType?.toLowerCase() === 'select' ? '/assets/icons/select.svg'
                    : chosenType?.toLowerCase() === 'date' ? '/assets/icons/date.svg'
                    : chosenType?.toLowerCase() === 'numeric' ? '/assets/icons/number.svg' :
                        ''
            } alt="icon" />}
         <p> {!chosenType ? 'Choose'
             : chosenType.charAt(0).toUpperCase() + chosenType.slice(1).replace(/[^a-zA-Z ]/g, " ")}
         </p>
        </div>
        <img src="/assets/icons/dropdown.svg" alt="dropdown"/>
      </div>

      {
        listStatus &&
        <div className="dropdown__list">
          {
            arr.map((item, index) => (
              <div className="dropdown__list-elem" key={index} onClick={() => {
                setType(item.value)
                setListStatus(false)
              }}>
                {item.label.charAt(0).toUpperCase() + item.label.slice(1).replace(/[^a-zA-Z ]/g, " ")}
              </div>
            ))
          }
        </div>
      }
    </div>

  );
};


import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Wrapper from "../../components/Wrapper";
import './index.scss';
import Input from "../../components/Input";
import InactiveRow from "../../components/InactiveRow";
import {
  useGetTimezonesQuery,
  useInviteFboMutation,
  useLazyAgencyInactiveQuery, useLazyCorporateListQuery
} from "../../store/builder/inspections.api";
import Modal from "../../components/Modal";
import {validationEmail} from "../../global/validate";
import {toast} from "react-toastify";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import AgencyDeleteModal from "../../components/AgencyDeleteModal";
import {useDebounce} from "../LogSheets/hooks";
import CustomPaginate from "../../components/PaginateComponent";

const AgencyInactive = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [createModalStatus, setCreateModalStatus] = useState(false);
  const [cropper, setCropper] = useState();
  const [imageBlob, setImageBlob] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [inviteModal, setInviteModal] = useState(false);
  const [email, setEmail] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearchValue = useDebounce(search, 500);

  const [getAgency, {
    data: getAgencyData,
    isLoading: getAgencyIsLoading,
    isError: getAgencyIsError,
    error: getAgencyError,
    isSuccess: getAgencyIsSuccess,
    }] = useLazyAgencyInactiveQuery();

  const {data: timezonesData,
    error: timezonesError,
    isLoading: timezonesIsLoading,
    isSuccess: timezonesIsSuccess,
    isError: timezonesIsError,
  } = useGetTimezonesQuery();

  const [getCorporateList, {
    data: corporateListData,
    error: corporateListError,
    isFetching: corporateListIsLoading,
    isSuccess: corporateListIsSuccess,
    isError: corporateListIsError,
  }] = useLazyCorporateListQuery();

  const [invite,
    {
      data: inviteData,
      isLoading: inviteIsLoading,
      isError: inviteIsError,
      error: inviteError,
      isSuccess: inviteIsSuccess,
    },] = useInviteFboMutation();

  useEffect(() => {
    if (inviteIsLoading || getAgencyIsLoading) {
      dispatch(setLoader(true));
    }
    if (getAgencyIsSuccess) {
      dispatch(setLoader(false));
    }
    if (inviteIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Invite successfully sent!');
      setInviteModal(false);
    }
    if (inviteIsError || getAgencyIsError) {
      dispatch(setLoader(false));
      toast.error(inviteError?.data?.email[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError, getAgencyIsLoading, getAgencyIsError, getAgencyIsSuccess]);


  useEffect(() => {
    if (createModalStatus) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'visible'
    }
  }, [createModalStatus])

  useEffect(() => {
    getAgency({page: currentPage, search: debouncedSearchValue})
  }, [currentPage, debouncedSearchValue])

  useEffect(() => {
    getCorporateList({status: 'active'});
  }, []);

  useEffect(() => {
    if (getAgencyData) {
      setList(getAgencyData?.data?.map(item => ({...item, action_status: false})));
    }
  }, [getAgencyData]);

  const getAgencies = () => {
    getAgency({page: currentPage})
  }

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const image = base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      setShowModal(false)
      document.querySelector('.modal-fbo').style.display = 'flex'
      setPhoto(cropper.getCroppedCanvas().toDataURL())
      setImageBlob(image)
    }
  };
  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleSubmitInvite = () => {
    if (validationEmail(email)) {
      invite({email: email, role_id: 2})
    } else {
      toast.error('Please enter correct email')
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <title>FBO Inactive | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"Inactive FBOs"}
        showBtn={true}
        type="agancy-trial"
        action={() => setInviteModal(true)}
        secondAction={() => setCreateModalStatus(true)}
      />
      {createModalStatus && (
        <Modal
          title={"Create FBO"}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setShowModal={setShowModal}
          setChosenPhoto={setChosenPhoto}
          photo={photo}
          setCreateModalStatus={setCreateModalStatus}
          imageBlob={imageBlob}
          setPhoto={setPhoto}
          type="agancy"
          exit={() => {
            setCreateModalStatus(false)
            setPhoto(null)
            setImageBlob(null)
            setCurrentItem(null)
          }}
          getAgencies={getAgencies}
          timezones={timezonesData?.data?.map(item => ({value: item, label: item}))}
        />
      )}
      {
        inviteModal &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Invite FBO</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setInviteModal(false)}
              />
            </div>
            <div className={`modal__content-body`}>
              <Input
                containerWidth={'100%'}
                id="enail"
                name="emal"
                placeholder="Email"
                label={"Email"}
                type={"email"}
                onChange={handleEmail}
                defaultValue=""
              />
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => setInviteModal(false)}>
                Cancel
              </button>
              <button className="save" onClick={handleSubmitInvite}>
                Send Link
              </button>
            </div>
          </div>
          <div className="modal__bg" onClick={() => setInviteModal(false)}></div>
        </div>
      }
      {
        deleteModal &&
        <AgencyDeleteModal exit={() => setDeleteModal(false)} getAgencies={getAgencies} currentFBO={currentItem} />
      }
      <div className="inactive inspections">
        <div className="inactive__filters">
          <Input
            type="text"
            placeholder="Search by name"
            name="trial__search"
            defaultValue=""
            maxwidth={250}
            image={"/assets/icons/search.svg"}
            onChange={(e) => {setSearch(e.target.value)}}
          />
        </div>
        <div className="inactive__box">
          <div className="inactive__table">
            <div className="inactive__table-head">
              <div className="inactive__table-th">ID</div>
              <div className="inactive__table-th">Name</div>
              <div className="inactive__table-th">Airport ID</div>
              <div className="inactive__table-th">Timezone</div>
              <div className="inactive__table-th">Corporate</div>
              <div className="inactive__table-th">Logo</div>
              <div className="inactive__table-th">Invited date</div>
              <div className="inactive__table-th">Trial start date</div>
              <div className="inactive__table-th">Inactive date</div>
              <div className="inactive__table-th">Action</div>
            </div>
            {
              list?.length > 0 ?
                list?.map(inactive => (
                  <InactiveRow
                    inactive={inactive}
                    key={inactive?.id}
                    setList={setList}
                    getAgencies={getAgencies}
                    setCurrentItem={setCurrentItem}
                    setCreateModalStatus={setCreateModalStatus}
                    setDeleteModal={setDeleteModal}
                    currentItem={currentItem}
                    corporateData={corporateListData}
                  />
                ))
                :
                <div className="corporate__table-empty">
                  <img
                    src="/assets/no-data-v3.svg"
                    alt="empty"
                  />
                  <p>There are no FBO</p>
                </div>
            }
          </div>
          <CustomPaginate
            pageCount={getAgencyData?.pages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default AgencyInactive;
import { useState, useEffect, useRef } from "react";

export default function useTimer(endDate, dependency) {
  const timerRef = useRef(null);
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [endDate, dependency]);

  function calculateRemainingTime() {
    const now = new Date().getTime();
    const distance = new Date(endDate).getTime() - now;

    if (distance < 0) {
      clearInterval(timerRef.current);
      return 0;
    }

    const remainingSeconds = Math.floor(distance / 1000);

    return remainingSeconds;
  }

  return remainingTime;
}

import React from 'react';

const Profile6 = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.6415 28.2445C30.0423 26.3001 31 24.1631 31 22C31 18.134 27.866 15 24 15C20.134 15 17 18.134 17 22C17 24.1631 17.9577 26.3001 19.3585 28.2445C20.7487 30.1742 22.4581 31.7503 23.6769 32.7485C23.8768 32.9122 24.1232 32.9122 24.3231 32.7485C25.5419 31.7503 27.2513 30.1742 28.6415 28.2445ZM25.5904 34.2957C28.1746 32.1791 33 27.4917 33 22C33 17.0294 28.9706 13 24 13C19.0294 13 15 17.0294 15 22C15 27.4917 19.8254 32.1791 22.4096 34.2957C23.3466 35.0631 24.6534 35.0631 25.5904 34.2957Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 20C22.8954 20 22 20.8954 22 22C22 23.1046 22.8954 24 24 24C25.1046 24 26 23.1046 26 22C26 20.8954 25.1046 20 24 20ZM20 22C20 19.7909 21.7909 18 24 18C26.2091 18 28 19.7909 28 22C28 24.2091 26.2091 26 24 26C21.7909 26 20 24.2091 20 22Z" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>


  );
};

export default Profile6;
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { useGetEquipmentTypeQuery } from "../../../store/builder/inspections.api";
import { setLoader } from "../../../store/builder/reducer";
import {useLocation} from "react-router-dom";

export default function EquipmentSelect({
  setCurrentEquipment,
  currentEquipment,
  setFields,
  equipment,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const { data: equipmentData, isFetching: equipmentIsLoading, error: equipmentError } =
    useGetEquipmentTypeQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([equipmentError])

  useEffect(() => {
    if (equipmentIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [equipmentIsLoading]);

  useEffect(() => {
    if (equipmentData) {
      let options = [];
      equipmentData?.forEach((item) => {
        options = [
          ...options,
          { label: item?.title, value: item?.id, fields: item?.fields, has_facility: item?.has_facility },
        ];
      });
      setEquipmentOptions(options.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }
        return -1;
      }));
      if (equipment) {
        let s = equipmentData?.filter(
          (item) => item?.id === equipment?.type?.id
        )[0];
        setCurrentEquipment({
          label: s?.title,
          value: s?.id,
          fields: s?.fields,
        });
      }
    }
  }, [equipmentData]);
  return (
    <>
      <label htmlFor="">
        Equipment type
        <Select
          options={equipmentOptions}
          value={currentEquipment}
          onChange={(selected) => {
            setCurrentEquipment([]);
            setTimeout(() => {
              setCurrentEquipment(selected);
              setFields(selected?.fields)
            }, 0);
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: 534,
              height: 42,
              backgroundColor: "#F4F5F6",
            }),
          }}
        />
      </label>
    </>
  );
}

import React, {useEffect, useState} from 'react';
import CustomSelect from "../CustomSelect";
import {useDispatch} from "react-redux";
import {setFields} from "../../store/builder/reducer";
import Input from "../Input";

const FillDryRating = ({readOnly, disabled, item}) => {
  const dispatch = useDispatch();
  const [colorimetricScale, setColorimetricScale] = useState({
    id: "colorimetric_scale",
    label: "Colorimetric Scale",
    options: [
      {value:'A', label: 'A'},
      {value:'B', label: 'B'},
      {value:'G', label: 'G'}
    ]
  })
  const [colorimetricGraduation, setColorimetricGraduation] = useState({
    id: "colorimetric_graduation",
    label: "Colorimetric Graduation",
    options: [
      {value:'0', label: '0'},
      {value:'1', label: '1'},
      {value:'2', label: '2'},
      {value:'3', label: '3'},
      {value:'4', label: '4'},
      {value:'5', label: '5'},
      {value:'6', label: '6'},
      {value:'7', label: '7'},
      {value:'8', label: '8'},
      {value:'9', label: '9'},
      {value:'10', label: '10'},
    ]
  })
  const [particleAssessment, setParticleAssessment] = useState({
    id: "particle_assessment",
    label: "Particle Assessment",
    options: [
      {value:'A', label: 'A'},
      {value:'B', label: 'B'},
      {value:'C', label: 'C'},
      {value:'D', label: 'D'},
      {value:'E', label: 'E'},
      {value:'F', label: 'F'},
      {value:'G', label: 'G'},
      {value:'H', label: 'H'},
      {value:'I', label: 'I'},
    ]
  })
  const [wetOrDry, setWetOrDry] = useState({
    id: "wet_or_dry",
    label: "Wet or Dry",
    options: [
      {value:'W', label: 'Wet'},
      {value:'D', label: 'Dry'},
    ]
  })
  const [answer, setAnswer] = useState({
    colorimetric_scale: null,
    colorimetric_graduation: null,
    particle_assessment: null,
    wet_or_dry: null,
  })
  const [finalAnswer, setFinalAnswer] = useState(null)

  useEffect(() => {
    setFinalAnswer(() => {
      let total;
      total = `${answer?.colorimetric_scale ? answer?.colorimetric_scale + "," : " ,"}` +
              `${answer?.colorimetric_graduation ? answer?.colorimetric_graduation + "," : " ,"}` +
              `${answer?.particle_assessment ? answer?.particle_assessment + "," : " ,"}` +
              `${answer?.wet_or_dry ? answer?.wet_or_dry : " "}`
      return total
      })
  }, [answer]);

  useEffect(() => {
    if (!readOnly) {
      let fieldData = {
        ...item,
        id: item.id,
        answers: [`${finalAnswer}`],
        // required: (answer?.colorimetric_scale === null || answer?.colorimetric_graduation === null)
      }
      dispatch(setFields(fieldData))
    }

  }, [finalAnswer])

  const handleChange = (e) => {
    setAnswer({...answer, [e.target.id]: e.target.value})
  }

  return (
    <>
      <div className="fillInspection__field-select">
        {
          readOnly ?
            <>
              <div className="fillInspection__field-dry">
                <Input label={colorimetricScale?.label} containerWidth={'220px'} value={item?.answers[0]?.split(',')[0]} readOnly={readOnly} />
                <Input label={colorimetricGraduation?.label} containerWidth={'220px'} value={item?.answers[0]?.split(',')[1]} readOnly={readOnly} />
                <Input label={particleAssessment?.label} containerWidth={'220px'} value={item?.answers[0]?.split(',')[2]} readOnly={readOnly} />
              </div>
              {/*<div className="fillInspection__field-dry">*/}
                {/*<Input label={wetOrDry?.label} containerWidth={'300px'} value={item?.answers[0]?.split(',')[3]} readOnly={readOnly} />*/}
              {/*</div>*/}
            </>
            :
            <>
              <div className="fillInspection__field-dry">
                <CustomSelect onChange={handleChange} name={colorimetricScale?.id} id={colorimetricScale?.id}
                              label={colorimetricScale?.label} options={colorimetricScale?.options} maxwidth={"220px"}
                              defaultValue={answer?.colorimetric_scale}/>
                <CustomSelect onChange={handleChange} name={colorimetricGraduation?.id} id={colorimetricGraduation?.id}
                              label={colorimetricGraduation?.label} options={colorimetricGraduation?.options}
                              maxwidth={"220px"}/>
                <CustomSelect onChange={handleChange} name={particleAssessment?.id} id={particleAssessment?.id}
                              label={particleAssessment?.label} options={particleAssessment?.options}
                              maxwidth={"220px"}/>
              </div>
              {/*<div className="fillInspection__field-dry">*/}

                {/*<CustomSelect onChange={handleChange} name={wetOrDry?.id} id={wetOrDry?.id} label={wetOrDry?.label}*/}
                {/*              options={wetOrDry?.options} maxwidth={"300px"}/>*/}
              {/*</div>*/}
            </>
        }


      </div>
    </>
  );
};

export default FillDryRating;
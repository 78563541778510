import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {validationEmail} from "../../global/validate";
import {toast} from "react-toastify";
import axios from "axios";
import {setLoader} from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import {useDispatch} from "react-redux";
import {setVerifyRegister, setVerifyRegisterTime} from "../../store/builder/registerSlice";
import {useGetTimezonesQuery} from "../../store/builder/inspections.api";
import CustomSelect from "../../components/CustomSelect";
import Checkbox from "../../components/Checkbox";
import log from "../../SVG/Log";

export default function RegistrationNew () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [eye, setEye] = useState(false);
  const [isFboStatus, setIsFboStatus] = useState(false)
  const [state, setState] = useState({
    first_name: {
      id: "first_name",
      value: null,
      errorShow: false,
      error: "Please enter correct first name (min length 2)",
    },
    last_name: {
      id: "last_name",
      value: null,
      errorShow: false,
      error: "Please enter correct last name (min length 3)",
    },
    fbo_name: {
      id: "fbo_name",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO name",
    },
    fbo_phone: {
      id: "fbo_phone",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO phone",
    },
    fbo_email: {
      id: "fbo_email",
      value: null,
      errorShow: false,
      error: "Please enter correct FBO email",
    },
    airport_id: {
      id: "airport_id",
      value: null,
      errorShow: false,
      error: "Please enter correct Airport ID (min length 3)",
    },
    fbo_timezone: {
      id: "fbo_timezone",
      value: null,
      errorShow: false,
      error: "Please choose a timezone",
    },
    password: {
      id: "password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
    password_confirmation: {
      id: "password_confirmation",
      value: null,
      errorShow: false,
      error: "Password and confirm password should be identical",
    },
    terms: {
      id: "terms",
      value: null,
      errorShow: false,
      error: "You must agree to the FBO safety-first certified",
    },
    token: null
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);
  const [publicToken, setPublicToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(1)
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [corporateFboState, setCorporateFboState] = useState({
    email: null,
    password: null,
    password_confirmation: null,
    first_name: null,
    last_name: null,
    phone: null,
    fbo_name: null,
    fbo_phone: null,
    fbo_email: null,
    airport_id: null,
    work_day_start_time: null,
    start_day_minutes: null,
    nozzle_fueling_pressure: null,
    sump_solid_rating: null,
    sump_water_rating: null,
    sump_trend_alert: null,
    has_hydrant_system: false,
    has_upstream_tests: false,
    fbo_timezone: null,
    token: null,
    is_safety_first_certified: false,
  });
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([
    {
      label: '12:00 AM',
      value: 0,
    },
    {
      label: '1:00 AM',
      value: 60,
    },
    {
      label: '2:00 AM',
      value: 120,
    },
    {
      label: '3:00 AM',
      value: 180,
    },
    {
      label: '4:00 AM',
      value: 240,
    },
    {
      label: '5:00 AM',
      value: 300,
    },
    {
      label: '6:00 AM',
      value: 360,
    },
    {
      label: '7:00 AM',
      value: 420,
    },
    {
      label: '8:00 AM',
      value: 480,
    },
    {
      label: '9:00 AM',
      value: 540,
    },
    {
      label: '10:00 AM',
      value: 600,
    },
    {
      label: '11:00 AM',
      value: 660,
    },
    {
      label: '12:00 PM',
      value: 720,
    },
    {
      label: '1:00 PM',
      value: 780,
    },
    {
      label: '2:00 PM',
      value: 840,
    },
    {
      label: '3:00 PM',
      value: 900,
    },
    {
      label: '4:00 PM',
      value: 960,
    },
    {
      label: '5:00 PM',
      value: 1020,
    },
    {
      label: '6:00 PM',
      value: 1080,
    },
    {
      label: '7:00 PM',
      value: 1140,
    },
    {
      label: '8:00 PM',
      value: 1200,
    },
    {
      label: '9:00 PM',
      value: 1260,
    },
    {
      label: '10:00 PM',
      value: 1320,
    },
    {
      label: '11:00 PM',
      value: 1380,
    },
  ])
  const [waterRating, setWaterRating] = useState([
    {
      value: "A", label: "A. Bright"
    },
    {
      value: "B", label: "B. Hazy"
    },
    {
      value: "C", label: "C. Cloudy"
    },
    {
      value: "D", label: "D. Wet"
    },
    {
      value: "E", label: "E. Surfactants"
    }
  ])
  const [solidRating, setSolidRating] = useState([
    {
      value: 1, label: "1. Clean"
    },
    {
      value: 2, label: "2. Slight"
    },
    {
      value: 3, label: "3. Particulate"
    },
    {
      value: 4, label: "4. Dirty"
    }
  ])
  const [hasHydrant, setHydrant] = useState(false)
  const [enableDevice, setEnableDevice] = useState(false)
  useEffect(() => {
    if (location.search) {
      setPublicToken(searchParams.get('public_key'))
    }
  }, [location.search])

  useEffect(() => {
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}auth/timezones`, config)
      .then((response) => {
        setTimezoneOptions(response?.data?.data?.map(el => ({value: el, label: el})))
      })
      .catch(err => {
        console.log(err)
      })

  }, [])

  useEffect(() => {
    dispatch(setLoader(true))
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    if (publicToken) {
      axios.post(`${process.env.REACT_APP_API_URL}auth/verify-sing-up-token`, {
        token: publicToken,
      }, config)
        .then(res => {
          dispatch(setLoader(false));
          if (res.data.data.user.fbo_id) {
            setIsFboStatus(false);
          } else if (res.data.data?.user?.role_id === 8) {
            setIsFboStatus(false);
          } else {
            setIsFboStatus(true);
          }
          if (!res.data.data.token_is_valid) {
            toast.error(res.data.message)
            navigate('/login')
          }
          if (!res.data.data?.user?.fbo_id && res.data.data?.user?.role_id == 2 && res.data.data?.user?.is_fbo_complete === false && res.data.data?.user?.has_corporate_settings !== null) {
            setSecondStep(true)
          }
          if (res.data.data?.user?.has_corporate_settings === false) {
            setThirdStep(true)
          }
          setState(old => ({...old, token: res.data.data.secret_token}));
          setCorporateFboState(old => ({...old, email: res.data.data?.user?.email, fbo_name: res.data.data?.user?.fbo_name, token: res.data.data.secret_token}))

        })
        .catch(err => {
          dispatch(setLoader(false))
        })
        .finally(() => {
          dispatch(setLoader(false))
        })
    }
    dispatch(setLoader(false))
  }, [publicToken])

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };
  const handleChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const handleCorporateChange = (e) => {
    setCorporateFboState(old => ({...old, [e.target.name]: e.target.value}))
  }

  const submitHandler = (e) => {
    e.preventDefault();
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    if (isFboStatus) {
      if (
        // state?.fbo_email?.value &&
        // validationEmail(state?.fbo_email?.value) &&
        state?.first_name?.value?.length >= 2 &&
        state?.last_name?.value?.length > 2 &&
        state?.fbo_name?.value?.length > 2 &&
        // phoneNumber?.length > 9 &&
        state?.airport_id?.value?.length > 2 &&
        state?.fbo_timezone?.value &&
        state?.password?.value?.length > 5) {
        dispatch(setLoader(true));
        axios
          .post(
            `${process.env.REACT_APP_API_URL}auth/finish-sing-up`,
            {
              token: state?.token,
              first_name: state.first_name.value,
              last_name: state.last_name.value,
              fbo_name: state.fbo_name.value,
              fbo_email: state.fbo_email.value,
              fbo_timezone: state?.fbo_timezone?.value,
              // fbo_phone: "+1" + phoneNumber?.replace(/\D/g, ''),
              airport_id: state.airport_id.value,
              password: state.password.value,
              password_confirmation: state.password_confirmation.value,
              is_safety_first_certified: termsAgree
            },
            config
          )
          .then((res) => {
            dispatch(setLoader(false));
            toast.success("You successfully registered", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate("/installation");
            }, 500)
          })
          .catch((err) => {
            dispatch(setLoader(false));
            if (err?.response?.data?.token) {
              toast.error("This user has already been registered!");
            }
            if (err?.response?.data?.first_name) {
              toast.error(err?.response?.data?.first_name[0]);
            }
            if (err?.response?.data?.last_name) {
              toast.error(err?.response?.data?.last_name[0]);
            }
            if (err?.response?.data?.fbo_name) {
              toast.error(err?.response?.data?.fbo_name[0]);
            }
            if (err?.response?.data?.fbo_phone) {
              toast.error(err?.response?.data?.fbo_phone[0]);
            }
            if (err?.response?.data?.fbo_email) {
              toast.error(err?.response?.data?.fbo_email[0]);
            }
            if (err?.response?.data?.airport_id) {
              toast.error(err?.response?.data?.airport_id[0]);
            }
            if (err?.response?.data?.fbo_timezone) {
              toast.error(err?.response?.data?.fbo_timezone[0]);
            }
            if (err?.response?.data?.password) {
              toast.error(err?.response?.data?.password[0]);
            }
            if (err?.response?.data?.password_confirmation) {
              toast.error(err?.response?.data?.password_confirmation[0]);
            }
          });
      }
    } else {
      if (state?.first_name?.value?.length >= 2 &&
        state?.last_name?.value?.length > 2 &&
        // phoneNumber?.length > 9 &&
        state?.password?.value?.length > 5) {
        dispatch(setLoader(true));
        axios
          .post(
            `${process.env.REACT_APP_API_URL}auth/finish-registration`,
            {
              token: state?.token,
              first_name: state.first_name.value,
              last_name: state.last_name.value,
              password: state.password.value,
              // fbo_phone: "+1" + phoneNumber?.replace(/\D/g, ''),
              password_confirmation: state.password_confirmation.value,
            }, config
            )
          .then((res) => {
            dispatch(setLoader(false));
            toast.success("You successfully registered", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate("/login");
            }, 500)
          })
          .catch((err) => {
            dispatch(setLoader(false));
            if (err?.response?.data?.token) {
              toast.error("This user has already been registered!");
            }
            if (err?.response?.data?.first_name) {
              toast.error(err?.response?.data?.first_name[0]);
            }
            if (err?.response?.data?.last_name) {
              toast.error(err?.response?.data?.last_name[0]);
            }
            if (err?.response?.data?.password) {
              toast.error(err?.response?.data?.password[0]);
            }
            if (err?.response?.data?.fbo_phone) {
              toast.error(err?.response?.data?.fbo_phone[0]);
            }
            if (err?.response?.data?.password_confirmation) {
              toast.error(err?.response?.data?.password_confirmation[0]);
            }
          });
      }
    }

    if (!state?.first_name?.value || state?.first_name?.value?.length < 2) {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: true}}))
    } else {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: false}}))
    }

    if (!state?.last_name?.value || state?.last_name?.value?.length < 3) {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: true}}))
    } else {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: false}}))
    }

    if (!state?.fbo_name?.value || state?.fbo_name?.value?.length < 3) {
      setState(old => ({...old, fbo_name: {...old.fbo_name, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_name: {...old.fbo_name, errorShow: false}}))
    }

    if (!state?.fbo_timezone?.value) {
      setState(old => ({...old, fbo_timezone: {...old.fbo_timezone, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_timezone: {...old.fbo_timezone, errorShow: false}}))
    }
    //
    // if (!phoneNumber || phoneNumber?.length < 10) {
    //   setState(old => ({...old, fbo_phone: {...old.fbo_phone, errorShow: true}}))
    // } else {
    //   setState(old => ({...old, fbo_phone: {...old.fbo_phone, errorShow: false}}))
    // }

    if (!state?.airport_id?.value || state?.airport_id?.value?.length < 3) {
      setState(old => ({...old, airport_id: {...old.airport_id, errorShow: true}}))
    } else {
      setState(old => ({...old, airport_id: {...old.airport_id, errorShow: false}}))
    }

    if (!state?.password?.value || state?.password?.value?.length < 3) {
      setState(old => ({...old, password: {...old.password, errorShow: true}}))
    } else {
      setState(old => ({...old, password: {...old.password, errorShow: false}}))
    }

    if (state?.password?.value !== state?.password_confirmation?.value) {
      setState(old => ({...old, password_confirmation: {...old.password_confirmation, errorShow: true}}))
    } else {
      setState(old => ({...old, password_confirmation: {...old.password_confirmation, errorShow: false}}))
    }
    // if (isFboStatus) {
    //   if (!termsAgree) {
    //     setState(old => ({...old, terms: {...old.terms, errorShow: true}}))
    //   } else {
    //     setState(old => ({...old, terms: {...old.terms, errorShow: false}}))
    //   }
    // }
  }

  const submitFirstStep = (e) => {
    e.preventDefault();
    if (corporateFboState?.first_name?.length >= 2 &&
      corporateFboState?.last_name?.length >= 3 &&
      corporateFboState?.password?.length > 5 &&
      // corporateFboState?.is_safety_first_certified &&
      corporateFboState?.password_confirmation?.length > 5 &&
      corporateFboState?.password === corporateFboState?.password_confirmation) {
      setStep(2)
    }
    if (corporateFboState?.first_name?.length < 2) {
      toast.error('First name should be at least 2 characters length')
    }
    if (corporateFboState?.last_name?.length < 3) {
      toast.error('Last name should be at least 3 characters length')
    }
    if (corporateFboState?.password !== corporateFboState?.password_confirmation) {
      toast.error('Password and Confirm password should be the same')
    }
    // if (!corporateFboState?.is_safety_first_certified) {
    //   toast.error('You must agree to the FBO safety-first certified')
    // }
  }
  const submitSecondStep = (e) => {
    e.preventDefault();
    if (!thirdStep) {
      if (corporateFboState?.fbo_timezone &&
        corporateFboState?.airport_id?.length >= 3) {
        dispatch(setLoader(true))
        let config = {
          headers: {
            AppVersion: '1.0.0',
            AppType: 'web',
            DeviceType: 'web'
          },
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}auth/finish-sing-up`,
            {
              ...corporateFboState
            },
            config
          )
          .then((res) => {
            dispatch(setLoader(false));
            toast.success("You successfully registered", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate("/login");
            }, 500)
          })
          .catch((err) => {
            dispatch(setLoader(false));
            if (err?.response?.data?.token) {
              toast.error("This user has already been registered!");
            }
          });
      }
      if (corporateFboState?.airport_id?.length < 3) {
        toast.error('Airport should be at least 3 characters length')
      }
      if (!corporateFboState?.fbo_timezone) {
        toast.error('Choose timezone')
      }
    } else {
      if (corporateFboState?.fbo_timezone &&
        corporateFboState?.airport_id?.length >= 3) {
        setStep(3)
      }
      if (corporateFboState?.airport_id?.length < 3 || !corporateFboState?.airport_id) {
        toast.error('Airport should be at least 3 characters length')
      }
      if (!corporateFboState?.fbo_timezone) {
        toast.error('Choose timezone')
      }
    }
  }
  const submitThirdStep = (e) => {
    e.preventDefault();
    if (corporateFboState?.start_day_minutes &&
      corporateFboState?.nozzle_fueling_pressure &&
      corporateFboState?.sump_solid_rating &&
      corporateFboState?.sump_water_rating &&
      corporateFboState?.sump_trend_alert &&
      corporateFboState?.has_hydrant_system !== null
    ) {
      dispatch(setLoader(true))
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}auth/finish-sing-up`,
          {
            ...corporateFboState
          },
          config
        )
        .then((res) => {
          dispatch(setLoader(false));
          toast.success("You successfully registered", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(() => {
            navigate("/login");
          }, 500)
        })
        .catch((err) => {
          dispatch(setLoader(false));
          if (err?.response?.data?.token) {
            toast.error("This user has already been registered!");
          }
        });

    }
    if (!corporateFboState?.start_day_minutes) {
      toast.error('Choose start day minutes')
    }
    if (!corporateFboState?.nozzle_fueling_pressure?.length > 0) {
      toast.error('Nozzle Fueling Pressure is required')
    }
    if (!corporateFboState?.sump_solid_rating) {
      toast.error('Choose Sump Solid Rating')
    }
    if (!corporateFboState?.sump_water_rating) {
      toast.error('Choose Sump Water Rating')
    }
    if (!corporateFboState?.sump_trend_alert?.length > 0) {
      toast.error('Sump Trend Alert is required')
    }
  }

  return (
    <div className="auth">
      <Helmet>
        <title>Registration | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/register-bg.png" alt="bg" />
        </div>
        <div className="auth__right s">
          <div className="auth__content regist">
            <h1 className="auth__title">{step === 1 ? "Sign up" : "Create FBO"}</h1>
            {
              !secondStep ?
                <form className="auth__form">
                  <div className="auth__box">
                    <div className="auth__field" style={{width: '48%'}}>
                      <label htmlFor="email" className="auth__label">
                        First name
                      </label>
                      <input
                        id="first_name"
                        placeholder="First name"
                        type="text"
                        className="auth__input"
                        onChange={inputHandler}
                      />
                      {state?.first_name?.errorShow && (
                        <span className="auth__error">{state?.first_name?.error}</span>
                      )}
                    </div>
                    <div className="auth__field" style={{width: '48%'}}>
                      <label htmlFor="email" className="auth__label">
                        Last name
                      </label>
                      <input
                        id="last_name"
                        placeholder="Last name"
                        type="text"
                        className="auth__input"
                        onChange={inputHandler}
                        autoComplete={"off"}
                      />
                      {state?.last_name?.errorShow && (
                        <span className="auth__error">{state?.last_name?.error}</span>
                      )}
                    </div>
                  </div>
                  {
                    isFboStatus ?
                      <>
                        <div className="auth__box">
                          <div className="auth__field">
                            <label htmlFor="fbo_name" className="auth__label">
                              FBO Name
                            </label>
                            <input
                              id="fbo_name"
                              placeholder="Enter FBO Name"
                              type="text"
                              className="auth__input"
                              onChange={inputHandler}
                            />
                            {state?.fbo_name?.errorShow && (
                              <span className="auth__error">{state?.fbo_name?.error}</span>
                            )}
                          </div>
                          {/*<div className="auth__field">*/}
                          {/*  <label htmlFor="fbo_email" className="auth__label">*/}
                          {/*    FBO Email*/}
                          {/*  </label>*/}
                          {/*  <input*/}
                          {/*    id="fbo_email"*/}
                          {/*    placeholder="FBO Email"*/}
                          {/*    type="text"*/}
                          {/*    className="auth__input"*/}
                          {/*    onChange={inputHandler}*/}
                          {/*  />*/}
                          {/*  {state?.fbo_email?.errorShow && (*/}
                          {/*    <span className="auth__error">{state?.fbo_email?.error}</span>*/}
                          {/*  )}*/}
                          {/*</div>*/}
                        </div>
                        <div className="auth__box">
                          <div className="auth__field">
                            <label htmlFor="airport_id" className="auth__label">
                              Airport ID
                            </label>
                            <input
                              id="airport_id"
                              placeholder="Enter Airport ID"
                              type="text"
                              className="auth__input"
                              onChange={inputHandler}
                              autoComplete={"off"}
                            />
                            {state?.airport_id?.errorShow && (
                              <span className="auth__error">{state?.airport_id?.error}</span>
                            )}
                          </div>
                        </div>
                        <div className="auth__field corporate-dark">
                          <CustomSelect
                            id="fbo_timezone"
                            label="Timezone"
                            name="fbo_timezone"
                            options={timezoneOptions}
                            onChange={inputHandler}
                          />
                          {state?.fbo_timezone?.errorShow && (
                            <span className="auth__error">{state?.fbo_timezone?.error}</span>
                          )}
                        </div>
                      </> : null
                  }
                  {/*<div className="auth__field phone">*/}
                  {/*  <label htmlFor="fbo_phone" className="auth__label">*/}
                  {/*    FBO Phone*/}
                  {/*  </label>*/}
                  {/*  <span className="phone__num">+1</span>*/}
                  {/*  <input*/}
                  {/*    id="fbo_phone"*/}
                  {/*    placeholder="Enter phone number"*/}
                  {/*    type="tel"*/}
                  {/*    value={phoneNumber}*/}
                  {/*    // pattern="\+\d\s\d{3}\s\d{3}-\d{4}"*/}
                  {/*    className="auth__input"*/}
                  {/*    onChange={handleChange}*/}
                  {/*  />*/}
                  {/*  {state?.fbo_phone?.errorShow && (*/}
                  {/*    <span className="auth__error">{state?.fbo_phone?.error}</span>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                  <div className="auth__field">
                    <label htmlFor="password" className="auth__label">
                      Password
                    </label>
                    <input
                      placeholder="Password"
                      id="password"
                      type={eye ? "text" : "password"}
                      className="auth__input"
                      onChange={inputHandler}
                    />
                    <img
                      className="eye"
                      src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                      alt="eye"
                      onClick={() => {
                        setEye(!eye);
                      }}
                    />
                    {state?.password?.errorShow && (
                      <span className="auth__error">{state?.password?.error}</span>
                    )}
                  </div>
                  <div className="auth__field">
                    <label htmlFor="password_confirmation" className="auth__label">
                      Confirm password
                    </label>
                    <input
                      placeholder="Confirm password"
                      id="password_confirmation"
                      type={eye ? "text" : "password"}
                      className="auth__input"
                      onChange={inputHandler}
                    />
                    <img
                      className="eye"
                      src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                      alt="eye"
                      onClick={() => {
                        setEye(!eye);
                      }}
                    />
                    {state?.password_confirmation?.errorShow && (
                      <span className="auth__error">{state?.password_confirmation?.error}</span>
                    )}
                  </div>
                  {/*<div className="auth__field">*/}
                  {/*  <label htmlFor="confirm_password" className="auth__label">*/}
                  {/*    Confirm password*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    placeholder="Confirm password"*/}
                  {/*    id="confirm_password"*/}
                  {/*    type={eye ? "text" : "password"}*/}
                  {/*    className="auth__input"*/}
                  {/*    onChange={inputHandler}*/}
                  {/*  />*/}
                  {/*  <img*/}
                  {/*    className="eye"*/}
                  {/*    src={*/}
                  {/*      eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"*/}
                  {/*    }*/}
                  {/*    alt="eye"*/}
                  {/*    onClick={() => {*/}
                  {/*      setEye(!eye);*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*  {state?.password?.errorShow && (*/}
                  {/*    <span className="auth__error">{state?.password?.error}</span>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                  {
                    isFboStatus ? (
                      <div className="auth__links">
                        <div className="remember">
                          <div className="auth__checkbox">
                            <input type="checkbox" id="remember" defaultValue={termsAgree}
                                   onClick={(e) => setTermsAgree(e.target.checked)}
                            />
                            <label htmlFor="remember">Check if FBO is safety-first certified.</label>
                          </div>
                          {state?.terms?.errorShow && (
                            <span className="auth__error">{state?.terms?.error}</span>
                          )}
                        </div>
                      </div>
                    ) : null
                  }
                  <button className="auth__submit s" onClick={submitHandler}>Sign Up</button>
                  <div className="auth__terms">
                    <Link to="/terms">Terms of use</Link>
                    <Link to="/terms">Privacy policy</Link>
                  </div>
                </form>
                : step === 1 ?
                <form className="auth__form">
                  <div className="auth__box">
                    <div className="auth__field" style={{width: '48%'}}>
                      <label htmlFor="first_name" className="auth__label">
                        First name
                      </label>
                      <input
                        id="first_name"
                        name="first_name"
                        placeholder="First name"
                        type="text"
                        className="auth__input"
                        onChange={handleCorporateChange}
                      />
                    </div>
                    <div className="auth__field" style={{width: '48%'}}>
                      <label htmlFor="last_name" className="auth__label">
                        Last name
                      </label>
                      <input
                        id="last_name"
                        name="last_name"
                        placeholder="Last name"
                        type="text"
                        className="auth__input"
                        onChange={handleCorporateChange}
                        autoComplete={"off"}
                      />
                    </div>
                  </div>

                  <div className="auth__field">
                    <label htmlFor="email" className="auth__label">
                      Email
                    </label>
                    <input
                      id="email"
                      placeholder="Email"
                      type="text"
                      className="auth__input"
                      onChange={handleCorporateChange}
                      value={corporateFboState?.email}
                      readOnly={true}
                    />
                  </div>

                  <div className="auth__field">
                    <label htmlFor="password" className="auth__label">
                      Password
                    </label>
                    <input
                      placeholder="Password"
                      id="password"
                      name="password"
                      type={eye ? "text" : "password"}
                      className="auth__input"
                      onChange={handleCorporateChange}
                    />
                    <img
                      className="eye"
                      src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                      alt="eye"
                      onClick={() => {
                        setEye(!eye);
                      }}
                    />
                  </div>
                  <div className="auth__field">
                    <label htmlFor="password_confirmation" className="auth__label">
                      Confirm password
                    </label>
                    <input
                      placeholder="Confirm password"
                      id="password_confirmation"
                      name={"password_confirmation"}
                      type={eye ? "text" : "password"}
                      className="auth__input"
                      onChange={handleCorporateChange}
                    />
                    <img
                      className="eye"
                      src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                      alt="eye"
                      onClick={() => {
                        setEye(!eye);
                      }}
                    />
                  </div>
                  <div className="auth__links">
                    <div className="remember">
                      <div className="auth__checkbox">
                        <input type="checkbox" id="remember" defaultValue={termsAgree}
                               onClick={(e) => {
                                 setCorporateFboState(old => ({...old, is_safety_first_certified: e.target.checked}))
                                 setTermsAgree(e.target.checked)
                               }}
                        />
                        <label htmlFor="remember">Check if FBO is safety-first certified.</label>
                      </div>
                    </div>
                  </div>

                  <button className="auth__submit s" onClick={submitFirstStep}>Continue</button>
                  <div className="auth__terms">
                    <Link to="/terms">Terms of use</Link>
                    <Link to="/terms">Privacy policy</Link>
                  </div>
                </form>
                : step === 2 ?
                  <form className="auth__form">
                    <div className="auth__box">
                      <div className="auth__field" style={{width: '100%'}}>
                        <label htmlFor="fbo_name" className="auth__label">
                          FBO name
                        </label>
                        <input
                          id="fbo_name"
                          name="fbo_name"
                          placeholder="FBO name"
                          type="text"
                          className="auth__input"
                          onChange={handleCorporateChange}
                          value={corporateFboState?.fbo_name}
                          readOnly={true}
                        />
                      </div>
                    </div>

                    <div className="auth__field corporate-dark">
                      <CustomSelect
                        label="Timezone"
                        name="fbo_timezone"
                        options={timezoneOptions}
                        onChange={handleCorporateChange}
                      />
                    </div>

                    <div className="auth__field">
                      <label htmlFor="airport_id" className="auth__label">
                        Airport ID
                      </label>
                      <input
                        placeholder="Airport ID"
                        id="airport_id"
                        name="airport_id"
                        type={"text"}
                        className="auth__input"
                        onChange={handleCorporateChange}
                        autoComplete={"off"}
                      />
                    </div>

                    <button className="auth__submit s" onClick={submitSecondStep}>{thirdStep ? "Continue" : "Create"}</button>
                  </form>
                  : step === 3 ?
                    <form className="auth__form">
                      <div className="auth__field corporate-dark">
                        <CustomSelect
                          label="When do you want new inspections generated?"
                          name="start_day_minutes"
                          options={timeOptions}
                          onChange={handleCorporateChange}
                        />
                      </div>
                      <div className="auth__box">
                        <div className="auth__field" style={{width: '100%'}}>
                          <label htmlFor="nozzle_fueling_pressure" className="auth__label">
                            Nozzle Fueling Pressure
                          </label>
                          <input
                            id="nozzle_fueling_pressure"
                            name="nozzle_fueling_pressure"
                            placeholder="Nozzle Fueling Pressure"
                            type="number"
                            className="auth__input"
                            onChange={handleCorporateChange}
                          />
                        </div>
                      </div>

                      <div className="auth__field corporate-dark">
                        <CustomSelect
                          label="Sump Solid Rating"
                          name="sump_solid_rating"
                          options={solidRating}
                          onChange={handleCorporateChange}
                        />
                      </div>
                      <div className="auth__field corporate-dark">
                        <CustomSelect
                          label="Sump Water Rating"
                          name="sump_water_rating"
                          options={waterRating}
                          onChange={handleCorporateChange}
                        />
                      </div>

                      <div className="auth__field">
                        <label htmlFor="sump_trend_alert" className="auth__label">
                          Sump Trend Alert
                        </label>
                        <input
                          placeholder="Sump Trend Alert"
                          id="sump_trend_alert"
                          name="sump_trend_alert"
                          type={"number"}
                          className="auth__input"
                          onChange={handleCorporateChange}
                        />
                      </div>
                      <div className="auth__box">
                        <div className="auth__box-checkbox">
                          <Checkbox
                            checked={corporateFboState?.has_hydrant_system}
                            key="has_hydrant_system"
                            id="has_hydrant_system"
                            label={"Has Hydrant System"}
                            onChange={(e) => {
                              setCorporateFboState(old => ({...old, [e.target.name]: e.target.checked}))
                            }}
                          />
                        </div>
                        <div className="auth__box-checkbox">
                          <Checkbox
                            checked={corporateFboState?.has_upstream_tests}
                            label={"Has Upstream Tests"}
                            id={"has_upstream_tests"}
                            key={"has_upstream_tests"}
                            onChange={(e) => setCorporateFboState(old => ({...old, [e.target.name]: e.target.checked}))}
                          />
                        </div>
                      </div>
                      <button className="auth__submit s" onClick={submitThirdStep}>Create</button>
                    </form>
                    : null
            }
          </div>
        </div>
      </div>
    </div>

  );
};


import React, {useEffect, useState} from 'react';
import Datepicker from "../Datepicker";
import Input from "../Input";
import moment from "moment";
import {setFields} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";

const FillDate = ({ readOnly, item, disabled, sheet, category_id, inspection_id }) => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(item?.answers?.length > 0 ? item?.answers[0] : null);

  useEffect(() => {
    let inputData = {
      id: item.id,
      answers: [`${date !== 'Invalid date' && date !== null  ? moment(date).format('MM/DD/YYYY') : ''}`],
      has_comment: false,
      comment_text: item?.comment_text,
      comment_files: item.comment_files,
    };
    dispatch(setFields(inputData));
  }, [date])

  return (
    <div>
      {
        readOnly || disabled ?
          <Input readOnly={true} type={"text"} value={item?.answers[0]} maxwidth={170}  />
          :
          <Datepicker width={200} value={date} setValue={setDate} />
      }
    </div>
  );
};

export default FillDate;
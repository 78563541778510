import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  useDeleteInvitedAgencyMutation, useResendInvitedAgencyMutation
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import UserIcon from "../../SVG/UserIcon";
import Pencil from "../../SVG/Pencil";
import LockIcon from "../../SVG/LockIcon";
import Trash from "../../SVG/Trash";
import {useLocation} from "react-router-dom";

const InvitedAgencyRow = ({item, getInvitedAgency}) => {
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [activeStatus, setActiveStatus] = useState('delete');
  const dispatch = useDispatch();
  const [settingsStatus, setSettingsStatus] = useState(false);
  const location = useLocation();

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (
        e.target.closest(".management__table-settings") === null &&
        e.target.closest(".dots-img") === null &&
        e.target.closest(".management__table-edit") === null
      ) {
        setSettingsStatus(false);
      }
    });
  }, [location]);

  const [
    deleteInvitedAgency, {}
  ] = useDeleteInvitedAgencyMutation();
  const [
    resendInvitedAgency, {}
  ] = useResendInvitedAgencyMutation();
  const handleConfirmModal = (mode , status) => {
    setActiveStatus(status);
    setConfirmStatus(mode);
  };

  const handleSubmit = async () => {
    dispatch(setLoader(true));

    if (activeStatus === 'delete') {
      await deleteInvitedAgency(item?.id)
        .then(async () => {
          toast.success('User deleted successfully!');
          await getInvitedAgency({page: '1'})
        })
        .catch(err => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          dispatch(setLoader(false));
          setConfirmStatus(false)
        })
    }
    else {
        await resendInvitedAgency(item?.id)
          .then(() => {
            toast.success('Invitation resend successfully!');
          })
          .catch(err => {
            toast.error(err.response.data.message);
          })
          .finally(() => {
            dispatch(setLoader(false));
            setConfirmStatus(false)
          })
      }
  }

  const confirmDeleteModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => {
                setConfirmStatus(false);
              }}
            />
          </div>
          <div className="modal__content-body delete">
            <p>{`${activeStatus === 'delete' 
                  ? 'Are you sure you want to delete this user?' 
                  : 'Are you sure you want to resend invitation to this user?'}`}</p>
          </div>
          <div className="modal__content-bottom">
            <button
              className="close"
              onClick={() => {
                setConfirmStatus(false);
              }}
            >
              Cancel
            </button>
            <button className="save" onClick={handleSubmit}>
              {`${activeStatus === 'delete' ? 'Delete' : 'Resend'}`}
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    );
  };
  const tableRowSettings = () => {
    return (
      <div
        className={`management__table-settings`}
      >
        <div
          className="management__table-edit"
          onClick={() => handleConfirmModal(true, 'resend')}
        >
          <Pencil color={"#999999"} />
          <span>Resend</span>
        </div>
        <div
          className="management__table-edit"
          onClick={() => handleConfirmModal(true, 'delete')}
        >
          <Trash color={"#999999"} />
          Delete
        </div>
      </div>
    );
  };

  return (
    <div className="inspectionsForm__table-row inspectionsForm__table-row__invited-row">
      {confirmStatus && confirmDeleteModal()}
      <div
        style={{ width: "50%" }}
        className="inspectionsForm__table-bodyTh"
      >
        {item?.email}
      </div>
      <div
        style={{ width: "50%", textAlign: "right" }}
        className="inspectionsForm__table-bodyTh userSettings"
      >
        <img
          src="/assets/icons/dots.svg"
          alt="dots"
          className="dots-img"
          onClick={() => {
            setSettingsStatus(true);
            // if (lengthArray === indexElem + 1) {
            //   setLastElem(true);
            // } else {
            //   setLastElem(false);
            // }
          }}
        />
        {settingsStatus && tableRowSettings()}
      </div>

    </div>
  );
};

export default InvitedAgencyRow;
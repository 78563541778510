import React, {useEffect, useState} from 'react';
import {
  useGetAgencyQuery,
  useLazyGetAgencyQuery,
  useSetAgencyToUserMutation
} from "../../store/builder/inspections.api";
import './index.scss';
import Input from "../Input";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setLoader} from "../../store/builder/reducer";
import {setAgenciesList} from "../../store/builder/agenciesListSlice";
const FBOList = ({dropdown, setDropdown}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(null);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const agenciesList = useSelector((state) => state?.rootReducer?.agenciesListSlice?.agencies);
  const loader = useSelector((state) => state?.rootReducer?.reducer?.loader)
  const [deactivatedStatus, setDeactivatedStatus] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)

  const [getAgencies, {
    data: agencyData,
    isLoading: agencyIsLoading,
    isError: agencyIsError,
    error: agencyError,
    isSuccess: agencyIsSuccess
  }] = useLazyGetAgencyQuery();

  const [setAgency, {
    data: setAgencyData,
    isLoading: setAgencyIsLoading,
    isError: setAgencyIsError,
    error: setAgencyError,
    isSuccess: setAgencyIsSuccess
  }] = useSetAgencyToUserMutation();

  useEffect(() => {
    getAgencies()
  }, [loader])

  useEffect(() => {
    if (agencyIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
    if (agencyIsSuccess) {
      dispatch(setAgenciesList(agencyData))
    }
  }, [agencyIsLoading, agencyIsSuccess])

  useEffect(() => {
    if (currentTab?.id) {
      localStorage.setItem("current-fbo", currentTab?.id);
      localStorage.setItem("current-fbo-title", currentTab?.title);
    }
  }, [currentTab]);

  useEffect(() => {
    if (userInfo) {
      if (agencyError?.status === 403) {
        localStorage.setItem("current-fbo", userInfo?.agency?.id);
        localStorage.setItem("current-fbo-title", userInfo?.agency?.title);
      } else {
        if (localStorage.getItem("current-fbo")) {
          let s = agenciesList?.filter((item) => item.id == localStorage.getItem("current-fbo"))[0];
          setCurrentTab(s);
        } else {
          setCurrentTab(userInfo?.agency);
          localStorage.setItem("current-fbo", userInfo?.agency?.id);
          localStorage.setItem("current-fbo-title", userInfo?.agency?.title);
        }
      }
    }
  }, [userInfo, agenciesList, location]);

  const filteredData = agencyData?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.title?.toLowerCase()?.includes(search.toLowerCase());
    }
  });

  const renderDeactivated = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head no-bottom">
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivatedStatus(false)}
            />
          </div>
          <div className="modal__content-body">
            <img className="deactivated-img" src="/assets/rafiki.png" alt="bg"/>
            <p className="deactivated">
              The {currentItem?.title} has been deactivated and you need to contact NDX to resolve the issue
            </p>
          </div>
          <div className="modal__content-bottom">
            <button className="save full">
              <a target="_blank" href="https://www.ndxcentral.com/#contact">
                Contact NDX
              </a>
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <div className="fbo__list">
      {deactivatedStatus && renderDeactivated()}
      <div className="fbo__list-content">
        <div className="fbo__list-head">
          <p className="fbo__list-title">FBO Select</p>
          <Input
            type="text"
            placeholder="Search by name"
            name="fboSearch"
            id="fboSearch"
            image={"/assets/icons/search.svg"}
            maxwidth={292}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {
          filteredData?.length > 0 ?
            <div className="fbo__list-box">
              {filteredData?.map((item) => (
                <div
                  className={`fbo__list-elem ${item?.id === currentTab?.id ? "active" : ""}`}
                  key={item?.id}
                  onClick={() => {
                    if (userInfo?.is_corporate) {
                      if (item?.active) {
                        dispatch(setLoader(true))
                        setCurrentTab(item);
                        setAgency({agency_id: item?.id})
                          .then(res => {
                            setTimeout(() => {
                              window.location.href = "/inspections";
                            }, 0);
                          })
                      } else {
                        setCurrentItem(item)
                        setDeactivatedStatus(true);
                      }
                    } else {
                      dispatch(setLoader(true))
                      setCurrentTab(item);
                      setAgency({agency_id: item?.id})
                        .then(res => {
                          setTimeout(() => {
                            window.location.href = "/inspections";
                          }, 0);
                        })
                    }
                  }}
                >
                  <img src={item?.logo} alt="logo"/>
                  <div>
                    <p>
                      {item?.title}
                    </p>
                    <span>
                  ID: {item?.id}
                </span>
                  </div>
                </div>
              ))}
            </div>
            : !agencyIsLoading && filteredData?.length === 0 ?
              <div className="fbo__list-box">
                <p className="fbo__list-nothing">No results found</p>
              </div>
            : null

        }
      </div>

      <div className="fbo__list-bg" onClick={() => setDropdown(false)}></div>
    </div>
  );
};

export default FBOList;
import React from "react";
import Input from "../../Input";

export default function TextField({ id, label, setFields, type, value, fields, field }) {

  return (
    <div className="equipment__container">
      <Input
        label={label}
        type={type}
        id={id}
        containerWidth={"100%"}
        defaultValue={!value ? "" : value}
        onChange={(e) => {
          setFields((old) => {
            let data = [...old.map(item => {
              let newArray = {...item}
              if (newArray?.key === id) {
                newArray.value = e.target.value
              }
              return newArray
            })];
            return data
          })
        }}
      />
    </div>
  );
}

import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import {
  useGetCorporatePricesQuery,
  useGetPricesQuery,
  usePaymentCheckoutMutation, usePaymentCorporateCheckoutMutation
} from "../../store/builder/inspections.api";
import {setSecret} from "../../store/builder/paymentSlice";
import {useNavigate} from "react-router-dom";
import PaymentModal from "../PaymentModal";
import AnotherMethodPaymentModal from "../AnotherPaymentModal";
import {toast} from "react-toastify";

const SubscriptionPlan = ({setExtraShow, setCurrentTab, isCorporate = false}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [subChecked, setSubChecked] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [anotherPaymentModal, setAnotherPaymentModal] = useState(false);

  const {
    data: pricesData,
    isLoading: pricesIsLoading,
    isError: pricesIsError,
    error: pricesError,
    isSuccess: pricesIsSuccess,
  } = useGetPricesQuery();

  const {
    data: pricesCorporateData,
    isLoading: pricesCorporateIsLoading,
    isError: pricesCorporateIsError,
    error: pricesCorporateError,
    isSuccess: pricesCorporateIsSuccess,
  } = useGetCorporatePricesQuery();

  const [checkout, { data, isLoading, isError, error, isSuccess }] =
    usePaymentCheckoutMutation();

  const [checkoutCorporate,
    { data: checkoutCorporateData,
      isLoading: checkoutCorporateIsLoading,
      isError: checkoutCorporateIsError,
      error: checkoutCorporateError,
      isSuccess: checkoutCorporateIsSuccess
    }] = usePaymentCorporateCheckoutMutation();

  useEffect(() => {
    if (pricesIsLoading || pricesCorporateIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
    if (pricesIsError || pricesCorporateIsError) {
      dispatch(setLoader(false))
    }
    if (pricesIsSuccess || pricesCorporateIsSuccess) {
      dispatch(setLoader(false))
    }
  }, [pricesIsLoading, pricesCorporateIsLoading, pricesIsError,
    pricesIsSuccess,
    pricesCorporateIsError,
    pricesCorporateIsSuccess])

  useEffect(() => {
    if (isCorporate) {
      if (userInfo?.corporate?.subscription === "active") {
        if (userInfo?.current_subscription?.period === "yearly") {
          setSubChecked(false)
        } else {
          setSubChecked(true)
        }
      }
    } else {
      if (userInfo?.subscription === "active") {
        if (userInfo?.current_subscription?.period === "yearly") {
          setSubChecked(false)
        } else {
          setSubChecked(true)
        }
      }
    }

  }, [userInfo]);

  const handleSubmit = () => {
    if (isCorporate) {
      checkoutCorporate({
        subscription_period: subChecked ? "monthly" : "yearly",
      }).then((res) => {
        dispatch(setSecret(res?.data?.data?.client_secret))
      });
      setPaymentModal(true)
    } else {
      checkout({
        subscription_period: subChecked ? "monthly" : "yearly",
        custom_form_builder: discountApplied ? '1' : '0'
      }).then((res) => {
        dispatch(setSecret(res?.data?.data?.client_secret))
      });
      setPaymentModal(true)
    }
  }

  function formatStatus(status) {
    return status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <div className="profile__plan">
      {
        paymentModal &&
          <PaymentModal
            exit={() => setPaymentModal(false)}
            changeTab={setCurrentTab}
            setAnotherPaymentModal={setAnotherPaymentModal}
            isCorporate={isCorporate}
          />
      }
      {
        anotherPaymentModal &&
          <AnotherMethodPaymentModal isCorporate={isCorporate} setShowPaymentModal={setAnotherPaymentModal} />
      }
      {
        isCorporate ?
            userInfo?.corporate?.subscription === "active" &&
              <div className="profile__plan-head">
                <p className="profile__plan-title">Current plan</p>
              </div>
          :
            userInfo?.subscription === "active" &&
            <div className="profile__plan-head">
            <p className="profile__plan-title">Current plan</p>
            </div>
      }
      {
        isCorporate ?
          userInfo?.corporate?.subscription === "requires_action" &&
          <div className="profile__plan-head">
            <p className="profile__plan-title">NDX Corporate Plan</p>
          </div>
          :
        userInfo?.subscription === "requires_action" &&
        <div className="profile__plan-head">
          <p className="profile__plan-title">NDX Plan</p>
        </div>
      }
      {
        isCorporate ?
          (userInfo?.corporate?.subscription !== "active" && userInfo?.corporate?.subscription !== "requires_action") &&
          <div className="subscription__type">
            <div className="subscription__type-plans">
              <span
                className={`subscription__type-plan ${
                  !subChecked ? "active" : ""
                }`}
              >
                Yearly
              </span>
              <label className="switch subscriptionInput">
                <input
                  type="checkbox"
                  onChange={(e) => setSubChecked(e.target.checked)}
                />
                <span className="slider round"></span>
              </label>
              <span
                className={`subscription__type-plan ${
                subChecked ? "active" : ""
              }`}
              >
                Monthly
              </span>
            </div>
          </div>
            :
        (userInfo?.subscription !== "active" && userInfo?.subscription !== "requires_action") &&
        <div className="subscription__type">
          <div className="subscription__type-plans">
              <span
                className={`subscription__type-plan ${
                  !subChecked ? "active" : ""
                }`}
              >
                Yearly
              </span>
            <label className="switch subscriptionInput">
              <input
                type="checkbox"
                onChange={(e) => setSubChecked(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
            <span
              className={`subscription__type-plan ${
                subChecked ? "active" : ""
              }`}
            >
                Monthly
              </span>
          </div>
        </div>
      }

      <div className={`subscription__plan ${userInfo?.subscription === 'requires_action' ? 'inactive' : ''}`}>
        <div className="subscription__plan-info">
          {
            isCorporate ?
              userInfo?.corporate?.subscription === "active" ?
                <span>NDX Subscription:</span> :
                <span>Inspection Subscription:</span>
              :
            userInfo?.subscription === "active" ?
              <span>NDX Subscription:</span> :
              <span>Inspection Subscription:</span>
          }

          <div className="subscription__plan-price">
            <p>
              {
                isCorporate ?
                <span className="subscription__plan-discount">
                  {
                    !subChecked ?
                    "$" + pricesCorporateData?.data?.subscription?.yearly + "/year"
                    :
                    "$" + pricesCorporateData?.data?.subscription?.monthly + "/month"
                  }
                </span>
                  :
                <span className="subscription__plan-discount">
                  {
                    !subChecked ?
                    "$" + pricesData?.data?.subscription?.yearly + "/year"
                    :
                    "$" + pricesData?.data?.subscription?.monthly + "/month"
                  }
                </span>
              }
            </p>
            {
              isCorporate ?
                  subChecked ? <p className="subscription__plan-total">${pricesCorporateData?.data?.subscription?.yearly} billed yearly</p>
                    : <p className="subscription__plan-total">${pricesCorporateData?.data?.subscription?.monthly} billed monthly</p>
                :
                  subChecked ? <p className="subscription__plan-total">${pricesData?.data?.subscription?.yearly} billed yearly</p>
                    : <p className="subscription__plan-total">${pricesData?.data?.subscription?.monthly} billed monthly</p>
            }

          </div>
        </div>
      </div>


      {
        !isCorporate &&
        <div className={`subscription__plan ${userInfo?.subscription === 'requires_action' ? 'inactive' : ''}`}>
          <div className="subscription__plan-info">

            {
              userInfo?.subscription === "active" ?
                <span>Custom Form Builder</span> : (
                  <div>
                    <span style={{fontWeight: 600}}>Do you want to add-on the Custom Form Builder?</span>
                    <div className="subscription__plan-info_checkbox">
                      <div className="radio-option">
                        <input
                          type="radio"
                          name={'no'}
                          id={'no'}
                          value={'No'}
                          disabled={userInfo?.subscription === 'requires_action'}
                          style={!discountApplied ? {color: '#4268B0'} : {}}
                          checked={!discountApplied}
                          onChange={(e) => {
                            setDiscountApplied(false)
                          }}
                        />
                        <label style={!discountApplied ? {color: '#4268B0'} : {}} htmlFor={'no'}>No</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          name={'yes'}
                          id={'yes'}
                          checked={discountApplied}
                          disabled={userInfo?.subscription === 'requires_action'}
                          value={'Yes'}
                          onChange={(e) => {
                            setDiscountApplied(true)
                          }}
                        />
                        <label style={discountApplied ? {color: '#4268B0'} : {}} htmlFor={'yes'}>Yes</label>
                      </div>
                    </div>
                  </div>
                )
            }

            <div className="subscription__plan-price">
              <p>
              <span className="subscription__plan-discount">
                {
                  !subChecked ?
                    "$" + pricesData?.data?.custom_form_builder?.yearly + "/year"
                    :
                    "$" + pricesData?.data?.custom_form_builder?.monthly + "/month"
                }
              </span>
              </p>
              {
                subChecked ? <p className="subscription__plan-total">${pricesData?.data?.custom_form_builder?.yearly} billed yearly</p>
                  : <p className="subscription__plan-total">${pricesData?.data?.custom_form_builder?.monthly} billed monthly</p>
              }
            </div>
          </div>
        </div>
      }


      {
        !isCorporate ?
          <div className={`subscription__total ${userInfo?.subscription === 'requires_action' ? 'inactive' : ''}`}>
            <div className="subscription__total-elem">
              {
                userInfo?.subscription !== "active" ?
                  <span>Total:</span>
                  :
                  <span>Paid:</span>
              }

              <span>
              {
                subChecked ? "$" +
                  ( discountApplied
                    ? (+pricesData?.data?.subscription?.monthly + +pricesData?.data?.custom_form_builder?.monthly).toFixed(2)
                    : pricesData?.data?.subscription?.monthly)
                  : "$" +
                  ( discountApplied
                    ? (+pricesData?.data?.subscription?.yearly + +pricesData?.data?.custom_form_builder?.yearly).toFixed(2)
                    : pricesData?.data?.subscription?.yearly)              }
            </span>
            </div>
          </div>
          :
          <div className={`subscription__total ${userInfo?.corporate?.subscription === 'requires_action' ? 'inactive' : ''}`}>
            <div className="subscription__total-elem">
              {
                userInfo?.corporate?.subscription !== "active" ?
                  <span>Total:</span>
                  :
                  <span>Paid:</span>
              }

              <span>
              {
                subChecked ?
                  "$" + Number(pricesCorporateData?.data?.subscription?.monthly)
                  :
                  "$" + Number(pricesCorporateData?.data?.subscription?.yearly)
              }
            </span>
            </div>
          </div>

      }

      {
        isCorporate ?
        userInfo?.corporate?.subscription === "requires_action" &&
        <>
          <div className="subscription__plan">
            <div className="subscription__plan-info column">
              <span style={{fontWeight: 600, marginBottom: 16}}>Payment method</span>
              {userInfo?.corporate?.current_subscription?.payment_method_data ?
                Object.entries(userInfo?.corporate?.current_subscription?.payment_method_data).map(
                  (item) => (
                <p className="subscription__plan-details">
                  {formatStatus(item[0])}:
                  <span>{" " + formatStatus(item[1])}</span>
                </p>
                  )
                ) : null}
              <p className="subscription__plan-details">
                Status:
                <span className="processing">{" " + "Verification Required"}</span>
              </p>
              <p className="subscription__plan-details">
                Verification link:
                <a href={userInfo?.corporate?.current_subscription?.verification_link} target={"_blank"} className="subscription__plan-link">
                  {" " + "Open link"}
                </a>
              </p>
            </div>
          </div>
        </>
          :
          userInfo?.subscription === "requires_action" &&
          <>
            <div className="subscription__plan">
              <div className="subscription__plan-info column">
                <span style={{fontWeight: 600, marginBottom: 16}}>Payment method</span>
                {userInfo?.current_subscription?.payment_method_data ?
                  Object.entries(userInfo?.current_subscription?.payment_method_data).map(
                    (item) => (
                      <p className="subscription__plan-details">
                        {formatStatus(item[0])}:
                        <span>{" " + formatStatus(item[1])}</span>
                      </p>
                    )
                  ) : null}
                <p className="subscription__plan-details">
                  Status:
                  <span className="processing">{" " + "Verification Required"}</span>
                </p>
                <p className="subscription__plan-details">
                  Verification link:
                  <a href={userInfo?.current_subscription?.verification_link} target={"_blank"} className="subscription__plan-link">
                    {" " + "Open link"}
                  </a>
                </p>
              </div>
            </div>
          </>
      }

      {
        isCorporate ?
        userInfo?.corporate?.subscription !== "requires_action" &&
        <button
          className={
            `auth__submit 
          ${userInfo?.corporate?.subscription === "active" ? 'disabled_button' : ''}
            `}
          onClick={handleSubmit}>
          Get Started
        </button>
          :
          userInfo?.subscription !== "requires_action" &&
          <button
            className={
              `auth__submit 
          ${((userInfo?.agency?.trial_end_date && new Date() <= new Date(userInfo?.agency?.trial_end_date))
                || userInfo?.subscription === "active") ? 'disabled_button' : ''}
            `}
            onClick={handleSubmit}>
            Get Started
          </button>
      }
    </div>
  );
};


export default SubscriptionPlan;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setFields } from "../../store/builder/reducer";

export default function FillSelect({ options, index, item, sheet, readOnly, disabled }) {
  const dispatch = useDispatch();
  const [showImages, setShowImages] = useState(null);
  const [selectItem, setSelectItem] = useState(null);
  const [sendData, setSendData] = useState({
    id: null,
    has_comment: null,
    comment_text: null,
    comment_files: null,
    answers: null,
  });

  const handleFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setShowImages(imagesArray);
  };

  const renderImages = () => {
    return showImages?.map((item, index) => (
      <div key={index} className="fillInspection__field-commentImage">
        <div className="fillInspection__field-commentImageDel">
          <img src="/assets/icons/trash2.svg" alt="trash" />
        </div>
        <img className="fillInspection__field-commentImg" src={item} />
      </div>
    ));
  };

  useEffect(() => {
    if (sendData?.id) {
      dispatch(setFields(sendData));
    }
  }, [sendData]);

  useEffect(() => {
    if (showImages && showImages?.length > 0) {
      dispatch(setFields({ ...sendData, comment_files: showImages }));
    }
  }, [showImages]);

  useEffect(() => {
    let data = options?.filter(
      (option) => option.value === item?.answers[0]
    )[0];
    setSelectItem(data);
  }, [options]);
  return (
    <>
      <div className="fillInspection__field-select">
        <Select
          onChange={(e) => {
            setSelectItem(e);
            setSendData({
              id: item.id,
              has_comment: e.has_comment,
              comment_text: "",
              comment_files: [],
              answers: [`${e.value}`],
            });
          }}
          options={options}
          value={selectItem}
          isDisabled={readOnly || disabled ? true : sheet ? true : false}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: "100%",
              height: 42,
              backgroundColor: "#F4F5F6",
            }),
          }}
        />
      </div>
      {selectItem?.has_comment && (
        <div
          style={{ marginTop: 12 }}
          className="fillInspection__field-comment"
        >
          <p className="fillInspection__field-commentName">Comment</p>
          <textarea
            maxLength={255}
            placeholder="Write a problem"
            className="fillInspection__field-commentArea"
            sheet={sheet ? true : false}
            disabled={readOnly || disabled}
            defaultValue={item?.comment_text || ''}
            onInput={(e) => {
              setSendData((old) => ({ ...old, comment_text: e.target.value }));
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "#F4F5F6",
              }),
            }}
          ></textarea>
          {/* <div className="fillInspection__field-commentFile">
            <label htmlFor={"select-images" + index}>
              <span>
                <img src="/assets/icons/camera.svg" alt="camera" />
              </span>
              <p>Choose file</p>
            </label>
            <input
              className="inputFile"
              id={"select-images" + index}
              onChange={handleFile}
              multiple
              type="file"
              accept="image/*"
            />
          </div>
          <div className="fillInspection__field-commentImages">
            {renderImages()}
          </div> */}
        </div>
      )}
    </>
  );
}

import React from 'react';

const EquipmentType = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.66667 4C3.19391 4 2 5.19391 2 6.66667V8.44444C2 9.9172 3.19391 11.1111 4.66667 11.1111H6.44444C7.9172 11.1111 9.11111 9.9172 9.11111 8.44444V6.66667C9.11111 5.19391 7.9172 4 6.44444 4H4.66667ZM6.44444 5.77778H4.66667C4.17575 5.77778 3.77778 6.17575 3.77778 6.66667V8.44444C3.77778 8.93536 4.17575 9.33333 4.66667 9.33333H6.44444C6.93536 9.33333 7.33333 8.93536 7.33333 8.44444V6.66667C7.33333 6.17575 6.93536 5.77778 6.44444 5.77778Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.66667 12.8889C3.19391 12.8889 2 14.0828 2 15.5556V17.3333C2 18.8061 3.19391 20 4.66667 20H6.44444C7.9172 20 9.11111 18.8061 9.11111 17.3333V15.5556C9.11111 14.0828 7.9172 12.8889 6.44444 12.8889H4.66667ZM6.44444 14.6667H4.66667C4.17575 14.6667 3.77778 15.0646 3.77778 15.5556V17.3333C3.77778 17.8243 4.17575 18.2222 4.66667 18.2222H6.44444C6.93536 18.2222 7.33333 17.8243 7.33333 17.3333V15.5556C7.33333 15.0646 6.93536 14.6667 6.44444 14.6667Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4444 11.1111C14.4444 9.63835 15.6384 8.44444 17.1111 8.44444H18.8889C20.3616 8.44444 21.5556 9.63835 21.5556 11.1111V12.8889C21.5556 14.3616 20.3616 15.5556 18.8889 15.5556H17.1111C15.6384 15.5556 14.4444 14.3616 14.4444 12.8889V11.1111ZM17.1111 10.2222H18.8889C19.3798 10.2222 19.7778 10.6202 19.7778 11.1111V12.8889C19.7778 13.3798 19.3798 13.7778 18.8889 13.7778H17.1111C16.6202 13.7778 16.2222 13.3798 16.2222 12.8889V11.1111C16.2222 10.6202 16.6202 10.2222 17.1111 10.2222Z" fill={color}/>
      <path d="M9.11133 8.44477H10.0002C10.4911 8.44477 10.8891 8.84274 10.8891 9.33366V14.667C10.8891 15.1579 10.4911 15.5559 10.0002 15.5559H9.11133V17.3337H10.0002C11.473 17.3337 12.6669 16.1398 12.6669 14.667V12.8892H14.4447V11.1114H12.6669V9.33366C12.6669 7.8609 11.473 6.66699 10.0002 6.66699H9.11133V8.44477Z" fill={color}/>
    </svg>

  );
};

export default EquipmentType;
import React from 'react';

const CommentsEmptyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="169" height="160" viewBox="0 0 169 160" fill="none">
      <path
        d="M89.7779 142.133C128.948 142.133 160.704 110.377 160.704 71.0663C160.704 31.756 128.948 0 89.7779 0C50.6076 0 18.8516 31.756 18.8516 71.0663C18.8516 110.377 50.6076 142.133 89.7779 142.133Z"
        fill="#EAEEF9"/>
      <path
        d="M156.656 30.8717C159.823 30.8717 162.391 28.3038 162.391 25.1361C162.391 21.9683 159.823 19.4004 156.656 19.4004C153.488 19.4004 150.92 21.9683 150.92 25.1361C150.92 28.3038 153.488 30.8717 156.656 30.8717Z"
        fill="#F4F5F9"/>
      <path
        d="M165.048 8.34873C167.211 8.34873 168.965 6.59501 168.965 4.43169C168.965 2.26837 167.211 0.514648 165.048 0.514648C162.885 0.514648 161.131 2.26837 161.131 4.43169C161.131 6.59501 162.885 8.34873 165.048 8.34873Z"
        fill="#F4F5F9"/>
      <path
        d="M13.9522 52.88C16.1155 52.88 17.8692 51.1263 17.8692 48.9629C17.8692 46.7996 16.1155 45.0459 13.9522 45.0459C11.7889 45.0459 10.0352 46.7996 10.0352 48.9629C10.0352 51.1263 11.7889 52.88 13.9522 52.88Z"
        fill="#F4F5F9"/>
      <path
        d="M114.119 29.998C138.269 29.998 157.75 47.561 157.75 69.1521C157.75 81.559 151.31 92.6768 141.167 99.9276V116.202C141.167 118.457 138.591 119.585 136.981 118.296L123.135 107.501C120.237 107.984 117.339 108.306 114.28 108.306C90.131 108.306 70.6504 90.7433 70.6504 69.1521C70.6504 47.561 90.131 29.998 114.119 29.998Z"
        fill="#C9CED8"/>
      <g filter="url(#filter0_d_11828_36712)">
        <path
          d="M71.4569 28.0645C44.5705 28.0645 22.8359 47.5609 22.8359 71.569C22.8359 85.426 30.0808 97.8328 41.3505 105.728V123.774C41.3505 126.191 44.0875 127.642 46.0194 126.03L61.4751 114.107C64.695 114.751 67.915 115.074 71.2959 115.074C98.1823 115.074 119.917 95.577 119.917 71.569C119.917 47.5609 98.1823 28.0645 71.4569 28.0645Z"
          fill="url(#paint0_linear_11828_36712)"/>
      </g>
      <path
        d="M55.6019 73.3405C58.5362 73.3405 60.9148 70.9599 60.9148 68.0233C60.9148 65.0867 58.5362 62.7061 55.6019 62.7061C52.6677 62.7061 50.2891 65.0867 50.2891 68.0233C50.2891 70.9599 52.6677 73.3405 55.6019 73.3405Z"
        fill="#ABB3C7"/>
      <path
        d="M85.7074 73.3405C88.6416 73.3405 91.0203 70.9599 91.0203 68.0233C91.0203 65.0867 88.6416 62.7061 85.7074 62.7061C82.7732 62.7061 80.3945 65.0867 80.3945 68.0233C80.3945 70.9599 82.7732 73.3405 85.7074 73.3405Z"
        fill="#ABB3C7"/>
      <path
        d="M77.3371 83.5676C76.8541 83.5676 76.3711 83.7287 76.0491 84.2121C74.7612 85.9845 72.8292 86.9513 70.5753 86.9513C68.4823 86.9513 66.3893 85.9845 65.2624 84.2121C64.7794 83.4065 63.8134 83.2453 63.0084 83.8899C62.2034 84.3732 62.0424 85.34 62.6864 86.1456C64.4574 88.7237 67.5163 90.335 70.7363 90.335C73.9562 90.335 76.8541 88.7237 78.7861 86.1456C79.2691 85.34 79.1081 84.3732 78.4641 83.8899C77.9811 83.5676 77.6591 83.5676 77.3371 83.5676Z"
        fill="#ABB3C7"/>
      <path
        d="M59.4764 23.9754C59.2823 23.9754 59.0882 23.9754 58.8941 23.9754C57.9235 23.5868 57.3411 22.6154 57.5352 21.644L60.8353 10.9587C61.2235 9.98731 62.1941 9.40447 63.1647 9.59875C64.1353 9.98731 64.7177 10.9587 64.5236 11.9301L61.4177 22.6154C61.2235 23.3926 60.4471 23.9754 59.4764 23.9754Z"
        fill="#B5BCCE"/>
      <path
        d="M51.3248 26.3065C50.5483 26.3065 49.7718 25.7237 49.3836 24.9466L44.3365 9.59851C43.9482 8.62711 44.5306 7.46144 45.5012 7.07288C46.4718 6.68432 47.6365 7.26716 48.0247 8.23855L53.0719 23.5866C53.4601 24.558 52.8778 25.7237 51.9072 26.1122C51.713 26.3065 51.5189 26.3065 51.3248 26.3065Z"
        fill="#B5BCCE"/>
      <path
        d="M43.7527 29.9981C43.1703 29.9981 42.7821 29.8038 42.3938 29.4152L36.182 23.3926C35.4055 22.6155 35.4055 21.4498 36.182 20.6727C36.9585 19.8956 38.1232 19.8956 38.8997 20.6727L45.1115 26.6953C45.888 27.4724 45.888 28.6381 45.1115 29.4152C44.7233 29.8038 44.1409 29.9981 43.7527 29.9981Z"
        fill="#B5BCCE"/>
      <defs>
        <filter id="filter0_d_11828_36712" x="0.835938" y="17.0645" width="141.08" height="142.646"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="11"/>
          <feGaussianBlur stdDeviation="11"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11828_36712"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11828_36712" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_11828_36712" x1="71.3448" y1="25.7827" x2="71.3448" y2="127.774"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDFEFF"/>
          <stop offset="0.9964" stopColor="#ECF0F5"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CommentsEmptyIcon;
import React, {useEffect, useState} from 'react';
import Input from "../../Input";
import CustomSelect from "../../CustomSelect";
import {useGetTimezonesQuery, useUpdateCorporateMutation} from "../../../store/builder/inspections.api";
import {useErrorHandler} from "../../../global/ErrorsHandler";
import {setLoader} from "../../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {validationEmail} from "../../../global/validate";
import Checkbox from "../../Checkbox";

const CorporateEditModal = ({exit, currentCorporateAccount, timezoneOptions, getCorporateListFunc}) => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [timeOptions, setTimeOptions] = useState([
    {
      label: '12:00 AM',
      value: 0,
    },
    {
      label: '1:00 AM',
      value: 60,
    },
    {
      label: '2:00 AM',
      value: 120,
    },
    {
      label: '3:00 AM',
      value: 180,
    },
    {
      label: '4:00 AM',
      value: 240,
    },
    {
      label: '5:00 AM',
      value: 300,
    },
    {
      label: '6:00 AM',
      value: 360,
    },
    {
      label: '7:00 AM',
      value: 420,
    },
    {
      label: '8:00 AM',
      value: 480,
    },
    {
      label: '9:00 AM',
      value: 540,
    },
    {
      label: '10:00 AM',
      value: 600,
    },
    {
      label: '11:00 AM',
      value: 660,
    },
    {
      label: '12:00 PM',
      value: 720,
    },
    {
      label: '1:00 PM',
      value: 780,
    },
    {
      label: '2:00 PM',
      value: 840,
    },
    {
      label: '3:00 PM',
      value: 900,
    },
    {
      label: '4:00 PM',
      value: 960,
    },
    {
      label: '5:00 PM',
      value: 1020,
    },
    {
      label: '6:00 PM',
      value: 1080,
    },
    {
      label: '7:00 PM',
      value: 1140,
    },
    {
      label: '8:00 PM',
      value: 1200,
    },
    {
      label: '9:00 PM',
      value: 1260,
    },
    {
      label: '10:00 PM',
      value: 1320,
    },
    {
      label: '11:00 PM',
      value: 1380,
    },
  ])
  const [waterRating, setWaterRating] = useState([
    {
      value: "A", label: "A. Bright"
    },
    {
      value: "B", label: "B. Hazy"
    },
    {
      value: "C", label: "C. Cloudy"
    },
    {
      value: "D", label: "D. Wet"
    },
    {
      value: "E", label: "E. Surfactants"
    }
  ])
  const [solidRating, setSolidRating] = useState([
    {
      value: 1, label: "1. Clean"
    },
    {
      value: 2, label: "2. Slight"
    },
    {
      value: 3, label: "3. Particulate"
    },
    {
      value: 4, label: "4. Dirty"
    }
  ])
  const [state, setState] = useState({
    title: null,
    timezone: null,
    phone: null,
    email: null,
    start_day_minutes: null,
    sump_water_rating: null,
    sump_solid_rating: null,
    nozzle_fueling_pressure: null,
    sump_trend_alert: null,
    has_upstream_tests: false,
  })

  const [updateCorporate, {
    data: updateCorporateData,
    error: updateCorporateError,
    isLoading: updateCorporateIsLoading,
    isSuccess: updateCorporateIsSuccess,
    isError: updateCorporateIsError,
  }] = useUpdateCorporateMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([updateCorporateError]);

  useEffect(() => {
    if (updateCorporateIsLoading) {
      dispatch(setLoader(true))
    }
    if (updateCorporateIsSuccess) {
      dispatch(setLoader(false))
      exit()
      toast.success("Successfully updated")
      getCorporateListFunc()
    }
    if (updateCorporateIsError) {
      dispatch(setLoader(false))
    }
  }, [updateCorporateIsLoading])

  useEffect(() => {
    if (currentCorporateAccount) {
      setState(old => ({
        ...old,
        title: currentCorporateAccount?.title,
        // timezone: currentCorporateAccount?.timezone,
        phone: currentCorporateAccount?.phone,
        email: currentCorporateAccount?.email,
        start_day_minutes: currentCorporateAccount?.start_day_minutes,
        sump_water_rating: currentCorporateAccount?.sump_water_rating,
        sump_solid_rating: currentCorporateAccount?.sump_solid_rating,
        nozzle_fueling_pressure: currentCorporateAccount?.nozzle_fueling_pressure,
        sump_trend_alert: currentCorporateAccount?.sump_trend_alert,
        has_upstream_tests: !!currentCorporateAccount?.has_upstream_tests,
      }))
      setPhoneNumber(currentCorporateAccount?.phone?.substring(2)?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'))
    }
  }, [currentCorporateAccount])

  const handleInput = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }

  const handleCheckbox = (e) => {
    setState(old => ({...old, has_upstream_tests: e.target.checked}))
  }

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const update = (event) => {
    event.preventDefault();
    if (
      state.nozzle_fueling_pressure &&
      // state.timezone &&
      // state.timezone !== 'utc' &&
      state.start_day_minutes !== null &&
      state.sump_water_rating &&
      state.sump_solid_rating &&
      state.title?.length >= 3 &&
      validationEmail(state.email) &&
      phoneNumber?.length >= 9
    ) {
      updateCorporate({
        body: {
          title: state.title,
          // timezone: state.timezone,
          phone: "+1" + phoneNumber.replace(/\D/g, ''),
          email: state.email,
          start_day_minutes: state.start_day_minutes,
          sump_water_rating: state.sump_water_rating,
          sump_solid_rating: state.sump_solid_rating?.toString(),
          nozzle_fueling_pressure: state.nozzle_fueling_pressure,
          sump_trend_alert: state.sump_trend_alert,
          has_upstream_tests: state.has_upstream_tests ? 1 : 0,
        },
        id: currentCorporateAccount?.id
      })
    }
    // if (!state.sump_trend_alert) {
    //   toast.error('Sump trend alert is required')
    // }
    if (!state.nozzle_fueling_pressure) {
      toast.error('Nozzle fueling pressure is required')
    }
    // if (!state.timezone || state.timezone === null || state.timezone === undefined || state.timezone === 'utc') {
    //   toast.error('Timezone is required')
    // }
    if (state.start_day_minutes === null) {
      toast.error('When do you want new inspections to generate field is required')
    }
    if (!state.sump_water_rating) {
      toast.error('Sump water rating is required')
    }
    if (!state.sump_solid_rating) {
      toast.error('Sump solid rating is required')
    }
    if (state.title?.length < 3) {
      toast.error('Title length should be at least 3 characters')
    }
    if (phoneNumber?.length < 9) {
      toast.error('Enter the valid phone number')
    }
    if (!validationEmail(state.email)) {
      toast.error('Enter the valid email')
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{"Edit Corporate"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body height`}>
          <Input
            containerWidth={'100%'}
            id="title"
            name="title"
            placeholder="Enter Corporate name"
            label={"Corporate name"}
            type={"text"}
            defaultValue={currentCorporateAccount?.title}
            onChange={handleInput}
          />
          <div className="auth__field phone">
            <label htmlFor="corporate_phone" className="auth__label fbo">
              Phone Number
            </label>
            <span className="phone__num">+1</span>
            <input
              id="phone"
              name="phone"
              placeholder="Enter Phone Number"
              type="tel"
              value={phoneNumber}
              className="auth__input"
              onChange={handlePhoneChange}
            />
          </div>
          <Input
            containerWidth={'100%'}
            id="email"
            name="email"
            placeholder="Enter email"
            label={"Email"}
            type={"email"}
            defaultValue={currentCorporateAccount?.email}
            onChange={handleInput}
          />
          {/*<CustomSelect*/}
          {/*  name={"timezone"}*/}
          {/*  label={"Timezone"}*/}
          {/*  type={"timezone"}*/}
          {/*  maxwidth="100%"*/}
          {/*  options={timezoneOptions}*/}
          {/*  defaultValue={currentCorporateAccount?.timezone?.toString()?.length ?*/}
          {/*    timezoneOptions.filter(el => el.value === currentCorporateAccount?.timezone)[0]*/}
          {/*    : null}*/}
          {/*  onChange={handleInput}*/}
          {/*/>*/}
          <div style={{marginBottom: 16}}>
            <CustomSelect
              label={"When do you want new inspections to generate"}
              options={timeOptions}
              name={"start_day_minutes"}
              // onChange={handleStartTime}
              defaultValue={currentCorporateAccount?.start_day_minutes?.toString()?.length ?
                timeOptions.filter(el => el.value === currentCorporateAccount?.start_day_minutes)[0]
                : null}
              onChange={handleInput}
            />
          </div>
          <div style={{marginBottom: 16}}>
            <CustomSelect
              label={"Sump water rating"}
              options={waterRating}
              name={"sump_water_rating"}
              onChange={handleInput}
              defaultValue={currentCorporateAccount?.sump_water_rating?.toString()?.length ?
                waterRating.filter(el => el.value === currentCorporateAccount?.sump_water_rating)[0]
                : null}
            />
          </div>
          <div style={{marginBottom: 16}}>
            <CustomSelect
              label={"Sump Solid Rating"}
              placeholder={"Sump Solid Rating"}
              name={"sump_solid_rating"}
              defaultValue={currentCorporateAccount?.sump_solid_rating ? solidRating.filter(el => el.value == currentCorporateAccount?.sump_solid_rating)[0] : ""}
              onChange={handleInput}
              options={solidRating}
              maxwidth={'100%'}
            />
          </div>
          <Input
            containerWidth={"100%"}
            label={"Nozzle Fueling Pressure"}
            placeholder={"Nozzle Fueling Pressure"}
            name={"nozzle_fueling_pressure"}
            defaultValue={currentCorporateAccount?.nozzle_fueling_pressure}
            onChange={handleInput}
          />
          <Input
            label={"Sump Trend Alert"}
            containerWidth={"100%"}
            placeholder={"Sump Trend Alert"}
            name={"sump_trend_alert"}
            defaultValue={currentCorporateAccount?.sump_trend_alert}
            onChange={handleInput}
          />
          <div className="facility-modal__field" style={{marginTop: 16}}>
            <Checkbox
              key={"has_upstream_tests"}
              label={"Has Upstream Tests"}
              id={"has_upstream_tests"}
              checked={state?.has_upstream_tests}
              onChange={handleCheckbox}
            />
          </div>
        </div>

        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Cancel
          </button>
          <button className="save" onClick={update}>
            Save
          </button>
        </div>
      </div>
      <div className="modal__bg" onClick={exit}></div>
    </div>
  );
};

export default CorporateEditModal;
import React, {useState} from 'react';
import CorrectiveActionModal from "../CorrectiveActionModal";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function CorrectiveActionRow ({action, currentTab, setModalStatus, setChosenCorrectiveAction, chosenCorrectiveAction}) {
  const navigate = useNavigate();
  return (
      currentTab === 0 ?
      <>
        <div className="actions__table-row" key={action.id} onClick={() => {
          setModalStatus(true)
          setChosenCorrectiveAction(action)
        }}>
          <div className="actions__table-bodyTh">{action.equipment_title ? action.equipment_title : '---'}</div>
          <div className="actions__table-bodyTh">{action.equipment_type_title ? action.equipment_type_title : '---'}</div>
          <div className="actions__table-bodyTh">{action.facility_title ? action.facility_title : '---'}</div>
          <div className="actions__table-bodyTh">
            {action.inspection_title ? action.inspection_title : "---"}
            /
            {action.category_title ? action.category_title : "---"}
          </div>

          <div className="actions__table-bodyTh">{action.created_at ? moment(action.created_at).format("MM/DD/YYYY") : '---'}</div>
          <div className="actions__table-bodyTh">{action.comment ? action.comment : '---'}</div>
        </div>
      </>
      : currentTab === 1 ?
      <>
        <div className="actions__table-row" key={action.id} onClick={() => {
          setModalStatus(true)
          setChosenCorrectiveAction(action)
        }}>
          <div className="actions__table-bodyTh closed">{action.equipment_title ? action.equipment_title : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.equipment_type_title ? action.equipment_type_title : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.facility_title ? action.facility_title : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.discrepancy ? action.discrepancy : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.action ? action.action : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.repair_time ? action.repair_time : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.closed_time ? moment(action.closed_time).format("MM/DD/YYYY") : '---'}</div>
          <div className="actions__table-bodyTh closed">{action.closed_by_name ? action.closed_by_name : '---'}</div>
        </div>
      </>
        : null
  );
};


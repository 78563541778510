import React from "react";
import { CartesianGrid, AreaChart, Area, ResponsiveContainer } from "recharts";
import "./index.scss";

const customData = [
  {
    name: "1",
    pv: 2400,
  },
  {
    name: "2",
    pv: 400,
  },
  {
    name: "3",
    pv: 1900,
  },
  {
    name: "4",
    pv: 800,
  },
  {
    name: "5",
    pv: 200,
  },
  {
    name: "6",
    pv: 500,
  },
  {
    name: "7",
    pv: 2500,
  },
];

export default function EquipmentDetailts({ data, setCurrentItem }) {
  const randomStatusProcent = Math.floor(Math.random() * 100);
  return (
    <div className="eq-details">
      <div className="eq-details__content">
        <div className="eq-details__header">
          <div className="eq-details__title">Storage Tank - 1</div>
          <div className="eq-details__close">
            <img onClick={() => setCurrentItem(null)} src="/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div className="eq-details__body">
          <div className="eq-details__row">
            <div className="eq-details__schedule">
              <div className="eq-details__subtitle">MX Schedule</div>
              <div className="eq-schedule__row">
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">Monthly Due</div>
                  <div className="eq-schedule__value">12d</div>
                </div>
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">Quarterly Due</div>
                  <div className="eq-schedule__value">74d</div>
                </div>
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">Semi Annual Due</div>
                  <div className="eq-schedule__value">---</div>
                </div>
              </div>
              <div className="eq-schedule__row">
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">Annual Due</div>
                  <div className="eq-schedule__value">194d</div>
                </div>
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">Meter Cal Due</div>
                  <div className="eq-schedule__value">---</div>
                </div>
                <div className="eq-schedule__item">
                  <div className="eq-schedule__key">DP Cal Due</div>
                  <div className="eq-schedule__value">---</div>
                </div>
              </div>
            </div>
            <div className="eq-details__activity">
              <div className="eq-details__subtitle">Finding Activity</div>
              <div className="eq-activity__row">
                <div className="eq-activity__item">
                  <div className="eq-activity__stat">
                    <div className="key">
                      <span className="green"></span>
                      Open
                    </div>
                    <div className="value">0</div>
                  </div>
                  <div className="eq-activity__stat">
                    <div className="key">
                      <span className="blue"></span>
                      OOS
                    </div>
                    <div className="value">0</div>
                  </div>
                  <div className="eq-activity__stat">
                    <div className="key">
                      <span className="grey"></span>
                      Oldest
                    </div>
                    <div className="value">--</div>
                  </div>
                </div>
                <div className="eq-activity__item">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={customData}>
                      <CartesianGrid vertical={false} strokeDasharray="3 3" />
                      <Area
                        type="monotone"
                        dataKey="pv"
                        stroke="#0DB091"
                        fillOpacity={0.15}
                        fill="#0DB091"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="eq-details__row">
            <div className="eq-details__reseipts">
              <div className="eq-details__subtitle">Receipts</div>
              <div className="eq-reseipts__body">
                <div className="eq-reseipts__item">
                  <div className="key">
                    <span className="green"></span>
                    Receipts Today
                  </div>
                  <div className="value">0</div>
                </div>
                <div className="eq-reseipts__item">
                  <div className="key">
                    <span className="blue"></span>
                    Total Receipts
                  </div>
                  <div className="value">0</div>
                </div>
                <div className="eq-reseipts__item">
                  <div className="key">
                    <span className="grey"></span>
                    Totalizer
                  </div>
                  <div className="value">0</div>
                </div>
                <div className="eq-reseipts__item">
                  <div className="key">
                    <span className="yellow"></span>
                    Gallons Today
                  </div>
                  <div className="value">0</div>
                </div>
                <div className="eq-reseipts__item">
                  <div className="key">
                    <span className="red"></span>
                    Total Gallons
                  </div>
                  <div className="value">0</div>
                </div>
              </div>
            </div>
            <div className="eq-details__utilization">
              <div className="eq-details__subtitle">Utilization</div>
              <div className="eq-utilization__body">
                <div className="left">
                  <div className="eq-utilization__item">
                    <div className="key">
                      <span className="blue"></span>
                      User
                    </div>
                    <div className="value">50%</div>
                  </div>
                  <div className="eq-utilization__item">
                    <div className="key">
                      <span className="yellow"></span>
                      Not Used
                    </div>
                    <div className="value">50%</div>
                  </div>
                </div>
                <div className="right"></div>
              </div>
            </div>
          </div>
          <div className="eq-details__row">
            <div className="eq-details__status">
              <div className="eq-details__subtitle">
                <span>Tank status</span>
                <div className="eq-details__descr">
                  Last updated: 2020-12-13 05:11:52
                </div>
              </div>
              <div className="eq-status__body">
                <div className="eq-status__texts">
                  <div className="eq-status__text">
                    <div className="key">
                      <span className="green"></span>
                      Fuel On-hand
                    </div>
                    <div className="value">--days</div>
                  </div>
                  <div className="eq-status__text">
                    <div className="key">
                      <span className="blue"></span>
                      Gallons In Tank
                    </div>
                    <div className="value">0</div>
                  </div>
                  <div className="eq-status__text">
                    <div className="key">
                      <span className="grey"></span>
                      Tank Safe Fill
                    </div>
                    <div className="value">0</div>
                  </div>
                  <div className="eq-status__text">
                    <div className="key">
                      <span className="yellow"></span>
                      Tank High Level
                    </div>
                    <div className="value">0</div>
                  </div>
                </div>
                <div className="eq-status__data">
                  <div style={{ height: randomStatusProcent + "%" }}>
                    {randomStatusProcent} gal
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="eq-details__bg" onClick={() => setCurrentItem(null)}></div>
    </div>
  );
}

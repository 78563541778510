import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setFields, setFile} from "../../store/builder/reducer";
import {useUploadFileMutation} from "../../store/builder/inspections.api";
import {useParams} from "react-router-dom";
import log from "../../SVG/Log";
import {toast} from "react-toastify";

export default function FillInput({
  option,
  type,
  item,
  index,
  radioOptions,
  setRadioOptions,
  sheet,
  inspection_id,
  readOnly,
  disabled,
  answer,
  setCurrentItem,
  currentItem,
}) {
  const [showImages, setShowImages] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checkedData, setCheckedData] = useState(null);
  const [count, setCount] = useState(0);
  const fields = useSelector((state) => state.rootReducer.reducer.fields);
  const dispatch = useDispatch();
  const params = useParams();
  const [uploadFile, {data, isSuccess, isLoading, isError}] = useUploadFileMutation();
  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
  const handleFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    if (selectedFiles.length > 5) {
      toast.error("Maximum 5 files allowed!");
      return;
    }
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    // IF TYPE "FILES" SET SHOW IMAGES ARRAY
    setShowImages({ preview: imagesArray[0], data: imagesArray });

    for (let i = 0; i < e.target.files.length; i++) {
      const form = new FormData();
      form.append("file", e.target.files[i]);
      form.append('type', "inspection");
      form.append('inspection_id', inspection_id);
      form.append('category_id', item.id);
      form.append('uuid', guid());

      uploadFile(form)
        .then((res) => {
          dispatch(setFile({id: item.id, file: res?.data?.data?.uuid}))
        })
    }
  };
  const renderImages = () => {
    return showImages?.data?.map((item, index) => (
      <div key={index} className="fillInspection__field-commentImage">
        <div className="fillInspection__field-commentImageDel" onClick={() => setShowImages(old => {
          let arr = old?.data?.filter((el) => el !== item);
          return {preview: arr?.[0], data: arr};
        })}>
          <img src="/assets/icons/trash2.svg" alt="trash" />
        </div>
        <img className="fillInspection__field-commentImg" src={item} alt={item} />
      </div>
    ));
  };

  const changeOptionStatus = (e) => {
    if (type === "checkbox") {
      setChecked(e.target.checked);
    }
    if (type === "radio") {
      if (e.target.checked) {
        let data = radioOptions?.map((elem, idx) => {
          if (idx === index) {
            elem.checked = e.target.checked;
          } else {
            elem.checked = false;
          }
          return elem;
        });
        setRadioOptions(data);
        setChecked(e.target.checked);
      }
    }
  };
  useEffect(() => {
    if (checkedData?.id) {
      dispatch(setFields(checkedData));
    }
  }, [checkedData]);

  useEffect(() => {
      if (count < 2) {
        if (item?.data_type === 'fsii_available') {
          setRadioOptions((old) => {
            return old.map((s) => {
              if (item?.answers[0] === s?.value) {
                s.checked = true;
              }
              return s;
            })
          })
        } else {
          setRadioOptions(radioOptions?.map((opt) => {
            if (opt?.value === item?.answers[0]) {
              opt.checked = true;
            }
            return opt;
          }))
        }
        setCount(count + 1);
      }
  }, [radioOptions]);

  useEffect(() => {
    if (type === "checkbox") {
      fields?.forEach((field) => {
        field?.answers?.forEach((answer) => {
          if (answer === option.value) {
            setChecked(true);
          }
        });
      });
    }
  }, []);

  return (
    <>
      <div className="fillInspection__field-optionBox">
        <label className={"fillInspection__field-" + type}>
          {option?.label}
          <input
            name={item.id}
            type={type === "radio" ? "checkbox" : "checkbox"}
            data-comment={option?.has_comment}
            disabled={readOnly || disabled ? true : sheet ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                if (type === "radio") {
                  setCheckedData({
                    id: item.id,
                    has_comment: option.has_comment,
                    comment_text: "",
                    comment_files: item.comment_files,
                    answers: [`${option.value}`],
                  });
                } else {
                  let data = [
                    ...fields?.filter((field) => field.id === item.id),
                  ][0];
                  setCheckedData({
                    id: item.id,
                    has_comment: option.has_comment,
                    comment_text: "",
                    comment_files: item.comment_files,
                    answers: [...data?.answers, e.target.value],
                  });
                }
              } else {
                if (type !== "radio") {
                  let data = [
                    ...fields?.filter((field) => field.id === item.id),
                  ][0];
                  let answers =
                    data?.answers?.length > 1
                      ? data?.answers?.filter(
                          (answer) => answer !== e.target.value
                        )[0]
                      : "";
                  setCheckedData({
                    id: item.id,
                    has_comment: option.has_comment,
                    comment_text: "",
                    comment_files: item.comment_files,
                    answers: [answers],
                  });
                }
              }
              changeOptionStatus(e);
            }}
            value={option.value}
            checked={type === "radio" ? radioOptions[index]?.checked : checked}
          />
          <span
            className={`checkmark${type === "checkbox" ? "-checkbox" : ""} `}
          >
            {type === "checkbox" && (
              <img src="/assets/icons/check.svg" alt="" />
            )}
          </span>
        </label>
      </div>
      {
        (type === "radio" ? radioOptions[index]?.checked : checked) &&
        option?.has_comment && (
          <div className="fillInspection__field-comment">
            <p className="fillInspection__field-commentName">Comment</p>
            <textarea
              maxLength={255}
              placeholder="Write a problem"
              className="fillInspection__field-commentArea"
              defaultValue={item?.comment_text}
              disabled={readOnly ? true : sheet ? true : false}
              onChange={(e) => {
                setCheckedData((old) => ({
                  ...old,
                  comment_text: e?.target?.value,
                  comment_files: item?.comment_files,
                }));
              }}
            ></textarea>
            {
              item.data_type === 'fsii_condition' ? null :
              item?.comment_files ?
              ((!showImages?.preview && !item?.comment_files[0]?.url) ?
                <div className="fillInspection__field-commentFile">
                  <label htmlFor={"select-images" + index}>
                    <span>
                      <img src="/assets/icons/camera.svg" alt="camera" />
                    </span>
                    <p>Choose file</p>
                  </label>
                  <input
                    className="inputFile"
                    id={"select-images" + index}
                    onChange={handleFile}
                    multiple
                    type="file"
                    accept="image/*"
                    disabled={readOnly}
                  />
                </div>
                : (showImages?.preview && !item?.comment_files[0]?.url) ?
                    // <div className="fillInspection__field-commentFile">
                    //   <label htmlFor={"select-images" + index}>
                    //   {/*{*/}
                    //   {/*  showImages?.data?.map((img, i) => {*/}
                    //   {/*    <img key={i} className="fillInspection__field-preview" src={img} alt="file"/>*/}
                    //   {/*  })*/}
                    //   {/*}*/}
                    //     <img className="fillInspection__field-preview" src={showImages?.preview} alt="file"/>
                    //   </label>
                    //   <input
                    //     className="inputFile"
                    //     id={"select-images" + index}
                    //     onChange={handleFile}
                    //     multiple
                    //     type="file"
                    //     accept="image/*"
                    //     disabled={readOnly}
                    //   />
                    // </div>
                    <div className="fillInspection__field-commentImages">
                      {renderImages()}
                    </div>
                    : (!showImages?.preview && item?.comment_files[0]?.url) ?
                      <div className="fillInspection__field-commentFile">
                        <div className="fillInspection__field-commentImages">
                        {
                          item?.comment_files?.map((item, index) => (
                            <div key={index} className="fillInspection__field-commentImage">
                              <img className="fillInspection__field-commentImg" src={item?.url} alt={item} />
                            </div>
                          ))
                        }
                        </div>
                      </div>
                      : (showImages?.preview && item?.comment_files[0]?.url) ?
                        <div className="fillInspection__field-commentFile">
                          <label htmlFor={"select-images" + index}>
                            <img className="fillInspection__field-preview" src={showImages?.preview} alt="file"/>
                          </label>
                          <input
                            className="inputFile"
                            id={"select-images" + index}
                            onChange={handleFile}
                            multiple
                            type="file"
                            accept="image/*"
                            disabled={readOnly}
                          />
                        </div>
                        : null
              ) :
                <div className="fillInspection__field-commentFile">
                  <label htmlFor={"select-images" + index}>
                    <span>
                      <img src="/assets/icons/camera.svg" alt="camera" />
                    </span>
                    <p>Choose file</p>
                  </label>
                  <input
                    className="inputFile"
                    id={"select-images" + index}
                    onChange={handleFile}
                    multiple
                    type="file"
                    accept="image/*"
                    disabled={readOnly}
                  />
                </div>
            }
          </div>
        )}
    </>
  );
}

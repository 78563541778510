import React from 'react';
import GreenGraph from "../../../SVG/GreenGraph";
import RedGraph from "../../../SVG/RedGraph";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {useNavigate} from "react-router-dom";
import GreyGraph from "../../../SVG/GreyGraph";

const COLORS = ['#DA6767', '#E9EAEB'];

const CorporateDashboardCard = ({graph, nfcPercentage = 0, missedPercentage = 0, actionsPercentage = 0, chartData, item}) => {
  const navigate = useNavigate();
  const transformToObjectArray = (originalObject) => {
    const result = [];
    for (const key in originalObject) {
      if (key !== "percentage") {
        const { key: name, value } = originalObject[key];
        result.push({ name, value });
      }
    }
    return result;
  };
  const transformedArray = transformToObjectArray(item?.data?.equipment);

  return (
    <div className="corporate__card">
      <div className="corporate__card-head">
        <div className="corporate__card-detail">
          <img className="corporate__card-avatar" src={item?.agency?.logo} alt="avatar"/>
          <div className="corporate__card-text">
            <p className="corporate__card-title">{item?.agency?.title}</p>
            <p className="corporate__card-subtitle">{item?.agency?.region_label}</p>
          </div>
        </div>
        <div className="corporate__card-detail" onClick={() => navigate(`/advanced-metrics?agency=${item?.agency?.id}`)}>
          <span>Details</span>
          <img src="/assets/icons/agency-arrow.svg" alt="icon"/>
        </div>
      </div>
      <div className="corporate__card-block">
        <div className="corporate__card-stats">
          <div className="corporate__card-left graph">
            <div className="corporate__card-chart">
              <ResponsiveContainer>
                <PieChart width={50} height={50}>
                  <Pie
                    data={transformedArray}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={15}
                    outerRadius={23}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {transformedArray.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <p className="corporate__card-chartPercentage">{item?.data?.equipment?.percentage + "%"}</p>
            </div>
            <div className="corporate__card-text">
              <p className="corporate__card-statsSubTitle">Out of Service</p>
              <p className="corporate__card-statsTitle">{item?.data?.equipment["Out of service(Current)"]?.value}<span>/{item?.data?.equipment["In service"]?.value + item?.data?.equipment["Out of service(Current)"]?.value}</span></p>
            </div>
          </div>
        </div>

        <div className="corporate__card-stats">
          <div className="corporate__card-left graph">
            <div className="corporate__card-text">
              <p className="corporate__card-statsSubTitle">Inspections Completed with NFC</p>
              <p className="corporate__card-statsTitle">{item?.data?.inspections_completed_nfc?.total}</p>
            </div>
          </div>
          <div className={`corporate__card-right ${item?.data?.inspections_completed_nfc?.percentage === 0 ? "grey" : item?.data?.inspections_completed_nfc?.percentage >= 0 ? 'green' : 'red'}`}>
            {
              item?.data?.inspections_completed_nfc?.percentage == 0 ?
                <GreyGraph /> :
              item?.data?.inspections_completed_nfc?.percentage >= 0 ?
                <GreenGraph />
                :
                <RedGraph />
            }
            {
              item?.data?.inspections_completed_nfc?.percentage !== 0 &&
              <img src={`/assets/icons/${item?.data?.inspections_completed_nfc?.percentage === 0 ? 'green-statistic-arrow.svg' : 'red-statistic-arrow.svg'}`} alt="arrow"/>
            }
            <p>{item?.data?.inspections_completed_nfc?.percentage}%</p>
          </div>
        </div>
      </div>

      <div className="corporate__card-block">
        <div className="corporate__card-stats">
          <div className="corporate__card-left">
            <p className="corporate__card-statsSubTitle">Missed Inspections</p>
            <p className="corporate__card-statsTitle">{item?.data?.missed_inspections?.total}</p>
          </div>
          <div className={`corporate__card-right ${item?.data?.missed_inspections?.percentage === 0 ? "grey" : item?.data?.missed_inspections?.percentage >= 0 ? 'green' : 'red'}`}>
            {
              item?.data?.missed_inspections?.percentage !== 0 &&
              <img src={item?.data?.missed_inspections?.percentage >= 0 ? "/assets/icons/green-statistic-arrow.svg" : "/assets/icons/red-statistic-arrow.svg"} alt="arrow"/>
            }
            <p>{item?.data?.missed_inspections?.percentage}%</p>
          </div>
        </div>

        <div className="corporate__card-stats">
          <div className="corporate__card-left graph">
            <div className="corporate__card-text">
              <p className="corporate__card-statsSubTitle">Corrective Actions Created</p>
              <p className="corporate__card-statsTitle">{item?.data?.corrective_actions_created?.total}</p>
            </div>
          </div>
          <div className={`corporate__card-right ${item?.data?.corrective_actions_created?.percentage === 0 ? "grey" : item?.data?.corrective_actions_created?.percentage >= 0 ? 'green' : 'red'}`}>
            {
              item?.data?.corrective_actions_created?.percentage !== 0 &&
              <img src={`/assets/icons/${item?.data?.corrective_actions_created?.percentage >= 0 ? 'green-statistic-arrow.svg' : 'red-statistic-arrow.svg' }`} alt="arrow"/>
            }
            <p>{item?.data?.corrective_actions_created?.percentage}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateDashboardCard;
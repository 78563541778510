import React, {useEffect, useState} from 'react';
import Input from "../Input";
import {
  useGetAgencyQuery, useUpdateAgencyCorrectiveSettingsMutation
} from "../../store/builder/inspections.api";
import CustomSelect from "../CustomSelect";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useErrorHandler} from "../../global/ErrorsHandler";

const CorrectiveActions = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const {data: agencyData, error: agencyError, isLoading: agencyIsLoading, isSuccess: agencyIsSuccess, isError: agencyIsError} = useGetAgencyQuery();
  const [
    updateAgencyCorrectiveSettings,
    {
      data: updateAgancyData,
      isError: updateAgancyIsError,
      isLoading: updateAgancyIsLoading,
      isSuccess: updateAgancyIsSuccess,
      error: updateAgancyError,
    },
  ] = useUpdateAgencyCorrectiveSettingsMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateAgancyError,
  ]);

  const [changeData, setChangeData] = useState({})
  const [solidRating, setSolidRating] = useState([
    {
      value: 1, label: "1. Clean"
    },
    {
      value: 2, label: "2. Slight"
    },
    {
      value: 3, label: "3. Particulate"
    },
    {
      value: 4, label: "4. Dirty"
    }
  ])
  const [waterRating, setWaterRating] = useState([
    {
      value: "A", label: "A. Bright"
    },
    {
      value: "B", label: "B. Hazy"
    },
    {
      value: "C", label: "C. Cloudy"
    },
    {
      value: "D", label: "D. Wet"
    },
    {
      value: "E", label: "E. Surfactants"
    }
  ])

  useEffect(() => {
    if (agencyIsSuccess) {
      setChangeData({
        nozzle_fueling_pressure: userInfo?.agency?.nozzle_fueling_pressure,
        sump_solid_rating: userInfo?.agency?.sump_solid_rating,
        sump_water_rating: userInfo?.agency?.sump_water_rating,
        sump_trend_alert: userInfo?.agency?.sump_trend_alert,
        differential_pressure: userInfo?.agency?.differential_pressure,
        nozzle_fueling_pressure_decommission: userInfo?.agency?.nozzle_fueling_pressure_decommission,
        // differential_pressure_decommission: userInfo?.agency?.differential_pressure_decommission
      })
    }
  }, [agencyIsSuccess, userInfo])


  useEffect(() => {
    if (updateAgancyIsLoading || agencyIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [updateAgancyIsLoading, agencyIsLoading])

  const handleInput = (e) => {
    setChangeData(old => ({...old, [e.target.name]: e.target.value}))
  }

  const updateFbo = () => {
    const formData = new FormData();
    formData.append('sump_solid_rating', changeData?.sump_solid_rating !== null ? changeData?.sump_solid_rating : "");
    formData.append('sump_water_rating', changeData?.sump_water_rating !== null ? changeData?.sump_water_rating : "");
    formData.append('sump_trend_alert', changeData?.sump_trend_alert !== null ? changeData?.sump_trend_alert : "");
    formData.append('nozzle_fueling_pressure', changeData?.nozzle_fueling_pressure !== null ? changeData?.nozzle_fueling_pressure : "");
    formData.append('differential_pressure', changeData?.differential_pressure !== null ? changeData?.differential_pressure : "");
    formData.append('nozzle_fueling_pressure_decommission', changeData?.nozzle_fueling_pressure_decommission !== null ? changeData?.nozzle_fueling_pressure_decommission : "");
    // formData.append('differential_pressure_decommission', changeData?.differential_pressure_decommission);

    updateAgencyCorrectiveSettings(formData)
      .then(res => {
        toast.success(res?.data?.message);
      })
      .catch(error => {
        toast.error(error.message)
      })
  }

  return (
    <div>
      <div className="profile__info profile">
        <div className="profile__info column">
          <p className="profile__title">Corrective Action Settings</p>
          <p className="profile__subtitle">Exceeding below values will generate email alerts</p>
          <div className="profile-corrective-actions">
            <Input
              disabled={userInfo?.agency?.has_corporate_settings}
              tooltip={true}
              tooltipText={"User defined threshold will trigger e-mail alert if inspection input exceeds threshold."}
              label={"Nozzle Fueling Pressure"}
              maxwidth={395}
              placeholder={"Nozzle Fueling Pressure"}
              name={"nozzle_fueling_pressure"}
              defaultValue={userInfo?.agency?.nozzle_fueling_pressure}
              onChange={handleInput}
            />
            <Input
              disabled={userInfo?.agency?.has_corporate_settings}
              tooltip={true}
              tooltipText={"User defined threshold will trigger e-mail alert if inspection input exceeds threshold."}
              label={"Differential Pressure"}
              maxwidth={395}
              placeholder={"Differential Pressure"}
              name={"differential_pressure"}
              defaultValue={userInfo?.agency?.differential_pressure}
              onChange={handleInput}
            />
            <CustomSelect
              disabled={userInfo?.agency?.has_corporate_settings}
              tooltip={true}
              tooltipText={"For example, if you select 2 Slight, you will receive an email if the rating is 2, 3, 4, 5"}
              label={"Sump Solid Rating"}
              tooltipWidth={250}
              placeholder={"Sump Solid Rating"}
              name={"sump_solid_rating"}
              defaultValue={userInfo?.agency?.sump_solid_rating ? solidRating.filter(el => el.value == userInfo?.agency?.sump_solid_rating)[0] : ""}
              onChange={handleInput}
              options={solidRating}
              maxwidth={'100%'}
            />
            <CustomSelect
              disabled={userInfo?.agency?.has_corporate_settings}
              tooltip={true}
              tooltipText={"For example, if you select B Hazy, you will receive an email if the rating is B, C, D, E"}
              label={"Sump Water Rating"}
              tooltipWidth={250}
              maxwidth={'100%'}
              placeholder={"Sump Water Rating"}
              name={"sump_water_rating"}
              defaultValue={userInfo?.agency?.sump_water_rating ? waterRating?.filter(el => el.value === userInfo?.agency?.sump_water_rating)[0] : ""}
              onChange={handleInput}
              options={waterRating}
            />
            <div style={{marginTop: '15px'}}>
            <Input
              disabled={userInfo?.agency?.has_corporate_settings}
              tooltip={true}
              tooltipText={"Nozzle Fueling Pressure Decommission"}
              label={"Nozzle Fueling Pressure Decommission"}
              maxwidth={395}
              placeholder={"Nozzle Fueling Pressure Decommission"}
              name={"nozzle_fueling_pressure_decommission"}
              defaultValue={userInfo?.agency?.nozzle_fueling_pressure_decommission}
              onChange={handleInput}
            />
            </div>
            {/*<div style={{marginTop: '30px'}}>*/}
            {/*<Input*/}
            {/*  tooltip={true}*/}
            {/*  tooltipText={"Differential Pressure Decommission"}*/}
            {/*  label={"Differential Pressure Decommission"}*/}
            {/*  maxwidth={395}*/}
            {/*  placeholder={"Differential Pressure Decommission"}*/}
            {/*  name={"differential_pressure_decommission"}*/}
            {/*  defaultValue={userInfo?.agency?.differential_pressure_decommission}*/}
            {/*  onChange={handleInput}*/}
            {/*/>*/}
            {/*</div>*/}
            <div style={{marginTop: '15px'}}>
              <Input
                disabled={userInfo?.agency?.has_corporate_settings}
                tooltip={true}
                tooltipText={"Sump trend alert will e-mail you when the same sump result is entered for a piece of equipment X number of days in a row"}
                label={"Sump Trend Alert"}
                maxwidth={395}
                placeholder={"Sump Trend Alert"}
                name={"sump_trend_alert"}
                defaultValue={userInfo?.agency?.sump_trend_alert}
                onChange={handleInput}
              />
            </div>

          </div>

      </div>
      <button style={{marginTop: '15px', width: '130px'}} className="profile__methods-btn" onClick={updateFbo}>Save</button>
    </div>
    </div>
  );
};

export default CorrectiveActions;
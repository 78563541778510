import React from 'react';
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useDeleteAgencyMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";

const AgencyDeleteModal = ({exit, currentFBO, getAgencies}) => {
  const dispatch = useDispatch();
  const [deleteAgency, {isLoading, isError, error, data, isSuccess}] = useDeleteAgencyMutation();
  const errorHandler = useErrorHandler([error]);

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Are you sure you want to delete this FBO?</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
          If you click "Delete", this FBO will be removed from the system.
        </div>
        <div className="modal__content-bottom">
          <button className="save danger " onClick={() => {
            dispatch(setLoader(true))
            deleteAgency(currentFBO.id)
              .then(res => {
                dispatch(setLoader(false))
                toast.success(res.data.message)
                exit()
                getAgencies()
              })
              .catch(err => {
                dispatch(setLoader(false))
                exit()
              })
          }}>
            Delete
          </button>
          <button className="close almost" onClick={exit}>
            Cancel
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default AgencyDeleteModal;
import React, { useEffect } from "react";
import { useState } from "react";
import { useUploadFileMutation } from "../../../store/builder/inspections.api";
import Input from "../../Input";
import CustomSelect from "../../CustomSelect";
import {toast} from "react-toastify";

export default function FileField({
  id,
  label,
  setFields,
  equipmentId,
  field,
  files = false,
  fields
}) {
  const [image, setImage] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [galleryStatus, setGalleryStatus] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [currentFileType, setCurrentFileType] = useState(null)
  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  };
  const [uploadFile, { data, isSuccess, isLoading, isError }] =
    useUploadFileMutation();
  useEffect(() => {
    if (image) {
      setIsHovering(false);
    }
  }, [image]);

  const handleImage = (e) => {
    setCurrentFileType(e?.target?.value?.split('.')[e?.target?.value?.split('.').length - 1])
    const selectedFiles = e.target.files;
    if (field?.key === "hose_certs") {
      if (selectedFiles.length <= fields?.filter(el => el.key === 'hose_count')[0]?.value) {
        const selectedFilesArray = Array?.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
          return {file: URL.createObjectURL(file), format: file.name.split('.')[file.name.split('.').length - 1]}
        });

        if (files) {
          let arrayUUID = [];
          for (let i = 0; i < e.target.files.length; i++) {
            const form = new FormData();
            form.append("file", e.target.files[i]);
            form.append("type", "equipment");
            form.append("equipment_id", equipmentId ? equipmentId : 0);
            form.append("uuid", guid());

            setImage({ preview: imagesArray, data: e.target.files[0] });
            uploadFile(form).then((res) => {
              arrayUUID = [...arrayUUID, res?.data?.data?.uuid];
              setFields((old) => {
                // let withoutCurrent = old?.filter((item) => item.key !== id);
                // let current = old?.filter((item) => item.key === id)[0];
                // if (current) {
                //   current.value = res.data.data.uuid
                // } else {
                //   current = {
                //     value: res.data.data.uuid,
                //   };
                // }
                // return [...withoutCurrent, current];
                let data = [
                  ...old.map((item) => {
                    let newArray = { ...item };
                    if (newArray?.key === id) {
                      newArray.value = arrayUUID;
                    }
                    return newArray;
                  }),
                ];
                return data;
              });
            });
          }
        } else {
          const form = new FormData();
          form.append("file", e.target.files[0]);
          form.append("type", "equipment");
          form.append("equipment_id", equipmentId ? equipmentId : 0);
          form.append("uuid", guid());

          setImage({ preview: imagesArray, data: e.target.files[0] });
          uploadFile(form).then((res) => {
            setFields((old) => {
              // let withoutCurrent = old?.filter((item) => item.key !== id);
              // let current = old?.filter((item) => item.key === id)[0];
              // if (current) {
              //   current.value = res.data.data.uuid
              // } else {
              //   current = {
              //     value: res.data.data.uuid,
              //   };
              // }
              // return [...withoutCurrent, current];
              let data = [
                ...old.map((item) => {
                  let newArray = { ...item };
                  if (newArray?.key === id) {
                    newArray.value = res?.data?.data?.uuid;
                  }
                  return newArray;
                }),
              ];
              return data;
            });
          });
        }
      } else if (fields?.filter(el => el.key === 'hose_count')[0]?.value === null || fields?.filter(el => el.key === 'hose_count')[0]?.value === undefined) {
        toast.error(`Choose number of hose count`);
      } else {
        toast.error(`Maximum number of files: ${fields?.filter(el => el.key === 'hose_count')[0]?.value}`);

      }
    } else {
      const selectedFilesArray = Array?.from(selectedFiles);
      const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });

      if (files) {
        let arrayUUID = [];
        for (let i = 0; i < e.target.files.length; i++) {
          const form = new FormData();
          form.append("file", e.target.files[i]);
          form.append("type", "equipment");
          form.append("equipment_id", equipmentId ? equipmentId : 0);
          form.append("uuid", guid());

          setImage({ preview: imagesArray, data: e.target.files[0] });
          uploadFile(form).then((res) => {
            arrayUUID = [...arrayUUID, res?.data?.data?.uuid];
            setFields((old) => {
              // let withoutCurrent = old?.filter((item) => item.key !== id);
              // let current = old?.filter((item) => item.key === id)[0];
              // if (current) {
              //   current.value = res.data.data.uuid
              // } else {
              //   current = {
              //     value: res.data.data.uuid,
              //   };
              // }
              // return [...withoutCurrent, current];
              let data = [
                ...old.map((item) => {
                  let newArray = { ...item };
                  if (newArray?.key === id) {
                    newArray.value = arrayUUID;
                  }
                  return newArray;
                }),
              ];
              return data;
            });
          });
        }
      } else {
        const form = new FormData();
        form.append("file", e.target.files[0]);
        form.append("type", "equipment");
        form.append("equipment_id", equipmentId ? equipmentId : 0);
        form.append("uuid", guid());

        setImage({ preview: imagesArray, data: e.target.files[0] });
        uploadFile(form).then((res) => {
          setFields((old) => {
            // let withoutCurrent = old?.filter((item) => item.key !== id);
            // let current = old?.filter((item) => item.key === id)[0];
            // if (current) {
            //   current.value = res.data.data.uuid
            // } else {
            //   current = {
            //     value: res.data.data.uuid,
            //   };
            // }
            // return [...withoutCurrent, current];
            let data = [
              ...old.map((item) => {
                let newArray = { ...item };
                if (newArray?.key === id) {
                  newArray.value = res?.data?.data?.uuid;
                }
                return newArray;
              }),
            ];
            return data;
          });
        });
      }
    }
  };

  const renderFullPhoto = () => {
    return (
      <div className="modal">
        <img
          className="modal__gallery-photo"
          src={field?.file?.url ? field?.file?.url : currentPhoto}
          alt="photo"
        />
        <div
          className="modal__bg"
        ></div>
      </div>
    );
  };

  return (
    <>
      {galleryStatus && renderFullPhoto()}
      <div className="equipment__container">
        <div className="equipment__file">
          <div className="title">{label}</div>
          <div className="file">
            {isHovering && (
              <label
                htmlFor={id}
                className="file__upload"
                onMouseOver={() => setIsHovering(true)}
              >
                <img src="/assets/icons/upload.svg" alt="upload" />
              </label>
            )}
            <input
              type="file"
              id={id}
              accept="image/png, image/jpeg, .pdf"
              onChange={handleImage}
              multiple={files}
            />
            {field?.file && !image ? (
              <div
                className="file__preview"
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                onClick={() => setGalleryStatus(true)}
              >
                {
                  field?.file?.url ?
                    <img src={field?.file?.url} alt="photo" /> :
                    "N/A"
                }
              </div>
            ) : field?.files?.length > 0 && !image ? (
              <div
                className="file__preview"
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
              >
                {field?.files?.map((item, index) => {
                    if (item?.filename?.split('.')[item?.filename?.split('.')?.length - 1] === 'pdf') {
                      return <a className="file__preview-pdf" href={item?.url} target="_blank">{item?.filename}</a>
                    } else {
                      return <img onClick={() => {
                        setCurrentPhoto(item.url)
                        setGalleryStatus(true)
                      }} src={item.url} alt={`photo ${index}`} key={index} />
                    }
                }
                )}
              </div>
            ) : !image && !field?.file ? (
              <label htmlFor={id}>
                <span>
                  <img src="/assets/icons/camera.svg" alt="camera" />
                </span>
                <p>Choose file</p>
              </label>
            ) : (
              <label htmlFor={id} className="file__preview">
                {image?.preview?.map((item, index) => {
                  if (item?.format === 'pdf') {
                    return <img src={'/assets/icons/pdf-icon.svg'} key={index} alt="photo" />
                  } else {
                    return <img src={item?.file || item} key={index} alt="photo" />
                  }
                  }
                )}
              </label>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

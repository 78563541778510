import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import {useUpdateFboPricesMutation} from "../../store/builder/inspections.api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {useErrorHandler} from "../../global/ErrorsHandler";

const EditPricingModal = ({exit, currentItem, getAgencies}) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [sendData, setSendData] = useState({
    subscription_monthly: null,
    subscription_yearly: null,
    custom_form_builder_monthly: null,
    custom_form_builder_yearly: null,
  });

  const [updatePrice, {
    data: updatePriceData,
    error: updatePriceError,
    isFetching: updatePriceIsLoading,
    isSuccess: updatePriceIsSuccess,
    isError: updatePriceIsError
  }] = useUpdateFboPricesMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([updatePriceError]);

  useEffect(() => {
    if (currentItem) {
      setSendData(old => ({...old,
        subscription_monthly: currentItem?.prices?.subscription_monthly,
        subscription_yearly: currentItem?.prices?.subscription_yearly,
        custom_form_builder_monthly: currentItem?.prices?.custom_form_builder_monthly,
        custom_form_builder_yearly: currentItem?.prices?.custom_form_builder_yearly,
      }))
    }
  }, [currentItem]);

  useEffect(() => {
    if (updatePriceIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Successfully updated!');
      getAgencies();
      exit();
    }
    if (updatePriceIsError) {
      dispatch(setLoader(false));
    }
  }, [updatePriceIsSuccess, updatePriceIsError]);

  const handleChange = (e) => {
    setSendData(old => ({...old, [e.target.name]: e.target.value}))
  }

  const handleSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();
     const ifEmptyInput = Object.values(sendData).some((entry) => !entry);
    if (submitted && !ifEmptyInput) {
      dispatch(setLoader(true))
      updatePrice({id: currentItem?.id, body: sendData})
      setSubmitted(false);
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Edit Pricing</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="modal__content-body">
          <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between"}}>
            <div className="auth__field">
              <label htmlFor="subscription_monthly" className="auth__label">
                Subscription Monthly
              </label>
              <input
                id="subscription_monthly"
                name="subscription_monthly"
                placeholder="Subscription Monthly"
                type="text"
                className="auth__input"
                style={{width: '210px'}}
                onChange={handleChange}
                defaultValue={currentItem ? currentItem?.prices?.subscription_monthly : ""}
              />
              {(submitted && !sendData?.subscription_monthly) && (
                <span className="auth__error">Subscription Monthly is required!</span>
              )}
            </div>
            <div className="auth__field">
              <label htmlFor="subscription_yearly" className="auth__label">
                Subscription Yearly
              </label>
              <input
                id="subscription_yearly"
                name="subscription_yearly"
                placeholder="Subscription Yearly"
                type="text"
                className="auth__input"
                style={{width: '210px'}}
                onChange={handleChange}
                defaultValue={currentItem ? currentItem?.prices?.subscription_yearly : ""}
              />
              {(submitted && !sendData?.subscription_yearly) && (
                <span className="auth__error">Subscription Yearly is required!</span>
              )}
            </div>
          </div>
          <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between"}}>
            <div className="auth__field">
              <label htmlFor="custom_form_builder_monthly" className="auth__label">
                Custom form builder Monthly
              </label>
              <input
                id="custom_form_builder_monthly"
                name="custom_form_builder_monthly"
                placeholder="Subscription Monthly"
                type="text"
                className="auth__input"
                style={{width: '210px'}}
                onChange={handleChange}
                defaultValue={currentItem ? currentItem?.prices?.custom_form_builder_monthly : ""}
              />
              {(submitted && !sendData?.custom_form_builder_monthly) && (
                <span className="auth__error">Custom form builder Monthly is required!</span>
              )}
            </div>
            <div className="auth__field">
              <label htmlFor="custom_form_builder_yearly" className="auth__label">
                Custom form builder Yearly
              </label>
              <input
                id="custom_form_builder_yearly"
                name="custom_form_builder_yearly"
                placeholder="Custom form builder Yearly"
                type="text"
                className="auth__input"
                style={{width: '210px'}}
                onChange={handleChange}
                defaultValue={currentItem ? currentItem?.prices?.custom_form_builder_yearly : ""}
              />
              {(submitted && !sendData?.custom_form_builder_yearly) && (
                <span className="auth__error">Custom form builder Yearly is required!</span>
              )}
            </div>
          </div>
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>Cancel</button>
          <button className="save" onClick={handleSubmit}>Save</button>
        </div>
      </div>
      <div className="modal__bg">
      </div>
    </div>

  );
};

export default EditPricingModal;
import React from 'react';

const CorporateUsersModal = ({exit, chosenUsers}) => {

  return (
    <div className="modal modal-fbo">
      <div className="modal__content corporate">
        <div className="modal__content-head corporate">
          <p>{chosenUsers?.key} <span>{chosenUsers?.count}</span></p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body corporate`}>
          <div className="modal__corporate">
            <div className="modal__corporate-header">
              <div className="modal__corporate-th">Name</div>
              <div className="modal__corporate-th">Email</div>
              <div className="modal__corporate-th">FBO</div>
            </div>
            <div className="modal__corporate-body">
              {
                chosenUsers?.users?.map(item => (
                  <div className="modal__corporate-row">
                    <div className="modal__corporate-bodyTh">
                      <img src={item?.avatar} alt="avatar"/>
                      <span>{item?.name}</span>
                    </div>
                    <div className="modal__corporate-bodyTh">
                      {item?.email}
                    </div>
                    <div className="modal__corporate-bodyTh">
                      {item?.agency_title}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        {/*<div className="modal__content-bottom">*/}
        {/*  <button className="close" onClick={exit}>*/}
        {/*    Close*/}
        {/*  </button>*/}
        {/*  <button className="save">*/}
        {/*    Save*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default CorporateUsersModal;
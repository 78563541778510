import React from 'react';

const Pencil = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.02276 18.3974L2.74741 15.1365C2.91243 14.3938 3.28581 13.7137 3.82373 13.1758L13.6717 3.32782C15.2338 1.76573 17.7664 1.76572 19.3285 3.32782L20.6717 4.67097C22.2338 6.23307 22.2338 8.76572 20.6717 10.3278L10.8237 20.1758C10.2858 20.7137 9.60564 21.087 8.86302 21.2521L5.60211 21.9767C3.45862 22.4531 1.54643 20.5409 2.02276 18.3974ZM4.69978 15.5703L3.97514 18.8312C3.81636 19.5457 4.45375 20.1831 5.16825 20.0243L8.42916 19.2997C8.78626 19.2203 9.11448 19.0446 9.37833 18.7921L5.20743 14.6212C4.95485 14.885 4.77914 15.2132 4.69978 15.5703ZM6.62131 13.2066L10.7929 17.3782L16.3787 11.7924L12.2071 7.62082L6.62131 13.2066ZM19.2575 8.91361L17.7929 10.3782L13.6213 6.2066L15.0859 4.74204C15.8669 3.96099 17.1333 3.96099 17.9143 4.74203L19.2575 6.08518C20.0385 6.86623 20.0385 8.13256 19.2575 8.91361Z" fill={color} />
    </svg>
  );
};

export default Pencil;
import React from 'react';
import {useNavigate} from "react-router-dom";

const MetricsInspectionColumn = ({data, title, type}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`metrics__row`}>
      <div className="metrics__row-head">
        <p>{title}</p>
      </div>
      <div className="metrics__row-box">
        {
          data?.length > 0 ?
            data?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className={`metrics__row-elem 
                  ${type === 'monthly' ? (item?.due_left_days > 4 ? 'grey' : item?.due_left_days <= 4 && item?.due_left_days > 1 ? 'yellow' : 'red') : ''}
                  ${type === 'quarterly' || type === 'semi' ? (item?.due_left_days > 6 ? 'grey' : item?.due_left_days <= 6 && item?.due_left_days > 1 ? 'yellow' : 'red') : ''}
                  ${type === 'annual' ? (item?.due_left_days > 7 ? 'grey' : item?.due_left_days <= 7 && item?.due_left_days > 1 ? 'yellow' : 'red') : ''}
                  `}
                  onClick={() => {
                    if (type === 'monthly') {
                      if (item?.due_left_days <= 4) {
                        navigate(`/inspections/${item?.id}`)
                      } else {
                        return
                      }
                    } else if (type === 'quarterly' || type === 'semi') {
                      if (item?.due_left_days <= 6) {
                        navigate(`/inspections/${item?.id}`)
                      } else {
                        return
                      }
                    } else if (type === 'annual') {
                      if (item?.due_left_days <= 7) {
                        navigate(`/inspections/${item?.id}`)
                      } else {
                        return
                      }
                    } else {
                      return
                    }
                  }}
                >
                  <div className="metrics__row-elemTop">
                    <div className="metrics__row-elem-title">
                      {item?.name}
                    </div>
                    {
                      item?.due_left_days > 4 ? null :
                        <img src="/assets/icons/chevron-right.svg" alt="arrow" className="metrics__row-elemImg"/>
                    }
                  </div>
                  <div className="metrics__row-elemBottom">
                    <div className="metrics__row-elemLeft">
                      <img src="/assets/icons/assignments.svg" alt="assignments"/>
                      <span>{item?.status}</span>
                    </div>
                    <div className="metrics__row-elemRight">
                      <img src="/assets/icons/schedule.svg" alt="schedule"/>
                      <p>
                        Due by:
                        <span
                          className="metrics__row-elemHour">
                      {item?.due_by}
                    </span>
                      </p>
                    </div>
                  </div>
                </div>

              )
            })
            :
            <div className="metrics__column-nodata">
              <img src="/assets/no-data.svg" alt="empty"/>
              <p>No data yet</p>
            </div>
        }
      </div>
    </div>
  );
};

export default MetricsInspectionColumn;
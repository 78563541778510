import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  fields: [],
};

const reducer = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loader = action.payload;
    },
    setFields(state, action) {
      if (action.payload) {
        if (action.payload?.type === "full") {
          state.fields = action.payload?.fields
        } else {
          if (state.fields.length > 0) {
            if (action.payload.delete) {
              state.fields = state.fields?.filter(
                (elem) => elem.id !== action.payload?.id
              );
            } else {
              let s = state.fields.filter(
                (item) => item?.id === action.payload?.id
              )[0];
              let a = state.fields.filter(
                (item) => item.id !== action.payload.id
              );
              if (s) {
                if (action.payload.type === "sump_sample") {
                  if (s.answers.length === 0) {
                    s.answers = action.payload.answers;
                  } else {
                    if (action.payload.answers[0].match(/\D/g)) {
                      if (s.answers[0].length === 1) {
                        if (
                          s.answers[0].match(/\D/g) &&
                          action.payload.answers[0].match(/\D/g)
                        ) {
                          s.answers = [action.payload.answers[0]];
                        } else {
                          s.answers = [
                            s.answers[0][0] + action.payload.answers[0],
                          ];
                        }
                      } else if (s.answers[0].length > 1) {
                        s.answers = [
                          s.answers[0][0] + action.payload.answers[0],
                        ];
                      } else {
                        s.answers = [action.payload.answers[0]];
                      }
                    } else {
                      if (s.answers[0].length === 1) {
                        if (
                          s.answers[0].match(/\d/g) &&
                          action.payload.answers[0].match(/\d/g)
                        ) {
                          s.answers = [action.payload.answers[0]];
                        } else {
                          s.answers = [
                            action.payload.answers[0] + s.answers[0],
                          ];
                        }
                      } else if (s.answers[0].length > 1) {
                        s.answers = [
                          action.payload.answers[0] + s.answers[0][1],
                        ];
                      } else {
                        s.answers = [action.payload.answers[0]];
                      }
                    }
                    // if ()
                    // s.answers = action.payload.answers
                  }
                } else {
                  s.answers = action.payload.answers;
                }
                if (action.payload.type === "dry_rating") {
                  s.required = action.payload.required;
                }
                s.has_comment = action.payload.has_comment;
                s.comment_text = action.payload.comment_text;
                s.comment_files = action.payload.comment_files;
              } else {
                state.fields = [...a, action.payload];
              }
            }
          } else {
            state.fields = [action.payload];
          }
        }
      } else {
        state.fields = [];
      }
    },
    clearFields(state, action) {
      state.fields = action.payload;
    },
    setFile(state, action) {
      state.fields = state.fields.map(item => {
        if (item.id === action.payload.id) {
          item.comment_files = [...item.comment_files, {uuid: action.payload.file}];
        }
        return item
      })
    }
  },
});

export const { setLoader, setFields, clearFields, setFile } = reducer.actions;
export default reducer.reducer;

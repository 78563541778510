import React from 'react';

const RefreshEquipment = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="refresh-ccw">
        <path id="Vector" d="M2.93066 5.4043V10.3512H7.87757" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_2" d="M21.0695 18.5953V13.6484H16.1226" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector_3" d="M18.9999 9.52699C18.5817 8.34533 17.871 7.28885 16.9342 6.45613C15.9973 5.62341 14.8647 5.0416 13.6421 4.76498C12.4196 4.48836 11.1469 4.52595 9.94275 4.87424C8.73864 5.22252 7.64239 5.87016 6.75627 6.75672L2.93066 10.3515M21.0693 13.6494L17.2437 17.2442C16.3576 18.1307 15.2614 18.7784 14.0572 19.1267C12.8531 19.4749 11.5804 19.5125 10.3579 19.2359C9.1353 18.9593 8.00273 18.3775 7.06584 17.5448C6.12895 16.712 5.41827 15.6556 5.00012 14.4739" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>

  );
};

export default RefreshEquipment;
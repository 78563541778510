import React, {useEffect, useState} from 'react';
import './index.scss'

export default function Notification() {
  const [currentTab, setCurrentTab] = useState(1)

  const changeTab = (tabId) => setCurrentTab(tabId)

  return (
    <div className="notification">
      <p className="notification__title">Notifications</p>
      <div className="notification__tabs">
        <div className={`notification__tab ${currentTab === 1 ? 'active' : ''}`} onClick={() => changeTab(1)}>
          <span>All</span>
          <span>2</span>
        </div>
        <div className={`notification__tab ${currentTab === 2 ? 'active' : ''}`} onClick={() => changeTab(2)}>
          <span>Request</span>
          <span>4</span>
        </div>
        <div className={`notification__tab ${currentTab === 3 ? 'active' : ''}`} onClick={() => changeTab(3)}>
          <span>Updated</span>
        </div>
      </div>
      <div className="notification__list">
        <div className="notification__list-elem">
          <img className="notification__list-photo" src="/assets/tranmautritam.png" alt="photo"/>
          <div className="notification__list-info">
            <p className="notification__list-name">Umaka Whitebear</p>
            <p className="notification__list-date">December 20</p>
          </div>
        </div>
      </div>
    </div>
  );
};


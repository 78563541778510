import React from 'react';

const Profile4 = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32 17H16C15.4477 17 15 17.4477 15 18V30C15 30.5523 15.4477 31 16 31H32C32.5523 31 33 30.5523 33 30V18C33 17.4477 32.5523 17 32 17ZM16 15C14.3431 15 13 16.3431 13 18V30C13 31.6569 14.3431 33 16 33H32C33.6569 33 35 31.6569 35 30V18C35 16.3431 33.6569 15 32 15H16Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20 27.5C20 28.3284 19.3284 29 18.5 29C17.6716 29 17 28.3284 17 27.5C17 26.6716 17.6716 26 18.5 26C19.3284 26 20 26.6716 20 27.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M25 27.5C25 28.3284 24.3284 29 23.5 29C22.6716 29 22 28.3284 22 27.5C22 26.6716 22.6716 26 23.5 26C24.3284 26 25 26.6716 25 27.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M13 20H35V22H13V20Z" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>

  );
};

export default Profile4;
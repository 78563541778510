import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Profile1 from "../../SVG/Profile1";
import Profile5 from "../../SVG/Profile5";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import moment from "moment/moment";
import ProfileHistory from "../../components/Profile/ProfileHistory";
import OrderDetailsHistory from "../../components/Profile/OrderDetails";
import FboSettings from "../../components/Profile/FboSettings";
import FboProfile from "../../components/Profile/FboProfile";
import History from "../../SVG/History";
import BillingAddress from "../../components/Profile/BillingAddress";
import {useDispatch, useSelector} from "react-redux";
import UpdatePaymentModal from "../../components/Profile/UpdatePaymentModal";
import BillingAddressModal from "../../components/Profile/BillingAddressModal";
import ExtraPhonesModal from "../../components/Profile/ExtraPhonesModal";
import {useNavigate} from "react-router-dom";
import Profile3 from "../../SVG/Profile3";
import Profile4 from "../../SVG/Profile4";
import Profile6 from "../../SVG/Profile6";
import {useLazyGetPaymentInfoQuery} from "../../store/builder/inspections.api";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";
import NotAvailable from "../../components/NotAvailable";
import SubscriptionPlan from "../../components/Profile/SubscriptionPlan";

const ProfilePlan = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const token = localStorage.getItem("ndx_user_token");
  const [currentTab, setCurrentTab] = useState(0);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [extraShow, setExtraShow] = useState(false);

  const [
    getPaymentInfo,
    {
      data: paymentInfoData,
      isLoading: paymentInfoIsLoading,
      isError: paymentInfoIsError,
      error: paymentInfoError,
      isSuccess: paymentInfoIsSuccess,
    },
  ] = useLazyGetPaymentInfoQuery();

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
  };

  const changeTab = (tabId) => setCurrentTab(tabId);

  return (
    <Wrapper>
      <Helmet>
        <title>My Plan | NDX</title>
      </Helmet>
      <DashboardHeader title={"My NDX Plan"}/>
      {showPaymentModal && (
        <UpdatePaymentModal
          setShowPaymentModal={setShowPaymentModal}
          getUserIndex={getUserIndex}
          getPaymentInfo={getPaymentInfo}
        />
      )}
      {showBillingModal && (
        <BillingAddressModal
          setShowPaymentModal={setShowBillingModal}
          getUserIndex={getUserIndex}
        />
      )}
      {
        showShippingModal && (
          <BillingAddressModal
            setShowPaymentModal={setShowShippingModal}
            getUserIndex={getUserIndex}
          />
        )}
      {showDeleteModal && (
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Delete card</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setShowDeleteModal(false)}
              />
            </div>
            <div
              className="modal__content-body"
              style={{display: "flex", justifyContent: "center"}}
            >
              Are you sure want to remove the card?
            </div>
            <div className="modal__content-bottom">
              <button
                className="close"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className="save danger">Delete</button>
            </div>
          </div>
          <div
            className="modal__bg"
          ></div>
        </div>
      )}
      {extraShow && (
        <ExtraPhonesModal
          setShowExtra={setExtraShow}
          setShowPaymentModal={setShowPaymentModal}
          setShowBillingModal={setShowBillingModal}
          setShowShippingModal={setShowShippingModal}
        />
      )}
      <div className="profile">
        <div className="profile__tabs">
          {userInfo?.role?.name === "admin" && (
            <div
              className={`profile__tab ${currentTab === 0 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(0);
              }}
            >
              <Profile3 active={currentTab === 0 && true}/>
              <p className="profile__tab-name">NDX Plan</p>
            </div>
          )}

          {userInfo?.role?.name === "admin" || userInfo?.role?.name === "management" ? (
            <div
              className={`profile__tab ${currentTab === 1 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(1);
              }}
            >
              <Profile4 active={currentTab === 1 && true}/>
              <p className="profile__tab-name">Payment methods</p>
            </div>
          ) : null}

          {userInfo?.role?.name === "admin" || userInfo?.role?.name === "management" ? (
            <div
              className={`profile__tab ${currentTab === 2 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(2);
              }}
            >
              <Profile6 active={currentTab === 2 && true}/>
              <p className="profile__tab-name">Address</p>
            </div>
          ) : null}

          {userInfo?.role?.name === "admin" && (
            <div
              className={`profile__tab ${currentTab === 3 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(3);
              }}
            >
              <History active={currentTab === 3 && true}/>
              <p className="profile__tab-name">History</p>
            </div>
          )}

        </div>

        <div className="profile__box">
          {currentTab === 0 ? (
            (((userInfo?.agency?.trial_status === 'equipment'
                || userInfo?.agency?.trial_status === 'inspection') && !userInfo?.agency?.is_prices_set)
            ) ? <NotAvailable />
               : <SubscriptionPlan setExtraShow={setExtraShow} setCurrentTab={setCurrentTab} />
          ) : currentTab === 1 ? (
            <div className="profile__methods">
              <div className="profile__methods-head">
                <p className="profile__methods-title">Payment methods</p>
                <button
                  className="profile__methods-btn"
                  onClick={() => setShowPaymentModal(true)}
                >
                  Update
                </button>
              </div>
              {
                paymentInfoData?.data?.payment_method ?
                  <div className="profile__methods-cards">
                    <p>Method: {paymentInfoData?.data?.payment_method}</p>
                    <img
                      className="profile__methods-img"
                      src={paymentInfoData?.data?.icon}
                      alt=""
                    />
                    <div className="profile__methods-card">
                      {paymentInfoData?.data ?
                        Object.entries(paymentInfoData?.data?.details).map(
                          (item) => (
                            <p className="profile__card-text">
                              {item[0]}: <span>{item[1]}</span>
                            </p>
                          )
                        ) : null}
                    </div>

                  </div>
                  :
                  <div style={{width: '100%', display: 'flex', justifyContent:'center'}}>
                    <img src="/assets/no-payment.png" alt="img"/>
                  </div>
              }
            </div>
          ) : currentTab === 3 ? (
            userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection' ?
              <NotAvailable /> :
            !currentOrder ? (
              <ProfileHistory setCurrentOrder={setCurrentOrder}/>
            ) : (
              <OrderDetailsHistory
                currentOrder={currentOrder}
                setCurrentOrder={setCurrentOrder}
              />
            )
          ) : currentTab === 2 ? (
            <BillingAddress/>
          ) : null}
        </div>
      </div>

    </Wrapper>
  );
};

export default ProfilePlan;
import React, {useState} from 'react';
import './index.scss';

const Gallery = ({images, chosenPhoto, exit}) => {
  const [currentPhoto, setCurrentPhoto] = useState(chosenPhoto ? chosenPhoto : images[0]);

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = '_blank';
    link.download = 'downloaded_image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="gallery">
      <div className="gallery__content">
        <div className="gallery__content-header">
          <img src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="gallery__content-main">
          <img className="gallery__content-img" src={currentPhoto} alt="photo"/>
          <img
            src="/assets/icons/download-img.svg"
            alt="download"
            className="gallery__content-download"
            onClick={() => downloadImage(currentPhoto)}
          />
        </div>
        <div className="gallery__content-list">
          {
            images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={'photo'}
                className={`gallery__content-elem ${currentPhoto == image ? 'active' : ''}`}
                onClick={() => setCurrentPhoto(image)}
              />
            ))
          }
        </div>
      </div>
      <div className="gallery__bg" onClick={exit}></div>
    </div>
  );
};

export default Gallery;
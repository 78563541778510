import React, {useEffect, useState} from 'react';
import AgancyIcon from "../../SVG/AgancyIcon";
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const SideFBODropdown = () => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState(false);
  const fboDropDown = localStorage.getItem('navbarDropDown');
  const sidebarStatus = useSelector(state => state?.rootReducer?.inspectionSlice?.sidebar)

  useEffect(() => {
    if (!sidebarStatus) {
      setDropdown(false)
      localStorage.setItem('navbarDropDown', "false")
    }
  }, [sidebarStatus]);

  return (
    <div className={`sidebar__link initial column`}>
      <div
        onClick={() => {
          if (sidebarStatus) {
            setDropdown(!dropdown)
            localStorage.setItem('navbarDropDown', fboDropDown === 'false' ? 'true' : 'false')}}
          }
        className={`sidebar__link-inner column ${location.pathname === '/fbo/trial' || location.pathname === '/fbo/invited' || location.pathname === '/fbo/inactive' || location.pathname === '/fbo/all' ? 'active' : ''}`}>
        <AgancyIcon color={location.pathname === '/fbo/trial' || location.pathname === '/fbo/invited' || location.pathname === '/fbo/inactive' || location.pathname === '/fbo/all' ? '#FFFFFF' : '#AEB3B7' } />
        <p className="sidebar__link-name">FBO</p>
      </div>
      {
        fboDropDown === 'true' &&
        <div className="sidebar__dropdown">
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/fbo/invited' ? 'active' : ''}`}
                   to="/fbo/invited">Invited</NavLink>
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/fbo/trial' ? 'active' : ''}`}
                   to="/fbo/trial">Trial</NavLink>
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/fbo/all' ? 'active' : ''}`}
                   to="/fbo/all">Subscribed</NavLink>
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/fbo/inactive' ? 'active' : ''}`}
                   to="/fbo/inactive">Inactive</NavLink>
          <img className="sidebar__icon one" src="/assets/icons/dropdown-short.svg" alt="icon"/>
          <img className="sidebar__icon two" src="/assets/icons/dropdown-long.svg" alt="icon"/>
          <img className="sidebar__icon three" src="/assets/icons/dropdown-long.svg" alt="icon"/>
          <img className="sidebar__icon four" src="/assets/icons/dropdown-long.svg" alt="icon"/>
        </div>
      }
    </div>
  );
};

export default SideFBODropdown;
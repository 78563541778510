import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {changeCategories} from "../../store/builder/equipmentEditSlice";

export default function EditEquipmentForm ({form, index, category}) {
  const [checked, setChecked] = useState(form.selected ? form.selected : false)
  const dispatch = useDispatch();

  const changeStatus = (e) => {
    setChecked(e.target.checked)
    dispatch(changeCategories({key: category.key, id: form.id, selected: e.target.checked}))
  }

  return (
    <label className="editType__forms-elem checkbox-container" htmlFor={"form_name" + index}>
      <input name="form_name" id={"form_name" + index} type="checkbox" defaultChecked={checked} onChange={changeStatus}/>
      {form.name}
      <span className="checkmark s"></span>
    </label>
  );
};

import React, {useEffect, useState} from 'react';
import './index.scss';
import DashboardHeader from "../../components/DashboardHeader";
import Wrapper from "../../components/Wrapper";
import {useLazyGetSettingsQuery} from "../../store/builder/inspections.api";
import SettingsModal from "../../components/Settings/SettingsModal";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {Helmet} from "react-helmet";

export default function Settings () {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [currentSetting, setCurrentSetting] = useState(null)
  const [getSettings, {data, error, isLoading, isError, isSuccess}] = useLazyGetSettingsQuery();

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [isLoading])

  return (
    <Wrapper>
      <Helmet>
        <title>Settings | NDX</title>
      </Helmet>
      {modal && <SettingsModal setModal={setModal} currentSetting={currentSetting} setCurrentSetting={setCurrentSetting} getSettings={getSettings} />}
      <DashboardHeader title={"Settings"} type={"settings"} />
      <div className="settings">
        <div className="settings__inner">
          <div className="settings__head">
            <div className="settings__head-th">Name</div>
            <div className="settings__head-th">Description</div>
            <div className="settings__head-th">Value</div>
            <div className="settings__head-th"></div>
          </div>
        {
          data?.data?.length ? data?.data?.map(item => (
                (item.name.includes('license') || item.name.includes('device')) ? null :
                (
                  <div className="settings__elem" key={item?.id}>
                    <span className="settings__elem-th">{item?.name}</span>
                    <span className="settings__elem-th">{item?.description}</span>
                    <span className="settings__elem-th">{item?.field_type === 'price' ? '$' : ''}{item?.value}</span>
                    <span className="settings__elem-th">
                      <img onClick={() => {
                        setCurrentSetting(item)
                        setModal(true)
                      }} src="/assets/icons/edit.svg" alt="icon"/>
                    </span>
                  </div>
                )
          )) : (
            <div className='emptyList'>
              <img src="/assets/empty-list.png" alt="empty" />
              <p>No settings data yet</p>
            </div>
          )
        }
        </div>
      </div>
    </Wrapper>
  );
};


import React from 'react';

const CommentsEnterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M24.0937 11.3468L12.6804 5.64018C5.01369 1.80018 1.86702 4.94685 5.70702 12.6135L6.86702 14.9335C7.20035 15.6135 7.20035 16.4002 6.86702 17.0802L5.70702 19.3868C1.86702 27.0535 5.00035 30.2002 12.6804 26.3602L24.0937 20.6535C29.2137 18.0935 29.2137 13.9068 24.0937 11.3468ZM19.787 17.0002H12.587C12.0404 17.0002 11.587 16.5468 11.587 16.0002C11.587 15.4535 12.0404 15.0002 12.587 15.0002H19.787C20.3337 15.0002 20.787 15.4535 20.787 16.0002C20.787 16.5468 20.3337 17.0002 19.787 17.0002Z"
        fill="#4268B0"/>
    </svg>
  );
};

export default CommentsEnterIcon;
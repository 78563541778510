import React from 'react';

const Plus = ({color}) => {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 3.33335C8.37322 3.33335 6.87355 3.46752 5.66984 3.6265C4.58652 3.76957 3.76963 4.58646 3.62656 5.66978C3.46759 6.87349 3.33341 8.37316 3.33341 10C3.33341 11.6269 3.46759 13.1266 3.62656 14.3303C3.76963 15.4136 4.58652 16.2305 5.66984 16.3735C6.87355 16.5325 8.37322 16.6667 10.0001 16.6667C11.6269 16.6667 13.1266 16.5325 14.3303 16.3735C15.4136 16.2305 16.2305 15.4136 16.3736 14.3303C16.5326 13.1266 16.6667 11.6269 16.6667 10C16.6667 8.37316 16.5326 6.87349 16.3736 5.66978C16.2305 4.58646 15.4136 3.76957 14.3303 3.6265C13.1266 3.46752 11.6269 3.33335 10.0001 3.33335ZM5.45161 1.97418C3.62095 2.21596 2.21602 3.62089 1.97424 5.45155C1.80803 6.71009 1.66675 8.28442 1.66675 10C1.66675 11.7156 1.80803 13.29 1.97424 14.5485C2.21602 16.3792 3.62095 17.7841 5.45161 18.0259C6.71015 18.1921 8.28448 18.3334 10.0001 18.3334C11.7157 18.3334 13.29 18.1921 14.5485 18.0259C16.3792 17.7841 17.7841 16.3792 18.0259 14.5485C18.1921 13.29 18.3334 11.7156 18.3334 10C18.3334 8.28442 18.1921 6.71009 18.0259 5.45155C17.7841 3.62089 16.3792 2.21596 14.5485 1.97418C13.29 1.80797 11.7157 1.66669 10.0001 1.66669C8.28448 1.66669 6.71015 1.80797 5.45161 1.97418Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99992 14.1666C10.4602 14.1666 10.8333 13.7936 10.8333 13.3333V10.8333H13.3333C13.7935 10.8333 14.1666 10.4602 14.1666 9.99998C14.1666 9.53974 13.7935 9.16665 13.3333 9.16665H10.8333V6.66665C10.8333 6.20641 10.4602 5.83331 9.99992 5.83331C9.53968 5.83331 9.16659 6.20641 9.16659 6.66665V9.16665H6.66659C6.20635 9.16665 5.83325 9.53974 5.83325 9.99998C5.83325 10.4602 6.20635 10.8333 6.66659 10.8333H9.16659V13.3333C9.16659 13.7936 9.53968 14.1666 9.99992 14.1666Z" fill={color}/>
      </svg>
  );
};

export default Plus;
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import DashboardHeader from "../../components/DashboardHeader";
import FacilityModal from "../../components/FacilityModal";
import Input from "../../components/Input";
import Wrapper from "../../components/Wrapper";
import {useLazyGetFacilityIndexQuery, useRemoveFacilityMutation, useUnAssignFacilityMutation,} from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import CustomPaginate from "../../components/PaginateComponent";

export default function Facility() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [unAssignStatus, setUnAssignStatus] = useState(false);
  const [chosenFacility, setChosenFacility] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [getFacility,{
    data: facilityData,
    isError: facilityIsError,
    error: facilityError,
    isSuccess: facilityIsSuccess,
    isFetching: facilityIsLoading,
  }] = useLazyGetFacilityIndexQuery();

  const [removeFacility,{
    data: removeFacilityData,
    isError: removeFacilityIsError,
    error: removeFacilityError,
    isSuccess: removeFacilityIsSuccess,
    isFetching: removeFacilityIsLoading,
  }] = useRemoveFacilityMutation();

  const [
    unAssign,
    {
      data: unAssignData,
      isError: unAssignIsError,
      error: unAssignError,
      isSuccess: unAssignIsSuccess,
      isFetching: unAssignIsLoading,
    },
  ] = useUnAssignFacilityMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([facilityError]);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modal])

  useEffect(() => {
    getFacility(currentPage)
  }, [currentPage])
  useEffect(() => {
    if (facilityIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [facilityIsLoading]);

  const filteredData = facilityData?.data?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.name?.toLowerCase()?.includes(search.toLowerCase());
    }
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  const getData = () => {
    getFacility(currentPage)
  }

  const renderUnAssignModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Unassign</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUnAssignStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to unassign the NFC Tag?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setUnAssignStatus(false)
            }}>
              Cancel
            </button>
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              unAssign(chosenFacility)
                .unwrap()
                .then((res) => {
                  dispatch(setLoader(false))
                  toast.success(res?.message ? res?.message : res?.data?.message)
                  setUnAssignStatus(false)
                  getFacility(currentPage)
                  setChosenFacility(null)
                })
                .catch(err => {
                  dispatch(setLoader(false))
                })
            }}>
              Unassign
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderDeleteModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to delete this facility?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeleteModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            If you click "Delete", this facility will be removed from the system.
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              removeFacility(chosenFacility)
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDeleteModal(false)
                  setChosenFacility(null)
                  getFacility(currentPage)
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setDeleteModal(false)
                })
            }}>
              Delete
            </button>
            <button className="close almost" onClick={() => setDeleteModal(false)}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>

    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Facility | NDX</title>
      </Helmet>
      {modal && <FacilityModal setModal={setModal} getData={getData} />}
      {unAssignStatus && renderUnAssignModal()}
      {deleteModal && renderDeleteModal()}
      <DashboardHeader
        title={"Facility"}
        showBtn={true}
        type="facility"
        action={() => setModal(true)}
      />
      <div className="facility">
        <div className="facility__content">
          <div className="facility__search">
            <Input
              type="text"
              placeholder="Search by name or airport ID"
              name="facilitySearch"
              id="facilitySearch"
              image={"/assets/icons/search.svg"}
              maxwidth={264}
              onChange={handleSearch}
            />
          </div>
          <div className="facility__table">
            <div className="facility__table-header">
              <div className="facility__th">Facility name</div>
              <div className="facility__th">Total Inspections</div>
              <div className="facility__th">Inspections Enabled</div>
              <div className="facility__th"></div>
              <div className="facility__th"></div>
            </div>
            {filteredData?.length > 0 ? (
              <div className="facility__table-body">
                {filteredData?.map((item, index) => (
                  <div className="facility__row" key={index}>
                    <div className="facility__td">{item?.name}</div>
                    <div className="facility__td">{item?.links?.length}</div>
                    <div className="facility__td">{item?.inspections_enabled ? "Enabled" : "Not Enabled"}</div>
                    <div className="facility__td">
                      <p className="equipment__table-title">NFC</p>
                      {
                        item?.beacon_address
                        ? (
                        <p className="equipment__table-subtitle">
                          <img
                            src="/assets/icons/nfc-active.png"
                            alt="icon"
                          />
                          <img
                            src="/assets/icons/beacon-address.svg"
                            onClick={() => {
                              setUnAssignStatus(true);
                              setChosenFacility(item.id);
                            }}
                            alt="icon"
                          />
                        </p>
                      )
                        : (
                        <p className="equipment__table-subtitle">
                          <img src="/assets/icons/nfc-inactive.png" alt="icon" />
                        </p>
                      )}
                    </div>
                    <div className="facility__td actions-facility">
                      <div
                        onClick={() => navigate("/facility/edit/" + item.id)}
                      >
                        Edit
                      </div>
                      {
                        userInfo?.is_global &&
                        (
                          <div className="facility__td-delete" onClick={() => {
                            setDeleteModal(true)
                            setChosenFacility(item?.id)
                          }}>
                            Delete
                          </div>
                        )
                      }
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="facility__table-empty">
                <img
                  src="/assets/icons/facility-empty.svg"
                  alt="empty"
                  onClick={() => {
                    setModal(true);
                  }}
                />
                <p>There are no Facilities!</p>
              </div>
            )}
          </div>
          <CustomPaginate
            pageCount={facilityData?.pages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Wrapper>
  );
}

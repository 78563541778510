import React from "react";
import Input from "../Input";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import {
  useGetCorporateSecretKeyMutation,
  useGetSecretKeyMutation,
  usePaymentConfirmMutation, useUpdateCorporatePaymentMutation,
  useUpdatePaymentMutation,
} from "../../store/builder/inspections.api";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../store/builder/reducer";
import { toast } from "react-toastify";
import { AddressElement } from "@stripe/react-stripe-js";
import {useErrorHandler} from "../../global/ErrorsHandler";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const CheckoutForm = ({
  setShowPaymentModal,
  getUserIndex,
  getPaymentInfo,
  isCorporate,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    updatePayment,
    { data, isLoading, isSuccess, isError, error: paymentError },
  ] = useUpdatePaymentMutation();
  const [
    updateCorporatePayment,
    { data: updateCorporatePaymentData, isLoading: updateCorporatePaymentIsLoading, isSuccess: updateCorporatePaymentIsSuccess, isError: updateCorporatePaymentIsError, error: updateCorporatePaymentError },
  ] = useUpdateCorporatePaymentMutation()
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setLoader(true));
    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmSetup({
        elements,
        // confirmParams: {
        //   return_url: 'http://localhost:3002/payment-result',
        // },
        redirect: "if_required",
      })
      .then((res) => {
        dispatch(setLoader(true));
        if (isCorporate) {
          updateCorporatePayment({
            payment_method: res?.setupIntent?.payment_method,
            setup_secret: res?.setupIntent?.id,
          })
            .then((res) => {
              toast.success(res.data.message);
              getUserIndex();
              getPaymentInfo();
              setShowPaymentModal(false);
            })
            .catch((err) => {
              toast.error(err.response.message);
              dispatch(setLoader(false));
            });
          if (res.error) {
            toast.error(res?.error?.message);
          }
          dispatch(setLoader(false));
        } else {
          updatePayment({
            payment_method: res?.setupIntent?.payment_method,
            setup_secret: res?.setupIntent?.id,
          })
            .then((res) => {
              toast.success(res.data.message);
              getUserIndex();
              getPaymentInfo();
              setShowPaymentModal(false);
            })
            .catch((err) => {
              toast.error(err.response.message);
              dispatch(setLoader(false));
            });
          if (res.error) {
            toast.error(res?.error?.message);
          }
          dispatch(setLoader(false));
        }

      })
      .catch((err) => {
        toast.error(err?.error?.message);
        dispatch(setLoader(false));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="SubmitButton" disabled={!stripe}>
        Save
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const UpdatePaymentModal = ({
  setShowPaymentModal,
  getUserIndex,
  getPaymentInfo,
  isCorporate = false,
}) => {
  const [secretKey, setSecretKey] = useState(null);
  const [getKey, { data, isLoading, isSuccess, isError, error: secretError }] =
    useGetSecretKeyMutation();
  const [getCorporateKey, { data: getCorporateKeyData, isLoading: getCorporateKeyIsLoading, isSuccess: getCorporateKeyIsSuccess, isError: getCorporateKeyIsError, error: getCorporateKeyError }] = useGetCorporateSecretKeyMutation();
  const [errorHandler, setErrorHandler] = useErrorHandler([secretError, getCorporateKeyError]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCorporate) {
      getCorporateKey();
    } else {
      getKey();
    }
  }, []);

  useEffect(() => {
    dispatch(setLoader(true))
    if (isSuccess || getCorporateKeyIsSuccess) {
      if (isCorporate) {
        setSecretKey(getCorporateKeyData?.data?.client_secret);
      } else {
        setSecretKey(data?.data?.client_secret);
      }
      dispatch(setLoader(false))
    }
    if (isError || getCorporateKeyIsError) {
      dispatch(setLoader(false))
    }
  }, [isSuccess, getCorporateKeyIsSuccess, getCorporateKeyIsError, getCorporateKeyIsError, isError]);

  return (
    <div className="modal payment__main">
      <div className="modal__content">
        <div className="modal__content-head">
          <p id="stripeModal">Update payment method</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => setShowPaymentModal(false)}
          />
        </div>
        <div className="modal__content-body">
          {secretKey ? (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: secretKey }}
            >
              <CheckoutForm
                setShowPaymentModal={setShowPaymentModal}
                getUserIndex={getUserIndex}
                getPaymentInfo={getPaymentInfo}
                isCorporate={isCorporate}
              />
            </Elements>
          ) : (
            <div className="skeleton-loader"></div>
          )}
        </div>
        {/*<div className="modal__content-bottom">*/}
        {/*  <button className="save full">*/}
        {/*    Save*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
      <div
        className="modal__bg"
      ></div>
    </div>
  );
};

export default UpdatePaymentModal;

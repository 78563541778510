import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { inspectionApi } from "./builder/inspections.api";
import inspectionSlice from "./builder/inspectionSlice";
import reducer from "./builder/reducer";
import loginSlice from "./builder/loginSlice";
import equipmentEditSlice from "./builder/equipmentEditSlice";
import forgotPasswordSlice from './builder/forgotPasswordSlice'
import registerSlice from "./builder/registerSlice";
import paymentSlice from "./builder/paymentSlice";
import agenciesListSlice from "./builder/agenciesListSlice";

const rootReducer = combineReducers({
  inspectionSlice,
  reducer,
  loginSlice,
  equipmentEditSlice,
  forgotPasswordSlice,
  registerSlice,
  paymentSlice,
  agenciesListSlice,
});

export const store = configureStore({
  reducer: {
    [inspectionApi.reducerPath]: inspectionApi.reducer,
    rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(inspectionApi.middleware),
});

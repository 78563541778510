import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import Input from "../../components/Input";
import Select from "react-select";
import {
  useDeleteEquipmentMutation,
  useEquipmentActivateMutation,
  useEquipmentDecommissionMutation, useGetEquipmentTypeQuery,
  useLazyEquipmentDecommissionQuery,
  useLazyGetEquipmentQuery, useLazyGetFacilityQuery,
  useUnAssignEquipmentMutation,
} from "../../store/builder/inspections.api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import EquipmentDetailts from "../../components/EquipmentDetails";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import CustomPaginate from "../../components/PaginateComponent";
import CustomSelect from "../../components/CustomSelect";
import { Helmet } from "react-helmet";
import {useDebounce} from "../LogSheets/hooks";
import EquipmentManagementRow from "../../components/EquipmentManagementRow";
import ToolsRow from "../../components/ToolsComponents/ToolsRow";
import ToolsModal from "../../components/ToolsComponents/ToolsModal";

export default function EquipmentManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const pageTab = urlParams.get("active");
  const [currentTab, setCurrentTab] = useState(
    pageTab && pageTab !== "" ? pageTab : 0
  );
  const [currentPage, setCurrentPage] = useState(1)
  const [currentItem, setCurrentItem] = useState(false);
  const [search, setSearch] = useState(urlParams.get('search') ? urlParams.get('search') : '');
  const [confirmModal, setConfirmModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentId,setCurrentId] = useState(null)
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([])
  const [facilityOptions, setFacilityOptions] = useState([])
  const [filters, setFilters] = useState({
    equipment_type_id: null,
    facility_id: null
  })
  const [currentTool, setCurrentTool] = useState(null)
  const [toolsModal, setToolsModal] = useState(false)
  const debouncedSearchValue = useDebounce(search, 500);


  const changeTab = (tabId) => {
    setCurrentTab(tabId);
  };

  const [
    getEquipment,
    {
      data: equipmentData,
      isError: equipmentIsError,
      error: equipmentError,
      isSuccess: equipmentIsSuccess,
      isFetching: equipmentIsLoading,
    },
  ] = useLazyGetEquipmentQuery();
  const [getFacility,
    {
      data: facilityData,
      isError: facilityIsError,
      error: facilityError,
      isSuccess: facilityIsSuccess,
      isFetching: facilityIsLoading,
    },] = useLazyGetFacilityQuery();
  const [
    decommission,
    {
      data: decommissionData,
      isLoading: decommissionIsLoading,
      error: decommissionError,
    },
  ] = useEquipmentDecommissionMutation();
  const [
    activate,
    { data: activateData, isLoading: activateIsLoading, error: activateError },
  ] = useEquipmentActivateMutation();
  const [
    unAssign,
    {
      data: unAssignData,
      isError: unAssignIsError,
      error: unAssignError,
      isSuccess: unAssignIsSuccess,
      isFetching: unAssignIsLoading,
    },
  ] = useUnAssignEquipmentMutation();

  const {data: equipmentTypeData,
    isError: equipmentTypeIsError,
    error: equipmentTypeError,
    isSuccess: equipmentTypeIsSuccess,
    isFetching: equipmentTypeIsLoading,} = useGetEquipmentTypeQuery();

  const [deleteEquipment, {data: deleteEquipmentTypeData,
    isError: deleteEquipmentIsError,
    error: deleteEquipmentError,
    isSuccess: deleteEquipmentIsSuccess,
    isFetching: deleteEquipmentIsLoading}] = useDeleteEquipmentMutation()

  useEffect(() => {
    getFacility()
  }, [])

  useEffect(() => {
    setFilters(old => ({...old,
      equipment_type_id: urlParams.get('equipment_type') ? urlParams.get('equipment_type') : null,
      facility_id: urlParams.get('facility') ? urlParams.get('facility') : null}))
  }, [])

  useEffect(() => {
    getEquipment({active: pageTab == 0 ? `true` : pageTab === "" ? "true" : "false", page: 1,
      equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,
      facility_id: filters.facility_id ? filters.facility_id : null,
      search: debouncedSearchValue ? debouncedSearchValue : null,
    });
  }, [filters, debouncedSearchValue]);

  const [errorHandler, setErrorHandler] = useErrorHandler([
    equipmentError,
    decommissionError,
    activateError,
    unAssignError,
    deleteEquipmentError,
  ]);
  useEffect(() => {
    if (
      equipmentIsLoading ||
      unAssignIsLoading ||
      decommissionIsLoading ||
      activateIsLoading
    ) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }

    // if (unAssignIsSuccess) {
    //   dispatch(setLoader(false));
    //   toast.success(unAssignData.message);
    // }
  }, [
    equipmentIsLoading,
    unAssignIsLoading,
    unAssignIsSuccess,
    decommissionIsLoading,
    activateIsLoading,
  ]);

  useEffect(() => {
    if (equipmentTypeIsSuccess) {
      setEquipmentTypeOptions(equipmentTypeData?.map((item) => ({
        value: item.id,
        label: item.title,
      })))
    }
    if (facilityIsSuccess) {
      setFacilityOptions(facilityData?.map((item) => ({
        value: item.id,
        label: item.name,
      })))
    }
  }, [equipmentTypeIsSuccess, facilityIsSuccess])

  const handleSearch = (e) => {
    navigate(`/equipment?active=${urlParams.get('active')}${urlParams.get('equipment_type') ? `&facility=${urlParams.get('facility')}` : ''}${urlParams.get('equipment_type') ? `&equipment_type=${urlParams.get('equipment_type')}` : ''}${e.target.value !== '' ? `&search=${e.target.value}` : ''}`);
    setSearch(e.target.value);
  };

  const handlePageClick = (page) => {
    getEquipment({active: pageTab == 0 ? `true` : pageTab === "" ? "true" : "false", page: page.selected + 1,
      equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,
      facility_id: filters.facility_id ? filters.facility_id : null,
      search: debouncedSearchValue ? debouncedSearchValue : null,
    });
    setCurrentPage(page.selected + 1)
    window.scrollTo(0, 0);
  };

  const decommissionEquipment = () => {
    decommission(currentId)
      .then((res) => {
      getEquipment({active: `true`, page: 1});
      navigate(`/equipment?active=0`);
      setConfirmModal(false)
    })
  }

  const deleteEquipmentData = () => {
    deleteEquipment(currentId)
      .then(res => {
        getEquipment({active: pageTab == 0 ? `true` : pageTab === "", page: currentPage});
        toast.success(res.data.message)
        setDeleteModal(false)
      })
  }

  const renderConfirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setConfirmModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to decommission this equipment?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setConfirmModal(false)}>
              Close
            </button>
            <button className="save" onClick={() => decommissionEquipment()}>
              Yes
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderDeleteModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeleteModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            <p className="modal__content-dangerText">
              Are you sure you want to delete this equipment?
              <br/>
              All related data will be removed
            </p>
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setDeleteModal(false)}>
              Close
            </button>
            <button className="save danger" onClick={deleteEquipmentData}>
              Delete
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>

    )
  }
  return (
    <>
      <Wrapper hidden={currentItem}>
        <Helmet>
          <title>Equipment Management | NDX</title>
        </Helmet>
        {confirmModal && renderConfirmModal()}
        {deleteModal && renderDeleteModal()}
        <DashboardHeader
          title={"Equipment Management"}
          showBtn={true}
          action={currentTab == 2 ? () => setToolsModal(true) : () => navigate("/create-equipment")}
          type={currentTab == 2 ? "equipment-tools" : "equipment"}
        />
        <div className={`equipment`}>
          <div className="equipment__header">
            <div
              className={`equipment__header-tab ${
                currentTab == 0 ? "active" : ""
              }`}
              onClick={() => {
                changeTab(0);
                setCurrentPage(1);
                getEquipment({active: 'true', page: 1,
                  equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,
                  facility_id: filters.facility_id ? filters.facility_id : null,
                  search: debouncedSearchValue ? debouncedSearchValue : null,
                })
                navigate(`/equipment?active=0${urlParams.get('facility') ? `&facility=${urlParams.get('facility')}` : ''}${urlParams.get('equipment_type') ? `&equipment_type=${urlParams.get('equipment_type')}` : ''}${urlParams.get('search') ? `&search=${urlParams.get('search')}` : ''}`);
              }}
            >
              Active
            </div>
            <div
              className={`equipment__header-tab ${
                currentTab == 1 ? "active" : ""
              }`}
              onClick={() => {
                changeTab(1);
                setCurrentPage(1);
                getEquipment({active: 'false', page: 1,
                  equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,
                  facility_id: filters.facility_id ? filters.facility_id : null,
                  search: debouncedSearchValue ? debouncedSearchValue : null,
                })
                navigate(`/equipment?active=1${urlParams.get('facility') ? `&facility=${urlParams.get('facility')}` : ''}${urlParams.get('equipment_type') ? `&equipment_type=${urlParams.get('equipment_type')}` : ''}${urlParams.get('search') ? `&search=${urlParams.get('search')}` : ''}`);
              }}
            >
              Decommissioned
            </div>
            {/*<div*/}
            {/*  className={`equipment__header-tab ${*/}
            {/*    currentTab == 2 ? "active" : ""*/}
            {/*  }`}*/}
            {/*  onClick={() => {*/}
            {/*    changeTab(2);*/}
            {/*    setCurrentPage(1);*/}
            {/*    // getEquipment({active: 'false', page: 1,*/}
            {/*    //   equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,*/}
            {/*    //   facility_id: filters.facility_id ? filters.facility_id : null,*/}
            {/*    //   search: debouncedSearchValue ? debouncedSearchValue : null,*/}
            {/*    // })*/}
            {/*    navigate(`/equipment?active=2${urlParams.get('facility') ? `&facility=${urlParams.get('facility')}` : ''}${urlParams.get('equipment_type') ? `&equipment_type=${urlParams.get('equipment_type')}` : ''}${urlParams.get('search') ? `&search=${urlParams.get('search')}` : ''}`);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Tools*/}
            {/*</div>*/}
          </div>
          <div className="equipment__filters">
            {
              currentTab == 2 ?
                <Input
                  defaultValue={urlParams.get('search')}
                  placeholder="Search by description"
                  image={"assets/icons/search.svg"}
                  maxwidth={200}
                  // onChange={handleSearch}
                />
                :
                <>
                  <Input
                    defaultValue={urlParams.get('search')}
                    placeholder="Enter Eq, User or Comment"
                    image={"assets/icons/search.svg"}
                    maxwidth={200}
                    onChange={handleSearch}
                  />
                  <Select key={JSON.stringify(facilityOptions)} defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility'))[0]} styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                      borderRadius: 8,
                      height: 42,
                      backgroundColor: '#F4F5F6',
                      marginTop: 8,
                      width: 120,
                    }),
                  }}
                          isClearable
                          options={facilityOptions}
                          onChange={(e) => {
                            setCurrentPage(1);
                            navigate(`/equipment?active=${urlParams.get('active')}${e?.value ? `&facility=${e?.value}` : ''}${urlParams.get('equipment_type') ? `&equipment_type=${urlParams.get('equipment_type')}` : ''}${urlParams.get('search') ? `&search=${urlParams.get('search')}` : ''}`);
                            setFilters((old) => ({...old, facility_id: e?.value}))
                          }}
                          placeholder="Facility"
                  />

                  <Select key={equipmentTypeOptions} defaultValue={equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_type'))[0]} styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                      borderRadius: 8,
                      height: 42,
                      backgroundColor: '#F4F5F6',
                      marginTop: 8,
                      width: 200,
                    }),
                  }}
                          isClearable
                          options={equipmentTypeOptions}
                          placeholder="Equipment Type"
                          onChange={(e) => {
                            setCurrentPage(1);
                            navigate(`/equipment?active=${urlParams.get('active')}${urlParams.get('facility') ? `&facility=${urlParams.get('facility')}` : ''}${e?.value ? `&equipment_type=${e?.value}` : ''}${urlParams.get('search') ? `&search=${urlParams.get('search')}` : ''}`);
                            setFilters((old) => ({...old, equipment_type_id: e?.value}))
                          }}
                  />
                </>
            }
          </div>
          <div className="equipment__box">
            <div className="equipment__table">
              {
                currentTab == 2 ?
                  <div className="equipment__table-head tools">
                    <div className="equipment__table-th">Tool Description</div>
                    <div className="equipment__table-th">Identification</div>
                    <div className="equipment__table-th">Calibration date</div>
                    <div className="equipment__table-th">Action</div>
                  </div>
                  :
                  <div className="equipment__table-head">
                    <div className="equipment__table-th">Type</div>
                    <div className="equipment__table-th">ID#</div>
                    <div className="equipment__table-th">Fuel Type</div>
                    <div className="equipment__table-th">Facility</div>
                    <div className="equipment__table-th">
                      <p className="equipment__table-subtitle">
                        <img src="/assets/icons/beacons.svg" alt="icon" />
                      </p>
                    </div>
                    <div className="equipment__table-th">
                      <div className="equipment__table-btn">
                        <img src="/assets/icons/edit-3.svg" alt="icon"/>
                      </div>
                      <div className="secret">Decommission</div>
                    </div>

                  </div>
              }
              <div
                className={`equipment__table-body ${
                  !equipmentData?.data?.length ? "empty" : ""
                }`}
              >
                {
                  currentTab == 2 ?
                    <ToolsRow setCurrentTool={setCurrentTool} />
                    :
                    equipmentData?.data?.length ? (
                      equipmentData?.data?.filter(el => el?.type?.id !== 44)?.map((equipment, index) => (
                        <EquipmentManagementRow equipment={equipment}
                                                key={index}
                                                index={index}
                                                getEquipment={getEquipment}
                                                setConfirmModal={setConfirmModal}
                                                setDeleteModal={setDeleteModal}
                                                setCurrentId={setCurrentId}
                                                unAssign={unAssign}
                                                pageTab={pageTab}
                                                activate={activate}
                                                filters={filters}
                                                debouncedSearchValue={debouncedSearchValue}
                        />
                      ))
                    ) : (
                      <div>
                        <img src="/assets/empty-list.png" alt="empty" />
                        <p>No equipment data yet</p>
                      </div>
                    )
                }
              </div>
            </div>
            <CustomPaginate
              pageCount={equipmentData?.pages}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </Wrapper>
      {currentItem && <EquipmentDetailts setCurrentItem={setCurrentItem} />}
    </>
  );
}

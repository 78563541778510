import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BillingAddressModal from "../Profile/BillingAddressModal";
import { setUser } from "../../store/builder/loginSlice";
import axios from "axios";
import ShippingAddressModal from "./ShippingAddressModal";

const BillingAddress = ({isCorporate = false}) => {
  const token = localStorage.getItem("ndx_user_token");
  const dispatch = useDispatch();
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
    // .catch((err) => {
    //   dispatch(setLoader(false));
    //   dispatch(setUser(null));
    //   navigate("/login");
    //   toast.error(err.response.data.message);
    // });
  };

  return (
    <div className="profile__methods">
      {showBillingModal && (
        <BillingAddressModal
          setShowPaymentModal={setShowBillingModal}
          getUserIndex={getUserIndex}
          isCorporate={isCorporate}
        />
      )}
      {showShippingModal && (
        <ShippingAddressModal
          setShowPaymentModal={setShowShippingModal}
          getUserIndex={getUserIndex}
        />
      )}
      {/*<div className="profile__methods-head">*/}
      {/*  <p className="profile__methods-title">Shipping address</p>*/}
      {/*  <button*/}
      {/*    className="profile__methods-btn"*/}
      {/*    onClick={() => setShowShippingModal(true)}*/}
      {/*  >*/}
      {/*    Update Shipping Address*/}
      {/*  </button>*/}
      {/*</div>*/}
      {/*<div className="profile__address">*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">Full name:</div>*/}
      {/*    <div className="value">*/}
      {/*      {userInfo?.first_name && userInfo?.last_name*/}
      {/*        ? `${userInfo?.first_name} ${userInfo?.last_name}`*/}
      {/*        : userInfo?.name}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">Address line 1:</div>*/}
      {/*    <div className="value">{userInfo?.address?.line1 ?? "---"}</div>*/}
      {/*  </div>*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">Address line 2:</div>*/}
      {/*    <div className="value">{userInfo?.address?.line2 ?? "---"}</div>*/}
      {/*  </div>*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">City:</div>*/}
      {/*    <div className="value">{userInfo?.address?.city ?? "---"}</div>*/}
      {/*  </div>*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">State/ Provide/ Region:</div>*/}
      {/*    <div className="value">{userInfo?.address?.state ?? "---"}</div>*/}
      {/*  </div>*/}
      {/*  <div className="block">*/}
      {/*    <div className="key">ZIP:</div>*/}
      {/*    <div className="value">{userInfo?.address?.postal_code ?? "---"}</div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="profile__methods-head">
        <p className="profile__methods-title">Billing Address</p>
        <button
          className="profile__methods-btn"
          onClick={() => setShowBillingModal(true)}
        >
          Update Billing Address
        </button>
      </div>
      <div className="profile__methods-cards">
        {
          isCorporate ?
            <div className="profile__methods-card">
              <p className="profile__card-text">
                <span className="profile__card-text-key">Address line 1:</span>{" "}
                <span>
                      {userInfo?.corporate?.billing_address?.line1
                        ? userInfo?.corporate?.billing_address?.line1
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">Address line 2:</span>{" "}
                <span>
                      {userInfo?.corporate?.billing_address?.line2
                        ? userInfo?.corporate?.billing_address?.line2
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">City:</span>{" "}
                <span>
                      {userInfo?.corporate?.billing_address?.city
                        ? userInfo?.corporate?.billing_address?.city
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">State/ Province/ Region:</span>{" "}
                <span>
                      {userInfo?.corporate?.billing_address?.state
                        ? userInfo?.corporate?.billing_address?.state
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">ZIP:</span>{" "}
                <span>
                      {userInfo?.corporate?.billing_address?.postal_code
                        ? userInfo?.corporate?.billing_address?.postal_code
                        : "---"}
                    </span>
              </p>
            </div>
            :
            <div className="profile__methods-card">
              <p className="profile__card-text">
                <span className="profile__card-text-key">Address line 1:</span>{" "}
                <span>
                      {userInfo?.billing_address?.line1
                        ? userInfo?.billing_address?.line1
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">Address line 2:</span>{" "}
                <span>
                      {userInfo?.billing_address?.line2
                        ? userInfo?.billing_address?.line2
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">City:</span>{" "}
                <span>
                      {userInfo?.billing_address?.city
                        ? userInfo?.billing_address?.city
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">State/ Province/ Region:</span>{" "}
                <span>
                      {userInfo?.billing_address?.state
                        ? userInfo?.billing_address?.state
                        : "---"}
                    </span>
              </p>
              <p className="profile__card-text">
                <span className="profile__card-text-key">ZIP:</span>{" "}
                <span>
                      {userInfo?.billing_address?.postal_code
                        ? userInfo?.billing_address?.postal_code
                        : "---"}
                    </span>
              </p>
            </div>
        }

      </div>
    </div>
  );
};

export default BillingAddress;

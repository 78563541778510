import React, { useEffect } from "react";
import "./index.scss";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/builder/loginSlice";

import axios from "axios";
export default function Expired() {
  const navigate = useNavigate();
  const token = localStorage.getItem("ndx_user_token");
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);
  useEffect(() => {
    if (userInfo?.subscription === "active") {
      navigate("/inspections");
    } else {
      return;
    }
  }, [userInfo]);

  useEffect(() => {
    const getUserIndex = () => {
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
        dispatch(setUser(res.data));
      });
      // .catch((err) => {
      //   dispatch(setLoader(false));
      //   dispatch(setUser(null));
      //   navigate("/login");
      //   toast.error(err.response.data.message);
      // });
    };
    getUserIndex();
  }, []);

  return (
    <div className="expired">
      <div className="expired__left">
        <img src="/assets/regist-bg.png" alt="bg" />
      </div>
      <div className="expired__right">
        <div className="expired__box">
          <div className="expired__box-img">
            <img src="/assets/icons/expired-img.png" alt="icon" />
          </div>
          <p className="expired__title">
            {document.location.search.split("=")[1] === "processing"
              ? "Your payment is still being processed"
              : "Your subscription has expired"}
          </p>
          <p className="expired__text">
            {document.location.search.split("=")[1] === "processing"
              ? "Your payment is in the process of being completed. Thank you for your patience and understanding."
              : "We really appreciate your interest in our services and would like to invite you to renew your subscription. After all, thanks to the subscription, you get access to the unique features and resources of our service."}
          </p>
          {document.location.search.split("=")[1] === "processing" ? (
            <button
              className="expired__btn"
              onClick={() => window.location.reload()}
            >
              Check payment
            </button>
          ) : (
            <button className="expired__btn">Update Subscription</button>
          )}
        </div>
      </div>
    </div>
  );
}

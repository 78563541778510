import React, {useEffect} from 'react';
import FboDetails from "../../SVG/FBODetails";
import Pencil from "../../SVG/Pencil";
import {useLocation} from "react-router-dom";
import Trash from "../../SVG/Trash";
import MapIcon from "../../SVG/MapIcon";

const RegionRow = ({region, setRegionsList, regionsList, setCurrentRegion, setNewRegionsModal, getRegionsList, setDeleteRegionsModal, setCurrentTab, setChosenRegionId}) => {
  const location = useLocation();

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".corporate-action") === null && e.target.closest(".fboManagement__dropdown") === null) {
        setRegionsList(old => {
          let data = [...old]
          data?.map(item => {
            item.dropdown_status = false
          })
          return data
        })
      }
    });
  }, [location]);

  const changeDropdownStatus = () => {
    const newData = [...regionsList]
    newData?.map(el => {
      if (el.id === region.id) {
        el.dropdown_status = !el.dropdown_status
      } else {
        el.dropdown_status = false
      }
    })
    setRegionsList(newData)
  }

  return (
    <div className="regions__table-row" id={region?.id}>
      {
        region.dropdown_status &&
        <div className="fboManagement__dropdown corporate">
          <div className="fboManagement__dropdown-elem" onClick={() => {
            setCurrentTab(6)
            setChosenRegionId(region?.id)
            setCurrentRegion(region)
          }}>
            <MapIcon color={'#999999'} />
            <p>Region Details</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {
            setNewRegionsModal(true)
            setCurrentRegion(region)
          }}>
            <Pencil color={'#999999'} />
            <p>Edit Regions</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {
            setDeleteRegionsModal(true)
            setCurrentRegion(region)
          }}>
            <Trash color={'#999999'} />
            <p>Delete Region</p>
          </div>
        </div>
      }
      <div className="regions__table-td">{region?.name}</div>
      <div className="regions__table-td">{region?.agencies?.length}</div>
      <div className="regions__table-td corporate-action">
        <img src="/assets/icons/dots.svg" alt="dots" onClick={changeDropdownStatus}/>
      </div>
    </div>
  );
};

export default RegionRow;
import React from 'react';

const FboGraphicsIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 3C2.55228 3 3 3.44772 3 4V18C3 18.5523 3.44772 19 4 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H4C2.34315 21 1 19.6569 1 18V4C1 3.44772 1.44772 3 2 3Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.4962 5.13193C20.9757 5.40594 21.1423 6.01679 20.8683 6.49631L17.6818 12.0727C16.9723 13.3144 15.485 13.8826 14.1283 13.4304L11.0068 12.3899C10.6094 12.2574 10.1714 12.3867 9.90972 12.7138L6.78091 16.6249C6.4359 17.0561 5.8066 17.126 5.37534 16.781C4.94408 16.436 4.87416 15.8067 5.21917 15.3755L8.34798 11.4645C9.13315 10.483 10.4469 10.095 11.6393 10.4925L14.7608 11.533C15.213 11.6837 15.7088 11.4943 15.9453 11.0805L19.1318 5.50403C19.4058 5.02451 20.0167 4.85792 20.4962 5.13193Z" fill={color}/>
    </svg>
  );
};

export default FboGraphicsIcon;
import React, {useEffect, useState} from "react";
import { validationEmail } from "../../global/validate";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import { useErrorHandler } from '../../global/ErrorsHandler';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {useForgotPasswordMutation} from "../../store/builder/inspections.api";
import {setEmail, setVerify} from "../../store/builder/forgotPasswordSlice";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: {
      id: "email",
      value: null,
      errorShow: false,
      error: "Please enter correct email",
    }
  });
  const [forgotPassword, {data, error, isLoading, isError, isSuccess}] = useForgotPasswordMutation()
  const [errorHandler, setErrorHandler] = useErrorHandler([error])

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success(data?.message)
  //     dispatch(setVerify(data?.data?.verify_token))
  //     dispatch(setEmail(state.email.value))
  //     navigate('/enter-code')
  //   }
  // }, [isSuccess])

  // useEffect(() => {
  //   if (isLoading) {
  //     dispatch(setLoader(true))
  //   } else {
  //     dispatch(setLoader(false))
  //   }
  // }, [isLoading])

  const submitHandler = (e) => {
    e.preventDefault();
    if (validationEmail(state?.email?.value)) {
      dispatch(setLoader(true));
      // forgotPassword({email: state?.email.value})
      let body = {email: state?.email.value}
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/reset-password`, body, config)
        .then((res) => {
          dispatch(setVerify(res?.data?.data?.verify_token))
          dispatch(setEmail(state.email.value))
          navigate('/enter-code')
          dispatch(setLoader(false));
          toast.success(res.data.message);
        })
        .catch((err) => {
          dispatch(setLoader(false));
          toast.error(err.response.data.message);
        });
    } else {
      dispatch(setLoader(false));
      toast.error('Please enter correct email')
    }
  };

  return (
    <div className="auth">
      <Helmet>
        <title>Forgot Password | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/forgot-image.png" alt="bg" />
        </div>
        <div className="auth__right">
          <div className="auth__content forgot">
            <h1 className="auth__title">Forgot password?</h1>
            <p className="auth__subtitle">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
            <div className="auth__form" onSubmit={submitHandler}>
              <div className="auth__field">
                <label htmlFor="email" className="auth__label">
                  Email
                </label>
                <input
                  id="email"
                  placeholder="Enter email"
                  type="text"
                  className="auth__input"
                  onChange={inputHandler}
                />
                {state?.email?.errorShow && (
                  <span className="auth__error">{state?.email?.error}</span>
                )}
              </div>
              <button className="auth__submit" onClick={submitHandler}>Send verification code</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  email: null,
  code: null,
  password: null,
  confirm_password: null,
  verifyToken: null,
};

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload.password
      state.confirm_password = action.payload.confirm_password
    },
    setVerify(state, action) {
      state.verifyToken = action.payload
    }
  },
});

export const { setEmail, setCode, setPassword, setVerify } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;

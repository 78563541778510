import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const inspectionApi = createApi({
  reducerPath: "inspection/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("ndx_user_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("time", String(Date.now()));
      } else {
        window.location.href = "/login";
        localStorage.removeItem('out_of_service')
      }
      headers.set("accept", `application/json`);
      headers.set("agency", localStorage.getItem("current-fbo"));
      headers.set("DeviceType", 'web');
      headers.set("AppVersion", '1.0.0');
      headers.set("AppType", 'web');

      return headers;
    },
  }),
  keepUnusedDataFor: 0.0001,
  endpoints: (build) => ({
    getInspection: build.query({
      query: () => ({
        url: "inspection-form/list",
      }),
      providesTags: ["Inspection"],
    }),
    getInspectionsPagination: build.query({
      query: (params) => ({
        url: `inspection-form?page=${params.page ? params.page : 1}`,
      }),
      providesTags: ["InspectionPagination"],
    }),
    getPeriods: build.query({
      query: () => ({
        url: "inspection-form/periods",
      }),
    }),
    getOrdersList: build.query({
      query: (params) => ({
        url: `orders/list?${params}`,
      }),
      invalidatesTags: ["OrderList"]
    }),
    getOrdersListFbo: build.query({
      query: (params) => ({
        url: `orders/list?${params?.id ? `agency_id=${params?.id}` : ''}${params?.page ? `&page=${params?.page}` : ''}`,
      }),
      invalidatesTags: ["OrderList"]
    }),
    getSubscriptionsFbo: build.query({
      query: (params) => ({
        url: `subscriptions/list?${params?.id ? `agency_id=${params?.id}` : ''}${params?.page ? `&page=${params?.page}` : ''}`,
      }),
      invalidatesTags: ["OrderList"]
    }),
    getOrder: build.query({
      query: (id) => ({
        url: `orders/${id}`,
      }),
      providesTags: ["CurrentOrder"]
    }),
    createInspection: build.mutation({
      query: (body) => ({
        url: "inspection-form/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspection", "InspectionPagination"],
    }),
    getFacilityByAgency: build.query({
      query: ({id, page}) => ({
        url: `facility?agency_id=${id}&page=${page}`,
      }),
      providesTags: ["Facility"],
    }),
    getFacility: build.query({
      query: () => ({
        url: "facility/list",
      }),
      providesTags: ["Facility"],
    }),
    getFacilityIndex: build.query({
      query: (page) => ({
        url: `facility?page=${page}&limit=10`,
      }),
      providesTags: ["FacilityIndex"],
    }),
    createFacility: build.mutation({
      query: (body) => ({
        url: "facility/create",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Facility"],
    }),
    getFieldTypes: build.query({
      query: () => ({
        url: "inspection-form/field-types",
      }),
      providerTags: ["FieldTypes"],
    }),
    createInspectionStep: build.mutation({
      query: (body) => ({
        url: "inspection/step/create",
        method: "POST",
        body,
      }),
    }),
    getEquipment: build.query({
      query: (params) => ({
        url: `equipment?active=${params.active}&page=${
          params.page ? params.page : 1
        }${
          params.equipment_type_id
            ? "&equipment_type_id=" + params.equipment_type_id
            : ""
        }
        ${params.facility_id ? "&facility_id=" + params.facility_id : ""}
        ${params.search ? "&search=" + params.search : ""}
        `,
      }),
      providerTags: ["GetEquipment"],
    }),
    getEquipmentAll: build.query({
      query: (params) => ({
        url: `equipment?page=1&per_page=100`,
      }),
      providerTags: ["GetEquipmentAll"],
    }),
    getAgenciesWithEquipments: build.query({
      query: (params) => ({
        url: `equipment/types/list-by-agency-with-equipments?form_code=${params}`,
      }),
      providerTags: ["GetAgenciesWithEquipment"],
    }),
    getCurrentEquipment: build.query({
      query: (params) => ({
        // TODO: ADD PAGINATION
        url: `equipment/${params}`,
      }),
      providerTags: ["CurrentEquipment"],
    }),
    equipmentDecommission: build.mutation({
      query: (id) => ({
        url: `equipment/${id}/decommission`,
        method: "POST",
      }),
      invalidatesTags: ["GetEquipment"],
    }),
    equipmentActivate: build.mutation({
      query: (id) => ({
        url: `equipment/${id}/activate`,
        method: "POST",
      }),
    }),
    getReports: build.query({
      query: (params) => ({
        url: `equipment/tools-report${params.agency_id ? "?agency_id=" + params.agency_id : ""}`,
        method: "GET",
      }),
    }),
    getReportHistory: build.query({
      query: (params) => ({
        url: `equipment/tools-history${params.equipment_id ? "?equipment_id=" + params.equipment_id : ""}`,
        method: "GET",
      }),
    }),
    getInspectionById: build.query({
      query: (params) => ({
        url: `inspection-form/get/${params}`,
      }),
      providesTags: ["CurrentInspection"],
    }),
    getInspectionFacilityById: build.query({
      query: (params) => ({
        url: `inspection/get/${params}`,
      }),
      providesTags: ["CurrentInspection"],
    }),
    createCategory: build.mutation({
      query: (body) => ({
        url: "inspection-form/category/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentInspection"],
    }),
    deleteCategory: build.mutation({
      query: (body) => ({
        url: "inspection-form/category/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentInspection"],
    }),
    changeCategory: build.mutation({
      query: (body) => ({
        url: "inspection-form/category/rename",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentInspection"],
    }),
    reorderCategory: build.mutation({
      query: (body) => ({
        url: "inspection-form/category/reorder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentInspection"],
    }),
    updateField: build.mutation({
      query: (body) => ({
        url: "inspection-form/category/update-fields",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CurrentInspection"],
    }),
    getEquipmentType: build.query({
      query: (params) => ({
        url: `equipment/types/list`,
      }),
      providesTags: ["EquipmentTypesList"],
    }),
    getInspectionsByType: build.query({
      query: (params) => ({
        url: `inspection-form/list-by-type?type=${params}`,
      }),
      providesTags: ["InspectionsByType"],
    }),
    getCurrentInspection: build.query({
      query: (params) => ({
        url: `equipment/types/${params}`,
      }),
      providesTags: ["EquipmentTypesInspections"],
    }),
    updateEquipmentType: build.mutation({
      query: (body) => ({
        url: "inspection-form/relating/assign",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "EquipmentTypesInspections",
        "GetCurrentFacilityInspections",
      ],
    }),
    completeInspection: build.mutation({
      query: (body) => ({
        url: "inspection/save",
        method: "POST",
        body,
      }),
    }),
    getInspectionByFacility: build.query({
      query: (id) => ({
        url: `inspection/list-web${id !== 0 ? `?facility_id=${id}` : ''}`,
        method: "GET",
        // let s = "";
        // if (id !== 1) {
        //   for (let i = 0; i < id.length; i++) {
        //     s += `facility_id[]=${id[i]?.value}&`;
        //   }
        // } else {
        //   s = `facility_id[]=1`;
        // }
        // let s = typeof id === array ? id.forEach : id
        // return {

        // };
      }),
      providesTags: ["GetInspections"],
      invalidatesTags: [
        "GetCurrentFacilityInspections",
        "EquipmentTypesInspections",
      ],
    }),
    refuseInspections: build.mutation({
      query: (body) => ({
        url: "inspection-form/relating/refuse",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "EquipmentTypesInspections",
        "GetCurrentFacilityInspections",
      ],
    }),
    getCorrectiveActions: build.query({
      query: (params) => ({
        url:
          `corrective-actions?closed=${params.closed}` +
          `${params.page ? "&page=" + params.page : "&page=1"}` +
          `${params.facility_id ? "&facility_id=" + params.facility_id : ""}` +
          `${
            params.equipment_id ? "&equipment_id=" + params.equipment_id : ""
          }` +
          `${params.date_from ? "&date_from=" + params.date_from : ""}` +
          `${params.date_to ? "&date_to=" + params.date_to : ""}` +
          `${params.search ? "&search=" + params.search : ""}`,
      }),
      providesTags: ["GetCorrectiveActions"],
    }),
    getCurrentFacilityInspections: build.query({
      query: (params) => ({
        url: `facility/${params}`,
      }),
      providesTags: ["GetCurrentFacilityInspections"],
    }),
    updateFacilityInfo: build.mutation({
      query: (body) => ({
        url: "facility/update",
        method: "POST",
        body,
      }),
    }),
    changeCorrectiveAction: build.mutation({
      query: (body) => ({
        url: "corrective-actions/close",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetCorrectiveActions"],
    }),
    getReportList: build.query({
      query: (params) => ({
        url: `report/pdf/list?` +
            // `${params.page ? "&page=" + params.page : "&page=1"}` +
          `${params.facility_id ? "&facility_id=" + params.facility_id : ""}` +
          `${params.type ? "&type=" + params.type : ""}` +
          `${params.date_from ? "&date_from=" + params.date_from : ""}` +
          `${params.date_to ? "&date_to=" + params.date_to : ""}` +
          `${params.search ? "&search=" + params.search : ""}`,
      }),
      providesTags: ["ReportList"],
    }),
    createReport: build.mutation({
      query: (body) => ({
        url: "report/pdf/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ReportList"],
    }),
    createFuelReceiptReport: build.mutation({
      query: (body) => ({
        url: "report/pdf/fuel/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ReportList"],
    }),
    removeReport: build.mutation({
      query: (id) => ({
        url: `report/pdf/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["ReportList"],
    }),
    getStationsList: build.query({
      query: (params) => ({
        url: `stations/list`,
      }),
      providesTags: ["Stations"],
    }),
    createStation: build.mutation({
      query: (body) => ({
        url: "stations/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Stations"],
    }),
    updateStation: build.mutation({
      query: (body) => ({
        url: "stations/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Stations"],
    }),
    deleteStation: build.mutation({
      query: (body) => ({
        url: `stations/${body}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Stations"],
    }),
    getTerminalsList: build.query({
      query: (params) => ({
        url: `terminals/list`,
      }),
      providesTags: ["Terminals"],
    }),
    createTerminal: build.mutation({
      query: (body) => ({
        url: "terminals/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Terminals"],
    }),
    updateTerminal: build.mutation({
      query: (body) => ({
        url: "terminals/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Terminals"],
    }),
    deleteTerminal: build.mutation({
      query: (body) => ({
        url: `terminals/${body}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Terminals"],
    }),
    createEquipment: build.mutation({
      query: (body) => ({
        url: `equipment/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetEquipment"],
    }),
    updateEquipment: build.mutation({
      query: (body) => ({
        url: "equipment/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetEquipment"],
    }),
    getUsers: build.query({
      query: (params) => ({
        url: `users/list${params ? `?agency_id=${params}` : ""}`,
      }),
      providesTags: ["UsersList"],
    }),
    getUsersByAgency: build.query({
      query: (params) => ({
        url: `users/agency`,
      }),
      providesTags: ["UsersListByAgency"],
    }),
    getChosenUser: build.query({
      query: (params) => ({
        url: `users/${params}`,
      }),
      providesTags: ["ChosenUser"],
    }),
    getUsersRoles: build.query({
      query: (params) => ({
        url: `users/roles`,
      }),
      providesTags: ["UsersRolesList"],
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: `users/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["UsersList", "CorporateDetails"],
    }),
    updateUser: build.mutation({
      query: (body) => ({
        url: `users/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["UsersList", "CorporateDetails"],
    }),
    updatePassword: build.mutation({
      query: ({id, password, password_confirmation}) => ({
        url: `users/update-password/${id}`,
        method: "POST",
        body: {password, password_confirmation},
      }),
      invalidatesTags: ["UsersList", "CorporateDetails"],
    }),
    deleteUser: build.mutation({
      query: (body) => ({
        url: `users/${body}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UsersList", "CorporateDetails"],
    }),
    unAssignEquipment: build.mutation({
      query: (body) => ({
        url: `equipment/${body}/un-assign`,
        method: "POST",
      }),
      invalidatesTags: ["GetEquipment"],
    }),
    unAssignFacility: build.mutation({
      query: (body) => ({
        url: `facility/${body}/un-assign`,
        method: "POST",
      }),
      invalidatesTags: ["FacilityIndex"],
    }),
    getMissedInspections: build.query({
      query: (params) => ({
        url:
          `inspection/missed?page=${params.page ? params.page : 1}` +
          `&tab_name=${params?.tab_name}` +
          `${params.date_from ? "&date_from=" + params.date_from : ""}` +
          `${params.date_to ? "&date_to=" + params.date_to : ""}` +
          `${
            params.completed === 1
              ? "&completed=1"
              : params.completed === 0
              ? "&completed=0"
              : ""
          }` +
            `${params.facility_id ? "&facility_id=" + params.facility_id : ""}` +
            `${params.period ? "&period=" + params.period : ""}` +
            `${params.equipment_type_id ? "&equipment_type_id=" + params.equipment_type_id : ""}`,
      }),
      providesTags: ["MissedInspections"],
    }),
    getReturnToService: build.query({
      query: (params) => ({
        url:
          `inspection/return-to-service?page=${params.page ? params.page : 1}` +
          `${params.date_from ? "&date_from=" + params.date_from : ""}` +
          `${params.date_to ? "&date_to=" + params.date_to : ""}` +
          `${params.completed === 1 ? "&completed=1" : params.completed === 0 ? "&completed=0" : ""}` +
          `${params.facility_id ? "&facility_id=" + params.facility_id : ""}` +
          `${params.period ? "&period=" + params.period : ""}` +
          `${params.equipment_type_id ? "&equipment_type_id=" + params.equipment_type_id : ""}`,
      }),
      providesTags: ["ReturnToService"],
    }),
    updateMissedInspection: build.mutation({
      query: (body) => ({
        url: `inspection/missed/${body.id}/update`,
        method: "POST",
        body: body.data,
      }),
      invalidatesTags: ["MissedInspections"],
    }),
    getServiceRequests: build.query({
      query: (params) => ({
        url: `service-requests?page=${params.page ? params.page : 1}${
          params.status ? `&status=${params.status}` : ""
        }`,
      }),
      providesTags: ["ServiceRequests"],
    }),
    updateServiceRequest: build.mutation({
      query: (body) => ({
        url: `service-requests/${body.id}/update`,
        method: "POST",
        body: body.data,
      }),
      invalidatesTags: ["ServiceRequests"],
    }),
    getSheets: build.query({
      query: (params) => ({
        url: `log-sheets?page=${params?.page ? params?.page : "1"}${
          params?.search && params?.search !== ""
            ? `&search=${params?.search}`
            : ""
        }${
          params?.equipment_type_id
            ? `&equipment_type_id=${params?.equipment_type_id}`
            : ""
        }${params?.user_id ? `&user_id=${params?.user_id}` : ""}${
          params?.date_from ? `&date_from=${params?.date_from}` : ""
        }${params?.date_to ? `&date_to=${params?.date_to}` : ""}
        ${params?.facility_id ? `&facility_id=${params?.facility_id}` : ""}
        ${params?.status ? `&status=${params?.status}` : ""}
        ${params?.period ? `&period=${params?.period}` : ""}`,
      }),
      providesTags: ["SheetsList"],
    }),
    getAgency: build.query({
      query: () => ({
        url: `agency/list`,
      }),
      providesTags: ["Agancy"],
    }),
    getTrialAgency: build.query({
      query: (data) => ({
        url: `agency/trials?limit=10&page=${data?.page}${data?.search ? `&search=${data?.search}` : ''}`,
      }),
      providesTags: ["AgencyTrials"],
    }),
    getAgencyIndex: build.query({
      query: (data) => ({
        url: `agency?page=${data?.page}${data?.search ? `&search=${data?.search}` : ''}`,
      }),
      providesTags: ["AgancyIndex"],
    }),
    getAgencySubscribed: build.query({
      query: (data) => ({
        url: `agency/subscribed?page=${data?.page}${data?.search ? `&search=${data?.search}` : ''}`,
      }),
      providesTags: ["AgancySubscribed"],
    }),
    getInvitedAgencyIndex: build.query({
      query: (data) => ({
        url: `agency/invited?page=${data?.page}${data?.search ? `&search=${data?.search}` : ''}`,
      }),
      providesTags: ["AgancyInvited"],
    }),
    deleteInvitedAgency: build.mutation({
      query: (body) => ({
        url: `users/${body}`,
        method: "DELETE",
      }),
      providesTags: ["AgancyInvited"]
    }),
    resendInvitedAgency: build.mutation({
      query: (body) => ({
        url: `agency/resend-invitation/${body}`,
        method: "POST",
      }),
      providesTags: ["AgancyInvited"]
    }),
    getTimezones: build.query({
      query: () => ({
        url: `agency/timezones`,
      }),
      providesTags: ["Timezones"],
    }),
    createAgency: build.mutation({
      query: (body) => ({
        url: `agency/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    updateAgancy: build.mutation({
      query: ({ id, data }) => {
        return {
          url: `agency/${id}/update`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    startAgencyTrial: build.mutation({
      query: (data) => {
        return {
          url: `agency/start-inspection-trial`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["AgencyTrials, AgancyIndex"],
    }),
    extendAgencyTrial: build.mutation({
      query: (data) => {
        return {
          url: `agency/update-inspection-trial`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["AgencyTrials, AgancyIndex"],
    }),
    updateAgancySettings: build.mutation({
      query: (body) => {
        return {
          url: `account/update-agency-settings`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    updateAgancyProfile: build.mutation({
      query: (body) => {
        return {
          url: `account/update-agency-profile`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    updateAgancyProfileSettings: build.mutation({
      query: (body) => {
        return {
          url: `account/update-agency-profile-settings`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    updateAgencyCorrectiveSettings: build.mutation({
      query: (body) => {
        return {
          url: `account/update-agency-corrective-settings`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Agancy, AgancyIndex"],
    }),
    refreshInspections: build.query({
      query: () => ({
        url: `inspection/create-periodic-inspections`,
      }),
      invalidatesTags: [`GetInspections`],
    }),
    getCurrentInspectionAndCategory: build.query({
      query: (body) => ({
        url: `inspection/get/${body.id}/${body.category_id}`,
      }),
      providesTags: ["CurrentInspectionAndCategory"],
    }),
    createService: build.mutation({
      query: (body) => ({
        url: `service-requests/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ServiceRequests"],
    }),
    saveEquipmentForm: build.mutation({
      query: (body) => ({
        url: `equipment/types/save-forms`,
        method: "POST",
        body,
      }),
    }),
    getAssigned: build.query({
      query: () => ({
        url: `inspection-form/list-grouped-with-links`,
      }),
      invalidatesTags: [`GetAssigned`],
    }),
    deleteInspection: build.mutation({
      query: (body) => ({
        url: `inspection-form/delete`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionPagination"],
    }),
    saveProfilePhoto: build.mutation({
      query: (body) => ({
        url: `account/update-profile-photo`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ChosenUser"],
    }),
    uploadFile: build.mutation({
      query: (body) => ({
        url: `files/upload`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        url: `auth/reset-password`,
        method: "POST",
        body,
      }),
    }),
    sendCode: build.mutation({
      query: (body) => ({
        url: `auth/verify-reset-password`,
        method: "POST",
        body,
      }),
    }),
    changePassword: build.mutation({
      query: (body) => ({
        url: `auth/set-password`,
        method: "POST",
        body,
      }),
    }),
    inviteFbo: build.mutation({
      query: (body) => ({
        url: `auth/register-w-invite`,
        method: "POST",
        body,
      }),
    }),
    getOutOfService: build.query({
      query: () => ({
        url: `equipment/list-decommissioned`,
      }),
      invalidatesTags: [`OutOfService`],
    }),
    getFuelTypes: build.query({
      query: () => ({
        url: `equipment/fuel-types`,
      }),
      invalidatesTags: [`OutOfService`],
    }),
    getSecretKey: build.mutation({
      query: () => ({
        url: `account/get-intent-secret`,
        method: "POST",
      }),
    }),
    getCorporateSecretKey: build.mutation({
      query: () => ({
        url: `corporate/payment/get-intent-secret`,
        method: "POST",
      }),
    }),
    updatePayment: build.mutation({
      query: (body) => ({
        url: `account/update-payment-method`,
        method: "POST",
        body,
      }),
    }),
    updateCorporatePayment: build.mutation({
      query: (body) => ({
        url: `corporate/payment/update-payment-method`,
        method: "POST",
        body,
      }),
    }),
    paymentCheckout: build.mutation({
      query: (body) => ({
        url: `payment/checkout`,
        method: "POST",
        body,
      }),
    }),
    paymentCorporateCheckout: build.mutation({
      query: (body) => ({
        url: `corporate/payment/checkout`,
        method: "POST",
        body,
      }),
    }),
    paymentConfirm: build.mutation({
      query: (body) => ({
        url: `payment/confirm`,
        method: "POST",
        body,
      }),
    }),
    paymentCorporateConfirm: build.mutation({
      query: (body) => ({
        url: `corporate/payment/confirm`,
        method: "POST",
        body,
      }),
    }),
    buyPhones: build.mutation({
      query: (body) => ({
        url: `payment/buy-phones`,
        method: "POST",
        body,
      }),
    }),
    getHistorySubscription: build.query({
      query: () => ({
        url: `history/subscriptions`,
      }),
      invalidatesTags: [`HistorySubscription`],
    }),
    getHistoryPayments: build.query({
      query: () => ({
        url: `history/payments`,
      }),
      invalidatesTags: [`HistoryPayments`],
    }),
    getHistoryLeasing: build.query({
      query: () => ({
        url: `history/leasing`,
      }),
      invalidatesTags: [`HistoryLeasing`],
    }),
    getHistoryOrders: build.query({
      query: () => ({
        url: `history/orders`,
      }),
      invalidatesTags: [`HistoryOrders`],
    }),
    getPrices: build.query({
      query: () => ({
        url: `payment/prices`,
      }),
      invalidatesTags: [`PaymentPrices`],
    }),
    getCorporatePrices: build.query({
      query: () => ({
        url: `corporate/payment/prices`,
      }),
      invalidatesTags: [`PaymentPricesCorporate`],
    }),
    getFuelReceipt: build.query({
      query: (params) => ({
        url: `inspection/get-fuel-inspections-by-agency?agency_id=${params.agency}&is_complete=${params.isCompleted}` +
            `${params.page ? "&page=" + params.page : "&page=1"}` +
            `${params.facility_id ? "&facility_id=" + params.facility_id : ""}` +
            `${params.equipment_id ? "&equipment_id=" + params.equipment_id : ""}` +
            `${params.date_from ? "&date_from=" + params.date_from : ""}` +
            `${params.date_to ? "&date_to=" + params.date_to : ""}` +
            `${params.search ? "&search=" + params.search : ""}`,
      }),
      invalidatesTags: [`FuelReceiptList`],
    }),
    getPaymentInfo: build.query({
      query: () => ({
        url: `account/payment-method`,
      }),
      invalidatesTags: [`PaymentInfo`],
    }),
    getPaymentInfoCorporate: build.query({
      query: () => ({
        url: `corporate/payment/payment-method`,
      }),
      invalidatesTags: [`PaymentInfoCorporate`],
    }),
    getSettings: build.query({
      query: () => ({
        url: `settings/index`,
      }),
      invalidatesTags: [`Settings`],
    }),
    getAgencyById: build.query({
      query: (id) => ({
        url: `agency/${id}/details`,
      }),
      invalidatesTags: [`AgencyById`],
    }),
    updateSetting: build.mutation({
      query: (data) => ({
        url: `settings/${data?.id}/update`,
        method: "POST",
        body: data?.body,
      }),
      providesTags: ["Settings"]
    }),
    updateFboPrices: build.mutation({
      query: (data) => ({
        url: `agency/${data?.id}/update-prices`,
        method: "POST",
        body: data?.body,
      }),
      providesTags: ["AgancyIndex"]
    }),
    updateBilling: build.mutation({
      query: (body) => ({
        url: `account/update-address`,
        method: "POST",
        body: body,
      }),
    }),
    updateCorporateBilling: build.mutation({
      query: (body) => ({
        url: `corporate/payment/update-address`,
        method: "POST",
        body: body,
      }),
    }),
    //
    updateOrder: build.mutation({
      query: (data) => ({
        url: `orders/${data?.id}`,
        method: "POST",
        body: data?.body,
      }),
      providesTags: ["CurrentOrder"]
    }),
    createManualOrder: build.mutation({
      query: (body) => ({
        url: `orders/create-manual-order`,
        method: "POST",
        body: body,
      }),
      providesTags: ["OrderList"]
    }),
    updateOrderShippingAddress: build.mutation({
      query: (data) => ({
        url: `orders/${data.id}/update-address`,
        method: "POST",
        body: data.body,
      }),
    }),
    deleteEquipment: build.mutation({
      query: (id) => ({
        url: `equipment/${id}/delete`,
        method: "POST",
      }),
      providesTags: ["EquipmentList"]
    }),
    updateUserProfile: build.mutation({
      query: (body) => ({
        url: `account/update-profile`,
        method: "POST",
        body: body,
      }),
    }),
    updateUserProfilePassword: build.mutation({
      query: (body) => ({
        url: `account/update-password`,
        method: "POST",
        body: body,
      }),
    }),
    cancelSubscription: build.mutation({
      query: (body) => ({
        url: `agency/${body?.id}/cancel-subscription`,
        method: "POST",
        body: body.data
      }),
      providesTags: ["AgancyIndex, AgencyById"]
    }),
    getUsersByAgencyId: build.query({
      query: (id) => ({
        url: `agency/${id}/users`,
      }),
      invalidatesTags: [`UsersByAgency`],
    }),
    clearInspections: build.mutation({
      query: (params) => ({
        url: `log-sheets/delete?page=${params?.page ? params?.page : "1"}${
          params?.search && params?.search !== ""
            ? `&search=${params?.search}`
            : ""
        }${
          params?.equipment_type_id
            ? `&equipment_type_id=${params?.equipment_type_id}`
            : ""
        }${params?.user_id ? `&user_id=${params?.user_id}` : ""}${
          params?.date_from ? `&date_from=${params?.date_from}` : ""
        }${params?.date_to ? `&date_to=${params?.date_to}` : ""}
        ${params?.facility_id ? `&facility_id=${params?.facility_id}` : ""}
        ${params?.status ? `&status=${params?.status}` : ""}
        ${params?.period ? `&period=${params?.period}` : ""}`,
        method: "POST",
      }),
      invalidatesTags: ["SheetsList"],
    }),
    clearInspectionsFbo: build.mutation({
      query: (body) => ({
        url: `agency/clear-inspections`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    reactivateFboEquipments: build.mutation({
      query: (body) => ({
        url: `agency/reactivate-equipments`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    createFiltersChange: build.mutation({
      query: (body) => ({
        url: `inspection/create/period/filter-change`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    createFuelReceiptInspection: build.mutation({
      query: (body) => ({
        url: `inspection/create/period/fuel-receipt`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    createFuelQualityRecord: build.mutation({
      query: (body) => ({
        url: `inspection/create/period/fuel-quality`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    getCustomerRequests: build.query({
      query: (params) => ({
        url: `customer-requests/list?page=${params.page}`,
      }),
      invalidatesTags: ["CustomerRequestsRow"],
    }),
    updateCustomerRequest: build.mutation({
      query: (body) => ({
        url: `customer-requests/update-status`,
        method: "POST",
        body: body
      }),
      providesTags: ["CustomerRequestsRow"]
    }),
    sentCustomerLink: build.mutation({
      query: (body) => ({
        url: `customer-requests/send-sing-up-link`,
        method: "POST",
        body: body
      }),
      providesTags: ["CustomerRequestsRow"]
    }),
    dashboardPersonalStats: build.query({
      query: () => ({
        url: `dashboard/personnel-stats`,
      }),
      invalidatesTags: ["DashboardPersonalStats"],
    }),
    dashboardEquipmentStats: build.query({
      query: () => ({
        url: `dashboard/equipment-stats`,
      }),
      invalidatesTags: ["DashboardEquipmentStats"],
    }),
    dashboardPersonalInfo: build.query({
      query: (params) => ({
        url: `dashboard/personal-info${params.name ? `?filter_type=${params.name}` : ''}`,
      }),
      invalidatesTags: ["DashboardPersonalInfo"],
    }),
    dashboardOutStandingInspections: build.query({
      query: () => ({
        url: `dashboard/outstanding-inspections`,
      }),
      invalidatesTags: ["DashboardOutStandingInspections"],
    }),
    dashboardOutStandingRts: build.query({
      query: () => ({
        url: `dashboard/outstanding-rts`,
      }),
      invalidatesTags: ["DashboardOutStandingRts"],
    }),
    dashboardCorrectiveActions: build.query({
      query: () => ({
        url: `dashboard/corrective-actions`,
      }),
      invalidatesTags: ["DashboardCorrectiveActions"],
    }),
    dashboardAveragePerInspection: build.query({
      // query: ({filter_period, facility_id, equipment_type_id, employee_id}) => ({
      //   url: `dashboard/average-time?filter_period=${filter_period}
      //         ${facility_id ? "&facility_id=" + facility_id : ""}
      //         ${equipment_type_id ? "&equipment_type_id=" + equipment_type_id : ""}
      //         ${employee_id ? "&employee_id=" + employee_id : ""}`,
      // }),
      query: (params) => ({
        url: `dashboard/average-time${params ? `?${params}` : ''}`,
      }),
      invalidatesTags: ["DashboardAveragePerInspection"],
    }),
    dashboardInspections: build.query({
      query: ({period}) => ({
        url: `dashboard/inspections?filter_period=${period}`,
      }),
      invalidatesTags: ["dashboardInspections"],
    }),
    canAddFacility: build.mutation({
      query: (body) => ({
        url: `agency/update-ability-to-add-facility`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    metricsInspectionsPlanned: build.query({
      query: ({agency}) => ({
        url: `dashboard/inspections-last-week/?agency_id=${agency}`,
      }),
      invalidatesTags: ["metricsPlanned"],
    }),
    metricsInspectionsYesterday: build.query({
      query: ({agency}) => ({
        url: `dashboard/inspections-yesterday?agency_id=${agency}`,
      }),
      invalidatesTags: ["metricsYesterday"],
    }),
    metricsInspectionsMissed: build.query({
      query: ({agency, period, filter_for_period}) => ({
        url:
          `dashboard/inspections-missed-last-month?agency_id=${agency}
            ${period ? `&period=${period}` : ""}
            ${filter_for_period ? `&filter_for_period=${filter_for_period}` : ""}
          `,
      }),
      invalidatesTags: ["metricsMissed"],
    }),
    metricsEquipmentCountType: build.query({
      query: ({agency, equipment_type_id}) => ({
        url:
          `dashboard/equipment-count-by-type?agency_id=${agency}
           ${equipment_type_id ? `&equipment_type_id=${equipment_type_id}` : ''}
          `,
      }),
      invalidatesTags: ["metricsEquipmentCount"],
    }),
    metricsEquipmentRemovedYesterday: build.query({
      query: ({agency}) => ({
        url:
          `dashboard/equipment-removed-yesterday?agency_id=${agency}`,
      }),
      invalidatesTags: ["metricsEquipmentRemoved"],
    }),
    metricsUpcomingInspections: build.query({
      query: ({agency, equipment_id}) => ({
        url:
          `dashboard/upcoming-inspections?agency_id=${agency}
          ${equipment_id ? `&equipment_id=${equipment_id}` : ''}
          `,
      }),
      invalidatesTags: ["metricsUpcoming"],
    }),
    metricsCorrectiveActionsStats: build.query({
      query: ({agency}) => ({
        url:
          `dashboard/corrective-action-stats?agency_id=${agency}`,
      }),
      invalidatesTags: ["metricsCorrectiveActionsStatistics"],
    }),
    metricsInspectionByEquipmentType: build.query({
      query: ({agency, equipment_type_id}) => ({
        url:
          `dashboard/inspection-metrics-by-equipment-type?agency_id=${agency}
            ${equipment_type_id? `&equipment_id=${equipment_type_id}` : ''}
          `,
      }),
      invalidatesTags: [""],
    }),
    metricsAverageInspectionDuration: build.query({
      query: ({agency, equipment_type_id}) => ({
        url:
          `dashboard/average-inspection-duration?agency_id=${agency}
          ${equipment_type_id? `&equipment_type_id=${equipment_type_id}` : ''}`,
      }),
      invalidatesTags: [""],
    }),
    metricsEquipmentDecommissions: build.query({
      query: ({agency, period}) => ({
        url:
          `dashboard/equipment-decommissions?agency_id=${agency}&period=${period}`,
      }),
      invalidatesTags: [""],
    }),
    metricsEquipmentRemoved: build.query({
      query: ({agency}) => ({
        url:
          `dashboard/equipment-decommission-stats?agency_id=${agency}`,
      }),
      invalidatesTags: [""],
    }),
    corporateList: build.query({
      query: (params) => ({
        url:
          `corporate/list?status=${params.status}`,
      }),
      invalidatesTags: [""],
    }),
    createPeriodInspection: build.mutation({
      query: ({period, facility_id, code}) => ({
        url: `inspection/create/period/${period}`,
        method: "POST",
        body: {
          facility_id: facility_id,
          form_code: code,
        }
      }),
      providesTags: [""]
    }),
    createCorporateAccount: build.mutation({
      query: (body) => ({
        url: `auth/corporate-registration`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    assignFboWithCorporate: build.mutation({
      query: (body) => ({
        url: `corporate/assign-agency`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    unAssignFbo: build.mutation({
      query: (body) => ({
        url: `corporate/un-assign-agency`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    regionsList: build.query({
      query: () => ({
        url: `region/list`,
      }),
      invalidatesTags: [""],
    }),
    regionDetails: build.query({
      query: (id) => ({
        url: `region/${id}/details`,
      }),
      invalidatesTags: [""],
    }),
    createRegion: build.mutation({
      query: (body) => ({
        url: `region/create-update-assign`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
    deleteRegion: build.mutation({
      query: (id) => ({
        url: `region/${id}/delete`,
        method: "POST",
      }),
      providesTags: [""]
    }),
    dashboardCorporateFBO: build.query({
      query: (filter) => ({
        url: `corporate/dashboard/agency-metrics?filter=${filter}`,
      }),
      invalidatesTags: [""],
    }),
    dashboardCorporateEquipment: build.query({
      query: () => ({
        url: `corporate/dashboard/equipments`,
      }),
      invalidatesTags: [""],
    }),
    dashboardCorporatePersonnel: build.query({
      query: () => ({
        url: `corporate/dashboard/personnel`,
      }),
      invalidatesTags: [""],
    }),
    updateCorporateProfile: build.mutation({
      query: (body) => ({
        url: `corporate/profile/update-profile-settings`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    updateCorporateCorrective: build.mutation({
      query: (body) => ({
        url: `corporate/profile/update-corrective-settings`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    corporateDetails: build.query({
      query: (id) => ({
        url: `corporate/${id}/details`,
      }),
      invalidatesTags: ["CorporateDetails"],
    }),
    updateCorporate: build.mutation({
      query: ({body, id}) => ({
        url: `corporate/${id}/update`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    corporateUsersList: build.query({
      query: (id) => ({
        url: `corporate/users/list`,
      }),
      invalidatesTags: ["CorporateUsers"],
    }),
    agencyWaterTestKitTypes: build.query({
      query: (id) => ({
        url: `agency/water-test-kit-types`,
      }),
      invalidatesTags: ["CorporateUsers"],
    }),
    updateCorporatePricing: build.mutation({
      query: ({body, id}) => ({
        url: `corporate/update-prices/${id}`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    updateSettingsAsCorporate: build.mutation({
      query: ({body, id}) => ({
        url: `agency/${id}/update-settings-as-corporate`,
        method: "POST",
        body: body,
      }),
      providesTags: [""]
    }),
    deleteAgency: build.mutation({
      query: (id) => ({
        url: `agency/${id}/delete`,
        method: "POST",
      }),
      providesTags: [""]
    }),
    skipInspection: build.mutation({
      query: (inspection_id) => ({
        url: `inspection/skip?inspection_id=${inspection_id}`,
        method: "POST",
      }),
      providesTags: [""]
    }),
    getTools: build.query({
      query: (params) => ({
        url: `equipment/tools?page=${params.page}&active=${params.active}${params.search.length > 0 ? `&search=${params.search}` : ''}${params.facility_id ? `&facility_id=${params.facility_id}` : ''}`,
      }),
      invalidatesTags: ["ToolsList"],
    }),
    getCalibrations: build.query({
      query: (params) => ({
        url: `inspection/get-calibration-inspections-by-agency?agency_id=${params?.agency_id}
        ${params?.inspection_type ? `&inspection_type=${params?.inspection_type}` : ''}
        ${params?.search?.length > 0 ? `&search=${params?.search}` : ''}
        ${params.is_complete === 'completed' ? `&is_complete=${1}` : params.is_complete === 'new' ? `&is_complete=${0}` : ''}
        ${params.date_from && params.date_from !== "Invalid date" ? `&date_from=${params.date_from}` : ''}
        ${params.date_to && params.date_to !== "Invalid date" ? `&date_to=${params.date_to}` : ''}
        ${params.equipment_id ? `&equipment_id=${params.equipment_id}` : ''}
        `,
      }),
      invalidatesTags: ["CalibrationsList"],
    }),
    getComments: build.query({
      query: (inspection_id) => ({
        url: `inspection/get-comments?inspection_id=${inspection_id}`,
      }),
      invalidatesTags: ["CommentsList"]
    }),
    addComments: build.mutation({
      query: (body) => ({
        url: `inspection/add-comment`,
        method: "POST",
        body
      }),
      providesTags: ["CommentsList"]
    }),
    removeFacility: build.mutation({
      query: (id) => ({
        url: `facility/${id}/delete`,
        method: "POST",
      }),
      providesTags: ["FacilityList"]
    }),
    agencyInspectionEnable: build.mutation({
      query: (body) => ({
        url: `agency/enable-inspection`,
        method: "POST",
        body
      }),
      providesTags: [""]
    }),
    agencyDeactivate: build.mutation({
      query: (id) => ({
        url: `agency/${id}/deactivate`,
        method: "POST",
      }),
      providesTags: [""]
    }),
    agencyReactivate: build.mutation({
      query: (id) => ({
        url: `agency/${id}/reactivate`,
        method: "POST",
      }),
      providesTags: [""]
    }),
    agencySetSubscription: build.mutation({
      query: (body) => ({
        url: `agency/assign-subscription`,
        method: "POST",
        body
      }),
      providesTags: [""]
    }),
    agencyInactive: build.query({
      query: (params) => ({
        url: `agency/inactive?page=${params?.page}&limit=10${params?.search ? `&search=${params?.search}` : ''}`,
      }),
      invalidatesTags: ["InactiveList"]
    }),
    corporateUpdateStatus: build.mutation({
      query: (data) => ({
        url: `corporate/${data?.id}/update-status`,
        method: "POST",
        body: data?.body
      }),
      providesTags: [""]
    }),
    setAgencyToUser: build.mutation({
      query: (body) => ({
        url: `account/set-agency`,
        method: "POST",
        body: body
      }),
      providesTags: [""]
    }),
  //
  }),
});
export const {
  useGetInspectionQuery,
  useGetFacilityQuery,
  useLazyGetFacilityQuery,
  useLazyGetFacilityIndexQuery,
  useCreateFacilityMutation,
  useGetPeriodsQuery,
  useCreateInspectionMutation,
  useGetFieldTypesQuery,
  useCreateInspectionStepMutation,
  useLazyGetEquipmentQuery,
  useLazyGetEquipmentAllQuery,
  useCreateCategoryMutation,
  useLazyGetInspectionByIdQuery,
  useDeleteCategoryMutation,
  useChangeCategoryMutation,
  useUpdateFieldMutation,
  useReorderCategoryMutation,
  useGetEquipmentTypeQuery,
  useLazyGetEquipmentTypeQuery,
  useLazyGetInspectionsByTypeQuery,
  useUpdateEquipmentTypeMutation,
  useLazyGetCurrentInspectionQuery,
  useCompleteInspectionMutation,
  useLazyGetInspectionByFacilityQuery,
  useLazyGetCorrectiveActionsQuery,
  useLazyGetCurrentFacilityInspectionsQuery,
  useUpdateFacilityInfoMutation,
  useRefuseInspectionsMutation,
  useChangeCorrectiveActionMutation,
  useLazyGetInspectionFacilityByIdQuery,
  useGetReportListQuery,
  useLazyGetReportListQuery,
  useCreateReportMutation,
  useCreateFuelReceiptReportMutation,
  useGetStationsListQuery,
  useCreateStationMutation,
  useGetTerminalsListQuery,
  useCreateTerminalMutation,
  useUpdateStationMutation,
  useUpdateTerminalMutation,
  useDeleteStationMutation,
  useDeleteTerminalMutation,
  useCreateEquipmentMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUsersByAgencyQuery,
  useLazyGetChosenUserQuery,
  useGetUsersRolesQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useUnAssignEquipmentMutation,
  useUpdateEquipmentMutation,
  useEquipmentDecommissionMutation,
  useEquipmentActivateMutation,
  useLazyGetReportsQuery,
  useLazyGetReportHistoryQuery,
  useLazyGetMissedInspectionsQuery,
  useLazyGetReturnToServiceQuery,
  useUpdateMissedInspectionMutation,
  useGetServiceRequestsQuery,
  useLazyGetServiceRequestsQuery,
  useUpdateServiceRequestMutation,
  useLazyGetSheetsQuery,
  useGetAgencyQuery,
  useLazyGetAgencyQuery,
  useLazyGetAgencyIndexQuery,
  useLazyGetAgencySubscribedQuery,
  useLazyGetInvitedAgencyIndexQuery,
  useDeleteInvitedAgencyMutation,
  useResendInvitedAgencyMutation,
  useCreateAgencyMutation,
  useUpdateAgancyMutation,
  useLazyRefreshInspectionsQuery,
  useLazyGetCurrentInspectionAndCategoryQuery,
  useLazyGetCurrentEquipmentQuery,
  useCreateServiceMutation,
  useSaveEquipmentFormMutation,
  useGetAssignedQuery,
  useLazyGetInspectionsPaginationQuery,
  useDeleteInspectionMutation,
  useSaveProfilePhotoMutation,
  useUploadFileMutation,
  useGetTimezonesQuery,
  useForgotPasswordMutation,
  useSendCodeMutation,
  useChangePasswordMutation,
  useLazyGetOutOfServiceQuery,
  useGetFuelTypesQuery,
  useGetSecretKeyMutation,
  useGetCorporateSecretKeyMutation,
  useRemoveReportMutation,
  useUpdatePaymentMutation,
  useUpdateCorporatePaymentMutation,
  usePaymentCheckoutMutation,
  usePaymentCorporateCheckoutMutation,
  usePaymentConfirmMutation,
  usePaymentCorporateConfirmMutation,
  useBuyPhonesMutation,
  useGetHistorySubscriptionQuery,
  useGetHistoryPaymentsQuery,
  useGetHistoryLeasingQuery,
  useGetHistoryOrdersQuery,
  useGetPricesQuery,
  useGetCorporatePricesQuery,
  useLazyGetPricesQuery,
  useGetFuelReceiptQuery,
  useLazyGetFuelReceiptQuery,
  useLazyGetPaymentInfoQuery,
  useGetOrdersListQuery,
  useLazyGetOrdersListQuery,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useUpdateSettingMutation,
  useUpdateBillingMutation,
  useUpdateOrderMutation,
  useCreateManualOrderMutation,
  useDeleteEquipmentMutation,
  useUpdateUserProfileMutation,
  useUpdateUserProfilePasswordMutation,
  useClearInspectionsMutation,
  useUpdateAgancySettingsMutation,
  useUpdateAgancyProfileMutation,
  useUpdateAgancyProfileSettingsMutation,
  useUpdateAgencyCorrectiveSettingsMutation,
  useGetUsersByAgencyIdQuery,
  useLazyGetUsersByAgencyIdQuery,
  useCancelSubscriptionMutation,
  useGetAgencyByIdQuery,
  useLazyGetTrialAgencyQuery,
  useLazyGetAgencyByIdQuery,
  useLazyGetOrdersListFboQuery,
  useLazyGetSubscriptionsFboQuery,
  useUpdateOrderShippingAddressMutation,
  useClearInspectionsFboMutation,
  useReactivateFboEquipmentsMutation,
  useGetCustomerRequestsQuery,
  useLazyGetCustomerRequestsQuery,
  useUpdateCustomerRequestMutation,
  useSentCustomerLinkMutation,
  useInviteFboMutation,
  useStartAgencyTrialMutation,
  useExtendAgencyTrialMutation,
  useLazyDashboardPersonalStatsQuery,
  useLazyDashboardEquipmentStatsQuery,
  useLazyDashboardPersonalInfoQuery,
  useLazyDashboardOutStandingInspectionsQuery,
  useLazyDashboardOutStandingRtsQuery,
  useLazyDashboardCorrectiveActionsQuery,
  useLazyDashboardAveragePerInspectionQuery,
  useLazyDashboardInspectionsQuery,
  useLazyGetFacilityByAgencyQuery,
  useCanAddFacilityMutation,
  useUpdateFboPricesMutation,
  useLazyMetricsInspectionsPlannedQuery,
  useLazyMetricsInspectionsYesterdayQuery,
  useLazyMetricsInspectionsMissedQuery,
  useLazyMetricsEquipmentCountTypeQuery,
  useLazyMetricsEquipmentRemovedYesterdayQuery,
  useLazyMetricsUpcomingInspectionsQuery,
  useLazyMetricsCorrectiveActionsStatsQuery,
  useLazyMetricsInspectionByEquipmentTypeQuery,
  useLazyMetricsAverageInspectionDurationQuery,
  useLazyMetricsEquipmentDecommissionsQuery,
  useLazyMetricsEquipmentRemovedQuery,
  useCreatePeriodInspectionMutation,
  useUnAssignFacilityMutation,
  useGetAgenciesWithEquipmentsQuery,
  useLazyGetAgenciesWithEquipmentsQuery,
  useCreateFiltersChangeMutation,
  useCreateFuelQualityRecordMutation,
  useCreateFuelReceiptInspectionMutation,
  useLazyCorporateListQuery,
  useCreateCorporateAccountMutation,
  useAssignFboWithCorporateMutation,
  useUnAssignFboMutation,
  useLazyRegionsListQuery,
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useLazyRegionDetailsQuery,
  useLazyDashboardCorporateFBOQuery,
  useLazyDashboardCorporateEquipmentQuery,
  useLazyDashboardCorporatePersonnelQuery,
  useUpdateCorporateProfileMutation,
  useUpdateCorporateCorrectiveMutation,
  useLazyCorporateDetailsQuery,
  useUpdateCorporateMutation,
  useCorporateUsersListQuery,
  useAgencyWaterTestKitTypesQuery,
  useUpdateCorporatePricingMutation,
  useLazyGetPaymentInfoCorporateQuery,
  useUpdateCorporateBillingMutation,
  useUpdateSettingsAsCorporateMutation,
  useDeleteAgencyMutation,
  useSkipInspectionMutation,
  useLazyGetToolsQuery,
  useLazyGetCalibrationsQuery,
  useLazyGetCommentsQuery,
  useAddCommentsMutation,
  useRemoveFacilityMutation,
  useAgencyInspectionEnableMutation,
  useAgencyDeactivateMutation,
  useAgencyReactivateMutation,
  useAgencySetSubscriptionMutation,
  useLazyAgencyInactiveQuery,
  useCorporateUpdateStatusMutation,
  useSetAgencyToUserMutation,
} = inspectionApi;

import React from 'react';
import './index.scss';

const CheckData = () => {
  return (
    <div className="checkData">
      <div className="checkData__left">
        <img src="/assets/register-bg.png" alt="bg" />
      </div>
      <div className="checkData__right">
        <div className="checkData__box">
          <div className="checkData__box-img">
            <img src="/assets/check-data.png" alt="icon" />
          </div>
          <p className="checkData__title">NDX Checking data</p>
          <p className="checkData__text">
            You have expired 7 days to install the equipment, NDX will start checking employees and equipment,
            please wait, you will receive email notifications with further actions.
          </p>
            <button className="checkData__btn auth__submit">Got it</button>
        </div>
      </div>
    </div>

  );
};

export default CheckData;
import React, {useState} from 'react';
import './index.scss';
import {useNavigate} from "react-router-dom";

const Installation = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  return (
    <div className="installation">
      <div className="installation__left">
        <img src="/assets/register-bg.png" alt="bg" />
      </div>
      <div className="installation__right">
        <div className="installation__box">
          <div className="installation__box-img">
            <img src="/assets/installation.png" alt="icon" />
          </div>
          <p className="installation__title">Equipment installation</p>
          <p className="installation__text">
            Way to go!  You just setup your admin and FBO account account.  Proceed to the next page to log in and finish setting up your FBO settings.
          </p>
          {/*<div className="auth__checkbox">*/}
          {/*  <input type="checkbox" id="remember" onChange={handleChange} />*/}
          {/*  <label htmlFor="remember">I have read the information</label>*/}
          {/*</div>*/}
          <div style={{height: 16}}>
          </div>
          <button
            className={`installation__btn auth__submit`}
            // disabled={!checked}
            onClick={() => {
                navigate('/login');
            }}
          >Proceed to Log In</button>
        </div>
      </div>
    </div>
  );
};

export default Installation;
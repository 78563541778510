import React from 'react';

const Profile3 = ({active}) => {
  return (
    <div>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24 32.8C28.8601 32.8 32.8 28.8601 32.8 24C32.8 19.1399 28.8601 15.2 24 15.2C19.1399 15.2 15.2 19.1399 15.2 24C15.2 28.8601 19.1399 32.8 24 32.8ZM24 35C30.0751 35 35 30.0751 35 24C35 17.9249 30.0751 13 24 13C17.9249 13 13 17.9249 13 24C13 30.0751 17.9249 35 24 35Z" fill={active ? "#464646" : "#FFFFFF"}/>
        <path d="M22.8996 18.5004C22.8996 17.8929 23.3921 17.4004 23.9996 17.4004C24.6071 17.4004 25.0996 17.8929 25.0996 18.5004C26.9221 18.5004 28.3996 19.9779 28.3996 21.8004C28.3996 22.4079 27.9071 22.9004 27.2996 22.9004C26.6921 22.9004 26.1996 22.4079 26.1996 21.8004C26.1996 21.1929 25.7071 20.7004 25.0996 20.7004H22.6174C22.1657 20.7004 21.7996 21.0665 21.7996 21.5181C21.7996 21.8701 22.0248 22.1826 22.3588 22.2939L26.3361 23.6197C27.5684 24.0305 28.3996 25.1837 28.3996 26.4826C28.3996 28.1493 27.0485 29.5004 25.3819 29.5004H25.0996C25.0996 30.1079 24.6071 30.6004 23.9996 30.6004C23.3921 30.6004 22.8996 30.1079 22.8996 29.5004C21.0771 29.5004 19.5996 28.0229 19.5996 26.2004C19.5996 25.5929 20.0921 25.1004 20.6996 25.1004C21.3071 25.1004 21.7996 25.5929 21.7996 26.2004C21.7996 26.8079 22.2921 27.3004 22.8996 27.3004H25.3819C25.8335 27.3004 26.1996 26.9343 26.1996 26.4826C26.1996 26.1306 25.9744 25.8181 25.6404 25.7068L21.6631 24.381C20.4308 23.9703 19.5996 22.8171 19.5996 21.5181C19.5996 19.8515 20.9507 18.5004 22.6174 18.5004H22.8996Z" fill={active ? "#464646" : "#FFFFFF"}/>
      </svg>
    </div>
  );
};

export default Profile3;
import React from 'react';

const Corrective = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5C3 2.79086 4.79086 1 7 1H15.3431C16.404 1 17.4214 1.42143 18.1716 2.17157L19.8284 3.82843C20.5786 4.57857 21 5.59599 21 6.65685V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5ZM19 8V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14V5C14 6.65685 15.3431 8 17 8H19ZM18.8891 6C18.7909 5.7176 18.6296 5.45808 18.4142 5.24264L16.7574 3.58579C16.5419 3.37035 16.2824 3.20914 16 3.11094V5C16 5.55228 16.4477 6 17 6H18.8891Z" fill={color}/>
      <path d="M8 3.5C8 3.22386 8.22386 3 8.5 3H9.5C9.77614 3 10 3.22386 10 3.5V4.5C10 4.77614 9.77614 5 9.5 5H8.5C8.22386 5 8 4.77614 8 4.5V3.5Z" fill={color}/>
      <path d="M8 7.5C8 7.22386 8.22386 7 8.5 7H9.5C9.77614 7 10 7.22386 10 7.5V8.5C10 8.77614 9.77614 9 9.5 9H8.5C8.22386 9 8 8.77614 8 8.5V7.5Z" fill={color}/>
      <path d="M10 9.5C10 9.22386 10.2239 9 10.5 9H11.5C11.7761 9 12 9.22386 12 9.5V10.5C12 10.7761 11.7761 11 11.5 11H10.5C10.2239 11 10 10.7761 10 10.5V9.5Z" fill={color}/>
      <path d="M8.5 11C8.22386 11 8 11.2239 8 11.5V12.5C8 12.7761 8.22386 13 8.5 13H9.5C9.77614 13 10 12.7761 10 12.5V11.5C10 11.2239 9.77614 11 9.5 11H8.5Z" fill={color}/>
      <path d="M10 13.5C10 13.2239 10.2239 13 10.5 13H11.5C11.7761 13 12 13.2239 12 13.5V14.5C12 14.7761 11.7761 15 11.5 15H10.5C10.2239 15 10 14.7761 10 14.5V13.5Z" fill={color}/>
      <path d="M8.5 15C8.22386 15 8 15.2239 8 15.5V16.5C8 16.7761 8.22386 17 8.5 17H9.5C9.77614 17 10 16.7761 10 16.5V15.5C10 15.2239 9.77614 15 9.5 15H8.5Z" fill={color}/>
      <path d="M10 17.5C10 17.2239 10.2239 17 10.5 17H11.5C11.7761 17 12 17.2239 12 17.5V18.5C12 18.7761 11.7761 19 11.5 19H10.5C10.2239 19 10 18.7761 10 18.5V17.5Z" fill={color}/>
      <path d="M8.5 19C8.22386 19 8 19.2239 8 19.5V20.5C8 20.7761 8.22386 21 8.5 21H9.5C9.77614 21 10 20.7761 10 20.5V19.5C10 19.2239 9.77614 19 9.5 19H8.5Z" fill={color}/>
      <path d="M10.5 5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 10.2239 7 10.5 7H11.5C11.7761 7 12 6.77614 12 6.5V5.5C12 5.22386 11.7761 5 11.5 5H10.5Z" fill={color}/>
    </svg>

  );
};

export default Corrective;
import React, {useEffect, useState} from 'react';
import Input from "../../Input";
import {validationEmail} from "../../../global/validate";
import {toast} from "react-toastify";
import {useCreateCorporateAccountMutation, useUpdateCorporateMutation} from "../../../store/builder/inspections.api";
import {setLoader} from "../../../store/builder/reducer";
import {useDispatch} from "react-redux";
import {useErrorHandler} from "../../../global/ErrorsHandler";

const CorporateCreateModal = ({exit, getCorporateList, currentCorporateAccount}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    first_name: null,
    last_name: null,
    email: null,
    // fbo_corporate_phone: null,
    fbo_corporate_name: null,
    fbo_corporate_email: null,
    password: null,
    password_confirmation: null,
  })
  const [phoneNumber, setPhoneNumber] = useState("");

  const [createCorporate, {
    data: createCorporateData,
    error: createCorporateError,
    isFetching: createCorporateIsLoading,
    isSuccess: createCorporateIsSuccess,
    isError: createCorporateIsError,
  }] = useCreateCorporateAccountMutation();

  const [updateCorporate, {
    data: updateCorporateData,
    error: updateCorporateError,
    isFetching: updateCorporateIsLoading,
    isSuccess: updateCorporateIsSuccess,
    isError: updateCorporateIsError,
  }] = useUpdateCorporateMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([createCorporateError])

  useEffect(() => {
    if (createCorporateIsLoading) {
      dispatch(setLoader(true))
    }
    if (createCorporateIsSuccess) {
      dispatch(setLoader(false))
      getCorporateList()
      toast.success('Corporate account successfully created!')
      exit()
    }
  }, [createCorporateIsLoading, createCorporateIsSuccess])

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const handleChange = (event) => {
    setState({
     ...state,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    if (state.first_name?.length >= 2 &&
      state.last_name?.length >= 3 &&
      validationEmail(state.email) &&
      validationEmail(state.fbo_corporate_email) &&
      // phoneNumber?.length > 9 &&
      state.fbo_corporate_name?.length > 0 &&
      state.password?.length > 3 &&
      state.password_confirmation?.length > 3 &&
      state.password === state.password_confirmation
    ) {
      createCorporate({...state})
    }

    if (!state.first_name || state.first_name?.length < 2) {
      toast.error('First name should be at least 2 characters')
    }
    if (!state.last_name || state.last_name?.length < 3) {
      toast.error('Last name should be at least 3 characters')
    }
    if (!validationEmail(state.email)) {
      toast.error('Please write correct email')
    }
    if (!validationEmail(state.fbo_corporate_email)) {
      toast.error('Please write correct corporate email')
    }
    // if (!phoneNumber || phoneNumber?.length < 10) {
    //   toast.error('Please write correct phone number')
    // }
    if (!state.fbo_corporate_name || state.fbo_corporate_name?.length < 3) {
      toast.error('Corporate name should be at least 3 characters')
    }

    if (!state.password || state.password?.length < 3) {
      toast.error('Password should be at least 3 characters')
    }

    if (!state.password_confirmation || state.password_confirmation?.length < 3) {
      toast.error('Confirm password should be at least 3 characters')
    }

    if (state?.password !== state?.password_confirmation) {
      toast.error('Password and confirm password must be the same')
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{"Create Corporate"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <Input
            containerWidth={'100%'}
            id="first_name"
            name="first_name"
            placeholder="Enter first name"
            label={"First name"}
            type={"text"}
            onChange={handleChange}
          />
          <Input
            containerWidth={'100%'}
            id="last_name"
            name="last_name"
            placeholder="Enter last name"
            label={"Last name"}
            type={"text"}
            onChange={handleChange}
          />
          <Input
            containerWidth={'100%'}
            id="email"
            name="email"
            placeholder="Enter email"
            label={"Email"}
            type={"text"}
            onChange={handleChange}
          />
          {/*<div className="auth__field phone">*/}
          {/*  <label htmlFor="corporate_phone" className="auth__label fbo">*/}
          {/*    Phone Number*/}
          {/*  </label>*/}
          {/*  <span className="phone__num">+1</span>*/}
          {/*  <input*/}
          {/*    id="fbo_corporate_phone"*/}
          {/*    name="fbo_corporate_phone"*/}
          {/*    placeholder="Enter Phone Number"*/}
          {/*    type="tel"*/}
          {/*    value={phoneNumber}*/}
          {/*    className="auth__input"*/}
          {/*    onChange={handlePhoneChange}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<Input*/}
          {/*  containerWidth={'100%'}*/}
          {/*  id="fbo_corporate_phone"*/}
          {/*  name="fbo_corporate_phone"*/}
          {/*  placeholder="Enter phone number"*/}
          {/*  label={"Phone Number"}*/}
          {/*  type={"num"}*/}
          {/*  onChange={handleChange}*/}
          {/*/>*/}
          <Input
            containerWidth={'100%'}
            id="fbo_corporate_name"
            name="fbo_corporate_name"
            placeholder="Enter Corporate Name"
            label={"Corporate Name"}
            type={"text"}
            onChange={handleChange}
          />
          <Input
            containerWidth={'100%'}
            id="fbo_corporate_email"
            name="fbo_corporate_email"
            placeholder="Enter Corporate email"
            label={"Corporate Email"}
            type={"text"}
            onChange={handleChange}
          />
          <Input
            containerWidth={'100%'}
            id="password"
            name="password"
            placeholder="Enter password"
            label={"Password"}
            type={"password"}
            onChange={handleChange}
          />
          <Input
            containerWidth={'100%'}
            id="password_confirmation"
            name="password_confirmation"
            placeholder="Enter confirm password"
            label={"Confirm password"}
            type={"password"}
            onChange={handleChange}
          />
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Cancel
          </button>
          <button className="save" onClick={handleSubmit}>
            Create
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default CorporateCreateModal;
import React, {useEffect, useState} from 'react';
import './index.scss';
import Input from "../../Input";
import PlusIcon from "../../../SVG/PlusIcon";
import CorporateRegionsModal from "../CorporateRegionsModal";
import {
  useDeleteRegionMutation,
  useLazyGetAgencyQuery
} from "../../../store/builder/inspections.api";
import {useDebounce} from "../../../pages/LogSheets/hooks";
import RegionRow from "../../RegionRow";
import {useDispatch} from "react-redux";
import {setLoader} from "../../../store/builder/reducer";
import {useErrorHandler} from "../../../global/ErrorsHandler";
import {toast} from "react-toastify";

const CorporateRegions = ({regions, getRegionsList, setCurrentTab, setChosenRegionId, setCurrentRegion, currentRegion}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [newRegionsModal, setNewRegionsModal] = useState(false);
  const [deleteRegionsModal, setDeleteRegionsModal] = useState(false)
  const [regionsList, setRegionsList] = useState([])
  // const [currentRegion, setCurrentRegion] = useState(null)
  const [filteredRegions, setFilteredRegions] = useState([])
  const debouncedSearchValue = useDebounce(search, 500);

  const [
    getAgencyList,
    {
      data: agencyListData,
      isLoading: agencyListIsLoading,
      isError: agencyListIsError,
      error: agencyListError,
      isSuccess: agencyListIsSuccess,
    },
  ] = useLazyGetAgencyQuery()

  const [deleteRegion,
    {
      data: deleteRegionData,
      isLoading: deleteRegionIsLoading,
      isError: deleteRegionIsError,
      error: deleteRegionError,
      isSuccess: deleteRegionIsSuccess,
    }] = useDeleteRegionMutation();

  useEffect(() => {
    getAgencyList()
  }, [])

  useEffect(() => {
    if (deleteRegionIsLoading) {
      dispatch(setLoader(true))
    }
    if (deleteRegionIsSuccess) {
      dispatch(setLoader(false))
      toast.success('Region deleted successfully')
      setDeleteRegionsModal(false)
      getRegionsList()
    }
  }, [deleteRegionIsLoading, deleteRegionIsSuccess])

  useEffect(() => {
    if (regions?.length > 0) {
      const s = [...regions]
      setRegionsList(s?.map(item => ({...item, dropdown_status: false})))
    }
  }, [regions])

  useEffect(() => {
    const filter = regionsList?.filter((el) => {
      if (debouncedSearchValue === "") {
        return el
      } else {
        return el?.name?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredRegions(filter)
  }, [debouncedSearchValue, regionsList])

  const [errorHandler, setErrorHandler] = useErrorHandler([deleteRegionError]);

  const removeRegion = () => {
    if (currentRegion!== null) {
      deleteRegion(currentRegion.id)
    } else {

    }
  }
  return (
    <div className="regions">
      {newRegionsModal &&
        <CorporateRegionsModal
          exit={() => {
            setNewRegionsModal(false)
            setCurrentRegion(null)
          }}
          agencies={agencyListData}
          currentRegion={currentRegion}
          setCurrentRegion={setCurrentRegion}
          getRegionsList={getRegionsList}
        />
      }
      {
        deleteRegionsModal &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p></p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setDeleteRegionsModal(false)}
              />
            </div>
            <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
              Are you sure you want to delete this region?
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => setDeleteRegionsModal(false)}>
                Close
              </button>
              <button className="save" onClick={removeRegion}>
                Yes
              </button>
            </div>
          </div>
          <div className="modal__bg" onClick={() => setDeleteRegionsModal(false)}></div>
        </div>
      }
      <p className="profile__title">Regions Settings</p>
      <div className="regions__header">
        <Input
          type="text"
          placeholder="Search by name"
          name="ordersSearch"
          id="ordersSearch"
          image={"/assets/icons/search.svg"}
          maxwidth={204}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div className="regions__header-btn" onClick={() => {
          setNewRegionsModal(true)
          setCurrentRegion(null)
        }}>
          <PlusIcon />
          <span>New Region</span>
        </div>
      </div>
      <div className="regions__table">
        <div className="regions__table-header">
          <div className="regions__table-th">Regions name</div>
          <div className="regions__table-th">Total FBOs</div>
          <div className="regions__table-th"></div>
        </div>
        <div className="regions__table-body">
          {
            filteredRegions?.length > 0 ?
              filteredRegions?.map((region) => (
                <RegionRow
                  key={region.id}
                  region={region}
                  regionsList={regionsList}
                  setRegionsList={setRegionsList}
                  setNewRegionsModal={setNewRegionsModal}
                  setCurrentRegion={setCurrentRegion}
                  getRegionsList={getRegionsList}
                  setDeleteRegionsModal={setDeleteRegionsModal}
                  setCurrentTab={setCurrentTab}
                  setChosenRegionId={setChosenRegionId}
                />
              ))
            :
              <div className="facility__table-empty">
                <img
                  src="/assets/icons/facility-empty.svg"
                  alt="empty"
                />
                <p>There are no Regions!</p>
              </div>
          }

        </div>
      </div>
    </div>
  );
};

export default CorporateRegions;
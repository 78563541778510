import React from 'react';

const Service = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3775 13.3588C21.9126 13.8893 22.5 14.6985 22.5 15.7066V16.375C22.5 17.8247 21.3247 19 19.875 19H4.125C2.67525 19 1.5 17.8247 1.5 16.375L1.5 15.7066C1.5 14.6985 2.08736 13.8893 2.62253 13.3588C2.96849 13.0158 3.25 12.5659 3.25 12C3.25 11.4341 2.96849 10.9842 2.62253 10.6412C2.08736 10.1107 1.5 9.30151 1.5 8.29343L1.5 7.62502C1.5 6.17527 2.67525 5.00002 4.125 5.00002L19.875 5C21.3247 5 22.5 6.17525 22.5 7.625V8.29343C22.5 9.30151 21.9126 10.1107 21.3775 10.6412C21.0315 10.9842 20.75 11.4341 20.75 12C20.75 12.5659 21.0315 13.0158 21.3775 13.3588ZM20.75 15.7066C20.75 15.3889 20.5517 15.0044 20.1454 14.6016C19.6088 14.0696 19 13.1994 19 12C19 10.8006 19.6088 9.9304 20.1454 9.39844C20.5517 8.99564 20.75 8.61106 20.75 8.29343V7.625C20.75 7.14175 20.3582 6.75 19.875 6.75L4.125 6.75002C3.64175 6.75002 3.25 7.14177 3.25 7.62502V8.29343C3.25 8.61106 3.44826 8.99564 3.85458 9.39844C4.39118 9.9304 5 10.8006 5 12C5 13.1994 4.39118 14.0696 3.85458 14.6016C3.44826 15.0044 3.25 15.3889 3.25 15.7066V16.375C3.25 16.8582 3.64175 17.25 4.125 17.25L19.875 17.25C20.3582 17.25 20.75 16.8582 20.75 16.375V15.7066Z" fill={color}/>
      <path d="M14.7071 10.7071L10.7071 14.7071C10.3166 15.0976 9.68342 15.0976 9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L13.2929 9.29289C13.6834 8.90237 14.3166 8.90237 14.7071 9.29289C15.0976 9.68342 15.0976 10.3166 14.7071 10.7071Z" fill={color}/>
      <path d="M11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10Z" fill={color}/>
      <path d="M13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13C13.4477 13 13 13.4477 13 14Z" fill={color}/>
    </svg>

  );
};

export default Service;
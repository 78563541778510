export const mockBarChartData = [
  {
    "key": "2024-01-26 00:00:00",
    "value": 1
  },
  {
    "key": "2024-01-27 00:00:00",
    "value": 3
  },
  {
    "key": "2024-01-28 00:00:00",
    "value": 2
  }
]

export const mockCircleChartData = [
  {
    "key": "global",
    "value": 2
  },
  {
    "key": "admin",
    "value": 2
  },
  {
    "key": "linesman",
    "value": 8
  },
  {
    "key": "inspection",
    "value": 4
  }
]

export   const testDataRow = [
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'red'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'green'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'green'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'yellow'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'red'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'green'
  },
  {
    title: 'FILTER VESSEL JET-A',
    count: '4/5',
    time: '2h 32m',
    status: 'green'
  },
]

export const tableHeadMockData = [
  {
    label: 'Completed Date',
    value: 'completed_at',
    is_date: true
  },
  {
    label: 'Inspection ID',
    value: 'inspection_id',
  },
  {
    label: 'Facility',
    value: 'facility_name'
  },
  {
    label: 'NFC',
    value: 'nfc',
    image: true,
  },
  {
    label: 'Completed by',
    value: 'closed_by',
    is_uppercase: true,
  },
]
export const table1BodyMockData = [
  {
    id: 1,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 2,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 3,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 4,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 5,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 6,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 7,
    inspection_id: 'JF-3',
    facility: 'LavCart',
    nfc: '/assets/icons/nfc-mid.png',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },

]


export const tableHead2MockData = [
  {
    label: 'Due Date',
    value: 'due_date',
    is_date: true
  },
  {
    label: 'Inspections',
    value: 'inspection_id',
  },
  {
    label: 'Equipment ID',
    value: 'equipment_name',
  },
  {
    label: 'Facility',
    value: 'facility_name'
  },
  {
    label: 'Period',
    value: 'period',
    is_uppercase: true,
  },
]

export const tableHeadPersonalMockData = [
  {
    label: 'Name',
    value: 'name',
    with_avatar: true
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Roles',
    value: 'role',
    is_role: true,
  },
  {
    label: 'Last login - Web',
    value: 'last_login_at',
    is_date: true
  },
  {
    label: 'Last login - Mob',
    value: 'last_mobile_login_at',
    is_date: true
  },
]

export const table2BodyMockData = [
  {
    id: 1,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 2,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 3,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 4,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 5,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 6,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
  {
    id: 7,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    inspections: 'Fuel Facility Checks',
    due_date: '04/29/24',
    period: 'Daily',
  },
]

export const tableHead3MockData = [
  {
    label: 'Removed Date',
    value: 'status_updated_at',
    is_date: true
  },
  {
    label: 'Equipment ID',
    value: 'title',
  },
  {
    label: 'Equipment Type',
    value: 'type',
  },
  {
    label: 'Facility',
    value: 'facility'
  },
  {
    label: 'Comment',
    value: 'decommission_comment'
  },
]

export const table3BodyMockData = [
  {
    id: 1,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 2,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 3,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 4,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 5,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 6,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
  {
    id: 7,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    equipment_type: 'STORAGE TANK',
    removed_date: '04/29/24',
    comment: 'Seal replaced',
  },
]

export const tableHead4MockData = [
  {
    label: 'Close Date',
    value: 'closed_at',
    is_date: true
  },
  {
    label: 'Equipment ID',
    value: 'equipment_id',
  },
  {
    label: 'Equipment Type',
    value: 'equipment_type',
  },
  {
    label: 'Facility',
    value: 'facility_name'
  },
  {
    label: 'Discrepancy',
    value: 'discrepancy'
  },
  {
    label: 'Corrective Action',
    value: 'action'
  },
  {
    label: 'Close by',
    value: 'closed_by'
  },
]

export const table4BodyMockData = [
  {
    id: 1,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 2,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 3,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 4,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 5,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 6,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
  {
    id: 7,
    equipment_id: 'JF-3',
    facility: 'LavCart',
    discrepancy: 'Fire Extinguishers - Safety / Tamper Seals: seal  Fire Extinguishers - Safety / Tamper Seals: seal missing',
    corrective_action: 'New motor was installed on 12/14/20',
    equipment_type: 'STORAGE TANK',
    close_date: '04/29/24',
    close_by: 'Matthew Sanzobrin',
  },
]

export const tableHeadOpenMockData = [
  {
    label: 'Create date',
    value: 'created_at',
    is_date: true
  },
  {
    label: 'Equipment ID',
    value: 'equipment_title',
  },
  {
    label: 'Equipment Type',
    value: 'equipment_type',
  },
  {
    label: 'Facility',
    value: 'facility_name',
  },
  {
    label: 'Comment',
    value: 'comment',
  },
]

export const tableHead5MockData = [
  {
    label: 'Type Equipment',
    value: 'title',
  },
  {
    label: 'Count',
    value: 'count',
  },
  {
    label: 'NFC chip enabled',
    value: 'with_nfc',
  },
  {
    label: 'Inspections done with chip last month',
    value: 'percent_done_w_chip_last_month',
    is_percent: true,
  },
  {
    label: 'Inspections done with chip this month',
    value: 'percent_done_w_chip_this_month',
    is_percent: true,
  },
]



export const tableHead6MockData = [
  {
    label: 'Name',
    value: 'name',
    with_avatar: true,
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Average time',
    value: 'average_time',
  },
]
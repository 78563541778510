import "./App.css";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Inspections from "./pages/Inspections";
import Sidebar from "./components/Sidebar";
import CorrectiveActions from "./pages/CorrectiveActions";
import EquipmentManagement from "./pages/EquipmentManagement";
import FuelReceiptForm from "./pages/FuelReceiptForm";
import LogSheets from "./pages/LogSheets";
import Proximity from "./pages/Proximity";
import ServiceRequest from "./pages/ServiceRequest";
import UserManagement from "./pages/UserManagement";
import FormBuilder from "./pages/FormBuilder";
import Profile from "./pages/Profile";
import React, {useEffect, useState} from "react";
import CreateEquipment from "./pages/CreateEquipment";
import Facility from "./pages/Facility";
import Loader from "./components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import InspectionsForm from "./pages/InspectionsForm";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import EquipmentType from "./pages/EquipmentType";
import Login from "./pages/Login";
import EquipmentTypeEdit from "./pages/EquipmentTypeEdit";
import FillInspection from "./pages/FillInspection";
import Report from "./pages/Report";
import axios from "axios";
import {setLoader} from "./store/builder/reducer";
import {setUser} from "./store/builder/loginSlice";
import Agancy from "./pages/Agancy";
import ErrorPage from "./pages/404Page";
import EquipmentTypeEditNew from "./pages/EquipmentTypeEditNew";
import AssignedInspections from "./pages/AssignedInspections";
import ForgotPassword from "./pages/ForgotPassword";
import EnterCode from "./pages/EnterCode";
import NewPassword from "./pages/NewPassword";
import Registration from "./pages/Registration";
import ConfirmRegistration from "./pages/ConfirmRegistration";
import Expired from "./pages/Expired";
import Terms from "./pages/Terms";
import OrdersPage from "./pages/Orders";
import OrderDetailsPage from "./pages/OrderDetails";
import Settings from "./pages/Settings";
import AgencyDetail from "./pages/AgencyDetail";
import EquipmentOutModal from "./components/EquipmentOutModal";
import {useLazyGetOutOfServiceQuery} from "./store/builder/inspections.api";
import CheckData from "./pages/CheckData";
import FBOList from "./components/FBOList";
import AgencyTrial from "./pages/AgencyTrial";
import Installation from "./pages/Installation";
import ProfilePlan from "./pages/ProfilePlan";
import Dashboard from "./pages/Dashboard";
import AdvancedMetrics from "./pages/AdvancedDashboard";
import RegistrationNew from "./pages/RegistrationNew";
import FBOSettings from "./pages/FBOSettings";
import CorporateAccount from "./pages/CorporateAccount";
import RegistrationCorporate from "./pages/RegistrationCorporate";
import CorporateDashboard from "./pages/CorporateDashboard";
import InvitedFbo from "./pages/InvitedFbo";
import CorporateSettings from "./pages/CorporateSettings";
import FBOManagement from "./pages/FBOManagement";
import CorporateDetails from "./pages/CorporateDetails";
import ToolsManagement from "./pages/ToolsManagement";
import ToolsCreate from "./pages/ToolsCreate";
import AgencyInactive from "./pages/AgencyInactive";

function App() {
  const [equipmentOutModalStatus, setEquipmentOutModalStatus] = useState(false)
  const [dropdown, setDropdown] = useState(false);
  const loader = useSelector((state) => state.rootReducer.reducer.loader);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const token = localStorage.getItem("ndx_user_token");
  const outOfService = localStorage.getItem("out_of_service");
  const currentFbo = localStorage.getItem("current-fbo");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [trialExpiredPopup, setTrialExpiredPopup] = useState(false);
  const stripeModal = document.getElementById('stripeModal');
  const [getOutOfService, {
    data: outOfServiceData,
    error: outOfServiceError,
    isLoading: outOfServiceIsLoading,
    isSuccess: outOfServiceIsSuccess,
    isError: outOfServiceISError
  }] = useLazyGetOutOfServiceQuery();

  const fboDropDown = localStorage.getItem('navbarDropDown');
  const fboDropDownCorporate = localStorage.getItem('navbarDropDownCorporate');

  // useEffect(() => {
  //   window.dispatchEvent(new Event("storage"));
  // }, []);
  //
  // window.addEventListener('storage', (e) => {
  //   if (e.target.localStorage['current-fbo'] !== currentFbo) {
  //     window.location.reload();
  //   }
  // })

  useEffect(() => {
    if (!userInfo?.is_corporate) {
      if (outOfService == 1) {
        getOutOfService()
        setEquipmentOutModalStatus(true)
      } else {
        setEquipmentOutModalStatus(false)
      }
    }
  }, [outOfService])

  useEffect(() => {
    if (location.pathname !== "/login" &&
      location?.pathname !== "/forgot-password" &&
      location?.pathname !== "/enter-code" &&
      location?.pathname !== "/create-password" &&
      location?.pathname !== "/registration" &&
      location?.pathname !== "/registration-corporate" &&
      location?.pathname !== "/sign-up" &&
      location?.pathname !== "/confirm-registration" &&
      location?.pathname !== "/subscription" &&
      location?.pathname !== "/payment" &&
      location?.pathname !== "/payment-result" &&
      location?.pathname !== "/warning" &&
      location?.pathname !== "/check-data" &&
      location?.pathname !== "/installation" &&
      location?.pathname !== "/terms") {
      if (loader === false) {
        if (token) {
          let config = {
            headers: {
              Authorization: "Bearer " + token,
              AppVersion: '1.0.0',
              AppType: 'web',
              DeviceType: 'web',
              agency: localStorage.getItem("current-fbo")
            },
          };
          axios
            .get(`${process.env.REACT_APP_API_URL}user`, config)
            .then((res) => {
              dispatch(setUser(res.data));
              if (!currentFbo) {
                localStorage.setItem('current-fbo', res?.data?.agency?.id)
                localStorage.setItem('current-fbo-tz', res?.data?.agency?.timezone)
              } else {
                if (!res.data?.is_global && !res.data?.is_corporate) {
                  if (currentFbo !== String(res?.data?.agency?.id)) {
                    localStorage.setItem('current-fbo', res?.data?.agency?.id)
                    localStorage.setItem('current-fbo-tz', res?.data?.agency?.timezone)
                  }
                }
              }
            })
            .catch((err) => {
              dispatch(setLoader(false));
              dispatch(setUser(null));
              navigate("/login");
              toast.error(err?.response?.data?.message);
            });
        }
      }
    }
  }, [token, loader]);

  useEffect(() => {
    if (!fboDropDown || !fboDropDownCorporate) {
      localStorage.setItem('navbarDropDown', 'false')
      localStorage.setItem('navbarDropDownCorporate', 'false')
    }

    if (location.pathname === "/") {
      if (userInfo?.is_management || userInfo?.is_read_only) {
        navigate("/log-sheets");
      } else {
        navigate("/inspections");
      }
    }
    if (userInfo?.is_management || userInfo?.is_read_only) {
      if (location.pathname === "/inspections"){
        navigate("/log-sheets");
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname !== "/login" &&
      location?.pathname !== "/forgot-password" &&
      location?.pathname !== "/enter-code" &&
      location?.pathname !== "/create-password" &&
      location?.pathname !== "/registration" &&
      location?.pathname !== "/registration-corporate" &&
      location?.pathname !== "/sign-up" &&
      location?.pathname !== "/confirm-registration" &&
      location?.pathname !== "/subscription" &&
      location?.pathname !== "/payment" &&
      location?.pathname !== "/payment-result" &&
      location?.pathname !== "/warning" &&
      location?.pathname !== "/installation" &&
      location?.pathname !== "/check-data" &&
      location?.pathname !== "/terms"){

      if (userInfo?.is_admin) {
        if (userInfo?.agency?.is_settings_complete === false) {
          navigate("/fbo-settings");
        }
      }

      // if (userInfo?.subscription === "draft" || userInfo?.subscription === null) {
      //   // navigate('/subscription')
      // } else if (userInfo?.subscription === "processing") {
      //   // navigate('/warning?status=processing')
      //     setWarning(true)
      // } else if (userInfo?.subscription === "expired") {
      //     setWarning(true)
      // } else if (userInfo?.subscription === "cancelled") {
      //     setWarning(true)
      // } else {
      //   return;
      // }

      if (userInfo?.is_admin) {
        if (userInfo?.agency?.trial_status === "complete" && (userInfo?.payment_data === null || userInfo?.payment_data?.length === 0)) {
          if (location?.pathname !== "/profile-plan" && location?.pathname !== "/fbo-settings") {
            setPaymentPopup(true);
          }
        }
      }

      if (userInfo?.is_corporate) {
        if (userInfo?.payment_data === null || userInfo?.payment_data?.length === 0) {
          if (location?.pathname !== "/profile-plan" && location?.pathname !== "/fbo-settings") {
            setPaymentPopup(true);
          }
        }
      }
    }

  }, [userInfo]);

  // const isObjectEmpty = (objectName) => {
  //   return Object.keys(objectName)?.length === 0
  // }

  useEffect(() => {
    if (dropdown) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'visible'
    }
  }, [dropdown])

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".agency__dropdown-view") === null && e.target.closest(".fbo__list") === null) {
        setDropdown(false);
      }
    });
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000}/>
      {
        !userInfo?.is_corporate ?
        (equipmentOutModalStatus && outOfServiceData?.length > 0) &&
        <EquipmentOutModal exit={() => {
          setEquipmentOutModalStatus(false)
          localStorage.removeItem("out_of_service");
        }} data={outOfServiceData}/>
          : null
      }

      {/*{*/}
      {/*  warning && (*/}
      {/*    <div className="modal">*/}
      {/*      <div className="modal__content">*/}
      {/*        <div className="expired__box">*/}
      {/*          <div className="expired__box-img">*/}
      {/*            <img src="/assets/icons/expired-img.png" alt="icon"/>*/}
      {/*          </div>*/}
      {/*          <p className="expired__title">*/}
      {/*            {*/}
      {/*              userInfo?.subscription === "processing" ?*/}
      {/*                "Your payment is still being processed" :*/}
      {/*                "Your subscription has expired"*/}
      {/*            }*/}
      {/*          </p>*/}
      {/*          <p className="expired__text">*/}
      {/*            {*/}
      {/*              userInfo?.subscription === "processing" ?*/}
      {/*                "Your payment is in the process of being completed. Thank you for your patience and understanding."*/}
      {/*                :*/}
      {/*                "We really appreciate your interest in our services and would like to invite you to renew your subscription. After all, thanks to the subscription, you get access to the unique features and resources of our service."*/}
      {/*            }*/}
      {/*          </p>*/}
      {/*          {*/}
      {/*            userInfo?.subscription === "processing" ?*/}
      {/*              null :*/}
      {/*              <div className="equipment__button" style={{maxWidth: "89%"}} onClick={() => {*/}
      {/*                setWarning(false)*/}
      {/*                navigate('/fbo-settings?tabId=0')*/}
      {/*              }}>*/}
      {/*                Subscribe*/}
      {/*              </div>*/}
      {/*          }*/}
      {/*        </div>*/}

      {/*      </div>*/}
      {/*      <div className="modal__bg" onClick={() => setWarning(false)}></div>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*}*/}

      {
        trialExpiredPopup &&
        <div className="modal">
          <div className="modal__content">
            <div className="expired__box">
              <h2 style={{margin: '0 auto 10px'}}>Trial Expired</h2>
              <div className="expired__box-img">
                <img src="/assets/no-payment2.png" alt="noPayment"/>
              </div>
              <p className="no-payment-text">
                You have completed the trial period of the system, now you will need to subscribe and start using full NDX access.
              </p>
              <div className="no-payment-buttons">
                <div className="header__btn no-payment-buttons__payment-method-button" onClick={() => {
                  setTrialExpiredPopup(false);
                  navigate('/fbo-settings?tabId=0')
                }}>
                  Subscribe
                </div>
              </div>

            </div>

          </div>
          <div className="modal__bg"></div>
        </div>
      }
      {
        (paymentPopup && !stripeModal && location.pathname !== "/login") && (
          <div className="modal">
            <div className="modal__content">
              <div className="expired__box">
                <h2 style={{margin: '0 auto 10px'}}>Payment Method</h2>
                <div className="expired__box-img">
                  <img src="/assets/no-payment2.png" alt="noPayment"/>
                </div>
                <p className="no-payment-text">
                  {
                    userInfo?.is_corporate ?
                      "To get full access to the NDX website, you should purchase a subscription. Please add a payment method."
                      :
                      "You have completed the trial period of the system, now you will need to subscribe and start using full NDX access."
                  }
                </p>
                <div className="no-payment-buttons">
                  <div
                    className="header__btn invite no-payment-buttons__skip-button" onClick={() => {
                    setPaymentPopup(false);
                  }}>
                    Skip
                  </div>
                  <div className="header__btn no-payment-buttons__payment-method-button" onClick={() => {
                    setPaymentPopup(false);
                    if (userInfo?.is_corporate) {
                      navigate('/corporate-settings?tab=4')
                    } else {
                      navigate('/profile-plan');
                    }
                  }}>
                    Add Payment Methods
                  </div>
                </div>

              </div>

            </div>
            <div className="modal__bg"></div>
          </div>
        )
      }
      {location.pathname !== "/login" &&
        location?.pathname !== "/forgot-password" &&
        location?.pathname !== "/enter-code" &&
        location?.pathname !== "/create-password" &&
        location?.pathname !== "/registration" &&
        location?.pathname !== "/registration-corporate" &&
        location?.pathname !== "/sign-up" &&
        location?.pathname !== "/confirm-registration" &&
        location?.pathname !== "/subscription" &&
        location?.pathname !== "/payment" &&
        location?.pathname !== "/payment-result" &&
        location?.pathname !== "/warning" &&
        location?.pathname !== "/terms" &&
        location?.pathname !== "/check-data" &&
        location?.pathname !== "/installation" &&
        <Sidebar dropdown={dropdown} setDropdown={setDropdown} />}
      {((userInfo?.is_global || userInfo?.is_corporate) && dropdown) && <FBOList dropdown={dropdown} setDropdown={setDropdown} />}

      {loader && <Loader/>}
      <Routes>
        {
          (userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection') ?
            null
            : <Route path="/dashboard" element={<Dashboard />} />
        }
        <Route path="/advanced-metrics" element={<AdvancedMetrics />}/>
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('inspection') && (
              <Route path="/inspections" element={<Inspections/>}/>
          ))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('inspection_detail') && (
          <Route path="/inspections/:id" element={<FillInspection/>}/>))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('inspection_form') && (
            <>
              <Route path="/inspection-form" element={<InspectionsForm/>}/>
              <Route path="/inspection-form/edit/:id" element={<FormBuilder/>}/>
            </>
          ))
        }

        {
          (userInfo?.agency?.trial_status === 'equipment' && userInfo?.agency?.trial_status === 'inspection') ?
            userInfo?.agency?.is_able_to_add_facility === 1 ?
            <>
              <Route path="/facility" element={<Facility/>}/>
              <Route
                path="/facility/edit/:id"
                element={<EquipmentTypeEdit editType={"facility"}/>}
              />
            </>
              : null :
            userInfo?.permissions?.includes('facility') && (
              <>
                <Route path="/facility" element={<Facility/>}/>
                <Route
                  path="/facility/edit/:id"
                  element={<EquipmentTypeEdit editType={"facility"}/>}
                />
              </>
            )
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('corrective_action') && (
            <Route path="/actions" element={<CorrectiveActions/>}/>
          ))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('log_sheet') && (
            <Route path="/log-sheets" element={<LogSheets/>}/>
          ))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('observation') && (
            <Route path="/observation" element={<ServiceRequest/>}/>
          ))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('equipment_type') && (
            <>
              <Route path="/equipment-type" element={<EquipmentType/>}/>
              <Route
                path="/equipment-type/edit/:id"
                element={<EquipmentTypeEditNew editType={"equipment"}/>}
              />
              <Route path="/equipment-type/assigned" element={<AssignedInspections/>}/>
            </>
          ))
        }
        {
          userInfo?.permissions?.includes('equipment_management') && (
            <>
              <Route path="/equipment" element={<EquipmentManagement/>}/>
              <Route path="/equipment/edit/:id" element={<CreateEquipment/>}/>
              <Route path="/create-equipment" element={<CreateEquipment/>}/>
            </>
          )
        }
        {
          userInfo?.permissions?.includes('user_management') && (
            <Route path="/management" element={<UserManagement/>}/>
          )
        }
        <Route path="/tools" element={<ToolsManagement />}/>
        <Route path="/tools/create" element={<ToolsCreate />}/>
        <Route path="/tools/edit/:id" element={<ToolsCreate />}/>
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('report') && (
            <Route path="/report" element={<Report/>}/>
          ))
        }
        {
          userInfo?.agency?.trial_status !== 'equipment' && (
          userInfo?.permissions?.includes('fuel_receipt') && (
            <Route path="/receipt-form" element={<FuelReceiptForm/>}/>
          ))
        }
        {
          userInfo?.is_global &&
          <>
            <Route path="/fbo/all" element={<Agancy/>}/>
            <Route path="/fbo/invited" element={<InvitedFbo/>}/>
            <Route path="/fbo/trial" element={<AgencyTrial/>}/>
            <Route path="/fbo/inactive" element={<AgencyInactive/>}/>
            {/*<Route path="/fbo/corporate" element={<CorporateFBO />}/>*/}
          </>
        }
        {
          (userInfo?.is_global || userInfo?.is_corporate) &&
          <Route path="/fbo/:id" element={<AgencyDetail/>}/>
        }
        {
          userInfo?.is_global &&
          <>
            <Route path="/orders" element={<OrdersPage/>}/>
            <Route path="/orders/:id" element={<OrderDetailsPage/>}/>
          </>
        }
        {
          userInfo?.is_global &&
          <>
            <Route path="/corporate/active" element={<CorporateAccount type={"active"} />}/>
            <Route path="/corporate/inactive" element={<CorporateAccount type={"inactive"} />}/>
            <Route path="/corporate/:id" element={<CorporateDetails />}/>
          </>
        }
        {
          userInfo?.is_corporate &&
          <>
            <Route path="/corporate-dashboard" element={<CorporateDashboard />}/>
            <Route path="/fbo-management" element={<FBOManagement />}/>
          </>
        }

        {/*{*/}
        {/*  userInfo?.is_global &&*/}
        {/*  <Route path="/customer-requests" element={<CustomerRequests />}/>*/}
        {/*}*/}
        {
          userInfo?.is_global &&
          <Route path="/settings" element={<Settings/>}/>
        }
        {
          userInfo &&
          <Route path="*" element={<ErrorPage/>}/>
        }
        <Route path="/login" element={<Login/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/enter-code" element={<EnterCode/>}/>
        <Route path="/create-password" element={<NewPassword/>}/>
        <Route path="/sign-up" element={<Registration/>}/>
        <Route path="/registration" element={<RegistrationNew />}/>
        <Route path="/registration-corporate" element={<RegistrationCorporate />}/>
        <Route path="/confirm-registration" element={<ConfirmRegistration/>}/>
        <Route path="/installation" element={<Installation />} />
        {/*{*/}
        {/*  token &&*/}
        {/*  <Route path="/subscription" element={<Subscription/>}/>*/}
        {/*}*/}
        {/*<Route path="/payment" element={<Payment/>}/>*/}
        {/*<Route path="/payment-result" element={<SuccessPayment/>}/>*/}
        <Route path="/proximity" element={<Proximity/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/corporate-settings" element={<CorporateSettings />}/>
        {
          userInfo?.role?.name === "admin" && <Route path="/fbo-settings" element={<FBOSettings/>}/>
        }
        <Route path="/profile-plan" element={<ProfilePlan/>}/>
        <Route path="/warning" element={<Expired/>}/>
        <Route path="/check-data" element={<CheckData />}/>
        <Route path="/terms" element={<Terms />}/>

        {/*<Route path={"*"} element={<Navigate to="/404" replace />} />*/}

        {/*<Route path="/stations" element={<Stations />} />*/}
        {/*<Route path="/terminals" element={<Terminals />} />*/}
      </Routes>
    </>
  );
}

export default App;

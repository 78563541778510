import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import './index.scss'
import FBOManagementRow from "../../components/FBOManagementRow";
import {
  useAgencyWaterTestKitTypesQuery,
  useGetTimezonesQuery,
  useLazyGetAgencyQuery
} from "../../store/builder/inspections.api";
import Modal from "../../components/Modal";
import Cropper from "react-cropper";
import {useDebounce} from "../LogSheets/hooks";
import FBOManagementModal from "../../components/FBOManagementModal";
import FBOSettings from "../../components/FBOSettings";


const FBOManagement = () => {
  const [FBOData, setFBOData] = useState([]);
  const [createModalStatus, setCreateModalStatus] = useState(false);
  const [createFBOManagement, setCreateFBOManagement] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [cropper, setCropper] = useState();
  const [search, setSearch] = useState('')
  const debouncedSearchValue = useDebounce(search, 500);
  const [filteredAgencies, setFilteredAgencies] = useState([])
  const [settingsModal, setSettingsModal] = useState(false);

  const {data: timezonesData,
    error: timezonesError,
    isLoading: timezonesIsLoading,
    isSuccess: timezonesIsSuccess,
    isError: timezonesIsError,
  } = useGetTimezonesQuery();

  const [
    getAgencyList,
    {
      data: agencyListData,
      isLoading: agencyListIsLoading,
      isError: agencyListIsError,
      error: agencyListError,
      isSuccess: agencyListIsSuccess,
    },
  ] = useLazyGetAgencyQuery()

  const {data: waterKitData, error: waterKitError, isLoading: waterKitIsLoading, isSuccess: waterKitIsSuccess, isError: waterKitIsError} = useAgencyWaterTestKitTypesQuery()

  const getAgencies = () => {
    getAgencyList()
  }

  useEffect(() => {
    if (createFBOManagement || settingsModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [createFBOManagement, settingsModal])

  useEffect(() => {
    getAgencyList()
  }, [])

  useEffect(() => {
    if (agencyListIsSuccess) {
      setFBOData(agencyListData?.map(item => ({...item, dropdown_status: false})))
    }
  }, [agencyListIsSuccess, agencyListData])

  useEffect(() => {
    const filter = FBOData?.filter((el) => {
      if (debouncedSearchValue === "") {
        return el
      } else {
        return el?.title?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredAgencies(filter)
  }, [debouncedSearchValue, FBOData])

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const image = base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      setShowModal(false)
      document.querySelector('.modal-fbo').style.display = 'flex'
      setPhoto(cropper.getCroppedCanvas().toDataURL())
      setImageBlob(image)
    }
  };



  return (
    <Wrapper>
      <Helmet>
        <title>FBO Management | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"FBO Management"}
        type="fbo-management"
        action={() => setCreateFBOManagement(true)}
      />
      {settingsModal && <FBOSettings exit={() => setSettingsModal(false)} currentFbo={currentItem} getAgencies={getAgencyList} waterKitData={waterKitData} />}
      {
        createFBOManagement && (
          <FBOManagementModal exit={() => setCreateFBOManagement(false)} />
        )
      }
      {createModalStatus && (
        <Modal
          title={"Create FBO"}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setShowModal={setShowModal}
          setChosenPhoto={setChosenPhoto}
          photo={photo}
          setCreateModalStatus={setCreateModalStatus}
          imageBlob={imageBlob}
          setPhoto={setPhoto}
          type="agancy"
          exit={() => {
            setCreateModalStatus(false)
            setPhoto(null)
            setImageBlob(null)
            setCurrentItem(null)
          }}
          getAgencies={getAgencyList}
          timezones={timezonesData?.data?.map(item => ({value: item, label: item}))}
          isAdmin={false}
        />
      )}
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                    document.querySelector('.modal-fbo').style.display = 'flex'
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                minCropBoxWidth={300}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            // onClick={() => {
            //   setShowModal(false);
            //   setChosenPhoto(null);
            //   document.querySelector('.modal-fbo').style.display = 'flex'
            // }}
          ></div>
        </div>
      )}
      <div className="fboManagement">
        <div className="fboManagement__content">
          <div className="fboManagement__search">
            <Input
              type="text"
              placeholder="Search by name"
              name="corporateSearch"
              id="corporateSearch"
              image={"/assets/icons/search.svg"}
              maxwidth={264}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="fboManagement__table">
            <div className="fboManagement__table-header">
              <div className="fboManagement__th">FBO's ID</div>
              <div className="fboManagement__th">Name</div>
              <div className="fboManagement__th">Airport ID</div>
              <div className="fboManagement__th">Timezone</div>
              <div className="fboManagement__th">Logo</div>
              <div className="fboManagement__th">Action</div>
            </div>
            <div className="fboManagement__table-body">
              {
                filteredAgencies?.length > 0 ?
                  filteredAgencies?.map(item => (
                  <FBOManagementRow
                    key={item.id}
                    setCreateModalStatus={setCreateModalStatus}
                    setCurrentItem={setCurrentItem}
                    item={item}
                    data={FBOData}
                    setFBOData={setFBOData}
                    getAgencies={getAgencies}
                    setSettingsModal={setSettingsModal}
                  />
                ))
                  :
                  <div className="facility__table-empty">
                    <img
                      src="/assets/icons/facility-empty.svg"
                      alt="empty"
                    />
                    <p>There are no FBO!</p>
                  </div>
              }
            </div>
          </div>
          {/*<CustomPaginate*/}
          {/*  pageCount={corporateData?.pages}*/}
          {/*  handlePageClick={handlePageClick}*/}
          {/*/>*/}
        </div>
      </div>


    </Wrapper>
  );
};

export default FBOManagement;
import React, {useEffect, useState} from 'react';
import DashboardHeader from "../../components/DashboardHeader";
import {Helmet} from "react-helmet";
import Wrapper from "../../components/Wrapper";
import {
  useCreateEquipmentMutation,
  useLazyGetCurrentEquipmentQuery,
  useLazyGetEquipmentTypeQuery, useLazyGetFacilityQuery, useUpdateEquipmentMutation
} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import Input from "../../components/Input";
import FacilitySelect from "../../components/EquipmentComponents/FacilitySelect";
import TextField from "../../components/EquipmentComponents/TextField";
import RadioField from "../../components/EquipmentComponents/RadioField";
import DateField from "../../components/EquipmentComponents/DateField";
import SelectField from "../../components/EquipmentComponents/SelectField";
import FileField from "../../components/EquipmentComponents/FileField";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../global/ErrorsHandler";

const ToolsCreate = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation()?.state?.equipment ?? params?.id;
  const navigate = useNavigate();
  const [fields, setFields] = useState([])
  const [title, setTitle] = useState("");
  const [currentFacility, setCurrentFacility] = useState(null);

  const [
    getFacility,
    { data: facilityData, isFetching: facilityIsLoading, error: facilityError },
  ] = useLazyGetFacilityQuery();

  const [
    getEquipment,
    {
      data: getEquipmentData,
      error: getEquipmentError,
      isError: getEquipmentIsError,
      isLoading: getEquipmentIsLoading,
      isSuccess: getEquipmentIsSuccess,
    },
  ] = useLazyGetCurrentEquipmentQuery();

  const [getEquipmentTypes, {
    data: equipmentTypesData,
    isLoading: equipmentTypesIsLoading,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
    isSuccess: equipmentTypesIsSuccess
  }] = useLazyGetEquipmentTypeQuery();

  const [
    createEquipment,
    { isFetching: createIsLoading, error: createError, isError: createIsError, isSuccess: createIsSuccess },
  ] = useCreateEquipmentMutation();
  const [
    updateEquipment,
    { isFetching: updateIsLoading, error: updateError, isError: updateIsError, isSuccess: updateIsSuccess },
  ] = useUpdateEquipmentMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateError,
    createError,
  ]);

  useEffect(() => {
    getFacility();
    if (params?.id) {
      getEquipment(params.id);
    } else {
      getEquipmentTypes()
    }
  }, [])

  useEffect(() => {
    if (equipmentTypesIsLoading || createIsLoading || updateIsLoading) {
      dispatch(setLoader(true))
    }
    if (equipmentTypesIsSuccess || createIsSuccess || updateIsSuccess) {
      dispatch(setLoader(false))
    }
    if (equipmentTypesIsError || createIsError || updateIsError) {
      dispatch(setLoader(false))
    }
  }, [equipmentTypesIsLoading,
    equipmentTypesIsError,
    equipmentTypesIsSuccess, createIsLoading, updateIsLoading])

  useEffect(() => {
    if (params?.id) {
      if (getEquipmentData?.data?.fields?.length > 0) {
        setFields(getEquipmentData?.data?.fields?.filter(field => field.key !== "last_annual_date"))
        setTitle(getEquipmentData?.data?.title)
        setCurrentFacility({
          value: getEquipmentData?.data?.facility?.id,
          label: getEquipmentData?.data?.facility?.name,
        });
      }
    } else {
      if (equipmentTypesData?.length > 0) {
        setFields(equipmentTypesData?.filter(el => el.id === 44)?.filter(field => field.key !== "last_annual_date")[0]?.fields)
      }
    }
  }, [equipmentTypesData, getEquipmentData])

  const submitHandler = () => {
    let s = [];
    let data = {
      id: location?.id ? location?.id : location,
      title: title !== "" ? title : null,
      facility_id: currentFacility?.value ? currentFacility?.value : null,
      equipment_type_id: 44,
      fields: fields,
    };

    for (let key in data) {
      if (!data[key] && key === data.facility_id) {
        toast?.error("Please enter " + key);
        s.push(false);
      }
    }

    if (s.length === 0) {
      dispatch(setLoader(true));
      if (!location) {
        createEquipment(data)
          .then((res) => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            dispatch(setLoader(false));
            toast.success(res.data.message);
            setFields([]);
            setTimeout(() => {
              navigate(-1);
            }, 500);
          })
          .catch((err) => {});
      } else {
        updateEquipment({ ...data})?.then((res) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          dispatch(setLoader(false));
          toast.success(res.data.message);
          setTimeout(() => {
            navigate(-1);
          }, 500);
        })
          .catch((err) =>  toast.error(err?.response?.data?.message));
      }
    }
  };

  return (
    <Wrapper>
      <DashboardHeader type={"tools-create"} title={"New Tool"}/>
      <Helmet>
        <title>New Tool | NDX</title>
      </Helmet>
      <div className="equipment" style={{padding: 10}}>
        <div className="equipmentCreate">
          <Input
            placeholder="ID#"
            maxwidth={500}
            label={"Enter ID#"}
            value={title}
            onInput={(e) => {
              setTitle(() => e.target.value)
            }}
          />
          {/*{!params?.id &&  (*/}
          {/*  <FacilitySelect*/}
          {/*    currentFacility={currentFacility}*/}
          {/*    setCurrentFacility={setCurrentFacility}*/}
          {/*    facilityData={facilityData?.filter(el => el.can_edit === true)}*/}
          {/*    // setCurrentStations={setCurrentStations}*/}
          {/*    // setStationsOptions={setStationsOptions}*/}
          {/*    // setTerminalsOptions={setTerminalsOptions}*/}
          {/*  />*/}
          {/*)}*/}
          {fields &&
            fields?.filter(field => field.key !== "last_annual_date")?.map((field) => {
              if (field?.type === "text" || field?.type === "number") {
                return (
                  <TextField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    type={field?.type}
                    fields={fields}
                    value={field?.value ? field?.value : ""}
                  />
                );
              }
              if (field?.type === "radio") {
                return (
                  <RadioField
                    id={field?.key}
                    key={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    options={field?.options}
                    field={field}
                  />
                );
              }
              if (field?.type === "date") {
                return (
                  <DateField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    field={field}
                    tooltip={field?.tooltip}
                  />
                );
              }
              if (field?.type === "select") {
                return (
                  <SelectField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    field={field}
                    options={field.options}
                  />
                );
              }
              if (field?.type === "file") {
                return (
                  <FileField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    equipmentId={params.id}
                    field={field}
                  />
                );
              }
              if (field?.type === "files") {
                return (
                  <FileField
                    key={field?.key}
                    id={field?.key}
                    label={field?.label}
                    setFields={setFields}
                    equipmentId={params.id}
                    field={field}
                    files={true}
                    fields={fields}
                  />
                );
              }
            })}
          <div className="equipment__container">
            <button className="equipment__button" onClick={submitHandler}>
              {location ? "Save" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ToolsCreate;
import React from 'react';

const Checked = ({colorBg = '#AEB3B7', colorCheck = 'white'}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill={colorBg}/>
      <path d="M6.3457 10.1922L8.6534 12.1152L13.6534 7.11523" stroke={colorCheck} strokeWidth="1.53846" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Checked;

import React from 'react';
import Select from 'react-select'
import './index.scss'
import Tooltip from "../Tooltip";

export default function CustomSelect ({value = '', options, label, name = '', onChange, isMulti = false, defaultValue, tooltip = false, tooltipText, maxwidth, full, tooltipWidth = 0, id, disabled = false}) {

  const onDataChange=(value, action) => {
    onChange({
      target: {
        name: action.name,
        value: value.value,
        id: action.name,
      }
    })
  }
  return (
    <div className={`customSelect ${full ? "full" : ''}`}>
      <span className="customSelect__label">
        {label}
        {tooltip && <Tooltip tooltipText={tooltipText}  tooltipWidth={tooltipWidth}/>}
      </span>
      <Select options={options} styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
          borderRadius: 8,
          height: 42,
          backgroundColor: '#F4F5F6',
          marginTop: 8,
          width: maxwidth
        }),
      }} id={id}
      isMulti={isMulti}
              // value={value ? value : defaultValue}
              defaultValue={defaultValue ? defaultValue : 'Select...'}
      name={name} onChange={onDataChange}
              isDisabled={disabled}
      />
    </div>
  );
};


import React, {useEffect, useState} from 'react';
import Select from "react-select";
import CustomPieChart from "../CustomPieChart";
import log from "../../../SVG/Log";
const colors = [
  "#548CC5",
  "#DF9800",
  "#2AA58D",
  "#E9EAEB",
  "#EC7049",
  "#592AA5"
]
const VerticalPieChart = ({width, data, title, customColors, level, height = '', surface = false, totalCount = '', legendHeight = ''}) => {

  const [pieData, setPieData] = useState([])
  const [total, setTotal] = useState(0)
  const [chartColors, setChartColors] = useState(customColors ? customColors : colors)

  useEffect(() => {
    if (data) {
      let count = 0;
      data?.map(item => {
        count += item.value;
      })
      if (totalCount) {
        setTotal(totalCount);
      } else {
        setTotal(count);
      }
      let newPieData = [...data]
      for (let i = 0; i <= newPieData.length; i++) {
        for (let j = 0; j <= chartColors.length; j++) {
          if (i === j) {
            newPieData[i] = {...newPieData[i], color: chartColors[j]}
          }
        }
      }
      setPieData(newPieData)
    }
  }, [data]);

  return (
    <div className={`chart ${width} ${height} ${surface ? 'surface-105' : ''}`}>
      <div className="chart__header">
        <p className="chart__header-title">{title}</p>
      </div>
      <div className="chart__box vertical">
        <CustomPieChart level={level} legendHeight={legendHeight} data={pieData} total={total} persentage={true} legend={true} />
      </div>
    </div>

  );
};

export default VerticalPieChart;
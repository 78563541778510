import React from 'react';
import './index.scss'

export default function Checkbox({onChange, label, checked = false, id, key, disabled = false}) {
  return (
  <label className="checkbox-container">{label}
    <input type="checkbox" key={key} checked={checked ? checked : false} onChange={onChange} id={id} name={id} disabled={disabled} />
      <span className="checkmark"></span>
  </label>
  );
};


import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";

const ToolsRow = ({tool, setCurrentTool, currentTab, currentTool, setConfirmModal, setCurrentId, activate, getToolsDecom}) => {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".tools__table-action") === null && e.target.closest(".tools__table-dropdown") === null) {
        setDropdownStatus(false);
      }
    });
  }, [location]);

  return (
    <div
      key={tool?.id}
      className={`tools__table-row ${currentTab == 1 || currentTab == 4 ? 'tool' : currentTab == 2 || currentTab == 3 ? 'calibration' : ''}`}
      onClick={() => {
        if (currentTab == 2 || currentTab == 3) {
          navigate(`/inspections/${tool.inspection_id}`)
        }
      }}
    >
      {
        currentTab == 1 || currentTab == 4 ?
          <>
            <div className="tools__table-bodyTh">
              <p className="tools__table-subtitle">
                {tool?.title}
              </p>
            </div>
            <div className="tools__table-bodyTh">
              <p className="tools__table-subtitle">
                {tool?.fields?.filter(el => el.key === "tool_description")[0]?.value}
              </p>
            </div>
            <div className="tools__table-bodyTh">
              <p className="tools__table-subtitle">
                {tool?.fields?.filter(el => el.key === "tool_calibration_period")[0]?.value + ' months'}
              </p>
            </div>
            <div className="tools__table-bodyTh action">
              <div
                onClick={() => {
                  navigate(`/tools/edit/${tool?.id}`);
                }}
                className="equipment__table-btn s"
              >
                <img src="/assets/icons/edit-3.svg" alt="icon"/>
              </div>
              {
                tool?.is_active ?
                  <div
                    className="equipment__table-btn decom"
                    onClick={() => {
                      setCurrentId(tool?.id);
                      setConfirmModal(true)
                    }}
                  >
                    Decommission
                  </div>
                  :
                  <div
                    className="equipment__table-btn decom green"
                    onClick={() => {
                      activate(tool?.id)
                        .then((res) => {
                          getToolsDecom();
                        });
                    }}
                  >
                    Activate
                  </div>
              }
            </div>
          </>
          : currentTab == 2 ?
            <>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {tool?.equipment_title}
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {tool?.equipment_fields?.filter(el => el.key === "tool_description")[0]?.value}
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {tool?.equipment_fields?.filter(el => el.key === "tool_calibration_period")[0]?.value + " months"}
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {/*{tool?.submitted_user?.length > 0 ? tool?.submitted_user[0]?.name : '---'}*/}
                  {
                    tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")?.length > 0 ?
                      tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")[0]?.answers[0]?.length > 0 ?
                        tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")[0]?.answers[0]
                        : "---"
                      : "---"
                  }
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {moment(tool?.calibration_due_date)?.format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle">
                  {tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')?.length > 0 ?
                    tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')[0]?.answers[0]?.length > 0 ?
                      tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')[0]?.answers[0]
                      : '---'
                    : '---'}
                </p>
              </div>
              <div className="tools__table-bodyTh">
                <p className="tools__table-subtitle status">
                  <span className={tool?.calibration_status}>
                    {
                      tool?.calibration_status === 'fail'? 'Failed' :
                        tool?.calibration_status === 'success'? 'Success' :
                            tool?.calibration_status === 'pending'? 'Pending' :
                              '---'
                    }
                  </span>
                </p>
              </div>
              {/*<div className="tools__table-bodyTh">*/}
              {/*  {*/}
              {/*    tool?.categories[0]?.fields?.filter(el => el.type === 'photo')?.length > 0 ?*/}
              {/*      tool?.categories[0]?.fields?.filter(el => el.type === 'photo')[0]?.photo?.url ?*/}
              {/*        <img className="tools__table-image" src={tool?.categories[0]?.fields?.filter(el => el.type === 'photo')[0]?.photo?.url} alt="test"/>*/}
              {/*        : "N/A" : 'N/A'*/}
              {/*  }*/}
              {/*</div>*/}
            </>
            : currentTab == 3 ?
              <>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {tool?.equipment_title}
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {tool?.equipment_fields?.filter(el => el.key === "tool_description")[0]?.value}
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {tool?.equipment_fields?.filter(el => el.key === "tool_calibration_period")[0]?.value + " months"}
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {
                      tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")?.length > 0 ?
                        tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")[0]?.answers[0]?.length > 0 ?
                          tool?.categories[0]?.fields?.filter(el => el.label === "Calibrated/Verified By")[0]?.answers[0]
                          : "---"
                        : "---"
                    }
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {moment(tool?.calibration_due_date)?.format("MM/DD/YYYY")}
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle">
                    {tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')?.length > 0 ?
                      tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')[0]?.answers[0]?.length > 0 ?
                        tool?.categories[0]?.fields?.filter(el => el.label === 'Comment')[0]?.answers[0]
                        : '---'
                      : '---'}
                  </p>
                </div>
                <div className="tools__table-bodyTh">
                  <p className="tools__table-subtitle status">
                  <span className={tool?.calibration_status}>
                    {
                      tool?.calibration_status === 'fail'? 'Failed' :
                        tool?.calibration_status === 'success'? 'Success' :
                          tool?.calibration_status === 'pending'? 'Pending' :
                            '---'
                    }
                  </span>
                  </p>
                </div>
                {/*<div className="tools__table-bodyTh">*/}
                {/*  {*/}
                {/*    tool?.categories[0]?.fields?.filter(el => el.type === 'photo')?.length > 0 ?*/}
                {/*      tool?.categories[0]?.fields?.filter(el => el.type === 'photo')[0]?.photo?.url ?*/}
                {/*        <img className="tools__table-image" src={tool?.categories[0]?.fields?.filter(el => el.type === 'photo')[0]?.photo?.url} alt="test"/>*/}
                {/*        : "N/A" : 'N/A'*/}
                {/*  }*/}
                {/*</div>*/}
              </>
              : null
      }
    </div>
  );
};

export default ToolsRow;
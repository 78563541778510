import React, {useState} from 'react';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";

const EquipmentManagementRow = ({equipment, index, unAssign,
                                  getEquipment,
                                  setDeleteModal,
                                  setCurrentId,
                                  setConfirmModal,
                                  pageTab,
                                  activate,
                                  filters,
                                  debouncedSearchValue}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const navigate = useNavigate();
  const [unAssignStatus, setUnAssignStatus] = useState(false);

  const renderUnAssignModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Unassign</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUnAssignStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to unassign the NFC Tag?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setUnAssignStatus(false)
            }}>
              Cancel
            </button>
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              unAssign(equipment.id)
                .unwrap()
                .then((res) => {
                  dispatch(setLoader(false))
                  toast.success(res?.message ? res?.message : res?.data?.message)
                  setUnAssignStatus(false)
                  getEquipment({
                    active: (pageTab === 0 || pageTab === '0') ? `true` : pageTab === "" ? "true" : "false", page: 1,
                    equipment_type_id: filters.equipment_type_id ? filters.equipment_type_id : null,
                    facility_id: filters.facility_id ? filters.facility_id : null,
                    search: debouncedSearchValue ? debouncedSearchValue : null,
                  });
                })
                .catch(err => {
                  dispatch(setLoader(false))
                })
            }}>
              Unassign
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <>
      {unAssignStatus && renderUnAssignModal()}
    <div key={equipment?.id} className="equipment__table-row">
      <div className="equipment__table-bodyTh">
        <p className="equipment__table-subtitle">
          {equipment?.type?.title}
        </p>
      </div>
      <div className="equipment__table-bodyTh">
        <p className="equipment__table-subtitle">
          {equipment?.title}
        </p>
      </div>
      <div className="equipment__table-bodyTh">
        <p className="equipment__table-subtitle">
          {equipment?.fuel_type?.name ? equipment?.fuel_type?.name : "---"}
        </p>
      </div>
      <div className="equipment__table-bodyTh">
        <p className="equipment__table-subtitle">
          {equipment?.facility?.name}
        </p>
      </div>

      <div className="equipment__table-bodyTh s">
        <p className="equipment__table-title">NFC</p>
        {equipment?.beacon_address ? (
          <p className="equipment__table-subtitle">
            <img
              src="/assets/icons/nfc-active.png"
              alt="icon"
            />
            <img
              src="/assets/icons/beacon-address.svg"
              onClick={() => setUnAssignStatus(true)}
              alt="icon"
            />
          </p>
        ) : (
          <p className="equipment__table-subtitle">
            <img src="/assets/icons/nfc-inactive.png" alt="icon"/>
          </p>
        )}
      </div>

      <div className="equipment__table-bodyTh">
        <div
          onClick={() => {
            navigate(`/equipment/edit/${equipment?.id}`, {
              state: {equipment},
            });
          }}
          className="equipment__table-btn s"
        >
          <img src="/assets/icons/edit-3.svg" alt="icon"/>
        </div>
        {
          !equipment?.is_active && userInfo?.is_global ?
            <div
              onClick={() => {
                setDeleteModal(true)
                setCurrentId(equipment?.id)
              }}
              className="equipment__table-btn"
            >
              <img src="/assets/icons/delete.svg" alt="icon"/>
            </div> : null
        }

        {equipment?.is_active ? (
          <div
            className="equipment__table-btn decom"
            onClick={() => {
              setCurrentId(equipment?.id);
              setConfirmModal(true)
            }}
          >
            Decommission
          </div>
        ) : (
          <div
            className="equipment__table-btn decom green"
            onClick={() => {
              activate(equipment?.id).then((res) => {
                getEquipment({active: `false`, page: 1});
                navigate(`/equipment?active=1`);
              });
            }}
          >
            Activate
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default EquipmentManagementRow;
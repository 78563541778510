import React, {useEffect, useState} from "react";
import Wrapper from "../../components/Wrapper";
import Cropper from "react-cropper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {
  useLazyGetPaymentInfoQuery,
  useSaveProfilePhotoMutation, useUpdateUserProfileMutation,
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";
import {useLocation, useNavigate} from "react-router-dom";
import Profile5 from "../../SVG/Profile5";
import ProfileHistory from "../../components/Profile/ProfileHistory";
import UpdatePaymentModal from "../../components/Profile/UpdatePaymentModal";
import ExtraPhonesModal from "../../components/Profile/ExtraPhonesModal";
import BillingAddress from "../../components/Profile/BillingAddress";
import BillingAddressModal from "../../components/Profile/BillingAddressModal";
import OrderDetailsHistory from "../../components/Profile/OrderDetails";
import FboProfile from "../../components/Profile/FboProfile";
import ProfileFacilityIcon from "../../SVG/ProfileFacilityIcon";
import ProfileFacility from "../../components/ProfileFacility";
import Profile3 from "../../SVG/Profile3";
import Profile4 from "../../SVG/Profile4";
import Profile6 from "../../SVG/Profile6";
import History from "../../SVG/History";
import NotAvailable from "../../components/NotAvailable";
import SubscriptionPlan from "../../components/Profile/SubscriptionPlan";
import CorrectiveActions from "../../components/Profile/CorrectiveActions";
import CorrectiveActionsIcon from "../../SVG/CorrectiveActions";
import {useErrorHandler} from "../../global/ErrorsHandler";

export default function FBOSettings() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(7);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [cropper, setCropper] = useState();
  const [extraShow, setExtraShow] = useState(false);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const token = localStorage.getItem("ndx_user_token");
  const navigate = useNavigate();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [userProfile, setUserProfile] = useState({
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    password: null,
    password_confirmation: null,
    notify_corrective_actions: userInfo?.notify_corrective_actions,
    notify_inspections: userInfo?.notify_inspections,
    notify_equipment: userInfo?.notify_equipment,
    notify_service_requests: userInfo?.notify_service_requests,
  })

  const [save, {data, isLoading, isError, error, isSuccess}] =
    useSaveProfilePhotoMutation();

  // const [
  //   getPaymentInfo,
  //   {
  //     data: paymentInfoData,
  //     isLoading: paymentInfoIsLoading,
  //     isError: paymentInfoIsError,
  //     error: paymentInfoError,
  //     isSuccess: paymentInfoIsSuccess,
  //   },
  // ] = useLazyGetPaymentInfoQuery();

  const [updateUser,
    {
      data: updateUserData,
      isLoading: updateUserIsLoading,
      isError: updateUserIsError,
      error: updateUserError,
      isSuccess: updateUserIsSuccess,
    },] = useUpdateUserProfileMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateUserError,
    error
  ]);

  useEffect(() => {
    if (location?.search?.split('=')[1] == 0) {
      setCurrentTab(0)
    }
  }, [location])

  useEffect(() => {
    if (userInfo?.agency?.is_settings_complete === false) {
      changeTab(7)
    }
  }, [userInfo])

  useEffect(() => {
    if (isLoading || updateUserIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (isSuccess) {
      toast.success(data.message);
    }
    if (updateUserIsSuccess) {
      toast.success(updateUserData.message);
    }
  }, [isLoading, isSuccess, isError, updateUserIsLoading, updateUserIsSuccess]);

  useEffect(() => {
    if (typeof chosenPhoto === "string") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [chosenPhoto]);

  useEffect(() => {
    setUserProfile(old =>
      ({
        ...old,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        notify_corrective_actions: userInfo?.notify_corrective_actions,
        notify_inspections: userInfo?.notify_inspections,
        notify_equipment: userInfo?.notify_equipment,
        notify_service_requests: userInfo?.notify_service_requests
      }))
  }, [userInfo, currentTab])

  function changeTab (tabId) {
    setCurrentTab(tabId);
  }

  const goToAddMethods = () => {
    setCurrentTab(1);
  }

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const form = new FormData();
      form.append(
        "profile_photo",
        base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      );
      save(form)
        .then(() => {
          setShowModal(false);
          let config = {
            headers: {
              Authorization: "Bearer " + token,
              AppVersion: '1.0.0',
              AppType: 'web',
              DeviceType: 'web'
            },
          };
          axios
            .get(`${process.env.REACT_APP_API_URL}user`, config)
            .then((res) => {
              dispatch(setUser(res.data));
            })
            .catch((err) => {
              dispatch(setLoader(false));
              dispatch(setUser(null));
              navigate("/login");
              toast.error(err.response.data.message);
            });
        })
        .catch((err) => {
          toast.error("Something went wrong, try another photo");
        });
    }
  };

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Profile | NDX</title>
      </Helmet>
      <DashboardHeader title={"Settings"}/>

      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            // onClick={() => {
            //   setShowModal(false);
            //   setChosenPhoto(null);
            // }}
          ></div>
        </div>
      )}
      {/*{showPaymentModal && (*/}
      {/*  <UpdatePaymentModal*/}
      {/*    setShowPaymentModal={setShowPaymentModal}*/}
      {/*    getUserIndex={getUserIndex}*/}
      {/*    getPaymentInfo={getPaymentInfo}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showBillingModal && (*/}
      {/*  <BillingAddressModal*/}
      {/*    setShowPaymentModal={setShowBillingModal}*/}
      {/*    getUserIndex={getUserIndex}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{*/}
      {/*  showShippingModal && (*/}
      {/*  <BillingAddressModal*/}
      {/*    setShowPaymentModal={setShowShippingModal}*/}
      {/*    getUserIndex={getUserIndex}*/}
      {/*  />*/}
      {/*  )}*/}
      {showDeleteModal && (
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Delete card</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setShowDeleteModal(false)}
              />
            </div>
            <div
              className="modal__content-body"
              style={{display: "flex", justifyContent: "center"}}
            >
              Are you sure want to remove the card?
            </div>
            <div className="modal__content-bottom">
              <button
                className="close"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className="save danger">Delete</button>
            </div>
          </div>
          <div
            className="modal__bg"
          ></div>
        </div>
      )}
      {extraShow && (
        <ExtraPhonesModal
          setShowExtra={setExtraShow}
          setShowPaymentModal={setShowPaymentModal}
          setShowBillingModal={setShowBillingModal}
          setShowShippingModal={setShowShippingModal}
        />
      )}
      <div className="profile">
        <div className="profile__tabs">
          {userInfo?.role?.name === "admin" && (
            <div
              className={`profile__tab ${currentTab === 7 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(7);
              }}
            >
              <Profile5 active={currentTab === 7 && true}/>
              <p className="profile__tab-name">FBO Profile</p>
            </div>
          )}
          {(userInfo?.role?.name === "admin" && userInfo?.agency?.is_settings_complete) && (
            <div
              className={`profile__tab ${currentTab === 6 ? "active" : ""}`}
              onClick={() => {
                setCurrentOrder(null);
                changeTab(6);
              }}
            >
              <CorrectiveActionsIcon active={currentTab === 6 && true}/>
              <p className="profile__tab-name">Corrective Action Settings</p>
            </div>
          )}
          {
            (userInfo?.agency?.is_settings_complete && userInfo?.agency?.trial_status !== 'complete' && userInfo?.agency?.trial_status !== null) ?
              userInfo?.role?.name === "admin" && (
              <div
                className={`profile__tab ${currentTab === 8 ? "active" : ""}`}
                onClick={() => {
                  setCurrentOrder(null);
                  changeTab(8);
                }}
              >
                <ProfileFacilityIcon active={currentTab === 8 && true}/>
                <p className="profile__tab-name">Facility Settings</p>
              </div>)
              : null
          }
          {/*{(userInfo?.role?.name === "admin" && userInfo?.agency?.is_settings_complete) && (*/}
          {/*  <div*/}
          {/*    className={`profile__tab ${currentTab === 0 ? "active" : ""}`}*/}
          {/*    onClick={() => {*/}
          {/*      setCurrentOrder(null);*/}
          {/*      changeTab(0);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {*/}
          {/*      ((userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'equipment')*/}
          {/*      && (userInfo?.subscription === null))*/}
          {/*      && <div style={{top: '9px', left: '9px'}} className="red-dot"></div>*/}
          {/*    }*/}
          {/*    <Profile3 active={currentTab === 0 && true}/>*/}
          {/*    <p className="profile__tab-name">NDX Plan</p>*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{((userInfo?.role?.name === "admin" || userInfo?.role?.name === "management") && userInfo?.agency?.is_settings_complete) ? (*/}
          {/*  <div*/}
          {/*    className={`profile__tab ${currentTab === 1 ? "active" : ""}`}*/}
          {/*    onClick={() => {*/}
          {/*      setCurrentOrder(null);*/}
          {/*      changeTab(1);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {*/}
          {/*      (userInfo?.payment_data?.length === 0) && <div style={{top: '9px', left: '9px'}} className="red-dot"></div>*/}
          {/*    }*/}
          {/*    <Profile4 active={currentTab === 1 && true}/>*/}
          {/*    <p className="profile__tab-name">Payment Methods</p>*/}
          {/*  </div>*/}
          {/*) : null}*/}

          {/*{((userInfo?.role?.name === "admin" || userInfo?.role?.name === "management") && userInfo?.agency?.is_settings_complete) ? (*/}
          {/*  <div*/}
          {/*    className={`profile__tab ${currentTab === 2 ? "active" : ""}`}*/}
          {/*    onClick={() => {*/}
          {/*      setCurrentOrder(null);*/}
          {/*      changeTab(2);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {*/}
          {/*      (Object.values(userInfo?.address).every(item => item === null))*/}
          {/*      && <div style={{top: '9px', left: '9px'}} className="red-dot"></div>*/}
          {/*    }*/}
          {/*    <Profile6 active={currentTab === 2 && true}/>*/}
          {/*    <p className="profile__tab-name">Address</p>*/}
          {/*  </div>*/}
          {/*) : null}*/}

          {/*{(userInfo?.role?.name === "admin" && userInfo?.agency?.is_settings_complete) && (*/}
          {/*  <div*/}
          {/*    className={`profile__tab ${currentTab === 3 ? "active" : ""}`}*/}
          {/*    onClick={() => {*/}
          {/*      setCurrentOrder(null);*/}
          {/*      changeTab(3);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <History active={currentTab === 3 && true}/>*/}
          {/*    <p className="profile__tab-name">History</p>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>

        <div className="profile__box">
          {currentTab === 7 ? (
            <FboProfile/>
          ) : currentTab === 8 ? (
            <ProfileFacility />
          ) : currentTab === 6 ? (
            <CorrectiveActions />
          ) : currentTab === 0 ? (
            (((userInfo?.agency?.trial_status === 'equipment'
                || userInfo?.agency?.trial_status === 'inspection') && !userInfo?.agency?.is_prices_set)
            ) ? <NotAvailable />
              : <SubscriptionPlan setExtraShow={setExtraShow} setCurrentTab={setCurrentTab} />
          ) : currentTab === 1 ? (
            <div className="profile__methods">
              <div className="profile__methods-head">
                <p className="profile__methods-title">Payment methods</p>
                <button
                  className="profile__methods-btn"
                  onClick={() => setShowPaymentModal(true)}
                >
                  Update
                </button>
              </div>
            </div>
          ) : currentTab === 3 ? (
            userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection' ?
              <NotAvailable /> :
              !currentOrder ? (
                <ProfileHistory setCurrentOrder={setCurrentOrder}/>
              ) : (
                <OrderDetailsHistory
                  currentOrder={currentOrder}
                  setCurrentOrder={setCurrentOrder}
                />
              )
          ) : currentTab === 2 ? (
            <BillingAddress/>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}

import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Wrapper from "../../components/Wrapper";
import {useParams} from "react-router-dom";
import {useGetUsersRolesQuery, useLazyCorporateDetailsQuery} from "../../store/builder/inspections.api";
import CorporateDetailsRow from "../../components/Corporate/CorporateDetailsRow";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";

const CorporateDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [currentUser, setCurrentUser] = useState(null)

  const [getCorporate,
    {
      data: corporateData,
      error: corporateError,
      isLoading: corporateIsLoading,
      isSuccess: corporateIsSuccess,
      isError: corporateIsError,
    }] = useLazyCorporateDetailsQuery();

  const {
    data: userRolesData,
    isSuccess: userRolesIsSuccess,
    isLoading: userRolesIsLoading,
    isError: userRolesIsError,
    error: userRolesError,
  } = useGetUsersRolesQuery();

  const [currentTab, setCurrentTab] = useState(1)

  useEffect(() => {
    if (params?.id) {
      getCorporate(params?.id)
    }
  }, [params?.id])

  useEffect(() => {
    if (corporateIsLoading) {
      dispatch(setLoader(true))
    }
    if (corporateIsSuccess) {
      dispatch(setLoader(false))
    }
    if (corporateIsError) {
      dispatch(setLoader(false))
    }
  }, [corporateIsLoading, corporateIsSuccess, corporateIsError])

  const changeTab = (tabId) => setCurrentTab(tabId)

  return (
    <Wrapper>
      <Helmet>
        <title>FBO Management | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"Corporate Details"}
        type="corporate-details"
        // action={() => setCreateFBOManagement(true)}
      />
      <div className="agencyDetails">
        <div className="agencyDetails__info">
          <div className="agencyDetails__info-left">
            <div className="agencyDetails__info-main">
              <img className="agencyDetails__info-logo" src={corporateData?.details?.logo} alt="logo"/>
              <div className="agencyDetails__info-text">
                <p>Corporate name: </p>
                <p>{corporateData?.details?.title}</p>
              </div>
            </div>
            <p className="agencyDetails__info-info">
              <span>Corporate ID: </span>{corporateData?.details?.id}</p>
            <p className="agencyDetails__info-info">
              <span>Timezone: </span>{corporateData?.details?.timezone}</p>
          </div>
        </div>
        <div className="agencyDetails__tabs">
          <div className={`agencyDetails__tab ${currentTab === 1 ? 'active' : ""}`} onClick={() => changeTab(1)}>
            User Management
          </div>
          <div className={`agencyDetails__tab ${currentTab === 2 ? 'active' : ""}`}
               onClick={() => changeTab(2)}>Subscriptions
          </div>
          <div className={`agencyDetails__tab ${currentTab === 3 ? 'active' : ""}`}
               onClick={() => changeTab(3)}>FBO
          </div>
        </div>
        {
          currentTab === 1 ?
            <div className="agencyDetails__table">
              <div className="management__table-head corporate">
                <div className="management__table-th">ID</div>
                <div className="management__table-th">First name</div>
                <div className="management__table-th">Last name</div>
                <div className="management__table-th">Email</div>
                <div className="management__table-th">Ein</div>
                <div className="management__table-th">Initials</div>
                <div className="management__table-th">Action</div>
              </div>
              <div className="agencyDetails__table-body">
                {corporateData?.corporate_users?.map((user, index) => (
                  <CorporateDetailsRow
                    user={user}
                    lengthArray={corporateData?.corporate_users?.length}
                    indexElem={index}
                    key={user?.id}
                    setCurrentUser={setCurrentUser}
                    currentUser={currentUser}
                    userRolesData={userRolesData}
                    type={'users'}
                  />
                ))}
              </div>
            </div>
            : currentTab === 2 ?
              <div className="agencyDetails__table">
                <div className="management__table-head subsc">
                  <div className="management__table-th">Start date</div>
                  <div className="management__table-th">End date</div>
                  <div className="management__table-th">Subscription cost</div>
                  <div className="management__table-th">Invoice/Receipt from Stripe</div>
                </div>
                <div className="agencyDetails__table-body">
                  {corporateData?.subscriptions?.map((user, index) => (
                    <CorporateDetailsRow
                      user={user}
                      lengthArray={corporateData?.corporate_users?.length}
                      indexElem={index}
                      key={user?.id}
                      setCurrentUser={setCurrentUser}
                      currentUser={currentUser}
                      userRolesData={userRolesData}
                      type={'subscription'}
                    />
                  ))}
                </div>
              </div>
            : currentTab === 3 ?
                <div className="agencyDetails__table">
                  <div className="management__table-head subsc">
                    <div className="management__table-th">FBO ID</div>
                    <div className="management__table-th">Name</div>
                    <div className="management__table-th">Airport ID</div>
                    <div className="management__table-th">Timezone</div>
                  </div>
                  <div className="agencyDetails__table-body">
                    {corporateData?.corporate_agencies?.map((user, index) => (
                      <CorporateDetailsRow
                        user={user}
                        lengthArray={corporateData?.corporate_users?.length}
                        indexElem={index}
                        key={user?.id}
                        setCurrentUser={setCurrentUser}
                        currentUser={currentUser}
                        userRolesData={userRolesData}
                        type={'fbo'}
                      />
                    ))}
                  </div>
                </div>
            : null
        }

      </div>

      </Wrapper>
  );
};

export default CorporateDetails;
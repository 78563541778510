import React, {useEffect, useState} from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import {useNavigate, useParams} from "react-router-dom";
import "./index.scss";
import Input from "../../components/Input";
import Plus from "../../SVG/Plus";
import EquipmentTypeModal from "../../components/EquipmentTypeModal";
import {
  useGetAgencyQuery,
  useLazyGetCurrentFacilityInspectionsQuery,
  useLazyGetCurrentInspectionQuery,
  useLazyGetInspectionsByTypeQuery,
  useRefuseInspectionsMutation,
  useReorderCategoryMutation,
  useUpdateEquipmentTypeMutation,
  useUpdateFacilityInfoMutation,
} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import log from "../../SVG/Log";
import {useErrorHandler} from "../../global/ErrorsHandler";
import CustomSelect from "../../components/CustomSelect";
import {Helmet} from "react-helmet";
import Checkbox from "../../components/Checkbox";
import Datepicker from "../../components/Datepicker";
import moment from "moment/moment";

export default function EquipmentTypeEdit({editType}) {
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facilityEditData, setFacilityEditData] = useState({
    id: null,
    name: null,
    agency_id: null,
    inspections_enabled : false,
    default_facility_inspections_enabled: false,
    last_monthly_date: null,
  });

  const [
    getCurrentInspection,
    {
      data: inspectionCurrentData,
      isError: inspectionCurrentIsError,
      error: inspectionCurrentError,
      isSuccess: inspectionCurrentIsSuccess,
      isLoading: inspectionCurrentIsLoading,
    },
  ] = useLazyGetCurrentInspectionQuery();

  const [
    getCurrentFacilityInspection,
    {
      data: facilityInspectionCurrentData,
      isError: facilityInspectionCurrentIsError,
      error: facilityInspectionCurrentError,
      isSuccess: facilityInspectionCurrentIsSuccess,
      isLoading: facilityInspectionCurrentIsLoading,
    },
  ] = useLazyGetCurrentFacilityInspectionsQuery();

  const [
    refuseInspections,
    {
      data: refuseInspectionsData,
      isError: refuseInspectionsIsError,
      error: refuseInspectionsError,
      isSuccess: refuseInspectionsIsSuccess,
      isLoading: refuseInspectionsIsLoading,
    },
  ] = useRefuseInspectionsMutation();

  const [modalStatus, setModalStatus] = useState(false);
  const [addedInspections, setAddedInspections] = useState([]);
  const [pickedInspection, setPickedInspection] = useState(null);

  const [
    getInspection,
    {
      data: inspectionData,
      isError: inspectionIsError,
      error: inspectionError,
      isSuccess: inspectionIsSuccess,
      isLoading: inspectionIsLoading,
    },
  ] = useLazyGetInspectionsByTypeQuery();

  const [
    update,
    {
      data: updateData,
      isError: updateIsError,
      error: updateError,
      isSuccess: updateIsSuccess,
      isLoading: updateIsLoading,
    },
  ] = useUpdateEquipmentTypeMutation();

  const [
    updateFacilityInfo,
    {
      data: updateFacilityInfoData,
      isError: updateFacilityInfoIsError,
      error: updateFacilityInfoError,
      isSuccess: updateFacilityInfoIsSuccess,
      isLoading: updateFacilityInfoIsLoading,
    },
  ] = useUpdateFacilityInfoMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateFacilityInfoError,
    inspectionCurrentError,
    updateError,
    inspectionError,
    refuseInspectionsError,
    facilityInspectionCurrentError,
  ]);

  useEffect(() => {
    getInspection(editType === "facility" ? "facility" : "equipment_type");
    if (editType === "facility") {
      getCurrentFacilityInspection(params.id);
    } else {
      getCurrentInspection(params.id);
    }
  }, [
    facilityInspectionCurrentIsSuccess,
  ]);

  useEffect(() => {
    if (updateFacilityInfoIsSuccess) {
      toast.success(updateFacilityInfoData.message);
      setTimeout(() => {
        navigate('/facility')
      }, 500)
    }
  }, [updateFacilityInfoIsSuccess]);

  useEffect(() => {
    setFacilityEditData((old) => ({
      ...old,
      name: facilityInspectionCurrentData?.data?.name,
      id: facilityInspectionCurrentData?.data?.id,
      agency_id: facilityInspectionCurrentData?.data?.agency_id,
      inspections_enabled: facilityInspectionCurrentData?.data?.inspections_enabled,
      default_facility_inspections_enabled: facilityInspectionCurrentData?.data?.default_facility_inspections_enabled,
      last_monthly_date: facilityInspectionCurrentData?.data?.last_monthly_date,
    }));
  }, [facilityInspectionCurrentIsSuccess])

  useEffect(() => {
    if (inspectionIsLoading) {
      dispatch(setLoader(true));
    }
    if (inspectionIsSuccess) {
      dispatch(setLoader(false));
    }
    if (updateIsLoading) {
      dispatch(setLoader(true));
    }
    if (updateIsSuccess) {
      // toast.success(updateData.message);
      setModalStatus(false);
    }
    if (refuseInspectionsIsLoading) {
      dispatch(setLoader(true));
    }
    if (refuseInspectionsIsSuccess) {
      dispatch(setLoader(false));
      toast.success(refuseInspectionsData?.message)
    }
  }, [
    inspectionIsLoading,
    inspectionIsSuccess,
    updateIsSuccess,
    updateIsLoading,
    refuseInspectionsIsSuccess,
  ]);

  const removeFromAdded = (id) => {
    if (editType === "facility") {
      refuseInspections({inspection_form_id: id, facility_id: params?.id});
    } else {
      refuseInspections({
        inspection_form_id: id,
        equipment_type_id: params?.id,
      });
    }
  };

  const saveEquipment = () => {
    if (editType === "facility") {
      update({
        inspection_form_id: pickedInspection?.id,
        facility_id: params?.id,
      })
        .unwrap()
        .then(res => {
          toast.success(res.message)
        })
      setPickedInspection(null);
    } else {
      update({
        inspection_form_id: pickedInspection?.id,
        equipment_type_id: params?.id,
      })
        .unwrap()
        .then(res => {
          toast.success(res.message)
        })
      setPickedInspection(null);
    }
  };

  const saveFacilityInfo = () => {
    updateFacilityInfo(facilityEditData);
  };

  const handleFacilityData = (e) => {
    setFacilityEditData((old) => ({...old, [e.target.name]: e.target.value}));
  };
  const handleFacilityEnabled = (e) => {
    setFacilityEditData((old) => ({...old, inspections_enabled: e.target.checked}));
  };
  const handleFacilityEnabledDefault = (v) => {
    setFacilityEditData((old) => ({...old, default_facility_inspections_enabled: v}));
  };

  return (
    <Wrapper>
      <Helmet>
        <title>
          {editType === "facility" ? "Edit Facility" : "Edit Equipment Type"} |
          NDX
        </title>
      </Helmet>
      {modalStatus && (
        <EquipmentTypeModal
          exit={() => setModalStatus(false)}
          list={inspectionData.data}
          comparisonList={
            editType === "facility"
              ? facilityInspectionCurrentData?.data?.links
              : inspectionCurrentData?.data?.links
          }
          setAddedInspections={setAddedInspections}
          addedInspections={addedInspections}
          pickedInspection={pickedInspection}
          setPickedInspection={setPickedInspection}
          saveEquipment={() => saveEquipment()}
        />
      )}
      <DashboardHeader
        title={
          editType === "facility" ? "Edit Facility" : "Edit Equipment Type"
        }
        type={editType === "facility" ? "facility-edit" : "equipment-edit"}
        action={saveFacilityInfo}
      />
      <div className="editType">
        {editType === "facility" ? (
          <div style={{marginBottom: 20}} className="editType__fields">
            <Input
              containerWidth={"95%"}
              maxwidth={"100%"}
              placeholder={"Facility Name"}
              defaultValue={facilityInspectionCurrentData?.data?.name}
              onChange={handleFacilityData}
              name={"name"}
              id={"name"}
              label="Facility Name"
              readOnly={!facilityInspectionCurrentData?.data?.can_edit}
            />
            {
              userInfo?.is_global && (<label className="checkbox-container">
                Inspection Enabled
                <input
                  type="checkbox"
                  key={facilityEditData?.inspections_enabled}
                  defaultChecked={facilityEditData?.inspections_enabled}
                  id={"inspection_enabled"} name={"inspection_enabled"}
                  onChange={handleFacilityEnabled}
                />
                <span className="checkmark"></span>
              </label>)
            }

            <span style={{fontSize: '14px'}}>Do fuel facility checks apply to this facility?</span>
            <div className="subscription__plan-info_checkbox" style={{marginTop: 10, marginBottom: 16}}>
              <div className="radio-option">
                <input
                  type="radio"
                  name={'no'}
                  id={'no'}
                  value={'No'}
                  checked={!facilityEditData?.default_facility_inspections_enabled}
                  onChange={() => handleFacilityEnabledDefault(0)}
                />
                <label style={!facilityEditData?.default_facility_inspections_enabled ? {color: '#4268B0'} : {}}
                       htmlFor={'no'}>No</label>
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  name={'yes'}
                  id={'yes'}
                  checked={facilityEditData?.default_facility_inspections_enabled}
                  value={'Yes'}
                  onChange={() => handleFacilityEnabledDefault(1)}
                />
                <label style={facilityEditData?.default_facility_inspections_enabled ? {color: '#4268B0'} : {}}
                       htmlFor={'yes'}>Yes</label>
              </div>
            </div>
            {
              (userInfo?.is_global || userInfo?.is_admin) &&
              <>
                <span className="customInput">Last monthly date</span>
                <Datepicker value={facilityEditData?.last_monthly_date} setValue={(e) => setFacilityEditData((old) => ({
                  ...old,
                  last_monthly_date: moment(e).format('MM/DD/YYYY')
                }))}/>
              </>
            }

          </div>
        ) : (
          // <Input placeholder={'Equipment Type ' + params.id} value={inspectionCurrentData?.data?.title}
          //        label="Equipment Type"/>
          <div>
            <p className="editType__equipmentLabel">Equipment Type</p>
            <div className="editType__equipmentName">
              {inspectionCurrentData?.data?.title}
            </div>
          </div>
        )}
        <div>
          {
            // return {id: item?.form?.id, name: item?.form?.name, period: item?.form?.period}

            editType === "facility"
              ? facilityInspectionCurrentData?.data?.links?.map(
                (inspection) => (
                  <div className="editType__added" key={inspection?.form?.id}>
                    <p>{inspection?.form?.name}</p>
                    {
                      facilityInspectionCurrentData?.data?.can_edit && 
                      <img
                      src="/assets/icons/close.svg"
                      alt="icon"
                      onClick={() => removeFromAdded(inspection?.form?.id)}
                    />
                    }
                  </div>
                )
              )
              : inspectionCurrentData?.data?.links?.map((inspection) => (
                <div className="editType__added" key={inspection?.form?.id}>
                  <p>{inspection?.form?.name}</p>
                  <img
                    src="/assets/icons/close.svg"
                    alt="icon"
                    onClick={() => removeFromAdded(inspection?.form?.id)}
                  />
                </div>
              ))
          }
        </div>
        {
          facilityInspectionCurrentData?.data?.can_edit && 
          <div className="editType__btn" onClick={() => setModalStatus(true)}>
            <Plus color={"#4268B0"}/>
            <span>Add Inspection</span>
          </div>
        }
        
      </div>
    </Wrapper>
  );
}

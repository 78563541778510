import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import Input from "../../components/Input";
import {
  useCreateReportMutation,
  useLazyGetFacilityQuery,
  useGetReportListQuery, useRemoveReportMutation, useLazyGetEquipmentQuery, useLazyGetReportListQuery,
} from "../../store/builder/inspections.api";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import ReportModal from "../../components/ReportModal";
import moment from "moment";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import Trash from "../../SVG/Trash";
import {toast} from "react-toastify";
import Datepicker from "../../components/Datepicker";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import {useDebounce} from "../LogSheets/hooks";

export default function Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [reportModalStatus, setReportModalStatus] = useState(false);
  const [search, setSearch] = useState("");
  const [currentReport, setCurrentReport] = useState(null)
  const [dangerShow, setDangerShow] = useState(false)
  const [facilityOptions, setFacilityOptions] = useState([])
  const [reportFilters, setReportFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
  })
  const [getReportData,{
    data: reportData,
    isError: reportIsError,
    isFetching: reportIsLoading,
    isSuccess: reportIsSuccess,
    error: reportError,
  }] = useLazyGetReportListQuery();

  const [getFacility, {
    data: facilityData,
    isError: facilityIsError,
    isFetching: facilityIsLoading,
    isSuccess: facilityIsSuccess,
    error: facilityError,
  }] = useLazyGetFacilityQuery();

  const [removeReport, {
    data: removeReportData,
    isError: removeReportIsError,
    isFetching: removeReportIsLoading,
    isSuccess: removeReportIsSuccess,
    error: removeReportError,
  }] = useRemoveReportMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    facilityError,
    reportError,
  ]);
  const debouncedSearchValue = useDebounce(search, 500);

  useEffect(() => {
    if (reportModalStatus || dangerShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [reportModalStatus, dangerShow])

  useEffect(() => {
    getFacility()
  }, [])

  useEffect(() => {
    if (facilityData) {
      setFacilityOptions(facilityData?.map(item => ({value: item.id, label: item.name})))
    }
  }, [facilityData])

  useEffect(() => {
    setReportFilters(old => ({
      ...old,
      facility_id: urlParams.get('facility_id'),
      date_from: urlParams.get('date_from'),
      date_to: urlParams.get('date_to'),
    }))
  }, [])

  useEffect(() => {
    getReportData({
      facility_id: reportFilters?.facility_id,
      date_from: reportFilters?.date_from,
      date_to: reportFilters?.date_to,
      search: debouncedSearchValue,
      type: "pdf"
    })
  }, [reportFilters, debouncedSearchValue])

  useEffect(() => {
    if (removeReportIsLoading) {
      dispatch(setLoader(true));
    }
    if (removeReportIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Report successfully deleted')
      setDangerShow(false)
    }
  }, [ removeReportIsLoading, removeReportIsSuccess])

  useEffect(() => {
    if (reportIsLoading) {
      dispatch(setLoader(true));
    }
    if (reportIsSuccess) {
      dispatch(setLoader(false));
    }
  }, [reportIsLoading, reportIsSuccess]);

  useEffect(() => {
    const filteredArr = reportData?.data?.filter(el => el.file_name === "wait")
    const interval = setInterval(() => {
      if (filteredArr?.length === 0) {
        clearInterval(interval);
      } else {
        getReportData({
          facility_id: reportFilters?.facility_id,
          date_from: reportFilters?.date_from,
          date_to: reportFilters?.date_to,
          search: debouncedSearchValue,
          type: "pdf"
        });
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [reportData]);

  // const filteredData = reportData?.data?.filter((el) => {
  //   if (search === "") {
  //     return el;
  //   } else {
  //     return el?.name?.toLowerCase()?.includes(search.toLowerCase());
  //   }
  // });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleFilterDateFrom = (date) => {
    setReportFilters(old => ({...old, date_from: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/report?${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${date ? `&date_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
  }
  const handleFilterDateTo = (date) => {
    setReportFilters(old => ({...old, date_to: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/report?${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${date ? `&date_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
  }
  const renderDanger = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Delete report</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure want to remove this report?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setDangerShow(false)}>
              Cancel
            </button>
            <button className="save danger" onClick={() => {
              removeReport(currentReport)
            }}>
              Delete
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Report | NDX</title>
      </Helmet>
      {reportModalStatus && (
        <ReportModal
          title={"Create Report"}
          exit={() => setReportModalStatus(false)}
          facility={facilityData?.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          type={'report'}
        />
      )}
      {
        dangerShow && renderDanger()
      }
      <DashboardHeader
        title={"Report"}
        type={"report"}
        action={() => setReportModalStatus(true)}
      />
      <div className="report">
        <div className="report__content">
          <div className="report__search">
            <Input type="text" name={'search'} id={'search'}
                   onChange={handleSearch}
                   image="/assets/icons/search.svg"
                   placeholder="Search"
                   defaultValue={search} maxwidth={200}
            />
            <Datepicker
              type={'from'}
              setValue={handleFilterDateFrom}
              value={urlParams.get('date_from')}
            />
            <Datepicker
              type={'to'}
              setValue={handleFilterDateTo}
              value={urlParams.get('date_to')}
            />
            <Select styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                borderRadius: 8,
                width: 200,
                height: 42,
                backgroundColor: '#F4F5F6'
              }),
            }} isClearable
                    key={JSON.stringify(facilityOptions) + "1"}
                    placeholder={'Facility'} options={facilityOptions}
                    defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                    onChange={(e) => {
                      setReportFilters(old => ({...old, facility_id: e?.value}))
                      navigate(`/report?${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
                    }}
            />
          </div>
          <div className="report__table">
            <div className="report__table-header">
              <div className="report__th">Name</div>
              <div className="report__th">Timestamp</div>
              <div className="report__th">Facility</div>
              <div className="report__th">Year</div>
              <div className="report__th">Month</div>
              <div className="report__th">Completed by</div>
              <div className="report__th">Download</div>
              <div className="report__th">Delete</div>
            </div>
            <div className="report__table-body">
              {reportData?.data?.length ? reportData?.data?.map((report, index) => (
                <div className="report__row" key={report?.id}>
                  <div className="report__td">{report.name}</div>
                  <div className="report__td">
                    {moment(report?.updated_at).format("MM/DD/YY HH:mm")}
                  </div>
                  <div className="report__td">{report?.facility?.name}</div>
                  <div className="report__td">{report?.year}</div>
                  <div className="report__td">
                    {/*{moment(*/}
                    {/*  moment("2014-" + report?.month_to + "-28").format(*/}
                    {/*    "YYYY/MM/DD"*/}
                    {/*  )*/}
                    {/*).format("MMMM")}*/}
                    {report?.month_to}
                  </div>
                  <div className="report__td">{report?.created_by?.name}</div>
                  {
                    report?.file_name === "wait" ?
                      <div className="report__td small">Generating Report</div>
                      :
                      report?.file_name && report?.file_name?.length > 0 ? (
                    <a
                      href={report?.file_name}
                      target="_blank"
                      className="report__td"
                      rel="noreferrer"
                    >
                      <img src="/assets/icons/Download.svg" alt="icon" />
                    </a>
                  ) : (
                    <div className="report__td">NO DATA</div>
                  )}
                  <div className="report__td" onClick={() => {
                    setDangerShow(true)
                    setCurrentReport(report.id)
                  }}>
                    <img src="/assets/icons/trash-2.svg" alt="icon" />
                  </div>
                </div>
              )) : (
                <div className='emptyList'>
                  <img src="/assets/empty-list.png" alt="empty" />
                  <p>No report data yet</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

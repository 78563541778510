import React from 'react';

const Fuel = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 4.5C18 4.22386 17.7761 4 17.5 4H6.5C6.22386 4 6 4.22386 6 4.5V18.0279C6 18.366 6.35589 18.586 6.65836 18.4348C7.50294 18.0125 8.49706 18.0125 9.34164 18.4348L11.5528 19.5403C11.8343 19.6811 12.1657 19.6811 12.4472 19.5403L14.6584 18.4348C15.5029 18.0125 16.4971 18.0125 17.3416 18.4348C17.6441 18.586 18 18.366 18 18.0279V4.5ZM20 21.191C20 21.5627 19.6088 21.8044 19.2764 21.6382L16.4472 20.2236C16.1657 20.0828 15.8343 20.0828 15.5528 20.2236L13.3416 21.3292C12.4971 21.7515 11.5029 21.7515 10.6584 21.3292L8.44721 20.2236C8.16569 20.0828 7.83431 20.0828 7.55279 20.2236L4.72361 21.6382C4.39116 21.8044 4 21.5627 4 21.191V2H20V21.191Z" fill={color}/>
      <path d="M9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6H9Z" fill={color}/>
      <path d="M15 6C14.4477 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6Z" fill={color}/>
      <path d="M8 11C8 10.4477 8.44772 10 9 10H11C11.5523 10 12 10.4477 12 11C12 11.5523 11.5523 12 11 12H9C8.44772 12 8 11.5523 8 11Z" fill={color}/>
      <path d="M15 10C14.4477 10 14 10.4477 14 11C14 11.5523 14.4477 12 15 12C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10Z" fill={color}/>
      <path d="M8 15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H9C8.44772 16 8 15.5523 8 15Z" fill={color}/>
      <path d="M15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14Z" fill={color}/>
      <path d="M2 3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3C22 3.55228 21.5523 4 21 4H3C2.44772 4 2 3.55228 2 3Z" fill={color}/>
    </svg>

  );
};

export default Fuel;
import React, {useEffect, useState} from 'react';
import Input from "../Input";
import Select from "react-select";
import Checkbox from "../Checkbox";
import {
  useAgencyWaterTestKitTypesQuery,
  useGetTimezonesQuery, useLazyGetAgencyQuery,
  useUpdateAgancyProfileSettingsMutation
} from "../../store/builder/inspections.api";
import CustomSelect from "../CustomSelect";
import Cropper from "react-cropper";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import log from "../../SVG/Log";

const FboProfile = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [getAgencies, {data: agencyData, error: agencyError, isLoading: agencyIsLoading, isSuccess: agencyIsSuccess, isError: agencyIsError}] = useLazyGetAgencyQuery();
  const {data: timezoneData, error: timezoneError, isLoading: timezoneIsLoading, isSuccess: timezoneIsSuccess, isError: timezoneIsError} = useGetTimezonesQuery()
  const {data: waterKitData, error: waterKitError, isLoading: waterKitIsLoading, isSuccess: waterKitIsSuccess, isError: waterKitIsError} = useAgencyWaterTestKitTypesQuery()

  const [
    updateAgency,
    {
      data: updateAgancyData,
      isError: updateAgancyIsError,
      isLoading: updateAgancyIsLoading,
      isSuccess: updateAgancyIsSuccess,
      error: updateAgancyError,
    },
  ] = useUpdateAgancyProfileSettingsMutation();

  const [phoneNumber, setPhoneNumber] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [chosenPhoto, setChosenPhoto] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [cropper, setCropper] = useState()
  const [changeData, setChangeData] = useState({})
  const [waterKitOptions, setWaterKitOptions] = useState([])
  const [timeOptions, setTimeOptions] = useState([
    {
      label: '12:00 AM',
      value: 0,
    },
    {
      label: '1:00 AM',
      value: 60,
    },
    {
      label: '2:00 AM',
      value: 120,
    },
    {
      label: '3:00 AM',
      value: 180,
    },
    {
      label: '4:00 AM',
      value: 240,
    },
    {
      label: '5:00 AM',
      value: 300,
    },
    {
      label: '6:00 AM',
      value: 360,
    },
    {
      label: '7:00 AM',
      value: 420,
    },
    {
      label: '8:00 AM',
      value: 480,
    },
    {
      label: '9:00 AM',
      value: 540,
    },
    {
      label: '10:00 AM',
      value: 600,
    },
    {
      label: '11:00 AM',
      value: 660,
    },
    {
      label: '12:00 PM',
      value: 720,
    },
    {
      label: '1:00 PM',
      value: 780,
    },
    {
      label: '2:00 PM',
      value: 840,
    },
    {
      label: '3:00 PM',
      value: 900,
    },
    {
      label: '4:00 PM',
      value: 960,
    },
    {
      label: '5:00 PM',
      value: 1020,
    },
    {
      label: '6:00 PM',
      value: 1080,
    },
    {
      label: '7:00 PM',
      value: 1140,
    },
    {
      label: '8:00 PM',
      value: 1200,
    },
    {
      label: '9:00 PM',
      value: 1260,
    },
    {
      label: '10:00 PM',
      value: 1320,
    },
    {
      label: '11:00 PM',
      value: 1380,
    },
  ])

  useEffect(() => {
    getAgencies()
  }, [])

  useEffect(() => {
    if (agencyIsSuccess) {
      setChangeData({
        airport_id: agencyData[0]?.airport_id,
        timezone: agencyData[0]?.timezone,
        title: agencyData[0]?.title,
        start_day_minutes: agencyData[0]?.start_day_minutes,
        has_upstream_tests: agencyData[0]?.has_upstream_tests ? 1 : 0,
        has_corrected_dp: agencyData[0]?.has_corrected_dp ? 1 : 0,
        water_test_kit_id: agencyData[0]?.water_test_kit_id,
        daily_weekly_grace_period: agencyData[0]?.daily_weekly_grace_period,
      })
      setPhoneNumber(agencyData[0]?.fbo_phone?.replace(/\D/g, ''))
    }
  }, [agencyIsSuccess])

  useEffect(() => {
    if (phoneNumber) {
      setChangeData(old => ({...old, fbo_phone: phoneNumber}))
    }
  }, [phoneNumber])

  useEffect(() => {
    if (updateAgancyIsLoading || agencyIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [updateAgancyIsLoading, agencyIsLoading])

  useEffect(() => {
    if (waterKitData?.length > 0) {
      setWaterKitOptions(waterKitData?.map(item => ({label: item?.name, value: item?.id})))
    }
  }, [waterKitData])

  useEffect(() => {
    if (typeof chosenPhoto === "string") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [chosenPhoto]);

  const handleInput = (e) => {
    setChangeData(old => ({...old, [e.target.name]: e.target.value}))
  }

  const handleTimezone = (e) => {
    setChangeData(old => ({...old, timezone: e.value}))
  }
  const handleChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };
  // const handleCreatedFor = (e) => {
  //   setChangeData(old => ({...old, start_day_is_today: e.target.value}))
  // }
  const handleStartTime = (e) => {
    setChangeData(old => ({...old, start_day_minutes: e.target.value}))
  }

  const handleWaterKit = (e) => {
    setChangeData(old => ({...old, water_test_kit_id: e.target.value}))
  }

  const handleUpstream = (e) => {
    setChangeData(old => ({...old, has_upstream_tests: e.target.checked === true ? 1 : 0}))
  }
  const handleHasCorrected = (e) => {
    setChangeData(old => ({...old, has_corrected_dp: e.target.checked === true ? 1 : 0}))
  }

  const cropperHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChosenPhoto(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  function base64ToBlob(base64Data) {
    const contentType = base64Data?.substring(5, base64Data?.indexOf(";"));
    const byteCharacters = atob(
      base64Data?.substring(base64Data?.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setPhoto(cropper.getCroppedCanvas().toDataURL())
      setShowModal(false)
    }
  };

  const updateFbo = () => {
    const formData = new FormData();
    if (photo) {
      formData.append(
        "logo",
        base64ToBlob(photo)
      );
    }
    formData.append('title', changeData?.title);
    formData.append('timezone', changeData?.timezone);
    formData.append('airport_id', changeData?.airport_id);
    formData.append('start_day_minutes', changeData?.start_day_minutes);
    formData.append('has_upstream_tests', changeData?.has_upstream_tests);
    formData.append('has_corrected_dp', changeData?.has_corrected_dp);
    formData.append('water_test_kit_id', changeData?.water_test_kit_id);
    formData.append('daily_weekly_grace_period', changeData?.daily_weekly_grace_period);

    if (Number(changeData?.daily_weekly_grace_period) > -1 && Number(changeData?.daily_weekly_grace_period) <= 365) {
      updateAgency(formData)
        .then(res => {
          if (res?.error) {
            toast.error(res?.error?.data?.message);
          } else {
            toast.success(res?.data?.message);
            getAgencies();
            if (!userInfo?.agency?.is_settings_complete) {
              setShowSaveModal(true)
            }
          }
        })
    } else {
      toast.error('Daily Weekly Grace Period can be 0 to 365')
    }

  }

  return (
    <div>
      {showSaveModal && (
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p></p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setShowSaveModal(false)}
              />
            </div>
            <div className="modal__content-body">
              <div className="modal__content-body save-modal">
                <img
                  src="/assets/icons/Done.svg"
                  alt="img"
                />
                <span>Great job! You just set up your FBO profile.
              Next steps are to add your equipment in Equipment Management and
              add users in User Management. You have up to 7 days to do this.
              Contact NDX once all your equipment and users have been added
                </span>
              </div>
            </div>
            <div className="modal__content-bottom">
              <button className="save full" onClick={() => setShowSaveModal(false)}>
                Got it
              </button>
            </div>
          </div>
          <div className="modal__bg"></div>
        </div>
      )}
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                minCropBoxWidth={300}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
          ></div>
        </div>
      )}
      <div className="profile__info profile">
        <div className="profile__info column">
          <p className="profile__title">FBO Profile
          </p>
          <div className="profile__imageBox">
            <img
              className="profile__avatar"
              src={photo ? photo : userInfo?.agency?.logo}
              alt="avatar"
            />
            <div>
              <p className="profile__imageBox-desc">
                We only support .JPG, .JPEG, or .PNG file.
              </p>
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-label"
              >
                <input
                  type="file"
                  name="profile_photo"
                  id="profile_photo"
                  onChange={cropperHandler}
                  accept="image/jpeg, image/jpg, image/png"
                />
                Upload photo
              </label>
            </div>
          </div>
        </div>
        <Input
          label={"FBO name"}
          maxwidth={595}
          placeholder={"FBO name"}
          id={"fbo_phone"}
          name={"title"}
          defaultValue={userInfo?.agency?.title}
          onChange={handleInput}
        />
        <label className="customInput">
          <span style={{marginBottom: 8}}>FBO Timezone</span>
          <Select
            defaultValue={userInfo?.agency?.timezone === 'America/New_York' ? null : userInfo?.agency?.timezone ? {
              value: userInfo?.agency?.timezone,
              label: userInfo?.agency?.timezone
            } : null}
            options={timezoneData?.data?.map(item => ({value: item, label: item}))}
            onChange={handleTimezone}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 629,
                height: 42,
                backgroundColor: "#F4F5F6",
              }),
            }}
          />
        </label>
        <Input
          label={"Airport ID"}
          maxwidth={595}
          placeholder={"Airport ID"}
          defaultValue={userInfo?.agency?.airport_id}
          onChange={handleInput}
          name={"airport_id"}
        />

        <p className="profile__title">FBO Settings
          {
            !userInfo?.agency?.is_settings_complete &&
            <span className="required"> (Required)</span>
          }
        </p>

        <div style={{width: 630}}>
          <CustomSelect
            tooltip={true}
            disabled={userInfo?.agency?.has_corporate_settings}
            tooltipText={"Influences when inspections are generated. A 7 am workday will allow day’s inspections to be completed 7 am - 7 am next day."}
            label={"When do you want new inspections to generate"}
            options={timeOptions}
            name={"start_day_minutes"}
            onChange={handleStartTime}
            defaultValue={userInfo?.agency?.start_day_minutes?.toString()?.length ?
              timeOptions.filter(el => el.value === userInfo?.agency?.start_day_minutes)[0]
              : null}
          />
        </div>

        {
          waterKitOptions?.length > 0 &&
          <div style={{width: 630}}>
            <CustomSelect
              disabled={userInfo?.agency?.has_corporate_settings}
              label={"Water Test Kit"}
              options={waterKitOptions}
              name={"water_test_kit_id"}
              onChange={handleWaterKit}
              defaultValue={changeData?.water_test_kit_id ?
                waterKitOptions?.filter(el => el.value == userInfo?.agency?.water_test_kit_id?.toString())[0]
                : null}
            />
          </div>
        }

        <div className="facility-modal__field" style={{marginTop: 16}}>
          <Checkbox
            disabled={userInfo?.agency?.has_corporate_settings}
            key={"has_upstream_tests"}
            label={"Has Upstream Tests"}
            id={"has_upstream_tests"}
            checked={changeData?.has_upstream_tests}
            onChange={handleUpstream}
          />
        </div>

        <div className="facility-modal__field">
          <Checkbox
            disabled={userInfo?.agency?.has_corporate_settings}
            key={"has_corrected_dp"}
            label={"Has Corrected DP for weekly Avgas"}
            id={"has_corrected_dp"}
            checked={changeData?.has_corrected_dp}
            onChange={handleHasCorrected}
          />
        </div>

        <div style={{height: 16}}></div>
        <Input
          disabled={userInfo?.agency?.has_corporate_settings}
          label={"Daily Weekly Grace Period"}
          maxwidth={595}
          placeholder={"Daily Weekly Grace Period"}
          defaultValue={userInfo?.agency?.daily_weekly_grace_period}
          value={changeData?.daily_weekly_grace_period}
          onChange={handleInput}
          name={"daily_weekly_grace_period"}
          type={"number"}
          tooltip={true}
          tooltipText={"Minimum value is 0 and maximum value is 365"}
        />

      </div>
      <button className="profile__methods-btn" onClick={updateFbo}>Save</button>
    </div>
  );
};

export default FboProfile;
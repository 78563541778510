import React, {useEffect, useState} from 'react';
import './index.scss';
import InspectionField from "../InspectionField";
import {useGetFieldTypesQuery} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import {useUpdateFieldMutation} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import { useErrorHandler } from '../../global/ErrorsHandler';

export default function InspectionSideFields({category, setOpen}) {
  // const [changesStatus, setChangesStatus] = useState(true)
  const dispatch = useDispatch()
  const location = useLocation()
  const currentCategory = useSelector(state => state.rootReducer.inspectionSlice.category)
  const {
    data: fieldTypesData,
    isError: fieldTypesIsError,
    isFetching: fieldTypesIsLoading,
    isSuccess: fieldTypesIsSuccess,
    error: fieldTypesError
  } = useGetFieldTypesQuery()

  const [updateCategory, {
    data: updateCategoryTypesData,
    error: updateCategoryTypesDataError,
    isError: updateCategoryTypesIsError,
    isLoading: updateCategoryTypesIsLoading,
    isSuccess: updateCategoryTypesIsSuccess,
  }
  ] = useUpdateFieldMutation()

  const [errorHandler, setErrorHandler] = useErrorHandler([updateCategoryTypesDataError, fieldTypesError])

  useEffect(() => {
    if (updateCategoryTypesIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }

    if (updateCategoryTypesIsSuccess) {
      toast.success(updateCategoryTypesData.message)
      setOpen(false)
    }
    if (updateCategoryTypesIsError) {
      for (const key in updateCategoryTypesDataError.data) {
        toast.error(updateCategoryTypesDataError.data[key][0])
      }
    }
  }, [updateCategoryTypesIsLoading, updateCategoryTypesIsSuccess, updateCategoryTypesIsError])

  function acceptableCheck(arr) {
    if (arr.every(obj => obj.acceptable?.hasOwnProperty('type'))) {
      if (arr.every(obj => obj.acceptable?.value?.length > 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const changeCategoryFields = () => {
        if (acceptableCheck(currentCategory?.fields)) {
          updateCategory({id: currentCategory.id, fields: currentCategory.fields})
        } else {
          toast.error('Acceptable value is required!')
        }
  }


  return (
    <div className="sideFields">
      <div className="sideFields__content">
        <div className="sideFields__content-head">
          <p className="sideFields__content-title">{category?.label}</p>
          <div>
            <button onClick={changeCategoryFields}>Save</button>
            <img src="/assets/icons/close.svg" alt="icon" onClick={() => setOpen(false)}/>
          </div>
        </div>
        <div className="sideFields__content-fields">
          {
            currentCategory?.fields?.map((field, index) => (
              <InspectionField key={index} parent={true} item={category} field={field} elem={field}
                               durationsOptions={fieldTypesData?.data} idx={index} allFields={currentCategory?.fields} />
            ))
          }
        </div>
      </div>
      <div className="sideFields__bg" onClick={() => setOpen(false)}>
      </div>

    </div>
  );
};


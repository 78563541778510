import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Coin from "../../SVG/Coin";
import CaseIcon from "../../SVG/CaseIcon";
import Pencil from "../../SVG/Pencil";
import Trash from "../../SVG/Trash";
import RefreshEquipment from "../../SVG/RefreshEquipment";
import ClearInspection from "../../SVG/ClearInspection";
import FboDetails from "../../SVG/FBODetails";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useClearInspectionsFboMutation, useReactivateFboEquipmentsMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import Settings from "../../SVG/Settings";

const FBOManagementRow = ({item, data, setFBOData, setCurrentCorporateAccount, setPricingModal, getAgencies, setCurrentItem, setCreateModalStatus, setSettingsModal}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reactivateStatus, setReactivateStatus] = useState(false)
  const [dangerClearShow, setDangerClearShow] = useState(false)

  const [clearInspection, {
    data: clearInspectionData,
    error: clearInspectionError,
    isFetching: clearInspectionIsLoading,
    isSuccess: clearInspectionIsSuccess,
    isError: clearInspectionIsError
  }] = useClearInspectionsFboMutation();

  const [reactivate, {
    data: reactivateData,
    error: reactivateError,
    isFetching: reactivateIsLoading,
    isSuccess: reactivateIsSuccess,
    isError: reactivateIsError
  }] = useReactivateFboEquipmentsMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    clearInspectionError,
    reactivateError,
  ]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".corporate-action") === null && e.target.closest(".fboManagement__dropdown") === null) {
        setFBOData(old => {
          let data = [...old]
          data?.map(item => {
            item.dropdown_status = false
          })
          return data
        })
      }
    });
  }, [location]);


  const changeDropdownStatus = () => {
    const newData = [...data]
    newData?.map(el => {
      if (el.id === item.id) {
        el.dropdown_status = !el.dropdown_status
      } else {
        el.dropdown_status = false
      }
    })
    setFBOData(newData)
  }

  const renderDangerClear = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to clear all inspections?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerClearShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            If you click "Clear", all inspections for this FBO will be deleted permanently.
            This includes all historical inspections.
            This action cannot be undone, so please make sure that you want to delete all inspections before proceeding.
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              clearInspection({confirmed: true, agency_id: item?.id})
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerClearShow(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  if (err?.status === 403) {
                    toast.error("Something went wrong")
                  }
                  setDangerClearShow(false)
                })
            }}>
              Clear
            </button>
            <button className="close almost" onClick={() => {
              setDangerClearShow(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }
  const renderReactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to reactivate equipment?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setReactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Reactivate" will reactivate on the equipment and make it available for further use. Are you sure you want to reactive the equipment?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              reactivate({confirmed: true, agency_id: item?.id})
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("Equipments successfully reactivated")
                  setReactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  if (err?.status === 403) {
                    toast.error("Something went wrong")
                  }
                  dispatch(setLoader(false))
                  setReactivateStatus(false)
                })
            }}>
              Reactivate
            </button>
            <button className="close almost" onClick={() => {
              setReactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }


  return (
    <div className="fboManagement__table-row">
      {dangerClearShow && renderDangerClear()}
      {reactivateStatus && renderReactivate()}
      <div className="fboManagement__table-th">{item?.id}</div>
      <div className="fboManagement__table-th">{item?.title}</div>
      <div className="fboManagement__table-th">{item?.airport_id}</div>
      <div className="fboManagement__table-th">{item?.timezone}</div>
      <div className="fboManagement__table-th logo">
        <img src={item?.logo} alt="icon"/>
      </div>
      <div className="fboManagement__table-th corporate-action">
        <img src="/assets/icons/dots.svg" alt="dots" onClick={changeDropdownStatus} />
      </div>

      {
        item?.dropdown_status &&
        <div className="fboManagement__dropdown">
          <div className="fboManagement__dropdown-elem" onClick={() => {
            navigate(`/fbo/${item?.id}`)
            // setPricingModal(true);
          }}>
            <FboDetails color={'#999999'} />
            <p>FBO Details</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {
            setCurrentItem(item);
            setCreateModalStatus(true)
          }}>
            <Pencil color={'#999999'} />
            <p>Edit</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {
            setCurrentItem(item);
            setSettingsModal(true);
          }}>
            <Settings color={'#999999'} />
            <p>Settings</p>
          </div>
        </div>
      }
    </div>
  );
};

export default FBOManagementRow;
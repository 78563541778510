import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  user: null,
};

const loginSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setUser } = loginSlice.actions;
export default loginSlice.reducer;

import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import {useUpdateSettingsAsCorporateMutation} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useErrorHandler} from "../../global/ErrorsHandler";

const FboSettings = ({exit, currentFbo, getAgencies, waterKitData}) => {
  const dispatch = useDispatch();
  const [timeOptions, setTimeOptions] = useState([
    {
      label: '12:00 AM',
      value: 0,
    },
    {
      label: '1:00 AM',
      value: 60,
    },
    {
      label: '2:00 AM',
      value: 120,
    },
    {
      label: '3:00 AM',
      value: 180,
    },
    {
      label: '4:00 AM',
      value: 240,
    },
    {
      label: '5:00 AM',
      value: 300,
    },
    {
      label: '6:00 AM',
      value: 360,
    },
    {
      label: '7:00 AM',
      value: 420,
    },
    {
      label: '8:00 AM',
      value: 480,
    },
    {
      label: '9:00 AM',
      value: 540,
    },
    {
      label: '10:00 AM',
      value: 600,
    },
    {
      label: '11:00 AM',
      value: 660,
    },
    {
      label: '12:00 PM',
      value: 720,
    },
    {
      label: '1:00 PM',
      value: 780,
    },
    {
      label: '2:00 PM',
      value: 840,
    },
    {
      label: '3:00 PM',
      value: 900,
    },
    {
      label: '4:00 PM',
      value: 960,
    },
    {
      label: '5:00 PM',
      value: 1020,
    },
    {
      label: '6:00 PM',
      value: 1080,
    },
    {
      label: '7:00 PM',
      value: 1140,
    },
    {
      label: '8:00 PM',
      value: 1200,
    },
    {
      label: '9:00 PM',
      value: 1260,
    },
    {
      label: '10:00 PM',
      value: 1320,
    },
    {
      label: '11:00 PM',
      value: 1380,
    },
  ])
  const [waterRating, setWaterRating] = useState([
    {
      value: "A", label: "A. Bright"
    },
    {
      value: "B", label: "B. Hazy"
    },
    {
      value: "C", label: "C. Cloudy"
    },
    {
      value: "D", label: "D. Wet"
    },
    {
      value: "E", label: "E. Surfactants"
    }
  ])
  const [solidRating, setSolidRating] = useState([
    {
      value: 1, label: "1. Clean"
    },
    {
      value: 2, label: "2. Slight"
    },
    {
      value: 3, label: "3. Particulate"
    },
    {
      value: 4, label: "4. Dirty"
    }
  ])
  const [settingsOptions, setSettingsOptions] = useState([
    {
      value: 1,
      label: 'Default Settings'
    },
    {
      value: 0,
      label: 'Custom Settings'
    }
  ])
  const [state, setState] = useState({
    start_day_minutes: null,
    nozzle_fueling_pressure: null,
    sump_solid_rating: null,
    sump_water_rating: null,
    sump_trend_alert: null,
    has_corporate_settings: null,
    nozzle_fueling_pressure_decommission: null,
    daily_weekly_grace_period: null,
    // water_test_kit_id: null,
  })

  const [
    updateSettings,
    {
      data: updateSettingsData,
      isLoading: updateSettingsIsLoading,
      isError: updateSettingsIsError,
      error: updateSettingsError,
      isSuccess: updateSettingsIsSuccess,
    },
  ] = useUpdateSettingsAsCorporateMutation()

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateSettingsError,
  ]);

  useEffect(() => {
    if (updateSettingsIsLoading) {
      dispatch(setLoader(true))
    }
    if (updateSettingsIsError) {
      dispatch(setLoader(false))
    }
    if (updateSettingsIsSuccess) {
      dispatch(setLoader(false))
      getAgencies()
      exit()
      toast.success("Successfully updated")
    }
  }, [updateSettingsIsLoading, updateSettingsIsError, updateSettingsIsSuccess])

  useEffect(() => {
    if (currentFbo) {
      setState({
        start_day_minutes: currentFbo.start_day_minutes,
        nozzle_fueling_pressure: currentFbo.nozzle_fueling_pressure,
        nozzle_fueling_pressure_decommission: currentFbo?.nozzle_fueling_pressure_decommission,
        sump_solid_rating: currentFbo.sump_solid_rating,
        sump_water_rating: currentFbo.sump_water_rating,
        sump_trend_alert: currentFbo.sump_trend_alert,
        has_corporate_settings: currentFbo.has_corporate_settings ? "1" : "0",
        daily_weekly_grace_period: currentFbo?.daily_weekly_grace_period,
        // water_test_kit_id: currentFbo?.water_test_kit_id,
      })
    }
  }, [currentFbo]);

  const handleInput = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }

  const update = (e) => {
    e.preventDefault()
    if (state?.nozzle_fueling_pressure &&
      state?.nozzle_fueling_pressure !== null ) {
      updateSettings({body: {...state}, id: currentFbo?.id})
    }
    if (!state?.nozzle_fueling_pressure || state?.nozzle_fueling_pressure == null) {
      toast.error('Nozzle fueling pressure field is required!')
    }
    // if (!state?.sump_trend_alert || state?.sump_trend_alert == null) {
    //   toast.error('Sump trend alert field is required!')
    // }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{"Settings"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body height settings-change`}>
          <div style={{marginBottom: 16}}>
            {
              state?.has_corporate_settings == 1 ?
                <Input
                  containerWidth={"100%"}
                  label={"When do you want new inspections to generate"}
                  name={"start_day_minutes"}
                  defaultValue={currentFbo?.start_day_minutes?.toString()?.length ?
                    timeOptions.filter(el => el.value === currentFbo?.start_day_minutes)[0].label
                    : null}
                  readOnly={true}
                />
                :
                <CustomSelect
                  label={"When do you want new inspections to generate"}
                  options={timeOptions}
                  name={"start_day_minutes"}
                  defaultValue={currentFbo?.start_day_minutes?.toString()?.length ?
                    timeOptions.filter(el => el.value === currentFbo?.start_day_minutes)[0]
                    : null}
                  onChange={handleInput}
                />
            }

          </div>
          <Input
            containerWidth={"100%"}
            label={"Nozzle Fueling Pressure"}
            placeholder={"Nozzle Fueling Pressure"}
            name={"nozzle_fueling_pressure"}
            type={"number"}
            defaultValue={currentFbo?.nozzle_fueling_pressure}
            onChange={handleInput}
            readOnly={state?.has_corporate_settings == 1 ? true : false}
          />
          <Input
            containerWidth={"100%"}
            label={"Nozzle Fueling Pressure Decommission"}
            placeholder={"Nozzle Fueling Pressure Decommission"}
            name={"nozzle_fueling_pressure_decommission"}
            type={"number"}
            defaultValue={currentFbo?.nozzle_fueling_pressure_decommission}
            onChange={handleInput}
            readOnly={state?.has_corporate_settings == 1 ? true : false}
          />
          <div style={{display: 'flex', gap: 20}}>
            <div style={{width: "50%"}}>
              {
                state?.has_corporate_settings == 1 ?
                  <Input
                    containerWidth={"100%"}
                    label={"Sump solid Rating"}
                    name={"sump_solid_rating"}
                    defaultValue={currentFbo?.sump_solid_rating ? solidRating.filter(el => el.value == currentFbo?.sump_solid_rating)[0].label
                      : null}
                    readOnly={true}
                  />
                  :
                  <CustomSelect
                    label={"Sump solid Rating"}
                    placeholder={"Sump Solid Rating"}
                    name={"sump_solid_rating"}
                    defaultValue={currentFbo?.sump_solid_rating ? solidRating.filter(el => el.value == currentFbo?.sump_solid_rating)[0] : ""}
                    onChange={handleInput}
                    options={solidRating}
                    maxwidth={'100%'}
                  />
              }

            </div>
            <div style={{width: "50%"}}>
              {
                state?.has_corporate_settings == 1 ?
                  <Input
                    containerWidth={"100%"}
                    label={"Sump water Rating"}
                    name={"sump_water_rating"}
                    defaultValue={currentFbo?.sump_water_rating?.toString()?.length ?
                      waterRating.filter(el => el.value === currentFbo?.sump_water_rating)[0].label
                      : null}
                    readOnly={true}
                  />
                  :
                  <CustomSelect
                    label={"Sump water rating"}
                    options={waterRating}
                    name={"sump_water_rating"}
                    onChange={handleInput}
                    defaultValue={currentFbo?.sump_water_rating?.toString()?.length ?
                      waterRating.filter(el => el.value === currentFbo?.sump_water_rating)[0]
                      : null}
                  />
              }


            </div>
          </div>

          <div style={{marginTop: 16}}>
            <Input
              label={"Sump Trend Alert"}
              containerWidth={"100%"}
              placeholder={"Sump Trend Alert"}
              name={"sump_trend_alert"}
              type={"number"}
              defaultValue={currentFbo?.sump_trend_alert}
              onChange={handleInput}
              readOnly={state?.has_corporate_settings == 1 ? true : false}
            />
          </div>
          <div style={{marginTop: 16}}>
            <Input
              tooltip={true}
              tooltipText={"Minimum value is 0 and maximum value is 365"}
              containerWidth={"100%"}
              id="daily_weekly_grace_period"
              name="daily_weekly_grace_period"
              placeholder="Daily Weekly Grace Period"
              label={"Daily Weekly Grace Period"}
              type={"number"}
              onChange={handleInput}
              readOnly={state?.has_corporate_settings == 1 ? true : false}
              defaultValue={currentFbo ? currentFbo?.daily_weekly_grace_period : ""}
            />
          </div>

          <CustomSelect
            label={"Standardize FBO Settings"}
            options={settingsOptions}
            name={"has_corporate_settings"}
            onChange={handleInput}
            defaultValue={settingsOptions?.filter(el => el.value == currentFbo?.has_corporate_settings)}
          />
        </div>

        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Cancel
          </button>
          <button className="save" onClick={update}>
            Save
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default FboSettings;
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactCodeInput from "react-verification-code-input";
import { validationEmail } from "../../global/validate";
import axios from "axios";
import { setLoader } from "../../store/builder/reducer";
import { setUser } from "../../store/builder/loginSlice";
import {
  setVerifyRegister,
  setVerifyRegisterTime,
} from "../../store/builder/registerSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTimer from "../../components/CustomHooks/useTimer";

export default function ConfirmRegistration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifyToken = useSelector(
    (state) => state.rootReducer.registerSlice.verifyToken
  );
  const timeExpired = useSelector(
    (state) => state?.rootReducer?.registerSlice?.verifyTokenExpired
  );
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [state, setState] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const remainingTime = useTimer(timeExpired, timeExpired);

  useEffect(() => {
    if (state?.length > 5) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [state]);

  const handleAgain = (e) => {
    dispatch(setLoader(true));
    e.preventDefault();
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/resend-email`, {
        user_id: userInfo.id,
      }, config)
      .then((res) => {
        dispatch(setLoader(false));
        localStorage.setItem(
          "token_for_register",
          res.data?.email_verify_token
        );
        dispatch(setVerifyRegisterTime(res.data.email_verify_token_expires_at));
        toast.success("Code has been sent to your email", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      });
  };

  const handleCode = (e) => {
    setState(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.length > 0) {
      dispatch(setLoader(true));
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/verify-email`, {
          verify_token: localStorage.getItem("token_for_register"),
          code: state,
        }, config)
        .then((res) => {
          dispatch(setLoader(false));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(() => {
            navigate("/subscription");
          }, 500);
        })
        .catch((err) => {
          dispatch(setLoader(false));
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        });
    } else {
      toast.error("Please enter the correct code");
    }
  };

  return (
    <div className="auth">
      <Helmet>
        <title>Confirm Code | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/register-bg.png" alt="bg" />
        </div>
        <div className="auth__right">
          <div className="auth__content regist">
            <div className="auth__back">
              <img src="/assets/icons/back.svg" alt="icon" />
              <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                Back to Sign up
              </span>
            </div>
            <h1 className="auth__title">Confirm your email</h1>
            <form className="auth__form">
              <ReactCodeInput
                placeholder="------"
                className="auth__field-codeField registration"
                onChange={handleCode}
              />

              <button
                className={`auth__submit ${disabledBtn ? "disabled" : ""}`}
                disabled={disabledBtn}
                onClick={handleSubmit}
              >
                Confirm
              </button>
              <div>
                {remainingTime > 0 ? (
                  <span className="auth__try">
                    Try again in {remainingTime} sec
                  </span>
                ) : (
                  <span className="auth__try again" onClick={handleAgain}>
                    Send again
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import Wrapper from "../../components/Wrapper";
import './index.scss'

export default function Proximity() {
  return (
    <Wrapper>
      <div>
        Proximity
      </div>
    </Wrapper>

  );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import {
  addInspectionInfo,
  addSteps,
  addParentField,
} from "../../store/builder/inspectionSlice";
import { useLocation, useParams } from "react-router-dom";
import {
  useCreateFacilityMutation,
  useGetFieldTypesQuery,
  useLazyGetInspectionByIdQuery,
  useReorderCategoryMutation,
} from "../../store/builder/inspections.api";
import "./index.scss";
import { setLoader } from "../../store/builder/reducer";
import { useCreateCategoryMutation } from "../../store/builder/inspections.api";
import { toast } from "react-toastify";
import CategoryField from "../../components/CategoryField";
import InspectionSideFields from "../../components/InspectionSideFields";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import log from "../../SVG/Log";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";

export default function FormBuilder() {
  const {
    data: fieldTypesData,
    isError: fieldTypesIsError,
    error: fieldTypesError,
    isSuccess: fieldTypesIsSuccess,
    isFetching: fieldTypesIsLoading,
  } = useGetFieldTypesQuery();

  const [
    getInspection,
    {
      data: inspectionData,
      isError: inspectionIsError,
      error: inspectionError,
      isSuccess: inspectionIsSuccess,
      isFetching: inspectionIsLoading,
    },
  ] = useLazyGetInspectionByIdQuery();

  const [
    reorder,
    {
      data: setOrderData,
      isError: setOrderIsError,
      error: setOrderError,
      isSuccess: setOrderIsSuccess,
      isFetching: setOrderIsLoading,
    },
  ] = useReorderCategoryMutation();

  const [addStepStatus, setAddStepStatus] = useState(false);
  const [stepName, setStepName] = useState("");
  const [stepsList, setStepsList] = useState([]);
  const [durationsOptions, setDurationsOptions] = useState([]);
  const [createCategoryData, setCreateCategoryData] = useState({
    inspection_form_id: 1,
    label: "",
  });
  const [cards, setCards] = useState(inspectionData?.categories);
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const [create, { isError, isLoading, isSuccess, data, error }] =
    useCreateCategoryMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    error,
    setOrderError,
    inspectionError,
    fieldTypesError,
  ]);

  useEffect(() => {
    if (fieldTypesData?.data) {
      setDurationsOptions(Object?.values(fieldTypesData?.data));
    }
    getInspection(params.id);
  }, [fieldTypesData]);

  useEffect(() => {
    if (inspectionIsLoading) {
      dispatch(setLoader(true));
    }
    if (inspectionIsSuccess) {
      setCards(inspectionData?.categories);
      dispatch(setLoader(false));
    }
  }, [inspectionIsSuccess, inspectionIsLoading]);

  useEffect(() => {
    dispatch(setLoader(fieldTypesIsLoading));
    setCreateCategoryData((old) => ({ ...old, inspection_form_id: params.id }));
  }, [fieldTypesIsLoading]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(isLoading));
    }
    if (isSuccess) {
      toast.success(data.message);
      dispatch(setLoader(false));
      setAddStepStatus(false);
    }
  }, [isLoading, isSuccess, isError]);

  useEffect(() => {
    setCards(inspectionData?.categories);
  }, [inspectionIsSuccess, isSuccess, isLoading]);

  useEffect(() => {
    if (cards?.length > 1) {
      reorder({
        inspection_form_id: params.id,
        category_ids: cards?.map((item) => item.id + "").join(","),
      });
      getInspection(params.id);
    }
  }, [cards]);
  const handleStep = (e) => {
    setStepName(e.target.value);
    setCreateCategoryData((old) => ({ ...old, label: e.target.value }));
  };

  const addNewCategory = (e) => {
    e.preventDefault();
    if (stepName) {
      setStepsList((old) => [
        ...old,
        { label: stepName, id: old.length + 1, fields: [] },
      ]);
      setAddStepStatus(false);
      create(createCategoryData);
    }
  };

  const moveCard = (dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  };

  const renderCard = useCallback((card, index) => {
    return (
      <CategoryField
        category={card}
        key={card.id}
        index={index}
        id={card.id}
        moveCard={moveCard}
        cards={cards}
      />
    );
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Form Builder | NDX</title>
      </Helmet>
      <DashboardHeader
        title={inspectionData?.name}
        inspection_type={inspectionData?.period}
        showBtn={false}
        type={"inspection_form_update"}
      />
      <div className="builder">
        <div className="builder__steps">
          <div className="builder__form">
            <div className="builder__form-bottom">
              <div className="builder__form-categoryHeader">
                <p className="builder__form-title">Categories</p>
                <div
                  className="builder__form-addStep"
                  onClick={() => setAddStepStatus(true)}
                >
                  <span>Add Category</span>
                </div>
              </div>
              <DndProvider backend={HTML5Backend}>
                <div>
                  {inspectionData?.categories?.map((category, index) =>
                    renderCard(category, index)
                  )}
                </div>
              </DndProvider>
              {addStepStatus &&
                <form onSubmit={(e) => addNewCategory(e)}>
                  <div className="builder__form-category">
                    <input
                      id="addStep"
                      placeholder="Categories name"
                      name="addStep"
                      onChange={(e) => handleStep(e)}
                    />
                    <div className="builder__form-categoryInner">
                      <img
                        src="/assets/icons/x.svg"
                        alt="close"
                        onClick={() => setAddStepStatus(false)}
                      />
                      <img
                        src="/assets/icons/confirm.svg"
                        alt="confirm"
                        onClick={(e) => {
                          addNewCategory(e)
                          setAddStepStatus(false);
                        }}
                      />
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
        {/*<div className="builder__fields">*/}
        {/*  {*/}
        {/*    currentStep !== 0 ?*/}
        {/*      renderField*/}
        {/*      : null*/}
        {/*  }*/}
        {/*</div>*/}
      </div>
    </Wrapper>
  );
}

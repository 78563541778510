import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from '../../components/DashboardHeader';
import './index.scss'
import {useDispatch, useSelector} from "react-redux";
import {
  useCreateServiceMutation,
  useGetServiceRequestsQuery, useGetUsersByAgencyIdMutation, useGetUsersByAgencyIdQuery, useGetUsersByAgencyMutation,
  useGetUsersQuery, useLazyGetServiceRequestsQuery, useLazyGetUsersByAgencyIdQuery
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import ServiceRow from "../../components/ServiceRow";
import { useErrorHandler } from '../../global/ErrorsHandler';
import Input from "../../components/Input";
import CustomSelect from "../../components/CustomSelect";
import {toFormData} from "axios";
import {toast} from "react-toastify";
import { Helmet } from 'react-helmet';
import CustomPaginate from "../../components/PaginateComponent";
import Select from "react-select";

export default function ServiceRequest() {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [modal, setModal]= useState(false)
  const [createData, setCreateData] = useState({
    comment: null,
    file: null,
  })
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [statusOptions, setStatusOptions] = useState([
    {
      value: "new",
      label: "New"
    },
    {
      value: "pending",
      label: "Pending"
    },
    {
      value: "closed",
      label: "Closed"
    },
  ])

  const [getObservations, {
    data: requestsData,
    isSuccess: requestsIsSuccess,
    isFetching: requestsIsLoading,
    isError: requestsIsError,
    error: requestsError,
  }] = useLazyGetServiceRequestsQuery()

  const [
    createService,
    {
      data: createServiceData,
      isSuccess: createServiceDataIsSuccess,
      isLoading: createServiceDataIsLoading,
      isError: createServiceDataIsError,
      error: createServiceDataError,
    }
  ] = useCreateServiceMutation()

  const [getUsers,
    {
      data: getUsersData,
      isSuccess: getUsersIsSuccess,
      isLoading: getUsersIsLoading,
      isError: getUsersIsError,
      error: getUsersError,
    }] = useLazyGetUsersByAgencyIdQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([requestsError])

  useEffect(() => {
    getUsers(userInfo?.agency?.id)
  }, [])

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modal])


  useEffect(() => {
    getObservations({ page: currentPage, status: filter})
  }, [currentPage, filter])

  useEffect(() => {
    if (requestsIsLoading) {
      dispatch(setLoader(true))
    }
    if (requestsIsSuccess) {
      dispatch(setLoader(false))
    }
    if (createServiceDataIsLoading) {
      dispatch(setLoader(true))
    }
    if (createServiceDataIsSuccess) {
      dispatch(setLoader(false))
      setModal(false)
    }
  }, [requestsIsSuccess, requestsIsLoading, createServiceDataIsSuccess, createServiceDataIsLoading])

  const handleCreate = (e) => {
    if (e.target.name === 'comment') {
      setCreateData(old => ({...old, [e.target.name]: e.target.value}))
    } else {
      setCreateData(old => ({...old, [e.target.name]: e.target.files[0]}))
    }
  }

  const submitCreate = () => {
    if (createData.comment) {
      const form = new FormData()
      form.append('comment', createData.comment)
      if (createData.file) {
        form.append('file', createData.file)
      }
      createService(form).then(res => {
        toast.success(res.data?.message, 5000)
      })
    } else {
      toast.error("Fill the comment field!")
    }
  }

  const renderCreateModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setModal(false)}
            />
          </div>
          <div className="modal__content-body">
            <Input
              containerWidth={"100%"}
              id="comment"
              name="comment"
              placeholder="Comment"
              label={"Comment"}
              type={"text"}
              onChange={handleCreate}
            />

            <label htmlFor="file" className="customInput file" style={{width: "100%"}}>
              File
              <input type="file" name='file' id='file' onChange={handleCreate} />
            </label>

          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setModal(false)}>
              Close
            </button>
            <button className="save" onClick={submitCreate}>
              Save
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>

    )
  }

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    getObservations({ page: page.selected + 1 });
    window.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Observation | NDX</title>
      </Helmet>
      {modal && renderCreateModal()}
      <DashboardHeader title={'Observation'} showBtn={true} type='serviceRequest' action={() => setModal(true)} />
      <div className="service">
        <div className="service__filters">
          <Select styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                      borderRadius: 8,
                      height: 42,
                      backgroundColor: '#F4F5F6',
                      marginTop: 8,
                      width: 240,
                    }),
                  }}
                  placeholder={"Status"}
                  options={statusOptions}
                  onChange={(e) => {
                    setFilter(e.value)
                  }}
          />
        </div>
        <div className="service__table">
          <div className="service__table-head">
            <div className="service__table-th">Created Date</div>
            <div className="service__table-th">Comment</div>
            <div className="service__table-th">Status</div>
            <div className="service__table-th">File</div>
          </div>
          <div className="service__table-body">
            {
              requestsData?.data?.length ? requestsData?.data?.map(request => (
                <ServiceRow request={request} key={request.id} userOptions={getUsersData?.map(item => ({value: item?.id, label: item?.name}))} />
              )) : (
                <div className='emptyList'>
                  <img src="/assets/empty-list.png" alt="empty" />
                  <p>No observation data yet</p>
                </div>
              )
            }
          </div>

          <CustomPaginate
            pageCount={requestsData?.pages}
            handlePageClick={handlePageClick}
          />
        </div>

      </div>
    </Wrapper>

  );
};

import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoader } from "../store/builder/reducer";
import {useLazyGetAgencyQuery} from "../store/builder/inspections.api";

export const useErrorHandler = (errors) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentFbo = localStorage.getItem('current-fbo')
  const agenciesList = useSelector((state) => state?.rootReducer?.agenciesListSlice?.agencies);

  useEffect(() => {
    errors?.forEach((error) => {
      if (error) {
        if (error?.status == 422) {
          if (error?.data?.message == 'Invalid Agency.') {
            const s = agenciesList?.filter(el => el?.id !== currentFbo)[0]
            localStorage.setItem('current-fbo', s?.id)
            localStorage.setItem('current-fbo-title', s?.title)
            window.location.reload();
          }
        }
        if (error?.status == 401) {
          toast.error(error?.data?.message, 1000);
          dispatch(setLoader(false));
          setTimeout(() => {
            navigate("/login");
            localStorage.removeItem("ndx_user_token");
          }, 500);
        } else if (error?.status == 403) {
        } else {
          toast.error(error?.data?.message, 1000);
          dispatch(setLoader(false));
        }
      }
    });
  }, errors);

  return [];
};

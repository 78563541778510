import React from 'react';

const Management = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3386 12.2873L12.3579 9.36658C11.9049 9.10089 11.7353 8.52944 11.9702 8.05966C12.2284 7.54336 12.8695 7.35318 13.3674 7.64518L18.2331 10.4985C19.3749 11.0694 19.9231 12.393 19.5195 13.6041C19.0258 15.0851 17.3349 15.7855 15.9386 15.0873L13.5528 13.8945C13.0589 13.6475 12.8586 13.0468 13.1056 12.5528C13.3526 12.0588 13.9533 11.8586 14.4473 12.1056L16.8331 13.2985C17.1407 13.4523 17.5133 13.298 17.6221 12.9717C17.711 12.7048 17.5902 12.4131 17.3386 12.2873Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.59093 8L6.3636 17.6518C6.27877 18.0193 6.40801 18.4033 6.6978 18.6448L9.35972 20.8631C9.73056 21.1721 10.2692 21.1721 10.6401 20.8631L13.302 18.6448C13.5918 18.4033 13.721 18.0193 13.6362 17.6518L11.4089 8H8.59093ZM13.1788 6.77514C13.0741 6.32141 12.67 6 12.2044 6H7.79541C7.32976 6 6.92573 6.32141 6.82102 6.77514L4.41481 17.202C4.16035 18.3047 4.54805 19.4568 5.41743 20.1813L8.07935 22.3995C9.19188 23.3267 10.8079 23.3267 11.9205 22.3995L14.5824 20.1813C15.4517 19.4568 15.8395 18.3047 15.585 17.202L13.1788 6.77514Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3945 2H7.60562C7.27127 2 6.95904 2.1671 6.77357 2.4453L6.71727 2.52976C6.50291 2.8513 6.493 3.26758 6.69183 3.59895L7.84115 5.5145C8.02187 5.8157 8.34738 6 8.69864 6H11.3015C11.6528 6 11.9783 5.8157 12.159 5.51449L13.3083 3.59895C13.5071 3.26758 13.4972 2.8513 13.2829 2.52976L13.2266 2.4453C13.0411 2.1671 12.7289 2 12.3945 2ZM7.60562 0C6.60256 0 5.66587 0.501303 5.10947 1.3359L5.05317 1.42036C4.41008 2.38499 4.38036 3.63382 4.97684 4.62795L6.12616 6.54349C6.66833 7.4471 7.64485 8 8.69864 8H11.3015C12.3553 8 13.3318 7.4471 13.874 6.54349L15.0233 4.62795C15.6198 3.63382 15.5901 2.38499 14.947 1.42036L14.8907 1.3359C14.3343 0.501303 13.3976 0 12.3945 0H7.60562Z" fill={color}/>
    </svg>

  );
};

export default Management;
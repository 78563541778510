import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import moment from "moment";
import {toast} from "react-toastify";
import report from "../../SVG/Report";
import {useCreateFuelReceiptReportMutation, useCreateReportMutation} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import { useErrorHandler } from '../../global/ErrorsHandler';

export default function ReportModal ({title, exit, facility, type}) {
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({
    facility_id: null,
    year: null,
    month_from: null,
    name: null,
  })
  const [months, setMonths] = useState([
    {value: 1, label: 'January'},
    {value: 2, label: 'February'},
    {value: 3, label: 'March'},
    {value: 4, label: 'April'},
    {value: 5, label: 'May'},
    {value: 6, label: 'June'},
    {value: 7, label: 'July'},
    {value: 8, label: 'August'},
    {value: 9, label: 'September'},
    {value: 10, label: 'October'},
    {value: 11, label: 'November'},
    {value: 12, label: 'December'},
  ])
  const [years, setYears] = useState([])
  const [monthError, setMonthError] = useState(false)
  const [error, setError] = useState(false)

  const [createReport, {
    data: createReportData,
    isError: createReportIsError,
    isLoading: createReportIsLoading,
    isSuccess: createReportIsSuccess,
    error: createReportError,
  }] = useCreateReportMutation()

  const [createFuelReport, {
    data: createFuelReportData,
    isError: createFuelReportIsError,
    isLoading: createFuelReportIsLoading,
    isSuccess: createFuelReportIsSuccess,
    error: createFuelReportError,
  }] = useCreateFuelReceiptReportMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([createReportError, createFuelReportError])

  function generateArrayOfYears() {
    let max = new Date().getFullYear()
    let min = 2023
    let years = []

    for (let i = max; i >= min; i--) {
      years.push({label: i, value: i})
    }
    return years
  }
  useEffect(() => {
    if (createReportIsLoading || createFuelReportIsLoading) {
      dispatch(setLoader(true))
    }
    if (createReportIsSuccess) {
      dispatch(setLoader(false))
      toast.success(createReportData?.message)
      exit()
    }
    if (createFuelReportIsSuccess) {
      dispatch(setLoader(false))
      toast.success(createFuelReportData?.message)
      exit()
    }
  }, [createReportIsLoading,
    createReportIsSuccess, createFuelReportIsLoading, createFuelReportIsSuccess])
  useEffect(() => {
    setYears(generateArrayOfYears)
  }, [])
  useEffect(() => {
    if (reportData?.month_from > reportData?.month_to) {
      if (reportData?.month_from && reportData?.month_to) {
        toast.error('"Month from" must be lower or equal to "Month to"')
      }
      setMonthError(true)
    } else {
      setMonthError(false)
    }
  }, [reportData.month_from, reportData.month_to])

  const handleChange = (e) => {
    setReportData(old => ({...old, [e.target.name]: e.target.value}))
  }
  const createReportFunc = () => {
    if (reportData?.name && reportData?.month_from && reportData?.year && reportData?.facility_id) {
      if (type === "report") {
        createReport(reportData)
      } else {
        createFuelReport(reportData)
      }
    } else {
      toast.error('Fill all the fields!')
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{title}</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="modal__content-body report">
          <Input id="name" name="name" placeholder="Report name" label={'Report name'} type={'text'} onChange={handleChange} />
          <CustomSelect name={'facility_id'} label={'Facility name'} options={facility} onChange={handleChange} />
          <CustomSelect name={'year'} label={'Year'} options={years} onChange={handleChange} />
          <CustomSelect name={'month_from'} label={'Month'} options={months} onChange={handleChange}/>
          {/*<CustomSelect name={'month_to'} label={'Month to'} options={months} onChange={handleChange} />*/}
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>Close</button>
          <button className="save" onClick={createReportFunc}>Create</button>
        </div>
      </div>
      <div className="modal__bg">
      </div>
    </div>
  );
};


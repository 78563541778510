import React, {useEffect, useState} from 'react';
import './index.scss';
import CustomSelect from "../CustomSelect";
import Input from "../Input";
import Checkbox from "../Checkbox";
import {useGetAgencyQuery, useLazyGetFacilityByAgencyQuery} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import CustomPaginate from "../PaginateComponent";
import FacilityModal from "../FacilityModal";
import {create} from "axios";
import {retry} from "@reduxjs/toolkit/query";
import {useNavigate} from "react-router-dom";

const ProfileFacility = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [warning, setWarning] = useState(false);
  const [createFacilityStatus, setCreateFacilityStatus] = useState(false);

  const [getFacilityByAgencyId,
    {
      data: getFacilityByAgencyIdIdData,
      error: getFacilityByAgencyIdError,
      isLoading: getFacilityByAgencyIdIsLoading,
      isSuccess: getFacilityByAgencyIdIsSuccess,
      isError: getFacilityByAgencyIdIsError,
    }] = useLazyGetFacilityByAgencyQuery();

  const {data, isLoading, isSuccess, isError} = useGetAgencyQuery();

  useEffect(() => {
    getFacilityByAgencyId({id: userInfo?.agency?.id, page: currentPage})
  }, [currentPage])

  const getFacility = () => {
    getFacilityByAgencyId({id: userInfo?.agency?.id, page: currentPage})
  }

  useEffect(() => {
    dispatch(setLoader(getFacilityByAgencyIdIsLoading))
  }, [getFacilityByAgencyIdIsLoading])

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {createFacilityStatus && <FacilityModal setModal={setCreateFacilityStatus} getData={getFacility} />}
      {warning &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Attention</p>
            </div>
            <div className="modal__content-body">
              <p style={{lineHeight: "24px", fontSize: 16}}>Most customers don't need additional facilities.
                If you want to add additional facilities, we ask that you contact us to confirm that an additional
                facility will meet your use case</p>
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => setWarning(false)}>
                Close
              </button>
              <button className="save" onClick={() => setWarning(false)}>
                OK
              </button>
            </div>
          </div>
          <div className="modal__bg"></div>
        </div>}
      <div className="profile__info">
        <p className="profile__title">Facility Settings</p>
        <button className="profile__info-btn" onClick={() => {
          if (data[0]?.is_able_to_add_facility_trial) {
            setCreateFacilityStatus(true)
          } else {
            setWarning(true)
          }
        }}>Create Facility</button>
      </div>
      <div className="profile__table">
        <div className="profile__table-head">
          <div className="profile__table-th">Facility name</div>
          <div className="profile__table-th">Total Inspections</div>
          <div className="profile__table-th">Inspections Enabled</div>
          <div className="profile__table-th"></div>
        </div>
        <div className="profile__table-body">
          {
            getFacilityByAgencyIdIdData?.data?.length ?
            getFacilityByAgencyIdIdData?.data?.map((item) => (
              <div className="profile__table-row" key={item?.id}>
                <div className="profile__table-bodyTh">{item?.name}</div>
                <div className="profile__table-bodyTh">{item?.links?.length}</div>
                <div className="profile__table-bodyTh">{item?.inspections_enabled ? 'Enabled' : 'Not Enabled'}</div>
                <div className="profile__table-bodyTh">
                  <div
                    onClick={() => navigate("/facility/edit/" + item.id)}
                  >
                  Edit
                  </div>
                </div>
              </div>
            ))
            :
            <div className="facility__table-empty">
              <img
              src="/assets/icons/facility-empty.svg"
              alt="empty"
              />
              <p>There are no Facilities!</p>
            </div>
          }
        </div>
      </div>
      <CustomPaginate
        pageCount={getFacilityByAgencyIdIdData?.pages}
        handlePageClick={handlePageClick}
      />
    </div>

  );
};

export default ProfileFacility;
import React from 'react';

const Profile1 = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 25C20.134 25 17 28.134 17 32V34C17 34.5523 16.5523 35 16 35C15.4477 35 15 34.5523 15 34V32C15 27.0294 19.0294 23 24 23C28.9706 23 33 27.0294 33 32V34C33 34.5523 32.5523 35 32 35C31.4477 35 31 34.5523 31 34V32C31 28.134 27.866 25 24 25Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 23C26.2091 23 28 21.2091 28 19C28 16.7909 26.2091 15 24 15C21.7909 15 20 16.7909 20 19C20 21.2091 21.7909 23 24 23ZM24 25C27.3137 25 30 22.3137 30 19C30 15.6863 27.3137 13 24 13C20.6863 13 18 15.6863 18 19C18 22.3137 20.6863 25 24 25Z" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>
  );
};

export default Profile1;
import React, { useEffect, useState } from "react";
import AddComment from "../SVG/AddComment";

const Option = ({
  id,
  img = "",
  option,
  setOption,
  removeInput,
  type,
  options,
}) => {
  const changeComment = (status) => {
    // setOption(old => [
    //   ...old.map(item => {
    //     if (item.id === id) {
    //       item.has_comment = status
    //     }
    //     return item
    //   })
    // ])

    let data = options?.map((elem) => {
      let item = { ...elem };
      if (item.id === id) {
        item.has_comment = status;
      }
      return item;
    });
    setOption(data);
  };

  const handleOptionInfoChange = (e) => {
    let data = options?.map((elem) => {
      let item = { ...elem };
      if (item?.id === id) {
        if (e.target.name === "label") {
          item.label = e.target.value;
        }
        if (e.target.name === "value") {
          item.value = e.target.value;
        }
      }
      return item;
    });
    setOption(data);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Label"
        name="label"
        id="label"
        className="customInput__label"
        defaultValue={option?.label}
        onChange={handleOptionInfoChange}
      />

      <div className="customInput__box">
        <label className={`customInput ${img ? "formBuilder" : ""}`}>
          {img && (
            <img
              style={{ top: 13 }}
              className="customInput__search"
              src={"/assets/icons/" + img}
              alt="icon"
            />
          )}

          <input
            type="text"
            name="value"
            id="value"
            placeholder={"Option " + option.id}
            defaultValue={option?.value}
            onChange={handleOptionInfoChange}
          />
        </label>
        <div
          onClick={() => {
            changeComment(!option?.has_comment);
          }}
          className={`customInput__comment ${
            option?.has_comment ? "active" : ""
          }`}
        >
          {
            type !== "checkbox" &&
            <AddComment color={option?.has_comment ? "#4268B0" : "#999999"} />
          }
        </div>
        <img
          src="/assets/icons/close.svg"
          alt="close"
          onClick={() => removeInput(type, option.id)}
        />
      </div>
    </div>
  );
};

export default Option;

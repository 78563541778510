import React from 'react';

const Monitor = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons/Monitor/Line">
        <path id="Shape" d="M9 18H11V20H13V18H15V20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H9V18Z" fill={color}/>
        <path id="Shape_2" fillRule="evenodd" clipRule="evenodd" d="M4 6L4 14C4 15.1046 4.89543 16 6 16L18 16C19.1046 16 20 15.1046 20 14L20 6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6ZM2 14C2 16.2091 3.79086 18 6 18L18 18C20.2091 18 22 16.2091 22 14L22 6C22 3.79086 20.2091 2 18 2L6 2C3.79086 2 2 3.79086 2 6L2 14Z" fill={color}/>
        <g id="Shape_3">
          <path d="M7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H14C14.5523 8 15 7.55228 15 7C15 6.44772 14.5523 6 14 6H7Z" fill={color} />
          <path d="M7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H9C9.55228 12 10 11.5523 10 11C10 10.4477 9.55228 10 9 10H7Z" fill={color}/>
        </g>
      </g>
    </svg>
  );
};

export default Monitor;
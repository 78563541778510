import React, {useEffect, useState} from "react";
import Wrapper from "../../components/Wrapper";
import Cropper from "react-cropper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import Profile1 from "../../SVG/Profile1";
import Input from "../../components/Input";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {
  useLazyGetPaymentInfoQuery,
  useSaveProfilePhotoMutation, useUpdateUserProfileMutation, useUpdateUserProfilePasswordMutation,
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";
import {useNavigate} from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import UpdatePaymentModal from "../../components/Profile/UpdatePaymentModal";
import ExtraPhonesModal from "../../components/Profile/ExtraPhonesModal";
import BillingAddressModal from "../../components/Profile/BillingAddressModal";
import CorporateRegions from "../../components/Corporate/CorporateRegions";
import LockIcon from "../../SVG/LockIcon";
import CircleLockIcon from "../../SVG/CircleLockIcon";

export default function Profile() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [cropper, setCropper] = useState();
  const [extraShow, setExtraShow] = useState(false);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const token = localStorage.getItem("ndx_user_token");
  const navigate = useNavigate();
  const changeTab = (tabId) => setCurrentTab(tabId);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [eye, setEye] = useState(false)
  const [eyeConfirm, setEyeConfirm] = useState(false)
  const [userProfile, setUserProfile] = useState({
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    ein: userInfo?.ein,
    initials: userInfo?.initials,
    notify_corrective_actions: userInfo?.notify_corrective_actions,
    notify_inspections: userInfo?.notify_inspections,
    notify_equipment: userInfo?.notify_equipment,
    notify_service_requests: userInfo?.notify_service_requests,
  })
  const [userPassword, setUserPassword] = useState({
    password: null,
    password_confirmation: null,
  })

  const [save, {data, isLoading, isError, error, isSuccess}] =
    useSaveProfilePhotoMutation();
  const [
    getPaymentInfo,
    {
      data: paymentInfoData,
      isLoading: paymentInfoIsLoading,
      isError: paymentInfoIsError,
      error: paymentInfoError,
      isSuccess: paymentInfoIsSuccess,
    },
  ] = useLazyGetPaymentInfoQuery();

  const [updateUser,
    {
      data: updateUserData,
      isLoading: updateUserIsLoading,
      isError: updateUserIsError,
      error: updateUserError,
      isSuccess: updateUserIsSuccess,
    },] = useUpdateUserProfileMutation();

  const [updateUserPassword,
    {
      data: updateUserPasswordData,
      isLoading: updateUserPasswordIsLoading,
      isError: updateUserPasswordIsError,
      error: updateUserPasswordError,
      isSuccess: updateUserPasswordIsSuccess,
    },] = useUpdateUserProfilePasswordMutation();

  useEffect(() => {
    getPaymentInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.agency?.is_settings_complete === false) {
      changeTab(7)
    }
  }, [userInfo])

  useEffect(() => {
    if (updateUserIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (updateUserIsSuccess) {
      toast.success(updateUserData.message);
    }
    if (updateUserIsError) {
      toast.error(updateUserError?.data?.message);
    }

  }, [ updateUserIsLoading, updateUserIsSuccess, updateUserIsError, updateUserError]);

  useEffect(() => {
    if (updateUserPasswordIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (updateUserPasswordIsSuccess) {
      toast.success(updateUserPasswordData.message);
    }
    if (updateUserPasswordIsError) {
      toast.error(updateUserPasswordError?.data?.message);
    }

  }, [ updateUserPasswordIsLoading, updateUserPasswordIsSuccess, updateUserPasswordIsError, updateUserPasswordError]);


  useEffect(() => {
    if (isLoading ) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (isSuccess) {
      toast.success(data?.message);
    }
    if (isError) {
      toast.error(error?.data?.message);
    }

  }, [isLoading, isSuccess, isError, data, error]);

  useEffect(() => {
    if (typeof chosenPhoto === "string") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [chosenPhoto]);

  useEffect(() => {
    setUserProfile(old =>
      ({
        ...old,
        first_name: userInfo?.first_name,
        initials: userInfo?.initials,
        ein: userInfo?.ein,
        last_name: userInfo?.last_name,
        notify_corrective_actions: userInfo?.notify_corrective_actions,
        notify_inspections: userInfo?.notify_inspections,
        notify_equipment: userInfo?.notify_equipment,
        notify_service_requests: userInfo?.notify_service_requests
      }))
  }, [userInfo, currentTab])

  const handleNotify = (e) => {
    setUserProfile(old => ({...old, [e.target.id]: e.target.checked}))
  }

  const handleUserChange = (e) => {
    setUserProfile(old => ({...old, [e.target.name]: e.target.value}))
  }
  const handlePasswordChange = (e) => {
    setUserPassword(old => ({...old, [e.target.name]: e.target.value}))
  }

  const cropperHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChosenPhoto(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const form = new FormData();
      form.append(
        "profile_photo",
        base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      );
      save(form)
        .then(() => {
          setShowModal(false);
          let config = {
            headers: {
              Authorization: "Bearer " + token,
              DeviceType: "web",
              AppType: "web",
              AppVersion: "1.0.0",
            },
          };
          axios
            .get(`${process.env.REACT_APP_API_URL}user`, config)
            .then((res) => {
              dispatch(setUser(res.data));
            })
            .catch((err) => {
              dispatch(setLoader(false));
              dispatch(setUser(null));
              navigate("/login");
              toast.error(err.response.data.message);
            });
        })
        .catch((err) => {
          toast.error("Something went wrong, try another photo");
        });
    }
  };

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Profile | NDX</title>
      </Helmet>
      <DashboardHeader title={"Profile"}/>
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            // onClick={() => {
            //   setShowModal(false);
            //   setChosenPhoto(null);
            // }}
          ></div>
        </div>
      )}
      {showPaymentModal && (
        <UpdatePaymentModal
          setShowPaymentModal={setShowPaymentModal}
          getUserIndex={getUserIndex}
          getPaymentInfo={getPaymentInfo}
        />
      )}
      {showBillingModal && (
        <BillingAddressModal
          setShowPaymentModal={setShowBillingModal}
          getUserIndex={getUserIndex}
        />
      )}
      {
        showShippingModal && (
        <BillingAddressModal
          setShowPaymentModal={setShowShippingModal}
          getUserIndex={getUserIndex}
        />
        )}
      {showDeleteModal && (
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Delete card</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setShowDeleteModal(false)}
              />
            </div>
            <div
              className="modal__content-body"
              style={{display: "flex", justifyContent: "center"}}
            >
              Are you sure want to remove the card?
            </div>
            <div className="modal__content-bottom">
              <button
                className="close"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className="save danger">Delete</button>
            </div>
          </div>
          <div
            className="modal__bg"
          ></div>
        </div>
      )}
      {extraShow && (
        <ExtraPhonesModal
          setShowExtra={setExtraShow}
          setShowPaymentModal={setShowPaymentModal}
          setShowBillingModal={setShowBillingModal}
          setShowShippingModal={setShowShippingModal}
        />
      )}
      <div className="profile">
        <div className="profile__tabs">
          {
            userInfo?.is_corporate ? (
                <>
                  <div
                    className={`profile__tab ${currentTab === 0 ? "active" : ""}`}
                    onClick={() => {
                      setCurrentOrder(null);
                      changeTab(0);
                    }}
                  >
                    <Profile1 active={currentTab === 0 && true}/>
                    <p className="profile__tab-name">Personal Information</p>
                  </div>
                  <div
                    className={`profile__tab ${currentTab === 1 ? "active" : ""}`}
                    onClick={() => {
                      setCurrentOrder(null);
                      changeTab(1);
                    }}
                  >
                    <CircleLockIcon active={currentTab === 1 && true}/>
                    <p className="profile__tab-name">Update password</p>
                  </div>
                </>
              )
              :
            !userInfo?.agency?.is_settings_complete ? null :
              (
                <>
                  <div
                    className={`profile__tab ${currentTab === 0 ? "active" : ""}`}
                    onClick={() => {
                      setCurrentOrder(null);
                      changeTab(0);
                    }}
                  >
                    <Profile1 active={currentTab === 0 && true}/>
                    <p className="profile__tab-name">Personal Information</p>
                  </div>
                  <div
                    className={`profile__tab ${currentTab === 1 ? "active" : ""}`}
                    onClick={() => {
                      setCurrentOrder(null);
                      changeTab(1);
                    }}
                  >
                    <CircleLockIcon active={currentTab === 1 && true}/>
                    <p className="profile__tab-name">Update password</p>
                  </div>
                </>
              )

          }
          {/*<div*/}
          {/*  className={`profile__tab ${currentTab === 1 ? "active" : ""}`}*/}
          {/*  onClick={() => {*/}
          {/*    setCurrentOrder(null);*/}
          {/*    changeTab(1);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Profile1 active={currentTab === 1 && true}/>*/}
          {/*  <p className="profile__tab-name">Regions Settings</p>*/}
          {/*</div>*/}
        </div>
        <div className={`profile__box ${currentTab === 2 ? 'regions' : ''}`}>
          {currentTab === 0 ? (
            <div>
              <div className="profile__info column">
                <p className="profile__title">Personal Information</p>
                <div className="profile__imageBox">
                  <img
                    className="profile__avatar"
                    src={userInfo?.avatar}
                    alt="avatar"
                  />
                  <div>
                    <p className="profile__imageBox-desc">
                      We only support .JPG, .JPEG, or .PNG file.
                    </p>
                    <label
                      htmlFor="profile_photo"
                      className="profile__imageBox-label"
                    >
                      <input
                        type="file"
                        name="profile_photo"
                        id="profile_photo"
                        onChange={cropperHandler}
                        accept="image/jpeg, image/png, image/jpg"
                      />
                      Upload photo
                    </label>
                  </div>
                </div>
              </div>
              <form className="profile__form">
                <div className="profile__form-top">
                  <div className="profile__form-separator">
                    <div className="profile__form-input">
                      <Input
                        label="First name"
                        placeholder={"First name"}
                        name={"first_name"}
                        defaultValue={userInfo?.first_name ? userInfo?.first_name : ""}
                        onChange={handleUserChange}
                        containerWidth={500}
                      />
                    </div>
                    <div className="profile__form-input">
                      <Input
                        label="Last name"
                        placeholder={"Last name"}
                        name={"last_name"}
                        defaultValue={userInfo?.last_name ? userInfo?.last_name : ""}
                        onChange={handleUserChange}
                        containerWidth={500}
                      />
                    </div>
                    <div className="profile__form-input">
                      <Input
                        label="Email"
                        type={"email"}
                        placeholder={"Email"}
                        value={userInfo?.email}
                        containerWidth={500}
                      />
                    </div>
                    <div className="profile__form-input">
                      <Input
                        label="Ein"
                        name={"ein"}
                        onChange={handleUserChange}
                        placeholder={"Ein"}
                        defaultValue={userInfo?.ein ? userInfo?.ein : ""}
                        containerWidth={500}
                      />
                    </div>
                    <div className="profile__form-input">
                      <Input
                        label="Initials"
                        name={"initials"}
                        placeholder={"Initials"}
                        onChange={handleUserChange}
                        defaultValue={userInfo?.initials ? userInfo?.initials : ""}
                        containerWidth={500}
                      />
                    </div>
                  </div>
                </div>
              </form>
              {
                (userInfo?.is_admin || userInfo?.is_inspector) &&
                <div className="profile__notification">
                  <div className="facility-modal__field">
                    <p className="profile__title">Notifications</p>
                    <Checkbox
                      onChange={handleNotify}
                      label={"Notify Corrective Actions"}
                      id={"notify_corrective_actions"}
                      checked={userProfile?.notify_corrective_actions}
                    />
                  </div>
                  <div className="facility-modal__field">
                    <Checkbox
                      onChange={handleNotify}
                      label={"Notify Equipment"}
                      id={"notify_equipment"}
                      checked={userProfile?.notify_equipment}
                    />
                  </div>
                  <div className="facility-modal__field">
                    <Checkbox
                      onChange={handleNotify}
                      label={"Notify Inspections"}
                      id={"notify_inspections"}
                      checked={userProfile?.notify_inspections}
                    />
                  </div>
                  <div className="facility-modal__field">
                    <Checkbox
                      onChange={handleNotify}
                      label={"Notify Observation"}
                      id={"notify_service_requests"}
                      checked={userProfile?.notify_service_requests}
                    />
                  </div>
                </div>
              }
              <button className="profile__methods-btn" onClick={() => updateUser(userProfile)}>Save</button>
            </div>
          ) : currentTab === 1 ? (
            <div>
              <form className="profile__form" style={{margin: '0 0 30px'}}>
                <div className="profile__form-top">
                  <div className="profile__form-password">
                    <p>Update password</p>
                    <div className="profile__form-input">
                      <Input
                        label="Password"
                        type={eye ? "text" : "password"}
                        placeholder={"Password"}
                        name={'password'}
                        onChange={handlePasswordChange}
                        containerWidth={500}
                      />
                      <img
                        className="eye"
                        src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                        alt="eye"
                        onClick={() => {
                          setEye(!eye);
                        }}
                      />
                    </div>
                    <div className="profile__form-input">
                      <Input
                        label="Confirm Password"
                        type={eyeConfirm ? "text" : "password"}
                        placeholder={"Confirm Password"}
                        name={'password_confirmation'}
                        onChange={handlePasswordChange}
                        containerWidth={500}
                      />
                      <img
                        className="eye"
                        src={eyeConfirm ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                        alt="eye"
                        onClick={() => {
                          setEyeConfirm(!eyeConfirm);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <button className="profile__methods-btn" onClick={() => updateUserPassword(userPassword)}>Save</button>
            </div>
          ) : currentTab === 2 ? (
              <CorporateRegions />
              )
            : null}
        </div>
      </div>
    </Wrapper>
  );
}

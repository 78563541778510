import React, {useEffect, useRef, useState} from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import "./index.scss";
import Input from "../../components/Input";
import Select from "react-select";
import Datepicker from "../../components/Datepicker";
import {
  useClearInspectionsMutation,
  useGetEquipmentTypeQuery, useGetPeriodsQuery, useGetUsersByAgencyQuery,
  useGetUsersQuery, useLazyGetFacilityQuery,
  useLazyGetSheetsQuery,
} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import moment from "moment";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {useNavigate} from "react-router-dom";
import CustomPaginate from "../../components/PaginateComponent";
import {Helmet} from "react-helmet";
import {useDebounce, useFocus} from "./hooks";
import {toast} from "react-toastify";

export default function LogSheets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [disableSearch, setDisableSearch] = useState(false);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersOptions, setUsersOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [statusOptions, setStatusOptions] = useState([
    {
      value: 'new',
      label: 'New'
    },
    {
      value: 'half',
      label: 'Half-Done'
    },
    {
      value: 'done',
      label: 'Completed'
    },
  ])
  const [selectedOption, setSelectedOption] = useState(urlParams.get('status') ? statusOptions?.filter(item => item?.value == urlParams.get('status'))[0] : {
    value: 'done',
    label: 'Completed'
  });
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [search, setSearch] = useState("");
  const [showBtn, setShowBtn] = useState(false)
  const [getSheets, {data, isFetching, isSuccess, isError, error}] =
    useLazyGetSheetsQuery();
  const {
    data: equipmentTypeList,
    error: equipmentTypeError,
    isLoading: equipmentTypeLoading,
  } = useGetEquipmentTypeQuery();

  const {
    data: usersList,
    error: usersError,
    isLoading: usersLoading,
  } = useGetUsersByAgencyQuery();

  const [getFacility, {
    data: facilityList,
    error: facilityError,
    isLoading: facilityLoading,
  }] = useLazyGetFacilityQuery();

  const {
    data: periodsList,
    error: periodsError,
    isLoading: periodsLoading,
  } = useGetPeriodsQuery();

  const [removeInspections, {
    data: clearInspectionData,
    isFetching: clearInspectionIsLoading,
    isSuccess: clearInspectionIsSuccess,
    isError: clearInspectionIsError,
    error: clearInspectionError
  }] = useClearInspectionsMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([error,clearInspectionError]);
  const debouncedSearchValue = useDebounce(search, 500);

  useEffect(() => {
    if (confirmModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [confirmModal])

  useEffect(() => {
    getFacility()
    getSheets({page: currentPage});
  }, []);
  useEffect(() => {
    setFacilityOptions(facilityList?.map(item => ({value: item.id, label: item.name})))
  }, [facilityList])

  useEffect(() => {
    if (usersList) {
      let s = usersList?.map((item) => {
        return {value: item?.id, label: item.name};
      });
      setUsersOptions(s);
    }
  }, [usersList]);

  useEffect(() => {
    if (equipmentTypeList) {
      let s = equipmentTypeList?.map((item) => {
        return {value: item?.id, label: item?.title};
      });
      setEquipmentTypeOptions(s);
    }
  }, [equipmentTypeList]);

  useEffect(() => {
    if (equipmentTypeLoading || isFetching || usersLoading || facilityLoading || clearInspectionIsLoading) {
      setDisableSearch(true);
      dispatch(setLoader(true));
    } else {
      setDisableSearch(false);
      dispatch(setLoader(false));
    }
  }, [isFetching, isSuccess, equipmentTypeLoading, usersLoading, facilityLoading, clearInspectionIsLoading]);

  useEffect(() => {
    if (urlParams.get('facility_id')) {
      setSelectedFacility(facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0])
    }
    if (urlParams.get('user_id')) {
      setSelectedUser(usersOptions?.filter(item => item?.value == urlParams.get('user_id'))[0])
    }
    if (urlParams.get('equipment_type_id')) {
      setSelectedEquipment(equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_type_id'))[0])
    }
    if (urlParams.get('period')) {
      setSelectedPeriod(periodsList?.data?.filter(item => item?.value == urlParams.get('period'))[0])
    }
    if (urlParams.get('status')) {
      setSelectedOption(statusOptions?.filter(item => item?.value == urlParams.get('status'))[0])
    }
    if (urlParams.get('date_from')) {
      setDateFrom(urlParams.get('date_from'))
    }
    if (urlParams.get('date_to')) {
      setDateTo(urlParams.get('date_to'))
    }
  }, [urlParams])

  useEffect(() => {
    getSheets({
      page: 1,
      search: debouncedSearchValue,
      equipment_type_id: selectedEquipment?.value
        ? selectedEquipment?.value
        : null,
      user_id: selectedUser?.value ? selectedUser?.value : null,
      date_from: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
      date_to: dateTo ? moment(dateTo).format("YYYY-MM-DD") : null,
      facility_id: selectedFacility?.value ? selectedFacility?.value : null,
      status: selectedOption?.value ? selectedOption?.value : null,
      period: selectedPeriod?.value ? selectedPeriod?.value : null,
    })
  }, [selectedEquipment, selectedUser, dateFrom, dateTo, selectedFacility, selectedOption, selectedPeriod, debouncedSearchValue]);

  useEffect(() => {
    setShowBtn(() => {
      if (search || dateFrom || dateTo || selectedEquipment || selectedFacility || selectedPeriod || selectedUser) {
        return true
      } else {
        return false
      }
    })
  }, [search,
    dateFrom,
    dateTo,
    selectedEquipment,
    selectedFacility,
    selectedPeriod,
    selectedUser])

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page?.selected + 1);
    getSheets({
      page: page?.selected + 1,
      search: debouncedSearchValue,
      equipment_type_id: selectedEquipment?.value
        ? selectedEquipment?.value
        : null,
      user_id: selectedUser?.value ? selectedUser?.value : null,
      date_from: dateFrom ? moment(dateFrom)?.format("YYYY-MM-DD") : null,
      date_to: dateTo ? moment(dateTo)?.format("YYYY-MM-DD") : null,
      facility_id: selectedFacility?.value ? selectedFacility?.value : null,
      status: selectedOption?.value ? selectedOption?.value : null,
      period: selectedPeriod?.value ? selectedPeriod?.value : null,
    });
    window.scrollTo(0, 0);
  };

  const removeInspection = () => {
    removeInspections({
      page: currentPage,
      search: debouncedSearchValue,
      equipment_type_id: selectedEquipment?.value
        ? selectedEquipment?.value
        : null,
      user_id: selectedUser?.value ? selectedUser?.value : null,
      date_from: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
      date_to: dateTo ? moment(dateTo).format("YYYY-MM-DD") : null,
      facility_id: selectedFacility?.value ? selectedFacility?.value : null,
      status: selectedOption?.value ? selectedOption?.value : null,
      period: selectedPeriod?.value ? selectedPeriod?.value : null})
      .then(res => {
        toast.success(res.data.message)
        setConfirmModal(false)
        navigate('/log-sheets')
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
  }

  const renderConfirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => {
                setConfirmModal(false)
              }}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure? It will delete filtered inspections
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setConfirmModal(false)
            }}>
              Close
            </button>
            <button className="save danger"
                    onClick={removeInspection}
            >
              Yes
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }
  return (
    <Wrapper>
      <Helmet>
        <title>Log Sheets | NDX</title>
      </Helmet>
      {
        confirmModal && renderConfirmModal()
      }
      <DashboardHeader
        title={"Log Sheets"}
        type="logSheets"
        action={() => setConfirmModal(true)}
        showBtn={showBtn}
      />
      <div className="sheets">
        <div className="sheets__header">
          <Input
            image={"/assets/icons/search.svg"}
            placeholder="Name, Eq, Facility, Completed by"
            maxwidth={200}
            onChange={handleSearch}
            // disabled={disableSearch}
            innerRef={inputRef}
          />
          <Datepicker
            keys={dateFrom}
            type={"from"}
            width={160}
            setValue={(e) => {
              setDateFrom(e)
              navigate(`/log-sheets?${e ? `&date_from=${moment(e).format("YYYY-MM-DD")}` : ''}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ''}${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            value={dateFrom}
          />
          <div style={{width: 20}}></div>
          <Datepicker
            keys={dateTo}
            type={"to"}
            width={160}
            setValue={(e) => {
              setDateTo(e)
              navigate(`/log-sheets?${e ? `&date_to=${moment(e).format("YYYY-MM-DD")}` : ''}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ''}${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            value={dateTo}
          />
          <div style={{width: 20}}></div>
          <Select
            placeholder="Equipment type"
            options={equipmentTypeOptions}
            isClearable
            value={selectedEquipment}
            key={JSON.stringify(equipmentTypeOptions) + "1"}
            onChange={(selected) => {
              setSelectedEquipment(selected);
              navigate(`/log-sheets?${selected?.value ? `&equipment_type_id=${selected?.value}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            // defaultValue={equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_type_id'))[0]}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 200,
                height: 42,
                backgroundColor: "#F4F5F6",
              }),
            }}
          />
          <Select
            placeholder="Submitted by"
            options={usersOptions}
            isClearable
            value={selectedUser}
            onChange={(selected) => {
              setSelectedUser(selected);
              navigate(`/log-sheets?${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${selected?.value ? `&user_id=${selected?.value}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            key={usersOptions}
            // defaultValue={usersOptions?.filter(item => item?.value == urlParams.get('user_id'))[0]}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 200,
                height: 42,
                backgroundColor: "#F4F5F6",
                marginLeft: 20,
              }),
            }}
          />
          <Select
            placeholder="Facility"
            options={facilityOptions}
            isClearable
            value={selectedFacility}
            onChange={(selected) => {
              setSelectedFacility(selected);
              navigate(`/log-sheets?${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${selected?.value ? `&facility_id=${selected?.value}` : ''}`)
            }}
            key={JSON.stringify(facilityOptions)}
            // defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 150,
                height: 42,
                backgroundColor: "#F4F5F6",
                marginLeft: 20,
              }),
            }}
          />
          <Select
            placeholder="Status"
            options={statusOptions}
            isClearable
            onChange={(selected) => {
              setSelectedOption(selected);
              navigate(`/log-sheets?${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${selected?.value ? `&status=${selected?.value}` : ''}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            defaultValue={urlParams.get('status') ? statusOptions?.filter(item => item?.value == urlParams.get('status'))[0] : {
              value: 'done',
              label: 'Completed'
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 150,
                height: 42,
                backgroundColor: "#F4F5F6",
                marginLeft: 20,
              }),
            }}
          />
          <Select
            placeholder="Period"
            options={periodsList?.data}
            isClearable
            value={selectedPeriod}
            onChange={(selected) => {
              setSelectedPeriod(selected);
              navigate(`/log-sheets?${urlParams.get('equipment_type_id') ? `&equipment_type_id=${urlParams.get('equipment_type_id')}` : ''}${urlParams.get('user_id') ? `&user_id=${urlParams.get('user_id')}` : ''}${urlParams.get('status') ? `&status=${urlParams.get('status')}` : ''}${selected?.value ? `&period=${selected?.value}` : ''}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ''}`)
            }}
            key={JSON.stringify(facilityOptions) + "2"}
            // defaultValue={periodsList?.data?.filter(item => item?.value == urlParams.get('period'))[0]}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 150,
                height: 42,
                backgroundColor: "#F4F5F6",
                marginLeft: 20,
              }),
            }}
          />
        </div>
        <div className="sheets__box">
          <div className="sheets__table">
            <div className="sheets__table-head">
              <div className="sheets__table-th">Name</div>
              <div className="sheets__table-th">Inspection Date</div>
              <div className="sheets__table-th">Completed date</div>
              <div className="sheets__table-th">Equipment</div>
              <div className="sheets__table-th">Facility</div>
              <div className="sheets__table-th">Period</div>
              <div className="sheets__table-th">Completed</div>
              <div className="sheets__table-th">Completed by</div>
            </div>
            <div className="sheets__table-body">
              {data?.data?.length ? data?.data?.map((sheet) => (
                <div
                  className="sheets__table-row"
                  key={sheet.id}
                  onClick={() => {
                    navigate(`/inspections/${sheet?.id}`, {
                      state: {sheet: true},
                    });
                  }}
                >
                  <div className="sheets__table-bodyTh">{sheet.name}</div>

                  <div className="sheets__table-bodyTh">
                    {sheet.inspection_date
                        ? moment(sheet.inspection_date).format("MM/DD/YY")
                        : "---"}
                  </div>
                  <div className="sheets__table-bodyTh">
                    {sheet.completed_date
                      ? moment(sheet.completed_date).format("MM/DD/YY HH:mm")
                      : "---"}
                  </div>
                  <div className="sheets__table-bodyTh">
                    {sheet.equipment_title ? sheet.equipment_title : "---"}
                  </div>
                  <div className="sheets__table-bodyTh">
                    {sheet.facility_title ? sheet.facility_title : "---"}
                  </div>
                  <div
                    className="sheets__table-bodyTh">{sheet.period.charAt(0).toUpperCase() + sheet.period.slice(1)}</div>
                  <div className={`sheets__table-bodyTh ${sheet.is_complete ? "completed" : "not_completed"}`}>
                    <span>
                    {sheet.is_complete ? "Completed" : "Not completed"}
                    </span>
                  </div>
                  <div className="sheets__table-bodyTh">
                    {sheet.submitted_by_name}
                  </div>
                </div>
              )) : (
                <div className='emptyList'>
                  <img src="/assets/empty-list.png" alt="empty" />
                  <p>No log sheets data yet</p>
                </div>
              )}
            </div>
          </div>
          <CustomPaginate
            pageCount={data?.pages}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
    </Wrapper>
  );
}

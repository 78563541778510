import React from 'react';

const MapIcon = ({color = "#999999"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 5.77485L10 4.44152V18.2251L14 19.5585V5.77485ZM16 5.87745V19.8368L19.1414 19.3881C19.6341 19.3177 20 18.8958 20 18.3981V6.45903C20 5.85048 19.461 5.38302 18.8586 5.46908L16 5.87745ZM4.85858 4.61194L8 4.16316V18.1226L5.14142 18.5309C4.53899 18.617 4 18.1495 4 17.541V5.60189C4 5.10423 4.36593 4.68231 4.85858 4.61194ZM15 22L9 20L5.42426 20.5108C3.61696 20.769 2 19.3666 2 17.541V5.60189C2 4.10894 3.09779 2.84317 4.57573 2.63204L9 2L15 4L18.5757 3.48918C20.383 3.23099 22 4.63338 22 6.45903V18.3981C22 19.8911 20.9022 21.1568 19.4243 21.368L15 22Z" fill={color}/>
    </svg>
  );
};

export default MapIcon;
import React, {useEffect} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import {useGetAssignedQuery} from "../../store/builder/inspections.api";
import './index.scss';
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";

export default function AssignedInspections () {
  const dispatch = useDispatch();
  const {data, isError, isLoading, isSuccess, error} = useGetAssignedQuery();

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [isLoading, isSuccess])
  return (
    <Wrapper>
      <DashboardHeader title={'Assigned Inspections'} type={'assigned'} />
      <div className="assigned">
          {
            data?.form_groups?.map((category) => (
              <div className="assigned__category" key={category?.key}>
                <p className="assigned__category-name">{category?.name}</p>
                <div className="assigned__forms">
                  {category?.forms?.map((form, index) => (
                    <div key={form?.id} className="assigned__form">
                      <p className="assigned__form-name">{form.name}</p>
                      <div className="assigned__form-box" style={index === category?.forms.length - 1 ? {borderBottom: 'none'} : null}>
                        {
                          form?.links.length > 0 ?
                          form?.links?.map(link => (
                            <div className="assigned__link" key={link.id}>
                              <p className="assigned__link-name">{link?.name}</p>
                            </div>
                          ))
                            :
                            "---"
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          }
      </div>

    </Wrapper>
  );
};


import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import {useAssignFboWithCorporateMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {toast} from "react-toastify";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";

const AssignWithCorporate = ({exit, currentItem, corporateData, getAgencies}) => {
  const dispatch = useDispatch();
  const [chosenCorporate, setChosenCorporate] = useState(null)

  const [assignCorporate, {
    data: assignCorporateData,
    error: assignCorporateError,
    isLoading: assignCorporateIsLoading,
    isSuccess: assignCorporateIsSuccess,
    isError: assignCorporateIsError,
  }] = useAssignFboWithCorporateMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([assignCorporateError,])

  useEffect(() => {
    if (assignCorporateIsLoading) {
      dispatch(setLoader(true))
    }
    if (assignCorporateIsSuccess) {
      dispatch(setLoader(false))
      toast.success("Successfully assigned with FBO")
      getAgencies()
      exit();
    }
  }, [assignCorporateIsLoading, assignCorporateIsSuccess])

  const handleSubmit = () => {
    if (chosenCorporate) {
      assignCorporate({
          agency_id: currentItem.id,
          corporate_id: chosenCorporate
      })
    } else {
      setChosenCorporate(null)
      toast.error('Choose corporate to assign')
    }
  }

  return (
    <div className="modal modal-fbo">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Assign with Corporate</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <Input
            label={"FBO"}
            name={'fbo_id'}
            id={'fbo_id'}
            placeholder={currentItem?.title}
            containerWidth={"100%"}
            readOnly={true}
          />
          <CustomSelect
            label={"Corporate"}
            options={corporateData?.map(item => ({label: item?.title, value: item?.id}))}
            name={"corporate_id"}
            onChange={(e) => setChosenCorporate(e.target.value)}
          />
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Close
          </button>
          <button className="save" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default AssignWithCorporate;
import React, {useEffect, useRef, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from '../../components/DashboardHeader';
import './index.scss'
import Input from "../../components/Input";
import Select from "react-select";
import {
  useLazyGetFacilityQuery,
  useLazyGetCorrectiveActionsQuery,
  useLazyGetMissedInspectionsQuery,
  useUpdateMissedInspectionMutation,
  useLazyGetOutOfServiceQuery,
  useLazyGetEquipmentAllQuery,
  useLazyGetEquipmentQuery,
  useLazyGetReturnToServiceQuery,
  useLazyGetEquipmentTypeQuery
} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {clearFields, setFields, setLoader} from "../../store/builder/reducer";
import CorrectiveActionRow from "../../components/CorrectiveActionRow";
import moment from "moment";
import CustomSelect from "../../components/CustomSelect";
import Datepicker from "../../components/Datepicker";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {useErrorHandler} from '../../global/ErrorsHandler';
import CustomPaginate from "../../components/PaginateComponent";
import {Helmet} from 'react-helmet';
import CorrectiveActionModal from "../../components/CorrectiveActionModal";
import {useNavigate} from "react-router-dom";

export default function CorrectiveActions() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(urlParams.get("tab_id") ? Number(urlParams.get("tab_id")) : 0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentMissedIns, setCurrentMissedIns] = useState(null)
  const [updateModal, setUpdateModal] = useState(false)
  const [modalStatus, setModalStatus] = useState(false);
  const [serviceModalStatus, setServiceModalStatus] = useState(false)
  const [actionsFilters, setActionsFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
  })

  const [search, setSearch] = useState('')
  const [updateInspectionData, setUpdateInspectionData] = useState({
    explanation: null,
    completed_date: '',
  })
  const [chosenCorrectiveAction, setChosenCorrectiveAction] = useState({})
  const [completedOptions, setCompletedOptions] = useState([
    {
      value: 1,
      label: 'Yes'
    },
    {
      value: 0,
      label: 'No'
    },
  ])
  const [missedInspectionsFilters, setMissedInspectionsFilters] = useState({
    date_from: null,
    date_to: null,
    completed: null,
    facility_id: null,
    equipment_type_id: null,
    period: null,
  })
  const [gracePeriodFilters, setGracePeriodFilters] = useState({
    date_from: null,
    date_to: null,
    completed: null,
    facility_id: null,
    equipment_type_id: null,
    period: null,
  })
  const [currentService, setCurrentService] = useState(null)
  const [facilityOptions, setFacilityOptions] = useState([])
  const [equipmentOptions, setEquipmentOptions] = useState([])
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([])
  const [periodOptions, setPeriodOptions] = useState([
    {
      label: "Daily",
      value: 'daily'
    },
    {
      label: "Weekly",
      value: 'weekly'
    }
  ])
  const [returnToServicePeriod, setReturnToServicePeriod] = useState([
    // {value: 'daily', label: "Daily"},
    // {value: "weekly", label: "Weekly"},
    {value: "monthly", label: "Monthly"},
    {value: "quarterly", label: "Quarterly"},
    // {value: "semiannual", label: "Semiannual"},
    {value: "annual", label: "Annual"},
  ])
  const [
    getActions,
    {
      data: actionsData,
      isError: actionsIsError,
      isSuccess: actionsIsSuccess,
      isFetching: actionsIsLoading,
      error: actionsError
    }
  ] = useLazyGetCorrectiveActionsQuery();
  const [getOutOfService, {
    data: outOfServiceData,
    error: outOfServiceError,
    isLoading: outOfServiceIsLoading,
    isSuccess: outOfServiceIsSuccess,
    isError: outOfServiceISError
  }] = useLazyGetOutOfServiceQuery()
  const [getMissedInspections,
    {
      data: getMissedInspectionsData,
      isError: getMissedInspectionsIsError,
      isSuccess: getMissedInspectionsIsSuccess,
      isLoading: getMissedInspectionsIsLoading,
      error: getMissedInspectionsError
    }
  ] = useLazyGetMissedInspectionsQuery();
  const [getReturnService,
    {
      data: getReturnServiceData,
      isError: getReturnServiceIsError,
      isSuccess: getReturnServiceIsSuccess,
      isLoading: getReturnServiceIsLoading,
      error: getReturnServiceError
    }
  ] = useLazyGetReturnToServiceQuery();

  const [getFacility, {
    data: facilityData,
    isError: facilityIsError,
    isSuccess: facilityIsSuccess,
    isLoading: facilityIsLoading,
    error: facilityError
  }] = useLazyGetFacilityQuery();
  const [
    updateInspection,
    {
      data: inspectionData,
      isError: inspectionIsError,
      isSuccess: inspectionIsSuccess,
      isFetching: inspectionIsLoading,
      error: inspectionError
    }
  ] = useUpdateMissedInspectionMutation();

  const [getEquipment, {data: equipmentData,
    isError: equipmentIsError,
    isSuccess: equipmentIsSuccess,
    isFetching: equipmentIsLoading,
    error: equipmentError}] = useLazyGetEquipmentAllQuery();
  const [getEquipmentType, {data: equipmentTypeData,isError: equipmentTypeIsError,
    isSuccess: equipmentTypeIsSuccess,
    isFetching: equipmentTypeIsLoading,
    error: equipmentTypeError }] = useLazyGetEquipmentTypeQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([inspectionError, getMissedInspectionsError, actionsError, equipmentTypeError])
  useEffect(() => {
    getFacility()
    getOutOfService()
    getEquipment({active: true})
    getEquipmentType()
  }, [])
  useEffect(() => {
    if (facilityData) {
      setFacilityOptions(facilityData?.map(item => ({value: item.id, label: item.name})))
    }
    if (equipmentData) {
      setEquipmentOptions(equipmentData?.data?.map(item => ({value: item.id, label: item.title})))
    }
    if (equipmentTypeData) {
      setEquipmentTypeOptions(equipmentTypeData?.map(item => ({value: item.id, label: item.title})))
    }
  }, [facilityData, equipmentData, equipmentTypeData])
  useEffect(() => {
    setActionsFilters(old => ({
      ...old,
      facility_id: urlParams.get('facility_id'),
      equipment_id: urlParams.get('equipment_id'),
      date_from: urlParams.get('date_from'),
      date_to: urlParams.get('date_to'),
    }))

    setMissedInspectionsFilters(old => ({
      ...old,
      date_from: urlParams.get('date_missed_from'),
      date_to: urlParams.get('date_missed_to'),
      facility_id: urlParams.get('facility_id'),
      equipment_type_id: urlParams.get('equipment_id'),
      period: urlParams.get('period'),
    }))
    setGracePeriodFilters(old => ({
      ...old,
      date_from: urlParams.get('date_grace_from'),
      date_to: urlParams.get('date_grace_to'),
      facility_id: urlParams.get('facility_id'),
      equipment_type_id: urlParams.get('equipment_id'),
      period: urlParams.get('period'),
    }))
  }, [])
  useEffect(() => {
    if (!updateModal) {
      setUpdateInspectionData({
        explanation: null,
        completed_date: '',
      })
    } else {
      if (currentMissedIns?.explanation) {
        setUpdateInspectionData(old => ({...old, completed_date: currentMissedIns?.completed_date}))
      }
    }
  }, [updateModal, currentMissedIns])
  useEffect(() => {
    if (currentTab === 3) {
      setActionsFilters({
        date_from: null,
        date_to: null,
        facility_id: null,
        equipment_id: null,
      })
      // setSearch('')
    }
    if (currentTab === 0 || currentTab === 1) {
      setMissedInspectionsFilters({
        completed: null,
        date_from: null,
        date_to: null,
        facility_id: null,
        equipment_type_id: null,
        period: null,
      })
      if (currentTab === 6) {
        setGracePeriodFilters({
          completed: null,
          date_from: null,
          date_to: null,
          facility_id: null,
          equipment_type_id: null,
          period: null,
        })
      }
      // setSearch('')
    }
    // setSearch('')
  }, [currentTab])
  useEffect(() => {
    if (actionsIsLoading || getMissedInspectionsIsLoading || getReturnServiceIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [actionsIsLoading, currentTab, getMissedInspectionsIsLoading, getReturnServiceIsLoading])

  useEffect(() => {
    if (inspectionIsLoading) {
      dispatch(setLoader(true))
    }
    if (inspectionIsSuccess) {
      dispatch(setLoader(false))
      toast.success(inspectionData.message)
      setUpdateModal(false)
    }
  }, [inspectionIsSuccess, inspectionIsLoading])

  useEffect(() => {
    dispatch(clearFields([]))
    if (currentTab === 0) {
      getActions({closed: false, page: 1})
    }
    if (currentTab === 1) {
      getActions({closed: true, page: 1})
    }
    if (currentTab === 3) {
      getMissedInspections({page: 1, tab_name: 'missed'})
    }
    if (currentTab === 6) {
      getMissedInspections({page: 1, tab_name: 'grace'})
    }
    if (currentTab === 5) {
      getReturnService({page: 1})
    }
  }, [currentTab])

  useEffect(() => {
    if (currentTab === 0) {
      getActions({
        closed: false,
        facility_id: actionsFilters?.facility_id,
        equipment_id: actionsFilters?.equipment_id,
        date_from: actionsFilters.date_from,
        date_to: actionsFilters.date_to,
        search: search,
        page: currentPage,
      })
    }
    if (currentTab === 1) {
      getActions({
        closed: true,
        facility_id: actionsFilters?.facility_id,
        equipment_id: actionsFilters?.equipment_id,
        date_from: actionsFilters.date_from,
        date_to: actionsFilters.date_to,
        search: search,
        page: currentPage,
      })
    }
    if (currentTab === 3) {
      getMissedInspections({
        tab_name: 'missed',
        completed: missedInspectionsFilters.completed,
        date_from: missedInspectionsFilters.date_from,
        date_to: missedInspectionsFilters.date_to,
        facility_id: missedInspectionsFilters.facility_id,
        equipment_type_id: missedInspectionsFilters.equipment_type_id,
        period: missedInspectionsFilters.period,
        page: currentPage,
      })
    }

    if (currentTab === 6) {
      getMissedInspections({
        tab_name: 'grace',
        completed: gracePeriodFilters.completed,
        date_from: gracePeriodFilters.date_from,
        date_to: gracePeriodFilters.date_to,
        facility_id: gracePeriodFilters.facility_id,
        equipment_type_id: gracePeriodFilters.equipment_type_id,
        period: gracePeriodFilters.period,
        page: currentPage,
      })
    }

    if (currentTab === 5) {
      getReturnService({
        completed: missedInspectionsFilters.completed,
        date_from: missedInspectionsFilters.date_from,
        date_to: missedInspectionsFilters.date_to,
        facility_id: missedInspectionsFilters.facility_id,
        equipment_type_id: missedInspectionsFilters.equipment_type_id,
        period: missedInspectionsFilters.period,
        page: currentPage,
      })
    }

  }, [actionsFilters, currentTab, search, missedInspectionsFilters, currentPage, gracePeriodFilters])

  useEffect(() => {
    if (updateModal || serviceModalStatus || modalStatus) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [updateModal, serviceModalStatus, modalStatus])

  const handleFilterDateFrom = (date) => {
    setActionsFilters(old => ({...old, date_from: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
  }
  const handleFilterDateTo = (date) => {
    setActionsFilters(old => ({...old, date_to: date ? moment(date).format('YYYY-MM-DD') : null}))
    navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${date ? `&date_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
  }
  const handleSearch = (e) => {
    setTimeout(() => {
      setSearch(e.target.value)
    }, 600)
  }

  const changeTab = (tabId) => {
    setCurrentTab(tabId)
  }
  const renderCorrectiveActions = () => {
    if (currentTab === 0) {
      if (actionsData?.data?.length > 0) {
        return actionsData?.data?.map(action => (
          <CorrectiveActionRow key={action.id} action={action} setModalStatus={setModalStatus} currentTab={currentTab}
                               setChosenCorrectiveAction={setChosenCorrectiveAction} chosenCorrectiveAction={chosenCorrectiveAction}/>))
      } else {
        return (
          <div className="facility__table-empty">
            <img
              src="/assets/icons/facility-empty.svg"
              alt="empty"
            />
            <p>There are no data!</p>
          </div>
        )
      }
    }
    if (currentTab === 1) {
      if (actionsData?.data?.length > 0) {
        return actionsData?.data?.map(action => {
          if (action.closed_by) {
            return <CorrectiveActionRow key={action.id} action={action}
                                        currentTab={currentTab}
                                        setModalStatus={setModalStatus}
                                        setChosenCorrectiveAction={setChosenCorrectiveAction}
                                        chosenCorrectiveAction={chosenCorrectiveAction}/>
          }
        })
      } else {
        return (
          <div className="facility__table-empty">
            <img
              src="/assets/icons/facility-empty.svg"
              alt="empty"
            />
            <p>There are no data!</p>
          </div>
        )
      }
    }
  }

  const handleUpdateIns = (e) => {
    if (e.target) {
      setUpdateInspectionData(old => ({...old, [e.target.name]: e.target.value}))
    } else {
      setUpdateInspectionData(old => ({...old, completed_date: moment(e).format("YYYY-MM-DD HH:mm:ss")}))
    }
  }

  const updateMissedInspection = (e) => {
    e.preventDefault()
    if (updateInspectionData.explanation && updateInspectionData.completed_date) {
      updateInspection({id: currentMissedIns.id, data: updateInspectionData})
        .then(() => {
          setTimeout(() => {
            navigate('/inspections/' + currentMissedIns?.inspection?.id)
          }, 1500)
        })
    } else {
      toast.error('Fill all the fields!')
    }
  }

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1)
    if (currentTab === 3) {
      getMissedInspections({
        page: page.selected + 1,
        tab_name: 'missed',
        completed: missedInspectionsFilters.completed,
        date_from: missedInspectionsFilters.date_from,
        date_to: missedInspectionsFilters.date_to,
        facility_id: missedInspectionsFilters.facility_id,
        equipment_type_id: missedInspectionsFilters.equipment_type_id,
        period: missedInspectionsFilters.period})
    } else if (currentTab === 6) {
      getMissedInspections({
        page: page.selected + 1,
        tab_name: 'grace',
        completed: gracePeriodFilters.completed,
        date_from: gracePeriodFilters.date_from,
        date_to: gracePeriodFilters.date_to,
        facility_id: gracePeriodFilters.facility_id,
        equipment_type_id: gracePeriodFilters.equipment_type_id,
        period: gracePeriodFilters.period,
      })
    } else if (currentTab === 5) {
      getReturnService({page: page.selected + 1, completed: missedInspectionsFilters.completed,
        date_from: missedInspectionsFilters.date_from,
        date_to: missedInspectionsFilters.date_to,
        facility_id: missedInspectionsFilters.facility_id,
        equipment_type_id: missedInspectionsFilters.equipment_type_id,
        period: missedInspectionsFilters.period})
    } else {
      getActions({closed: currentTab !== 0, page: page.selected + 1, facility_id: actionsFilters?.facility_id});
    }
    window.scrollTo(0, 0);
  };
  const updateMissedInspectionModal = () => {
    return (
      <div className="modal">
        <form className="modal__content" onSubmit={updateMissedInspection}>
          <div className="modal__content-head">
            <p>Return to Service Explanation</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUpdateModal(false)}
            />
          </div>
          <div className="modal__content-body">
            <label htmlFor="explanation" className="customInput">
              Explanation
              <textarea
                id="explanation"
                name="explanation"
                placeholder="Explanation"
                rows="4"
                onChange={handleUpdateIns}
                defaultValue={currentMissedIns?.explanation ? currentMissedIns?.explanation : ''}
              />
            </label>
            <span className="eq-edit__label" style={{paddingBottom: 8}}>Completed Date:</span>
            <div className="c-datepicker">
              {
                currentMissedIns?.explanation ?
                  <DatePicker
                    selected={updateInspectionData.completed_date ? moment(updateInspectionData.completed_date).toDate() : null}
                    onChange={handleUpdateIns}
                    placeholderText={"Select Date"}
                  />
                  : <DatePicker
                    selected={updateInspectionData.completed_date ? moment(updateInspectionData.completed_date).toDate() : null}
                    onChange={handleUpdateIns}
                    placeholderText={"Select Date"}
                  />
              }

              <span>
        <img src="/assets/icons/calendar.svg" alt="calendar"/>
      </span>
            </div>
          </div>

          <div className="modal__content-bottom">
            <button className="close" onClick={() => setUpdateModal(false)}>
              Close
            </button>
            <button className="save" onClick={updateMissedInspection}>
              Save
            </button>
          </div>
        </form>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderFilters = () => {
    return (
      <div className="actions__filters">
        <Input type="text" name={'search'} id={'search'} onChange={handleSearch} image="/assets/icons/search.svg"
               placeholder="Enter Eq, User or Comment" defaultValue={search} maxwidth={200} innerRef={ref}/>
        {/*<Input type="text" image='/assets/icons/filter.svg' placeholder="Quick filters" maxwidth={100} />*/}
        <Datepicker
          type={'from'}
          setValue={handleFilterDateFrom}
          value={urlParams.get('date_from')}
        />
        <Datepicker
          type={'to'}
          setValue={handleFilterDateTo}
          value={urlParams.get('date_to')}
        />
        <Select styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
            borderRadius: 8,
            width: 200,
            height: 42,
            backgroundColor: '#F4F5F6'
          }),
        }} isClearable
                key={JSON.stringify(facilityOptions) + "1"}
                placeholder={'Facility'} options={facilityOptions}
                defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                onChange={(e) => {
                  setActionsFilters(old => ({...old, facility_id: e?.value}))
                  navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
                }}
        />
        <Select styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
            borderRadius: 8,
            width: 200,
            height: 42,
            backgroundColor: '#F4F5F6'
          }),
        }} isClearable
                key={JSON.stringify(equipmentOptions) + "2"}
                placeholder={'Equipment'} options={equipmentOptions}
                defaultValue={equipmentOptions?.filter(item => item?.value == urlParams.get('equipment_id'))[0]}
                onChange={(e) => {
                  setActionsFilters(old => ({...old, equipment_id: e?.value}))
                  navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${e?.value ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
                }}
        />
      </div>
    )
  }

  const serviceModal = () => {
    return (
      <div className="modal">
        <form className="modal__content service">
          <div className="modal__content-head">
            <p>Out of Service</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setServiceModalStatus(false)}
            />
          </div>
          <div className="modal__content-body services">
            {
              !currentService?.corrective_actions?.length && !currentService?.service_requests?.length && !currentService?.missed_inspections?.length ?
                <p style={{textAlign: 'center', padding: 10}}>No elements</p> : null
            }
            {
              currentService?.corrective_actions?.length ?
              <>
                <p className="modal__content-serviceTitle">Corrective Actions</p>
                <div className="modal__content-serviceHead">
                  <p className="modal__content-serviceText">Category title</p>
                  <p className="modal__content-serviceText">Comment</p>
                  <p className="modal__content-serviceText">Date</p>
                </div>
              </>
                : null
            }
            {
              currentService?.corrective_actions?.map(item => (
                <div className="modal__content-service" key={item?.id}>
                  <p className="modal__content-serviceText">{item?.category_title}</p>
                  <p className="modal__content-serviceText">{item?.comment}</p>
                  <p className="modal__content-serviceText">{moment(item.created_at).format("MM-DD-YY")}</p>
                </div>
              ))
            }

            {
              currentService?.service_requests?.length ?
              <>
                <p className="modal__content-serviceTitle">Service requests</p>
                <div className="modal__content-serviceHead">
                  <p className="modal__content-serviceText">Comment</p>
                  <p className="modal__content-serviceText">Status</p>
                  <p className="modal__content-serviceText">Date</p>
                </div>
              </>
                : null
            }

            {
              currentService?.service_requests?.map(item => (
                <div className="modal__content-service" key={item?.id}>
                  <p className="modal__content-serviceText">{item?.comment}</p>
                  <p className={`modal__content-serviceText ${item?.status}`}>{item?.status}</p>
                  <p className="modal__content-serviceText">{moment(item.created_at).format("MM-DD-YY")}</p>
                </div>
              ))
            }

            {
              currentService?.missed_inspections?.length ?
                <>
                  <p className="modal__content-serviceTitle">Return to Service Inspections</p>
                  <div className="modal__content-serviceHead">
                    <p className="modal__content-serviceText">Name</p>
                    <p className="modal__content-serviceText">Period</p>
                    <p className="modal__content-serviceText">Date</p>
                  </div>
                </>
                : null
            }

            {
              currentService?.missed_inspections?.map(item => (
                <div className="modal__content-service" key={item?.id}>
                  <p className="modal__content-serviceText">{item?.name}</p>
                  <p className={`modal__content-serviceText`}>{item?.period?.charAt(0).toUpperCase() + item?.period?.slice(1)}</p>
                  <p className="modal__content-serviceText">{moment(item?.created_at).format("MM-DD-YY")}</p>
                </div>
              ))
            }
          </div>

          <div className="modal__content-bottom">
            {/*<button className="close" onClick={() => setServiceModalStatus(false)}>*/}
            {/*  Close*/}
            {/*</button>*/}
            {/*<button className="save" onClick={updateMissedInspection}>*/}
            {/*  Save*/}
            {/*</button>*/}
          </div>
        </form>
        <div className="modal__bg"></div>
      </div>
    )
  }


  return (
    <Wrapper>
      <Helmet>
        <title>Corrective Actions | NDX</title>
      </Helmet>
      {updateModal && updateMissedInspectionModal()}
      {modalStatus && (
        <CorrectiveActionModal
          exit={() => setModalStatus(false)}
          action={chosenCorrectiveAction}
          currentTab={currentTab}
        />
      )}
      {serviceModalStatus && serviceModal()}
      <DashboardHeader title={'Corrective Actions'} type='actions'/>
      <div className="actions">
        <div className="actions__header">
          <p className={`actions__header-tab ${currentTab == 0 ? 'active' : ''}`} onClick={() => {
            changeTab(0)
            navigate(`/actions?tab_id=0${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Open
            Items</p>
          <p className={`actions__header-tab ${currentTab == 1 ? 'active' : ''}`} onClick={() => {
            changeTab(1)
            navigate(`/actions?tab_id=1${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Closed
            Items</p>
          {/*<p className={`actions__header-tab ${currentTab === 2 ? 'active' : ''}`} onClick={()=>changeTab(2)}>Return To Service</p>*/}
          <p className={`actions__header-tab ${currentTab == 3 ? 'active' : ''}`} onClick={() => {
            changeTab(3)
            navigate(`/actions?tab_id=3${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Missed Inspections</p>

          <p className={`actions__header-tab ${currentTab == 5 ? 'active' : ''}`} onClick={() => {
            changeTab(5)
            navigate(`/actions?tab_id=5${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Return to Service Inspections</p>

          <p className={`actions__header-tab ${currentTab == 4 ? 'active' : ''}`} onClick={() => {
            changeTab(4)
            navigate(`/actions?tab_id=4${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Out of Service</p>

          <p className={`actions__header-tab ${currentTab == 6 ? 'active' : ''}`} onClick={() => {
            changeTab(6)
            navigate(`/actions?tab_id=6${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_from') ? `&date_from=${urlParams.get('date_from')}` : ""}${urlParams.get('date_to') ? `&date_to=${urlParams.get('date_to')}` : ""}`)
          }}>Grace Period</p>
        </div>
        {
          currentTab === 4 ?
            null :
            currentTab === 0 ?
              renderFilters() :
              currentTab === 1 ?
                renderFilters() :
                currentTab === 6 ?
                  <div className="actions__filters">
                    <Datepicker
                      type={'from'}
                      value={urlParams.get('date_missed_from')}
                      setValue={(date) => {
                        setGracePeriodFilters(old => ({
                          ...old,
                          date_from: date ? moment(date).format('YYYY-MM-DD') : null
                        }))
                        navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_grace_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_grace_to') ? `&date_grace_to=${urlParams.get('date_grace_to')}` : ""}`)
                      }
                      }/>
                    <Datepicker
                      type={'to'}
                      value={urlParams.get('date_missed_to')}
                      setValue={(date) => {
                        setGracePeriodFilters(old => ({
                          ...old,
                          date_to: date ? moment(date).format('YYYY-MM-DD') : null
                        }))
                        navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_grace_from') ? `&date_grace_from=${urlParams.get('date_grace_from')}` : ""}${date ? `&date_grace_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
                      }
                      }/>
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(facilityOptions) + "1"}
                            placeholder={'Facility'} options={facilityOptions}
                            defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                            onChange={(e) => {
                              setGracePeriodFilters(old => ({...old, facility_id: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_grace_to') ? `&date_grace_to=${urlParams.get('date_grace_to')}` : ""}${urlParams.get('date_grace_from') ? `&date_grace_from=${urlParams.get('date_grace_from')}` : ""}`)
                            }}
                    />
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(equipmentTypeOptions) + "2"}
                            placeholder={'Equipment Type'} options={equipmentTypeOptions}
                            defaultValue={equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_id'))[0]}
                            onChange={(e) => {
                              setGracePeriodFilters(old => ({...old, equipment_type_id: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${e?.value ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_grace_to') ? `&date_grace_to=${urlParams.get('date_grace_to')}` : ""}${urlParams.get('date_grace_from') ? `&date_grace_from=${urlParams.get('date_grace_from')}` : ""}`)
                            }}
                    />
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(periodOptions) + "2"}
                            placeholder={'Period'} options={periodOptions}
                            defaultValue={periodOptions?.filter(item => item?.value == urlParams.get('period'))[0]}
                            onChange={(e) => {
                              setGracePeriodFilters(old => ({...old, period: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${e?.value ? `&period=${e?.value}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_missed_to') ? `&date_grace_to=${urlParams.get('date_grace_to')}` : ""}${urlParams.get('date_grace_from') ? `&date_grace_from=${urlParams.get('date_grace_from')}` : ""}`)
                            }}
                    />
                  </div> :
                currentTab === 3 ?
                  <div className="actions__filters">
                    <Datepicker
                      type={'from'}
                      value={urlParams.get('date_missed_from')}
                      setValue={(date) => {
                        setMissedInspectionsFilters(old => ({
                              ...old,
                              date_from: date ? moment(date).format('YYYY-MM-DD') : null
                            }))
                            navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_missed_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}`)
                          }
                          }/>
                      <Datepicker
                          type={'to'}
                          value={urlParams.get('date_missed_to')}
                          setValue={(date) => {
                            setMissedInspectionsFilters(old => ({
                              ...old,
                              date_to: date ? moment(date).format('YYYY-MM-DD') : null
                            }))
                            navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}${date ? `&date_missed_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
                          }
                          }/>
                      <Select styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                          borderRadius: 8,
                          width: 200,
                          height: 42,
                          backgroundColor: '#F4F5F6'
                        }),
                      }} isClearable
                              key={JSON.stringify(facilityOptions) + "1"}
                              placeholder={'Facility'} options={facilityOptions}
                              defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                              onChange={(e) => {
                                setMissedInspectionsFilters(old => ({...old, facility_id: e?.value}))
                                navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                              }}
                      />
                      <Select styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                          borderRadius: 8,
                          width: 200,
                          height: 42,
                          backgroundColor: '#F4F5F6'
                        }),
                      }} isClearable
                              key={JSON.stringify(equipmentTypeOptions) + "2"}
                              placeholder={'Equipment Type'} options={equipmentTypeOptions}
                              defaultValue={equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_id'))[0]}
                              onChange={(e) => {
                                setMissedInspectionsFilters(old => ({...old, equipment_type_id: e?.value}))
                                navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${e?.value ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                              }}
                      />
                      <Select styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                          borderRadius: 8,
                          width: 200,
                          height: 42,
                          backgroundColor: '#F4F5F6'
                        }),
                      }} isClearable
                              key={JSON.stringify(periodOptions) + "2"}
                              placeholder={'Period'} options={periodOptions}
                              defaultValue={periodOptions?.filter(item => item?.value == urlParams.get('period'))[0]}
                              onChange={(e) => {
                                setMissedInspectionsFilters(old => ({...old, period: e?.value}))
                                navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${e?.value ? `&period=${e?.value}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                              }}
                      />
                    </div>
                    : <div className="actions__filters">
                      <Datepicker
                          type={'from'}
                          value={urlParams.get('date_missed_from')}
                          setValue={(date) => {
                            setMissedInspectionsFilters(old => ({
                              ...old,
                              date_from: date ? moment(date).format('YYYY-MM-DD') : null
                            }))
                            navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${date ? `&date_missed_from=${moment(date).format('YYYY-MM-DD')}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}`)
                          }
                          }/>
                      <Datepicker
                          type={'to'}
                          value={urlParams.get('date_missed_to')}
                          setValue={(date) => {
                            setMissedInspectionsFilters(old => ({
                              ...old,
                              date_to: date ? moment(date).format('YYYY-MM-DD') : null
                            }))
                            navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}${date ? `&date_missed_to=${moment(date).format('YYYY-MM-DD')}` : ""}`)
                          }
                          }/>
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(facilityOptions) + "1"}
                            placeholder={'Facility'} options={facilityOptions}
                            defaultValue={facilityOptions?.filter(item => item?.value == urlParams.get('facility_id'))[0]}
                            onChange={(e) => {
                              setMissedInspectionsFilters(old => ({...old, facility_id: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${e?.value ? `&facility_id=${e?.value}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${urlParams.get('equipment_id')}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                            }}
                    />
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(equipmentTypeOptions) + "2"}
                            placeholder={'Equipment Type'} options={equipmentTypeOptions}
                            defaultValue={equipmentTypeOptions?.filter(item => item?.value == urlParams.get('equipment_id'))[0]}
                            onChange={(e) => {
                              setMissedInspectionsFilters(old => ({...old, equipment_type_id: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${urlParams.get('period') ? `&period=${urlParams.get('period')}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${e?.value ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                            }}
                    />
                    <Select styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                        borderRadius: 8,
                        width: 200,
                        height: 42,
                        backgroundColor: '#F4F5F6'
                      }),
                    }} isClearable
                            key={JSON.stringify(returnToServicePeriod) + "2"}
                            placeholder={'Period'} options={returnToServicePeriod}
                            defaultValue={returnToServicePeriod?.filter(item => item?.value == urlParams.get('period'))[0]}
                            onChange={(e) => {
                              setMissedInspectionsFilters(old => ({...old, period: e?.value}))
                              navigate(`/actions?${urlParams.get('tab_id') ? `tab_id=${urlParams.get('tab_id')}` : ""}${e?.value ? `&period=${e?.value}` : ""}${urlParams.get('facility_id') ? `&facility_id=${urlParams.get('facility_id')}` : ""}${urlParams.get('equipment_id') ? `&equipment_id=${e?.value}` : ""}${urlParams.get('date_missed_to') ? `&date_missed_to=${urlParams.get('date_missed_to')}` : ""}${urlParams.get('date_missed_from') ? `&date_missed_from=${urlParams.get('date_missed_from')}` : ""}`)
                            }}
                    />
                    </div>
        }

        <div className="actions__box">
          <div className="actions__table">
            {
              // TABLE HEAD COLUMNS
              currentTab === 0 ?
                <div className="actions__table-head">
                  <div className="actions__table-th opened">Equipment ID</div>
                  <div className="actions__table-th opened">Equipment Type</div>
                  <div className="actions__table-th opened">Facility</div>
                  <div className="actions__table-th opened">Inspection/Category</div>
                  <div className="actions__table-th opened">Created Date</div>
                  <div className="actions__table-th opened">Comment</div>
                  {/*<div className="actions__table-th">Corrective Action</div>*/}
                </div> :
                currentTab === 3 || currentTab === 6 ?
                  <div className="actions__table-head">
                    <div className="actions__table-th missed">Inspection</div>
                    <div className="actions__table-th missed">Equipment ID</div>
                    <div className="actions__table-th missed">Facility</div>
                    <div className="actions__table-th missed">Inspection Date</div>
                    <div className="actions__table-th missed">Due Date</div>
                    <div className="actions__table-th missed">Period</div>
                  </div>
                  : currentTab === 4 ?
                    <div className="actions__table-head">
                      <div className="actions__table-th out">Equipment Serial</div>
                      <div className="actions__table-th out">Type</div>
                      <div className="actions__table-th out">Facility</div>
                      <div className="actions__table-th out">Reason</div>
                    </div> :
                    currentTab === 5 ?
                      <div className="actions__table-head">
                        <div className="actions__table-th missed">Inspection</div>
                        <div className="actions__table-th missed">Equipment ID</div>
                        <div className="actions__table-th missed">Facility</div>
                        <div className="actions__table-th missed">Inspection Date</div>
                        <div className="actions__table-th missed">Due Date</div>
                        <div className="actions__table-th missed">Period</div>
                      </div>
                      :
                    <div className="actions__table-head">
                      <div className="actions__table-th closed">Equipment ID</div>
                      <div className="actions__table-th closed">Equipment Type</div>
                      <div className="actions__table-th closed">Facility</div>
                      <div className="actions__table-th closed">Discrepancy</div>
                      <div className="actions__table-th closed">Corrective Action</div>
                      <div className="actions__table-th closed">Repair Time</div>
                      <div className="actions__table-th closed">Closed Date</div>
                      <div className="actions__table-th closed">Closed by</div>
                    </div>
            }


            {/*TABLE BODY ROWS*/}
            <div className="actions__table-body">
              {
                currentTab == 5 ?
                  getReturnServiceData?.data?.length > 0 ?
                  getReturnServiceData?.data?.map(action => (
                    <div className="actions__table-row" key={action.id} onClick={() => {
                      setCurrentMissedIns(action)
                      // setUpdateModal(true)
                      setTimeout(() => {
                        navigate('/inspections/' + action?.id)
                      }, 500)
                    }}>
                      <div
                        className="actions__table-bodyTh missed">{action?.name ? action?.name : '---'}</div>
                      <div
                        className="actions__table-bodyTh missed">{action?.equipment?.title ? action?.equipment?.title : '---'}</div>
                      <div
                        className="actions__table-bodyTh missed">{action?.facility?.name ? action?.facility?.name : '---'}</div>
                      <div
                          className="actions__table-bodyTh missed">{action?.inspection_date ? moment(action?.inspection_date)?.format("MM/DD/YYYY") : '---'}</div>
                      <div
                          className="actions__table-bodyTh missed">{action?.due_date_fbo_timezone ? moment(action?.due_date_fbo_timezone)?.format("MM/DD/YYYY hh:mm A") : '---'}</div>
                      <div
                        className="actions__table-bodyTh missed">{action?.period?.charAt(0)?.toUpperCase() + action?.period?.slice(1)}</div>
                    </div>
                  ))
                    : <div className="facility__table-empty">
                        <img
                          src="/assets/icons/facility-empty.svg"
                          alt="empty"
                        />
                        <p>There are no data!</p>
                      </div>
                  :
                  currentTab === 4 ?
                  outOfServiceData?.map(service => (
                    <div className="actions__table-row" key={service.id} onClick={() => {
                      setServiceModalStatus(true)
                      setCurrentService(service)
                    }}>
                      <div className="actions__table-bodyTh out">{service?.title ? service?.title : '---'}</div>
                      <div className="actions__table-bodyTh out">{service?.type ? service?.type: '---'}</div>
                      <div className="actions__table-bodyTh out">{service?.facility ? service?.facility : "---"}</div>
                      <div className="actions__table-bodyTh out">{service?.reason ? service?.reason : "---"}</div>
                    </div>
                  ))
                  :
                  currentTab === 0 || currentTab === 1 ?
                    renderCorrectiveActions()
                    : currentTab === 3 || currentTab === 6 ?
                    getMissedInspectionsData?.data?.length > 0 ?
                    getMissedInspectionsData?.data?.map(action => (
                      <div className="actions__table-row" key={action.id} onClick={() => {
                        setCurrentMissedIns(action)
                        // setUpdateModal(true)
                        if (currentTab === 6) {
                          setTimeout(() => {
                            navigate('/inspections/' + action?.id)
                          }, 500)
                        } else {
                          setTimeout(() => {
                            navigate('/inspections/' + action?.id)
                          }, 500)
                        }

                      }}>
                        <div
                          className="actions__table-bodyTh missed">{action?.name ? action?.name : '---'}</div>
                        <div
                          className="actions__table-bodyTh missed">{action?.equipment?.title ? action?.equipment?.title : '---'}</div>
                        <div
                          className="actions__table-bodyTh missed">{action?.facility?.name ? action?.facility?.name : '---'}</div>
                        <div
                            className="actions__table-bodyTh missed">{action?.inspection_date ? moment(action?.inspection_date)?.format("MM/DD/YYYY") : '---'}</div>
                        <div
                            className="actions__table-bodyTh missed">{action?.due_date_fbo_timezone ? moment(action?.due_date_fbo_timezone)?.format("MM/DD/YYYY hh:mm A") : '---'}</div>
                        <div
                          className="actions__table-bodyTh missed">{action?.period?.charAt(0)?.toUpperCase() + action?.period?.slice(1)}</div>
                      </div>
                    )) :
                      <div className="facility__table-empty">
                        <img
                          src="/assets/icons/facility-empty.svg"
                          alt="empty"
                        />
                        <p>There are no data!</p>
                      </div>
                    : null
                // actionsData?.data?.map(action => (<CorrectiveActionRow action={action} currentTab={currentTab} />))
              }

            </div>
          </div>
          {
            currentTab === 4 ?
              null :
              currentTab === 5 ?
                <CustomPaginate
                  pageCount={getReturnServiceData?.pages}
                  handlePageClick={handlePageClick}
                />
                :
              currentTab === 3 || currentTab === 6 ?
              <CustomPaginate
                pageCount={currentTab === 3 || currentTab === 6 ? getMissedInspectionsData?.pages : actionsData?.pages}
                handlePageClick={handlePageClick}
              />
                :
                currentTab === 0 || currentTab === 1 ?
                  <CustomPaginate
                    pageCount={actionsData?.pages}
                    handlePageClick={handlePageClick}
                  /> : null
          }

        </div>
      </div>
    </Wrapper>

  );
};

import React from 'react';
import './index.scss';

const NotAvailable = () => {
  return (
    <div className="available">
      <div className="available__box">
        <img src="/assets/not-available.png" alt="bg"/>
        <p className="available__title">Page is not available yet</p>
        <p className="available__subtitle">
          Payment history is not available. Once you pay,
          you will be able to see your statement here
        </p>
        <button className="available__btn">Contact NDX</button>
      </div>
    </div>

  );
};

export default NotAvailable;
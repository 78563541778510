import React from 'react';

export default function ViewImageModal ({exit, activeImage, images, setActiveImage}) {

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>View Files</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}/>
        </div>
        <div className="modal__content-body image-block">
          <img src={activeImage} alt="image" className="modal__content-body imageView" />
        </div>
        <div className="modal__content-body small-images-block">
          {
            images.map(image => (
              <div onClick={() => setActiveImage(image)} className="modal__content-body small-image">
                <img src={image} alt="image" />
              </div>
            ))
          }
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>Close</button>
        </div>

      </div>
      <div className="modal__bg">
      </div>
    </div>
  );
};


import React from 'react';

const ClearInspection = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons/Eraser/Line">
        <path id="Shape" d="M6 20C6 19.4477 6.44772 19 7 19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H7C6.44772 21 6 20.5523 6 20Z" fill={color}/>
        <path id="Shape_2" fillRule="evenodd" clipRule="evenodd" d="M20.8787 6.87823L17.1214 3.12087C15.9498 1.94929 14.0503 1.94929 12.8787 3.12087L2.12136 13.8782C0.949784 15.0498 0.949781 16.9493 2.12135 18.1209L3.82846 19.828C4.57861 20.5781 5.59602 20.9995 6.65689 20.9995H9.34318C10.404 20.9995 11.4215 20.5781 12.1716 19.828L20.8787 11.1209C22.0503 9.94929 22.0503 8.0498 20.8787 6.87823ZM15.7071 4.53508L19.4645 8.29244C19.855 8.68296 19.855 9.31613 19.4645 9.70665L15.0858 14.0853L9.91425 8.91376L14.2929 4.53508C14.6835 4.14456 15.3166 4.14456 15.7071 4.53508ZM8.41425 10.4138L3.53557 15.2924C3.14504 15.683 3.14505 16.3161 3.53557 16.7067L5.24268 18.4138C5.61775 18.7888 6.12646 18.9995 6.65689 18.9995H9.34318C9.87362 18.9995 10.3823 18.7888 10.7574 18.4138L13.5858 15.5853L8.41425 10.4138Z" fill={color}/>
      </g>
    </svg>

  );
};

export default ClearInspection;
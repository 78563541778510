import React, { useState } from "react";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {
  useCreateFacilityMutation,
  useGetAgencyQuery,
} from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import Input from "../Input";

import "./index.scss";
import CustomSelect from "../CustomSelect";
import Checkbox from "../Checkbox";
import Log from "../../SVG/Log";
import Datepicker from "../Datepicker";
import moment from "moment";

export default function FacilityModal({ setModal, getData }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: null,
    inspections_enabled: false,
    default_facility_inspections_enabled: false,
    last_monthly_date: null,
  });

  const [create, { isError, isFetching, isSuccess, error, data: createData }] =
    useCreateFacilityMutation();
  const [errorHandler, setErrorHandler] = useErrorHandler([error]);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);

  useEffect(() => {
    if (isFetching) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [isFetching, isSuccess]);

  const createFacility = () => {
    if (data?.name?.length > 1) {
      let s = [];
      for (let key in data) {
        if (key !== "inspections_enabled" && key !== "default_facility_inspections_enabled") {
          if (data[key] || data[key]?.length > 0) {
          } else {
            s.push("false");
          }
        }
      }
      if (s.length === 0) {
        create(data).then((res) => {
          toast.success("Facility created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(() => {
            getData();
          }, 1000)
        });

        // setData(null);
        setModal(false);
      } else {
        toast.error("Please enter all values ", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } else {
      toast.error("Fill the name field!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="facility-modal">
      <div className="facility-modal__content">
        <div className="facility-modal__header">
          <span></span>
          <div className="facility-modal__title">Create Facility</div>
          <div className="facility-modal__close">
            <img
              onClick={() => {
                setModal(false);
                setData(null);
              }}
              src="/assets/icons/close.svg"
              alt="close"
            />
          </div>
        </div>
        <form action="" className="facility-modal__form">
          <div className="facility-modal__field">
            <Input
              containerWidth={"100%"}
              label="Facility name"
              placeholder="Facility name"
              id="facilityName"
              onChange={(e) => {
                setData((old) => ({ ...old, name: e.target.value }));
              }}
            />
          </div>
          {/*<div className="facility-modal__field">*/}
          {/*  <Input*/}
          {/*    label="Airport ID"*/}
          {/*    placeholder="Airport ID"*/}
          {/*    id="airportId"*/}
          {/*    onChange={(e) => {*/}
          {/*      setData((old) => ({ ...old, airport_id: e.target.value }));*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          {
            userInfo?.is_global && (
              <div className="facility-modal__field">
                <Checkbox
                  label={"Inspections Enabled"}
                  id={"inspections_enabled"}
                  checked={data?.inspections_enabled}
                  onChange={(e) => {
                    setData((old) => ({
                      ...old,
                      inspections_enabled: e.target.checked,
                    }));
                  }}
                />
              </div>
            )
          }
          <div className="facility-modal__field">
            {/*<Checkbox*/}
            {/*  label={"Do fuel facility checks apply to this facility?"}*/}
            {/*  id={"default_facility_inspections_enabled"}*/}
            {/*  onChange={(e) => {*/}
            {/*    setData((old) => ({*/}
            {/*      ...old,*/}
            {/*      default_facility_inspections_enabled: e.target.checked,*/}
            {/*    }));*/}
            {/*  }}*/}
            {/*/>*/}
            <span style={{fontSize: '14px'}}>Do fuel facility checks apply to this facility?</span>
            <div className="subscription__plan-info_checkbox" style={{marginTop: 10, marginBottom: 16}}>
              <div className="radio-option">
                <input
                  type="radio"
                  name={'no'}
                  id={'no'}
                  value={'No'}
                  checked={!data?.default_facility_inspections_enabled}
                  onChange={(e) => {
                    setData((old) => ({
                      ...old,
                      default_facility_inspections_enabled: false,
                    }));
                  }}
                />
                <label style={!data?.default_facility_inspections_enabled ? {color: '#4268B0'} : {}}
                       htmlFor={'no'}>No</label>
              </div>
              <div className="radio-option">
                <input
                  type="radio"
                  name={'yes'}
                  id={'yes'}
                  checked={data?.default_facility_inspections_enabled}
                  value={'Yes'}
                  onChange={(e) => {
                    setData((old) => ({
                      ...old,
                      default_facility_inspections_enabled: true,
                    }));
                  }}
                />
                <label style={data?.default_facility_inspections_enabled ? {color: '#4268B0'} : {}}
                       htmlFor={'yes'}>Yes</label>
              </div>
            </div>
            {
              (userInfo?.is_global || userInfo?.is_admin) &&
              <>
                <span className="customInput">Last monthly date</span>
                <Datepicker
                  setValue={(e) => setData(old => ({...old, last_monthly_date: moment(e).format('MM/DD/YYYY')}))}/>
              </>
            }
          </div>
          <div className="facility-modal__btns">
            <div
              onClick={() => {
                setModal(false);
                setData(null);
              }}
              className="facility-modal__cancel"
            >
              Cancel
            </div>
            <div onClick={createFacility} className="facility-modal__create">
              Create
            </div>
          </div>
        </form>
      </div>
      <div
        className="facility-modal__bg"
        // onClick={() => {
        //   setModal(false);
        //   setData(null);
        // }}
      ></div>
    </div>
  );
}

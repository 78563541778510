import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {
  useGetTimezonesQuery, useLazyGetPaymentInfoCorporateQuery,
  useLazyRegionsListQuery,
  useSaveProfilePhotoMutation, useUpdateCorporateProfileMutation,
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";
import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Cropper from "react-cropper";
import UpdatePaymentModal from "../../components/Profile/UpdatePaymentModal";
import BillingAddressModal from "../../components/Profile/BillingAddressModal";
import Profile1 from "../../SVG/Profile1";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import CorporateRegions from "../../components/Corporate/CorporateRegions";
import History from "../../SVG/History";
import CorporateRegionDetails from "../../components/Corporate/CorporateRegionDetails";
import CustomSelect from "../../components/CustomSelect";
import CorrectiveActionsIcon from "../../SVG/CorrectiveActions";
import CorporateCorrectiveActions from "../../components/Corporate/CorporateCorrectiveActions";
import * as PropTypes from "prop-types";
import CorporateUsers from "../../components/Corporate/CorporateUsers";
import BillingAddress from "../../components/Profile/BillingAddress";
import SubscriptionPlan from "../../components/Profile/SubscriptionPlan";
import {validationEmail} from "../../global/validate";

function CorporateCorrectiveActionsCorporateCorrectiveActions(props) {
  return null;
}

CorporateCorrectiveActionsCorporateCorrectiveActions.propTypes = {
  className: PropTypes.func,
  children: PropTypes.node
};
const CorporateSettings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [cropper, setCropper] = useState();
  const [chosenRegionId, setChosenRegionId] = useState(null);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const token = localStorage.getItem("ndx_user_token");
  const navigate = useNavigate();
  const changeTab = (tabId) => setCurrentTab(tabId);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentRegion, setCurrentRegion] = useState(null)
  const [corporateProfile, setCorporateProfile] = useState({
    title: null,
    phone: null,
    email: null,
    timezone: null,
    start_day_minutes: null,
    logo: null,
    has_upstream_tests: false,
    daily_weekly_grace_period: null,
  })

  const [phoneNumber, setPhoneNumber] = useState("");
  const [timeOptions, setTimeOptions] = useState([
    {
      label: '12:00 AM',
      value: 0,
    },
    {
      label: '1:00 AM',
      value: 60,
    },
    {
      label: '2:00 AM',
      value: 120,
    },
    {
      label: '3:00 AM',
      value: 180,
    },
    {
      label: '4:00 AM',
      value: 240,
    },
    {
      label: '5:00 AM',
      value: 300,
    },
    {
      label: '6:00 AM',
      value: 360,
    },
    {
      label: '7:00 AM',
      value: 420,
    },
    {
      label: '8:00 AM',
      value: 480,
    },
    {
      label: '9:00 AM',
      value: 540,
    },
    {
      label: '10:00 AM',
      value: 600,
    },
    {
      label: '11:00 AM',
      value: 660,
    },
    {
      label: '12:00 PM',
      value: 720,
    },
    {
      label: '1:00 PM',
      value: 780,
    },
    {
      label: '2:00 PM',
      value: 840,
    },
    {
      label: '3:00 PM',
      value: 900,
    },
    {
      label: '4:00 PM',
      value: 960,
    },
    {
      label: '5:00 PM',
      value: 1020,
    },
    {
      label: '6:00 PM',
      value: 1080,
    },
    {
      label: '7:00 PM',
      value: 1140,
    },
    {
      label: '8:00 PM',
      value: 1200,
    },
    {
      label: '9:00 PM',
      value: 1260,
    },
    {
      label: '10:00 PM',
      value: 1320,
    },
    {
      label: '11:00 PM',
      value: 1380,
    },
  ])
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [extraShow, setExtraShow] = useState(false);

  const [save, {data, isLoading, isError, error, isSuccess}] =
    useSaveProfilePhotoMutation();

  const [regionsList,
    {
      data: regionsListData,
      isLoading: regionsListLoading,
      isError: regionsListIsError,
      error: regionsListError,
      isSuccess: regionsListIsSuccess,
    }] = useLazyRegionsListQuery();

  const [updateSettings,
    {
      data: updateSettingsData,
      isLoading: updateSettingsLoading,
      isError: updateSettingsIsError,
      error: updateSettingsError,
      isSuccess: updateSettingsIsSuccess,
    }] = useUpdateCorporateProfileMutation();

  const {data: timezonesData,
    error: timezonesError,
    isLoading: timezonesIsLoading,
    isSuccess: timezonesIsSuccess,
    isError: timezonesIsError,
  } = useGetTimezonesQuery();
  const [
    getPaymentInfo,
    {
      data: paymentInfoData,
      isLoading: paymentInfoIsLoading,
      isError: paymentInfoIsError,
      error: paymentInfoError,
      isSuccess: paymentInfoIsSuccess,
    },
  ] = useLazyGetPaymentInfoCorporateQuery();

  useEffect(() => {
    regionsList();
    getPaymentInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.corporate) {
      setCorporateProfile({
        title: userInfo.corporate.title,
        phone: userInfo.corporate.phone,
        email: userInfo.corporate.email,
        timezone: userInfo.corporate.timezone,
        logo: userInfo.corporate.logo,
        start_day_minutes: userInfo.corporate.start_day_minutes,
        has_upstream_tests: userInfo.corporate.has_upstream_tests ? true : false,
        daily_weekly_grace_period: userInfo?.corporate?.daily_weekly_grace_period,
      })
      let formattedPhone = userInfo?.corporate?.phone?.replace(/\D/g, ''); // remove non-numeric characters
      formattedPhone = formattedPhone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
      setPhoneNumber(formattedPhone);
    }
  }, [userInfo])

  useEffect(() => {
    if (location?.search) {
      setCurrentTab(Number(location?.search?.split('=')[1]))
    }
  }, [])

  useEffect(() => {
    if (typeof chosenPhoto === "string") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [chosenPhoto]);

  useEffect(() => {
    if (updateSettingsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }

    if (updateSettingsIsSuccess) {
      dispatch(setLoader(false))
      toast.success("Successfully updated")
      getUserIndex()
    }
  }, [updateSettingsLoading, updateSettingsIsSuccess])

  const cropperHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChosenPhoto(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const form = new FormData();
      form.append(
        "profile_photo",
        base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      );
      setCorporateProfile(old => ({...old, logo: base64ToBlob(cropper.getCroppedCanvas().toDataURL())}))
      setTimeout(() => {
        setShowModal(false);
      }, 200)
    }
  };

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
  };

  const getRegionsList = () => {
    regionsList()
  }

  const handleCorporate = (e) => {
    setCorporateProfile(old => ({...old, [e.target.name]: e.target.value}))
  }

  const handleCheckbox = (e) => {
    setCorporateProfile(old => ({...old, has_upstream_tests: e.target.checked === true ? 1 : 0}))
  }

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };
  const update = () => {
    if (
      corporateProfile?.title?.length !== 0 &&
      // phoneNumber.length!== 0 &&
      validationEmail(corporateProfile?.email) &&
      corporateProfile?.timezone &&
      corporateProfile?.start_day_minutes !== null
    ) {
      const form = new FormData();
      if (chosenPhoto) {
        form.append("logo", base64ToBlob(cropper.getCroppedCanvas().toDataURL()));
      }
      form.append("title", corporateProfile.title);
      form.append("daily_weekly_grace_period", corporateProfile?.daily_weekly_grace_period);
      form.append("email", corporateProfile.email);
      form.append("timezone", corporateProfile.timezone);
      form.append("start_day_minutes", corporateProfile.start_day_minutes);
      form.append("has_upstream_tests", corporateProfile.has_upstream_tests ? 1 : 0);

      updateSettings(form)
    } else {
      toast.error("Please fill all fields correctly")
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Profile | NDX</title>
      </Helmet>
      <DashboardHeader title={"Settings"}/>
      {showPaymentModal && (
        <UpdatePaymentModal
          setShowPaymentModal={setShowPaymentModal}
          getUserIndex={getUserIndex}
          getPaymentInfo={getPaymentInfo}
          isCorporate={true}
        />
      )}
      {showBillingModal && (
        <BillingAddressModal
          setShowPaymentModal={setShowBillingModal}
          getUserIndex={getUserIndex}
        />
      )}
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            onClick={() => {
              setShowModal(false);
              setChosenPhoto(null);
            }}
          ></div>
        </div>
      )}
      {showDeleteModal && (
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Delete card</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setShowDeleteModal(false)}
              />
            </div>
            <div
              className="modal__content-body"
              style={{display: "flex", justifyContent: "center"}}
            >
              Are you sure want to remove the card?
            </div>
            <div className="modal__content-bottom">
              <button
                className="close"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button className="save danger">Delete</button>
            </div>
          </div>
          <div
            className="modal__bg"
            onClick={() => setShowDeleteModal(false)}
          ></div>
        </div>
      )}
      <div className="profile">
        <div className="profile__tabs">
          <div
            className={`profile__tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => {
              setCurrentOrder(null);
              changeTab(0);
            }}
          >
            <Profile1 active={currentTab === 0 && true}/>
            <p className="profile__tab-name">Corporate Profile</p>
          </div>
          <div
            className={`profile__tab ${currentTab === 1 ? "active" : ""}`}
            onClick={() => {
              setCurrentOrder(null);
              changeTab(1);
            }}
          >
            <CorrectiveActionsIcon active={currentTab === 1 && true}/>
            <p className="profile__tab-name">Corrective Action Settings</p>
          </div>

          <div
            className={`profile__tab ${currentTab === 5 || currentTab === 6 ? "active" : ""}`}
            onClick={() => {
              setCurrentOrder(null);
              changeTab(5);
            }}
          >
            <History active={(currentTab === 5 || currentTab === 6) && true}/>
            <p className="profile__tab-name">Regions</p>
          </div>
          <div
            className={`profile__tab ${currentTab === 3 ? "active" : ""}`}
            onClick={() => {
              setCurrentOrder(null);
              changeTab(3);
            }}
          >
            <Profile1 active={currentTab === 3 && true}/>
            <p className="profile__tab-name">Corporate users</p>
          </div>
          {/*<div*/}
          {/*  className={`profile__tab ${currentTab === 8 ? "active" : ""}`}*/}
          {/*  onClick={() => {*/}
          {/*    setCurrentOrder(null);*/}
          {/*    changeTab(8);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Profile3 active={currentTab === 8 && true}/>*/}
          {/*  <p className="profile__tab-name">NDX Corporate Plan</p>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*  className={`profile__tab ${currentTab === 4 ? "active" : ""}`}*/}
          {/*  onClick={() => {*/}
          {/*    setCurrentOrder(null);*/}
          {/*    changeTab(4);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Profile4 active={currentTab === 4 && true}/>*/}
          {/*  <p className="profile__tab-name">Payment Methods</p>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*  className={`profile__tab ${currentTab === 7 ? "active" : ""}`}*/}
          {/*  onClick={() => {*/}
          {/*    setCurrentOrder(null);*/}
          {/*    changeTab(7);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  /!*{*!/*/}
          {/*  /!*  (Object.values(userInfo?.address).every(item => item === null))*!/*/}
          {/*  /!*  && <div style={{top: '9px', left: '9px'}} className="red-dot"></div>*!/*/}
          {/*  /!*}*!/*/}
          {/*  <Profile6 active={currentTab === 7 && true}/>*/}
          {/*  <p className="profile__tab-name">Address</p>*/}
          {/*</div>*/}

        </div>
        <div className={`profile__box ${currentTab === 5 || currentTab === 3 || currentTab === 6 ? 'regions' : ''}`}>
          {currentTab === 0 ? (
            <div>
              <div className="profile__info column">
                <p className="profile__title">Corporate Profile</p>
                <div className="profile__imageBox">
                  <img
                    className="profile__avatar"
                    src={chosenPhoto ? chosenPhoto : userInfo?.corporate?.logo}
                    alt="avatar"
                  />
                  <div>
                    <p className="profile__imageBox-desc">
                      We only support .JPG, .JPEG, or .PNG file.
                    </p>
                    <label
                      htmlFor="profile_photo"
                      className="profile__imageBox-label"
                    >
                      <input
                        type="file"
                        name="profile_photo"
                        id="profile_photo"
                        onChange={cropperHandler}
                        accept="image/jpeg, image/png, image/jpg"
                      />
                      Upload photo
                    </label>
                  </div>
                </div>
              </div>
              <form className="profile__form">
                <div className="profile__form-top">
                  <div className="profile__form-input">
                    <Input
                      label="Corporate name"
                      placeholder={"Corporate name"}
                      name={"title"}
                      containerWidth={"350px"}
                      defaultValue={userInfo?.corporate?.title ? userInfo?.corporate?.title : ""}
                      onChange={handleCorporate}
                    />
                    <Input
                      label="Corporate email"
                      placeholder={"Corporate email"}
                      name={"email"}
                      containerWidth={"350px"}
                      defaultValue={userInfo?.corporate?.email ? userInfo?.corporate?.email : ""}
                      onChange={handleCorporate}
                    />
                    {/*<div className="auth__field phone">*/}
                    {/*  <label htmlFor="fbo_phone" className="auth__label">*/}
                    {/*    FBO Phone*/}
                    {/*  </label>*/}
                    {/*  <span className="phone__num">+1</span>*/}
                    {/*  <input*/}
                    {/*    id="fbo_phone"*/}
                    {/*    placeholder="Enter phone number"*/}
                    {/*    type="tel"*/}
                    {/*    value={phoneNumber}*/}
                    {/*    // pattern="\+\d\s\d{3}\s\d{3}-\d{4}"*/}
                    {/*    className="auth__input corporate"*/}
                    {/*    onChange={handlePhoneChange}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    {
                      userInfo?.corporate &&
                      <>
                        <CustomSelect
                          name={"timezone"}
                          placeholder={"Timezone"}
                          label={"Timezone"}
                          options={timezonesData?.data?.map(item => ({value: item, label: item}))}
                          maxwidth="350px"
                          defaultValue={userInfo?.corporate?.timezone ? {
                            value: userInfo?.corporate?.timezone,
                            label: userInfo?.corporate?.timezone
                          } : null}
                          onChange={handleCorporate}
                        />
                        <CustomSelect
                          name={"start_day_minutes"}
                          placeholder={"Enter when inspections to generate"}
                          label={"When do you want new inspections to generate?"}
                          options={timeOptions}
                          maxwidth="350px"
                          defaultValue={(userInfo?.corporate?.start_day_minutes !== null && userInfo?.corporate?.start_day_minutes !== undefined) ?
                            timeOptions?.filter(el => el.value === userInfo?.corporate?.start_day_minutes)[0]
                            : null}
                          onChange={handleCorporate}
                        />
                      </>
                    }
                  </div>
                </div>
                <div className="facility-modal__field" style={{marginTop: 16}}>
                  <Checkbox
                    key={"has_upstream_tests"}
                    label={"Has Upstream Tests"}
                    id={"has_upstream_tests"}
                    checked={corporateProfile?.has_upstream_tests}
                    onChange={handleCheckbox}
                  />
                </div>
                <div style={{marginTop: 16}}>
                  <Input
                    tooltip={true}
                    tooltipText={"Minimum value is 0 and maximum value is 365"}
                    containerWidth={"350px"}
                    id="daily_weekly_grace_period"
                    name="daily_weekly_grace_period"
                    placeholder="Daily Weekly Grace Period"
                    label={"Daily Weekly Grace Period"}
                    type={"number"}
                    onChange={handleCorporate}
                    defaultValue={userInfo?.corporate?.daily_weekly_grace_period ? userInfo?.corporate?.daily_weekly_grace_period : ""}
                  />
                </div>
              </form>
              <button className="profile__methods-btn corporate" onClick={update}>Save</button>
            </div>
            )
            : currentTab === 1 ? (
                <CorporateCorrectiveActions/>
              )
              : currentTab === 3 ? (
                  <CorporateUsers/>
                )
                : currentTab === 4 ? (
                    <div className="profile__methods">
                      <div className="profile__methods-head">
                        <p className="profile__methods-title">Payment methods</p>
                        <button
                          className="profile__methods-btn"
                          onClick={() => setShowPaymentModal(true)}
                        >
                        Update
                      </button>
                    </div>
                    {
                      paymentInfoData?.data?.payment_method ?
                        <div className="profile__methods-cards">
                          <p>Method: {paymentInfoData?.data?.payment_method}</p>
                          <img
                            className="profile__methods-img"
                            src={paymentInfoData?.data?.icon}
                            alt="img "
                          />
                          <div className="profile__methods-card">
                            {paymentInfoData?.data ?
                              Object.entries(paymentInfoData?.data?.details).map(
                                (item) => (
                                  <p className="profile__card-text">
                                    {item[0]}: <span>{item[1]}</span>
                                  </p>
                                )
                              ) : null}
                            {
                              paymentInfoData?.data?.payment_method_status === "requires_action" &&
                              <>
                                <p className="profile__card-text processing">
                                  Status: <span>Verification Required</span>
                                </p>
                                <p className="profile__card-text link">
                                  Verification link: <span>
                            <a target="_blank" href={paymentInfoData?.data?.payment_method_link}>Open link</a>
                          </span>
                                </p>
                              </>
                            }

                          </div>

                        </div>
                        :
                        <div style={{width: '100%', display: 'flex', justifyContent:'center'}}>
                          <img src="/assets/no-payment.png" alt="img"/>
                        </div>
                    }
                  </div> )
            : currentTab === 5 ? (
              <CorporateRegions regions={regionsListData} currentRegion={currentRegion} setCurrentRegion={setCurrentRegion} getRegionsList={getRegionsList} setCurrentTab={setCurrentTab} setChosenRegionId={setChosenRegionId} />
            ) : currentTab === 6 ? (
              <CorporateRegionDetails currentRegion={currentRegion} setCurrentRegion={setCurrentRegion} chosenRegionId={chosenRegionId} getRegionsList={getRegionsList} setCurrentTab={setCurrentTab} />
            ) : currentTab === 7 ? (
                <BillingAddress isCorporate={true} />
                ) : currentTab === 8 ? (
                    <SubscriptionPlan setExtraShow={setExtraShow} setCurrentTab={setCurrentTab} isCorporate={true} />
                    )

                    : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default CorporateSettings;
import React from 'react';
import './index.scss';

const EquipmentOutModal = ({exit, data}) => {

  return (
    <div className="modal">
      <div className="modal__content service">
        <div className="modal__content-head">
          <p>Equipment Out of Service</p>
          {/*<img*/}
          {/*  className="modal__content-exit"*/}
          {/*  src="/assets/icons/close.svg"*/}
          {/*  alt="close"*/}
          {/*  onClick={exit}*/}
          {/*/>*/}
        </div>
        <div className="equipmentOut__table">
          <div className="equipmentOut__table-head">
            <div className="equipmentOut__table-headTh">
              Equipment Serial
            </div>
            <div className="equipmentOut__table-headTh">
              Type
            </div>
            <div className="equipmentOut__table-headTh">
              Facility
            </div>
          </div>
          <div className="equipmentOut__table-body">
            {
              data?.map((item, index) => {
                return (
                  <div className="equipmentOut__table-row" key={index}>
                  <div className="equipmentOut__table-th">
                    {item?.title}
                  </div>
                  <div className="equipmentOut__table-th">
                    {item?.type}
                  </div>
                  <div className="equipmentOut__table-th">
                    {item?.facility}
                  </div>
                </div>)
              })
            }

          </div>
        </div>
        <div className="modal__content-bottom">
          <button className="save full" onClick={exit}>
            Got it
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default EquipmentOutModal;
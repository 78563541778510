import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import './index.scss';
import TinyBarChart from "../../components/Charts/TinyBarChart";
import CustomPieChart from "../../components/Charts/CustomPieChart";
import TotalPieChart from "../../components/Charts/TotalPieChart";
import VerticalPieChart from "../../components/Charts/VerticalPieChart";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import TinyStackedBarChart from "../../components/Charts/TinyStackedBarChart";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
  useLazyDashboardPersonalStatsQuery,
  useLazyDashboardEquipmentStatsQuery,
  useLazyDashboardPersonalInfoQuery,
  useLazyDashboardOutStandingInspectionsQuery,
  useLazyDashboardOutStandingRtsQuery,
  useLazyDashboardCorrectiveActionsQuery,
  useLazyDashboardAveragePerInspectionQuery,
  useGetUsersRolesQuery, useLazyDashboardInspectionsQuery
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import moment from "moment";
import AverageTimeBar from "../../components/Charts/AverageTimeBar";
import {Helmet} from "react-helmet";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [outStandingInsp, setOutStandingInsp]= useState(null);
  const [tableOptions, setTableOptions] = useState([{value: '', label: 'All'}]);
  const [inspectionBarFilter, setInspectionFilter] = useState();
  const [filterPeriodOptions, setFilterPeriodOptions] = useState([
    {value: "week", label: "Last Week"},
    {value: "month", label: "Last Month"},
    {value: "quarter", label: "Last Quarter"},
    {value: "semiannual", label: "Last 6 Months"},
    {value: "annual", label: "TTM"},
  ])
  const [averageFilters, setAverageFilters] = useState({
    filter_period: filterPeriodOptions[0].value,
    facility_id: null,
    equipment_type_id: null,
    employee_id: null,
  })

  const [getDashboardPersonalStats, {
    data: dashboardPersonalStatsData,
    error: dashboardPersonalStatsError,
    isFetching: dashboardPersonalStatsIsLoading,
    isSuccess: dashboardPersonalStatsIsSuccess,
    isError: dashboardPersonalStatsIsError,
  }] = useLazyDashboardPersonalStatsQuery();

  const [getDashboardEquipmentStats, {
    data: dashboardEquipmentStatsData,
    error: dashboardEquipmentStatsError,
    isFetching: dashboardEquipmentStatsIsLoading,
    isSuccess: dashboardEquipmentStatsIsSuccess,
    isError: dashboardEquipmentStatsIsError,
  }] = useLazyDashboardEquipmentStatsQuery();

  const [getDashboardPersonalInfo, {
    data: dashboardPersonalInfoData,
    error: dashboardPersonalInfoError,
    isFetching: dashboardPersonalInfoIsLoading,
    isSuccess: dashboardPersonalInfoIsSuccess,
    isError: dashboardPersonalInfoIsError,
  }] = useLazyDashboardPersonalInfoQuery();

  const [getDashboardOutStandingInspections, {
    data: dashboardOutStandingInspectionsData,
    error: dashboardOutStandingInspectionsError,
    isFetching: dashboardOutStandingInspectionsIsLoading,
    isSuccess: dashboardOutStandingInspectionsIsSuccess,
    isError: dashboardOutStandingInspectionsIsError,
  }] = useLazyDashboardOutStandingInspectionsQuery();

  const [getDashboardOutStandingRts, {
    data: dashboardOutStandingRtsData,
    error: dashboardOutStandingRtsError,
    isFetching: dashboardOutStandingRtsIsLoading,
    isSuccess: dashboardOutStandingRtsIsSuccess,
    isError: dashboardOutStandingRtsIsError,
  }] = useLazyDashboardOutStandingRtsQuery();

  const [getDashboardCorrectiveActions, {
    data: dashboardCorrectiveActionsData,
    error: dashboardCorrectiveActionsError,
    isFetching: dashboardCorrectiveActionsIsLoading,
    isSuccess: dashboardCorrectiveActionsIsSuccess,
    isError: dashboardCorrectiveActionsIsError,
  }] = useLazyDashboardCorrectiveActionsQuery();

  const [getDashboardAveragePerInspection, {
    data: dashboardAveragePerInspectionData,
    error: dashboardAveragePerInspectionError,
    isFetching: dashboardAveragePerInspectionIsLoading,
    isSuccess: dashboardAveragePerInspectionIsSuccess,
    isError: dashboardAveragePerInspectionIsError,
  }] = useLazyDashboardAveragePerInspectionQuery();

  const [getDashboardInspections, {
    data: dashboardInspectionsData,
    error: dashboardInspectionsError,
    isFetching: dashboardInspectionsIsLoading,
    isSuccess: dashboardInspectionsIsSuccess,
    isError: dashboardInspectionsIsError,
  }] = useLazyDashboardInspectionsQuery();
  const {
    data: userRolesData,
    isSuccess: userRolesIsSuccess,
    isLoading: userRolesIsLoading,
    isError: userRolesIsError,
    error: userRolesError,
  } = useGetUsersRolesQuery();

  useEffect(() => {
    dispatch(setLoader(true))
          Promise.all([
            getDashboardPersonalStats(),
            getDashboardEquipmentStats(),
            getDashboardPersonalInfo({name: ""}),
            getDashboardOutStandingInspections(),
            getDashboardOutStandingRts(),
            getDashboardCorrectiveActions(),
            getDashboardAveragePerInspection( new URLSearchParams({filter_period: 'week'}).toString()),
            getDashboardInspections({period: 'week'})])
          .then(res => {
            dispatch(setLoader(false))
          })
          .catch(err => {
            dispatch(setLoader(false))
          });
  }, []);

  useEffect(() => {
    if (userRolesIsSuccess) {
      if (tableOptions?.length < 2) {
        setTableOptions(old => [...old, ...userRolesData?.map(item => ({value: item?.key, label: item?.name}))])
      }

    }
  }, [userRolesIsSuccess])

  function filterNullValues(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj[key]) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  useEffect(() => {
    let obj = {
      filter_period: averageFilters?.filter_period,
      facility_id: averageFilters?.facility_id,
      equipment_type_id: averageFilters?.equipment_type_id,
      employee_id: averageFilters?.employee_id,
    }

    const newObj = filterNullValues(obj);

    let params = new URLSearchParams(newObj).toString()
    getDashboardAveragePerInspection(params);

  }, [averageFilters])

  const handleTableChange = (e) => {
    getDashboardPersonalInfo({name: e.value})
  }

  const handleInspectionFilter = (e) => {
    getDashboardInspections({period: e})
  }

  const handleAverageFilter = (e) => {
    setAverageFilters(old => ({...old, [e.type]: e.value}));
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Dashboard | NDX</title>
      </Helmet>
      <DashboardHeader title={'Dashboard'} type={'dashboard'} action={() => navigate('/advanced-metrics')} />
      <div className="dashboard">
        <div className="dashboard__block">
          <TotalPieChart data={dashboardEquipmentStatsData} />
          <AverageTimeBar
            size={'small'}
            data={dashboardAveragePerInspectionData?.data}
            title={'Average Time Per Inspection'}
            options={filterPeriodOptions}
            handlePeriodChange={handleAverageFilter}
            setAverageFilters={setAverageFilters}
          />
        </div>
        <div className="dashboard__block">
          <TinyStackedBarChart
            options={filterPeriodOptions}
            title={"Completed Inspections"}
            data={dashboardInspectionsData?.data?.map(item => ({...item, name: item?.key, value1: item?.value?.nfc, value2: Number(item?.value?.total) - Number(item?.value?.nfc)}))}
            handleChange={handleInspectionFilter}
          />
          <TinyBarChart
            size={'big'}
            data={dashboardOutStandingInspectionsData?.data?.map(item => ({...item, key: moment(item.key).format('MM/DD/YY')}))}
            title={"Open Inspections"}
            isTime={false}
          />
        </div>
        <div className="dashboard__block">
          <TinyBarChart
            size={'big'}
            data={dashboardCorrectiveActionsData?.data?.map(item => ({...item, key: moment(item.key).format('MM/DD/YY')}))}
            title={"Corrective Actions Created"}
            isTime={false}
          />
          <TinyBarChart
            size={'big'}
            data={dashboardOutStandingRtsData?.data?.map(item => ({...item, key: moment(item.key).format('MM/DD/YY')}))}
            title={"Open Return to Service Inspections"}
            isTime={false}
          />
        </div>
        <div className="dashboard__block">
          <VerticalPieChart legendHeight={'centered'} surface={true} width={'small'} data={dashboardPersonalStatsData?.data} title={"Personnel Stats"} />
          <DashboardTable
            title={"User Information"}
            showSelect={true}
            data={dashboardPersonalInfoData?.data}
            options={tableOptions}
            handleChange={handleTableChange}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
import React, { useEffect, useState } from "react";
import Notification from "../Notification";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetFacilityQuery,
  useLazyRefreshInspectionsQuery,
} from "../../store/builder/inspections.api";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";

export default function DashboardHeader({
  title,
  showBtn,
  type = "",
  action,
  inspection_type = "",
  secondAction,
  dataForSecondAction,
  facilityId
}) {
  const [notificationOpened, setNotificationOpened] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);

  const [
    refreshInspectionAction,
    { error: refreshError, isFetching: refreshIsLoading },
  ] = useLazyRefreshInspectionsQuery();

  const [getFacility,{
    data: facilityData,
    isError: facilityIsError,
    isFetching: facilityIsLoading,
    error: facilityError,
    isSuccess: facilityIsSuccess,
  }] = useLazyGetFacilityQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    refreshError,
    facilityError,
  ]);

  useEffect(() => {
    // return window.addEventListener("click", (e) => {
    //   if (
    //     e.target.closest(".header__notification") === null &&
    //     e.target.closest(".notification") === null
    //   ) {
    //     setNotificationOpened(false);
    //   }
    // });
    getFacility()
  }, []);

  useEffect(() => {
    if (refreshIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [refreshIsLoading]);

  const toggleNotification = () => setNotificationOpened(!notificationOpened);

  function formatString(inputString) {
    return inputString.replace(/[_\W]+/g, ' ');
  }

  return (
    <div className="header">
      {notificationOpened && <Notification />}
      {type === "inspection_form_update" || type === "inspection_fill" ? (
        <div>
          <div className="header__subtitle">
            {type === "inspection_form_update"
              ? title
              : formatString(inspection_type) + " Inspection"}
          </div>
          <div className="header__title_i">
            {type === "inspection_form_update" ? "Categories" : title}
          </div>
        </div>
      ) : (
        <p className="header__title">{title}</p>
      )}
      <div className="header__right">
        {type === "inspection-form" ? (
          <div className="header__btn" onClick={action}>
            Create Inspect
          </div>
        ) : // ) : type === "actions" ? (
        //   <div className="header__btns">
        //     <div className="header__btn white">
        //       <img src="/assets/icons/file.svg" alt="icon" />
        //       <span>Activity Report</span>
        //     </div>
        //     <div className="header__btn">
        //       <img src="/assets/icons/print.svg" alt="icon" />
        //       <span>Print</span>
        //     </div>
        //   </div>

        type === "receiptForm" ? (
          <div className="header__btn" onClick={action}>Create Report</div>
        ) :
          type === "logSheets" ? (
            showBtn &&
              <div className="header__btn" onClick={action}>
                <span>Clear inspections</span>
              </div>
            ) :
          type === "serviceRequest" ? (
              <div className="header__btn" onClick={action}>Create Observation</div>
            ) :
        type === "inspection" ? (
          <>
            {
              userInfo.role.name === "admin" && process.env.REACT_APP_ENVIRONMENT !== "production" ?
                <div
                  className="header__btn"
                  onClick={() => {
                    refreshInspectionAction().then((res) => {
                      secondAction(facilityId);
                    });
                  }}
                >
                  Refresh Inspections
                </div> : null
            }

            <div className="header__btn plus" onClick={action}>
              <img src="/assets/icons/inspection-plus.svg" alt="plus"/>
            </div>
          </>
        ) : type === "equipment" ? (
          <div className="header__btn" onClick={action}>
            Create Equipment
          </div>
        ) : type === "tools" ? (
          <div className="header__btn" onClick={action}>
            Create Tool
          </div>
        ) : type === "facility-edit" ? (
          <div className="header__btns">
            <div className="header__btn cancel" onClick={() => navigate(-1)}>
              Cancel
            </div>
            <div className="header__btn" onClick={action}>
              Save
            </div>
          </div>
        ) : type === "management" ? (
          <div className="header__btn" onClick={action}>
            Invite User
          </div>
        ) :
        type === "facility" ? (
          <div className="header__btn" onClick={action}>
            Create Facility
          </div>
        )
        //   : type === "agancy" ? (
        //   <div className="header__btn" onClick={action}>
        //     Create FBO
        //   </div>
        // ) :
         : type === "order" ? (
              <div className="header__btn" onClick={action}>
                Create Manual Order
              </div>
            ) :
          type === "report" ? (
          <div className="header__btn" onClick={action}>
            Create Report
          </div>
        ) :
          type === 'equipment-edit' ?
            <div className="header__btns">
              <div className="header__btn cancel" onClick={() => navigate(-1)}>
                Cancel
              </div>
              <div className="header__btn" onClick={action}>
                Save
              </div>
            </div> :
            type === 'equipment-type' ?
              <div className="header__btn" onClick={action}>Assigned Inspections</div> :
              type === 'assigned' ?
                <div className="header__btn cancel" onClick={() => navigate(-1)}>
                  Cancel
                </div> :
                type === 'dashboard' ?
                  <div className="header__btn green" onClick={action}>
                    <img src="/assets/icons/bar-chart-icon.svg" alt="icon"/>
                    Advanced metrics
                  </div> :
                type === 'agancy-trial' ?
                  <div className="header__btns">
                    <div className="header__btn invite" onClick={action}>
                      Invite FBO
                    </div>
                    <div className="header__btn" onClick={secondAction}>
                      Create FBO
                    </div>
                  </div> :
                  type === 'agancy-invited' ?
                  <div className="header__btns">
                    <div className="header__btn" onClick={action}>
                      Invite FBO
                    </div>
                  </div> :
                  type === 'invite-corporate' ?
                  <div className="header__btns">
                    <div className="header__btn invite" onClick={action}>
                      Invite Corporate
                    </div>
                  </div> :
                    // type === 'fbo-management' ?
                    //   <div className="header__btns">
                    //     <div className="header__btn" onClick={action}>
                    //       Create FBO
                    //     </div>
                    //   </div> :
                    type === 'corporate' ?
                      <div className="header__btns">
                        <div className="header__btn grey" onClick={action}>
                          Create Corporate
                        </div>
                        <div className="header__btn" onClick={secondAction}>
                          Invite Corporate
                        </div>
                      </div> :
                      // type === "inspection_fill_skip" ?
                      //   <div className="header__btns">
                      //     <div className="header__btn danger" onClick={action}>
                      //       Skip Inspection
                      //     </div>
                      //   </div> :
        null
        }
      </div>
    </div>
  );
}

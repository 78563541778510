import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import {
  useCompleteInspectionMutation,
  useLazyGetCommentsQuery,
  useLazyGetCurrentInspectionAndCategoryQuery,
  useLazyGetInspectionFacilityByIdQuery, useSkipInspectionMutation,
} from "../../store/builder/inspections.api";
import { setFields, setLoader } from "../../store/builder/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import "./index.scss";
import Checked from "../../SVG/Checked";
import FIllField from "../../components/FIllField";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import axios from "axios";
import CommentsIcon from "../../SVG/CommentsIcon";
import CommentsInspections from "../../components/CommentsInspections";

export default function FillInspection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [currentItem, setCurrentItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showImages, setShowImages] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [lastCategory, setLastCategory] = useState(false);
  const [sameIdsError, setSameIdsError] = useState(false)
  const fields = useSelector((state) => state.rootReducer.reducer.fields);
  const [localLoaderStatus, setLocalLoaderStatus] = useState(false);
  const [requestSended, setRequestSended] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const [commentsStatus, setCommentsStatus] = useState(false);
  const [sendData, setSendData] = useState({
    items: [
      {
        inspection_id: params?.id,
        fields: [],
      },
    ],
  });

  const [startTime, setStartTime] = useState(null);
  const [
    complete,
    {
      isError: completeIsError,
      isFetching: completeIsLoading,
      isSuccess: completeIsSuccess,
      error: completeError,
    },
  ] = useCompleteInspectionMutation();

  const [
    skipInspection,
    {
      data: skipInspectionData,
      isError: skipInspectionIsError,
      isLoading: skipInspectionIsLoading,
      isSuccess: skipInspectionIsSuccess,
      error: skipInspectionError,
    },
  ] = useSkipInspectionMutation();

  const [
    getInspectionAndCategory,
    {
      data: getInspectionAndCategoryData,
      isError: getInspectionAndCategoryIsError,
      isFetching: getInspectionAndCategoryIsLoading,
      isSuccess: getInspectionAndCategoryIsSuccess,
      error: getInspectionAndCategoryError,
    },
  ] = useLazyGetCurrentInspectionAndCategoryQuery();

  const [getInspectionById, { data, isLoading, isError, error, isSuccess }] =
    useLazyGetInspectionFacilityByIdQuery();

  const [getComments,
    {
      data: getCommentsData,
      isError: getCommentsIsError,
      isFetching: getCommentsIsLoading,
      isSuccess: getCommentsIsSuccess,
      error: getCommentsError,
    }] = useLazyGetCommentsQuery();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    error,
    completeError,
    skipInspectionError,
  ]);

  const getCommentsAction = () => {
    getComments(params?.id)
  }

  useEffect(() => {
    if (commentsStatus) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [commentsStatus])

  useEffect(() => {
    if (isSuccess) {
      setShowCategories([...data?.categories])
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (skipInspectionIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (skipInspectionIsSuccess) {
      dispatch(setLoader(false));
      setSkipModal(false)
      toast.success("Inspection has been successfully skipped");
      navigate('/inspections')
    }
    if (skipInspectionIsError) {
      dispatch(setLoader(false));
    }
  }, [skipInspectionIsLoading, skipInspectionIsSuccess, skipInspectionIsError])

  useEffect(() => {
    getCommentsAction()
  }, [])

  useEffect(() => {
    // setLocalLoaderStatus(true);
    if (!requestSended) {
      // dispatch(setFields({payload: null}))
      //
      getInspectionById(params?.id)
        .unwrap()
        .then(res => {
          setCurrentItem(null);
          dispatch(setLoader(false));
          setLocalLoaderStatus(false);
          setRequestSended(true)
          let neededCategoryIdx = null;
          res?.categories?.forEach((item, index) => {
            let countHidden = 0;
            if (neededCategoryIdx === null) {
              countHidden = item?.fields?.filter(el => el.hidden)?.length
              if (item?.fields?.length !== countHidden) {
                neededCategoryIdx = index;
                return item;
              }
            } else {
              return
            }
          })
          setCurrentItem(() => {
            setStartTime(new Date())
            if (currentItem) {
              return currentItem
            } else {
              return res?.categories[neededCategoryIdx]
              // return res?.categories[0]
            }
            // currentItem ? currentItem : res?.categories[0]
          });
          setCurrentIndex(currentIndex ? currentIndex : neededCategoryIdx);
          setSendData((old) => ({
            items: [
              {
                ...old.items[0],
                category_id: currentItem
                  ? currentItem?.id
                  : res?.categories[neededCategoryIdx]?.id,
              },
            ],
          }));
          dispatch(
            setFields({
              fields: currentItem
                ? currentItem?.fields?.filter(el => {
                  if (el.data_type === 'flow_rate' && el.hidden) {
                    return el
                  } else {
                    return !el.hidden
                  }
                })
                : res?.categories[neededCategoryIdx]?.fields?.filter(el => {
                  if (el.data_type === 'flow_rate' && el.hidden) {
                    return el
                  } else {
                    return !el.hidden
                  }
                }),
              type: "full",
            })
          );
        })
        .catch((err) => {
          dispatch(setLoader(false));
          setRequestSended(true)
        })
    }
  }, [currentItem]);

  useEffect(() => {
    if (currentItem) {
      setSendData((old) => {
        return ({items: [{...old.items[0], category_id: currentItem?.id, fields: currentItem.fields}]});
      });
    }
  }, [currentItem])

  useEffect(() => {
    if (currentItem) {
      if (
        data?.categories[data?.categories.length - 1]?.id === currentItem?.id
      ) {
        setLastCategory(true);
      } else {
        setLastCategory(false);
      }
    }
  }, [currentItem]);

  useEffect(() => {
    let hasDuplicate = false;
    for (let i = 0; i < currentItem?.fields?.length; i++) {
      for (let j = i + 1; j < currentItem?.fields?.length; j++) {
        if (currentItem?.fields[i]?.id === currentItem?.fields[j]?.id) {
          hasDuplicate = true;
          break;
        }
      }
      if (hasDuplicate) break;
    }
    setSameIdsError(hasDuplicate);
  }, [currentItem])

  useEffect(() => {
    if (currentItem?.fields?.filter(el => el.children_optional_when)?.length > 0) {
      let parentField = fields?.filter(el => el.hasOwnProperty('children_optional_when'))[0]
      if (parentField) {
        if (parentField !== undefined && parentField !== null) {
          let parentFieldCheck = parentField?.children_optional_when?.split(',')
          if (parentFieldCheck?.filter(el => el === parentField?.answers[0])[0]) {
            setCurrentItem(old => ({
              ...old, fields: fields?.map(field => {
                if (!field?.hidden) {
                  if (field.hasOwnProperty('parent_id')) {
                    if (field.parent_id === parentField?.id) {
                      if (field.tooltip.toLowerCase() !== 'optional') {
                        return {...field, optional: true, web_hidden: true}
                      } else {
                        return {...field, web_hidden: true}
                      }
                    } else {
                      return field
                    }
                  } else {
                    return field
                  }
                } else {
                  return field
                }

              })
            }))
          } else {
            setCurrentItem(old => ({
              ...old, fields: fields?.map(field => {
                if (!field?.hidden) {
                  if (field.hasOwnProperty('parent_id')) {
                    if (field.parent_id === parentField?.id) {
                      if (field.tooltip.toLowerCase() !== 'optional') {
                        return {...field, optional: false, web_hidden: false}
                      } else {
                        return {...field, web_hidden: false}
                      }
                    } else {
                      return field
                    }
                  } else {
                    return field
                  }
                } else {
                  return field
                }
              })
            }))
          }
        }
      } else {
        if (currentItem) {
          setCurrentItem(old => ({...old, fields}))
        }
      }
    } else {
      setSendData(old => ({
        items: [{ ...old.items[0], category_id: currentItem?.id, fields: fields }],
      }))
    }
  }, [fields]);

  const handleItem = (item, index) => {
    setStartTime(new Date());
    setCurrentItem(null);
    setCurrentIndex(null);
    setTimeout(() => {
      setCurrentItem(item);
      setCurrentIndex(index);
      setSendData((old) => ({
        items: [{ ...old.items[0], category_id: item?.id, fields: [] }],
      }));
      dispatch(setFields({ fields: item?.fields, type: "full" }));
    }, 0);
  };

  const handleFile = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setShowImages(imagesArray);
  };

  const renderCategories = () => {
    if (location.search) {
      return (
        <div
          key={getInspectionAndCategoryData?.categories?.id}
          className={`fillInspection__item fillInspection__item_active`}
        >
          <div className="fillInspection__item-title">
            {getInspectionAndCategoryData?.categories?.label}
          </div>
          {getInspectionAndCategoryData?.categories?.is_completed &&
            <div className="fillInspection__item-text">
              Submitted by: {getInspectionAndCategoryData?.categories?.submitted_by}
            </div>
          }
          <div className="fillInspection__item-icon">
            <Checked colorBg={"#FFFFFF"} colorCheck={"#4268B0"}/>
          </div>
        </div>
      );
    } else {
      return showCategories?.map((item, index) => {
        const filteredCategoryFields = item?.fields?.filter(el => el.hidden)
        if (filteredCategoryFields?.length !== item?.fields?.length) {
          if (!item?.is_hidden) {
            return (<div
              onClick={() => {
                window.scroll({top: 0, behavior: "smooth"})
                handleItem(item, index)
              }}
              key={item?.id}
              className={`fillInspection__item ${
                currentItem?.id === item?.id ? "fillInspection__item_active" : ""
              } ${item?.is_completed ? "fillInspection__item_completed" : ""}`}
            >
              <div className="fillInspection__item-box">
                <div className="fillInspection__item-title">{item?.label}</div>
                {item?.is_completed &&
                  <div className="fillInspection__item-text">
                    Submitted by: <span>{item?.submitted_by}</span>
                  </div>
                }
              </div>

              <div className="fillInspection__item-icon">
                <Checked
                  colorBg={
                    currentItem?.id === item?.id && item?.is_completed
                      ? "#82C971"
                      : currentItem?.id === item?.id
                        ? "#FFFFFF"
                        : item?.is_completed
                          ? "#82C971"
                          : "#AEB3B7"
                  }
                  colorCheck={
                    currentItem?.id === item?.id && item?.is_completed
                      ? "#FFF"
                      : currentItem?.id === item?.id
                        ? "#4268B0"
                        : item?.is_completed
                          ? "#FFF"
                          : "#FFF"
                  }
                />
              </div>
            </div>)
          }
        } else {
          return null;
        }
    });
    }
  };
  const renderImages = () => {
    return showImages?.map((item, index) => (
      <div key={index} className="fillInspection__field-commentImage">
        <div className="fillInspection__field-commentImageDel">
          <img src="/assets/icons/trash2.svg" alt="trash" />
        </div>
        <img className="fillInspection__field-commentImg" src={item} />
      </div>
    ));
  };

  const renderFields = () => {
    return currentItem?.fields?.map((item) => {
      if (item?.hasOwnProperty('parent_id')) {
        if (item?.hasOwnProperty('optional')) {
          return item
        } else {
          return {...item, optional: true};
        }
      } else {
        return item
      }
    })?.map((field) => {
      if (!field?.hidden && !field.web_hidden) {
        return (
          <FIllField
            sendData={sendData}
            setSendData={setSendData}
            key={field?.id}
            item={field}
            handleFile={handleFile}
            renderImages={renderImages}
            setCurrentItem={setCurrentItem}
            currentItem={currentItem}
            sheet={location?.state?.sheet}
            inspection_id={params?.id}
            readOnly={location?.state?.readOnly}
          />
        )
      }
  });
  };

  const checkFsi = () => {
    let fsi;
    fsi = sendData?.items[0]?.fields?.some(item => (item?.data_type === "fsii_available" && item?.answers[0] === "N/A"));
    if (fsi) {
      sendData.items[0].fields = sendData?.items[0]?.fields?.map((field) => {
        if (field?.data_type !== "fsii_available" && field.data_type?.includes('fsii')) {
          return { ...field, optional: true };
        }
        return field;
      })
    }
  }

  const completeInspection = () => {
    let s = [];
    let a = [];
    let skipValidation;
    skipValidation = sendData?.items[0]?.fields?.some(item => (item?.data_type === "dp_available" || item?.data_type === "corrected_dp_available") && item?.answers[0] === "N/A")
    if (!skipValidation) {
      checkFsi();
      sendData?.items[0]?.fields?.forEach((field) => {
        if (
          field?.type === "sump_sample" ||
          field?.type === "sump_sample_optional"
        ) {
          if (field?.optional === false || field?.optional === undefined) {
            if (field?.answers.length === 0) {
              toast.error(field?.label + " required");
              s.push(false);
            } else {
              if (field?.answers[0]?.length > 1) {
                a.push(field?.label);
              } else {
                toast.error(field?.label + " required");
                s.push(false);
              }
            }
          }
        } else if (field?.type === "dry_rating") {
          if (!field?.optional) {
            if (field?.required) {
              toast.error(field?.label + "'s Colorimetric Scale and Colorimetric Graduation fields are requied");
              s.push(false);
            } else {
              a.push(field?.label);
            }
          } else {
            a.push(field?.label);
          }
        } else {
          if (field?.data_type !== "flow_rate") {
            if (field?.optional === false || field?.optional === undefined) {
              if (field?.answers.length === 0 || field?.answers[0]?.length === 0) {
                toast.error(field?.label + " required");
                s.push(false);
              } else {
                a.push(field?.label);
              }
            }
          } else {
            a.push(field?.label)
          }
        }
      });
    }
    if (s.length === 0) {
      setRequestSended(false)
      const newSendData = JSON.parse(JSON.stringify(sendData))
      newSendData?.items[0]?.fields?.map(field => {
        if (field?.optional === true || field?.hidden === true) {
          if (field?.answers?.length === 0) {
            field.answers = [""]
          }
        }
        return field;
      })
      // add timestamps
      // setEndTime(new Date());
      let startTimeStamp = startTime?.getTime();
      newSendData.items[0].start_timestamp = String(startTimeStamp)?.slice(0, 10);
      let endTime = new Date()?.getTime();
      newSendData.items[0].end_timestamp = String(endTime)?.slice(0, 10);

      dispatch(setLoader(true));
      setLocalLoaderStatus(true);

      // CHECK FOR HIDDEN CATEGORY
      let fieldWithChildCategory = sendData?.items[0]?.fields?.filter(item => item.child_category)[0]
      if (fieldWithChildCategory?.answers[0] === fieldWithChildCategory?.child_category?.answer) {
        setShowCategories(old => [
          ...old.map(item => {
            if (item.category_code === fieldWithChildCategory?.child_category?.code[0]) {
              return {...item, is_hidden: false }
            } else {
              return item
            }
          })
        ])
      }
      axios.post(`${process.env.REACT_APP_API_URL}inspection/save`,newSendData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ndx_user_token")}`,
          "time": String(Date.now()),
          "agency": localStorage.getItem("current-fbo"),
          "DeviceType": 'web',
          "AppVersion": '1.0.0',
          "AppType": 'web'
        },
      }).then((res) => {
        getInspectionById(params?.id)
          .unwrap()
          .then(res => {
            dispatch(
              setFields({
                fields: res?.data?.categories[as]?.fields,
                type: "full",
              })
            );
          }).catch(err => {
        })
        toast.success(res.data.message, 500);
        let as = null;
        const checkCompleted = (index) => {
          let checked = null;
          if (!data?.categories[index]?.is_completed && !data?.categories[index].is_hidden && index !== currentIndex) {
              checked = index;
          }
          return checked;
        };
        let f = data?.categories?.filter(
          (item, index) => !item?.is_completed && index !== currentIndex
        ).length;
        if (f > 0) {
          showCategories?.every((item, index) => {
            let checked = checkCompleted(index);
            if (checked) {
              as = checked;
              return false;
            } else {
              return true;
            }
          });
          setCurrentItem(null);
          setCurrentIndex(null);
          setTimeout(() => {
            setCurrentItem(data?.categories[as]);
            setCurrentIndex(as);
            setSendData((old) => ({
              items: [
                {
                  ...old.items[0],
                  category_id: data?.categories[as]?.id,
                  fields: [],
                },
              ],
            }));
            dispatch(setLoader(false));
          }, 0);
        } else {
          dispatch(setLoader(false));
          setTimeout(() => {
            if (location.search) {
              setModalStatus(true);
            } else {
              navigate(-1);
            }
          }, 1000);
        }
      })
        .catch((err) => {
          dispatch(setLoader(false));
          setLocalLoaderStatus(false);
          if (err?.data?.comment_text?.length > 0) {
            err?.data?.comment_text?.forEach(el => {
              toast.error(el, 1000);
            })
          }
        });
      // ---------------------------
      // complete(newSendData).unwrap()
      //   .then((res) => {
      //     getInspectionById(params?.id)
      //       .unwrap()
      //       .then(res => {
      //         dispatch(
      //           setFields({
      //             fields: res?.data?.categories[as]?.fields,
      //             type: "full",
      //           })
      //         );
      //     }).catch(err => {
      //     })
      //     toast.success(res.data.message, 500);
      //     let as = null;
      //     const checkCompleted = (index) => {
      //       let checked = null;
      //       if (
      //         !data?.categories[index]?.is_completed &&
      //         index !== currentIndex
      //       ) {
      //         checked = index;
      //       }
      //       return checked;
      //     };
      //     let f = data?.categories?.filter(
      //       (item, index) => !item?.is_completed && index !== currentIndex
      //     ).length;
      //     if (f > 0) {
      //       showCategories?.every((item, index) => {
      //         let checked = checkCompleted(index);
      //         if (checked) {
      //           as = checked;
      //           return false;
      //         } else {
      //           return true;
      //         }
      //       });
      //       setCurrentItem(null);
      //       setCurrentIndex(null);
      //       setTimeout(() => {
      //         setCurrentItem(data?.categories[as]);
      //         setCurrentIndex(as);
      //         setSendData((old) => ({
      //           items: [
      //             {
      //               ...old.items[0],
      //               category_id: data?.categories[as]?.id,
      //               fields: [],
      //             },
      //           ],
      //         }));
      //         dispatch(setLoader(false));
      //       }, 0);
      //     } else {
      //       dispatch(setLoader(false));
      //
      //       setTimeout(() => {
      //         if (location.search) {
      //           setModalStatus(true);
      //         } else {
      //           navigate(-1);
      //         }
      //       }, 1000);
      //     }
      //   })
      //   .catch((err) => {
      //     dispatch(setLoader(false));
      //     setLocalLoaderStatus(false);
      //     if (err?.data?.comment_text?.length > 0) {
      //       err?.data?.comment_text?.forEach(el => {
      //         toast.error(el, 1000);
      //       })
      //     }
      //   });
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Inspection Fill | NDX</title>
      </Helmet>
      <DashboardHeader
        title={data?.inspection_name}
        showBtn={true}
        type={data?.period === "daily" || data?.period === "weekly" ? data?.is_complete ? "" : "inspection_fill_skip" : "inspection_fill"}
        inspection_type={data?.period}
        action={() => setSkipModal(true)}
      />
      {
        commentsStatus && <CommentsInspections inspectionId={params?.id} data={getCommentsData} getCommentsAction={getCommentsAction} exit={() => setCommentsStatus(false)} />
      }
      {
        skipModal &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Skip Inspections</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setSkipModal(false)}
              />
            </div>
            <div
              className="modal__content-body"
              style={{display: "flex", justifyContent: "center"}}
            >
              Are you sure want to Skip Inspections?
            </div>
            <div className="modal__content-bottom">
              <button
                className="close"
                onClick={() => setSkipModal(false)}
              >
                Cancel
              </button>
              <button className="save danger" onClick={() => {
                skipInspection(data?.inspection_id)
              }}>Skip</button>
            </div>
          </div>
          <div
            className="modal__bg"
          ></div>
        </div>
      }
      <div className="fillInspection">
        <div className="fillInspection__left">
          {
            getCommentsData &&
            <div className="fillInspection__comments" onClick={() => setCommentsStatus(true)}>
              <CommentsIcon/>
              <p>comments {`(${getCommentsData?.length})`}</p>
            </div>
          }
          <div className="fillInspection__counter">
            <p className="fillInspection__counter-text">Completed:</p>
            <p className="fillInspection__counter-text">{data?.status}</p>
          </div>
          <div className="fillInspection__list">
            {renderCategories()}
          </div>
        </div>
        {
          data?.is_skipped ?
            <div className="fillInspection__right">
              <div className="fillInspection__fields">
                <div className="fillInspection__right-name">
                  {currentItem?.label}
                </div>
              </div>
              <div className="fillInspection__skip">
                <img src="/assets/skip-inspections.svg" alt="skip"/>
                <p className="fillInspection__skip-text">
                  Inspections were skipped and assigned to NOT USED status
                </p>
              </div>
            </div>
            :
          currentItem?.fields?.length > 0 && (
          <div className="fillInspection__right">
            <div className="fillInspection__fields">
              <div className="fillInspection__right-name">
                {currentItem?.label}
              </div>
              {
                sameIdsError &&
                <div className="fillInspection__fields-error">
                  Fields have the same ids, this category may not be completed
                </div>
              }
              { localLoaderStatus ?

                <div>
                  <span className="skeleton-loader"></span>
                </div>
                :
                renderFields()
              }
            </div>
            {
              (location?.state?.readOnly || currentItem?.is_readonly)  ?
              // location?.state?.readOnly  ?
              (<p className="fillInspection__unable">
                Unable to answer the question because the grace period of X days has expired.
              </p>) :
                location?.state?.expired ?
                  <p className="fillInspection__unable">
                    Unable to answer the question because the grace period of {data?.grace_period_days} {data?.grace_period_days === 1 ? 'day' : 'days'} has expired.
                  </p>
                  :
                <div className="fillInspection__btns">
                  {currentItem?.is_completed || !location?.state?.sheet && (
                    <div
                      className="fillInspection__btn fillInspection__btn_close"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </div>
                  )}
                  {currentItem?.is_completed || !location?.state?.sheet && !sameIdsError && (
                    <div
                      onClick={() => {
                        completeInspection();
                      }}
                      className="fillInspection__btn"
                    >
                      {location.search
                        ? "Update Inspection"
                        : lastCategory
                          ? "Complete Inspection"
                          : "Save and Continue"}
                    </div>
                  )}
                </div>

            }
          </div>
        )}
      </div>
    </Wrapper>
  );
}

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  verifyToken: null,
  verifyTokenExpired: null,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setVerifyRegister(state, action) {
      state.verifyToken = action.payload
    },
    setVerifyRegisterTime(state, action) {
      state.verifyTokenExpired = action.payload
    }
  },
});

export const { setVerifyRegister, setVerifyRegisterTime } = registerSlice.actions;
export default registerSlice.reducer;

import moment from "moment";
import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import DashboardHeader from "../../components/DashboardHeader";
import Wrapper from "../../components/Wrapper";
import {
  useLazyGetOrderQuery,
  useUpdateOrderMutation, useUpdateOrderShippingAddressMutation,
} from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import { toast } from "react-toastify";
import ShippingAddressModal from "../../components/Profile/ShippingAddressModal";
import ShippingOrderModal from "../../components/Profile/ShippingOrderModal";

export default function OrderDetailsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const [shippingStatus, setShippingStatus] = useState(false);
  const [
    getOrder,
    {
      data: ordersData,
      error: ordersError,
      isLoading: ordersIsLoading,
      isSuccess: ordersIsSuccess,
      isError: ordersIsError,
    },
  ] = useLazyGetOrderQuery();

  const [
    updateOrder,
    {
      data: orderUpdateData,
      error: orderUpdateError,
      isLoading: orderUpdateIsLoading,
      isSuccess: orderUpdateIsSuccess,
      isError: orderUpdateIsError,
    },
  ] = useUpdateOrderMutation();

  useEffect(() => {
    getOrder(params?.id);
  }, []);
  useEffect(() => {
    if (!ordersData || orderUpdateIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [ordersIsLoading, ordersData, orderUpdateIsLoading]);

  const getOrderDetail = () => {
    getOrder(params?.id)
  }

  const updateProcessing = () => {
    updateOrder({
      id: ordersData?.data?.id,
      body: { status: "processing" },
    }).then((res) => {
      toast.success(res?.data?.message);
      getOrder(params?.id);
    });
  };
  const updateDelivered = () => {
    updateOrder({
      id: ordersData?.data?.id,
      body: { status: "delivered" },
    }).then((res) => {
      toast.success(res?.data?.message);
      getOrder(params?.id);
    });
  };
  return (
    <Wrapper>
      {shippingStatus && <ShippingOrderModal setShowPaymentModal={setShippingStatus} orderData={ordersData?.data?.shipping_address} id={ordersData?.data?.id} getOrder={getOrderDetail} />}
      <DashboardHeader title={"Order Details"} type={"inspection"} />
      <div className="inspections">
        <div className="inspections__orders">
          <div className="inspections__orders-head">
            <div className="inspections__orders-headInner">
              <p className="inspections__orders-info">
                ID:{" "}
                <span className="inspections__orders-id">
                  #{ordersData?.data?.id}
                </span>
              </p>
              <p className="inspections__orders-info">
                Date Order:{" "}
                <span className="inspections__orders-default">
                  {moment(ordersData?.data?.created_at).format("MM/DD/YY")}
                </span>
              </p>
              <p className="inspections__orders-info">
                Time Order:{" "}
                <span className="inspections__orders-default">
                  {moment(ordersData?.data?.created_at)?.format("HH:mm")}
                </span>
              </p>
              <p className="inspections__orders-info">
                Status:{" "}
                <span
                  className={`inspections__orders-default s4 ${ordersData?.data?.status}`}
                >
                  {ordersData?.data?.status}
                </span>
              </p>
            </div>
            {ordersData?.data?.post_sale_status === "seen" ? (
              <div
                className="inspections__orders-headUpdate"
                onClick={updateProcessing}
              >
                Set processing
              </div>
            ) : ordersData?.data?.post_sale_status === "processing" ? (
              <div
                className="inspections__orders-headUpdate"
                onClick={updateDelivered}
              >
                Set delivered
              </div>
            ) : null}
          </div>
          <div className="inspections__orders-body">
            <div className="inspections__orders-table">
              <div className="inspections__orders-tableBox">
                <div className="inspections__orders-tableTitle">FBO</div>
                <div className="inspections__orders-tableInfo">
                  {ordersData?.data?.agency_title}
                </div>
              </div>
              <div className="inspections__orders-tableBox">
                <div className="inspections__orders-tableTitle shipping">
                  <p>Shipping Address</p>
                  <div className="edit-address" onClick={() => setShippingStatus(true)}>
                    <img src="/assets/icons/edit-shipping.svg" alt="edit"/>
                    <span>Edit</span>
                  </div>
                </div>
                <div className="inspections__orders-tableInfo">
                  {ordersData?.data?.shipping_address?.name}
                </div>
                <div className="inspections__orders-tableInfo">
                  {ordersData?.data?.shipping_address?.country}
                </div>
                <div className="inspections__orders-tableInfo">
                  {ordersData?.data?.shipping_address?.line1}
                </div>
                {ordersData?.data?.shipping_address?.line2 && (
                  <div className="inspections__orders-tableInfo">
                    {ordersData?.data?.shipping_address?.line2}
                  </div>
                )}
                <div className="inspections__orders-tableInfo">
                  {ordersData?.data?.shipping_address?.city}
                  {ordersData?.data?.shipping_address?.state
                    ? "/" + ordersData?.data?.shipping_address?.state
                    : ""}
                </div>
              </div>
              <div className="inspections__orders-tableBox">
                <div className="inspections__orders-tableTitle">
                  Sale status
                </div>
                <div className="inspections__orders-tableInfo">
                  <span
                    style={{
                      display: "block",
                      textAlign: "center",
                      width: "100%",
                      maxWidth: 150,
                      backgroundColor: ordersData?.data?.highlight_color,
                      color: "#FFFFFF",
                      padding: "5px 10px",
                      borderRadius: 6,
                      fontSize: 15,
                    }}
                  >
                    {ordersData?.data?.post_sale_status}
                  </span>
                </div>
              </div>
              <div className="inspections__orders-tableBox">
                <div className="inspections__orders-tableTitle">Total</div>
                <div className="inspections__orders-tableInfo">
                  <span>${ordersData?.data?.total}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inspections">
        <h2
          style={{ paddingLeft: 24, paddingTop: 24, paddingBottom: 24 }}
          className="header__title"
        >
          Items
        </h2>
        <div className="inspectionsForm__box">
          <div className="inspectionsForm__table">
            <div className="inspectionsForm__table-head">
              <div
                style={{
                  width: "18%",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                ID
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Order ID
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Price
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Product Name
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                QTY
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Total
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Type
              </div>
            </div>
            {ordersData?.data?.items?.map((item) => (
              <div style={{cursor: 'default'}} className="inspectionsForm__table-row" key={item.id}>
                <div
                  style={{ width: "18%", paddingRight: "5px" }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.id}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.order_id}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  ${item?.price}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.product_name}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.qty}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  ${item?.total}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.type}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

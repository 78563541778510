import React, {useEffect, useState} from 'react';
import './index.scss';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import MetricsTable from "../../components/Dashboard/MetricsTable";
import StatisticBlock from "../../components/Dashboard/StatisticBlock";
import CustomPieChart from "../../components/Charts/CustomPieChart";
import VerticalPieChart from "../../components/Charts/VerticalPieChart";
import Dot from "../../SVG/Dot";
import Select from "react-select";
import MetricsInspectionColumn from "../../components/Metrics/MetricsInspectionColumn";
import moment from "moment/moment";
import TinyBarChart from "../../components/Charts/TinyBarChart";
import {
  mockBarChartData, mockCircleChartData,
  table1BodyMockData,
  table2BodyMockData, table3BodyMockData, table4BodyMockData,
  tableHead2MockData, tableHead3MockData, tableHead4MockData, tableHead5MockData, tableHead6MockData,
  tableHeadMockData, tableHeadOpenMockData, tableHeadPersonalMockData,
  testDataRow
} from "./mockData";
import {
  useGetEquipmentTypeQuery, useGetUsersRolesQuery,
  useLazyDashboardPersonalInfoQuery,
  useLazyDashboardPersonalStatsQuery,
  useLazyMetricsAverageInspectionDurationQuery,
  useLazyMetricsCorrectiveActionsStatsQuery,
  useLazyMetricsEquipmentCountTypeQuery, useLazyMetricsEquipmentDecommissionsQuery, useLazyMetricsEquipmentRemovedQuery,
  useLazyMetricsEquipmentRemovedYesterdayQuery,
  useLazyMetricsInspectionByEquipmentTypeQuery,
  useLazyMetricsInspectionsMissedQuery,
  useLazyMetricsInspectionsPlannedQuery,
  useLazyMetricsInspectionsYesterdayQuery,
  useLazyMetricsUpcomingInspectionsQuery
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import log from "../../SVG/Log";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

const colors = [
  "#548CC5",
  "#2AA58D",
  "#DF9800",
  "#E9EAEB",
]

const AdvancedMetrics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pieData = [
    { name: "Group A", value: 400, color: "#548CC5" },
    { name: "Group C", value: 300, color: "#2AA58D" },
  ];
  const corporateAgency = location?.search?.split('=')[1]
  const currentFbo = corporateAgency ? corporateAgency : localStorage.getItem("current-fbo");

  const [filterPeriodOptions, setFilterPeriodOptions] = useState([
    {value: "", label: "All"},
    {value: "daily", label: "Daily"},
    {value: "weekly", label: "Weekly"},
    {value: "monthly", label: "Monthly"},
    {value: "quarterly", label: "Quarterly"},
    {value: "semiannual", label: "Semi-Annual"},
    {value: "annual", label: "Annual"},
  ])

  const [filterPeriodShortOptions, setFilterPeriodShortOptions] = useState([
    {value: "weekly", label: "Weekly"},
    {value: "monthly", label: "Monthly"},
    {value: "quarterly", label: "Quarterly"},
    {value: "semiannual", label: "Semi-Annual"},
  ])
  const [filterLastPeriodShortOptions, setFilterLastPeriodShortOptions] = useState([
    {value: "weekly", label: "Last Week"},
    {value: "monthly", label: "Last Month"},
    {value: "quarterly", label: "Last Quarter"},
    {value: "semiannual", label: "Last 6 Months"},
    {value: "annual", label: "TTM"},
  ])
  const [chosenMissedFilters, setChosenMissedFilters] = useState({
    period: null,
    filter_for_period: 'weekly',
  })
  const [tableOptions, setTableOptions] = useState([{value: '', label: 'All'}]);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState([])
  const [metricsEquipmentCountState, setMetricsEquipmentCountState] = useState([]);
  const {
    data: equipmentTypesData,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
    isSuccess: equipmentTypesIsSuccess,
    isFetching: equipmentTypesIsLoading
  } = useGetEquipmentTypeQuery();

  const {
    data: userRolesData,
    isSuccess: userRolesIsSuccess,
    isLoading: userRolesIsLoading,
    isError: userRolesIsError,
    error: userRolesError,
  } = useGetUsersRolesQuery();

  const [getMetricsPlanned, {
    data: metricsPlannedData,
    error: metricsPlannedError,
    isFetching: metricsPlannedIsLoading,
    isSuccess: metricsPlannedIsSuccess,
    isError: metricsPlannedIsError,
  }] = useLazyMetricsInspectionsPlannedQuery();

  const [getMetricsYesterday, {
    data: metricsYesterdayData,
    error: metricsYesterdayError,
    isFetching: metricsYesterdayIsLoading,
    isSuccess: metricsYesterdayIsSuccess,
    isError: metricsYesterdayIsError,
  }] = useLazyMetricsInspectionsYesterdayQuery();

  const [getMetricsMissed, {
    data: metricsMissedData,
    error: metricsMissedError,
    isFetching: metricsMissedIsLoading,
    isSuccess: metricsMissedIsSuccess,
    isError: metricsMissedIsError,
  }] = useLazyMetricsInspectionsMissedQuery();

  const [getMetricsEquipmentCountType, {
    data: metricsEquipmentCountTypeData,
    error: metricsEquipmentCountTypeError,
    isFetching: metricsEquipmentCountTypeIsLoading,
    isSuccess: metricsEquipmentCountTypeIsSuccess,
    isError: metricsEquipmentCountTypeIsError,
  }] = useLazyMetricsEquipmentCountTypeQuery();

  const [getMetricsEquipmentRemoved, {
    data: metricsEquipmentRemovedData,
    error: metricsEquipmentRemovedError,
    isFetching: metricsEquipmentRemovedIsLoading,
    isSuccess: metricsEquipmentRemovedIsSuccess,
    isError: metricsEquipmentRemovedIsError,
  }] = useLazyMetricsEquipmentRemovedYesterdayQuery();

  const [getMetricsUpcomingInspection, {
    data: metricsMetricsUpcomingData,
    error: metricsMetricsUpcomingError,
    isFetching: metricsMetricsUpcomingIsLoading,
    isSuccess: metricsMetricsUpcomingIsSuccess,
    isError: metricsMetricsUpcomingIsError,
  }] = useLazyMetricsUpcomingInspectionsQuery();

  const [getMetricsCorrectiveStats, {
    data: metricsMetricsCorrectiveStatsData,
    error: metricsMetricsCorrectiveStatsError,
    isFetching: metricsMetricsCorrectiveStatsIsLoading,
    isSuccess: metricsMetricsCorrectiveStatsIsSuccess,
    isError: metricsMetricsCorrectiveStatsIsError,
  }] = useLazyMetricsCorrectiveActionsStatsQuery();

  const [getMetricsInspectionByEquipment, {
    data: metricsMetricsInspectionByEquipmentData,
    error: metricsMetricsInspectionByEquipmentError,
    isFetching: metricsMetricsInspectionByEquipmentIsLoading,
    isSuccess: metricsMetricsInspectionByEquipmentIsSuccess,
    isError: metricsMetricsInspectionByEquipmentIsError,
  }] = useLazyMetricsInspectionByEquipmentTypeQuery();

  const [getMetricsAverageInspectionDur, {
    data: metricsMetricsAverageInspectionDurData,
    error: metricsMetricsAverageInspectionDurError,
    isFetching: metricsMetricsAverageInspectionDurIsLoading,
    isSuccess: metricsMetricsAverageInspectionDurIsSuccess,
    isError: metricsMetricsAverageInspectionDurIsError,
  }] = useLazyMetricsAverageInspectionDurationQuery();

  const [getMetricsEquipmentDecommission, {
    data: metricsMetricsEquipmentDecommissionData,
    error: metricsMetricsEquipmentDecommissionError,
    isFetching: metricsMetricsEquipmentDecommissionIsLoading,
    isSuccess: metricsMetricsEquipmentDecommissionIsSuccess,
    isError: metricsMetricsEquipmentDecommissionIsError,
  }] =useLazyMetricsEquipmentDecommissionsQuery();


  const [getDashboardPersonalStats, {
    data: dashboardPersonalStatsData,
    error: dashboardPersonalStatsError,
    isFetching: dashboardPersonalStatsIsLoading,
    isSuccess: dashboardPersonalStatsIsSuccess,
    isError: dashboardPersonalStatsIsError,
  }] = useLazyDashboardPersonalStatsQuery();

  const [getDashboardPersonalInfo, {
    data: dashboardPersonalInfoData,
    error: dashboardPersonalInfoError,
    isFetching: dashboardPersonalInfoIsLoading,
    isSuccess: dashboardPersonalInfoIsSuccess,
    isError: dashboardPersonalInfoIsError,
  }] = useLazyDashboardPersonalInfoQuery();

  const [getMetricsEquipmentRemovedService, {
    data: metricsEquipmentRemovedServiceData,
    error: metricsEquipmentRemovedServiceError,
    isFetching: metricsEquipmentRemovedServiceIsLoading,
    isSuccess: metricsEquipmentRemovedServiceIsSuccess,
    isError: metricsEquipmentRemovedServiceIsError,
  }] = useLazyMetricsEquipmentRemovedQuery();

    useEffect(() => {
    if (userRolesIsSuccess) {
      if (tableOptions?.length < 2) {
        setTableOptions(old => [...old, ...userRolesData?.map(item => ({value: item?.key, label: item?.name}))])
      }
    }
    if (equipmentTypesIsSuccess) {
      if (equipmentTypeOptions?.length < 2) {
        setEquipmentTypeOptions(old => [...old, ...equipmentTypesData?.map(item => ({value: item?.id, label: item?.title}))])
      }
    }
  }, [userRolesIsSuccess, equipmentTypesIsSuccess])

  useEffect(() => {
    dispatch(setLoader(true))
    if (currentFbo) {
      Promise.all([
        getMetricsPlanned({agency: currentFbo}),
        getMetricsYesterday({agency: currentFbo}),
        getMetricsMissed({agency: currentFbo, filter_for_period: 'weekly'}),
        getMetricsEquipmentCountType({agency: currentFbo}),
        getMetricsEquipmentRemoved({agency: currentFbo}),
        getMetricsUpcomingInspection({agency: currentFbo}),
        getMetricsCorrectiveStats({agency: currentFbo}),
        getMetricsInspectionByEquipment({agency: currentFbo}),
        getMetricsAverageInspectionDur({agency: currentFbo}),
        getMetricsEquipmentDecommission({agency: currentFbo, period: 'weekly'}),
        getDashboardPersonalStats({agency: currentFbo}),
        getDashboardPersonalInfo({name: "", agency: currentFbo}),
        getMetricsEquipmentRemovedService({agency: currentFbo}),
      ])
        .then(res => {
          dispatch(setLoader(false))
        })
        .catch(err => {
          dispatch(setLoader(false))
        });
    }
  }, [currentFbo]);

  useEffect(() => {
    if (metricsPlannedIsLoading ||
      metricsYesterdayIsLoading ||
    metricsMissedIsLoading ||
    metricsEquipmentCountTypeIsLoading ||
    metricsEquipmentRemovedIsLoading ||
    metricsMetricsUpcomingIsLoading ||
    metricsMetricsCorrectiveStatsIsLoading ||
    metricsMetricsInspectionByEquipmentIsLoading ||
    metricsMetricsAverageInspectionDurIsLoading ||
    metricsMetricsEquipmentDecommissionIsLoading ||
    dashboardPersonalStatsIsLoading ||
    dashboardPersonalInfoIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }

  }, [metricsPlannedIsLoading,
    metricsYesterdayIsLoading,
    metricsMissedIsLoading,
    metricsEquipmentCountTypeIsLoading,
    metricsEquipmentRemovedIsLoading,
    metricsMetricsUpcomingIsLoading,
    metricsMetricsCorrectiveStatsIsLoading,
    metricsMetricsInspectionByEquipmentIsLoading,
    metricsMetricsAverageInspectionDurIsLoading,
    metricsMetricsEquipmentDecommissionIsLoading,
    dashboardPersonalStatsIsLoading,
    dashboardPersonalInfoIsLoading]);


  useEffect(() => {
    if (metricsEquipmentCountTypeData?.data?.length > 0) {
      setMetricsEquipmentCountState(metricsEquipmentCountTypeData?.data?.map(item => ({...item, name: item.key.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "_")})))
    }
  }, [metricsEquipmentCountTypeData])

  useEffect(() => {
    getMetricsMissed({agency: currentFbo, period: chosenMissedFilters?.period, filter_for_period: chosenMissedFilters?.filter_for_period})
  }, [chosenMissedFilters])

  function setColors(data) {
    if (data) {
      let newPieData = [...data]
      const index = newPieData.findIndex(item => item.key === "Out of service(Current)");
      if (index !== -1) {
        const outOfServiceItem = newPieData.splice(index, 1)[0];
        newPieData.unshift(outOfServiceItem);
      }
      for (let i = 0; i <= newPieData.length; i++) {
        for (let j = 0; j <= colors.length; j++) {
          if (i === j) {
            newPieData[i] = {...newPieData[i], color: colors[j]}
          }
        }
      }
      return newPieData
    }
  }

  const handleFilterMissed = (e) => {
    setChosenMissedFilters((old) => ({...old, period: e.value}))
  }
  const handleFilterMissedSecond = (e) => {
    setChosenMissedFilters((old) => ({...old, filter_for_period: e.value}))
  }

  const handleFilterEquipmentCount = (e) => {
    getMetricsEquipmentCountType({agency: currentFbo, equipment_type_id: e.value})
  }

  const handleFilterUpcoming = (e) => {
    if (e !== null) {
      getMetricsUpcomingInspection({agency: currentFbo, equipment_id: e.value})
    } else {
      getMetricsUpcomingInspection({agency: currentFbo, equipment_id: e})
    }
  }

  const handleFilterAverageInspectionEquipment = (e) => {
    getMetricsEquipmentDecommission({agency: currentFbo, period: e})
  }

  const handleTableChange = (e) => {
    getDashboardPersonalInfo({agency: currentFbo, name: e.value})
  }

  // const handleFilterNFC = (e) => {
  //   console.log(e)
  //   getMetricsInspectionByEquipment({agency: currentFbo, equipment_type_id: e.value})
  // }

  const handleFilterAverageInspectionDuration = (e) => {
    getMetricsAverageInspectionDur({agency: currentFbo, equipment_type_id: e})
  }

  const navigateToInspection = (id) => {
    navigate(`/inspections/${id}`)
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Metrics | NDX</title>
      </Helmet>
      <DashboardHeader title={'Advanced Metrics'} type={'metrics'} />
      <div className="metrics">
        <div className="metrics__head">
          <div className="metrics__head-back" onClick={() => navigate(-1)}>
            <img src="/assets/icons/chevron-left.svg" alt="back"/>
            <span>Back</span>
          </div>
        </div>

        <div className="metrics__block">
          <div className="metrics__block-left">
            <div className="chart" style={{width: 320}}>
              <div className="chart__header">
                <p className="chart__header-title w-290">Percentage of Yesterday’s Inspections that were Completed</p>
              </div>
              <div style={{display: "flex", alignItems: "center", justifyContent: 'center'}}>
                <CustomPieChart
                  isPercent={true}
                  level={"lower-more"}
                  data={[{value: metricsYesterdayData?.completed_percentage, color: '#2AA58D'},
                    {value: 100 - metricsYesterdayData?.completed_percentage, color: '#E9EAEB'}]}
                  total={metricsYesterdayData?.completed_percentage === 0 || metricsYesterdayData?.completed_percentage === '0%' ? false : metricsYesterdayData?.completed_percentage}
                />
              </div>
            </div>
            <StatisticBlock period={"yesterday"} title={"Total of Planned Inspections"} data={metricsPlannedData?.planned} positive={metricsPlannedData?.planned?.percentage > 0 ? true : metricsPlannedData?.planned?.percentage == 0 ? 'grey' : false} width={'mid'} />
            <StatisticBlock period={"yesterday"} title={"Actual Inspections Performed"} data={metricsPlannedData?.performed} positive={metricsPlannedData?.performed?.percentage > 0 ? true : metricsPlannedData?.performed?.percentage == 0 ? 'grey' : false} width={'mid'} />
          </div>
          <div className="metrics__block-right">
            <MetricsTable
              headers={tableHeadMockData}
              body={metricsYesterdayData?.inspections}
              cols={'repeat(5, 1fr)'}
              title={'Inspections Completed Yesterday'}
              showSelect={false}
              width={'shrinked'}
              tableHeight={'metrics-large'}
              rowHeight={'metrics-mid'} />
          </div>
        </div>
        <div className="metrics__block">
          <div className="metrics__block-left">
            <VerticalPieChart level={'lower'} customColors={["#DA6767", "#E9EAEB"]} data={metricsMissedData?.metric} title={'Missed Inspections'} width={'pre-mid'} />
          </div>
          <div className="metrics__block-right h-370">
            <MetricsTable
              headers={tableHead2MockData}
              body={metricsMissedData?.inspections}
              cols={'2fr 3fr 1.5fr 2fr 1fr'}
              title={'Missed Inspections'}
              selectTitle={'Period'}
              showSelect={true}
              showSecondSelect={true}
              secondOptions={filterLastPeriodShortOptions}
              secondSelectDefault={filterLastPeriodShortOptions[0]}
              width={'shrinked'}
              tableHeight={'metrics-small'}
              rowHeight={'metrics-small'}
              options={filterPeriodOptions}
              handleSelect={handleFilterMissed}
              handleSecondSelect={handleFilterMissedSecond}
              rowPointer={true}
              rowAction={navigateToInspection}
            />
          </div>
        </div>
        <div className="metrics__block">
          <div className="chart w-100">
            <div className="chart__header">
              <p className="chart__header-title w-290">Total Count of Equipment by Type</p>
              <Select
                placeholder={"Equipment Type:"}
                options={equipmentTypeOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    width: 180,
                    height: 30,
                    fontSize: 14,
                    backgroundColor: "#FFFFFF",
                    color: "#718096",
                  }),
                }}
                onChange={handleFilterEquipmentCount}
              />
            </div>
            <div className="metrics__block-inner">
              {
                metricsEquipmentCountTypeData?.total !== 0 ?
                  <>
                    <CustomPieChart width={'mx-200'} level={'lower-more'} data={setColors(metricsEquipmentCountTypeData?.data)} total={metricsEquipmentCountTypeData?.total}/>
                    <div className="chart__total-elem green large" style={{marginRight: 8, marginLeft: 20, maxWidth: '41%'}}>
                      <div className="chart__total-left">
                        <p className="chart__total-title">{metricsEquipmentCountState?.filter(item => item.name === 'in_service')[0]?.key}</p>
                        <p className="chart__total-count"><span>.</span>{metricsEquipmentCountState?.filter(item => item.name === 'in_service')[0]?.value}</p>
                      </div>
                      <div className="chart__total-right">
                        {/*<img src="/assets/icons/warning.svg" alt="warning"/>*/}
                      </div>
                    </div>
                    <div className="chart__total-elem blue large" style={{maxWidth: '41%'}}>
                      <div className="chart__total-left">
                        <p className="chart__total-title">{metricsEquipmentCountState?.filter(item => item.name === "out_of_service_current_")[0]?.key}</p>
                        <p className="chart__total-count"><span>.</span>{metricsEquipmentCountState?.filter(item => item.name === "out_of_service_current_")[0]?.value}</p>
                      </div>
                      <div className="chart__total-right">
                        {/*<img src="/assets/icons/warning.svg" alt="warning"/>*/}
                      </div>
                    </div>
                  </>
                  :
                  <div style={{width: '100%', height: '205px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <div className="expired__box-img" style={{margin: '0'}}>
                        <img src="/assets/no-data.svg" alt="no-data"/>
                      </div>
                      <p className="no-payment-text">
                        There is no Data!
                      </p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="metrics__block">
          <StatisticBlock period={'yesterday'} title={"Equipment Removed from Service "} data={metricsEquipmentRemovedServiceData?.equipment_removed_from_service} positive={metricsEquipmentRemovedServiceData?.equipment_removed_from_service?.percentage > 0 ? true : metricsEquipmentRemovedServiceData?.equipment_removed_from_service?.percentage == 0 ? 'grey' : false} width={'w-30'} />
          <StatisticBlock period={'yesterday'} title={"Equipment Reintroduced Into Service"} data={metricsEquipmentRemovedServiceData?.equipment_reintroduced_into_service} positive={metricsEquipmentRemovedServiceData?.equipment_reintroduced_into_service?.percentage > 0 ? true : metricsEquipmentRemovedServiceData?.equipment_reintroduced_into_service?.percentage == 0 ? 'grey' : false} width={'w-30'} />
          <StatisticBlock period={'week'} title={"Average Time Equipment Stays Out of Service"} data={metricsEquipmentRemovedServiceData?.equipment_decommission_average} positive={metricsEquipmentRemovedServiceData?.equipment_decommission_average?.percentage > 0 ? true : metricsEquipmentRemovedServiceData?.equipment_reintroduced_into_service?.percentage == 0 ? 'grey' : false} width={'w-30'} />
        </div>
        <div className="metrics__block">
          <div className="chart w-100">
            <div className="chart__header">
              <p className="chart__header-title w-290">Table of Upcoming Inspections</p>
              <Select
                placeholder={"Equipment Type"}
                options={equipmentTypeOptions}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    width: 180,
                    height: 30,
                    fontSize: 14,
                    backgroundColor: "#FFFFFF",
                    color: "#718096",
                  }),
                }}
                isClearable
                onChange={handleFilterUpcoming}
              />
            </div>
            <div className="chart__box metrics-table">
              <MetricsInspectionColumn title={'Monthly'} type={'monthly'} data={metricsMetricsUpcomingData?.monthly} />
              <MetricsInspectionColumn title={'Quarterly'} type={'quarterly'} data={metricsMetricsUpcomingData?.quarterly} />
              <MetricsInspectionColumn title={'Semi-Annual'} type={'semi'} data={metricsMetricsUpcomingData?.semiannual} />
              <MetricsInspectionColumn title={'Annual'} type={'annual'} data={metricsMetricsUpcomingData?.annual} />
            </div>
          </div>
        </div>
        <div className="metrics__block">
          <MetricsTable
            headers={tableHead3MockData}
            body={metricsEquipmentRemovedData}
            cols={'repeat(5, 1fr)'}
            title={'Equipment Removed from Service Yesterday'}
            showSelect={false}
            width={'w-100'}
            tableHeight={'metrics-large'}
            rowHeight={'metrics-mid'}
          />
        </div>
        <div className="metrics__block">
          <StatisticBlock period={"yesterday"} data={{value: metricsMetricsCorrectiveStatsData?.corrective_actions_created, percentage: metricsMetricsCorrectiveStatsData?.corrective_actions_created_percentage}} title={'Corrective Actions Created'} positive={metricsMetricsCorrectiveStatsData?.corrective_actions_created_percentage > 0 ? true : metricsMetricsCorrectiveStatsData?.corrective_actions_created_percentage == 0 ? 'grey' : false} width={'w-30'} />
          <StatisticBlock period={"yesterday"} data={{value: metricsMetricsCorrectiveStatsData?.corrective_actions_closed, percentage: metricsMetricsCorrectiveStatsData?.corrective_actions_closed_percentage }} title={'Corrective Actions Closed Yesterday'} positive={metricsMetricsCorrectiveStatsData?.corrective_actions_closed_percentage > 0 ? true : metricsMetricsCorrectiveStatsData?.corrective_actions_closed_percentage == 0 ? 'grey' : false} width={'w-30'} />
          <StatisticBlock period={"week"} data={{value: metricsMetricsCorrectiveStatsData?.average_time, percentage: metricsMetricsCorrectiveStatsData?.average_time_percentage }} title={'Average Time a Corrective Action Remains Open'} positive={metricsMetricsCorrectiveStatsData?.average_time_percentage > 0 ? true : metricsMetricsCorrectiveStatsData?.average_time_percentage == 0 ? 'grey' : false} width={'w-30'} />
        </div>
        <div className="metrics__block">
          <MetricsTable
            headers={tableHead4MockData}
            body={metricsMetricsCorrectiveStatsData?.closed_yesterday_list}
            cols={'1.5fr 1.5fr 1.5fr 1.5fr 2.5fr 2.5fr 2fr'}
            title={'Corrective Actions Closed Yesterday'}
            showSelect={false}
            width={'w-100'}
            tableHeight={'metrics-large'}
            rowHeight={'metrics-mid'}
          />
        </div>
        <div className="metrics__block">
          <MetricsTable
            headers={tableHeadOpenMockData}
            body={metricsMetricsCorrectiveStatsData?.open_correctives_list}
            cols={'repeat(5, 1fr)'}
            title={'Open Corrective Actions Equipment'}
            showSelect={false}
            width={'w-100'}
            tableHeight={'metrics-middle'}
            rowHeight={'metrics-mid'}
          />
        </div>
        <div className="metrics__block">
          <TinyBarChart
            isTime={true}
            size={'small'}
            width={'w-100'}
            data={metricsMetricsEquipmentDecommissionData?.map(item => ({...item, key: moment(item.key).format('MM/DD/YYYY')}))}
            title={"Average Time Equipment Stays Out of Service"}
            options={filterPeriodShortOptions ? filterPeriodShortOptions : [null]}
            handlePeriodChange={handleFilterAverageInspectionEquipment}
          />
        </div>
        <div className="metrics__block stretch">
          <div className="metrics__block-left">
            <VerticalPieChart legendHeight={'centered'} level={'higher'} surface={true} height={'h-100'} width={'pre-mid'} data={dashboardPersonalStatsData?.data} title={"Total Number of Users and their Roles"} />
          </div>
          <div className="metrics__block-right">
            <MetricsTable
              headers={tableHeadPersonalMockData}
              body={dashboardPersonalInfoData?.data}
              cols={'3fr 2fr 2fr 2fr 2fr'}
              title={'User Information'}
              selectTitle={'User role'}
              showSelect={true}
              width={'shrinked'}
              tableHeight={'metrics-small'}
              rowHeight={'metrics-small'}
              options={tableOptions}
              handleSelect={handleTableChange}
            />
          </div>
        </div>
        <div className="metrics__block">
          <MetricsTable
            headers={tableHead5MockData}
            body={metricsMetricsInspectionByEquipmentData}
            cols={'1fr 1fr 1fr 2fr 2fr'}
            title={'NFC and Inspection Metrics'}
            selectTitle={'Equipment Type'}
            showSelect={false}
            width={'w-100'}
            tableHeight={'metrics-small'}
            rowHeight={'metrics-mid'}
            options={equipmentTypesData?.map(item => ({value: item.id, label: item.title}))}
            // handleSelect={handleFilterNFC}
          />
        </div>
        <div className="metrics__block">
          <TinyBarChart
            size={'small-mid'}
            width={'w-100'}
            isTime={true}
            options={equipmentTypesData ? equipmentTypeOptions : [null]}
            handlePeriodChange={handleFilterAverageInspectionDuration}
            data={metricsMetricsAverageInspectionDurData?.avg_inspection_duration_by_equipment}
            title={"Average Inspection Duration by Equipment Type"}
          />
        </div>
        <div className="metrics__block">
          <MetricsTable
            headers={tableHead6MockData}
            body={metricsMetricsAverageInspectionDurData?.avg_inspection_duration_by_inspector}
            cols={'repeat(3, 1fr)'}
            title={'Average Inspection Duration by Inspector'}
            showSelect={false}
            width={'w-100'}
            tableHeight={'metrics-small'}
            rowHeight={'metrics-mid'}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default AdvancedMetrics;
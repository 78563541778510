import React, { useEffect, useState } from "react";
import {
  useGetHistoryLeasingQuery,
  useGetHistoryOrdersQuery,
  useGetHistoryPaymentsQuery,
  useGetHistorySubscriptionQuery,
} from "../../store/builder/inspections.api";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import moment from "moment/moment";

const ProfileHistory = ({ setCurrentOrder }) => {
  const dispatch = useDispatch();
  const [historyTab, setHistoryTab] = useState(1);
  const {
    data: subHistoryData,
    isLoading: subHistoryIsLoading,
    isError: subHistoryIsError,
    error: subHistoryError,
    isSuccess: subHistoryIsSuccess,
  } = useGetHistorySubscriptionQuery();
  const {
    data: payHistoryData,
    isLoading: payHistoryIsLoading,
    isError: payHistoryIsError,
    error: payHistoryError,
    isSuccess: payHistoryIsSuccess,
  } = useGetHistoryPaymentsQuery();
  const {
    data: leasHistoryData,
    isLoading: leasHistoryIsLoading,
    isError: leasHistoryIsError,
    error: leasHistoryError,
    isSuccess: leasHistoryIsSuccess,
  } = useGetHistoryLeasingQuery();
  const {
    data: orderHistoryData,
    isLoading: orderHistoryIsLoading,
    isError: orderHistoryIsError,
    error: orderHistoryError,
    isSuccess: orderHistoryIsSuccess,
  } = useGetHistoryOrdersQuery();

  useEffect(() => {
    if (
      subHistoryIsLoading ||
      payHistoryIsLoading ||
      leasHistoryIsLoading ||
      orderHistoryIsLoading
    ) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [
    subHistoryIsLoading,
    payHistoryIsLoading,
    leasHistoryIsLoading,
    orderHistoryIsLoading,
  ]);

  return (
    <div className="profile__history">
      <div className="profile__history-head">
        <p>History</p>
      </div>
      <div className="profile__history-tabs">
        {/*<div*/}
        {/*  className={`profile__history-tab ${historyTab === 3 ? "active" : ""}`}*/}
        {/*  onClick={() => setHistoryTab(3)}*/}
        {/*>*/}
        {/*  Orders*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  className={`profile__history-tab ${historyTab === 0 ? "active" : ""}`}*/}
        {/*  onClick={() => setHistoryTab(0)}*/}
        {/*>*/}
        {/*  Payment history*/}
        {/*</div>*/}
        <div
          className={`profile__history-tab ${historyTab === 1 ? "active" : ""}`}
          onClick={() => setHistoryTab(1)}
        >
          Subscription history
        </div>
        {/*<div*/}
        {/*  className={`profile__history-tab ${historyTab === 2 ? "active" : ""}`}*/}
        {/*  onClick={() => setHistoryTab(2)}*/}
        {/*>*/}
        {/*  Leasing plan*/}
        {/*</div>*/}
      </div>
      <div className="profile__history-table">
        {historyTab === 0 ? (
          <div className="profile__history-tableHead">
            <div className="profile__history-tableTh">Date</div>
            <div className="profile__history-tableTh">Amount</div>
            <div className="profile__history-tableTh">Description</div>
            <div className="profile__history-tableTh">Download PDF</div>
          </div>
        ) : historyTab === 1 ? (
          <div className="profile__history-tableHead">
            <div className="profile__history-tableTh">Start date</div>
            <div className="profile__history-tableTh">End date</div>
            <div className="profile__history-tableTh">Subscription cost</div>
            <div className="profile__history-tableTh">Status</div>
          </div>
        ) : historyTab === 2 ? (
          <div className="profile__history-tableHead">
            <div className="profile__history-tableTh descr">Description</div>
            <div className="profile__history-tableTh">Leasing cost</div>
            <div className="profile__history-tableTh">Duration</div>
            <div className="profile__history-tableTh">Regular payment</div>
          </div>
        ) : (
          <div className="profile__history-tableHead">
            <div className="profile__history-tableTh descr">ID</div>
            <div className="profile__history-tableTh">Date</div>
            <div className="profile__history-tableTh">Status</div>
            <div className="profile__history-tableTh">Last updated</div>
            <div className="profile__history-tableTh">Total</div>
          </div>
        )}
        {historyTab === 0 ? (
          <div className="profile__history-tableBody">
            {payHistoryData?.data?.map((item) => (
              <div className="profile__history-tableBodyRow" key={item?.id}>
                <div className="profile__history-tableBodyTh">
                  {item?.date ? moment(item?.date).format("MM/DD/YY") : "-"}
                </div>
                <div className="profile__history-tableBodyTh">
                  ${item?.amount}
                </div>
                <div className="profile__history-tableBodyTh">
                  {item?.description}
                </div>
                <a
                  href={item?.receipt_url}
                  target="_blank"
                  className="profile__history-tableBodyTh download"
                >
                  <img src="/assets/icons/Download.svg" alt="icon" />
                </a>
              </div>
            ))}
          </div>
        ) : historyTab === 1 ? (
          <div className="profile__history-tableBody">
            {subHistoryData?.data?.map((item) => (
              <div className="profile__history-tableBodyRow" key={item?.id}>
                <div className="profile__history-tableBodyTh">
                  {moment(item?.created_at).format("MM/DD/YY")}
                </div>
                <div className="profile__history-tableBodyTh">
                  {moment(item?.end_date).format("MM/DD/YY")}
                </div>
                <div className="profile__history-tableBodyTh">
                  ${item?.price}
                </div>
                <div className="profile__history-tableBodyTh">
                  <p>{item.status}</p>
                </div>
              </div>
            ))}
          </div>
        ) : historyTab === 2 ? (
          <div className="profile__history-tableBody">
            {leasHistoryData?.data?.map((item) => (
              <>
                <div className="profile__history-tableBodyRow" key={item?.id}>
                  <div className="profile__history-tableBodyTh descr">
                    {item?.description}
                  </div>
                  <div className="profile__history-tableBodyTh">
                    ${item?.amount}
                  </div>
                  <div className="profile__history-tableBodyTh">
                    {item?.duration} month
                  </div>
                  <div className="profile__history-tableBodyTh">
                    $600/per month
                  </div>
                </div>
                <>
                  <div
                    className="profile__history-tableHead"
                    style={{ marginLeft: 10 }}
                  >
                    <div
                      className="profile__history-tableTh descr"
                      style={{ maxWidth: "33%", textAlign: "center" }}
                    >
                      Date
                    </div>
                    <div
                      className="profile__history-tableTh"
                      style={{ maxWidth: "33%", textAlign: "center" }}
                    >
                      Amount
                    </div>
                    <div
                      className="profile__history-tableTh"
                      style={{ maxWidth: "33%", textAlign: "center" }}
                    >
                      Is Paid
                    </div>
                  </div>
                  {item?.plan?.map((el) => (
                    <div
                      className="profile__history-tableBodyRow"
                      key={el?.id}
                      style={{ marginLeft: 10, backgroundColor: "#fff" }}
                    >
                      <div
                        className="profile__history-tableBodyTh descr"
                        style={{ maxWidth: "33%", textAlign: "center" }}
                      >
                        {el?.due_date ? moment(el?.due_date).format("ll") : "-"}
                      </div>

                      <div
                        className="profile__history-tableBodyTh"
                        style={{ maxWidth: "33%", textAlign: "center" }}
                      >
                        ${el?.amount ?? "-"}
                      </div>
                      <div
                        className="profile__history-tableBodyTh"
                        style={{ maxWidth: "33%", textAlign: "center" }}
                      >
                        {el?.is_paid ? "Yes" : "No"}
                      </div>
                    </div>
                  ))}
                </>
              </>
            ))}
          </div>
        ) : (
          <div className="profile__history-tableBody">
            {orderHistoryData?.data?.map((item) => (
              <div
                className="profile__history-tableBodyRow"
                style={{ cursor: "pointer" }}
                key={item?.id}
                onClick={() => {
                  setCurrentOrder(item);
                }}
              >
                <div className="profile__history-tableBodyTh descr">
                  {item?.id}
                </div>
                <div className="profile__history-tableBodyTh">
                  {moment(item?.created_at)?.format("MM/DD/YY")}
                </div>
                <div className={`profile__history-tableBodyTh`}>
                  <p className={` sds ${item?.status}`}>{item?.status}</p>
                </div>
                <div className="profile__history-tableBodyTh">
                  {moment(item?.status_updated_at)?.format("MM/DD/YY")}
                </div>
                <div className="profile__history-tableBodyTh">
                  ${item?.total}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHistory;

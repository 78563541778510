import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  categories: [],
};

const equipmentEditSlice = createSlice({
  name: "equipment_categories",
  initialState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload
    },
    changeCategories(state, action) {
      state.categories.map(category => {
        if (category.key === action.payload.key) {
          category.forms.map(form => {
            if (form.id === action.payload.id) {
              form.selected = action.payload.selected;
            }
            return form;
          })
        }
        return category;
      })
    }
  },
});

export const { setCategories, changeCategories } = equipmentEditSlice.actions;
export default equipmentEditSlice.reducer;

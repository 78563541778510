import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import {
  useAgencyInspectionEnableMutation,
  useAgencyWaterTestKitTypesQuery,
  useExtendAgencyTrialMutation,
  useGetAgencyQuery,
  useGetInspectionQuery,
  useGetTimezonesQuery, useInviteFboMutation, useLazyCorporateListQuery,
  useLazyGetAgencySubscribedQuery,
  useStartAgencyTrialMutation,
} from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import "./index.scss";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import Cropper from "react-cropper";
import {toast} from "react-toastify";
import CustomPaginate from "../../components/PaginateComponent";
import moment from "moment/moment";
import AgencyRow from "../../components/AgencyRow";
import {useDebounce} from "../LogSheets/hooks";
import Datepicker from "../../components/Datepicker";
import EditPricingModal from "../../components/EditPricingModal";
import {setAgenciesList} from "../../store/builder/agenciesListSlice";
import AgencyTableCard from "../../components/AgencyTableCard";
import {validationEmail} from "../../global/validate";

export default function Agancy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [inviteModal, setInviteModal] = useState(false);
  const [email, setEmail] = useState('');
  const [createModalStatus, setCreateModalStatus] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [list, setList] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [chosenPhoto, setChosenPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [cropper, setCropper] = useState();
  const [imageBlob, setImageBlob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentAgency, setCurrentAgency] = useState(null);
  const [search, setSearch] = useState('')
  const debouncedSearchValue = useDebounce(search, 500);
  const [startTrialStatus, setStartTrialStatus] = useState(false);
  const [trialDate, setTrialDate] = useState(null);
  const [startTrialDate, setStartTrialDate] = useState(null);
  const [editPrisingModal, setEditPrisingModal] = useState(false);
  const [inspectionStart, setInspectionStart] = useState(null);
  const [turnOffStatus, setTurnOffStatus] = useState(false);

  const [getAgency, {
    data: agencyData,
    error: agencyError,
    isFetching: agencyIsLoading,
    isSuccess: agencyIsSuccess,
    isError: agencyIsError,
  }] = useLazyGetAgencySubscribedQuery();

  const {data: timezonesData,
    error: timezonesError,
    isLoading: timezonesIsLoading,
    isSuccess: timezonesIsSuccess,
    isError: timezonesIsError,
  } = useGetTimezonesQuery();

  const [getCorporateList, {
    data: corporateListData,
    error: corporateListError,
    isFetching: corporateListIsLoading,
    isSuccess: corporateListIsSuccess,
    isError: corporateListIsError,
  }] = useLazyCorporateListQuery();

  const {data: waterKitData, error: waterKitError, isLoading: waterKitIsLoading, isSuccess: waterKitIsSuccess, isError: waterKitIsError} = useAgencyWaterTestKitTypesQuery()


  const [
    startTrial,
    {
      data: startTrialData,
      isError: startTrialIsError,
      isFetching: startTrialIsLoading,
      isSuccess: startTrialIsSuccess,
      error: startTrialError,
    },
  ] = useStartAgencyTrialMutation();

  const [enableInspections, {
    data: enableInspectionsData,
    error: enableInspectionsError,
    isFetching: enableInspectionsIsLoading,
    isSuccess: enableInspectionsIsSuccess,
    isError: enableInspectionsIsError,
  }] = useAgencyInspectionEnableMutation();

  const [invite,
    {
      data: inviteData,
      isLoading: inviteIsLoading,
      isError: inviteIsError,
      error: inviteError,
      isSuccess: inviteIsSuccess,
    },] = useInviteFboMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([agencyError, startTrialError, enableInspectionsError, inviteIsError]);

  useEffect(() => {
    if (startTrialStatus || editPrisingModal || turnOffStatus || showModal || createModalStatus || inviteModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [startTrialStatus, editPrisingModal, turnOffStatus, showModal, createModalStatus, inviteModal]);

  useEffect(() => {
    getCorporateList({status: 'active'});
  }, []);

  useEffect(() => {
    getAgency({page: currentPage, search: debouncedSearchValue})
  }, [debouncedSearchValue, currentPage])

  useEffect(() => {
    if (agencyIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [agencyIsLoading]);

  useEffect(() => {
    if (inviteIsLoading) {
      dispatch(setLoader(true));
    }
    if (inviteIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Invite successfully sent!');
      setInviteModal(false);
    }
    if (inviteIsError) {
      dispatch(setLoader(false));
      toast.error(inviteError?.data?.email[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError]);

  useEffect(() => {
    if (agencyData) {
      setList(agencyData?.data?.map(item => ({...item, action_status: false})));
      dispatch(setAgenciesList(agencyData?.data));
    }
  }, [agencyData]);

  useEffect(() => {
    if (!userInfo?.is_global) {
      navigate("/inspections");
    }
  }, [userInfo]);

  useEffect(() => {
    if (createModalStatus || startTrialStatus) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'visible'
    }
  }, [createModalStatus, startTrialStatus])
  const getAgencies = () => {
    getAgency({page: currentPage, search: debouncedSearchValue})
  }
  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const image = base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      setShowModal(false)
      document.querySelector('.modal-fbo').style.display = 'flex'
      setPhoto(cropper.getCroppedCanvas().toDataURL())
      setImageBlob(image)
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmitInvite = () => {
    if (validationEmail(email)) {
      invite({email: email, role_id: 2})
    } else {
      toast.error('Please enter correct email')
    }
  }

  const handleSubmitStart = () => {
    if (inspectionStart) {
      dispatch(setLoader(true))
      enableInspections({agency_id: currentItem?.id.toString(), start_date: inspectionStart, status: 1})
        .unwrap()
        .then(res => {
          toast.success(res.message);
          dispatch(setLoader(false));
          setStartTrialStatus(false);
          setInspectionStart(null)
          getAgencies();
        })
        .catch(err => {
          dispatch(setLoader(false));
        })
    } else {
      toast.error('Please select a start date');
    }
  }

  const renderTurnOffInspection = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to turn OFF the inspections?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setTurnOffStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            If you turn inspections off, inspections will stop being generated until you turn inspections back on
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              enableInspections({agency_id: currentItem?.id.toString(), status: 0})
                .unwrap()
                .then(res => {
                  toast.success(res.message);
                  dispatch(setLoader(false));
                  setTurnOffStatus(false);
                  getAgencies();
                })
                .catch(err => {
                  dispatch(setLoader(false));
                })
            }}>
              Turn off
            </button>
            <button className="close almost" onClick={() => {
              setTurnOffStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>

    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>FBO | NDX</title>
      </Helmet>
      {
        inviteModal &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>Invite FBO</p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setInviteModal(false)}
              />
            </div>
            <div className={`modal__content-body`}>
              <Input
                containerWidth={'100%'}
                id="enail"
                name="emal"
                placeholder="Email"
                label={"Email"}
                type={"email"}
                onChange={handleEmail}
                defaultValue=""
              />
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => setInviteModal(false)}>
                Cancel
              </button>
              <button className="save" onClick={handleSubmitInvite}>
                Send Link
              </button>
            </div>
          </div>
          <div className="modal__bg"></div>
        </div>
      }
      {
        editPrisingModal &&
        <EditPricingModal exit={() => setEditPrisingModal(false)} currentItem={currentItem} getAgencies={getAgencies}/>
      }
      {
        startTrialStatus &&
        <div className="modal">
          <div className="modal__content">
            <div className="modal__content-head">
              <p>
                Turn ON inspections
              </p>
              <img
                className="modal__content-exit"
                src="/assets/icons/close.svg"
                alt="close"
                onClick={() => setStartTrialStatus(false)}
              />
            </div>
            <div className="modal__content-body">
              <div className="modal__content-trial">
                <p className="trial__modal-title">Start Date</p>
                <Datepicker
                  width={440}
                  setValue={(e) => {
                    if (e !== 'Invalid Date' && e !== null) {
                      setInspectionStart(moment(e).format('YYYY-MM-DD'))
                    } else {
                      setInspectionStart(null)
                    }
                  }}
                  value={inspectionStart}
                />
              </div>
            </div>
            <div className="modal__content-bottom">
              <button className="close" onClick={() => {
                setStartTrialStatus(false);
                setInspectionStart(null)
              }}>
                Close
              </button>
              <button className="save" onClick={handleSubmitStart}>
                Confirm
              </button>
            </div>
          </div>
          <div className="modal__bg"></div>
        </div>
      }
      {
        turnOffStatus && renderTurnOffInspection()
      }
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                    document.querySelector('.modal-fbo').style.display = 'flex'
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                minCropBoxWidth={300}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            // onClick={() => {
            //   setShowModal(false);
            //   setChosenPhoto(null);
            //   document.querySelector('.modal-fbo').style.display = 'flex'
            // }}
          ></div>
        </div>
      )}
      {createModalStatus && (
        <Modal
          title={"Create FBO"}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setShowModal={setShowModal}
          setChosenPhoto={setChosenPhoto}
          photo={photo}
          setCreateModalStatus={setCreateModalStatus}
          imageBlob={imageBlob}
          setPhoto={setPhoto}
          type="agancy"
          exit={() => {
            setCreateModalStatus(false)
            setPhoto(null)
            setImageBlob(null)
            setCurrentItem(null)
          }}
          getAgencies={getAgencies}
          timezones={timezonesData?.data?.map(item => ({value: item, label: item}))}
          waterKitData={waterKitData?.map(item => ({label: item?.name, value: item?.id}))}
        />
      )}
      <DashboardHeader
        title={"Subscribed FBOs"}
        showBtn={true}
        type="agancy-trial"
        action={() => setInviteModal(true)}
        secondAction={() => setCreateModalStatus(true)}
      />
      <div className="inspections agency new">
        <div className="inspections__filters">
          <Input
            type="text"
            placeholder="Search by name"
            name="inspectionsSearch"
            id="inspectionsSearch"
            image={"/assets/icons/search.svg"}
            maxwidth={204}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            bg={"white"}
          />
        </div>
        <div className="inspectionsForm__box">
          <div className="inspectionsForm__table new">
            {list?.length ? list?.map((agancy) => (
              // <AgencyRow agancy={agancy} currentItem={currentItem} corporateData={corporateListData} setTurnOffStatus={setTurnOffStatus}
              //            setCurrentItem={setCurrentItem} setCreateModalStatus={setCreateModalStatus} getAgencies={getAgencies} key={agancy?.id}
              //             setList={setList} setStartTrialStatus={setStartTrialStatus} setEditPrisingModal={setEditPrisingModal}
              // />
              <AgencyTableCard agancy={agancy} currentItem={currentItem} corporateData={corporateListData} setTurnOffStatus={setTurnOffStatus}
                               setCurrentItem={setCurrentItem} setCreateModalStatus={setCreateModalStatus} getAgencies={getAgencies} key={agancy?.id}
                               setList={setList} setStartTrialStatus={setStartTrialStatus} setEditPrisingModal={setEditPrisingModal} />
            )) : (
              <div className='emptyList'>
                <img src="/assets/empty-list.png" alt="empty" />
                <p>No FBO data yet</p>
              </div>
            )}
          </div>
          {
            list?.length ?
            <CustomPaginate
              pageCount={agencyData?.pages}
              handlePageClick={handlePageClick}
            />
               : null
          }
        </div>
      </div>
    </Wrapper>
  );
}

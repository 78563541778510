import React, {useEffect, useState} from 'react';
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";
import './index.scss'
import Inspection from "../../SVG/Inspection";
import Corrective from "../../SVG/Corrective";
import Fuel from "../../SVG/Fuel";
import Log from "../../SVG/Log";
import Service from "../../SVG/Service";
import Equipment from "../../SVG/Equipment";
import Management from "../../SVG/Management";
import FacilityIcon from '../../SVG/Facility';
import InspectionForm from "../../SVG/InspectionForm";
import EquipmentType from "../../SVG/EquipmentType";
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../store/builder/reducer';
import {setUser} from "../../store/builder/loginSlice";
import Report from "../../SVG/Report";
import { setSidebar } from '../../store/builder/inspectionSlice';
import AgencyDropdown from '../AgencyDropdown';
import Settings from "../../SVG/Settings";
import SideFBODropdown from "../SideFBODropdown";
import Monitor from "../../SVG/Monitor";
import CorporateIcon from "../../SVG/CorporateIcon";
import FBOManagementIcon from "../../SVG/FBOManagementIcon";
import FboGraphicsIcon from "../../SVG/FBOGraphicsIcon";
import SideCorporateDropdown from "../SideCorporateDropdown";
import ToolsIcon from "../../SVG/ToolsIcon";

export default function Sidebar ({setDropdown, dropdown}) {
  const dispatch = useDispatch()
  const [profileStatus, setProfileStatus] = useState(false)
  const sidebarStatus = useSelector(state => state?.rootReducer?.inspectionSlice?.sidebar)
  const userInfo = useSelector(state => state?.rootReducer?.loginSlice?.user)
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    return window.addEventListener('click', (e) => {
      if (e.target.closest('.sidebar__user') === null && e.target.closest('.sidebar__profile') === null) {
        setProfileStatus(false)
      }
    })
  }, [location])

  const profileWindow = () => {
    return (
      <div className="sidebar__profile">
        <div className="sidebar__btns">
          {
            !userInfo?.is_corporate ?
            userInfo?.agency?.is_settings_complete && (
              <Link className="sidebar__btn" to={'/profile'} onClick={() => setProfileStatus(false)}>
                <img src="/assets/icons/user.svg" alt="icon"/>
                <span>Profile</span>
              </Link>
            ) :
              <Link className="sidebar__btn" to={'/profile'} onClick={() => setProfileStatus(false)}>
                <img src="/assets/icons/user.svg" alt="icon"/>
                <span>Profile</span>
              </Link>
          }
          {
            userInfo?.is_corporate && (
              <Link className="sidebar__btn" to={'/corporate-settings'} onClick={() => setProfileStatus(false)}>
                <img src="/assets/icons/coin.svg" alt="icon"/>
                <span>Corporate Settings</span>
              </Link>
            )
          }
          {
            userInfo?.role?.name === "admin" && (
              <Link className="sidebar__btn" to={'/fbo-settings'} onClick={() => setProfileStatus(false)}>
                {
                  (((userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection')
                      && userInfo?.subscription === null)
                    || userInfo?.payment_data?.length === 0
                    || Object.values(userInfo?.address).every(item => item === null)) && userInfo?.agency?.is_settings_complete
                  && <div style={{top: '9px', left: '1px'}} className="red-dot"></div>
                }
                <img src="/assets/icons/codesandbox.svg" alt="icon"/>
                <span>FBO Settings</span>
              </Link>
            )
          }

          <div onClick={() => {
            dispatch(setLoader(true))
            setTimeout(() => {
              dispatch(setUser(null))
              localStorage.removeItem('ndx_user_token')
              navigate('/login')
              dispatch(setLoader(false))
              localStorage.removeItem('current-fbo')
              localStorage.removeItem('current-fbo-title')
              localStorage.removeItem('current-fbo-tz')
            }, 500)
          }} className="sidebar__btn logout">
            <img src="/assets/icons/logout.svg" alt="icon"/>
            <span>Log Out</span>
          </div>
        </div>
        {/*<div className="sidebar__pages">*/}
        {/*  <p>Contact us</p>*/}
        {/*  <p>About</p>*/}
        {/*</div>*/}
      </div>
    )
  }

  return (
    <div className={`sidebar ${sidebarStatus ? 'active' : ''}`}>
      <div className="sidebar__top">
        <div className="sidebar__status">
          <img onClick={() => {
            navigate('/inspections')
          }} className="sidebar-logo" src="/assets/icons/Logo.svg" alt="logo"/>
          <img className='sidebar__menu'
               src={sidebarStatus ? "/assets/icons/menu-active.svg" : "/assets/icons/menu.svg"} alt="menu"
               onClick={() => {
                 dispatch(setSidebar(!sidebarStatus))
                 localStorage.setItem('sidebar-status', !sidebarStatus)
               }}/>
        </div>
        {
          userInfo?.is_corporate &&
          <>
            <div className="sidebar__links-corporate">
              <NavLink className={`sidebar__link ${location.pathname === '/corporate-dashboard' ? 'active' : ''}`}
                       to="/corporate-dashboard">
                <Inspection color={location.pathname === '/corporate-dashboard' ? '#FFFFFF' : '#AEB3B7'}/>
                <p className="sidebar__link-name">Dashboard</p>
              </NavLink>
              <NavLink className={`sidebar__link ${location.pathname === '/fbo-management' ? 'active' : ''}`}
                       to="/fbo-management">
                <FBOManagementIcon color={location.pathname === '/fbo-management' ? '#FFFFFF' : '#AEB3B7'}/>
                <p className="sidebar__link-name">FBO Management</p>
              </NavLink>
              {
                userInfo?.permissions?.includes('user_management') && (
                  <Link className={`sidebar__link ${location.pathname === '/management'  ? 'active' : ''}`} to="/management">
                    <Management color={location.pathname === '/management' ? '#FFFFFF' : '#AEB3B7' } />
                    <p className="sidebar__link-name">User Management</p>
                  </Link>
                )
              }
            </div>
            <div className="sidebar__divider"></div>
          </>
        }
        <div className="sidebar__agancy">
          <AgencyDropdown dropdown={dropdown} setDropdown={setDropdown}/>
        </div>
        {
          !userInfo?.is_corporate ?
            !userInfo?.agency?.is_settings_complete ? null :
              <div className="sidebar__links">
                {
                  ((userInfo?.agency?.trial_status !== 'equipment' && userInfo?.agency?.trial_status !== 'inspection') || (userInfo?.is_global)) ?
                    (
                      <NavLink className={`sidebar__link ${location.pathname === '/dashboard' ? 'active' : ''}`}
                               to="/dashboard">
                        {
                          userInfo?.is_corporate ?
                            <FboGraphicsIcon color={location.pathname === '/dashboard' ? '#FFFFFF' : '#AEB3B7'}/>
                            :
                            <Inspection color={location.pathname === '/dashboard' ? '#FFFFFF' : '#AEB3B7'}/>
                        }
                        <p className="sidebar__link-name">
                          {
                            userInfo?.is_corporate ?
                              "FBO Dashboard - Beta" : "Dashboard"
                          }
                        </p>
                      </NavLink>
                    ) : null
                }
                {
                  userInfo?.permissions?.includes('inspection') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Monitor color={location.pathname === '/inspections' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspections</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/inspections' ? 'active' : ''}`}
                               to="/inspections">
                        <Monitor color={location.pathname === '/inspections' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspections</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.is_global &&
                  <>
                    <SideFBODropdown />
                    <SideCorporateDropdown />
                  </>
                }

                {/*{*/}
                {/*  userInfo?.is_global &&*/}
                {/*  <NavLink className={`sidebar__link ${location.pathname === '/fbo/all' ? 'active' : ''}`} to="/fbo/all">*/}
                {/*    <AgancyIcon color={location.pathname === '/fbo/all' ? '#FFFFFF' : '#AEB3B7' } />*/}
                {/*    <p className="sidebar__link-name">FBO</p>*/}
                {/*  </NavLink>*/}
                {/*}*/}

                {/*{*/}
                {/*  userInfo?.is_global &&*/}
                {/*  <NavLink className={`sidebar__link ${location.pathname === '/corporate' ? 'active' : ''}`}*/}
                {/*           to="/corporate">*/}
                {/*    <CorporateIcon color={location.pathname === '/corporate' ? '#FFFFFF' : '#AEB3B7'}/>*/}
                {/*    <p className="sidebar__link-name">Corporate</p>*/}
                {/*  </NavLink>*/}
                {/*}*/}

                {/*{userInfo?.is_global &&*/}
                {/*  <NavLink className={`sidebar__link ${location.pathname === '/orders' ? 'active' : ''}`} to="/orders">*/}
                {/*    <svg style={{marginLeft: "-20px"}} width="48" height="48" viewBox="0 0 32 48" fill="none"*/}
                {/*         xmlns="http://www.w3.org/2000/svg">*/}
                {/*      <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*            d="M24 32.8C28.8601 32.8 32.8 28.8601 32.8 24C32.8 19.1399 28.8601 15.2 24 15.2C19.1399 15.2 15.2 19.1399 15.2 24C15.2 28.8601 19.1399 32.8 24 32.8ZM24 35C30.0751 35 35 30.0751 35 24C35 17.9249 30.0751 13 24 13C17.9249 13 13 17.9249 13 24C13 30.0751 17.9249 35 24 35Z"*/}
                {/*            fill={location.pathname === '/orders' ? '#FFFFFF' : '#AEB3B7'}/>*/}
                {/*      <path*/}
                {/*        d="M22.8996 18.5004C22.8996 17.8929 23.3921 17.4004 23.9996 17.4004C24.6071 17.4004 25.0996 17.8929 25.0996 18.5004C26.9221 18.5004 28.3996 19.9779 28.3996 21.8004C28.3996 22.4079 27.9071 22.9004 27.2996 22.9004C26.6921 22.9004 26.1996 22.4079 26.1996 21.8004C26.1996 21.1929 25.7071 20.7004 25.0996 20.7004H22.6174C22.1657 20.7004 21.7996 21.0665 21.7996 21.5181C21.7996 21.8701 22.0248 22.1826 22.3588 22.2939L26.3361 23.6197C27.5684 24.0305 28.3996 25.1837 28.3996 26.4826C28.3996 28.1493 27.0485 29.5004 25.3819 29.5004H25.0996C25.0996 30.1079 24.6071 30.6004 23.9996 30.6004C23.3921 30.6004 22.8996 30.1079 22.8996 29.5004C21.0771 29.5004 19.5996 28.0229 19.5996 26.2004C19.5996 25.5929 20.0921 25.1004 20.6996 25.1004C21.3071 25.1004 21.7996 25.5929 21.7996 26.2004C21.7996 26.8079 22.2921 27.3004 22.8996 27.3004H25.3819C25.8335 27.3004 26.1996 26.9343 26.1996 26.4826C26.1996 26.1306 25.9744 25.8181 25.6404 25.7068L21.6631 24.381C20.4308 23.9703 19.5996 22.8171 19.5996 21.5181C19.5996 19.8515 20.9507 18.5004 22.6174 18.5004H22.8996Z"*/}
                {/*        fill={location.pathname === '/orders' ? "#FFFFFF" : '#AEB3B7'}/>*/}
                {/*    </svg>*/}
                {/*    <p className="sidebar__link-name orders">*/}
                {/*      Orders*/}
                {/*      {userInfo?.orders && (*/}
                {/*        <span style={{backgroundColor: userInfo?.orders?.order_color}}>{userInfo?.orders?.count}</span>*/}
                {/*      )}*/}
                {/*    </p>*/}
                {/*  </NavLink>*/}
                {/*}*/}
                {/*{*/}

                {
                  (userInfo?.is_global || userInfo?.is_admin || userInfo?.is_corporate) &&
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <ToolsIcon color={location.pathname === '/tools' ? '#FFFFFF' : '#AEB3B7' } />
                        <p className="sidebar__link-name">Tools Management</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/tools' ? 'active' : ''}`} to="/tools">
                        <ToolsIcon color={location.pathname === '/tools' ? '#FFFFFF' : '#AEB3B7' } />
                        <p className="sidebar__link-name">Tools Management</p>
                      </NavLink>
                }
                {
                  userInfo?.permissions?.includes('inspection_form') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <InspectionForm color={location.pathname === '/inspections-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspection Form</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/inspections-form' ? 'active' : ''}`}
                               to="/inspection-form">
                        <InspectionForm color={location.pathname === '/inspections-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspection Form</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('facility') && (
                    userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection' ?
                      null :
                      <NavLink className={`sidebar__link`} to="/facility">
                        <FacilityIcon color={location.pathname === '/facility' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Facility</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('corrective_action') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Corrective color={location.pathname === '/actions' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Corrective Actions</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/actions' ? 'active' : ''}`} to="/actions">
                        <Corrective color={location.pathname === '/actions' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Corrective Actions</p>
                      </Link>
                  )
                }
                {
                  userInfo?.permissions?.includes('log_sheet') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Log color={location.pathname === '/log-sheets' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Log Sheets</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/log-sheets' ? 'active' : ''}`}
                            to="/log-sheets">
                        <Log color={location.pathname === '/log-sheets' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Log Sheets</p>
                      </Link>
                  )
                }
                {
                  userInfo?.permissions?.includes('observation') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Service color={location.pathname === '/observation' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Observation</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/observation' ? 'active' : ''}`}
                               to="/observation">
                        <Service color={location.pathname === '/observation' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Observation</p>
                      </NavLink>
                  )
                }

                {
                  userInfo?.permissions?.includes('equipment_type') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <EquipmentType color={location.pathname === '/equipment-type' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Equipment Type</p>
                      </p> :
                      <NavLink className={`sidebar__link`} to="/equipment-type">
                        <EquipmentType color={location.pathname === '/equipment-type' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Equipment Type</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('equipment_management') && (
                    <NavLink className={`sidebar__link`} to="/equipment?active=0">
                      <Equipment color={location.pathname === '/equipment' ? '#FFFFFF' : '#AEB3B7'}/>
                      <p className="sidebar__link-name">Equipment Management</p>
                    </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('user_management') && (
                    <Link className={`sidebar__link ${location.pathname === '/management' ? 'active' : ''}`}
                          to="/management">
                      <Management color={location.pathname === '/management' ? '#FFFFFF' : '#AEB3B7'}/>
                      <p className="sidebar__link-name">User Management</p>
                    </Link>
                  )
                }
                {
                  userInfo?.permissions?.includes('report') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Report color={location.pathname === '/report' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Report</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/report' ? 'active' : ''}`} to="/report">
                        <Report color={location.pathname === '/report' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Report</p>
                      </Link>
                  )
                }
                {/*{*/}
                {/*  userInfo?.is_global && (*/}
                {/*    <Link className={`sidebar__link ${location.pathname === '/customer-requests'  ? 'active' : ''}`} to="/customer-requests">*/}
                {/*      <CustomerRequestSVG color={location.pathname === '/customer-requests' ? '#FFFFFF' : '#AEB3B7' } />*/}
                {/*      <p className="sidebar__link-name">Customer Requests</p>*/}
                {/*    </Link>*/}
                {/*  )*/}
                {/*}*/}
                {
                  userInfo?.permissions?.includes('fuel_receipt') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Fuel color={location.pathname === '/receipt-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Fuel Receipt Form</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/receipt-form' ? 'active' : ''}`}
                            to="/receipt-form">
                        <Fuel color={location.pathname === '/receipt-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Fuel Receipt Form</p>
                      </Link>
                  )
                }
                {
                  userInfo?.is_global &&
                  <NavLink className={`sidebar__link ${location.pathname === '/settings' ? 'active' : ''}`}
                           to="/settings">
                    <Settings color={location.pathname === '/settings' ? '#FFFFFF' : '#AEB3B7'}/>
                    <p className="sidebar__link-name">Settings</p>
                  </NavLink>
                }
              </div>
            :
            userInfo?.corporate?.agencies?.length > 0 ?
              <div className="sidebar__links">
                <NavLink className={`sidebar__link ${location.pathname === '/dashboard' ? 'active' : ''}`}
                         to="/dashboard">
                  {
                    userInfo?.is_corporate ?
                      <FboGraphicsIcon color={location.pathname === '/dashboard' ? '#FFFFFF' : '#AEB3B7'}/>
                      :
                      <Inspection color={location.pathname === '/dashboard' ? '#FFFFFF' : '#AEB3B7'}/>
                  }
                  <p className="sidebar__link-name">
                    {
                      userInfo?.is_corporate ?
                        "FBO Dashboard" : "Dashboard"
                    }
                  </p>
                </NavLink>
                {
                  userInfo?.permissions?.includes('inspection') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Monitor color={location.pathname === '/inspections' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspections</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/inspections' ? 'active' : ''}`}
                               to="/inspections">
                        <Monitor color={location.pathname === '/inspections' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspections</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.is_global &&
                  <SideFBODropdown/>
                }

                {/*{*/}
                {/*  userInfo?.is_global &&*/}
                {/*  <NavLink className={`sidebar__link ${location.pathname === '/fbo/all' ? 'active' : ''}`} to="/fbo/all">*/}
                {/*    <AgancyIcon color={location.pathname === '/fbo/all' ? '#FFFFFF' : '#AEB3B7' } />*/}
                {/*    <p className="sidebar__link-name">FBO</p>*/}
                {/*  </NavLink>*/}
                {/*}*/}

                {
                  userInfo?.is_global &&
                  <NavLink className={`sidebar__link ${location.pathname === '/corporate' ? 'active' : ''}`}
                           to="/corporate">
                    <CorporateIcon color={location.pathname === '/corporate' ? '#FFFFFF' : '#AEB3B7'}/>
                    <p className="sidebar__link-name">Corporate</p>
                  </NavLink>
                }

                {userInfo?.is_global &&
                  <NavLink className={`sidebar__link ${location.pathname === '/orders' ? 'active' : ''}`} to="/orders">
                    <svg style={{marginLeft: "-20px"}} width="48" height="48" viewBox="0 0 32 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M24 32.8C28.8601 32.8 32.8 28.8601 32.8 24C32.8 19.1399 28.8601 15.2 24 15.2C19.1399 15.2 15.2 19.1399 15.2 24C15.2 28.8601 19.1399 32.8 24 32.8ZM24 35C30.0751 35 35 30.0751 35 24C35 17.9249 30.0751 13 24 13C17.9249 13 13 17.9249 13 24C13 30.0751 17.9249 35 24 35Z"
                            fill={location.pathname === '/orders' ? '#FFFFFF' : '#AEB3B7'}/>
                      <path
                        d="M22.8996 18.5004C22.8996 17.8929 23.3921 17.4004 23.9996 17.4004C24.6071 17.4004 25.0996 17.8929 25.0996 18.5004C26.9221 18.5004 28.3996 19.9779 28.3996 21.8004C28.3996 22.4079 27.9071 22.9004 27.2996 22.9004C26.6921 22.9004 26.1996 22.4079 26.1996 21.8004C26.1996 21.1929 25.7071 20.7004 25.0996 20.7004H22.6174C22.1657 20.7004 21.7996 21.0665 21.7996 21.5181C21.7996 21.8701 22.0248 22.1826 22.3588 22.2939L26.3361 23.6197C27.5684 24.0305 28.3996 25.1837 28.3996 26.4826C28.3996 28.1493 27.0485 29.5004 25.3819 29.5004H25.0996C25.0996 30.1079 24.6071 30.6004 23.9996 30.6004C23.3921 30.6004 22.8996 30.1079 22.8996 29.5004C21.0771 29.5004 19.5996 28.0229 19.5996 26.2004C19.5996 25.5929 20.0921 25.1004 20.6996 25.1004C21.3071 25.1004 21.7996 25.5929 21.7996 26.2004C21.7996 26.8079 22.2921 27.3004 22.8996 27.3004H25.3819C25.8335 27.3004 26.1996 26.9343 26.1996 26.4826C26.1996 26.1306 25.9744 25.8181 25.6404 25.7068L21.6631 24.381C20.4308 23.9703 19.5996 22.8171 19.5996 21.5181C19.5996 19.8515 20.9507 18.5004 22.6174 18.5004H22.8996Z"
                        fill={location.pathname === '/orders' ? "#FFFFFF" : '#AEB3B7'}/>
                    </svg>
                    <p className="sidebar__link-name orders">
                      Orders
                      {userInfo?.orders && (
                        <span style={{backgroundColor: userInfo?.orders?.order_color}}>{userInfo?.orders?.count}</span>
                      )}
                    </p>
                  </NavLink>}
                {
                  userInfo?.permissions?.includes('inspection_form') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <InspectionForm color={location.pathname === '/inspections-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspection Form</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/inspections-form' ? 'active' : ''}`}
                               to="/inspection-form">
                        <InspectionForm color={location.pathname === '/inspections-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Inspection Form</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.is_corporate &&
                  <NavLink className={`sidebar__link ${location.pathname === '/tools' ? 'active' : ''}`} to="/tools">
                    <ToolsIcon color={location.pathname === '/tools' ? '#FFFFFF' : '#AEB3B7' } />
                    <p className="sidebar__link-name">Tools Management</p>
                  </NavLink>
                }
                {
                  userInfo?.permissions?.includes('facility') && (
                    userInfo?.agency?.trial_status === 'equipment' || userInfo?.agency?.trial_status === 'inspection' ?
                      null :
                      <NavLink className={`sidebar__link`} to="/facility">
                        <FacilityIcon color={location.pathname === '/facility' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Facility</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('corrective_action') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Corrective color={location.pathname === '/actions' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Corrective Actions</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/actions' ? 'active' : ''}`} to="/actions">
                        <Corrective color={location.pathname === '/actions' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Corrective Actions</p>
                      </Link>
                  )
                }
                {
                  userInfo?.permissions?.includes('log_sheet') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Log color={location.pathname === '/log-sheets' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Log Sheets</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/log-sheets' ? 'active' : ''}`}
                            to="/log-sheets">
                        <Log color={location.pathname === '/log-sheets' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Log Sheets</p>
                      </Link>
                  )
                }
                {
                  userInfo?.permissions?.includes('observation') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Service color={location.pathname === '/observation' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Observation</p>
                      </p> :
                      <NavLink className={`sidebar__link ${location.pathname === '/observation' ? 'active' : ''}`}
                               to="/observation">
                        <Service color={location.pathname === '/observation' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Observation</p>
                      </NavLink>
                  )
                }

                {
                  userInfo?.permissions?.includes('equipment_type') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <EquipmentType color={location.pathname === '/equipment-type' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Equipment Type</p>
                      </p> :
                      <NavLink className={`sidebar__link`} to="/equipment-type">
                        <EquipmentType color={location.pathname === '/equipment-type' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Equipment Type</p>
                      </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('equipment_management') && (
                    <NavLink className={`sidebar__link`} to="/equipment?active=0">
                      <Equipment color={location.pathname === '/equipment' ? '#FFFFFF' : '#AEB3B7'}/>
                      <p className="sidebar__link-name">Equipment Management</p>
                    </NavLink>
                  )
                }
                {
                  userInfo?.permissions?.includes('user_management') && !userInfo?.is_corporate ? (
                    <Link className={`sidebar__link ${location.pathname === '/management' ? 'active' : ''}`}
                          to="/management">
                      <Management color={location.pathname === '/management' ? '#FFFFFF' : '#AEB3B7'}/>
                      <p className="sidebar__link-name">User Management</p>
                    </Link>
                  ) : null
                }
                {/*{*/}
                {/*  userInfo?.permissions?.includes('user_management') && (*/}
                {/*    <Link className={`sidebar__link ${location.pathname === '/management'  ? 'active' : ''}`} to="/management">*/}
                {/*      <Management color={location.pathname === '/management' ? '#FFFFFF' : '#AEB3B7' } />*/}
                {/*      <p className="sidebar__link-name">User Management</p>*/}
                {/*    </Link>*/}
                {/*  )*/}
                {/*}*/}
                {
                  userInfo?.permissions?.includes('report') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Report color={location.pathname === '/report' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Report</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/report' ? 'active' : ''}`} to="/report">
                        <Report color={location.pathname === '/report' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Report</p>
                      </Link>
                  )
                }
                {/*{*/}
                {/*  userInfo?.is_global && (*/}
                {/*    <Link className={`sidebar__link ${location.pathname === '/customer-requests'  ? 'active' : ''}`} to="/customer-requests">*/}
                {/*      <CustomerRequestSVG color={location.pathname === '/customer-requests' ? '#FFFFFF' : '#AEB3B7' } />*/}
                {/*      <p className="sidebar__link-name">Customer Requests</p>*/}
                {/*    </Link>*/}
                {/*  )*/}
                {/*}*/}
                {
                  userInfo?.permissions?.includes('fuel_receipt') && (
                    userInfo?.agency?.trial_status === 'equipment' ?
                      <p className={`sidebar__link inactive`}>
                        <Fuel color={location.pathname === '/receipt-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Fuel Receipt Form</p>
                      </p> :
                      <Link className={`sidebar__link ${location.pathname === '/receipt-form' ? 'active' : ''}`}
                            to="/receipt-form">
                        <Fuel color={location.pathname === '/receipt-form' ? '#FFFFFF' : '#AEB3B7'}/>
                        <p className="sidebar__link-name">Fuel Receipt Form</p>
                      </Link>
                  )
                }
                {
                  userInfo?.is_global &&
                  <NavLink className={`sidebar__link ${location.pathname === '/settings' ? 'active' : ''}`}
                           to="/settings">
                    <Settings color={location.pathname === '/settings' ? '#FFFFFF' : '#AEB3B7'}/>
                    <p className="sidebar__link-name">Settings</p>
                  </NavLink>
                }
              </div>
              : null
        }
      </div>
      {profileStatus && profileWindow()}
      <div className="sidebar__user" onClick={() => setProfileStatus(!profileStatus)}>
        <img src={userInfo?.avatar} alt="profile" className="sidebar__user-photo"/>
        <div className="sidebar__user-info">
          <p className="sidebar__user-name">{userInfo?.name}</p>
          <p className="sidebar__user-role">
            {
              userInfo?.is_admin ? "Admin" :
                userInfo?.is_inspector ? "Inspector" :
                  userInfo?.is_linesman ? "Linesman" :
                    userInfo?.is_global ? "Global" :
                      userInfo?.is_corporate ? "Corporate" :
                      null
            }
          </p>
        </div>
        <img src="/assets/icons/arrow.svg" alt="arrow" className={ + profileStatus ? 'rotate sidebar__user-arrow' : 'sidebar__user-arrow'}/>
      </div>
    </div>
  );
};


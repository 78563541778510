import React from 'react';

const Coin = ({color}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icons/Coin/Line">
        <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M12 20.8C16.8601 20.8 20.8 16.8601 20.8 12C20.8 7.13989 16.8601 3.2 12 3.2C7.13989 3.2 3.2 7.13989 3.2 12C3.2 16.8601 7.13989 20.8 12 20.8ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" fill={color}/>
        <path id="Shape_2" d="M10.8996 6.50039C10.8996 5.89288 11.3921 5.40039 11.9996 5.40039C12.6071 5.40039 13.0996 5.89288 13.0996 6.50039C14.9221 6.50039 16.3996 7.97785 16.3996 9.80039C16.3996 10.4079 15.9071 10.9004 15.2996 10.9004C14.6921 10.9004 14.1996 10.4079 14.1996 9.80039C14.1996 9.19288 13.7071 8.70039 13.0996 8.70039H10.6174C10.1657 8.70039 9.79961 9.06651 9.79961 9.51815C9.79961 9.87014 10.0248 10.1826 10.3588 10.2939L14.3361 11.6197C15.5684 12.0305 16.3996 13.1837 16.3996 14.4826C16.3996 16.1493 15.0485 17.5004 13.3819 17.5004H13.0996C13.0996 18.1079 12.6071 18.6004 11.9996 18.6004C11.3921 18.6004 10.8996 18.1079 10.8996 17.5004C9.07707 17.5004 7.59961 16.0229 7.59961 14.2004C7.59961 13.5929 8.0921 13.1004 8.69961 13.1004C9.30712 13.1004 9.79961 13.5929 9.79961 14.2004C9.79961 14.8079 10.2921 15.3004 10.8996 15.3004H13.3819C13.8335 15.3004 14.1996 14.9343 14.1996 14.4826C14.1996 14.1306 13.9744 13.8181 13.6404 13.7068L9.66307 12.381C8.43079 11.9703 7.59961 10.8171 7.59961 9.51815C7.59961 7.85149 8.95071 6.50039 10.6174 6.50039H10.8996Z" fill={color}/>
      </g>
    </svg>
  );
};

export default Coin;
import React, {useEffect} from 'react';
import Pencil from "../../../SVG/Pencil";
import {useLocation} from "react-router-dom";
import Trash from "../../../SVG/Trash";
import MapIcon from "../../../SVG/MapIcon";

const CorporateRegionDetailsRow = ({agency, setRegionsList, regionsList}) => {
  const location = useLocation();

  return (
    <div className="regions__table-row" id={agency?.id}>
      {
        agency.dropdown_status &&
        <div className="fboManagement__dropdown corporate">
          <div className="fboManagement__dropdown-elem" onClick={() => {

          }}>
            <MapIcon color={'#999999'} />
            <p>Region Details</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {

          }}>
            <Pencil color={'#999999'} />
            <p>Edit Regions</p>
          </div>
          <div className="fboManagement__dropdown-elem" onClick={() => {

          }}>
            <Trash color={'#999999'} />
            <p>Delete Region</p>
          </div>
        </div>
      }
      <div className="regions__table-td">{agency?.id}</div>
      <div className="regions__table-td">{agency?.title}</div>
      <div className="regions__table-td">{agency?.airport_id ? agency?.airport_id : '---'}</div>
      <div className="regions__table-td">{agency?.timezone ? agency?.timezone : '---'}</div>
      <div className="regions__table-td logo">
        <img src={agency?.logo} alt="logo"/>
      </div>
    </div>
  );
};

export default CorporateRegionDetailsRow;
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UserIcon from "../../../SVG/UserIcon";
import Pencil from "../../../SVG/Pencil";
import LockIcon from "../../../SVG/LockIcon";
import Trash from "../../../SVG/Trash";
import {
  useDeleteUserMutation,
  useGetAgencyQuery, useLazyCorporateDetailsQuery,
  useLazyGetChosenUserQuery, useUpdatePasswordMutation,
  useUpdateUserMutation
} from "../../../store/builder/inspections.api";
import Input from "../../Input";
import Select from "react-select";
import {setLoader} from "../../../store/builder/reducer";
import {toast} from "react-toastify";
import {validationEmail} from "../../../global/validate";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";

const CorporateDetailsRow = ({
                               lengthArray,
                               indexElem,
                               user,
                               setCurrentUser,
                               currentUser,
                               userRolesData,
                                type
}) => {
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [lastElem, setLastElem] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [settingsStatus, setSettingsStatus] = useState(false);
  const [chosenRoles, setChosenRoles] = useState({});
  const [chosenAgency, setChosenAgency] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [userEditData, setUserEditData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    initials: "",
    ein: "",
    // confirm_password: "",
    role_ids: [],
    agency_ids: [],
    notify_corrective_actions: false,
    notify_inspections: false,
    notify_equipment: false,
    notify_service_requests: false,
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirm_password: "",
  });

  const [dataErrors, setDataErrors] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    einError: false,
    rolesError: false,
    agencyError: false,
  });

  const [getCorporate,
    {
      data: corporateData,
      error: corporateError,
      isLoading: corporateIsLoading,
      isSuccess: corporateIsSuccess,
      isError: corporateIsError,
    }] = useLazyCorporateDetailsQuery();

  const [
    getUser,
    {
      data: chosenUserData,
      isSuccess: chosenUserIsSuccess,
      isLoading: chosenUserIsLoading,
      isError: chosenUserIsError,
      error: chosenUserError,
    },
  ] = useLazyGetChosenUserQuery();

  const [
    deleteUser,
    {
      data: deleteUserData,
      isSuccess: deleteUserIsSuccess,
      isLoading: deleteUserIsLoading,
      isError: deleteUserIsError,
      error: deleteUserError,
    },
  ] = useDeleteUserMutation();

  const {
    data: agencyData,
    error: agencyError,
    isFetching: agencyIsLoading,
    isSuccess: agencyIsSuccess,
  } = useGetAgencyQuery();

  const [
    updateUser,
    {
      data: updateUserData,
      isSuccess: updateUserIsSuccess,
      isFetching: updateUserIsLoading,
      isError: updateUserIsError,
      error: updateUserError,
    },
  ] = useUpdateUserMutation();
  const [
    updatePassword,
    {
      data: updatePasswordData,
      isSuccess: updatePasswordIsSuccess,
      isFetching: updatePasswordIsLoading,
      isError: updatePasswordIsError,
      error: updatePasswordError,
    },
  ] = useUpdatePasswordMutation();

  useEffect(() => {
    if (editModal || confirmStatus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [editModal, confirmStatus]);

  useEffect(() => {
    if (deleteUserIsLoading) {
      dispatch(setLoader(true));
    }
    if (corporateIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (deleteUserIsSuccess) {
      dispatch(setLoader(false));
      toast.success(deleteUserData?.message);
      setConfirmStatus(false);
    }
    if (updateUserIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
    if (updateUserIsSuccess) {
      dispatch(setLoader(false));
      toast.success(updateUserData?.message);
      setEditModal(false);
    }
    if (chosenUserIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [
    deleteUserIsSuccess,
    deleteUserIsLoading,
    updateUserIsLoading,
    updateUserIsSuccess,
    chosenUserIsLoading,
    corporateIsLoading,

  ]);

  useEffect(() => {
    if (chosenUserIsSuccess) {
      setChosenRoles({
        value: chosenUserData?.data?.role?.id,
        label: chosenUserData?.data?.role?.name,
      });
      setChosenAgency({
        value: chosenUserData?.data?.agency?.id,
        label: chosenUserData?.data?.agency?.title,
      });

      setUserEditData((old) => ({
        ...old,
        first_name: chosenUserData?.data?.first_name,
        last_name: chosenUserData?.data?.last_name,
        email: chosenUserData?.data?.email,
        ein: chosenUserData?.data?.ein,
        initials: chosenUserData?.data?.initials,
        notify_corrective_actions:
        chosenUserData?.data?.notify_corrective_actions,
        notify_inspections: chosenUserData?.data?.notify_inspections,
        notify_equipment: chosenUserData?.data?.notify_equipment,
        notify_service_requests: chosenUserData?.data?.notify_service_requests,
      }));
    }
  }, [chosenUserData, agencyData]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (
        e.target.closest(".userSettings") === null &&
        e.target.closest(".management__table-settings") === null
      ) {
        setSettingsStatus(false);
      }
    });
  }, [location]);

  useEffect(() => {
    if (dataErrors.firstNameError) {
      toast.error("Please enter correct first name");
    }
    if (dataErrors.lastNameError) {
      toast.error("Please enter correct last name");
    }
    if (dataErrors.emailError) {
      toast.error("Please enter correct email");
    }
    if (dataErrors.ein) {
      toast.error("Please enter ein");
    }
    // if (dataErrors.passwordError) {
    //   toast.error("Please enter correct password and confirm password");
    // }
    if (dataErrors.rolesError) {
      toast.error("Please choose any role");
    }
    if (dataErrors.agencyError) {
      toast.error("Please choose any FBO");
    }
  }, [dataErrors]);

  const handleDropdownToggle = () => {
    setSettingsStatus(!settingsStatus);
  };

  const validateEdit = () => {
    if (!userEditData?.first_name || userEditData?.first_name?.length < 3) {
      setDataErrors((old) => ({ ...old, firstNameError: true }));
    } else {
      setDataErrors((old) => ({ ...old, firstNameError: false }));
    }
    if (!userEditData?.last_name || userEditData?.last_name?.length < 3) {
      setDataErrors((old) => ({ ...old, lastNameError: true }));
    } else {
      setDataErrors((old) => ({ ...old, lastNameError: false }));
    }
    if (!userEditData?.email && !validationEmail(userEditData?.email)) {
      setDataErrors((old) => ({ ...old, emailError: true }));
    } else {
      setDataErrors((old) => ({ ...old, emailError: false }));
    }
    // if (userEditData?.password || userEditData?.confirm_password) {
    //   if (userEditData?.password !== userEditData?.confirm_password) {
    //     setDataErrors((old) => ({ ...old, passwordError: true }));
    //   }
    // } else {
    //   setDataErrors((old) => ({ ...old, passwordError: false }));
    // }
    if (chosenRoles === {}) {
      setDataErrors((old) => ({ ...old, rolesError: true }));
    } else {
      setDataErrors((old) => ({ ...old, rolesError: false }));
    }
    // if (chosenAgency === {}) {
    //   setDataErrors((old) => ({ ...old, agencyError: true }));
    // } else {
    //   setDataErrors((old) => ({ ...old, agencyError: false }));
    // }
  };


  const tableRowSettings = () => {
    return (
      <div
        className={`${
          lastElem
            ? "management__table-settings_last"
            : "management__table-settings"
        }`}
      >
        <div
          className="management__table-edit"
          onClick={() => {
            setUserDetailsModal(true);
          }}
        >
          <UserIcon color={"#999999"} />
          <span>User Details</span>
        </div>

        <div
          className="management__table-edit"
          onClick={() => {
            setEditModal(true);
            getUser(user.id);
          }}
        >
          <Pencil color={"#999999"} />
          <span>Edit User</span>
        </div>
        <div
          className="management__table-edit"
          onClick={() => {
            setEditPasswordModal(true);
          }}
        >
          <LockIcon color={"#999999"} />
          <span>Update Password</span>
        </div>

        <div
          className="management__table-delete"
          onClick={() => setConfirmStatus(true)}
        >
          <Trash color={"#999999"} />
          <span>Delete User</span>
        </div>
      </div>
    );
  };

  //functions
  const handleEditChanges = (e) => {
    if (e.target.type === "checkbox") {
      setUserEditData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setUserEditData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };
  const handlePasswordChanges = (e) => {
    setPasswordData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    validateEdit();
    if (
      !dataErrors?.nameError &&
      !dataErrors?.lastNameError &&
      // !dataErrors?.emailError &&
      !dataErrors?.rolesError &&
      !dataErrors?.passwordError
      // !dataErrors?.agencyError
    ) {

      dispatch(setLoader(true));
      updateUser({
        id: user.id,
        first_name: userEditData?.first_name,
        last_name: userEditData?.last_name,
        email: userEditData?.email,
        ein: userEditData?.ein,
        initials: userEditData?.initials,
        role_id: chosenRoles?.value,
        agency_id: chosenAgency?.value,
        notify_corrective_actions: userEditData?.notify_corrective_actions,
        notify_inspections: userEditData?.notify_inspections,
        notify_equipment: userEditData?.notify_equipment,
        notify_service_requests: userEditData?.notify_service_requests,
      })
        .then((res) => {
          dispatch(setLoader(false));
        })
        .catch((err) => {
          dispatch(setLoader(false));
        });
    }
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (!passwordData?.password || !passwordData?.confirm_password) {
      toast.error("Please enter both passwords!");
    } else {
      if (passwordData?.password?.length < 8) {
        toast.error("The password must be at least 8 characters.");
        return;
      }
      if (passwordData?.password === passwordData?.confirm_password) {
        dispatch(setLoader(true));
        updatePassword({
          id: user.id,
          password: passwordData?.password,
          password_confirmation: passwordData?.confirm_password,
        })
          .then(() => {
            setEditPasswordModal(false);
            toast.success("Password updated successfully!");
          })
          .catch((err) => {
            toast.error(err?.error?.message);
          })
          .finally(() => {
            dispatch(setLoader(false));
          })
      } else {
        toast.error("Passwords should be matched!");
      }
    }
  };

  //modals
  const confirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => {
                setConfirmStatus(false);
                setSettingsStatus(false);
              }}
            />
          </div>
          <div className="modal__content-body delete">
            <p>Are you sure you want to delete this user?</p>
          </div>
          <div className="modal__content-bottom">
            <button
              className="close"
              onClick={() => {
                setConfirmStatus(false);
                setSettingsStatus(false);
              }}
            >
              Close
            </button>
            <button className="save" onClick={async () => {
              await deleteUser(user.id)
                .then(() => {
                  getCorporate(params?.id)
                })
            }}>
              Delete
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    );
  };

  const renderEditModal = () => {
    return (
      <form className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Update User</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setEditModal(false)}
            />
          </div>
          <div className="modal__content-body user">
            <Input
              id="first_name"
              name="first_name"
              placeholder="First name"
              label={"First name"}
              type={"text"}
              defaultValue={userEditData?.first_name}
              onChange={handleEditChanges}
              required={true}
            />
            <Input
              id="last_name"
              name="last_name"
              placeholder="Last name"
              label={"Last name"}
              type={"text"}
              defaultValue={userEditData?.last_name}
              onChange={handleEditChanges}
              required={true}
            />
            <Input
              id="ein"
              name="ein"
              placeholder="Ein"
              label={"Ein"}
              type={"text"}
              defaultValue={userEditData?.ein}
              onChange={handleEditChanges}
              required={true}
            />
            <Input
              id="email"
              name="email"
              placeholder="Email"
              label={"Email"}
              type={"email"}
              required={true}
              defaultValue={userEditData?.email}
              onChange={handleEditChanges}
            />
            <Input
              required={true}
              defaultValue={userEditData?.initials}
              onChange={handleEditChanges}
              id="initials"
              name="initials"
              placeholder="Initials"
              label={"Initials"}
              type={"text"}
            />

            {/*<div className="customSelect">*/}
            {/*  <span className="customSelect__label">User Roles</span>*/}
            {/*  <Select*/}
            {/*    options={userRolesData?.map((role) => ({*/}
            {/*      value: role.id ,*/}
            {/*      label: role.name*/}
            {/*    }))}*/}
            {/*    styles={{*/}
            {/*      control: (baseStyles, state) => ({*/}
            {/*        ...baseStyles,*/}
            {/*        borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",*/}
            {/*        borderRadius: 8,*/}
            {/*        height: 42,*/}
            {/*        backgroundColor: "#F4F5F6",*/}
            {/*        marginTop: 8,*/}
            {/*        textTransform: "capitalize",*/}
            {/*      }),*/}
            {/*    }}*/}
            {/*    value={chosenRoles}*/}
            {/*    onChange={(e) => {*/}
            {/*      setChosenRoles(e);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*{agencyData && (*/}
            {/*  <div className="customSelect">*/}
            {/*    <span className="customSelect__label">FBO</span>*/}
            {/*    <Select*/}
            {/*      options={agencyData?.map((role) => ({*/}
            {/*        value: role.id,*/}
            {/*        label: role.title,*/}
            {/*      }))}*/}
            {/*      styles={{*/}
            {/*        control: (baseStyles, state) => ({*/}
            {/*          ...baseStyles,*/}
            {/*          borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",*/}
            {/*          borderRadius: 8,*/}
            {/*          height: 42,*/}
            {/*          backgroundColor: "#F4F5F6",*/}
            {/*          marginTop: 8,*/}

            {/*        }),*/}
            {/*      }}*/}
            {/*      name={"agency_ids"}*/}
            {/*      value={chosenAgency}*/}
            {/*      onChange={(e) => setChosenAgency(e)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            {user?.is_admin && (
              <div style={{ marginTop: 20 }} className="edit__user">
                <label className="checkbox-container">
                  Notify Corrective Actions
                  <input
                    type={"checkbox"}
                    id={"notify_corrective_actions"}
                    name={"notify_corrective_actions"}
                    onChange={handleEditChanges}
                    defaultChecked={userEditData?.notify_corrective_actions}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox-container">
                  Notify Equipment
                  <input
                    type={"checkbox"}
                    id={"notify_equipment"}
                    name={"notify_equipment"}
                    onChange={handleEditChanges}
                    defaultChecked={userEditData?.notify_equipment}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox-container">
                  Notify Inspections
                  <input
                    type={"checkbox"}
                    id={"notify_inspections"}
                    name={"notify_inspections"}
                    onChange={handleEditChanges}
                    defaultChecked={userEditData?.notify_inspections}
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="checkbox-container">
                  Notify Observation
                  <input
                    type={"checkbox"}
                    id={"notify_service_requests"}
                    name={"notify_service_requests"}
                    onChange={handleEditChanges}
                    defaultChecked={userEditData?.notify_service_requests}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </div>

          <div className="modal__content-bottom">
            <button className="close" onClick={() => setEditModal(false)}>
              Close
            </button>
            <button className="save" onClick={handleCreateUser}>
              Save
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </form>
    );
  };

  const renderEditPasswordModal = () => {
    return (
      <form className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Update Password</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setEditPasswordModal(false)}
            />
          </div>
          <div className="modal__content-body user">
            <Input
              id="password"
              name="password"
              placeholder="Password"
              label={"Password"}
              type={"password"}
              minLength={8}
              onChange={handlePasswordChanges}
            />
            <Input
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm password"
              label={"Confirm password"}
              type={"password"}
              minLength={8}
              onChange={handlePasswordChanges}
            />
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setEditPasswordModal(false)}>
              Close
            </button>
            <button className="save" onClick={handleChangePassword}>
              Save
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </form>
    );
  };

  const renderUserDetailsModal = () => {
    return (
      <form className="modal modal-drawer">
        <div className="modal__content modal__content-drawer">
          <div className="modal__content-head">
            <p>User Details</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUserDetailsModal(false)}
            />
          </div>
          <div className="modal__content-body user-drawer">
            <div className="modal__content-body_avatar">
              <div className="modal__content-body_avatar-image-wrapper">
                <img src={currentUser?.avatar} alt="avatar" />
              </div>
            </div>
            <Input
              disabled={true}
              id="first_name"
              name="first_name"
              placeholder="First name"
              label={"First name"}
              type={"text"}
              defaultValue={currentUser?.first_name}
            />
            <Input
              disabled={true}
              id="last_name"
              name="last_name"
              placeholder="Last name"
              label={"Last name"}
              type={"text"}
              defaultValue={currentUser?.last_name}
            />
            <Input
              id="ein"
              name="ein"
              placeholder="Ein"
              label={"Ein"}
              type={"text"}
              defaultValue={currentUser?.ein}
              disabled={true}
            />
            <Input
              id="email"
              name="email"
              placeholder="Email"
              label={"Email"}
              type={"email"}
              disabled={true}
              defaultValue={currentUser?.email}
            />
            <Input
              disabled={true}
              id="initials"
              name="initials"
              placeholder="Initials"
              label={"Initials"}
              type={"text"}
              defaultValue={currentUser?.initials}
            />
            <div className="modal__content-body_signature">
              <img src={currentUser?.signature} alt="signature" />
            </div>
            <Input
              id="user-roles"
              name="user-roles"
              placeholder="User Roles"
              label={"User Roles"}
              disabled={true}
              defaultValue={currentUser?.role_title}
            />
          </div>

          <div className="modal__content-bottom">
            <button className="close" onClick={() => setUserDetailsModal(false)}>
              Cancel
            </button>
            <button className="save" onClick={() => {
              setUserDetailsModal(false);
              setEditModal(true);
              getUser(user.id);
            }}>
              Edit User
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setUserDetailsModal(false)}></div>
      </form>
    );
  };

  return (
    <>
      {confirmStatus && confirmModal()}
      {editModal && renderEditModal()}
      {userDetailsModal && renderUserDetailsModal()}
      {editPasswordModal && renderEditPasswordModal()}
      {
        type === 'subscription' ?
          <div
            className="management__table-row subsc"
            onMouseOver={() => setCurrentUser(user)}
          >
            <div className="management__table-bodyTh">
              <span>{user.start_date ? moment(user.start_date).format("MM/DD/YYYY") : '---'}</span>
            </div>
            <div className="management__table-bodyTh">
              <span>{user.start_date ? moment(user.end_date).format("MM/DD/YYYY") : '---'}</span>
            </div>
            <div
              style={{ wordWrap: "break-word" }}
              className="management__table-bodyTh"
            >
              <span>{user?.price ? user?.price : "---"}</span>
            </div>
            <div
              style={{ wordWrap: "break-word" }}
              className="management__table-bodyTh"
            >
              <span>
                {user?.receipt_url ?
                  <a href={user?.receipt_url}>Open link</a>
                  :
                  "---"}

              </span>
            </div>
          </div>
          : type === 'users' ?
          <div
            className="management__table-row corporate"
            onMouseOver={() => setCurrentUser(user)}
          >
            <div className="management__table-bodyTh">

              <span>{user.id}</span>
            </div>
            <div className="management__table-bodyTh">
              <span>{user.first_name}</span>
            </div>
            <div
              style={{ wordWrap: "break-word" }}
              className="management__table-bodyTh"
            >
              <span>{user?.last_name}</span>
            </div>
            <div className="management__table-bodyTh" style={{textTransform: 'capitalize'}}>
              <p>{user?.email}</p>
            </div>
            <div className="management__table-bodyTh" style={{textTransform: 'capitalize'}}>
              <p>{user?.ein || '--- ---'}</p>
            </div>
            <div className="management__table-bodyTh" style={{textTransform: 'capitalize'}}>
              <p>{user?.initials || '--- ---'}</p>
            </div>
            <div className="management__table-bodyTh userSettings">
              <img
                src="/assets/icons/dots.svg"
                alt="dots"
                className="dots"
                onClick={() => {
                  handleDropdownToggle();
                  if (lengthArray === indexElem + 1) {
                    setLastElem(true);
                  } else {
                    setLastElem(false);
                  }
                }}
              />
              {settingsStatus && tableRowSettings()}
            </div>
          </div>
          : type === 'fbo' ?
              <div
                className="management__table-row subsc"
                onMouseOver={() => setCurrentUser(user)}
              >
                <div className="management__table-bodyTh">
                  <span>{user?.id}</span>
                </div>
                <div className="management__table-bodyTh">
                  <span>{user.title}</span>
                </div>
                <div
                  style={{ wordWrap: "break-word" }}
                  className="management__table-bodyTh"
                >
                  <span>{user?.airport_id}</span>
                </div>
                <div
                  style={{ wordWrap: "break-word" }}
                  className="management__table-bodyTh"
                >
                  <span>
                      {user?.timezone}
                  </span>
                </div>
              </div>
            : null
      }
    </>
  );
};

export default CorporateDetailsRow;
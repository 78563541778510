import React, {useEffect, useState} from "react";
import { validationEmail } from "../../global/validate";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ReactCodeInput from 'react-verification-code-input';
import {useSendCodeMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {setEmail, setVerify} from "../../store/builder/forgotPasswordSlice";

export default function EnterCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(null);
  const forgotData = useSelector(state => state.rootReducer.forgotPasswordSlice)

  const [sendCode, {data, error, isError, isSuccess, isLoading}] = useSendCodeMutation()
  const [errorHandler, setErrorHandler] = useErrorHandler([error])

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(setVerify(data?.data?.verify_token))
  //     navigate('/create-password')
  //   }
  // }, [isSuccess])
  //
  // useEffect(() => {
  //   if (isLoading) {
  //     dispatch(setLoader(true))
  //   } else {
  //     dispatch(setLoader(false))
  //   }
  // }, [isLoading])

  const handleCode = (e) => {
    setState(e);
  };


  const submitHandler = (e) => {
    e.preventDefault();
    if (state?.length === 6) {
      dispatch(setLoader(true))
      // sendCode({code: state, verify_token: forgotData.verifyToken})
      let body = {code: state, verify_token: forgotData.verifyToken}
      let config = {
        headers: {
          AppVersion: '1.0.0',
          AppType: 'web',
          DeviceType: 'web'
        },
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/verify-reset-password`, body, config)
        .then((res) => {
          dispatch(setVerify(res?.data?.data?.verify_token))
          navigate('/create-password')
          dispatch(setLoader(false));
          toast.success(res.data.message);
        })
        .catch((err) => {
          dispatch(setLoader(false));
          toast.error(err.response.data.message);
        });
    } else {
      toast.error('Enter valid code from email')
    }
  };

  return (
    <div className="auth">
      <Helmet>
        <title>Enter Code | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/forgot-image.png" alt="bg" />
        </div>
        <div className="auth__right">
          <div className="auth__content forgot">
            <h1 className="auth__title">Enter verification code</h1>
            <p className="auth__subtitle">We have just sent a verification code to {forgotData?.email}</p>
            <form className="auth__form" onSubmit={submitHandler}>
              <div className="auth__field">
                <ReactCodeInput className="auth__field-codeField" onChange={handleCode} />
                {state?.email?.errorShow && (
                  <span className="auth__error">{state?.email?.error}</span>
                )}
              </div>
              <button className="auth__submit">Verify</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import moment from "moment";
import React from "react";

import "./index.scss";

const OrderDetailsHistory = ({ currentOrder, setCurrentOrder }) => {
  return (
    <div className="order-history">
      <div
        className="order-history__title"
        onClick={() => {
          setCurrentOrder(null);
        }}
      >
        <img src="/assets/icons/back.svg" alt="" />
        <span>Order Details</span>
      </div>
      <div className="inspections__orders-head" style={{ paddingLeft: 10 }}>
        <div className="inspections__orders-headInner">
          <p className="inspections__orders-info">
            ID:{" "}
            <span className="inspections__orders-id">#{currentOrder?.id}</span>
          </p>
          <p className="inspections__orders-info">
            Date Order:{" "}
            <span className="inspections__orders-default">
              {moment(currentOrder?.created_at).format("MM/DD/YY")}
            </span>
          </p>
          <p className="inspections__orders-info">
            Time Order:{" "}
            <span className="inspections__orders-default">
              {moment(currentOrder?.created_at)?.format("HH:mm")}
            </span>
          </p>
          <p className="inspections__orders-info">
            Status:{" "}
            <span
              className={`inspections__orders-default s4 ${currentOrder?.status}
              `}
            >
              {currentOrder?.status}
            </span>
          </p>
        </div>
      </div>
      <div className="inspections__orders-body">
        <div
          className="inspections__orders-table"
          style={{ padding: 0, gap: 15 }}
        >
          <div className="inspections__orders-tableBox">
            <div className="inspections__orders-tableTitle">FBO</div>
            <div className="inspections__orders-tableInfo">
              {currentOrder?.agency_title}
            </div>
          </div>
          <div className="inspections__orders-tableBox">
            <div className="inspections__orders-tableTitle">
              Shipping address
            </div>
            <div className="inspections__orders-tableInfo">
              {currentOrder?.shipping_address?.name}
            </div>
            <div className="inspections__orders-tableInfo">
              {currentOrder?.shipping_address?.address?.country}
            </div>
            <div className="inspections__orders-tableInfo">
              {currentOrder?.shipping_address?.address?.line1}
            </div>
            {currentOrder?.shipping_address?.address?.line2 && (
              <div className="inspections__orders-tableInfo">
                {currentOrder?.shipping_address?.address?.line2}
              </div>
            )}
            <div className="inspections__orders-tableInfo">
              {currentOrder?.shipping_address?.address?.city}
              {currentOrder?.shipping_address?.address?.state
                ? currentOrder?.shipping_address?.address?.state
                : ""}
            </div>
          </div>
          <div className="inspections__orders-tableBox">
            <div className="inspections__orders-tableTitle">Sale status</div>
            <div className="inspections__orders-tableInfo">
              <span
                style={{
                  display: "block",
                  textAlign: "center",
                  width: "100%",
                  maxWidth: 150,
                  backgroundColor: currentOrder?.highlight_color,
                  color: currentOrder?.highlight_color ? '"#FFFFFF"' : "auto",
                  padding: "5px 10px",
                  borderRadius: 6,
                  fontSize: 15,
                }}
              >
                {currentOrder?.processing_status
                  ? currentOrder?.processing_status
                  : "-"}
              </span>
            </div>
          </div>
          <div className="inspections__orders-tableBox">
            <div className="inspections__orders-tableTitle">Total</div>
            <div className="inspections__orders-tableInfo">
              <span>${currentOrder?.total}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="inspections"
        style={{ margin: 0, padding: 0, marginTop: 20, maxWidth: "100%" }}
      >
        <div className="inspectionsForm__box">
          <div className="inspectionsForm__table">
            <div className="inspectionsForm__table-head">
              <div
                style={{
                  width: "18%",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                ID
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Price
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Product Name
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                QTY
              </div>
              <div
                style={{
                  width: "18%",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className="inspectionsForm__table-th"
              >
                Total
              </div>
            </div>
            {currentOrder?.items?.map((item) => (
              <div className="inspectionsForm__table-row" key={item.id}>
                <div
                  style={{ width: "18%", paddingRight: "5px" }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.id}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  ${item?.price}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.product_name}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  {item?.qty}
                </div>
                <div
                  style={{
                    width: "18%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                  }}
                  className="inspectionsForm__table-bodyTh"
                >
                  ${item?.total}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsHistory;

import React from 'react';

const GreenGraph = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="52" viewBox="0 0 74 52" fill="none">
      <path d="M5.21216 32.4822C3.56205 32.4822 2.03887 34.7858 0.896484 37.6012V51.4227H73.5014V1C71.0896 1.51191 69.9473 31.4584 65.5047 31.4584C61.9506 31.4584 58.6585 19.5243 57.0002 18.9167C55.342 18.3091 53.827 19.6766 52.6846 18.9167C51.5422 18.1567 50.5267 11.2381 48.3689 10.4703C43.7994 9.44644 35.168 34.7858 31.1062 34.7858C27.5521 34.0179 26.9497 25.0783 23.3634 24.8036C20.1901 24.5605 17.6515 44.512 13.9704 44.512C11.4318 44.512 7.87772 32.4822 5.21216 32.4822Z" fill="url(#paint0_linear_10861_18466)"/>
      <path d="M0.896484 37.5824C2.03887 34.7684 3.56205 32.466 5.21216 32.466C7.87772 32.466 11.4318 44.4896 13.9704 44.4896C17.6515 44.4896 20.1901 24.5484 23.3634 24.7913C26.9497 25.0659 27.5521 34.0009 31.1062 34.7684C35.168 34.7684 43.7994 9.44209 48.3689 10.4654C50.5267 11.2328 51.5422 18.1479 52.6846 18.9075C53.827 19.667 55.342 18.3002 57.0002 18.9075C58.6585 19.5148 61.9506 31.4427 65.5047 31.4427C69.9473 31.4427 71.0896 1.51164 73.5013 1" stroke="#0DB091" strokeWidth="0.564433" strokeLinecap="round"/>
      <defs>
        <linearGradient id="paint0_linear_10861_18466" x1="37.1989" y1="-26.131" x2="37.1989" y2="51.4227" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0BA259" stopOpacity="0.15"/>
          <stop offset="1" stopColor="#0BA259" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GreenGraph;
import React from 'react';

const ProfileFacilityIcon = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17 20.6667V17.3333C17 15.4924 18.3431 14 20 14H28C29.6569 14 31 15.4924 31 17.3333V20.6667H32C33.6569 20.6667 35 22.1591 35 24V30.6667C35 32.5076 33.6569 34 32 34H16C14.3431 34 13 32.5076 13 30.6667V24C13 22.1591 14.3431 20.6667 16 20.6667H17ZM20 16.2222H28C28.5523 16.2222 29 16.7197 29 17.3333V31.7778H19V17.3333C19 16.7197 19.4477 16.2222 20 16.2222ZM17 22.8889H16C15.4477 22.8889 15 23.3864 15 24V30.6667C15 31.2803 15.4477 31.7778 16 31.7778H17V22.8889ZM31 31.7778H32C32.5523 31.7778 33 31.2803 33 30.6667V24C33 23.3864 32.5523 22.8889 32 22.8889H31V31.7778Z" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>

  );
};

export default ProfileFacilityIcon;
import React, {useEffect, useState} from 'react';
import './index.scss';
import {
  useGetSecretKeyMutation, useLazyGetPaymentInfoCorporateQuery,
  useLazyGetPaymentInfoQuery,
  usePaymentCheckoutMutation
} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {useStripe} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import {setSecret} from "../../store/builder/paymentSlice";
import {toast} from "react-toastify";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";
import {useNavigate} from "react-router-dom";

const PaymentModal = ({exit, body, changeTab, setAnotherPaymentModal, isCorporate}) => {
  const navigate = useNavigate();
  const reduxSecretKey = useSelector(state => state?.rootReducer?.paymentSlice?.client_secret)
  const dispatch = useDispatch();
  const [chosenMethod, setChosenMethod] = useState(null);

  const [
    getPaymentInfo,
    {
      data: paymentInfoData,
      isFetching: paymentInfoIsLoading,
      isError: paymentInfoIsError,
      error: paymentInfoError,
      isSuccess: paymentInfoIsSuccess,
    },
  ] = useLazyGetPaymentInfoQuery();

  const [
    getPaymentCorporateInfo,
    {
      data: paymentInfoCorporateData,
      isFetching: paymentInfoCorporateIsLoading,
      isError: paymentInfoCorporateIsError,
      error: paymentInfoCorporateError,
      isSuccess: paymentInfoCorporateIsSuccess,
    },
  ] = useLazyGetPaymentInfoCorporateQuery();

  useEffect(() => {
    if (isCorporate) {
      getPaymentCorporateInfo()
    } else {
      getPaymentInfo();
    }
  }, []);

  useEffect(() => {
    if (paymentInfoIsLoading || !reduxSecretKey || paymentInfoCorporateIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [paymentInfoIsLoading, reduxSecretKey, paymentInfoCorporateIsLoading]);

  useEffect(() => {
    if (!paymentInfoData?.data) {
      setChosenMethod(3)
    }
    if (!paymentInfoCorporateData?.data) {
      setChosenMethod(3)
    }
  }, [paymentInfoData, paymentInfoCorporateData])

  const handleSubmit = async () => {
    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
    if (chosenMethod === 1) {
      dispatch(setLoader(true))
        try {
          const { error, data } = stripe.confirmPayment({
            clientSecret: reduxSecretKey,
            confirmParams: {
              payment_method: isCorporate ? paymentInfoCorporateData?.data?.payment_method_id : paymentInfoData?.data?.payment_method_id,
            },
            redirect: "if_required",
          });
          if (error) {
            dispatch(setLoader(false))
            toast.error(error.message);
          } else {
            toast.success('Success!')
            dispatch(setLoader(false))
            setTimeout(() => {
              window.location.reload();
              exit();
            }, 1500)
          }
        } catch (error) {
          dispatch(setLoader(false))
          toast.error(error.message);
        }
    }
    if (chosenMethod === 2) {
      setAnotherPaymentModal(true)
      exit()
    }
    if (chosenMethod === 3) {
      if (isCorporate) {
        changeTab(4)
      } else {
        changeTab(1)
      }
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Payment Method</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="modal__content-body">
          {
            // paymentInfoCorporateData
            isCorporate ?
              paymentInfoCorporateData?.data ?
                <div className="payment-choose">
                  <div className={`payment-choose-elem ${chosenMethod === 1 ? 'active' : ''}`}
                       onClick={() => {
                         if (paymentInfoCorporateData?.data?.payment_method_status === 'requires_action'){
                           return
                         } else {
                           setChosenMethod(1)
                         }
                       }}
                  >
                    <div className="payment-choose-left">
                      <div className="payment-choose-checkbox">
                        <div className="payment-choose-point"></div>
                      </div>
                    </div>
                    <div className="payment-choose-right">
                      <img src={paymentInfoCorporateData?.data?.icon} alt="icon" />
                      {paymentInfoCorporateData?.data ?
                        Object.entries(paymentInfoCorporateData?.data?.details).map(
                          (item) => (
                            <p className="payment-choose-text">
                              {item[0]}: <span>{item[1]}</span>
                            </p>
                          )
                        ) : null}
                      {
                        paymentInfoCorporateData?.data?.payment_method_status === 'requires_action' &&
                        <>
                          <p className="payment-choose-text processing">
                            Status: <span>Processing</span>
                          </p>
                          <p className="payment-choose-text link">
                            Verification link: <span><a href={paymentInfoCorporateData?.data?.payment_method_link} target="_blank">Open link</a></span>
                          </p>
                        </>
                      }
                    </div>
                  </div>
                  <div className={`payment-choose-elem ${chosenMethod === 2 ? 'active' : ''}`} onClick={() => setChosenMethod(2)}>
                    <div className="payment-choose-left">
                      <div className="payment-choose-checkbox">
                        <div className="payment-choose-point"></div>
                      </div>
                    </div>
                    <div className="payment-choose-right">
                      <p className="payment-choose-text"><span>Choose another payment method</span></p>
                    </div>

                  </div>
                </div>
                :
                <div className="payment-choose">
                  <p className="payment-choose-warning">You should add payment method to continue</p>
                </div>
              :
            paymentInfoData?.data ?
              <div className="payment-choose">
                <div className={`payment-choose-elem ${chosenMethod === 1 ? 'active' : ''}`}
                     onClick={() => {
                       if (paymentInfoData?.data?.payment_method_status === 'requires_action'){
                         return
                       } else {
                         setChosenMethod(1)
                       }
                     }}
                >
                  <div className="payment-choose-left">
                    <div className="payment-choose-checkbox">
                      <div className="payment-choose-point"></div>
                    </div>
                  </div>
                  <div className="payment-choose-right">
                    <img src={paymentInfoData?.data?.icon} alt="icon" />
                    {paymentInfoData?.data ?
                      Object.entries(paymentInfoData?.data?.details).map(
                        (item) => (
                          <p className="payment-choose-text">
                            {item[0]}: <span>{item[1]}</span>
                          </p>
                        )
                      ) : null}
                    {
                      paymentInfoData?.data?.payment_method_status === 'requires_action' &&
                        <>
                          <p className="payment-choose-text processing">
                            Status: <span>Processing</span>
                          </p>
                          <p className="payment-choose-text link">
                            Verification link: <span><a href={paymentInfoData?.data?.payment_method_link} target="_blank">Open link</a></span>
                          </p>
                        </>
                    }
                  </div>
                </div>
                <div className={`payment-choose-elem ${chosenMethod === 2 ? 'active' : ''}`} onClick={() => setChosenMethod(2)}>
                  <div className="payment-choose-left">
                    <div className="payment-choose-checkbox">
                      <div className="payment-choose-point"></div>
                    </div>
                  </div>
                  <div className="payment-choose-right">
                    <p className="payment-choose-text"><span>Choose another payment method</span></p>
                  </div>

                </div>
              </div>
              :
              <div className="payment-choose">
                <p className="payment-choose-warning">You should add payment method to continue</p>
              </div>
          }

        </div>
        <div className="modal__content-bottom">
          <button className="save full" onClick={handleSubmit}>
            {
              chosenMethod === 1 ?
                "Confirm" :
                chosenMethod === 2 ?
                  "Continue" :
                  chosenMethod === 3 ?
                    "Add payment method" :
                      ''
            }
          </button>
        </div>
      </div>
      <div className="modal__bg">
      </div>
    </div>

  );
};

export default PaymentModal;
import React, {useEffect, useState} from 'react';
import Input from "../../Input";
import {useInviteFboMutation} from "../../../store/builder/inspections.api";
import {validationEmail} from "../../../global/validate";
import {toast} from "react-toastify";
import {setLoader} from "../../../store/builder/reducer";
import {useDispatch} from "react-redux";

const CorporateInviteModal = ({exit}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')

  const [invite,
    {
      data: inviteData,
      isLoading: inviteIsLoading,
      isError: inviteIsError,
      error: inviteError,
      isSuccess: inviteIsSuccess,
    }] = useInviteFboMutation();

  useEffect(() => {
    if (inviteIsLoading) {
      dispatch(setLoader(true));
    }
    if (inviteIsSuccess) {
      dispatch(setLoader(false));
      toast.success('Invite successfully sent!');
      exit();
    }
    if (inviteIsError) {
      dispatch(setLoader(false));
      toast.error(inviteError?.data?.email[0]);
    }
  }, [inviteIsLoading, inviteIsSuccess, inviteIsError]);

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleSubmitInvite = () => {
    if (validationEmail(email)) {
      invite({email: email, role_id: 8})
    } else {
      toast.error('Please enter correct email')
    }
  }


  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{"Invite Corporate"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body`}>
          <Input
            containerWidth={'100%'}
            id="email"
            name="email"
            placeholder="Enter email"
            label={"Email"}
            type={"text"}
            onChange={handleEmail}
          />
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Cancel
          </button>
          <button className="save" onClick={handleSubmitInvite}>
            Invite
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default CorporateInviteModal;
import React, {useEffect, useState} from "react";
import {useUpdateCorporatePricingMutation} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";

export default function EditCorporatePricingModal ({exit, currentCorporateAccount, getCorporateListFunc}) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    subscription_monthly: null,
    subscription_yearly: null,
  })

  const [updatePricing, {
    data: updatePricingData,
    error: updatePricingError,
    isLoading: updatePricingIsLoading,
    isSuccess: updatePricingIsSuccess,
    isError: updatePricingIsError,
  }] = useUpdateCorporatePricingMutation();

  useEffect(() => {
    setState(old =>
      ({...old,
          subscription_monthly: currentCorporateAccount?.subscription_monthly,
          subscription_yearly: currentCorporateAccount?.subscription_yearly}
      ))
  }, [currentCorporateAccount])

  useEffect(() => {
    if (updatePricingIsLoading) {
      dispatch(setLoader(true))
    }
    if (updatePricingIsSuccess) {
      dispatch(setLoader(false))
      toast.success('Price successfully updated')
      getCorporateListFunc()
      exit()
    }
    if (updatePricingIsError) {
      dispatch(setLoader(false))
      toast.error('Something went wrong')
    }
  }, [updatePricingIsLoading, updatePricingIsSuccess, updatePricingIsError])


  const handleChange = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }

  const submit = (e) => {
    e.preventDefault();
    if (state?.subscription_monthly?.length > 0 && state?.subscription_yearly?.length > 0) {
      updatePricing({body: {subscription_monthly: state.subscription_monthly, subscription_yearly: state.subscription_yearly}, id: currentCorporateAccount?.id})
    } else {
      toast.error('Fill all the fields!')
    }
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Edit Pricing</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="modal__content-body">
          <div className="modal__content-inner corporate-pricing">
            <div className="auth__field">
              <label htmlFor="subscription_monthly" className="auth__label">
                Corporate account Monthly
              </label>
              <input
                id="subscription_monthly"
                name="subscription_monthly"
                placeholder="Corporate account Monthly"
                type="number"
                className="auth__input"
                style={{width: '94%'}}
                onChange={handleChange}
                defaultValue={currentCorporateAccount ? currentCorporateAccount?.subscription_monthly : ""}
              />
            </div>
            <div className="auth__field">
              <label htmlFor="custom_form_builder_monthly" className="auth__label">
                Corporate account Yearly
              </label>
              <input
                id="subscription_yearly"
                name="subscription_yearly"
                placeholder="Corporate account Yearly"
                type="number"
                className="auth__input"
                style={{width: '94%'}}
                onChange={handleChange}
                defaultValue={currentCorporateAccount ? currentCorporateAccount?.subscription_yearly : ""}
              />
            </div>
          </div>
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>Cancel</button>
          <button className="save" onClick={submit}>Save</button>
        </div>
      </div>
      <div className="modal__bg">
      </div>
    </div>
  )
}
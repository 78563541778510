import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function FacilitySelect({
  currentFacility,
  setCurrentFacility,
  facilityData,
  setCurrentStations,
  setStationsOptions,
  setTerminalsOptions,
}) {
  const [facilityOptions, setFacilityOptions] = useState([]);

  useEffect(() => {
    if (facilityData) {
      let options = [];
      facilityData?.forEach((item) => {
        options = [
          ...options,
          { label: item?.name, value: item?.id, stations: item?.stations },
        ];
      });
      setFacilityOptions(options);
    }
  }, [facilityData]);

  useEffect(() => {
    if (currentFacility?.stations) {
      setCurrentStations(null);
      let options = [];
      currentFacility?.stations?.forEach((item) => {
        options = [
          ...options,
          { label: item?.name, value: item?.id, terminals: item?.terminals },
        ];
      });
      setStationsOptions(options);
      setTerminalsOptions([]);
    }
  }, [currentFacility]);

  return (
    <label htmlFor="">
      Facility
      <Select
        options={facilityOptions}
        onChange={(selected) => {
          setCurrentFacility(selected);
        }}
        value={currentFacility}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
            borderRadius: 8,
            width: 534,
            height: 42,
            backgroundColor: "#F4F5F6",
          }),
        }}
      />
    </label>
  );
}

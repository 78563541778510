import React from 'react';

const History = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path d="M28 16H30C30.5304 16 31.0391 16.2107 31.4142 16.5858C31.7893 16.9609 32 17.4696 32 18V32C32 32.5304 31.7893 33.0391 31.4142 33.4142C31.0391 33.7893 30.5304 34 30 34H18C17.4696 34 16.9609 33.7893 16.5858 33.4142C16.2107 33.0391 16 32.5304 16 32V18C16 17.4696 16.2107 16.9609 16.5858 16.5858C16.9609 16.2107 17.4696 16 18 16H20" stroke={active ? "#464646" : "#FFFFFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27 14H21C20.4477 14 20 14.4477 20 15V17C20 17.5523 20.4477 18 21 18H27C27.5523 18 28 17.5523 28 17V15C28 14.4477 27.5523 14 27 14Z" stroke={active ? "#464646" : "#FFFFFF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="19" y="23" width="9" height="2" rx="1" fill={active ? "#464646" : "#FFFFFF"}/>
      <rect x="19" y="27" width="6" height="2" rx="1" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>
  );
};

export default History;
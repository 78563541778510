import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import './index.scss';
import CorporateDashboardCard from "../../components/Corporate/CorporateDashboardCard";
import TotalPieChart from "../../components/Charts/TotalPieChart";
import CorporatePersonalStats from "../../components/Corporate/CorporatePersonalStats";
import {
  useLazyDashboardCorporateEquipmentQuery,
  useLazyDashboardCorporateFBOQuery, useLazyDashboardCorporatePersonnelQuery
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import CorporateUsersModal from "../../components/Corporate/CorporateUsersModal";

const CorporateDashboard = () => {
  const dispatch = useDispatch();
  const [dashboardFilterStatus, setDashboardFilterStatus] = useState(false)
  const [chosenFilter, setChosenFilter] = useState('worst')
  const [usersModal, setUsersModal] = useState(false)
  const [chosenUsers, setChosenUsers] = useState(null)

  const [getCorporateDashboard, {
    data: corporateDashboardData,
    error: corporateDashboardError,
    isFetching: corporateDashboardIsLoading,
    isSuccess: corporateDashboardIsSuccess,
    isError: corporateDashboardIsError,
  }] = useLazyDashboardCorporateFBOQuery();

  const [getCorporateDashboardEquipment, {
    data: corporateDashboardEquipmentData,
    error: corporateDashboardEquipmentError,
    isLoading: corporateDashboardEquipmentIsLoading,
    isSuccess: corporateDashboardEquipmentIsSuccess,
    isError: corporateDashboardEquipmentIsError,
  }] = useLazyDashboardCorporateEquipmentQuery();

  const [getCorporateDashboardPersonnel, {
    data: corporateDashboardPersonnelData,
    error: corporateDashboardPersonnelError,
    isLoading: corporateDashboardPersonnelIsLoading,
    isSuccess: corporateDashboardPersonnelIsSuccess,
    isError: corporateDashboardPersonnelIsError,
  }] = useLazyDashboardCorporatePersonnelQuery();

  useEffect(() => {
    dispatch(setLoader(true))
    Promise.all([
      getCorporateDashboard('worst_to_best'),
      getCorporateDashboardEquipment(),
      getCorporateDashboardPersonnel()
    ])
      .then(res => {
        dispatch(setLoader(false))
      })
      .catch(err => {
        dispatch(setLoader(false))
      });
  }, []);

  useEffect(() => {
    if (corporateDashboardIsLoading) {
      dispatch(setLoader(true))
    }
    if (corporateDashboardIsSuccess) {
      dispatch(setLoader(false))
    }
  }, [corporateDashboardIsLoading, corporateDashboardIsSuccess])

  useEffect(() => {
    if (chosenFilter === "worst") {
      getCorporateDashboard('worst_to_best')
    } else {
      getCorporateDashboard('best_to_worst')
    }
  }, [chosenFilter])

  return (
    <Wrapper>
      <Helmet>
        <title>Corporate Dashboard | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"Corporate Dashboard"}
        type="corporate-dashboard"
        // action={() => setCreateModal(true)}
        // secondAction={() => setInviteModal(true)}
      />
      {
        usersModal &&
        <CorporateUsersModal
          exit={() => {
            setUsersModal(false)
            setChosenUsers(null)
          }}
          chosenUsers={chosenUsers}
        />
      }
      <div className="corporate-dashboard">
        <div className="corporate-dashboard__block">
          {
            corporateDashboardEquipmentData ?
              <TotalPieChart data={{data: corporateDashboardEquipmentData?.chart, nfc_enabled: corporateDashboardEquipmentData?.nfc_activated_percentage}} />
              : <div className="corporate__skeleton"></div>
          }
          {
            corporateDashboardPersonnelData ?
              <CorporatePersonalStats data={corporateDashboardPersonnelData} setUsersModal={setUsersModal} setChosenUsers={setChosenUsers} />
              : <div className="corporate__skeleton"></div>
          }
        </div>
        <div
          className={`corporate-dashboard__filter ${dashboardFilterStatus ? "active" : ""}`}
          onClick={() => setDashboardFilterStatus(!dashboardFilterStatus)}
        >
          <p>{chosenFilter === "worst" ? "From worst to best" : "From best to worst"}</p>
          <img src="/assets/icons/chevron-down.svg" alt="arrow"/>
          {
            dashboardFilterStatus &&
            <div className="corporate-dashboard__dropdown">
              <div className={`corporate-dashboard__dropdown-elem ${chosenFilter === 'worst' ? 'active' : ''}`}
                    onClick={() => setChosenFilter('worst')}
              >
                <p>From worst to best</p>
                {chosenFilter === 'worst' ? <img src="/assets/icons/corporate-checkmark.svg" alt="check"/> : null}
              </div>
              <div className={`corporate-dashboard__dropdown-elem ${chosenFilter !== 'worst' ? 'active' : ''}`}
                    onClick={() => setChosenFilter('best')}
              >
                <p>From best to worst</p>
                {chosenFilter !== 'worst' ? <img src="/assets/icons/corporate-checkmark.svg" alt="check"/> : null}
              </div>
            </div>
          }
        </div>
        <div className="corporate-dashboard__block cards">
          {
            corporateDashboardData?.length > 0 ?
              corporateDashboardData?.map((item, index) => (
                <CorporateDashboardCard
                  item={item}
                  key={index}
                  data={corporateDashboardData}
                />
              ))
              :
              <div style={{width: '100%', height: '303px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                  <div className="expired__box-img" style={{margin: '0'}}>
                    <img src="/assets/no-data.svg" alt="no-data"/>
                  </div>
                  <p className="no-payment-text">
                    There is no FBO's!
                  </p>
                </div>
              </div>
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default CorporateDashboard;
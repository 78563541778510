import React, {useEffect, useState} from 'react';
import CustomSelect from "../CustomSelect";
import {useUpdateServiceRequestMutation} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useErrorHandler} from '../../global/ErrorsHandler';
import moment from "moment/moment";
import FullPhotoModal from "../Observation/FullPhotoModal";
import Input from "../Input";

export default function ServiceRow({request, userOptions}) {
  const dispatch = useDispatch()
  const [modalStatus, setModalStatus] = useState(false)
  const [statuses, setStatuses] = useState([
    {label: 'new', value: 'new'},
    {label: 'pending', value: 'pending'},
    {label: 'closed', value: 'closed'},
  ])

  const [currentStatus, setCurrentStatus] = useState({
    status: request.status
  })
  const [chosenUser, setChosenUser] = useState(request?.assignee_id)
  const [additionalContext, setAdditionalContext] = useState(request?.additional_context)
  const [fullPhoto, setFullPhoto] = useState(false)
  const [updateService,
    {
      data: updateServiceData,
      isSuccess: updateServiceIsSuccess,
      isLoading: updateServiceIsLoading,
      error: updateServiceError,
      isError: updateServiceIsError,
    }
  ] = useUpdateServiceRequestMutation()

  const [errorHandler, setErrorHandler] = useErrorHandler([updateServiceError])

  useEffect(() => {
    if (modalStatus) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalStatus])

  useEffect(() => {
    if (updateServiceIsLoading) {
      dispatch(setLoader(true))
    }
    if (updateServiceIsSuccess) {
      dispatch(setLoader(false))
      toast.success(updateServiceData.message)
      setModalStatus(false)
    }
    if (updateServiceIsError) {
      dispatch(setLoader(false))
      toast.success(updateServiceData.message)
      setModalStatus(false)
    }
  }, [updateServiceIsSuccess, updateServiceIsLoading, updateServiceIsError])

  const handleRequestChange = (e) => {
    setCurrentStatus(old => ({...old, status: e.target.value}))
  }

  const updateRequest = () => {
    if (currentStatus.status === request.status) {
      toast.error('Status must be updated!')
    } else {
      updateService({id: request.id, data: {
          status: currentStatus?.status,
          assignee_id: chosenUser,
          additional_context: additionalContext,
        }})
    }
  }

  const renderModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Observation</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setModalStatus(false)}
            />
          </div>
          <div className="modal__content-body">
            <span className="service__input-label">Comment</span>
            <div className="service__input-text">
              {request.comment}
            </div>
            <span className="service__input-label">File</span>
            <div className="service__input-file" onClick={() => {
              setFullPhoto(true);
            }}>
              <img src={request.file_path ? request.file_path : '/assets/icons/default-service-big.png'} alt="img"/>
            </div>
            <CustomSelect
              label={"Status"}
              options={statuses}
              name={"status"}
              onChange={handleRequestChange}
              defaultValue={{value: request.status, label: request.status}}
            />

            <CustomSelect
              label={"Assign to"}
              options={userOptions}
              name={"assignee_id"}
              onChange={(e) => {
                setChosenUser(e.target.value)
              }}
              defaultValue={userOptions?.filter(el => el?.value === request?.assignee_id)[0]}
            />
            <div style={{height: 16}}></div>
            <Input
              id="additional_context"
              name="additional_context"
              label="Additional Context"
              containerWidth={"100%"}
              onChange={(e) => setAdditionalContext(e.target.value)}
              defaultValue={request?.additional_context}
            />
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setModalStatus(false)}>
              Close
            </button>
            <button className="save" onClick={updateRequest}>
              Save
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <>
      {modalStatus && renderModal()}
      {fullPhoto && <FullPhotoModal photo={request?.file_path} setModal={setFullPhoto} />}
      <div className="service__table-row" key={request?.id} onClick={() => setModalStatus(true)}>
        <div
          className="service__table-bodyTh">{request.created_date ? moment(request.created_date).format("MM/DD/YYYY") : '---'}</div>
        <div className="service__table-bodyTh"><span>{request.comment}</span></div>
        <div className="service__table-bodyTh">
          <span
            className={`service__status ${request.status}`}>{request.status.charAt(0).toUpperCase() + request.status.slice(1)}</span>
        </div>
        <div className="service__table-bodyTh">
          <img src={request.file_path ? request.file_path : '/assets/icons/default-service.png'} alt="img"/>
        </div>
      </div>
    </>
  );
};


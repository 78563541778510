import React, {useEffect, useState} from 'react';
import AgancyIcon from "../../SVG/AgancyIcon";
import {NavLink, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import CorporateIcon from "../../SVG/CorporateIcon";

const SideFBODropdown = () => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState(false);
  const fboDropDown = localStorage.getItem('navbarDropDownCorporate');
  const sidebarStatus = useSelector(state => state?.rootReducer?.inspectionSlice?.sidebar)

  useEffect(() => {
    if (!sidebarStatus) {
      setDropdown(false)
      localStorage.setItem('navbarDropDownCorporate', "false")
    }
  }, [sidebarStatus]);

  return (
    <div className={`sidebar__link initial column`}>
      <div
        onClick={() => {
          if (sidebarStatus) {
            setDropdown(!dropdown)
            localStorage.setItem('navbarDropDownCorporate', fboDropDown === 'false' ? 'true' : 'false')}}
        }
        className={`sidebar__link-inner column ${location.pathname === '/corporate/active' || location.pathname === '/corporate/inactive' ? 'active' : ''}`}>
        <CorporateIcon color={location.pathname === '/corporate/active' || location.pathname === '/corporate/inactive' ? '#FFFFFF' : '#AEB3B7' }/>
        <p className="sidebar__link-name">Corporate</p>
      </div>
      {
        fboDropDown === 'true' &&
        <div className="sidebar__dropdown">
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/corporate/active' ? 'active' : ''}`}
                   to="/corporate/active">Active</NavLink>
          <NavLink className={`sidebar__dropdown-elem ${location.pathname === '/corporate/inactive' ? 'active' : ''}`}
                   to="/corporate/inactive">Inactive</NavLink>
          <img className="sidebar__icon one" src="/assets/icons/dropdown-short.svg" alt="icon"/>
          <img className="sidebar__icon two" src="/assets/icons/dropdown-long.svg" alt="icon"/>
        </div>
      }
    </div>
  );
};

export default SideFBODropdown;
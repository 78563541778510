import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import {
  useBuyPhonesMutation,
  useGetPricesQuery,
} from "../../store/builder/inspections.api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import BillingAddressModal from "./BillingAddressModal";
import ShippingAddressModal from "./ShippingAddressModal";
import axios from "axios";
import {setUser} from "../../store/builder/loginSlice";

const ExtraPhonesModal = ({
  setShowExtra,
  setShowBillingModal,
  setShowPaymentModal,
                            setShowShippingModal,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("ndx_user_token");
  const [modal, setModal] = useState(false);
  const [modalShipping, setModalShipping] = useState(false);
  const [secret, setSecret] = useState(null);
  const [address, setAddress] = useState(null);
  const [phoneCount, setPhoneCount] = useState(1);
  const [license, setLicense] = useState(null);
  const [leasing, setLeasing] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [buyPhones, { data, isSuccess, isLoading, isError, error }] =
    useBuyPhonesMutation();
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const {
    data: pricesData,
    isLoading: pricesIsLoading,
    isError: pricesIsError,
    error: pricesError,
    isSuccess: pricesIsSuccess,
  } = useGetPricesQuery();

  useEffect(() => {
    setTotalPrice(() => {
      let licensePrice;
      let leasingPrice;
      if (license === "license_yes") {
        licensePrice =
          (userInfo?.current_subscription?.period !== "yearly"
            ? pricesData?.data?.license?.monthly
            : pricesData?.data?.license?.yearly) * phoneCount;
      } else {
        licensePrice = 0;
      }
      if (leasing === "yes") {
        leasingPrice = pricesData?.data?.device?.leasing * 12 * phoneCount;
      } else if (leasing === "no") {
        leasingPrice = pricesData?.data?.device?.purchase * phoneCount;
      } else {
        leasingPrice = 0;
      }

      return (licensePrice + leasingPrice).toFixed(2);
    });
  }, [license, leasing, phoneCount]);

  useEffect(() => {
    if (isLoading || pricesIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [isLoading, pricesIsLoading]);

  const counter = (type) => {
    if (type === "plus") {
      setPhoneCount(phoneCount + 1);
    } else {
      setPhoneCount(phoneCount - 1);
    }
  };

  const chooseLicense = (e) => {
    setLicense(e.target.id);
  };

  const chooseLeasing = (e) => {
    setLeasing(e.target.id);
  };

  const getUserIndex = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}user`, config).then((res) => {
      dispatch(setUser(res.data));
    });
    // .catch((err) => {
    //   dispatch(setLoader(false));
    //   dispatch(setUser(null));
    //   navigate("/login");
    //   toast.error(err.response.data.message);
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (license && leasing) {
      buyPhones({
        device_count: phoneCount,
        license_count: license === "license_yes" ? phoneCount : 0,
        device_leasing: leasing === "yes" ? 1 : 0,
      })
        .then((res) => {
          if (res.error) {
            toast.error(res.error.data.message);
          } else {
            toast.success(res.data.message);
            setSecret(res.data?.data?.client_secret);
            setAddress(res?.data?.data?.addresses);
            setShowExtra(false);
            setShowBillingModal(false);
            setShowPaymentModal(false);
          }
        })
        .catch((err) => {
          // toast.error(err.response.message)
        });
    } else {
      toast.error("Choose all parameters!");
    }
  };

  const phones = [];
  for (let i = 0; i < phoneCount; i++) {
    phones.push(
      <img
        key={i}
        src="/assets/icons/pixel7.png"
        className="subscription__phones-elem bounceOut"
        alt="phone"
      />
    );
  }
  return (
    <div className="modal">
      {modal && <BillingAddressModal setShowPaymentModal={setModal} getUserIndex={getUserIndex} />}
      {modalShipping && <ShippingAddressModal setShowPaymentModal={setModalShipping} getUserIndex={getUserIndex} />}
      <div className="modal__content extra">
        <div className="modal__content-head">
          <p>Buy Extra phones</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => setShowExtra(false)}
          />
        </div>
        <div className="modal__content-body">
          <p className="subscription__extra-title">
            <span>Select how many phones:</span> A software license is required
            for each phone.
          </p>
          <div className="subscription__counts extra">
            <div className="subscription__counter extra">
              <button
                disabled={phoneCount === 1}
                onClick={() => counter("minus")}
                className="subscription__counter-minus box"
              >
                -
              </button>
              <div className="subscription__counter-num box">{phoneCount}</div>
              <button
                disabled={phoneCount === 10}
                onClick={() => counter("plus")}
                className="subscription__counter-plus box"
              >
                +
              </button>
            </div>
            <div className="subscription__phones extra">{phones}</div>
          </div>
          <div className="subscription__option">
            <p className="subscription__option-title extra">
              Data and Device Management:
            </p>
            <div style={{ display: "flex" }}>
              <label className="container" htmlFor="license_yes">
                <input
                  type="radio"
                  name="license_count"
                  onChange={chooseLicense}
                  id="license_yes"
                />
                With license (for $
                {userInfo?.current_subscription?.period !== "yearly"
                  ? pricesData?.data?.license?.monthly
                  : pricesData?.data?.license?.yearly}
                /per {userInfo?.current_subscription?.period})
                <span className="checkmark"></span>
              </label>
              <label className="container" htmlFor="license_no">
                <input
                  type="radio"
                  name="license_count"
                  onChange={chooseLicense}
                  id="license_no"
                />
                Without license
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="subscription__option">
            <p className="subscription__option-title extra">
              Payment options:
            </p>
            <div style={{ display: "flex" }}>
              <label className="container" htmlFor="no">
                <input
                  type="radio"
                  name="device_leasing"
                  onChange={chooseLeasing}
                  id="no"
                />
                Buy each Ground Sentinel for $
                {pricesData?.data?.device?.purchase}.
                <span className="checkmark"></span>
              </label>
              <label className="container" htmlFor="yes">
                <input
                  type="radio"
                  name="device_leasing"
                  onChange={chooseLeasing}
                  id="yes"
                />
                Lease ${(Math.round((pricesData?.data?.device?.leasing * 12) * 100) / 100).toFixed(2)} for 12 months.

                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <p className="subscription__updates row">
            Your current payment method is:
            {userInfo?.payment_data?.details ?
              userInfo?.payment_data?.details["Last 4"] : "---"}.{" "}
            <span className="blue"
              onClick={() => {
                setShowPaymentModal(true);
              }}
            >
                Update payment
              </span>
          </p>
          <div className="subscription__information">
            <p className="subscription__updates">
              <b>Your shipping address is:</b>
              {userInfo?.address?.line1 ? <p>{userInfo?.address?.line1}</p> : null}

              {userInfo?.address?.line2 ? <p>{userInfo?.address?.line2}</p> : null}
              <p>
                {
                  (!userInfo?.address?.city && !userInfo?.address?.state && !userInfo?.address?.country) ?
                    null :
                    `${userInfo?.address?.city ? userInfo?.address?.city : "---"}/
                    ${userInfo?.address?.state ? userInfo?.address?.state : "---"}/
                    ${userInfo?.address?.country ? userInfo?.address?.country : "---"}`
                }
              </p>
              {userInfo?.address?.postal_code && <p>{userInfo?.address?.postal_code}</p>}
              <p className="blue" onClick={() => {
                setModalShipping(true);
              }}>
                Update shipping address
              </p>
            </p>
            <p className="subscription__updates">
              <b>Your billing address is:</b>
              {userInfo?.billing_address?.line1 &&
                <p>{userInfo?.billing_address?.line1}</p>
              }
              {userInfo?.billing_address?.line2 ? <p>{userInfo?.billing_address?.line2}</p> : null}
              <p>{
                (!userInfo?.billing_address?.city && !userInfo?.billing_address?.state && !userInfo?.billing_address?.country) ?
                  null :
                  `${userInfo?.billing_address?.city ? userInfo?.billing_address?.city : "---"}/
                    ${userInfo?.billing_address?.state ? userInfo?.billing_address?.state : "---"}/
                    ${userInfo?.billing_address?.country ? userInfo?.billing_address?.country : "---"}`
              }</p>
              {
                userInfo?.billing_address?.postal_code &&
                <p>{userInfo?.billing_address?.postal_code}</p>
              }
              <p className="blue" onClick={() => {
                setShowBillingModal(true);
              }}>
                Update billing address
              </p>
            </p>
          </div>
        </div>
        <div className="modal__content-bottom">
          <button
            className={`save full ${totalPrice === 0 ? "disabled" : ""}`}
            onClick={handleSubmit}
            disabled={totalPrice === 0}
          >
            Pay ${totalPrice !== 0 ? totalPrice : ""}
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default ExtraPhonesModal;

import React, {useEffect, useState} from 'react'
import InspectionDropDown from "../InspectionDropDown";
import Input from "../Input";
import {addChildField, changeParentField, addParentField, removeField} from "../../store/builder/inspectionSlice";
import {useDispatch} from "react-redux";
import Plus from '../../SVG/Plus';
import Trash from '../../SVG/Trash'
import Option from "../../UI/Option";


export default function InspectionField({
                                          item,
                                          field,
                                          elem,
                                          id,
                                          parent,
                                          durationsOptions,
                                          setDurationsOptions,
                                          idx,
                                          allFields
                                        }) {
  const dispatch = useDispatch()
  const [type, setType] = useState(null)
  const [acceptableType, setAcceptableType] = useState(null)
  const [state, setState] = useState(elem)
  const [buttonsStatus, setButtonsStatus] = useState(false)
  const [acceptable, setAcceptable] = useState([{value: 'range', label: 'Range'}, {value: 'list', label: 'List'}])

  const [checkboxOptions, setCheckboxOptions] = useState([
    {id: 1, value: null, label: null, has_comment: false, show_comment: false},
    {id: 2, value: null, label: null, has_comment: false, show_comment: false}
  ])
  const [radioOptions, setRadioOptions] = useState([
    {id: 1, value: null, label: null, has_comment: false, show_comment: false},
    {id: 2, value: null, label: null, has_comment: false, show_comment: false}
  ])

  const [selectOptions, setSelectOptions] = useState([
    {id: 1, value: null, label: null, has_comment: false, show_comment: false},
    {id: 2, value: null, label: null, has_comment: false, show_comment: false}
  ])

  useEffect(() => {
    setState(old =>
      ({
        ...old,
        acceptable: {
          ...old.acceptable, type: old?.acceptable?.type ? old?.acceptable?.type : null,
          value: old?.acceptable?.value ? old?.acceptable?.value : null
        }
      }))
  }, [])

  useEffect(() => {
    dispatch(changeParentField({field_data: state, index: idx}))
  }, [state])

  useEffect(() => {
    setState({
      ...elem,
      options: type === 'checkbox' ? checkboxOptions : type === 'radio' ? radioOptions : type === 'select' ? selectOptions : [],
    })
  }, [checkboxOptions, radioOptions, selectOptions])
  useEffect(() => {
    setType(elem?.type ? elem?.type : null)
    setAcceptableType(elem?.acceptable?.type ? elem?.acceptable?.type : null)
    if (state === {}) {
      setState({id: field.id, label: null, acceptable: {type: null, value: null}})
    } else {
      setState(field)
    }
    if (field.type === 'radio') {
      setRadioOptions(field.options)
    }
    if (field.type === 'checkbox') {
      setCheckboxOptions(field.options)
    }
    if (field.type === 'select') {
      setSelectOptions(field.options)
    }

  }, [field])

  const addOption = () => {
    if (type?.toLowerCase() === "checkbox") {
      setCheckboxOptions(old => ([...old, {id: old.length + 1, value: null, label: null, has_comment: false}]))
    } else if (type?.toLowerCase() === "radio") {
      setRadioOptions(old => ([...old, {id: old.length + 1, value: null, label: null, has_comment: false}]))
    } else if (type?.toLowerCase() === "select") {
      setSelectOptions(old => ([...old, {id: old.length + 1, value: null, label: null, has_comment: false}]))
    }
  }
  const addField = (e) => {
    setType(e)
    switch (e) {
      case 'text':
        setState({...elem, title: null, placeholder: null, type: 'text', options: []})
        break;
      case 'textarea':
        setState({...elem, title: null, placeholder: null, type: 'textarea', options: []})
        break;
      case 'checkbox':
        setState({...elem, type: 'checkbox', options: checkboxOptions})
        break;
      case 'radio':
        setState({...elem, type: 'radio', options: radioOptions})
        break;
      case 'select':
        setState({...elem, type: 'select', options: selectOptions})
        break;
      case 'numeric':
        setState({...elem, type: 'numeric', options: []})
        break;
      case 'condition_code':
        setState({...elem, type: 'condition_code', options: []})
        break;
      case 'sump_sample':
        setState({...elem, type: 'sump_sample', options: []})
        break;
      case 'sump_sample_optional':
        setState({...elem, type: 'sump_sample_optional', options: []})
        break;
      case 'tank_condition':
        setState({...elem, type: 'cleaned_status', options: []})
        break;
      case 'microbial_test':
        setState({...elem, type: 'microbial_test', options: []})
        break;
    }
  }

  const addAcceptableType = (e) => {
    setAcceptableType(e)
    switch (e) {
      case 'range':
        setState({...elem, acceptable: {...elem.acceptable, type: 'range'}})
        break;
      case 'list':
        setState({...elem, acceptable: {...elem.acceptable, type: 'list'}})
        break;
    }
  }

  const removeInput = (type, id) => {
    if (type === 'checkbox') {
      setCheckboxOptions(old => old.filter((item) => item.id !== id))
    } else if (type === 'radio') {
      setRadioOptions(old => old.filter((item) => item.id !== id))
    } else if (type === 'select') {
      setSelectOptions(old => old.filter((item) => item.id !== id))
    }
  }

  const handleFieldName = (e) => {
    setState(old => ({...old, label: e.target.value}))
  }
  const handleTextFieldChange = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }
  const handleAcceptable = (e) => {
    setState(old => ({...old, acceptable: {...old.acceptable, value: e.target.value}}))
  }

  return (
    <div className="builder__field">
      <div className="builder__field-head">
        <div className="builder__field-addForm" onClick={() => {
          dispatch(addParentField({item, new_form: true}))
        }}>
          <Plus color={'#4268B0'}/>
          <span>Add Form</span>
        </div>
      </div>
      <div className={`builder__field-main a1`} id={id}>
        <div className={`builder__field-addChildBox ${allFields.length === 1 ? 'disabled' : ''}`}
             onClick={() => dispatch(removeField(idx))}>
          <Trash color={'#DA6767'}/>
        </div>
        <Input placeholder="Enter title" onChange={handleFieldName} required={true} defaultValue={state?.label}/>
        <p className="builder__field-acceptable">Acceptable</p>
        <InspectionDropDown arr={acceptable} acceptance={true} chosenType={acceptableType}
                            setType={(e) => addAcceptableType(e)}/>
        <Input placeholder="Value" name="acceptable_value" id="acceptable_value" onChange={handleAcceptable}
               required={false} defaultValue={state?.acceptable?.value} label={state?.acceptable?.type === 'range' ? "Example: 0|10" : ""} />
        <InspectionDropDown arr={durationsOptions} chosenType={type} acceptance={false} setType={(e) => addField(e)}/>
        {
          type?.toLowerCase() === "text" ?
            <div className="builder__field-items">
              <Input type="text" id="title" name="title" placeholder="Title" onChange={handleTextFieldChange}
                     defaultValue={state?.title}/>
              <Input type="text" id="placeholder" name="placeholder" placeholder="Placeholder"
                     onChange={handleTextFieldChange} defaultValue={state?.placeholder}/>
            </div>
            : type?.toLowerCase() === "textarea" ?
              <div className="builder__field-items">
                <Input type="text" id="title" name="title" placeholder="Title" onChange={handleTextFieldChange}
                       defaultValue={state?.title}/>
                <Input type="text" id="placeholder" name="placeholder" placeholder="Placeholder"
                       onChange={handleTextFieldChange} defaultValue={state?.placeholder}/>
              </div>
              : type?.toLowerCase() === "checkbox" ?
                <div>
                  {
                    checkboxOptions?.map((option, index) => (
                      <Option key={option.id} id={option.id} img={'checkbox-uncheked.svg'} option={option}
                              options={checkboxOptions}
                              setOption={setCheckboxOptions} removeInput={removeInput} type={"checkbox"}/>
                    ))
                  }
                </div>
                : type?.toLowerCase() === "radio" ?
                  <div>
                    {
                      radioOptions?.map((option, index) => (
                        <Option key={option.id} id={option.id} img={'radion-unchecked.svg'} option={option}
                                options={radioOptions} setOption={setRadioOptions} removeInput={removeInput}
                                type={"radio"}/>
                      ))
                    }
                  </div>
                  : type?.toLowerCase() === "select" ?
                    <div>
                      {
                        selectOptions?.map((option, index) => (
                          <Option key={option.id} id={option.id} option={option} options={selectOptions}
                                  setOption={setSelectOptions} removeInput={removeInput} type={"select"}/>
                        ))
                      }
                    </div>
                    : null
        }
        {
          type?.toLowerCase() === 'radio' ?
            <span className="builder__field-addOption" onClick={addOption}>Add option</span>
            :
            type?.toLowerCase() === 'checkbox' ?
              <span className="builder__field-addOption" onClick={addOption}>Add option</span>
              : type?.toLowerCase() === 'select' ?
                <span className="builder__field-addOption" onClick={addOption}>Add option</span>
                : null
        }
      </div>
    </div>


  )
}
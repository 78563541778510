import React from 'react';

const CommentsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M16.5 4.6875V8.51248C16.5 9.46498 16.185 10.2675 15.6225 10.8225C15.0675 11.385 14.265 11.7 13.3125 11.7V13.0575C13.3125 13.5675 12.7425 13.875 12.3225 13.59L11.595 13.11C11.6625 12.8775 11.6925 12.6225 11.6925 12.3525V9.30002C11.6925 7.77002 10.6725 6.75 9.1425 6.75H4.05C3.945 6.75 3.8475 6.75751 3.75 6.76501V4.6875C3.75 2.775 5.025 1.5 6.9375 1.5H13.3125C15.225 1.5 16.5 2.775 16.5 4.6875Z"
        stroke="#57A1EC" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M11.6925 9.30002V12.3525C11.6925 12.6225 11.6625 12.8775 11.595 13.11C11.3175 14.2125 10.4025 14.9025 9.1425 14.9025H7.1025L4.8375 16.41C4.5 16.6425 4.05 16.395 4.05 15.99V14.9025C3.285 14.9025 2.6475 14.6475 2.205 14.205C1.755 13.755 1.5 13.1175 1.5 12.3525V9.30002C1.5 7.87502 2.385 6.89251 3.75 6.76501C3.8475 6.75751 3.945 6.75 4.05 6.75H9.1425C10.6725 6.75 11.6925 7.77002 11.6925 9.30002Z"
        stroke="#57A1EC" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CommentsIcon;
import React from 'react';

const RedGraph = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="51" viewBox="0 0 74 51" fill="none">
      <path d="M68.7878 31.905C70.438 31.905 71.9611 34.2086 73.1035 37.0241V50.8455H0.49865V0.422852C2.91035 0.934757 4.05273 30.8812 8.49535 30.8812C12.0494 30.8812 15.3415 18.9472 16.9998 18.3395C18.658 17.7319 20.173 19.0995 21.3154 18.3395C22.4578 17.5796 23.4733 10.661 25.6311 9.8931C30.2006 8.86929 38.832 34.2086 42.8938 34.2086C46.4479 33.4407 47.0503 24.5011 50.6366 24.2264C53.8099 23.9834 56.3485 43.9348 60.0296 43.9348C62.5682 43.9348 66.1223 31.905 68.7878 31.905Z" fill="url(#paint0_linear_10886_35017)"/>
      <path d="M73.1035 37.0052C71.9611 34.1912 70.438 31.8888 68.7878 31.8888C66.1223 31.8888 62.5682 43.9124 60.0296 43.9124C56.3485 43.9124 53.8099 23.9713 50.6366 24.2142C47.0503 24.4888 46.4479 33.4238 42.8938 34.1912C38.832 34.1912 30.2006 8.86494 25.6311 9.88823C23.4733 10.6557 22.4578 17.5708 21.3154 18.3303C20.173 19.0899 18.658 17.723 16.9998 18.3303C15.3415 18.9376 12.0494 30.8655 8.49534 30.8655C4.05274 30.8655 2.91035 0.934493 0.498653 0.422852" stroke="#DA6767" strokeWidth="0.564433" strokeLinecap="round"/>
      <defs>
        <linearGradient id="paint0_linear_10886_35017" x1="36.8011" y1="-26.7081" x2="36.8011" y2="50.8455" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DA6767" stopOpacity="0.15"/>
          <stop offset="1" stopColor="#DA6767" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RedGraph;
import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import Checkbox from "../Checkbox";
import Select from "react-select";
import Datepicker from "../Datepicker";
import {
  useCreateManualOrderMutation,
  useGetAgencyQuery,
  useLazyGetAgencyQuery
} from "../../store/builder/inspections.api";
import moment from "moment";
import {toast} from "react-toastify";
import {setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import {useErrorHandler} from "../../global/ErrorsHandler";

const OrderCreateModal = ({exit, getOrdersList}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    subscription_ends_at: null,
    subscription_price: 0,
    license_count: 0,
    license_price: 0,
    custom_form_builder: null,
    custom_form_builder_price: 0,
    agency_id: null,
  })
  const [shippingState, setShippingState] = useState({
    city: null,
    line1: null,
    line2: null,
    state: null,
    country: null,
    postal_code: null,
  })
  const [agencyOptions, setAgencyOptions] = useState([])
  const [currentAgency, setCurrentAgency] = useState(null)
  const [getAgencies, {data, isLoading, isError, isSuccess, error}] = useLazyGetAgencyQuery();
  const [createOrder, {data: orderData, isLoading: orderIsLoading, isError: orderIsError, isSuccess: orderIsSuccess, error: orderError}] = useCreateManualOrderMutation();
  const [errorHandler, setErrorHandler] = useErrorHandler([orderError]);

  useEffect(() => {
    getAgencies()
  }, [])

  useEffect(() => {
    if (isSuccess) {
      setAgencyOptions(data.map(item => ({value: item?.id, label: item?.title})))
    }
  }, [isSuccess])

  useEffect(() => {
    if (!currentAgency) {
      setShippingState(old => ({...old,
        city: currentAgency?.shipping_address?.city,
        line1: currentAgency?.shipping_address?.line1,
        line2: currentAgency?.shipping_address?.line2,
        state: currentAgency?.shipping_address?.state,
        country: currentAgency?.shipping_address?.country,
        postal_code: currentAgency?.shipping_address?.postal_code,
      }))
    }
  }, [currentAgency])

  const handleChange = (e) => {
    setState(old => ({...old, [e.target.name]: e.target.value}))
  }
  const handleDate = (e) => {
    setState(old => ({...old, subscription_ends_at: moment(e).format("YYYY-MM-DD")}))
  }
  const handleFormBuilder = (e) => {
    setState(old => ({...old, custom_form_builder: e.target.id}))
  }
  const handleAgency = (e) => {
    setState(old => ({...old, agency_id: e?.value}))
    setCurrentAgency(() => data?.filter(el => el?.id === e.value)[0])
  }

  const handleShipping = (e) => {
    setShippingState(old => ({...old, [e.target.id]: e.target.value}))
  }

  const createOrderSubmit = () => {
    if (state?.license_count > 0) {
      if (state?.custom_form_builder == 1) {
        if (
          state?.subscription_ends_at &&
          state?.agency_id &&
          state?.custom_form_builder?.length &&
          state?.custom_form_builder_price >= 0 &&
          state?.license_count >= 0 &&
          state?.license_price >= 0 &&
          state?.subscription_price >= 0 &&
          shippingState?.line1?.length > 1 &&
          shippingState?.city?.length > 1 &&
          shippingState?.state?.length > 1 &&
          shippingState?.country?.length > 1 &&
          shippingState?.postal_code?.length > 1
        ) {
          dispatch(setLoader(true))
          createOrder({...state, ...shippingState})
            .then(res => {
              toast?.success(res.data.message)
              dispatch(setLoader(false))
              exit();
              getOrdersList();
            })
            .catch(err => {
              dispatch(setLoader(false))
            })
        }
        else {
          toast?.error("Fill all the fields, address line 2 is optional")
        }
      } else {
        if (
          state?.subscription_ends_at &&
          state?.agency_id &&
          state?.custom_form_builder?.length &&
          state?.license_count >= 0 &&
          state?.license_price >= 0 &&
          state?.subscription_price >= 0 &&
          shippingState?.line1?.length > 1 &&
          shippingState?.city?.length > 1 &&
          shippingState?.state?.length > 1 &&
          shippingState?.country?.length > 1 &&
          shippingState?.postal_code?.length > 1
        ) {
          dispatch(setLoader(true))
          createOrder({...state, ...shippingState})
            .then(res => {
              toast?.success(res.data.message)
              dispatch(setLoader(false))
              exit();
              getOrdersList();
            })
            .catch(err => {
              dispatch(setLoader(false))
            })
        }
        else {
          toast?.error("Fill all the fields, address line 2 is optional")
        }
      }
    } else {
      if (state?.custom_form_builder == 1) {
        if (
          state?.subscription_ends_at &&
          state?.agency_id &&
          state?.custom_form_builder?.length &&
          state?.custom_form_builder_price >= 0 &&
          state?.license_count >= 0 &&
          state?.license_price >= 0 &&
          state?.subscription_price >= 0
        ) {
          dispatch(setLoader(true))
          createOrder({...state})
            .then(res => {
              toast?.success(res.data.message)
              dispatch(setLoader(false))
              exit();
              getOrdersList();
            })
            .catch(err => {
              dispatch(setLoader(false))
            })
        }
        else {
          toast?.error("Fill all the fields")
        }
      } else {
        if (
          state?.subscription_ends_at &&
          state?.agency_id &&
          state?.custom_form_builder?.length &&
          state?.license_count >= 0 &&
          state?.license_price >= 0 &&
          state?.subscription_price >= 0
        ) {
          dispatch(setLoader(true))
          createOrder({...state})
            .then(res => {
              toast?.success(res.data.message)
              dispatch(setLoader(false))
              exit();
              getOrdersList();
            })
            .catch(err => {
              dispatch(setLoader(false))
            })
        }
        else {
          toast?.error("Fill all the fields")
        }
      }
    }
  }


  const renderShipping = () => {
    return (
      <div style={{borderRadius: 10, border: "1px solid #E9EAEB", padding: 20}}>
        <p className="note">Note: Any changes made in address section will only apply to the current order.</p>
        <div className="auth__box s">
          <Input
            onChange={handleShipping}
            label={"Address line 1"}
            placeholder={"Address line 1"}
            id="line1"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.line1)}
            defaultValue={currentAgency?.shipping_address?.line1}
          />
          <Input
            onChange={handleShipping}
            label={"Address line 2"}
            placeholder={"Address line 2"}
            id="line2"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.line2)}
            defaultValue={currentAgency?.shipping_address?.line2}
          />
        </div>
        <div className="auth__box s">
          <Input
            onChange={handleShipping}
            label={"City"}
            placeholder="City"
            id="city"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.city)}
            defaultValue={currentAgency?.shipping_address?.city}
          />
          <Input
            onChange={handleShipping}
            label={"State/ Province/ Region"}
            placeholder={"State/ Province/ Region"}
            id="state"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.state)}
            defaultValue={currentAgency?.shipping_address?.state}
          />

        </div>
        <div className="auth__box s">
          <Input
            onChange={handleShipping}
            label={"Country"}
            placeholder={"Country"}
            id="country"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.country)}
            defaultValue={currentAgency?.shipping_address?.country}
          />
          <Input
            onChange={handleShipping}
            label={"Postal Code"}
            placeholder={"Postal Code"}
            id="postal_code"
            maxwidth={180}
            keyProp={JSON.stringify(currentAgency?.shipping_address?.postal_code)}
            defaultValue={currentAgency?.shipping_address?.postal_code}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Create Manual Order</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => {
              exit()
              setCurrentAgency(null)
            }}
          />
        </div>
        <div className="modal__content-body order-manual">
          <div className="auth__box s">
            <Input
              id="subscription_price"
              name="subscription_price"
              placeholder="Subscription Price"
              label={"Subscription Price"}
              type={"number"}
              defaultValue={state?.subscription_price}
              onChange={handleChange}
            />
            <div className="customInput">
              <span className="customSelect__label mb">Subscription end</span>
              <Datepicker
                setValue={handleDate}
              />
            </div>
          </div>
          <div className="auth__box s">
            <Input
              id="license_count"
              name="license_count"
              placeholder="License Count"
              label={"License Count"}
              type={"number"}
              defaultValue={state?.license_count}
              onChange={handleChange}
            />
            <label className={`customInput`} style={{width: "100%"}} htmlFor={"license_price"}>
              <span style={{marginBottom: 8}}>License Price</span>
                <input
                  type={"number"}
                  name={"license_price"}
                  id={"license_price"}
                  placeholder="License Price"
                  defaultValue={state?.license_price}
                  onChange={handleChange}
                />
            </label>
          </div>
          <div className="inspections__orders-field">
            <p className="customSelect__label mb">
              Do you want to add-on the Custom Form Builder?
            </p>
            <div className="subscription__option-container">
              <div className="subscription__option-choose">
                <label className="container" htmlFor="1">
                  <input
                    type="radio"
                    onChange={handleFormBuilder}
                    name="custom_form_builder"
                    id="1"
                  />
                  Yes
                  <span className="checkmark"></span>
                </label>
                <label className="container" htmlFor="0">
                  <input
                    type="radio"
                    onChange={handleFormBuilder}
                    name="custom_form_builder"
                    id="0"
                  />
                  No
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {
            agencyOptions &&
            <div className="customSelect">
            <span className="customSelect__label">FBO</span>
            <Select
            options={agencyOptions}
              styles={{
              control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              height: 42,
              backgroundColor: "#F4F5F6",
              marginTop: 8,
              }),
            }}
            //   value={chosenRoles}
              onChange={handleAgency}
            />
            </div>
          }
          <div style={{height: 15}}></div>
          {
            state?.custom_form_builder == 1 &&
            <div className="modal__content-fieldfull">
              <Input
                id="custom_form_builder_price"
                name="custom_form_builder_price"
                placeholder="Custom Form Builder Price"
                label={"Custom Form Builder Price"}
                type={"number"}
                defaultValue={state?.custom_form_builder_price}
                onChange={handleChange}
              />
            </div>
          }

          {
            state?.license_count > 0 ?
              renderShipping() : null
          }

        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={() => {
            exit()
            setCurrentAgency(null)
          }}>
            Close
          </button>
          <button className="save" onClick={createOrderSubmit}>
            Create
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>

  );
};

export default OrderCreateModal;
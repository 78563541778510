import React from "react";

const FacilityIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8.66667V5.33333C5 3.49238 6.34315 2 8 2H16C17.6569 2 19 3.49238 19 5.33333V8.66667H20C21.6569 8.66667 23 10.1591 23 12V18.6667C23 20.5076 21.6569 22 20 22H4C2.34315 22 1 20.5076 1 18.6667V12C1 10.1591 2.34315 8.66667 4 8.66667H5ZM8 4.22222H16C16.5523 4.22222 17 4.71968 17 5.33333V19.7778H7V5.33333C7 4.71968 7.44772 4.22222 8 4.22222ZM5 10.8889H4C3.44772 10.8889 3 11.3864 3 12V18.6667C3 19.2803 3.44772 19.7778 4 19.7778H5V10.8889ZM19 19.7778H20C20.5523 19.7778 21 19.2803 21 18.6667V12C21 11.3864 20.5523 10.8889 20 10.8889H19V19.7778Z"
      fill={color}
    />
  </svg>
);

export default FacilityIcon;

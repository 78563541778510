import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";

export default function EquipmentTypeModal({
                                             exit,
                                             list,
                                             addedInspections,
                                             setAddedInspections,
                                             pickedInspection,
                                             setPickedInspection,
                                             saveEquipment,
                                             comparisonList
                                           }) {
  const [newList, setNewList] = useState([])
  useEffect(() => {
    setNewList(list.filter(o1 => !comparisonList.some(o2 => o1.id === o2.form.id)))
  }, [])

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Add inspection</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div style={{padding: "16px 26px 0 16px"}}>
          <Input image={"/assets/icons/search.svg"} id="inspection_search" name="inspection_search"
                 placeholder="Search inspection" type={'text'}/>
        </div>

        <div className="modal__content-body addInspection">
          {newList?.map(inspection => (
            <div className={`editType__inspection ${inspection.id === pickedInspection?.id ? 'active' : ''}`}
                 key={inspection.id} onClick={() => setPickedInspection(inspection)}>{inspection.name}</div>
          ))}
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={() => {
            setPickedInspection(null)
            exit()
          }}>Close</button>
          <button className="save" onClick={() => saveEquipment()}>Save</button>
        </div>
      </div>
      <div className="modal__bg">
      </div>
    </div>
  );
};


import React from 'react';

const FullPhotoModal = ({photo, setModal}) => {
    return (
        <div className="modal">
            <svg onClick={() => setModal(false)} className="modal__content-galleryExit" width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5L5 15" stroke="#ffffff" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5 5L15 15" stroke="#ffffff" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <div className="modal__content-gallery">
                <img className="modal__content-galleryPhoto" src={photo} alt="photo"/>
            </div>
            <div className="modal__bg"></div>
        </div>
    );
};

export default FullPhotoModal;
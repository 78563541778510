import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function FuelSelect({
  currentFacility,
  setCurrentFacility,
  facilityData,
}) {
  const [facilityOptions, setFacilityOptions] = useState([]);

  useEffect(() => {
    if (facilityData) {
      let options = [];
      facilityData?.forEach((item) => {
        options = [
          ...options,
          { label: item?.name, value: item?.id},
        ];
      });
      setFacilityOptions(options);
    }
  }, [facilityData]);

  useEffect(() => {
    if (currentFacility?.stations) {
      let options = [];
      currentFacility?.stations?.forEach((item) => {
        options = [
          ...options,
          { label: item?.name, value: item?.id},
        ];
      });
    }
  }, [currentFacility]);

  return (
    <label htmlFor="">
      Fuel Type
      <Select
        options={facilityOptions}
        onChange={(selected) => {
          setCurrentFacility(selected);
        }}
        value={currentFacility}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
            borderRadius: 8,
            width: 534,
            height: 42,
            backgroundColor: "#F4F5F6",
          }),
        }}
      />
    </label>
  );
}

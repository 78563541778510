import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  useLazyGetCurrentFacilityInspectionsQuery,
  useLazyGetCurrentInspectionQuery,
  useLazyGetInspectionsByTypeQuery,
  useRefuseInspectionsMutation, useSaveEquipmentFormMutation,
  useUpdateEquipmentTypeMutation, useUpdateFacilityInfoMutation
} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {toast} from "react-toastify";
import {setLoader} from "../../store/builder/reducer";
import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import EquipmentTypeModal from "../../components/EquipmentTypeModal";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import Plus from "../../SVG/Plus";
import EditEquipmentForm from "../../components/EditEquipmentForm";
import {setCategories} from "../../store/builder/equipmentEditSlice";
import log from "../../SVG/Log";

export default function EquipmentTypeEditNew ({ editType }) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facilityEditData, setFacilityEditData] = useState({
    id: null,
    name: null,
    airport_id: null,
    agency_id: null,
  });
  const categories = useSelector((state) => state?.rootReducer?.equipmentEditSlice)
  const [
    getCurrentInspection,
    {
      data: inspectionCurrentData,
      isError: inspectionCurrentIsError,
      error: inspectionCurrentError,
      isSuccess: inspectionCurrentIsSuccess,
      isLoading: inspectionCurrentIsLoading,
    },
  ] = useLazyGetCurrentInspectionQuery();
  const [
    getCurrentFacilityInspection,
    {
      data: facilityInspectionCurrentData,
      isError: facilityInspectionCurrentIsError,
      error: facilityInspectionCurrentError,
      isSuccess: facilityInspectionCurrentIsSuccess,
      isLoading: facilityInspectionCurrentIsLoading,
    },
  ] = useLazyGetCurrentFacilityInspectionsQuery();

  const [
    refuseInspections,
    {
      data: refuseInspectionsData,
      isError: refuseInspectionsIsError,
      error: refuseInspectionsError,
      isSuccess: refuseInspectionsIsSuccess,
      isLoading: refuseInspectionsIsLoading,
    },
  ] = useRefuseInspectionsMutation();

  const [modalStatus, setModalStatus] = useState(false);

  const [
    getInspection,
    {
      data: inspectionData,
      isError: inspectionIsError,
      error: inspectionError,
      isSuccess: inspectionIsSuccess,
      isLoading: inspectionIsLoading,
    },
  ] = useLazyGetInspectionsByTypeQuery();

  const [
    update,
    {
      data: updateData,
      isError: updateIsError,
      error: updateError,
      isSuccess: updateIsSuccess,
      isLoading: updateIsLoading,
    },
  ] = useUpdateEquipmentTypeMutation();

  const [
    updateFacilityInfo,
    {
      data: updateFacilityInfoData,
      isError: updateFacilityInfoIsError,
      error: updateFacilityInfoError,
      isSuccess: updateFacilityInfoIsSuccess,
      isLoading: updateFacilityInfoIsLoading,
    },
  ] = useUpdateFacilityInfoMutation();

  const [
    saveEquipmentForm,
    {
      data: saveEquipmentFormData,
      isError: saveEquipmentFormIsError,
      error: saveEquipmentFormError,
      isSuccess: saveEquipmentFormIsSuccess,
      isLoading: saveEquipmentFormIsLoading,
    },
  ] = useSaveEquipmentFormMutation()

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateFacilityInfoError,
    inspectionCurrentError,
    updateError,
    inspectionError,
    refuseInspectionsError,
    facilityInspectionCurrentError,
  ]);

  useEffect(() => {
    getCurrentInspection(params.id);
  }, []);

  // useEffect(() => {
  //   if (updateIsLoading) {
  //     dispatch(setLoader(true));
  //   }
  //   if (updateIsSuccess) {
  //     toast.success(updateData.message);
  //     setModalStatus(false);
  //   }
  //   if (refuseInspectionsIsLoading) {
  //     dispatch(setLoader(true));
  //   }
  //   if (refuseInspectionsIsSuccess) {
  //     dispatch(setLoader(false));
  //   }
  // }, [
  //   inspectionIsLoading,
  //   inspectionIsSuccess,
  //   updateIsSuccess,
  //   updateIsLoading,
  // ]);
  useEffect(() => {
    if (inspectionCurrentIsLoading) {
      dispatch(setLoader(true));
    }
    if (inspectionCurrentIsSuccess) {
      dispatch(setLoader(false));
      dispatch(setCategories(JSON.parse(JSON.stringify(inspectionCurrentData?.data?.form_groups))))
    }
  }, [inspectionCurrentIsLoading, inspectionCurrentIsSuccess])
  useEffect(() => {
    if (saveEquipmentFormIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }

    if (saveEquipmentFormIsSuccess) {
      dispatch(setLoader(false))
      toast.success(saveEquipmentFormData.message)
    }
  }, [saveEquipmentFormIsLoading, saveEquipmentFormIsSuccess])

  const saveForm = () => {
    saveEquipmentForm({id: params?.id, form_groups: categories?.categories})
  }


  return (
    <Wrapper>
      <Helmet>
        <title>
          Edit Equipment Type NDX
        </title>
      </Helmet>
      {/*{modalStatus && (*/}
      {/*  <EquipmentTypeModal*/}
      {/*    exit={() => setModalStatus(false)}*/}
      {/*    list={inspectionData.data}*/}
      {/*    comparisonList={*/}
      {/*      editType === "facility"*/}
      {/*        ? facilityInspectionCurrentData?.data?.links*/}
      {/*        : inspectionCurrentData?.data?.links*/}
      {/*    }*/}
      {/*    setAddedInspections={setAddedInspections}*/}
      {/*    addedInspections={addedInspections}*/}
      {/*    pickedInspection={pickedInspection}*/}
      {/*    setPickedInspection={setPickedInspection}*/}
      {/*    saveEquipment={() => saveEquipment()}*/}
      {/*  />*/}
      {/*)}*/}
      <DashboardHeader
        title={"Edit Equipment Type"}
        type={"equipment-edit"}
        action={saveForm}
      />
      <div className="editType">
          <div className="editType__box">
            <p className="editType__equipmentLabel">Equipment Type</p>
            <div className="editType__equipmentName">
              {inspectionCurrentData?.data?.title}
            </div>
          </div>
        <div>
          {
            inspectionCurrentData?.data?.form_groups?.map((category) => (
                <div className="editType__category" key={category?.key}>
                  <p>{category?.name}</p>
                  <div className="editType__forms">
                    {category.forms.map((form, index) => (
                      <EditEquipmentForm key={index} form={form} index={form.id} category={category} />
                    ))}
                  </div>
                </div>
              ))
          }
        </div>
        {/*<div className="editType__btn" onClick={() => setModalStatus(true)}>*/}
        {/*  <Plus color={"#4268B0"} />*/}
        {/*  <span>Add Inspection</span>*/}
        {/*</div>*/}
      </div>
    </Wrapper>
  );
}



import React from "react";

export default function RadioField({ id, label, setFields, options, field }) {

  return (
    <div className="equipment__container">
      <div className="equipment__radio">
        <div className="title">{label}</div>
        <div className="options">
          {options?.map((option, index) => {
            return (
            <div className="option" key={id + index}>
              <input
                type="radio"
                id={id + index}
                name={id}
                checked={field?.value === option?.value}
                value={option.value}
                onChange={(e) => {
                  setFields((old) => {
                    let data = [...old.map(item => {
                      let newItem = {...item}
                      if (newItem?.key === id) {
                        newItem.value = e.target.value
                      }
                      return newItem
                    })];
                    return data
                  })
                }}
              />
              <label htmlFor={id + index}>{option?.label}</label>
            </div>)
          })}
        </div>
      </div>
    </div>
  );
}

import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements, AddressElement, PaymentElement,
} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {setLoader} from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import {toast} from "react-toastify";
import {
  useGetSecretKeyMutation,
  usePaymentConfirmMutation,
  useUpdatePaymentMutation
} from "../../store/builder/inspections.api";
import Loader from "../Loader";
import {useDispatch, useSelector} from "react-redux";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  // const [updatePayment, {data, isLoading, isSuccess, isError, error: paymentError}] = useUpdatePaymentMutation();
  const [confirmPayment, {data: confirmData, isLoading: confirmIsLoading, isSuccess: confirmIsSuccess, isError: confirmIsError, error: confirmError}] = usePaymentConfirmMutation();
  const handleSubmit = async (event) => {

    event.preventDefault();
    dispatch(setLoader(true))
    if (!stripe || !elements) {
      return;
    }

    stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3002/payment-result',
      },
      redirect: "if_required",
    })
      .then(res => {
        if (res?.paymentIntent?.status === "succeeded") {
          confirmPayment({
            payment_intent_id: res?.paymentIntent?.id
          }).then(res => {
            toast.success("Successfully confirmed!")
            navigate('/inspections')
            setTimeout(() => {
              window.location.reload();
            }, 600)
          })
        } else if (res?.paymentIntent?.status === "processing") {
          toast.success("Successfully confirmed")
          navigate('/warning?status=processing')
        } else {
          toast.error(res?.error?.message)
        }
        dispatch(setLoader(false))
      })
      .catch(err => {
        toast.error(err?.error?.message)
        dispatch(setLoader(false))
      })
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <AddressElement options={{mode: 'shipping'}} />
      <button className="SubmitButton" disabled={!stripe}>Submit</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

export const Stripe = () => {
  const reduxSecretKey = useSelector(state => state?.rootReducer?.paymentSlice?.client_secret)

  return (
    reduxSecretKey ?
      <Elements stripe={stripePromise} options={{clientSecret: reduxSecretKey}}>
        <section className="signup__subscription">
          <CheckoutForm/>
        </section>
      </Elements>
      :
      <div>
        <Loader/>
      </div>
  );
};
import React, {PureComponent, useEffect, useState} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './../chart.scss';
import Select from "react-select";

const TinyBarChart = ({size = 'small', data, title, options, handlePeriodChange, width='', isTime = false}) => {
  const [newData, setNewData] = useState([]);
  const [unit, setUnit] = useState(isTime ? ' seconds' : '');

  useEffect(() => {
    if (data?.some((item) => item?.value)) {
      if (isTime) {
        const timeData = [...data]
        const highestValueObject = timeData?.reduce((prev, current) => {
          return (prev.value > current.value) ? prev : current;
        });
        if (highestValueObject?.value > 1440) {
          setNewData(timeData?.map(item => ({...item, value: Math.round(((item.value / 60) / 24) * 10) / 10})))
          setUnit(' hours')
        } else if (highestValueObject?.value > 60) {
          setNewData(timeData?.map(item => ({...item, value: Math.round((item.value / 60) * 10) / 10})))
          setUnit(' minutes')
        } else {
          setNewData(timeData?.map(item => ({...item, value: Math.round(item.value * 10) / 10})))
          setUnit(' seconds')
        }
      } else {
        setNewData(data)
      }
    } else {
      setNewData([])
    }
  }, [data]);

  return (
    <div className={`chart mid ${width}`}>
      <div className="chart__header">
        <p className="chart__header-title">{title}</p>
        {
          (size === 'small' || size === 'small-mid') &&
          <Select
            defaultValue={options[0]}
            options={options}
            placeholder={"Select type"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 135,
                height: 30,
                fontSize: 14,
                backgroundColor: "#FFFFFF",
                color: "#718096",
              }),
            }}
            onChange={(selected) => {
              handlePeriodChange(selected.value)
            }}
          />
        }

      </div>
      {
        newData?.length > 0 ? (
          <ResponsiveContainer height={size === "small" ? 206 : 303} width='100%'>
          <BarChart height={size === "small" ? 206 : 303} data={newData} barGap={size === 'small' ? 32 : 49}>
            <CartesianGrid strokeDasharray="1" vertical={size === 'small' ? false : true} horizontal={size === 'small'} />
            <Tooltip />
            <XAxis dataKey="key" stroke="rgba(0, 0, 0, 0.40)" tick={{fontSize: 14}} />
            <YAxis  stroke="rgba(0, 0, 0, 0.40)" />
            <Bar dataKey="value" unit={unit} name={title} fill="#548CC5" barSize={size === 'small' ? 18 : 40} radius={4} />
          </BarChart>
          </ResponsiveContainer>
        ) : (
          <div style={{width: '100%', height: '303px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <div className="expired__box-img" style={{margin: '0'}}>
                <img src="/assets/no-data.svg" alt="no-data"/>
              </div>
              <p className="no-payment-text">
                There is no Data!
              </p>
            </div>

          </div>
        )
      }

    </div>
  );
}

export default TinyBarChart;

import React from 'react';

const SwitchIcon = ({color = '#27323C'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.5 4H6.5C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9H17.5C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4ZM6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11H17.5C19.9853 11 22 8.98528 22 6.5C22 4.01472 19.9853 2 17.5 2H6.5Z"
            fill={color}/>
      <path
        d="M19 6.5C19 7.32843 18.3284 8 17.5 8C16.6716 8 16 7.32843 16 6.5C16 5.67157 16.6716 5 17.5 5C18.3284 5 19 5.67157 19 6.5Z"
        fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.5 15H6.5C5.11929 15 4 16.1193 4 17.5C4 18.8807 5.11929 20 6.5 20H17.5C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15ZM6.5 13C4.01472 13 2 15.0147 2 17.5C2 19.9853 4.01472 22 6.5 22H17.5C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13H6.5Z"
            fill={color}/>
      <path
        d="M8 17.5C8 18.3284 7.32843 19 6.5 19C5.67157 19 5 18.3284 5 17.5C5 16.6716 5.67157 16 6.5 16C7.32843 16 8 16.6716 8 17.5Z"
        fill={color}/>
    </svg>
  );
};

export default SwitchIcon;
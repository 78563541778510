import React from 'react';

const GreyGraph = () => {
  return (
    <svg width="70" height="23" viewBox="0 0 70 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="70" height="1" rx="0.5" fill="#999999"/>
      <rect y="1" width="70" height="22" fill="url(#paint0_linear_1_8)"/>
      <defs>
        <linearGradient id="paint0_linear_1_8" x1="35.1901" y1="-42.9322" x2="35.1901" y2="22.9999" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.15"/>
          <stop offset="1" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  );
};

export default GreyGraph;
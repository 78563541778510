import React from 'react';
import Select from "react-select";
import "./index.scss";
import moment from "moment";

const DashboardTable = ({showSelect, title, data, options, handleChange}) => {

  return (
    <div className="chart large table">
      <div className="chart__header">
        <p className="chart__header-title">{title}</p>
        {
          showSelect &&
          <Select
            options={options}
            defaultValue={{value: '', label: 'All'}}
            placeholder={"All"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                borderRadius: 8,
                width: 130,
                height: 30,
                fontSize: 14,
                backgroundColor: "#FFFFFF",
                color: "#718096",
              }),
            }}
            onChange={handleChange}
          />
        }

      </div>

        <div className="dashboard__table">
          <div className="dashboard__table-head dashboard__table-head-personal">
            <div className="dashboard__table-headCell no-transform">Name</div>
            <div className="dashboard__table-headCell no-transform">Email</div>
            <div className="dashboard__table-headCell no-transform">Roles</div>
            <div className="dashboard__table-headCell no-transform">Last login web</div>
            <div className="dashboard__table-headCell no-transform">Last login mobile</div>
          </div>
          <div className="dashboard__table-body">
            {
              data?.length ?
              data?.map((elem) => (
                <div
                  className="dashboard__table-row dashboard__table-row-personal"
                  key={elem?.id}
                >
                  {/*style={{maxWidth: '180px'}}*/}
                  <div className="dashboard__table-cell">
                    <img src={elem?.avatar} alt="photo"/>
                    <span>{elem?.name}</span>
                  </div>
                  <div className="dashboard__table-cell">
                    <span>{elem?.email}</span>
                  </div>
                  <div className="dashboard__table-cell"><span>{elem?.role?.name?.charAt(0).toUpperCase() + elem?.role?.name?.slice(1)}</span> </div>
                  <div className="dashboard__table-cell"> <span>{elem?.last_login_at ? moment(elem?.last_login_at).format('MM/DD/YY') : '--- ---'}</span></div>
                  <div className="dashboard__table-cell"><span>{elem?.last_mobile_login_at ? moment(elem?.last_mobile_login_at).format('MM/DD/YY') : '--- ---'}</span> </div>
                </div>
              ))
                :
              <div className='emptyList small'>
              <img src="/assets/empty-list.png" alt="empty" />
              <p>No data yet</p>
              </div>
            }
          </div>
        </div>
    </div>
  );
};

export default DashboardTable;
import React, {PureComponent, useEffect, useState} from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import './../chart.scss';
import Select from "react-select";

const StackedTinyBarChart = ({title, data, options, handleChange}) => {
  const [newData, setNewData] = useState([]);


  useEffect(() => {
    if (data?.some((item) => (item?.value?.nfc || item?.value?.total))) {
      setNewData(data)
    } else {
      setNewData([])
    }
  }, [data])

  const renderRadius = (type, item) => {
    if (type === 'value2') {
      if (item.value1 === 0) {
        return 4
      } else {
        return [0,0,4,4]
      }
    }
    if (type === 'value1') {
      return [4,4,0,0]
    }
  };

  return (
    <div className="chart mid">
      <div className="chart__header">
        <p className="chart__header-title">{title}</p>
        <Select
          defaultValue={options[0]}
          options={options}
          placeholder={"Select type"}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
              borderRadius: 8,
              width: 135,
              height: 30,
              fontSize: 14,
              backgroundColor: "#FFFFFF",
              color: "#718096",
            }),
          }}
          onChange={(selected) => {
            handleChange(selected.value)
          }}
        />
      </div>
      {
        newData?.length ? (
          <ResponsiveContainer height={287} width='100%'>
          <BarChart
            // width={695}
            height={287}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1" vertical={true} horizontal={false} />
            <XAxis dataKey="name" stroke="rgba(0, 0, 0, 0.40)" tick={{fontSize: 14}} />
            <YAxis stroke="rgba(0, 0, 0, 0.40)" />
            <Tooltip />
            <Legend />
              <Bar
                dataKey={"value2"}
                name={"Without Tap"}
                fill="#548CC5"
                stackId={'a'}
                barSize={18}
              >
                {newData.map((item, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={'#548CC5'}
                    radius={renderRadius('value2', item)}
                  />
                ))}
              </Bar>
              <Bar
                dataKey={"value1"}
                name={"With Tap"}
                fill="#DF9800"
                stackId={'a'}
                barSize={18}
              >
                {newData.map((item, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={'#DF9800'}
                    radius={renderRadius('value1', item)}
                  />
                ))}
              </Bar>
            {/*<Bar dataKey="value2" name="Inspections without location verification" stackId="a" fill="#548CC5" radius={[ 0, 0, 8, 8]} />*/}
            {/*<Bar dataKey="value1" name="Performed with location verification" stackId="a" fill="#DF9800"  radius={[8, 8, 0, 0]} />*/}
          </BarChart>
          </ResponsiveContainer>
        ) : (
          <div style={{width: '100%', height: '287px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div>
              <div className="expired__box-img" style={{margin: '0'}}>
                <img src="/assets/no-data.svg" alt="no-data"/>
              </div>
              <p className="no-payment-text">
                There is no Data!
              </p>
            </div>

          </div>
        )
      }
    </div>
  );
}

export default StackedTinyBarChart;

import React, { useEffect, useState } from "react";
import { useUpdateSettingMutation } from "../../store/builder/inspections.api";
import { setLoader } from "../../store/builder/reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";

const SettingsModal = ({
  setModal,
  setCurrentSetting,
  currentSetting,
  getSettings,
}) => {
  const dispatch = useDispatch();
  const [some, setSome] = useState(null);
  const [body, setBody] = useState({
    field_type: currentSetting?.field_type,
    value: currentSetting?.value,
    description: currentSetting?.description,
  });

  const [update, { data, error, isLoading, isSuccess, isError }] =
    useUpdateSettingMutation();
  const [errorHandler, setErrorHandler] = useErrorHandler([error]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [isLoading]);

  const handleChange = (e) => {
    setBody((old) => ({ ...old, [e.target.id]: e.target.value }));
  };

  const updateSetting = () => {
    update({ id: currentSetting?.id, body: body })
      .then((res) => {
        if (res?.error?.status) {
          toast.success(res.data?.message);
        } else {
          toast.success(res.data?.message);
          setModal(false);
          getSettings();
        }
      })
      .catch((err) => {
        toast.err(err.response.message);
      });
  };

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Update setting</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => {
              setModal(false);
              setCurrentSetting(null);
            }}
          />
        </div>
        <div className="modal__content-body">
          <div className="auth__field">
            <label htmlFor="fbo_email" className="auth__label fbo">
              Name
            </label>
            <input
              id="name"
              placeholder="Name"
              type="text"
              className="auth__input"
              readOnly={true}
              onChange={handleChange}
              defaultValue={currentSetting ? currentSetting?.name : ""}
            />
          </div>
          <div className="auth__field">
            <label htmlFor="fbo_email" className="auth__label fbo">
              Description
            </label>
            <input
              id="description"
              placeholder="Description"
              type="text"
              className="auth__input"
              onChange={handleChange}
              defaultValue={currentSetting ? currentSetting?.description : ""}
            />
          </div>
          <div className="auth__field">
            <label htmlFor="fbo_email" className="auth__label fbo">
              Value
            </label>
            {currentSetting && currentSetting?.field_type === "price" ? (
              <div className="input_number">
                <span>$</span>
                <input
                  id="value"
                  placeholder="Value"
                  type="number"
                  className="auth__input"
                  onChange={handleChange}
                  defaultValue={currentSetting ? currentSetting?.value : ""}
                />
              </div>
            ) : (
              <input
                id="value"
                placeholder="Value"
                type={
                  currentSetting?.field_type === "price" ? "number" : "text"
                }
                className="auth__input"
                onChange={handleChange}
                defaultValue={currentSetting ? currentSetting?.value : ""}
              />
            )}
          </div>
        </div>
        <div className="modal__content-bottom">
          <button
            className="close"
            onClick={() => {
              setModal(false);
              setCurrentSetting(null);
            }}
          >
            Close
          </button>
          <button className="save" onClick={updateSetting}>
            Save
          </button>
        </div>
      </div>
      <div
        className="modal__bg"
        // onClick={() => {
        //   setModal(false);
        //   setCurrentSetting(null);
        // }}
      ></div>
    </div>
  );
};

export default SettingsModal;

import React, {useEffect, useState} from 'react';
import {useUploadFileMutation} from "../../store/builder/inspections.api";
import {setFields, setLoader} from "../../store/builder/reducer";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

export default function FillPhoto ({ inspection_id, item, readOnly, sheet, category_id, disabled }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [image, setImage] = useState(null);
  const [uploadFile, { data, isSuccess, isLoading, isError }] =
    useUploadFileMutation();
  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  };

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true))
    }
    if (isSuccess || isError) {
      dispatch(setLoader(false))
    }
  }, [isLoading, isSuccess, isError])

  const handleImage = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array?.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    const form = new FormData();
    form.append("file", e.target.files[0]);
    form.append("type", "inspection");
    form.append("inspection_id", params?.id);
    form.append("category_id", category_id);
    form.append("uuid", guid());

    setImage({ preview: imagesArray, data: e.target.files[0] });
    uploadFile(form)
      .then((res) => {
        let inputData = {
          id: item.id,
          answers: [res?.data?.data?.uuid],
          has_comment: false,
          comment_text: "",
          comment_files: [],
        };
        dispatch(setFields(inputData));
    });
  }

  return (
    <div>
      <div className="equipment__file">
        <div className="file">
          <input
            id={item?.id}
            type="file"
            accept="image/png, image/jpeg"
            onChange={handleImage}
          />
          {
            (readOnly || disabled) && !image && !item?.photo?.url ?
              <span className="file__preview-inspectionPhotoContainer">
                <span>N/A</span>
              </span>
              :
              readOnly || disabled ?
                <a href={item?.photo?.url} target="_blank" className="file__preview-inspectionPhotoContainer">
                  <img className="file__preview-inspectionPhoto" src={item?.photo?.url} alt="photo" />
                </a>
                :
            item?.photo?.url && !image ? (
            <div
              className="file__preview column"
            >
              <a href={item?.photo?.url} target="_blank" className="file__preview-inspectionPhotoContainer">
                  <img className="file__preview-inspectionPhoto" src={item?.photo?.url} alt="photo" />
              </a>
              <label htmlFor={item?.id}>
              <span>
                <img src="/assets/icons/camera.svg" alt="camera" />
              </span>
                <p>Choose file</p>
              </label>

            </div>
          )  : !image && !item?.photo?.url ? (
            <label htmlFor={item?.id} className="dsdadsd">
                <span>
                  <img className="file__preview-inspectionPhoto" src="/assets/icons/camera.svg" alt="camera" />
                </span>
              <p>Choose file</p>
            </label>
          ) : (
              <div
                className="file__preview column"
              >
                <p className="file__preview-inspectionPhotoContainer">
                  {image?.preview?.map((item, index) => (
                    <img className="file__preview-inspectionPhoto" src={item} key={index} alt="photo" />
                  ))}
                </p>
                <label htmlFor={item?.id}>
                  <span>
                    <img src="/assets/icons/camera.svg" alt="camera" />
                  </span>
                  <p>Choose file</p>
                </label>
              </div>
          )}
        </div>
      </div>

    </div>
  );
};


import React, { useEffect, useState } from "react";
import "./index.scss";
import Input from "../Input";
import Checkbox from "../Checkbox";
import CustomSelect from "../CustomSelect";
import {
  useGetPeriodsQuery,
  useCreateInspectionMutation,
  useCreateAgencyMutation,
  useUpdateAgancyMutation,
} from "../../store/builder/inspections.api";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import Datepicker from "../Datepicker";
import moment from "moment/moment";

export default function Modal({
  title,
  exit,
  setData,
  type,
  currentItem,
  setCurrentItem,
  timezones,
  airportList,
  setChosenPhoto,
  setShowModal,
  photo,
  setPhoto,
  imageBlob,
  getAgencies,
  isAdmin = true,
  waterKitData,
}) {
  const dispatch = useDispatch();
  const [inspection, setInspection] = useState({
    name: null,
    period: null,
    is_for_equipment: false,
  });
  const [typeOptions, setTypeOptions] = useState([
    { value: false, label: "Facility" },
    { value: true, label: "Equipment" },
  ]);
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [timezone, setTimezone] = useState(null);
  const [airport, setAirport] = useState(null);
  const [hydrantSystems, setHydrantSystems] = useState(false);
  const [upstreamTests, setUpstreamTests] = useState(false);
  const [hasCorrectedDp, setHasCorrectedDp] = useState(false);
  const [enableDevice, setEnabledDevice] = useState(false)
  const [checkboxes, setCheckboxes] = useState({})
  const [todayIsDay, setTodayIsDay] = useState(false);
  const { data, isError, isFetching, error, isSuccess } = useGetPeriodsQuery();
  const [image, setImage] = useState(null);
  const [sendPhoto, setSendPhoto] = useState(null);
  const [blob, setBlob] = useState(null);
  const [time, setTime] = useState(null);
  const [startDayIsTodayOptions, setStartDayIsTodayOptions] = useState([
    { value: true, label: "Same Day" },
    { value: false, label: "Next Day" },
  ]);
  const [solidRating, setSolidRating] = useState([
    {
      value: 1, label: "1. Clean"
    },
    {
      value: 2, label: "2. Slight"
    },
    {
      value: 3, label: "3. Particulate"
    },
    {
      value: 4, label: "4. Dirty"
    }
  ])
  const [waterRating, setWaterRating] = useState([
    {
      value: "A", label: "A. Bright"
    },
    {
      value: "B", label: "B. Hazy"
    },
    {
      value: "C", label: "C. Cloudy"
    },
    {
      value: "D", label: "D. Wet"
    },
    {
      value: "E", label: "E. Surfactants"
    }
  ])
  const [timeOptions, setTimeOptions] = useState([
    {
      label: "12:00 AM",
      value: 0,
    },
    {
      label: "1:00 AM",
      value: 60,
    },
    {
      label: "2:00 AM",
      value: 120,
    },
    {
      label: "3:00 AM",
      value: 180,
    },
    {
      label: "4:00 AM",
      value: 240,
    },
    {
      label: "5:00 AM",
      value: 300,
    },
    {
      label: "6:00 AM",
      value: 360,
    },
    {
      label: "7:00 AM",
      value: 420,
    },
    {
      label: "8:00 AM",
      value: 480,
    },
    {
      label: "9:00 AM",
      value: 540,
    },
    {
      label: "10:00 AM",
      value: 600,
    },
    {
      label: "11:00 AM",
      value: 660,
    },
    {
      label: "12:00 PM",
      value: 720,
    },
    {
      label: "1:00 PM",
      value: 780,
    },
    {
      label: "2:00 PM",
      value: 840,
    },
    {
      label: "3:00 PM",
      value: 900,
    },
    {
      label: "4:00 PM",
      value: 960,
    },
    {
      label: "5:00 PM",
      value: 1020,
    },
    {
      label: "6:00 PM",
      value: 1080,
    },
    {
      label: "7:00 PM",
      value: 1140,
    },
    {
      label: "8:00 PM",
      value: 1200,
    },
    {
      label: "9:00 PM",
      value: 1260,
    },
    {
      label: "10:00 PM",
      value: 1320,
    },
    {
      label: "11:00 PM",
      value: 1380,
    },
  ]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [fboEmail, setFboEmail] = useState(null);
  const [fboData, setFboData] = useState({
    nozzle_fueling_pressure: null,
    nozzle_fueling_pressure_decommission: null,
    sump_solid_rating: null,
    sump_water_rating: null,
    sump_trend_alert: null,
    daily_weekly_grace_period: null,
    water_test_kit_id: null,
  })
  const [
    createInspection,
    {
      data: createInspectionData,
      isError: createInspectionIsError,
      isLoading: createInspectionIsLoading,
      isSuccess: createInspectionIsSuccess,
      error: createInspectionError,
    },
  ] = useCreateInspectionMutation();

  const [
    createAgancy,
    {
      data: createAgancyData,
      isError: createAgancyIsError,
      isFetching: createAgancyIsLoading,
      isSuccess: createAgancyIsSuccess,
      error: createAgancyError,
    },
  ] = useCreateAgencyMutation();

  const [
    updateAgency,
    {
      data: updateAgancyData,
      isError: updateAgancyIsError,
      isFetching: updateAgancyIsLoading,
      isSuccess: updateAgancyIsSuccess,
      error: updateAgancyError,
    },
  ] = useUpdateAgancyMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    createInspectionError,
    error,
    createAgancyError,
    updateAgancyIsError,
    updateAgancyError,
  ]);

  useEffect(() => {
    if (createInspectionIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }
  }, [createInspectionIsLoading]);

  useEffect(() => {
    if (createInspectionIsSuccess) {
      exit();
      toast.success(createInspectionData?.message);
    }
    if (createInspectionIsError) {
      toast.error(
        createInspectionError.data.period
          ? createInspectionError.data.period[0]
          : "Error"
      );
    }
  }, [createInspectionIsSuccess, createInspectionIsError]);
  useEffect(() => {

    if (currentItem?.timezone) {
      setTimezone(currentItem?.timezone);
    }
    if (currentItem?.airport_id) {
      setAirport(currentItem?.airport_id);
    }
    if (currentItem?.fbo_email) {
      setFboEmail(currentItem?.fbo_email);
    }
    if (currentItem?.nozzle_fueling_pressure) {
      setFboData(old => ({...old, nozzle_fueling_pressure: currentItem?.nozzle_fueling_pressure ? currentItem?.nozzle_fueling_pressure : null}));
    }
    if (currentItem?.nozzle_fueling_pressure_decommission) {
      setFboData(old => ({...old, nozzle_fueling_pressure_decommission: currentItem?.nozzle_fueling_pressure_decommission ? currentItem?.nozzle_fueling_pressure_decommission : null}));
    }
    if (currentItem?.sump_solid_rating) {
      setFboData(old => ({...old, sump_solid_rating: currentItem?.sump_solid_rating ? currentItem?.sump_solid_rating : null}));
    }
    if (currentItem?.sump_water_rating) {
      setFboData(old => ({...old, sump_water_rating: currentItem?.sump_water_rating ? currentItem?.sump_water_rating : null}));
    }
    if (currentItem?.sump_trend_alert) {
      setFboData(old => ({...old, sump_trend_alert: currentItem?.sump_trend_alert ? currentItem?.sump_trend_alert : null}));
    }
    if (currentItem?.daily_weekly_grace_period !== null && currentItem?.daily_weekly_grace_period !== undefined) {
      setFboData(old => ({...old, daily_weekly_grace_period: currentItem?.daily_weekly_grace_period !== null && currentItem?.daily_weekly_grace_period !== undefined ? currentItem?.daily_weekly_grace_period : null}));
    }
    if (currentItem?.water_test_kit_id) {
      setFboData(old => ({...old, water_test_kit_id: currentItem?.water_test_kit_id ? currentItem?.water_test_kit_id : null}))
    }
    // if (currentItem?.trial_end_date) {
    //   setFboData(old => ({...old, trial_end_date: currentItem?.trial_end_date ? currentItem?.trial_end_date : null}));
    // }
    if (currentItem?.has_hydrant_systems) {
      setHydrantSystems(currentItem?.has_hydrant_systems === 1 ? true : false);
    }
    if (currentItem?.has_upstream_tests) {
      setUpstreamTests(currentItem?.has_upstream_tests);
    }
    if (currentItem?.has_corrected_dp) {
      setHasCorrectedDp(currentItem?.has_corrected_dp);
    }
    // if (currentItem?.device_enabled) {
    //   setEnabledDevice(currentItem?.device_enabled);
    // }
    if (currentItem?.fbo_phone) {
      setPhoneNumber(currentItem?.fbo_phone?.replace(/\D/g, "").slice(1));
    }

    if (typeof currentItem?.start_day_minutes === "number") {
      setTime(currentItem?.start_day_minutes);
    }
    // setTodayIsDay(currentItem?.start_day_is_today);
  }, [currentItem]);

  useEffect(() => {
    if (currentItem?.logo) {
      setPhoto(currentItem?.logo);
    }
  }, []);

  const handleCreateChange = (e) => {
    setInspection((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleTimezone = (e) => {
    setTimezone(e.target.value);
  };
  const handleAirport = (e) => {
    setAirport(e.target.value);
  };
  const handleTime = (e) => {
    setTime(e.target.value);
  };
  const handleDayIsToday = (e) => {
    setTodayIsDay(e.target.value);
  };

  const handleFboData = (e) => {
    setFboData(old => ({...old, [e.target.name]: e.target.value}))
  }

  const submitCreateInspection = (e) => {
    e.preventDefault();
    dispatch(setLoader(true));
    if (type === "agancy") {
      let formData = new FormData();
      formData.append("title", inspection?.name ?? currentItem?.title);
      formData.append("timezone", timezone);
      formData.append("airport_id", airport);
      formData.append("has_hydrant_systems", hydrantSystems ? 1 : 0);
      formData.append("has_upstream_tests", upstreamTests ? 1 : 0);
      formData.append("has_corrected_dp", hasCorrectedDp ? 1 : 0);
      formData.append("daily_weekly_grace_period", fboData?.daily_weekly_grace_period);
      formData.append("water_test_kit_id", fboData?.water_test_kit_id === null ? 0 : fboData?.water_test_kit_id);
      formData.append("start_day_minutes", time);
      // formData.append("start_day_is_today", todayIsDay === true ? 1 : 0);
      formData.append("fbo_email", fboEmail);
      formData.append("fbo_phone", "+1" + phoneNumber?.replace(/\D/g, ""));
      formData.append("nozzle_fueling_pressure", fboData?.nozzle_fueling_pressure);
      formData.append("nozzle_fueling_pressure_decommission", fboData?.nozzle_fueling_pressure_decommission);
      formData.append("sump_solid_rating", fboData?.sump_solid_rating);
      formData.append("sump_water_rating", fboData?.sump_water_rating);
      if (fboData?.sump_trend_alert) {
        formData.append("sump_trend_alert", fboData?.sump_trend_alert);
      }
      if (!currentItem) {
        formData.append("trial_end_date", fboData?.trial_end_date ?? null);
      }

      if (imageBlob !== null) {
        formData.append("logo", imageBlob);
      }
      if (!currentItem) {
        createAgancy(formData)
          .then((res) => {
            if (res?.error?.status > 400) {
              dispatch(setLoader(false));
            } else {
              exit();
              dispatch(setLoader(false));
              toast.success(res?.data?.message);
            }
            dispatch(setLoader(false));
            getAgencies();
        });
      } else {
        updateAgency({
          id: currentItem?.id,
          data: formData,
        })
          .then((res) => {
            if (res?.error?.status !== 422 && res?.error?.status !== 500) {
              exit();
              setCurrentItem(null);
              getAgencies()
            }
            dispatch(setLoader(false));
            toast.success(res?.data?.message);
          })
          .catch((err) => {
            dispatch(setLoader(false));
          });

        // if (!fboData?.sump_trend_alert || fboData?.sump_trend_alert === null) {
        //   toast.error('Sump trend alert field is required')
        //   dispatch(setLoader(false))
        // }

      }
    } else {
      if (inspection?.name?.length > 1 && inspection?.period) {
        createInspection(inspection);
      } else {
        toast.error("Fill all fields!");
      }
    }
  };

  const cropperHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setChosenPhoto(reader.result);
    };
    reader.readAsDataURL(files[0]);
    setShowModal(true);
    document.querySelector(".modal-fbo").style.display = "none";
  };

  const handleChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    ); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const fboEmailHandler = (e) => {
    setFboEmail(e.target.value);
  };
  return (
    <div className="modal modal-fbo">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>{!currentItem ? title : "Update FBO"}</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={exit}
          />
        </div>
        <div className={`modal__content-body ${type === "agancy" ? "user" : ""}`}>
          <Input
            containerWidth={'100%'}
            id="name"
            name="name"
            placeholder={type === "inspection-form" ? "Inspection name" : "FBO name"}
            label={type === "inspection-form" ? "Inspection name" : "FBO name"}
            type={"text"}
            onChange={handleCreateChange}
            defaultValue={currentItem ? currentItem?.title : ""}
            disabled={currentItem?.disabled_fields?.includes('name')}
          />
          {type == "agancy" && (
            <Input
              id="fbo_email"
              name="fbo_email"
              placeholder="FBO Email"
              label={"FBO Email"}
              type={"email"}
              onChange={fboEmailHandler}
              defaultValue={currentItem ? currentItem?.fbo_email : ""}
              disabled={currentItem?.disabled_fields?.includes('fbo_email')}
            />
          )}
          {/* {type === "agancy" && (
            <div className="auth__field">
              <label htmlFor="fbo_email" className="auth__label fbo">
                FBO Email
              </label>
              <input
                id="fbo_email"
                placeholder="FBO Email"
                type="text"
                className="auth__input"
                onChange={fboEmailHandler}
                defaultValue={currentItem ? currentItem?.fbo_email : ""}
              />
            </div>
          )} */}

          {type === "agancy" && (
            <div className="auth__field phone">
              <label htmlFor="fbo_phone" className="auth__label fbo">
                FBO Phone
              </label>
              <span
                style={{
                  position: "absolute",
                  top: "37.5px",
                  fontSize: "14px",
                  left: "10px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                }}
              >
                +1
              </span>
              <input
                id="fbo_phone"
                placeholder="FBO Phone"
                type="tel"
                value={phoneNumber}
                pattern="\+\d\s\d{3}\s\d{3}-\d{4}"
                className="auth__input"
                onChange={handleChange}
                disabled={currentItem?.disabled_fields?.includes('fbo_phone')}
              />
            </div>
          )}

          {type !== "agancy" && (
            <CustomSelect
              label={"Inspection Period"}
              options={data?.data}
              name={"period"}
              onChange={handleCreateChange}
              disabled={currentItem?.disabled_fields?.includes('period')}
            />
          )}
          {type !== "agancy" && (
            <CustomSelect
              label={"Type"}
              options={typeOptions}
              name={"is_for_equipment"}
              onChange={handleCreateChange}
              disabled={currentItem?.disabled_fields?.includes('is_for_equipment')}
            />
          )}

          {type === "agancy" && (
            <CustomSelect
              label={"Timezone"}
              options={timezones}
              name={"timezone"}
              onChange={handleTimezone}
              defaultValue={
                currentItem
                  ? { value: currentItem.timezone, label: currentItem.timezone }
                  : null
              }
              disabled={currentItem?.disabled_fields?.includes('timezone')}
            />
          )}
          {type === "agancy" && (
            <div style={{ marginTop: 16 }}>
              <Input
                label={"Airport ID"}
                name={"airport_id"}
                placeholder={"Airport ID"}
                onChange={handleAirport}
                defaultValue={currentItem ? currentItem.airport_id : null}
                disabled={currentItem?.disabled_fields?.includes('airport_id')}
              />
            </div>
          )}
          {
            isAdmin &&
            <>
              {type === "agancy" && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                  <CustomSelect
                    tooltip={true}
                    tooltipText={"Influences when inspections are generated. A 7 am workday will allow day’s inspections to be completed 7 am - 7 am next day."}
                    label={"When do you want new inspections to generate"}
                    options={timeOptions}
                    name={"start_day_minutes"}
                    onChange={handleTime}
                    defaultValue={
                      currentItem
                        ? timeOptions.filter(
                          (el) => el.value === currentItem?.start_day_minutes
                        )[0]
                        : null
                    }
                    disabled={currentItem?.disabled_fields?.includes('start_day_minutes')}
                  />
                </div>
              )}
              {type == "agancy" && (
                <Input
                  tooltip={true}
                  tooltipText={"Nozzle Fueling Pressure"}
                  id="nozzle_fueling_pressure"
                  name="nozzle_fueling_pressure"
                  placeholder="Nozzle Fueling Pressure"
                  label={"Nozzle Fueling Pressure"}
                  type={"text"}
                  containerWidth={"93%"}
                  onChange={handleFboData}
                  defaultValue={currentItem ? currentItem?.nozzle_fueling_pressure : ""}
                  maxwidth={'100%'}
                  disabled={currentItem?.disabled_fields?.includes('nozzle_fueling_pressure')}
                />
              )}
              {type == "agancy" && (
                <Input
                  tooltip={true}
                  tooltipText={"Nozzle Fueling Pressure Decommission"}
                  id="nozzle_fueling_pressure_decommission"
                  name="nozzle_fueling_pressure_decommission"
                  placeholder="Nozzle Fueling Pressure Decommission"
                  label={"Nozzle Fueling Pressure Decommission"}
                  type={"text"}
                  containerWidth={"93%"}
                  onChange={handleFboData}
                  defaultValue={currentItem ? currentItem?.nozzle_fueling_pressure_decommission : ""}
                  maxwidth={'100%'}
                  disabled={currentItem?.disabled_fields?.includes('nozzle_fueling_pressure_decommission')}
                />
              )}

              {type == "agancy" && (
                <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between", gap: 16, marginBottom: 20}}>
                  <CustomSelect
                    tooltip={true}
                    tooltipText={'User-defined sump solid rating threshold will trigger e-mail alert when sump inspection input is at or below this solid rating'}
                    maxwidth={231}
                    id="sump_solid_rating"
                    name="sump_solid_rating"
                    placeholder="Sump Solid Rating"
                    label={"Sump Solid Rating"}
                    options={solidRating}
                    onChange={handleFboData}
                    defaultValue={currentItem ? solidRating.filter(el => el.value == currentItem?.sump_solid_rating)[0] : ""}
                    disabled={currentItem?.disabled_fields?.includes('sump_solid_rating')}
                  />
                  <CustomSelect
                    tooltip={true}
                    tooltipText={'User-defined sump water rating threshold will trigger e-mail alert when sump inspection input is at or below this sump water rating'}
                    maxwidth={231}
                    id="sump_water_rating"
                    name="sump_water_rating"
                    placeholder="Sump Water Rating"
                    label={"Sump Water Rating"}
                    options={waterRating}
                    onChange={handleFboData}
                    defaultValue={currentItem ? waterRating.filter(el => el.value == currentItem?.sump_water_rating)[0] : ""}
                    disabled={currentItem?.disabled_fields?.includes('sump_water_rating')}
                  />
                </div>
              )}
              {type == "agancy" && (
                <Input
                  tooltip={true}
                  tooltipText={"Sump trend alert will e-mail you when the same sump result is entered for a piece of equipment X number of days in a row"}
                  id="sump_trend_alert"
                  name="sump_trend_alert"
                  placeholder="Sump Trend Alert"
                  label={"Sump Trend Alert"}
                  type={"text"}
                  onChange={handleFboData}
                  defaultValue={currentItem ? currentItem?.sump_trend_alert : ""}
                  disabled={currentItem?.disabled_fields?.includes('sump_trend_alert')}
                />
              )}
              {type == "agancy" && (
                <Input
                  tooltip={true}
                  tooltipText={"Minimum value is 0 and maximum value is 365"}
                  id="daily_weekly_grace_period"
                  name="daily_weekly_grace_period"
                  placeholder="Daily Weekly Grace Period"
                  label={"Daily Weekly Grace Period"}
                  type={"number"}
                  onChange={handleFboData}
                  defaultValue={currentItem ? currentItem?.daily_weekly_grace_period : ""}
                  disabled={currentItem?.disabled_fields?.includes('daily_weekly_grace_period')}
                />
              )}
              {type == "agancy" && (
                <div style={{marginBottom: 20}}>
                  <CustomSelect
                    label={"Water Test Kit"}
                    options={waterKitData}
                    name={"water_test_kit_id"}
                    onChange={handleFboData}
                    defaultValue={currentItem ?
                      waterKitData?.filter(el => el.value == currentItem?.water_test_kit_id?.toString())[0]
                      : null}
                    disabled={currentItem?.disabled_fields?.includes('water_test_kit_id')}
                  />
                </div>
              )}
            </>
          }

          {/*{type === "agancy" && (*/}
          {/*  <div style={{ marginTop: 16, marginBottom: 16 }}>*/}
          {/*    <CustomSelect*/}
          {/*      tooltip={true}*/}
          {/*      tooltipText={"Inspections Created for"}*/}
          {/*      label={"Inspections Created for"}*/}
          {/*      options={startDayIsTodayOptions}*/}
          {/*      name={"start_day_is_today"}*/}
          {/*      onChange={handleDayIsToday}*/}
          {/*      defaultValue={*/}
          {/*        currentItem*/}
          {/*          ? startDayIsTodayOptions.filter(*/}
          {/*              (el) => el.value === currentItem?.start_day_is_today*/}
          {/*            )[0]*/}
          {/*          : null*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}

          {/*{(type == "agancy" && !currentItem)&& (*/}
          {/*  <div className="modal__content-trial" style={{margin: '0 0 16px'}}>*/}
          {/*    <p className="trial__modal-title">End Date</p>*/}
          {/*    <Datepicker*/}
          {/*      width={'100%'}*/}
          {/*      setValue={(e) => handleFboData({*/}
          {/*        target: {*/}
          {/*          name: 'trial_end_date',*/}
          {/*          value: moment(e).format('YYYY-MM-DD HH:mm:ss')*/}
          {/*        }*/}
          {/*      })}*/}
          {/*      value={currentItem ? currentItem?.trial_end_date : null}*/}
          {/*      img={false}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*)}*/}

          {type === "agancy" && (
            <div className="facility-modal__field">
              <Checkbox
                checked={hydrantSystems}
                key={currentItem + ''}
                label={"Has Hydrant systems"}
                id={"has_hydrant_systems"}
                onChange={(e) => {
                  setHydrantSystems(e.target.checked);
                }}
                disabled={currentItem?.disabled_fields?.includes('has_hydrant_systems')}
              />
              <Checkbox
                checked={upstreamTests}
                key={currentItem + '2'}
                label={"Has Upstream Tests"}
                id={"has_upstream_tests"}
                onChange={(e) => {
                  setUpstreamTests(e.target.checked);
                }}
                disabled={currentItem?.disabled_fields?.includes('has_upstream_tests')}
              />
              <Checkbox
                checked={hasCorrectedDp}
                key={currentItem + '2'}
                label={"Has Corrected DP for weekly Avgas"}
                id={"has_corrected_dp"}
                onChange={(e) => {
                  setHasCorrectedDp(e.target.checked);
                }}
                disabled={currentItem?.disabled_fields?.includes('has_corrected_dp')}
              />

            </div>
          )}
          {photo !== null && type === "agancy" && (
            <div className="crop-photo-container">
              <img className="crop-photo" src={photo} alt="" />
            </div>
          )}
          {type === "agancy" && (
            <div className="file">
              <input
                onChange={cropperHandler}
                type="file"
                id={"logo"}
                accept="image/png, image/jpeg"
                className="unused"
              />
              <label htmlFor={"logo"}>
                <span>
                  <img src="/assets/icons/camera.svg" alt="camera" />
                </span>
                <p>Choose file</p>
              </label>
            </div>
          )}
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={exit}>
            Close
          </button>
          <button className="save" onClick={submitCreateInspection}>
            Save
          </button>
        </div>
      </div>
      <div className="modal__bg"></div>
    </div>
  );
}

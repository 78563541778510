import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import './index.scss'
import Input from "../../components/Input";
import {useGetEquipmentTypeQuery, useGetFieldTypesQuery} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {useNavigate} from "react-router-dom";
import { useErrorHandler } from '../../global/ErrorsHandler';
import { Helmet } from 'react-helmet';

export default function EquipmentType() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState("");

  const {
    data: equipmentTypesData,
    isError: equipmentTypesIsError,
    error: equipmentTypesError,
    isSuccess: equipmentTypesIsSuccess,
    isFetching: equipmentTypesIsLoading
  } = useGetEquipmentTypeQuery()

  const [errorHandler, setErrorHandler] = useErrorHandler([equipmentTypesError])

  useEffect(() => {
    if (equipmentTypesIsLoading) {
      dispatch(setLoader(equipmentTypesIsLoading))
    }
    if (equipmentTypesIsSuccess) {
      dispatch(setLoader(false))
    }
  }, [equipmentTypesIsLoading, equipmentTypesIsSuccess])

  const filteredData = equipmentTypesData?.filter((el) => {
    if (search === "") {
      return el;
    } else {
      return el?.title?.toLowerCase()?.includes(search.toLowerCase());
    }
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Equipment Type | NDX</title>
      </Helmet>
      <DashboardHeader title={'Equipment Type'} type={'equipment-type'} action={() => navigate('/equipment-type/assigned')} />
      <div className="equipmentType">
        <div className="equipmentType__content">
          <div className="equipmentType__search">
            <Input
              type="text"
              placeholder="Search"
              name="equipmentTypeSearch"
              id="equipmentTypeSearch"
              image={"/assets/icons/search.svg"}
              maxwidth={264}
              onChange={handleSearch}
            />
          </div>
          <div className="equipmentType__table">
            <div className="equipmentType__table-header">
              <div className="equipmentType__th">Equipment Type</div>
              <div className="equipmentType__th">Inspections</div>
              <div className="equipmentType__th"></div>
            </div>
            <div className="equipmentType__table-body">
              {
                filteredData?.length ? filteredData?.map(type => (
                  <div className="equipmentType__row" key={type.id}>
                    <div className="equipmentType__row-th">{type.title}</div>
                    <div className="equipmentType__row-th">{type.links.length}</div>
                    <div className="equipmentType__row-th">
                      <div onClick={() => navigate('/equipment-type/edit/' + type.id)}>
                        Edit
                      </div>
                    </div>
                  </div>
                )) : (
                  <div className='emptyList'>
                    <img src="/assets/empty-list.png" alt="empty" />
                    <p>No equipment data yet</p>
                  </div>
                )
              }

            </div>
          </div>
        </div>
      </div>

    </Wrapper>
  );
};


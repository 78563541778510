import React, {useEffect, useRef, useState} from "react";
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import Select from "react-select";
import {
  useLazyGetFacilityQuery,
  useGetInspectionQuery,
  useGetPeriodsQuery,
  useLazyGetInspectionByFacilityQuery,
  useCreatePeriodInspectionMutation,
  useGetAgenciesWithEquipmentsQuery,
  useLazyGetAgenciesWithEquipmentsQuery,
} from "../../store/builder/inspections.api";
import Modal from "../../components/Modal";
import { setLoader } from "../../store/builder/reducer";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import moment from "moment";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Helmet } from "react-helmet";
import {toast} from "react-toastify";
import CreateFiltersChangeModal from "../../components/CreateFiltersChangeModal";

const statusOptions = [
  { value: "new", label: "New" },
  { value: "half", label: "Half-done" },
  { value: "completed", label: "Completed" },
];

export default function Inspections() {
  const urlParams = new URLSearchParams(window.location.search);
  const popupRef = useRef();
  const pageTab = urlParams.get("tab");
  const pageFacility = urlParams.get("facility")
  const [currentTab, setCurrentTab] = useState(
    pageTab && pageTab !== "" ? pageTab : "daily"
  );
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentSearch, setCurrentSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facilityOptions, setFacilityOptions] = useState(null);
  const [equipmentOptions, setEquipmentOptions] = useState(null);
  const [list, setList] = useState([]);
  const [addStatus, setAddStatus] = useState(false);
  const [currentFacility, setCurrentFacility] = useState(null);
  const [createFilterStatus, setCreateFilterStatus] = useState(false);
  const [createFilterType, setCreateFilterType] = useState('');

  const [
    getInspectionByFacility,
    { data, isError, isFetching, error, isSuccess },
  ] = useLazyGetInspectionByFacilityQuery();

  const {
    data: periods,
    isFetching: periodsisLoading,
    error: periodsError,
    isSuccess: periodsIsSuccess,
  } = useGetPeriodsQuery();

  const [getAgenciesWithEquipment, {
    data: agenciesWithEquipmentData,
    isLoading: agenciesWithEquipmentLoading,
    error: agenciesWithEquipmentError,
    isSuccess: agenciesWithEquipmentIsSuccess,
  }] = useLazyGetAgenciesWithEquipmentsQuery();

  const [
    getFacility,
    {
      data: facilityData,
      isError: facilityIsError,
      error: facilityError,
      isSuccess: facilityIsSuccess,
      isFetching: facilityIsLoading,
    },
  ] = useLazyGetFacilityQuery();

  const [createInspectionPer,
    {
      data: createInspectionPerData,
      isError: createInspectionPerIsError,
      error: createInspectionPerError,
      isSuccess: createInspectionPerIsSuccess,
      isLoading: createInspectionPerIsLoading,
    },] = useCreatePeriodInspectionMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    facilityError,
    periodsError,
    error,
    createInspectionPerError,
  ]);
  const [currentFacilityId, setCurrentFacilityId] = useState(null);

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setAddStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    getFacility();
  }, []);

  useEffect(() => {
    if (facilityIsLoading || isFetching || createInspectionPerIsLoading) {
      dispatch(setLoader(true));
    } else {
      dispatch(setLoader(false));
    }

    if (createInspectionPerIsSuccess) {
      toast.success('Inspection successfully created');
      setAddStatus(false);
    }
  }, [facilityIsLoading, isFetching, createInspectionPerIsLoading, createInspectionPerIsSuccess]);

  // useEffect(() => {
  //   dispatch(setLoader(isFetching));
  // }, []);

  useEffect(() => {
    if (facilityOptions) {
      getInspectionByFacility(facilityOptions[0]?.value);
      setCurrentFacilityId(facilityOptions[0]?.value)
    }
  }, [facilityOptions]);

  useEffect(() => {
    if (pageFacility) {
      setCurrentFacilityId(pageFacility)
    }
  }, [pageFacility])

  useEffect(() => {
    navigate(`/inspections?tab=${pageTab?.value ? pageTab?.value : "daily"}${currentFacilityId ? "&facility=" + currentFacilityId : ""}`);
  }, [currentFacilityId])

  const changeTab = (tabId) => {
    setCurrentTab(tabId);
    // setCurrentSearch("");
  };



  useEffect(() => {
    setFacilityOptions([{ value: 0, label: "All" }])
    if (facilityData) {
      facilityData?.map((item) => {
        setFacilityOptions((old) => {
          if (old) {
            return [...old, { value: item?.id, label: item?.name }];
          } else {
            return [{ value: item?.id, label: item?.name }];
          }
        });
        return item;
      });
    }
  }, [facilityData]);

  useEffect(() => {
    if (currentFacilityId) {
      setCurrentFacility(facilityData?.filter(item => item.id == currentFacilityId))
    }
  }, [currentFacilityId])

  const fullFilter = () => {
    let s = data?.inspections?.filter((item) =>
      currentSearch !== ""
        ? item?.inspection_name
            ?.toLowerCase()
            ?.includes(currentSearch?.toLowerCase())
        : item
    );
    s = s?.filter((item) => {
      if (currentTab === 'annual') {
        if (item?.period === "element_change" || item?.period === "visual_inspection") {
          return item
        }
        return item?.period === currentTab
      } else {
        return item?.period === currentTab
      }
    });
    s = s?.filter((item) =>
      currentStatus
        ? item?.status_progress === currentStatus?.value
        : item?.status_progress !== currentStatus?.value
    );
    setList(s);
  };

  useEffect(() => {
    fullFilter();
  }, [currentSearch, currentTab, currentStatus, data]);


  const createPeriodInspectionSubmit = ({period, code}) => {
    createInspectionPer({period: period, facility_id: currentFacilityId, code: code})
      .then(res => {
        navigate(`/inspections/${res?.data?.inspection_id}`)
      })

  }

  return (
    <Wrapper>
      <Helmet>
        <title>Inspections | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"Inspections"}
        showBtn={true}
        type="inspection"
        secondAction={getInspectionByFacility}
        facilityId={currentFacilityId}
        action={() => setAddStatus(!addStatus)}
      />
      {createFilterStatus &&
        <CreateFiltersChangeModal
          createFilterType={createFilterType}
          getAgenciesWithEquipment={getAgenciesWithEquipment}
          data={agenciesWithEquipmentData}
          agenciesWithEquipmentLoading={agenciesWithEquipmentLoading}
          exit={() => setCreateFilterStatus(false)}
        />
      }
      {
        addStatus &&
        <div className="inspections__addModal" ref={popupRef}>
          {
            (currentFacilityId !== null && currentFacilityId !== undefined && currentFacilityId !== '' && currentFacilityId !== 0) ?
              <div className="inspections__addModal-inner">
                {
                  currentFacility[0]?.manuallyCreatableInspections?.length > 0 ?
                  currentFacility[0]?.manuallyCreatableInspections?.map((item) => (
                    <div onClick={() => createPeriodInspectionSubmit({period: item?.period, code: item?.code})}>
                      {item?.name}
                    </div>
                  ))
                    :
                    <span className="no-payment-text center">
                      This facility is not able to create inspections!
                    </span>
                }
              </div>
              :
              <span className="no-payment-text center">
                First you need to  select Facility to be able to create inspections in period other
              </span>
          }
          <div onClick={() => {
            setAddStatus(false);
            setCreateFilterStatus(true);
            setCreateFilterType('fuel_receipt')
          }} className="inspections__addModal-create">
            Record of Fuel Receipt by Transport Truck
          </div>
          <div onClick={() => {
            setAddStatus(false);
            setCreateFilterStatus(true);
            setCreateFilterType('filter_change')
          }} className="inspections__addModal-create">
            Create Filter Change
          </div>
          <div onClick={() => {
            setAddStatus(false);
            setCreateFilterStatus(true);
            setCreateFilterType('fuel_quality')
          }} className="inspections__addModal-create">
            Fuel Quality Test Record
          </div>
        </div>
      }
      <div className="inspections">
        <div className="inspections__header">
          {periods?.data?.map((period, index) => (
            <p
              className={`inspections__header-tab ${
                currentTab === period?.value ? "active" : ""
              }`}
              key={index}
              onClick={() => {
                changeTab(period?.value);
                navigate(`/inspections?tab=${period.value}`);
              }}
            >
              {period?.label}
            </p>
          ))}
        </div>
        <div className="inspections__filters">
          <Input
            type="text"
            placeholder="Search by name"
            name="inspectionsSearch"
            id="inspectionsSearch"
            image={"/assets/icons/search.svg"}
            maxwidth={204}
            value={currentSearch}
            onChange={(e) => {
              setCurrentSearch(e.target.value);
            }}
          />
          <div>
            {facilityOptions && (
              <Select
                defaultValue={facilityOptions[0]}
                options={facilityOptions}
                placeholder={"By Facility"}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                    borderRadius: 8,
                    width: 240,
                    height: 42,
                    backgroundColor: "#F4F5F6",
                  }),
                }}
                onChange={(selected) => {
                  getInspectionByFacility(selected?.value);
                  setCurrentFacilityId(selected?.value)
                }}
              />
            )}
          </div>
          <div>
            <Select
              options={statusOptions}
              placeholder={"By Status"}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#E9EAEB" : "#E9EAEB",
                  borderRadius: 8,
                  width: 240,
                  height: 42,
                  backgroundColor: "#F4F5F6",
                }),
              }}
              isClearable
              onChange={(selected) => {
                setCurrentStatus(selected);
              }}
            />
          </div>
        </div>
        <div className="inspections__box">
          <div className="inspections__table">
            <div className="inspections__table-head">
              <div className="inspections__table-th">Date</div>
              <div className="inspections__table-th">Inspection ID</div>
              <div className="inspections__table-th">Facility</div>
              <div className="inspections__table-th">NFC</div>
              <div className="inspections__table-th">Due by</div>
              <div className="inspections__table-th">Progress</div>
              <div className="inspections__table-th">Status</div>
              <div className="inspections__table-th">Completed By</div>
            </div>
            {list?.filter(el => el.inspection_hidden === false)?.length ? list?.filter(el => el.inspection_hidden === false)?.map((inspection) => (
              <div
                onClick={() => {
                  navigate(`/inspections/${inspection.inspection_id}`, {
                    state: {data: inspection},
                  });
                }}
                className="inspections__table-row"
                key={inspection.inspection_id}
              >
                <div className="inspections__table-bodyTh">
                  {inspection?.date
                    ? moment(inspection?.date).format("ll")
                    : ""}
                </div>
                <div className="inspections__table-bodyTh">
                  <span>{inspection.inspection_name}</span>
                </div>
                <div className="inspections__table-bodyTh">
                  {inspection.facility_title}
                </div>
                <div className="inspections__table-bodyTh">
                  {inspection?.beacon_address ? (
                    <img src="/assets/icons/nfc-active.png" alt="icon"/>
                  ) : (
                    <img src="/assets/icons/nfc-inactive.png" alt="icon"/>
                  )}
                </div>
                <div className="inspections__table-bodyTh">
                  {inspection?.due_by ? inspection?.due_by : '---'}
                </div>
                <div className="inspections__table-bodyTh">
                  {inspection?.status === "" ? "0/0" : inspection?.status}
                </div>
                <div className="inspections__table-bodyTh">
                  <span className={inspection?.status_progress + " status"}>
                    {inspection?.status_progress}
                  </span>
                </div>
                <div className="inspections__table-bodyTh user">
                  {
                    inspection?.submitted_user ?
                      <>
                        {inspection?.submitted_user?.avatar && (
                          <img
                            className="inspections__table-img"
                            src={
                              inspection?.submitted_user?.avatar
                                ? inspection?.submitted_user?.avatar
                                : ""
                            }
                            alt="avatar"
                          />
                        )}
                        <span>{inspection?.submitted_user?.name}</span>
                      </>
                      : '---'
                  }
                </div>
                {/*<div*/}
                {/*  style={{ opacity: 0 }}*/}
                {/*  className="inspections__table-bodyTh"*/}
                {/*>*/}
                {/*  <img src="/assets/icons/Download.svg" alt="Download" />*/}
                {/*</div>*/}
              </div>
            )) : (
              <div className='emptyList'>
                <img src="/assets/empty-list.png" alt="empty"/>
                <p>No inspections data yet</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import React, {useEffect, useRef, useState} from 'react';
import {useChangeCategoryMutation, useDeleteCategoryMutation} from "../../store/builder/inspections.api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import InspectionSideFields from "../InspectionSideFields";
import {addParentField, initCategories} from "../../store/builder/inspectionSlice";
import {useDrag, useDrop} from "react-dnd";
import { useErrorHandler } from '../../global/ErrorsHandler';

export default function CategoryField({category, id, index, moveCard}) {
  const [isEdit, setIsEdit] = useState(false)
  const [fieldModal, setFieldModal] = useState(false)
  const [confirmModalStatus, setConfirmModalStatus] = useState(false)
  const [categoryData, setCategoryData] = useState({
    id: category.id,
    label: null
  })

  const [deleteCategory, {
    data: deleteCategoryData,
    isError: deleteCategoryIsError,
    error: deleteCategoryError,
    isSuccess: deleteCategoryIsSuccess,
    isFetching: deleteCategoryIsLoading
  }] = useDeleteCategoryMutation()

  const [changeCategory, {
    data: changeCategoryData,
    isError: changeCategoryIsError,
    error: changeCategoryError,
    isSuccess: changeCategoryIsSuccess,
    isFetching: changeCategoryIsLoading
  }] = useChangeCategoryMutation()

  const [errorHandler, setErrorHandler] = useErrorHandler([deleteCategoryError, changeCategoryError])

  const dispatch = useDispatch()

  const deleteCurrentCategory = () => {
    deleteCategory({id: category.id})
  }
  const handleCategoryName = (e) => {
    setCategoryData(old => ({...old, label: e.target.value}))
  }
  useEffect(() => {
    if (deleteCategoryIsSuccess) {
      toast.success(deleteCategoryData.message)
    }
    if (deleteCategoryIsError) {
      toast.error(deleteCategoryData.message)
    }
  }, [deleteCategoryIsLoading, deleteCategoryIsSuccess, deleteCategoryIsError])

  useEffect(() => {
    if (changeCategoryIsSuccess) {
      setIsEdit(false)
      toast.success(changeCategoryData.message)
      setConfirmModalStatus(false)
    }
    if (changeCategoryIsError) {
      toast.error(changeCategoryData.message)
    }
  }, [changeCategoryIsLoading, changeCategoryIsSuccess, changeCategoryIsError])
  useEffect(() => {
    if (deleteCategoryIsLoading || changeCategoryIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [deleteCategoryIsLoading, changeCategoryIsLoading])

  useEffect(() => {
    if (fieldModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [fieldModal])

  const confirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setConfirmModalStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to delete this category?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setConfirmModalStatus(false)}>
              Close
            </button>
            <button className="save" onClick={() => deleteCurrentCategory()}>
              Yes
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const handleCategorySubmit = (e) => {
    e.preventDefault()
    if (isEdit) {
      changeCategory(categoryData)
    }
  }
  const ItemTypes = {
    CARD: 'card',
  }
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <form ref={ref} onSubmit={(e) => handleCategorySubmit(e)} style={{opacity}} data-handler-id={handlerId}>
      {fieldModal && <InspectionSideFields category={category} setOpen={setFieldModal} />}
      {confirmModalStatus && confirmModal()}
      <div className="builder__form-category">
        {
          isEdit ?
            <input id="addStep" placeholder="Categories name" name="category_name" defaultValue={category.label} onChange={handleCategoryName}/>
            :
            <p className="builder__form-categoryTitle">{category.label}</p>
        }
        {
          isEdit ?
            <div className="builder__form-categoryInner">
              <img src="/assets/icons/x.svg" alt="confirm" onClick={() => setIsEdit(false)}/>
              <img src="/assets/icons/confirm.svg" alt="confirm" onClick={handleCategorySubmit} />
            </div>
            :
            <div className="builder__form-categoryInner">
              <p onClick={() => {
                //TODO: CHANGE ID FROM STRING TO INTEGER
                dispatch(initCategories({...category, fields: category.fields.length === 0 ? [{ id: Math.floor(Math.random() * Date.now()) + "", label: null }] : category.fields}))
                setFieldModal(!fieldModal)
              }}>Fields: {category.fields.length}</p>
              <img src="/assets/icons/edit.svg" alt="icon" onClick={() => {
                setIsEdit(!isEdit)
                setCategoryData(old => ({...old, label: category.label}))
              }} />
              <img src="/assets/icons/delete.svg" alt="icon" onClick={() => setConfirmModalStatus(true)}/>
            </div>
        }

        {/*<img src="/assets/icons/arrow-right.svg" alt="arrow" onClick={() => {*/}
        {/*  setCurrentStep(category.id)*/}
        {/*  dispatch(addParentField({category}))*/}
        {/*}}/>*/}
      </div>
    </form>
  );
};


import Wrapper from "../../components/Wrapper";
import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import DashboardHeader from "../../components/DashboardHeader";
import Input from "../../components/Input";
import './index.scss';
import CorporateCreateModal from "../../components/Corporate/CorporateCreate";
import CorporateInviteModal from "../../components/Corporate/CorporateInvite";
import CorporateAccountRow from "../../components/Corporate/CorporateAccountRow";
import EditCorporatePricingModal from "../../components/EditCorporatePricingModal";
import {
  useCorporateUpdateStatusMutation,
  useGetTimezonesQuery,
  useLazyCorporateListQuery
} from "../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/builder/reducer";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {useDebounce} from "../LogSheets/hooks";
import CorporateEditModal from "../../components/Corporate/CorporateEditModal";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";


export default function CorporateAccount ({ type }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentCorporateAccount, setCurrentCorporateAccount] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredCorporate, setFilteredCorporate] = useState([])
  const [deactivateStatus, setDeactivateStatus] = useState(false);
  const [reactivateStatus, setReactivateStatus] = useState(false);

  const debouncedSearchValue = useDebounce(search, 500);

  const [getCorporateList, {
    data: corporateListData,
    error: corporateListError,
    isFetching: corporateListIsLoading,
    isSuccess: corporateListIsSuccess,
    isError: corporateListIsError,
  }] = useLazyCorporateListQuery();

  const [changeStatus, {
    data: changeStatusData,
    error: changeStatusError,
    isFetching: changeStatusIsLoading,
    isSuccess: changeStatusIsSuccess,
    isError: changeStatusIsError,
  }] = useCorporateUpdateStatusMutation();

  const {data: timezoneData, error: timezoneError, isLoading: timezoneIsLoading, isSuccess: timezoneIsSuccess, isError: timezoneIsError} = useGetTimezonesQuery()

  const [errorHandler, setErrorHandler] = useErrorHandler([corporateListError, timezoneError])

  useEffect(() => {
    getCorporateList({status: type})

    dispatch(setLoader(true))
    setTimeout(() => {
      dispatch(setLoader(false))
    }, 1500)
  }, [type])

  useEffect(() => {
    if (createModal ||
      editModal ||
    inviteModal ||
    pricingModal) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'visible'
    }
  }, [createModal,
    editModal,
    inviteModal,
    pricingModal])

  useEffect(() => {
    if (corporateListIsLoading) {
      // dispatch(setLoader(true))
    }
    if (corporateListIsSuccess) {
      // dispatch(setLoader(false))
      setCorporateData(corporateListData?.map(item => ({...item, dropdown_status: false})))
    }
  }, [corporateListIsLoading, corporateListIsSuccess])

  useEffect(() => {
    const filter = corporateData?.filter((el) => {
      if (debouncedSearchValue === "") {
        return el
      } else {
        return el?.title?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredCorporate(filter)
  }, [debouncedSearchValue, corporateData])

  const getCorporateListFunc = () => getCorporateList({status: type})

  const renderDeactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to deactivate this corporate?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Deactivate" will deactivate this corporate and make it unavailable for further use. Are you sure you want to deactivate?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              changeStatus({id: currentCorporateAccount?.id, body: {status: 'inactive'}})
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("Corporate successfully deactivated")
                  setDeactivateStatus(false)
                  getCorporateListFunc()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                })
            }}>
              Deactivate
            </button>
            <button className="close almost" onClick={() => {
              setDeactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  const renderReactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to reactivate this corporate?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setReactivateStatus(false)}
            />
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              changeStatus({id: currentCorporateAccount?.id, body: {status: 'active'}})
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("Corporate successfully reactivated")
                  setReactivateStatus(false)
                  getCorporateListFunc()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                })
            }}>
              Reactivate
            </button>
            <button className="close almost" onClick={() => {
              setReactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <Wrapper>
      {deactivateStatus && renderDeactivate()}
      {reactivateStatus && renderReactivate()}
      {createModal && <CorporateCreateModal exit={() => {
        setCreateModal(false)
        setCurrentCorporateAccount(null)
      }} getCorporateList={getCorporateListFunc} currentCorporateAccount={currentCorporateAccount} />}
      {editModal && <CorporateEditModal
        exit={() => setEditModal(false)}
        currentCorporateAccount={currentCorporateAccount}
        timezoneOptions={timezoneData?.data?.map(el => ({value: el, label: el}))}
        getCorporateListFunc={getCorporateListFunc}
      />}

      {inviteModal && <CorporateInviteModal exit={() => setInviteModal(false)} />}
      {pricingModal && <EditCorporatePricingModal getCorporateListFunc={getCorporateListFunc} currentCorporateAccount={currentCorporateAccount} exit={() => setPricingModal(false)} />}
      <Helmet>
        <title>{type === 'active' ? 'Active ' : 'Inactive '}Corporate | NDX</title>
      </Helmet>
      <DashboardHeader
        title={type === 'active' ? 'Active Corporate' : 'Inactive Corporate'}
        type="corporate"
        action={() => setCreateModal(true)}
        secondAction={() => setInviteModal(true)}
      />
      <div className="corporate">
        <div className="corporate__content">
          <div className="corporate__search">
            <Input
              type="text"
              placeholder="Search by corporate name"
              name="corporateSearch"
              id="corporateSearch"
              image={"/assets/icons/search.svg"}
              maxwidth={264}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="corporate__table">
            <div className="corporate__table-header">
              <div className="corporate__th">First name</div>
              <div className="corporate__th">Last name</div>
              <div className="corporate__th">Corporate Name</div>
              <div className="corporate__th">Email</div>
              <div className="corporate__th">Action</div>
            </div>
            <div className="corporate__table-body">
              {
                filteredCorporate?.length > 0 ?
                  filteredCorporate?.map((item, index) => (
                  <CorporateAccountRow
                    item={item}
                    key={index}
                    data={corporateData}
                    setCorporateData={setCorporateData}
                    setCurrentCorporateAccount={setCurrentCorporateAccount}
                    setPricingModal={setPricingModal}
                    setEditModal={setEditModal}
                    setDeactivateStatus={setDeactivateStatus}
                    setReactivateStatus={setReactivateStatus}
                    type={type}
                  />
                ))
                  :
                  <div className="corporate__table-empty">
                    <img
                      src="/assets/no-data-v3.svg"
                      alt="empty"
                    />
                    <p>There are no Corporates</p>
                  </div>
              }
            </div>
          </div>
          {/*<CustomPaginate*/}
          {/*  pageCount={corporateData?.pages}*/}
          {/*  handlePageClick={handlePageClick}*/}
          {/*/>*/}
        </div>
      </div>


    </Wrapper>
  )
}
import React from 'react';

const CorrectiveActionsIcon = ({active}) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill={active ? "#FFFFFF" : "#AEB3B7"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15 17C15 14.7909 16.7909 13 19 13H27.3431C28.404 13 29.4214 13.4214 30.1716 14.1716L31.8284 15.8284C32.5786 16.5786 33 17.596 33 18.6569V31C33 33.2091 31.2091 35 29 35H19C16.7909 35 15 33.2091 15 31V17ZM31 20V31C31 32.1046 30.1046 33 29 33H19C17.8954 33 17 32.1046 17 31V17C17 15.8954 17.8954 15 19 15H26V17C26 18.6569 27.3431 20 29 20H31ZM30.8891 18C30.7909 17.7176 30.6296 17.4581 30.4142 17.2426L28.7574 15.5858C28.5419 15.3704 28.2824 15.2091 28 15.1109V17C28 17.5523 28.4477 18 29 18H30.8891Z" stroke={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20 15.5C20 15.2239 20.2239 15 20.5 15H21.5C21.7761 15 22 15.2239 22 15.5V16.5C22 16.7761 21.7761 17 21.5 17H20.5C20.2239 17 20 16.7761 20 16.5V15.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20 19.5C20 19.2239 20.2239 19 20.5 19H21.5C21.7761 19 22 19.2239 22 19.5V20.5C22 20.7761 21.7761 21 21.5 21H20.5C20.2239 21 20 20.7761 20 20.5V19.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M22 21.5C22 21.2239 22.2239 21 22.5 21H23.5C23.7761 21 24 21.2239 24 21.5V22.5C24 22.7761 23.7761 23 23.5 23H22.5C22.2239 23 22 22.7761 22 22.5V21.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20.5 23C20.2239 23 20 23.2239 20 23.5V24.5C20 24.7761 20.2239 25 20.5 25H21.5C21.7761 25 22 24.7761 22 24.5V23.5C22 23.2239 21.7761 23 21.5 23H20.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M22 25.5C22 25.2239 22.2239 25 22.5 25H23.5C23.7761 25 24 25.2239 24 25.5V26.5C24 26.7761 23.7761 27 23.5 27H22.5C22.2239 27 22 26.7761 22 26.5V25.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20.5 27C20.2239 27 20 27.2239 20 27.5V28.5C20 28.7761 20.2239 29 20.5 29H21.5C21.7761 29 22 28.7761 22 28.5V27.5C22 27.2239 21.7761 27 21.5 27H20.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M22 29.5C22 29.2239 22.2239 29 22.5 29H23.5C23.7761 29 24 29.2239 24 29.5V30.5C24 30.7761 23.7761 31 23.5 31H22.5C22.2239 31 22 30.7761 22 30.5V29.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M20.5 31C20.2239 31 20 31.2239 20 31.5V32.5C20 32.7761 20.2239 33 20.5 33H21.5C21.7761 33 22 32.7761 22 32.5V31.5C22 31.2239 21.7761 31 21.5 31H20.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
      <path d="M22.5 17C22.2239 17 22 17.2239 22 17.5V18.5C22 18.7761 22.2239 19 22.5 19H23.5C23.7761 19 24 18.7761 24 18.5V17.5C24 17.2239 23.7761 17 23.5 17H22.5Z" fill={active ? "#464646" : "#FFFFFF"}/>
    </svg>
  );
};

export default CorrectiveActionsIcon;
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {validationEmail} from "../../global/validate";
import {toast} from "react-toastify";
import axios from "axios";
import {setLoader} from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import {useDispatch} from "react-redux";
import {setVerifyRegister, setVerifyRegisterTime} from "../../store/builder/registerSlice";

export default function RegistrationCorporate () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [eye, setEye] = useState(false);
  const [isFboStatus, setIsFboStatus] = useState(false)
  const [state, setState] = useState({
    first_name: {
      id: "first_name",
      value: null,
      errorShow: false,
      error: "Please enter correct full name (min length 5)",
    },
    last_name: {
      id: "last_name",
      value: null,
      errorShow: false,
      error: "Please enter correct full name (min length 5)",
    },
    fbo_corporate_phone: {
      id: "fbo_corporate_phone",
      value: null,
      errorShow: false,
      error: "Please enter correct Corporate phone",
    },
    fbo_corporate_name: {
      id: "fbo_corporate_name",
      value: null,
      errorShow: false,
      error: "Please enter correct Corporate name",
    },
    fbo_corporate_email: {
      id: "fbo_corporate_email",
      value: null,
      errorShow: false,
      error: "Please enter correct Corporate email",
    },
    password: {
      id: "password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
    password_confirmation: {
      id: "password_confirmation",
      value: null,
      errorShow: false,
      error: "Password and confirm password should be identical",
    },
    token: null
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);
  const [publicToken, setPublicToken] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (location.search) {
      setPublicToken(searchParams.get('public_key'))
    }
  }, [location.search])

  useEffect(() => {
    dispatch(setLoader(true))
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
    if (publicToken) {
      axios.post(`${process.env.REACT_APP_API_URL}auth/verify-sing-up-token`, {
        token: publicToken,
      }, config)
        .then(res => {
          dispatch(setLoader(false));
          // if (res.data.data.user?.fbo_id) {
          //   setIfShowCheckbox(false);
          // }
          setState(old => ({...old, token: res.data.data.secret_token}));
        })
        .catch(err => {
          dispatch(setLoader(false))
        })
        .finally(() => {
          dispatch(setLoader(false))
        })
    }
    dispatch(setLoader(false))
  }, [publicToken])

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };
  const handleChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, ''); // remove non-numeric characters
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // add phone number formatting
    setPhoneNumber(formattedPhone);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let config = {
      headers: {
        AppVersion: '1.0.0',
        AppType: 'web',
        DeviceType: 'web'
      },
    };
      if (validationEmail(state?.fbo_corporate_email?.value) &&
        state?.first_name?.value?.length > 2 &&
        state?.last_name?.value?.length > 2 &&
        state?.fbo_corporate_name?.value?.length > 2 &&
        phoneNumber?.length > 9 &&
        state?.password?.value === state?.password_confirmation?.value) {
        dispatch(setLoader(true));
        axios
          .post(
            `${process.env.REACT_APP_API_URL}auth/finish-corporate-registration`,
            {
              token: state?.token,
              first_name: state.first_name.value,
              last_name: state.last_name.value,
              fbo_corporate_name: state.fbo_corporate_name.value,
              fbo_corporate_email: state.fbo_corporate_email.value,
              fbo_corporate_phone: "+1" + phoneNumber.replace(/\D/g, ''),
              password: state.password.value,
              password_confirmation: state.password_confirmation.value,
            },
            config
          )
          .then((res) => {
            dispatch(setLoader(false));
            toast.success("You successfully registered", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              navigate('/login')
            }, 500)
          })
          .catch((err) => {
            dispatch(setLoader(false));
            if (err?.response?.data?.token) {
              toast.error("This user has already been registered!");
            }
            if (err?.response?.data?.email) {
              toast.error(err?.response?.data?.email[0]);
            }
            if (err?.response?.data?.first_name) {
              toast.error(err?.response?.data?.first_name[0]);
            }
            if (err?.response?.data?.last_name) {
              toast.error(err?.response?.data?.last_name[0]);
            }
            if (err?.response?.data?.fbo_corporate_name) {
              toast.error(err?.response?.data?.corporate_name[0]);
            }
            if (err?.response?.data?.fbo_corporate_phone) {
              toast.error(err?.response?.data?.fbo_corporate_phone[0]);
            }
            if (err?.response?.data?.fbo_corporate_email) {
              toast.error(err?.response?.data?.fbo_corporate_email[0]);
            }
            if (err?.response?.data?.password) {
              toast.error(err?.response?.data?.password[0]);
            }
            if (err?.response?.data?.password_confirmation) {
              toast.error(err?.response?.data?.password_confirmation[0]);
            }
          });
      }

    if (!state?.first_name?.value || state?.first_name?.value?.length < 3) {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: true}}))
    } else {
      setState(old => ({...old, first_name: {...old.first_name, errorShow: false}}))
    }

    if (!state?.last_name?.value || state?.last_name?.value?.length < 3) {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: true}}))
    } else {
      setState(old => ({...old, last_name: {...old.last_name, errorShow: false}}))
    }

    if (!state?.fbo_corporate_name?.value || state?.fbo_corporate_name?.value?.length < 3) {
      setState(old => ({...old, fbo_corporate_name: {...old.fbo_corporate_name, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_corporate_name: {...old.fbo_corporate_name, errorShow: false}}))
    }

    if (!validationEmail(state?.fbo_corporate_email?.value)) {
      setState(old => ({...old, fbo_corporate_email: {...old.fbo_corporate_email, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_corporate_email: {...old.fbo_corporate_email, errorShow: false}}))
    }

    if (!phoneNumber || phoneNumber?.length < 10) {
      setState(old => ({...old, fbo_corporate_phone: {...old.fbo_corporate_phone, errorShow: true}}))
    } else {
      setState(old => ({...old, fbo_corporate_phone: {...old.fbo_corporate_phone, errorShow: false}}))
    }

    if (!state?.password?.value || state?.password?.value?.length < 3) {
      setState(old => ({...old, password: {...old.password, errorShow: true}}))
    } else {
      setState(old => ({...old, password: {...old.password, errorShow: false}}))
    }

    if (state?.password?.value !== state?.password_confirmation?.value) {
      setState(old => ({...old, password_confirmation: {...old.password_confirmation, errorShow: true}}))
    } else {
      setState(old => ({...old, password_confirmation: {...old.password_confirmation, errorShow: false}}))
    }
  }

  return (
    <div className="auth">
      <Helmet>
        <title>Corporate Registration | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/register-bg.png" alt="bg" />
        </div>
        <div className="auth__right s">
          <div className="auth__content regist">
            <h1 className="auth__title">Sign Up</h1>
            <form className="auth__form">
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="full_name" className="auth__label">
                    First name
                  </label>
                  <input
                    id="first_name"
                    name="first_name"
                    placeholder="First name"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.first_name?.errorShow && (
                    <span className="auth__error">{state?.first_name?.error}</span>
                  )}
                </div>
              </div>
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="full_name" className="auth__label">
                    Last name
                  </label>
                  <input
                    id="last_name"
                    name="last_name"
                    placeholder="Last name"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.last_name?.errorShow && (
                    <span className="auth__error">{state?.last_name?.error}</span>
                  )}
                </div>
              </div>
              {/*<div className="auth__box">*/}
              {/*  <div className="auth__field">*/}
              {/*    <label htmlFor="email" className="auth__label">*/}
              {/*      Email*/}
              {/*    </label>*/}
              {/*    <input*/}
              {/*      id="email"*/}
              {/*      name="email"*/}
              {/*      placeholder="Enter email"*/}
              {/*      type="text"*/}
              {/*      className="auth__input"*/}
              {/*      onChange={inputHandler}*/}
              {/*    />*/}
              {/*    {state?.email?.errorShow && (*/}
              {/*      <span className="auth__error">{state?.email?.error}</span>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="auth__field phone">
                <label htmlFor="corporate_phone" className="auth__label">
                  Phone Number
                </label>
                <span className="phone__num">+1</span>
                <input
                  id="fbo_corporate_phone"
                  name="fbo_corporate_phone"
                  placeholder="Enter Phone Number"
                  type="tel"
                  value={phoneNumber}
                  // pattern="\+\d\s\d{3}\s\d{3}-\d{4}"
                  className="auth__input"
                  onChange={handleChange}
                />
                {state?.fbo_corporate_phone?.errorShow && (
                  <span className="auth__error">{state?.fbo_corporate_phone?.error}</span>
                )}
              </div>
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="corporate_name" className="auth__label">
                    Corporate Name
                  </label>
                  <input
                    id="fbo_corporate_name"
                    name="fbo_corporate_name"
                    placeholder="Enter Corporate Name"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.fbo_corporate_name?.errorShow && (
                    <span className="auth__error">{state?.fbo_corporate_name?.error}</span>
                  )}
                </div>
              </div>
              <div className="auth__box">
                <div className="auth__field">
                  <label htmlFor="corporate_name" className="auth__label">
                    Corporate Email
                  </label>
                  <input
                    id="fbo_corporate_email"
                    name="fbo_corporate_email"
                    placeholder="Enter Corporate Email"
                    type="text"
                    className="auth__input"
                    onChange={inputHandler}
                  />
                  {state?.fbo_corporate_email?.errorShow && (
                    <span className="auth__error">{state?.fbo_corporate_email?.error}</span>
                  )}
                </div>
              </div>
              <div className="auth__field">
                <label htmlFor="password" className="auth__label">
                  Password
                </label>
                <input
                  placeholder="Enter password"
                  id="password"
                  name="password"
                  type={eye ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={
                    eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
                  }
                  alt="eye"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
                {state?.password?.errorShow && (
                  <span className="auth__error">{state?.password?.error}</span>
                )}
              </div>
              <div className="auth__field">
                <label htmlFor="password_confirmation" className="auth__label">
                  Confirm password
                </label>
                <input
                  placeholder="Confirm password"
                  id="password_confirmation"
                  name="password_confirmation"
                  type={eye ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"}
                  alt="eye"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
                {state?.password_confirmation?.errorShow && (
                  <span className="auth__error">{state?.password_confirmation?.error}</span>
                )}
              </div>
              <button className="auth__submit s" onClick={submitHandler}>Sign Up</button>
              <div className="auth__terms">
                <Link to="/terms">Terms of use</Link>
                <Link to="/terms">Privacy policy</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};


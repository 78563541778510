import React, { useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Loader from "../Loader";
import Input from "../Input";
import {useUpdateBillingMutation, useUpdateCorporateBillingMutation} from "../../store/builder/inspections.api";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import {setLoader} from "../../store/builder/reducer";

const BillingAddressModal = ({ setShowPaymentModal, getUserIndex, isCorporate }) => {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);
  const [state, setState] = useState({
    city: userInfo?.billing_address?.city,
    line1: userInfo?.billing_address?.line1,
    line2: userInfo?.billing_address?.line2,
    state: userInfo?.billing_address?.state,
    country: userInfo?.billing_address?.country,
    postal_code: userInfo?.billing_address?.postal_code,
    type: "billing"
  });
  const [update, { data, error, isLoading, isSuccess, isError }] =
    useUpdateBillingMutation();
  const [updateCorporate, { data: corporateData, error: corporateError, isLoading: corporateIsLoading, isSuccess: corporateIsSuccess, isError: corporateIsError }] = useUpdateCorporateBillingMutation();

  const handleChange = (e) => {
    setState((old) => ({ ...old, [e.target.id]: e.target.value }));
  };

  const updateBillingAddress = () => {
    if (
      state?.city?.length &&
      state?.line1?.length &&
      state?.state?.length &&
      state?.country?.length &&
      state?.postal_code?.length
    ) {
      dispatch(setLoader(true))
      if (isCorporate) {
        updateCorporate(state)
          .then((res) => {
            toast?.success(res.data.message);
            getUserIndex && getUserIndex();
            setShowPaymentModal(false);
            dispatch(setLoader(false))
          });
      } else {
        update(state)
          .then((res) => {
            toast?.success(res.data.message);
            getUserIndex && getUserIndex();
            setShowPaymentModal(false);
            dispatch(setLoader(false))
          });
      }

    } else {
      toast?.error("Fill all fields!");
    }
  };

  return (
    <div className="modal payment__main">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Update billing address</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => setShowPaymentModal(false)}
          />
        </div>
        <div className="modal__content-body user">
          {
            isCorporate ? (
              <>
                <Input
                  onChange={handleChange}
                  label={"Address line 1"}
                  placeholder={"Address line 1"}
                  id="line1"
                  defaultValue={userInfo?.corporate?.billing_address?.line1}
                />
                <Input
                  onChange={handleChange}
                  label={"Address line 2"}
                  placeholder={"Address line 2"}
                  id="line2"
                  defaultValue={userInfo?.corporate?.billing_address?.line2}
                />
                <Input
                  onChange={handleChange}
                  label={"City"}
                  placeholder="City"
                  id="city"
                  defaultValue={userInfo?.corporate?.billing_address?.city}
                />
                <Input
                  onChange={handleChange}
                  label={"State/ Province/ Region"}
                  placeholder={"State/ Province/ Region"}
                  id="state"
                  defaultValue={userInfo?.corporate?.billing_address?.state}
                />
                <Input
                  onChange={handleChange}
                  label={"Country"}
                  placeholder={"Country"}
                  id="country"
                  defaultValue={userInfo?.corporate?.billing_address?.country}
                />
                <Input
                  onChange={handleChange}
                  label={"Postal Code"}
                  placeholder={"Postal Code"}
                  id="postal_code"
                  defaultValue={userInfo?.corporate?.billing_address?.postal_code}
                />
              </>
            ) :
              (
                <>
                  <Input
                    onChange={handleChange}
                    label={"Address line 1"}
                    placeholder={"Address line 1"}
                    id="line1"
                    defaultValue={userInfo?.billing_address?.line1}
                  />
                  <Input
                    onChange={handleChange}
                    label={"Address line 2"}
                    placeholder={"Address line 2"}
                    id="line2"
                    defaultValue={userInfo?.billing_address?.line2}
                  />
                  <Input
                    onChange={handleChange}
                    label={"City"}
                    placeholder="City"
                    id="city"
                    defaultValue={userInfo?.billing_address?.city}
                  />
                  <Input
                    onChange={handleChange}
                    label={"State/ Province/ Region"}
                    placeholder={"State/ Province/ Region"}
                    id="state"
                    defaultValue={userInfo?.billing_address?.state}
                  />
                  <Input
                    onChange={handleChange}
                    label={"Country"}
                    placeholder={"Country"}
                    id="country"
                    defaultValue={userInfo?.billing_address?.country}
                  />
                  <Input
                    onChange={handleChange}
                    label={"Postal Code"}
                    placeholder={"Postal Code"}
                    id="postal_code"
                    defaultValue={userInfo?.billing_address?.postal_code}
                  />
                </>
              )
          }
        </div>
        <div className="modal__content-bottom">
          <button className="close" onClick={() => setShowPaymentModal(false)}>
            Close
          </button>
          <button className="save" onClick={updateBillingAddress}>
            Save
          </button>
        </div>
      </div>
      <div
        className="modal__bg"
      ></div>
    </div>
  );
};

export default BillingAddressModal;

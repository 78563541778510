import React, {useEffect, useState} from "react";
import { validationEmail } from "../../global/validate";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import { setLoader } from "../../store/builder/reducer";
import {setUser} from "../../store/builder/loginSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {useChangePasswordMutation} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {setVerify} from "../../store/builder/forgotPasswordSlice";

export default function NewPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    password: {
      id: "password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
    confirm_password: {
      id: "confirm_password",
      value: null,
      errorShow: false,
      error: "Please enter correct password (min length 5)",
    },
  });
  const token = useSelector(state => state.rootReducer.forgotPasswordSlice.verifyToken);
  const [changePassword, {data, error, isSuccess, isLoading, isError}] = useChangePasswordMutation();
  const [errorHandler, setErrorHandler] = useErrorHandler([error])

  const [eye, setEye] = useState(false);
  const [eyeConfirm, setEyeConfirm] = useState(false);

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success(data.message)
  //     navigate("/login")
  //   }
  // }, [isSuccess])
  //
  // useEffect(() => {
  //   if (isLoading) {
  //     dispatch(setLoader(true))
  //   } else {
  //     dispatch(setLoader(false))
  //   }
  // }, [isLoading])

  const submitHandler = (e) => {
    e.preventDefault();
    if (state?.password?.value?.length > 4) {
      if (state.password.value !== state.confirm_password.value) {
        toast.error('Passwords should be the same!')
      } else {
        // changePassword({verify_token: token, password: state.password.value , password_confirmation: state.confirm_password.value})
        dispatch(setLoader(true))
        let body = {verify_token: token, password: state.password.value , password_confirmation: state.confirm_password.value}
        let config = {
          headers: {
            AppVersion: '1.0.0',
            AppType: 'web',
            DeviceType: 'web'
          },
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}auth/set-password`, body, config)
          .then((res) => {
            toast.success(res.data.message)
            navigate("/login")
            dispatch(setLoader(false));
          })
          .catch((err) => {
            dispatch(setLoader(false));
            toast.error(err.response.data.message);
          });
      }
    } else {
      toast.error('Passwords length should be more than 5 symbols!')
    }

  };

  return (
    <div className="auth">
      <Helmet>
        <title>Create new password | NDX</title>
      </Helmet>
      <div className="auth__wrapper">
        <div className="auth__left">
          <img src="/assets/forgot-image.png" alt="bg" />
        </div>
        <div className="auth__right">
          <div className="auth__content forgot">
            <h1 className="auth__title">Create new password</h1>
            <p className="auth__subtitle">Please enter a new password. Your new password must be different from previous password.</p>
            <form className="auth__form" onSubmit={submitHandler}>
              <div className="auth__field">
                <label htmlFor="password" className="auth__label">
                  Password
                </label>
                <input
                  placeholder="Enter password"
                  id="password"
                  type={eye ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={
                    eye ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
                  }
                  alt="eye"
                  onClick={() => {
                    setEye(!eye);
                  }}
                />
                {state?.password?.errorShow && (
                  <span className="auth__error">{state?.password?.error}</span>
                )}
              </div>

              <div className="auth__field">
                <label htmlFor="password" className="auth__label">
                  Confirm new password
                </label>
                <input
                  placeholder="Enter new password"
                  id="confirm_password"
                  type={eyeConfirm ? "text" : "password"}
                  className="auth__input"
                  onChange={inputHandler}
                />
                <img
                  className="eye"
                  src={
                    eyeConfirm ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
                  }
                  alt="eye"
                  onClick={() => {
                    setEyeConfirm(!eyeConfirm);
                  }}
                />
                {state?.password?.errorShow && (
                  <span className="auth__error">{state?.password?.error}</span>
                )}
              </div>
              <button className="auth__submit">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Input from "../Input";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import {
  useGetSecretKeyMutation,
  usePaymentConfirmMutation, usePaymentCorporateConfirmMutation,
  useUpdatePaymentMutation,
} from "../../store/builder/inspections.api";
import Loader from "../Loader";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../store/builder/reducer";
import { toast } from "react-toastify";
import { AddressElement } from "@stripe/react-stripe-js";
import {useErrorHandler} from "../../global/ErrorsHandler";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const CheckoutForm = ({
                        setShowPaymentModal,
                        getUserIndex,
                        getPaymentInfo,
                        isCorporate,
                      }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    confirmPaymentBack,
    { data, isLoading, isSuccess, isError, error: paymentError },
  ] = usePaymentConfirmMutation();
  const [
    confirmPaymentBackCorporate,
    { data: confirmPaymentBackCorporateData,
      isLoading: confirmPaymentBackCorporateIsLoading,
      isSuccess: confirmPaymentBackCorporateIsSuccess,
      isError: confirmPaymentBackCorporateIsError,
      error: confirmPaymentBackCorporateError },
  ] = usePaymentCorporateConfirmMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setLoader(true));
    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
      })
      .then((res) => {
        dispatch(setLoader(true));
        if (isCorporate) {
          confirmPaymentBackCorporate({
            payment_intent_id: res?.paymentIntent?.id,
          })
            .then((res) => {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000)
            })
            .catch((err) => {
              toast.error(err.response.message);
              dispatch(setLoader(false));
            });
          if (res.error) {
            toast.error(res?.error?.message);
          }
          dispatch(setLoader(false));
        } else {
          confirmPaymentBack({
            payment_intent_id: res?.paymentIntent?.id,
          })
            .then((res) => {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000)
            })
            .catch((err) => {
              toast.error(err.response.message);
              dispatch(setLoader(false));
            });
          if (res.error) {
            toast.error(res?.error?.message);
          }
          dispatch(setLoader(false));
        }

      })
      .catch((err) => {
        toast.error(err?.error?.message);
        dispatch(setLoader(false));
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="SubmitButton" disabled={!stripe}>
        Save
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const AnotherMethodPaymentModal = ({
                              setShowPaymentModal,
                              getUserIndex,
                              getPaymentInfo,
                              isCorporate,
                            }) => {
  const reduxSecretKey = useSelector(state => state?.rootReducer?.paymentSlice?.client_secret)

  const dispatch = useDispatch();

  return (
    <div className="modal payment__main">
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Add payment method</p>
          <img
            className="modal__content-exit"
            src="/assets/icons/close.svg"
            alt="close"
            onClick={() => setShowPaymentModal(false)}
          />
        </div>
        <div className="modal__content-body">
          {reduxSecretKey ? (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: reduxSecretKey }}
            >
              <CheckoutForm
                setShowPaymentModal={setShowPaymentModal}
                getUserIndex={getUserIndex}
                getPaymentInfo={getPaymentInfo}
                isCorporate={isCorporate}
              />
            </Elements>
          ) : (
            <Loader />
          )}
        </div>
        {/*<div className="modal__content-bottom">*/}
        {/*  <button className="save full">*/}
        {/*    Save*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
      <div className="modal__bg"></div>
    </div>
  );
};

export default AnotherMethodPaymentModal;

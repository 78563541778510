import React, {useEffect, useState} from 'react';
import CorporateRegionsModal from "../CorporateRegionsModal";
import Input from "../../Input";
import {useLazyGetAgencyQuery, useLazyRegionDetailsQuery} from "../../../store/builder/inspections.api";
import {useDispatch} from "react-redux";
import {setLoader} from "../../../store/builder/reducer";
import CorporateRegionDetailsRow from "../CorporateRegionDetailsRow";
import {useDebounce} from "../../../pages/LogSheets/hooks";

const CorporateRegionDetails = ({ chosenRegionId, setCurrentRegion, currentRegion, setCurrentTab }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [agenciesList, setAgenciesList] = useState([])
  const [newRegionsModal, setNewRegionsModal] = useState(false);
  const [filteredAgenciesList, setFilteredAgenciesList] = useState([])
  const debouncedSearchValue = useDebounce(search, 500);

  const [getDetails,
    {
      data: detailsData,
      isLoading: detailsIsLoading,
      isError: detailsIsError,
      error: detailsError,
      isSuccess: detailsIsSuccess,
  }]= useLazyRegionDetailsQuery();

  const [
    getAgencyList,
    {
      data: agencyListData,
      isLoading: agencyListIsLoading,
      isError: agencyListIsError,
      error: agencyListError,
      isSuccess: agencyListIsSuccess,
    },
  ] = useLazyGetAgencyQuery();

  const getDetailsRegion = () => {
    getDetails(chosenRegionId)
  }

  useEffect(() => {
    if (detailsIsLoading) {
      dispatch(setLoader(true))
    }
    if (detailsIsSuccess) {
      dispatch(setLoader(false))
    }
    if (detailsData?.data?.agencies?.length > 0) {
      setAgenciesList(detailsData?.data?.agencies)
    }
  }, [detailsIsLoading, detailsIsSuccess, detailsData])

  useEffect(() => {
    if (chosenRegionId) {
      getDetailsRegion()
    }
  }, [chosenRegionId])

  useEffect(() => {
    const filter = agenciesList?.filter((el) => {
      if (debouncedSearchValue === "") {
        return el
      } else {
        return el?.title?.toLowerCase()?.includes(search.toLowerCase());
      }
    })
    setFilteredAgenciesList(filter)
  }, [debouncedSearchValue, agenciesList])

  useEffect(() => {
    getAgencyList();
  }, [])

  return (
    <div className="regions">
      {newRegionsModal &&
        <CorporateRegionsModal
          exit={() => {
            setNewRegionsModal(false)
            setCurrentRegion(null)
          }}
          agencies={agencyListData}
          currentRegion={detailsData?.data}
          setCurrentRegion={setCurrentRegion}
          getRegionsList={getDetailsRegion}
        />
      }
      <div className="metrics__head-back regions" onClick={() => setCurrentTab(5)}>
        <img src="/assets/icons/chevron-left.svg" alt="back"/>
        <span>Back</span>
      </div>
      <p className="profile__title">{detailsData?.data?.name}</p>
      <div className="regions__header">
        <Input
          type="text"
          placeholder="Search by name"
          name="ordersSearch"
          id="ordersSearch"
          image={"/assets/icons/search.svg"}
          maxwidth={204}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <div className="regions__header-btn" onClick={() => {
          setNewRegionsModal(true)
        }}>
          <span>Edit Region</span>
        </div>
      </div>
      <div className="regions__table details">
        <div className="regions__table-header">
          <div className="regions__table-th">FBOs ID</div>
          <div className="regions__table-th">Name</div>
          <div className="regions__table-th">Airport ID</div>
          <div className="regions__table-th">Timezone</div>
          <div className="regions__table-th">Logo</div>
        </div>
        <div className="regions__table-body">
          {
            filteredAgenciesList?.length > 0 ?
            filteredAgenciesList?.map(item => (
              <CorporateRegionDetailsRow key={item?.id} agency={item} />
            ))
              : <div className="facility__table-empty">
                <img
                  src="/assets/icons/facility-empty.svg"
                  alt="empty"
                />
                <p>There are no Regions!</p>
              </div>
          }
        </div>
      </div>
    </div>

  );
};

export default CorporateRegionDetails;
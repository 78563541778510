import React, {useEffect} from 'react';
import './index.scss';
import {useDispatch} from "react-redux";
import {useCorporateUsersListQuery} from "../../../store/builder/inspections.api";
import {setLoader} from "../../../store/builder/reducer";
import {useErrorHandler} from "../../../global/ErrorsHandler";

const CorporateUsers = () => {
  const dispatch = useDispatch();

  const {data: usersData,
    error: usersDataError,
    isLoading: usersDataIsLoading,
    isSuccess: usersDataIsSuccess,
    isError: usersDataIsError,
  } = useCorporateUsersListQuery();

  useEffect(() => {
    if (usersDataIsLoading) {
      dispatch(setLoader(true))
    }
    if (usersDataIsSuccess) {
      dispatch(setLoader(false))
    }
    if (usersDataIsError) {
      dispatch(setLoader(false))
    }
  }, [usersDataIsLoading, usersDataIsSuccess])

  const [errorHandler, setErrorHandler] = useErrorHandler([usersDataError]);

  return (
    <div className="corporate-users">
      <p className="profile__title">Corporate Users</p>
      <div className="corporate-users__table">
        <div className="corporate-users__table-header">
          <div className="corporate-users__table-th">Name</div>
          <div className="corporate-users__table-th">Email</div>
          <div className="corporate-users__table-th">ID</div>
        </div>
        <div className="corporate-users__table-body">
          {
            usersData?.map((user) => (
              <div className="corporate-users__table-row" key={user?.id}>
                <div className="corporate-users__table-bodyTh">
                  <img src={user?.avatar} alt="avatar" />
                  <span>{user?.name ? user?.name : '---'}</span>
                </div>
                <div className="corporate-users__table-bodyTh">{user?.email}</div>
                <div className="corporate-users__table-bodyTh">{user?.id}</div>
              </div>
            ))
          }

        </div>
      </div>
    </div>
  );
};

export default CorporateUsers;
import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import DashboardHeader from "../../components/DashboardHeader";
import Wrapper from "../../components/Wrapper";
import './index.scss';
import {useDispatch} from "react-redux";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {
  useAgencyWaterTestKitTypesQuery,
  useCanAddFacilityMutation,
  useCancelSubscriptionMutation, useGetAgencyQuery,
  useGetTimezonesQuery, useGetUsersRolesQuery,
  useLazyGetAgencyByIdQuery,
  useLazyGetFacilityByAgencyQuery,
  useLazyGetOrdersListFboQuery,
  useLazyGetSubscriptionsFboQuery,
  useLazyGetUsersQuery,
  userLazyGetOrdersListFbo
} from "../../store/builder/inspections.api";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../global/ErrorsHandler";
import moment from "moment/moment";
import ManagementRow from "../../components/ManagementRow";
import Modal from "../../components/Modal";
import Cropper from "react-cropper";
import CustomPaginate from "../../components/PaginateComponent";
import Checkbox from "../../components/Checkbox";


const AgencyDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [dangerShow, setDangerShow] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [currentUser, setCurrentUser] = useState(null)
  const [createModalStatus, setCreateModalStatus] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [imageBlob, setImageBlob] = useState(null)
  const [chosenPhoto, setChosenPhoto] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [cropper, setCropper] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [canAdd, setCanAdd] = useState(false)
  const [getAgency,
    {
      data: agencyData,
      error: agencyError,
      isLoading: agencyIsLoading,
      isSuccess: agencyIsSuccess,
      isError: agencyIsError,
    }] = useLazyGetAgencyByIdQuery();
  const {
    data: userRolesData,
    isSuccess: userRolesIsSuccess,
    isLoading: userRolesIsLoading,
    isError: userRolesIsError,
    error: userRolesError,
  } = useGetUsersRolesQuery();
  const [getFacilityByAgencyId,
    {
      data: getFacilityByAgencyIdIdData,
      error: getFacilityByAgencyIdError,
      isLoading: getFacilityByAgencyIdIsLoading,
      isSuccess: getFacilityByAgencyIdIsSuccess,
      isError: getFacilityByAgencyIdIsError,
    }] = useLazyGetFacilityByAgencyQuery();

  const [cancelSubscription, {
    data: cancelSubscriptionData,
    error: cancelSubscriptionError,
    isFetching: cancelSubscriptionIsLoading,
    isSuccess: cancelSubscriptionIsSuccess,
    isError: cancelSubscriptionIsError
  }] = useCancelSubscriptionMutation();

  const [getUsers, {
    data: getUsersData,
    error: getUsersError,
    isLoading: getUsersIsLoading,
    isSuccess: getUsersIsSuccess,
    isError: getUsersIsError,
  }] = useLazyGetUsersQuery();

  const [getOrders, {
    data: getOrdersData,
    error: getOrdersError,
    isLoading: getOrdersIsLoading,
    isSuccess: getOrdersIsSuccess,
    isError: getOrdersIsError,
  }] = useLazyGetOrdersListFboQuery();

  const [getSubs, {
    data: getSubsData,
    error: getSubsError,
    isLoading: getSubsIsLoading,
    isSuccess: getSubsIsSuccess,
    isError: getSubsIsError,
  }] = useLazyGetSubscriptionsFboQuery();

  const {
    data: timezonesData,
    error: timezonesError,
    isLoading: timezonesIsLoading,
    isSuccess: timezonesIsSuccess,
    isError: timezonesIsError,
  } = useGetTimezonesQuery();
  const {data, isLoading, isSuccess, isError} = useGetAgencyQuery();
  const [canAddAction, {
    data: setCanAddData,
    error: setCanAddError,
    isLoading: setCanAddIsLoading,
    isSuccess: setCanAddIsSuccess,
    isError: setCanAddIsError,
  }] = useCanAddFacilityMutation()
  const [errorHandler, setErrorHandler] = useErrorHandler([
    cancelSubscriptionError,setCanAddError
  ]);
  const {data: waterKitData, error: waterKitError, isLoading: waterKitIsLoading, isSuccess: waterKitIsSuccess, isError: waterKitIsError} = useAgencyWaterTestKitTypesQuery()

  useEffect(() => {
    getAgency(params.id)
  }, [params])

  useEffect(() => {
    getFacilityByAgencyId({id: params.id, page: 1});
  }, [agencyData])

  useEffect(() => {
    if (agencyIsSuccess) {
      setCanAdd(agencyData?.data?.is_able_to_add_facility_trial)
    }
  }, [agencyIsSuccess])

  useEffect(() => {
    if (setCanAddIsSuccess) {
      toast.success("Successfully changed!");
    }
    if (setCanAddIsError) {
      toast.error("Something went wrong");
    }
  }, [setCanAddIsSuccess, setCanAddIsError])

  useEffect(() => {
    if (currentTab == 0) {
      getUsers(params.id)
    }
    if (currentTab == 1) {
      getSubs({id: params.id, page: currentPage})
    }
    if (currentTab == 2) {
      getFacilityByAgencyId({id: params.id, page: currentPage})
    }
    if (currentTab == 3) {
      getOrders({id: params.id, page: currentPage})
    }
  }, [currentTab, currentPage])

  useEffect(() => {
    if (agencyIsLoading || setCanAddIsLoading) {
      dispatch(setLoader(true))
    }
    if (agencyIsSuccess || setCanAddIsSuccess) {
      dispatch(setLoader(false))
    }
    if (agencyIsError || setCanAddError) {
      dispatch(setLoader(false))
    }
  }, [agencyIsLoading, agencyIsSuccess, agencyIsError, setCanAddIsLoading, setCanAddError, setCanAddIsSuccess])

  useEffect(() => {
    if (getUsersIsLoading || getOrdersIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [getUsersIsLoading, getOrdersIsLoading])

  useEffect(() => {
    if (cancelSubscriptionIsSuccess) {
      dispatch(setLoader(false))
    }
    if (cancelSubscriptionIsError) {
      dispatch(setLoader(false))
      setDangerShow(false)
    }
  }, [cancelSubscriptionIsLoading, cancelSubscriptionIsSuccess])

  const changeTab = (num) => {
    setCurrentTab(num)
    setCurrentPage(1)
  }

  function base64ToBlob(base64Data) {
    const contentType = base64Data.substring(5, base64Data.indexOf(";"));
    const byteCharacters = atob(
      base64Data.substring(base64Data.indexOf(",") + 1)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const image = base64ToBlob(cropper.getCroppedCanvas().toDataURL())
      setShowModal(false)
      document.querySelector('.modal-fbo').style.display = 'flex'
      setPhoto(cropper.getCroppedCanvas().toDataURL())
      setImageBlob(image)
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  const handleCanAdd = (e) => {
    setCanAdd(old => ({...old, [e.target.id]: e.target.checked}))
    canAddAction({agency_id: params?.id, is_able_to_add_facility: e.target.checked ? 1 : 0})
      .unwrap()
      .then(() => {
        getAgency(params.id)
      })
  }

  const renderDangerSubscription = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Cancel subscription</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Canceling now will result in irreversible consequences and termination of the subscription!
          </div>
          <div className="modal__content-bottom">
            <button className="save danger third" onClick={() => {
              dispatch(setLoader(true))
              cancelSubscription({id: agencyData?.data?.id, data: {cancel_now: true}})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerShow(false)
                  getAgency(params.id)
                })
            }}>
              Cancel now
            </button>
            <button className="close almost" onClick={() => {
              dispatch(setLoader(true))
              cancelSubscription({id: agencyData?.data?.id, data: {cancel_now: false}})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerShow(false)
                  getAgency(params.id)
                })
            }}>
              Cancel at the end of period
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <Wrapper>
      {dangerShow && renderDangerSubscription()}
      {showModal && (
        <div className="p-modal">
          <div className="p-modal__content">
            <div className="p-modal__header">
              <p></p>
              <h5 className="p-modal__title">Crop image</h5>
              <div className="p-modal__close">
                <img
                  src="/assets/icons/close.svg"
                  alt="close"
                  onClick={() => {
                    setShowModal(false);
                    setChosenPhoto(null);
                    document.querySelector('.modal-fbo').style.display = 'flex'
                  }}
                />
              </div>
            </div>
            <div className="p-modal__cropper">
              <Cropper
                style={{
                  height: 400,
                  width: "100%",
                }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={chosenPhoto}
                aspectRatio={1}
                viewMode={0}
                minCropBoxHeight={300}
                minCropBoxWidth={300}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                minCropBoxWidth={300}
                guides={true}
              />
            </div>
            <div className="p-modal__btns">
              <label
                htmlFor="profile_photo"
                className="profile__imageBox-upload"
              >
                Change photo
              </label>
              <div className="profile__imageBox-upload" onClick={getCropData}>
                Save photo
              </div>
            </div>
          </div>
          <div
            className="p-modal__bg"
            // onClick={() => {
            //   setShowModal(false);
            //   setChosenPhoto(null);
            //   document.querySelector('.modal-fbo').style.display = 'flex'
            // }}
          ></div>
        </div>
      )}
      {createModalStatus && (
        <Modal
          title={"Create FBO"}
          currentItem={agencyData?.data}
          setCurrentItem={setCurrentItem}
          setShowModal={setShowModal}
          setChosenPhoto={setChosenPhoto}
          photo={photo}
          setCreateModalStatus={setCreateModalStatus}
          imageBlob={imageBlob}
          setPhoto={setPhoto}
          type="agancy"
          exit={() => {
            setCreateModalStatus(false)
            setPhoto(null)
            setImageBlob(null)
            setCurrentItem(null)
          }}
          // getAgencies={getAgencies}
          timezones={timezonesData?.data?.map(item => ({value: item, label: item}))}
          waterKitData={waterKitData?.map(item => ({label: item?.name, value: item?.id}))}
        />
      )}
      <Helmet>
        <title>FBO | NDX</title>
      </Helmet>
      <DashboardHeader
        title={"FBO Details"}
        showBtn={false}
        type="agancy-detail"
      />
      <div className="agencyDetails">
        <div className="agencyDetails__info">
          <div className="agencyDetails__info-left">
            <div className="agencyDetails__info-main">
              <img className="agencyDetails__info-logo" src={agencyData?.data?.logo} alt="logo"/>
              <div className="agencyDetails__info-text">
                <p>FBO name: </p>
                <p>{agencyData?.data?.title}</p>
              </div>
            </div>
            <p className="agencyDetails__info-info">
              <span>FBO ID: </span>{agencyData?.data?.id ? agencyData?.data?.id : "---"}</p>
            <p className="agencyDetails__info-info">
              <span>Airport ID: </span>{agencyData?.data?.airport_id ? agencyData?.data?.airport_id : "---"}</p>
            <p className="agencyDetails__info-info">
              <span>Timezone: </span>{agencyData?.data?.timezone ? agencyData?.data?.timezone : "---"}</p>
            <p className="agencyDetails__info-info">
              <span>Safety first: </span>{agencyData?.data?.is_safety_first_certified ? 'Certified' : "Not certified"}</p>
            <p className="agencyDetails__info-info"><span>Subscription: </span>Trial Period
              : {agencyData?.data?.subscription_end_date ? moment(agencyData?.data?.subscription_end_date).format("MM/DD/YY") :
                agencyData?.data?.trial_end_date ? moment(agencyData?.data?.trial_end_date).diff(moment(), 'days') + ' days left' : ' -----'
            }
              {/*<p*/}
              {/*  className={`fbo__renew ${agencyData?.data?.subscription_auto_renew == true ? "on" : 'off'}`}>Auto-renew: {agencyData?.data?.subscription_auto_renew == true ? "ON" : "OFF"}</p>*/}
            </p>
          </div>
          <div className="agencyDetails__info-right">
            {/*{*/}
            {/*  agencyData?.data?.subscription_end_date ?*/}
            {/*    <button className="agencyDetails__info-cancel" onClick={() => {*/}
            {/*      setDangerShow(true)*/}
            {/*    }}>Cancel subscription</button>*/}
            {/*    : null*/}
            {/*}*/}
            <button className="agencyDetails__info-edit" onClick={() => setCreateModalStatus(true)}>Edit</button>
          </div>
        </div>
        <div className="agencyDetails__tabs">
          <div className={`agencyDetails__tab ${currentTab === 0 ? 'active' : ""}`} onClick={() => changeTab(0)}>User
            Management
          </div>
          <div className={`agencyDetails__tab ${currentTab === 1 ? 'active' : ""}`}
               onClick={() => changeTab(1)}>Subscriptions
          </div>
          {
            (agencyData?.data?.trial_status === 'inspection' || agencyData?.data?.trial_status === 'equipment') &&
            <div className={`agencyDetails__tab ${currentTab === 2 ? 'active' : ""}`}
                 onClick={() => setCurrentTab(2)}>Facilities
            </div>
          }
          {/*<div className={`agencyDetails__tab ${currentTab === 3 ? 'active' : ""}`}*/}
          {/*     onClick={() => changeTab(3)}>Orders*/}
          {/*</div>*/}
        </div>
        {
          currentTab === 0 ?
            <div className="agencyDetails__table">
              <div className="management__table-head">
                <div className="management__table-th">ID</div>
                <div className="management__table-th">Name</div>
                <div className="management__table-th">Email</div>
                <div className="management__table-th">FBO</div>
                <div className="management__table-th">Role</div>
                <div className="management__table-th">Ein</div>
                <div className="management__table-th">Initials</div>
                <div className="management__table-th">Signature</div>
                <div className="management__table-th">Active</div>
                <div className="management__table-th"></div>
              </div>
              <div className="agencyDetails__table-body">
              {getUsersData?.map(user => (
                  <ManagementRow
                    key={user.id}
                    user={user}
                    userAgencyData={agencyData}
                    lengthArray={getUsersData?.length}
                    filteredData={getUsersData}
                    setCurrentUser={setCurrentUser}
                    currentUser={currentUser}
                    userRolesData={userRolesData}
                    // readOnly={true}
                  />
                ))}
              </div>
            </div>
            : currentTab === 1 ?
              <div className="agencyDetails__table">
                <div className="profile__history-tableHead">
                  <div className="profile__history-tableTh">Start date</div>
                  <div className="profile__history-tableTh">End date</div>
                  <div className="profile__history-tableTh">Subscription cost</div>
                  <div className="profile__history-tableTh">Invoice/Receipt from Stripe</div>
                </div>
                <div className="agencyDetails__table-body">
                  {getSubsData?.data?.length > 0 ?
                    getSubsData?.data?.map((item) => (
                      <div className="profile__history-tableBodyRow" key={item?.order_id}>
                        <div className="profile__history-tableBodyTh">
                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YY") : "---"}
                        </div>
                        <div className="profile__history-tableBodyTh">
                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YY") : "---"}
                        </div>
                        <div className="profile__history-tableBodyTh">
                          ${item?.price}
                        </div>
                        <div className="profile__history-tableBodyTh">
                          {
                            item?.receipt_url ? (
                              <a
                                href={item?.receipt_url}
                                target="_blank"
                                style={{
                                  borderRadius: '8px',
                                  padding: '8px 27px',
                                  fontWeight: 600,
                                  color: 'black',
                                  background: 'transparent',
                                  border: '1.5px solid #8e8e8e'
                                }}
                              >
                                View
                              </a>
                            ) : <span>--- ---</span>
                          }
                        </div>
                      </div>
                    ))
                    :
                    <div style={{
                      width: '100%',
                      height: '205px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                      }}>
                        <div className="expired__box-img" style={{margin: '0'}}>
                          <img src="/assets/no-data.svg" alt="no-data"/>
                        </div>
                        <p className="no-payment-text">
                          There is no Data!
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
              : currentTab === 2 ?
                <div>
                  {
                    (agencyData?.data?.trial_status === "equipment" || agencyData?.data?.trial_status === "inspection") &&
                    <div className="agencyDetails__block">
                      <div className="facility-modal__field pl-20">
                        <Checkbox
                          onChange={handleCanAdd}
                          label={"Ability to add facilities"}
                          id={"is_able_to_add_facility_trial"}
                          key={"is_able_to_add_facility_trial"}
                          checked={agencyData?.data?.is_able_to_add_facility_trial}
                      />
                    </div>
                  </div>
                }
                <div className="agencyDetails__table">
                  <div className="inspectionsForm__table-head invited">
                    <div
                      style={{
                        width: "50%",
                        paddingRight: "0px",
                      }}
                      className="inspectionsForm__table-th"
                    >
                <span
                  style={{cursor: "pointer"}}
                >
                  Facility name
                </span>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="inspectionsForm__table-th last"
                    >
                    <span
                      style={{cursor: "pointer"}}
                    >
                      Total Inspections
                    </span>
                    </div>
                  </div>
                  <div className="agencyDetails__table-body">
                    {
                      getFacilityByAgencyIdIdData?.data?.length ?
                        getFacilityByAgencyIdIdData?.data?.map(item => {
                          return (
                            <div className="inspectionsForm__table-row row">
                              <div
                                style={{width: "50%", paddingRight: "0px"}}
                                className="inspectionsForm__table-bodyTh"
                              >
                                {item?.name}
                              </div>
                              <div
                                style={{width: "50%", paddingRight: "0px", paddingLeft: 0}}
                                className="inspectionsForm__table-bodyTh last"
                              >
                                {item?.links?.length}
                              </div>
                            </div>
                          )
                        })
                        :
                        <div className="facility__table-empty">
                          <img
                            src="/assets/icons/facility-empty.svg"
                            alt="empty"
                          />
                          <p>There are no Facilities!</p>
                        </div>
                    }

                  </div>
                </div>

              </div>
                :
                currentTab === 3 ?
                  <div className="agencyDetails__table">
                    <div className="inspectionsForm__table-head">
                      <div
                        style={{
                          width: "18%",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  ID
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  Date
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  End Date
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  FBO
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  Status
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: " pointer"}}
                >
                  Last updated
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  Sale Status
                </span>
                      </div>
                      <div
                        style={{
                          width: "18%",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                        className="inspectionsForm__table-th"
                      >
                <span
                  style={{cursor: "pointer"}}
                >
                  Total
                </span>
                      </div>
                    </div>

                    <div className="agencyDetails__table-body">
                      {
                        getOrdersData?.data?.map(item => (
                          <NavLink
                            to={`/orders/${item?.id}`}
                            className="inspectionsForm__table-row"
                            key={item.id}
                            // style={{
                            //   borderColor: ,
                            //   borderWidth: 3,
                            // }}
                          >
                            <div
                              style={{width: "18%", paddingRight: "0px"}}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {item?.id}
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {moment(item?.created_at).format("MM/DD/YY")}
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {item?.subscription_end_date ?
                                moment(item?.subscription_end_date).format("MM/DD/YY")
                                : <span className="status-new">Expired</span>
                              }
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {item?.agency_title}
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              <p className={item?.status}>{item?.status}</p>
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {moment(item?.status_updated_at).format("MM/DD/YY")}
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              {"  "}
                              <span
                                className={`${
                                  item?.post_sale_status === "new"
                                    ? "status-new"
                                    : item?.post_sale_status === "seen"
                                      ? "status-seen"
                                      : item?.post_sale_status === "processing"
                                        ? "status-processing"
                                        : item?.post_sale_status === "canceled"
                                          ? "status-canceled"
                                          : item?.post_sale_status === "delivered"
                                            ? "status-delivered"
                                            : ""
                                }`}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                    {item?.post_sale_status ?? "-"}
                  </span>
                            </div>
                            <div
                              style={{
                                width: "18%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                              className="inspectionsForm__table-bodyTh"
                            >
                              $ {item?.total}
                            </div>
                          </NavLink>
                        ))
                      }
                    </div>
                  </div>
                  : null
        }

      </div>
      {
        currentTab == 3 ?
          <CustomPaginate
            pageCount={getOrdersData?.pages}
            handlePageClick={handlePageClick}
          />
          : currentTab == 2 ?
            <CustomPaginate
              pageCount={getFacilityByAgencyIdIdData?.pages}
              handlePageClick={handlePageClick}
            />
          : currentTab == 1 ?
            <CustomPaginate
              pageCount={getSubsData?.pages}
              handlePageClick={handlePageClick}
            />
            : null
      }
    </Wrapper>
  );
};

export default AgencyDetail;
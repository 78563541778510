import React from 'react';
import './index.scss';

const StatisticBlock = ({positive, width, title, data, period}) => {
  return (
    <div className={`statistic ${width === 'mid' ? 'mid' : width === 'large' ? 'large' : width === 'w-30' ? 'w-30' : ''}`}>
      <div className="statistic__head">
        <p className="statistic__head-title">{title}</p>
      </div>
      <div className="statistic__body">
        <div className="statistic__body-left">
          <p className="statistic__body-count">{data?.value === null ? '---' : data?.value}</p>
          <div className="statistic__body-stats">
            <p className={`statistic__body-text ${positive === true ? 'pos' : positive == 'grey' ? 'grey' : 'neg'}`}>
              {
                Number(data?.percentage) !== 0 &&
                <img src={`/assets/icons/${positive ? 'green-statistic-arrow.svg' : 'red-statistic-arrow.svg'}`} alt="arrow"/>
              }
              <span>
                {Math.round((Number(data?.percentage) + Number.EPSILON) * 100) / 100}%
              </span>
              {
                period === 'week' ? 'from last week' : 'comparison to yesterday'
              }
            </p>
          </div>
        </div>
        <div className="statistic__body-right">
          <img src={`/assets/icons/${Number(data?.percentage) === 0 ? 'grey-bar.svg' : positive ? 'green-bar.svg' : 'red-bar.svg'}`} alt="bar"/>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
import React, {useEffect, useState} from 'react';
import './index.scss';
import moment from "moment/moment";
import Coin from "../../SVG/Coin";
import FboDetails from "../../SVG/FBODetails";
import Plus from "../../SVG/Plus";
import UnAssignIcon from "../../SVG/UnAssignIcon";
import ClearInspection from "../../SVG/ClearInspection";
import RefreshEquipment from "../../SVG/RefreshEquipment";
import Pencil from "../../SVG/Pencil";
import SwitchIcon from "../../SVG/SwitchIcon";
import Trash from "../../SVG/Trash";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  useAgencyDeactivateMutation,
  useAgencySetSubscriptionMutation,
  useCancelSubscriptionMutation,
  useClearInspectionsFboMutation,
  useReactivateFboEquipmentsMutation, useUnAssignFboMutation
} from "../../store/builder/inspections.api";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {setLoader} from "../../store/builder/reducer";
import {toast} from "react-toastify";
import Datepicker from "../Datepicker";
import AssignWithCorporate from "../AssignCorporate";
import AgencyDeleteModal from "../AgencyDeleteModal";
import log from "../../SVG/Log";

const AgencyTableCard = ({agancy,
                           setCurrentItem,
                           setCreateModalStatus,
                           getAgencies,
                           setStartTrialStatus,
                           setList,
                           setEditPrisingModal,
                           currentItem,
                           corporateData,
                           setTurnOffStatus
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actionsStatus, setActionsStatus] = useState(false)
  const [reactivateStatus, setReactivateStatus] = useState(false)
  const [dangerShow, setDangerShow] = useState(false)
  const [dangerClearShow, setDangerClearShow] = useState(false)
  const [assignModal, setAssignModal] = useState(false)
  const [unAssignModal, setUnAssignModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [setSubscStatus, setSetSubscStatus] = useState(false);
  const [deactivateStatus, setDeactivateStatus] = useState(false);

  const [subscribtionDates, setSubscribtionDates] = useState({
    end_date: null,
    start_date: null,
  });
  const userInfo = useSelector((state) => state?.rootReducer?.loginSlice?.user);

  const [cancelSubscription, {
    data: cancelSubscriptionData,
    error: cancelSubscriptionError,
    isFetching: cancelSubscriptionIsLoading,
    isSuccess: cancelSubscriptionIsSuccess,
    isError: cancelSubscriptionIsError
  }] = useCancelSubscriptionMutation();
  const [clearInspection, {
    data: clearInspectionData,
    error: clearInspectionError,
    isFetching: clearInspectionIsLoading,
    isSuccess: clearInspectionIsSuccess,
    isError: clearInspectionIsError
  }] = useClearInspectionsFboMutation();
  const [reactivate, {
    data: reactivateData,
    error: reactivateError,
    isFetching: reactivateIsLoading,
    isSuccess: reactivateIsSuccess,
    isError: reactivateIsError
  }] = useReactivateFboEquipmentsMutation();
  const [unAssignAction, {
    data: unAssignActionData,
    error: unAssignActionError,
    isFetching: unAssignActionIsLoading,
    isSuccess: unAssignActionIsSuccess,
    isError: unAssignActionIsError
  }] = useUnAssignFboMutation();

  const [setSubscription, {
    data: setSubscriptionData,
    error: setSubscriptionError,
    isFetching: setSubscriptionIsLoading,
    isSuccess: setSubscriptionIsSuccess,
    isError: setSubscriptionIsError,
  }] = useAgencySetSubscriptionMutation();

  const [deactivate, {
    data: deactivateData,
    error: deactivateError,
    isFetching: deactivateIsLoading,
    isSuccess: deactivateIsSuccess,
    isError: deactivateIsError
  }] = useAgencyDeactivateMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([
    cancelSubscriptionError,
    clearInspectionError,
    reactivateError,
    unAssignActionError,
    setSubscriptionError,
    deactivateError,
  ]);

  useEffect(() => {
    if (dangerShow || dangerClearShow || reactivateStatus || setSubscStatus || deactivateStatus
      || assignModal || unAssignModal || deleteModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dangerShow,
    dangerClearShow,
    reactivateStatus,
    setSubscStatus,
    deactivateStatus,
    assignModal,
    unAssignModal,
    deleteModal]);

  useEffect(() => {
    return window.addEventListener("click", (e) => {
      if (e.target.closest(".agencyCard__action") === null && e.target.closest(".management__table-settings") === null) {
        setList(old => {
          let data = [...old]
          data?.map(item => {
            item.action_status = false
          })
          return data
        })
      }
    });
  }, [location]);

  useEffect(() => {
    if (cancelSubscriptionIsLoading) {
      dispatch(setLoader(true))
    }
    if (cancelSubscriptionIsSuccess) {
      dispatch(setLoader(false))
    }
    if (cancelSubscriptionIsError) {
      dispatch(setLoader(false))
    }
  }, [cancelSubscriptionIsLoading, cancelSubscriptionIsSuccess, cancelSubscriptionIsError])

  useEffect(() => {
    setSubscribtionDates(old => ({...old, start_date: currentItem?.subscription_start_date, end_date: currentItem?.subscription_end_date}))
  }, [currentItem])

  const handleSubscribtionSubmit = (e) => {
    e.preventDefault();
    if (subscribtionDates.start_date) {
      dispatch(setLoader(true))
      setSubscription({
        agency_id: agancy?.id?.toString(),
        start_date: moment(subscribtionDates.start_date).format('YYYY-MM-DD'),
        end_date: moment(subscribtionDates.end_date).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          toast.success(res.message);
          setSetSubscStatus(false);
          dispatch(setLoader(false))
          getAgencies();
          setSubscribtionDates({
            end_date: null,
            start_date: null,
          });
        })
        .catch(err => {
          setSetSubscStatus(false);
          dispatch(setLoader(false))
        });
    } else {
      toast.error('Please select start date');
    }
  }

  const renderDangerSubscription = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Cancel subscription</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Canceling now will result in irreversible consequences and termination of the subscription!
          </div>
          <div className="modal__content-bottom">
            <button className="save danger third" onClick={() => {
              dispatch(setLoader(true))
              cancelSubscription({id: agancy?.id, data: {cancel_now: true}})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerShow(false)
                  getAgencies()
                })
            }}>
              Cancel now
            </button>
            <button className="close almost" onClick={() => {
              dispatch(setLoader(true))
              cancelSubscription({id: agancy?.id, data: {cancel_now: false}})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerShow(false)
                  getAgencies()
                })
            }}>
              Cancel at the end of period
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setDangerShow(false)}></div>
      </div>
    )
  }

  const renderDangerClear = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to clear all inspections?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDangerClearShow(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            If you click "Clear", all inspections for this FBO will be deleted permanently.
            This includes all historical inspections.
            This action cannot be undone, so please make sure that you want to delete all inspections before proceeding.
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              clearInspection({confirmed: true, agency_id: agancy?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setDangerClearShow(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setDangerClearShow(false)
                })
            }}>
              Clear
            </button>
            <button className="close almost" onClick={() => {
              setDangerClearShow(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setDangerClearShow(false)}></div>
      </div>
    )
  }

  const renderReactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to reactivate equipment?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setReactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Reactivate" will reactivate on the equipment and make it available for further use. Are you sure
            you want to reactive the equipment?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              reactivate({confirmed: true, agency_id: agancy?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("Equipments successfully reactivated")
                  setReactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setReactivateStatus(false)
                })
            }}>
              Reactivate
            </button>
            <button className="close almost" onClick={() => {
              setReactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setReactivateStatus(false)}></div>
      </div>
    )
  }

  const renderUnAssignModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Unassign</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setUnAssignModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure want to unassign?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setUnAssignModal(false)
            }}>
              Cancel
            </button>
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              unAssignAction({agency_id: agancy?.id})
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success(res.data.message)
                  setUnAssignModal(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setUnAssignModal(false)
                })
            }}>
              Unassign
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setUnAssignModal(false)}></div>
      </div>
    )
  }

  const renderSubscriptionDates = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>
              {
                agancy?.subscription_start_date
                  ? "Edit subscription dates"
                  : "Set subscription dates"
              }
            </p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setSetSubscStatus(false)}
            />
          </div>
          <div className="modal__content-body">
            <div className="modal__content-subscriptionDates">
              <div>
                <p className="trial__modal-title">Start date</p>
                <Datepicker
                  width={195}
                  setValue={(e) => setSubscribtionDates(old => ({...old, start_date: moment(e).format('YYYY-MM-DD')}))}
                  value={subscribtionDates?.start_date !== "Invalid date" ? subscribtionDates?.start_date : null}
                />
              </div>
              <div className="divider"></div>
              <div>
                <p className="trial__modal-title">End date</p>
                <Datepicker
                  width={195}
                  setValue={(e) => setSubscribtionDates(old => ({...old, end_date: moment(e).format('YYYY-MM-DD')}))}
                  value={subscribtionDates?.end_date !== "Invalid date" ? subscribtionDates?.end_date : null}
                />
              </div>
            </div>
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => {
              setSetSubscStatus(false);
            }}>
              Close
            </button>
            <button className="save" onClick={handleSubscribtionSubmit}>
              Confirm
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => {
          setSetSubscStatus(false)
        }}></div>
      </div>

    )
  }

  const renderDeactivate = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p>Are you sure you want to deactivate this FBO?</p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setDeactivateStatus(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Clicking "Deactivate" will deactivate this FBO and make it unavailable for further use. Are you sure you
            want to deactivate?
          </div>
          <div className="modal__content-bottom">
            <button className="save danger " onClick={() => {
              dispatch(setLoader(true))
              deactivate(agancy?.id)
                .unwrap()
                .then(res => {
                  dispatch(setLoader(false))
                  toast.success("FBO successfully deactivated")
                  setDeactivateStatus(false)
                  getAgencies()
                })
                .catch(err => {
                  dispatch(setLoader(false))
                  setReactivateStatus(false)
                })
            }}>
              Deactivate
            </button>
            <button className="close almost" onClick={() => {
              setDeactivateStatus(false)
            }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="modal__bg" onClick={() => setDeactivateStatus(false)}></div>
      </div>
    )
  }

  return (
    <div className="agencyCard" key={agancy?.id}>
      {dangerShow && renderDangerSubscription()}
      {dangerClearShow && renderDangerClear()}
      {reactivateStatus && renderReactivate()}
      {setSubscStatus && renderSubscriptionDates()}
      {deactivateStatus && renderDeactivate()}
      {assignModal &&
        <AssignWithCorporate exit={() => setAssignModal(false)} getAgencies={getAgencies} currentItem={currentItem}
                             corporateData={corporateData}/>}
      {unAssignModal && renderUnAssignModal()}
      {deleteModal &&
        <AgencyDeleteModal exit={() => setDeleteModal(false)} currentFBO={currentItem} getAgencies={getAgencies}/>}
      {
        agancy?.action_status && (
          <div className="management__table-settings fbo">
            <div className="management__table-edit" onClick={() => {
              setCurrentItem(agancy)
              setSetSubscStatus(true)
            }}>
              <Coin color={"#999999"}/>
              <span>
                {
                  agancy?.subscription_start_date
                    ? "Edit subscription dates"
                    : "Set subscription dates"
                }
              </span>
            </div>
            <div className="management__table-edit details" onClick={() => {
              setActionsStatus(false)
              navigate(`/fbo/${agancy.id}`)
            }}>
              <FboDetails/>
              <span>FBO Details</span>
            </div>

            <div className="management__table-edit details" onClick={() => {
              setCurrentItem(agancy)
              setAssignModal(true)
            }}>
              <Plus color={"#999999"}/>
              <span>{agancy?.is_assigned_to_corporate ? "Reassign with Corporate" : "Assign with Corporate"}</span>
            </div>
            {
              agancy?.is_assigned_to_corporate &&
              <div className="management__table-edit details" onClick={() => {
                setCurrentItem(agancy)
                setUnAssignModal(true)
              }}>
                <UnAssignIcon color={"#999999"}/>
                <span>Unassign</span>
              </div>
            }

            {
              agancy?.can_be_cleared &&
              <div className="management__table-edit details" onClick={() => {
                setDangerClearShow(true)
                setActionsStatus(false)
              }}>
                <ClearInspection color="#999999"/>
                <span>Clear inspection</span>
              </div>
            }

            <div className="management__table-edit details" onClick={() => {
              setReactivateStatus(true)
              setActionsStatus(false)
            }}>
              <RefreshEquipment color={"#999999"}/>
              <span>Reactivate Equipment</span>
            </div>
            <div className="management__table-edit details" onClick={() => {
              setCurrentItem(agancy);
              setCreateModalStatus(true);
              setActionsStatus(false);
            }}>
              <Pencil color={"#999999"}/>
              <span>Edit FBO</span>
            </div>
            <div className="management__table-edit" onClick={() => {
              if (agancy?.inspection_enabled) {
                setTurnOffStatus(true)
                setCurrentItem(agancy);
              } else {
                setStartTrialStatus(true);
                setCurrentItem(agancy);
              }
            }}>
              <SwitchIcon color={'#999999'}/>
              <span>Turn {agancy?.inspection_enabled ? "OFF" : "ON"} inspections</span>
            </div>
            <div className="management__table-edit" onClick={() => setDeactivateStatus(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.92993 4.92993L19.0699 19.0699" stroke="#999999" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
              <span>Deactivate</span>
            </div>
            <div className="management__table-edit" onClick={() => {
              setCurrentItem(agancy);
              setDeleteModal(true)
            }}>
              <Trash color={"#DA6767"}/>
              <span>Delete FBO</span>
            </div>
          </div>
        )
      }
      <div className="agencyCard__left">
        <div className="agencyCard__left-agency">
          <div className="agencyCard__left-photo">
            <img className="agencyCard__left-logo" src={agancy.logo} alt="logo"/>
          </div>
          <p className="agencyCard__left-id">#{agancy?.id}</p>
        </div>
        <div className="agencyCard__left-info">
          <div className="agencyCard__left-text">
            <p className="agencyCard__left-name">{agancy?.title}</p>
            <p className="agencyCard__left-airport">
              Airport ID: <span>{agancy?.airport_id}</span>
            </p>
            <p className="agencyCard__left-airport">
              Corporate: <span>{agancy?.corporate ? agancy?.corporate : 'No corporate'}</span>
            </p>
          </div>

        </div>
      </div>
      <div className="agencyCard__mid border">
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Inspection status:</p>
          <span className={`agencyCard__mid-status ${agancy?.inspection_enabled ? 'on' : 'off'}`}>{agancy?.inspection_enabled ? 'on' : 'off'}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Inspections start date:</p>
          <span className="agencyCard__mid-subtext">{agancy?.inspection_start_date ? moment(agancy?.inspection_start_date).format('D MMM YYYY') : '---'}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Subscription:</p>
          <span className="agencyCard__mid-subtext">{agancy?.subscription_start_date ? moment(agancy?.subscription_start_date).format('MM/DD/YYYY') : '-'} - {agancy?.subscription_end_date ? moment(agancy?.subscription_end_date).format('MM/DD/YYYY') : '-'}</span>
        </div>
      </div>
      <div className="agencyCard__mid">
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Timezone:</p>
          <span className="agencyCard__mid-subtext">{agancy?.timezone ? agancy?.timezone : "---"}</span>
        </div>
        <div className="agencyCard__mid-block">
          <p className="agencyCard__mid-text">Facilities enabled:</p>
          <span className="agencyCard__mid-subtext">{agancy?.facilities_enabled ? agancy?.facilities_enabled : "---"}</span>
        </div>
      </div>
      <div className="agencyCard__action">
        <img className="agencyCard__action-btn" src="/assets/icons/dots.svg" alt="dots"
             onClick={() => {
               setList(old => {
                 let data = [...old]
                 data?.map(item => {
                   if (item?.id === agancy?.id) {
                     if (item.action_status) {
                       item.action_status = false
                     } else {
                       item.action_status = true
                     }
                   } else {
                     item.action_status = false
                   }
                 })
                 return data
               })
             }}
        />
      </div>
    </div>
  );
};

export default AgencyTableCard;
import React, {useEffect, useState} from 'react';
import CustomPieChart from "../../Charts/CustomPieChart";

const CorporatePersonalStats = ({data, setChosenUsers, setUsersModal}) => {
  const [colors, setColors] = useState([
    "#548CC5",
    "#2AA58D",
    "#DF9800",
    "#E9EAEB",
    "#592AA5",
    "#EC7049"
  ])

  const [pieData, setPieData] = useState([])
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (data?.chart) {
      let count = 0;
      let newPieData = [...data?.chart?.map(item => ({...item, value: item?.count}))]
      data?.chart?.map(item => {
        count += Number(item.count);
      })

      for (let i = 0; i <= newPieData.length; i++) {
        for (let j = 0; j <= colors.length; j++) {
          if (i === j) {
            newPieData[i] = {...newPieData[i], color: colors[j]}
          }
        }
      }
      setPieData(newPieData)
      setTotalCount(count)
    }
  }, [data]);

  return (
    <div className="chart mid-dashboard mid">
      <div className="chart__header">
        <p className="chart__header-title">Personnel Stats</p>
      </div>
      {
        pieData?.filter(item => item?.key)?.length > 0 ?
          <div className="chart__box corporate-stats" style={{ height: '90%'}}>
            <CustomPieChart width={'mx-200'} level={'lower-more'} data={pieData} total={totalCount} persentage={true} height={'180px'} />
            <div className="corporate__stats">
              {
                pieData?.filter(el => {
                  if (el.key) {
                    return el
                  }
                })?.map((item) => (
                  <div className="corporate__stats-row" onClick={() => {
                    setUsersModal(true)
                    setChosenUsers(item)
                  }}>
                    <div className="corporate__stats-rowLeft">
                      <div className="corporate__stats-dot" style={{backgroundColor: item?.color}}></div>
                      <p className="corporate__stats-role">{item?.key}</p>
                    </div>
                    <div className="corporate__stats-rowRight">
                      {
                        item?.users?.map((user, index) => {
                          if (index <= 2) {
                            return (
                              <div className="corporate__stats-avatar">
                                <img src={user?.avatar} alt="avatar"/>
                              </div>
                            )
                          }
                        })
                      }
                      {
                        item?.users?.length > 4 ?
                          <div className="corporate__stats-avatar">
                            {"+" + (item?.users?.length - 3)}
                          </div>
                          : null
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          :
          <div style={{width: '100%', height: '303px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <div className="expired__box-img" style={{margin: '0'}}>
                <img src="/assets/no-data.svg" alt="no-data"/>
              </div>
              <p className="no-payment-text">
                There is no Data!
              </p>
            </div>
          </div>

      }
    </div>
  );
};

export default CorporatePersonalStats;
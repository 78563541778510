import React, {useEffect, useRef, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import DashboardHeader from "../../components/DashboardHeader";
import {Helmet} from "react-helmet";
import './index.scss';
import Input from "../../components/Input";
import Select from "react-select";
import ToolsRow from "../../components/ToolsComponents/ToolsRow";
import CustomPaginate from "../../components/PaginateComponent";
import {
  useCreateEquipmentMutation,
  useEquipmentActivateMutation,
  useEquipmentDecommissionMutation, useGetReportsQuery,
  useLazyGetCalibrationsQuery,
  useLazyGetEquipmentQuery,
  useLazyGetFacilityQuery, useLazyGetReportHistoryQuery, useLazyGetReportsQuery,
  useLazyGetToolsQuery
} from "../../store/builder/inspections.api";
import {useDebounce} from "../LogSheets/hooks";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {setLoader} from "../../store/builder/reducer";
import Datepicker from "../../components/Datepicker";
import moment from "moment/moment";
import {useErrorHandler} from "../../global/ErrorsHandler";
import ReportHistory from "../../components/ToolsComponents/CalibrationHistory";
import {toast} from "react-toastify";

const ToolsManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentFbo = localStorage.getItem("current-fbo");
  const [currentTab, setCurrentTab] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportModal, setReportModal] = useState(false);
  const [activeReport, setActiveReport] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [currentTool, setCurrentTool] = useState(null);
  const [facilityOptions, setFacilityOptions] = useState([])
  const [equipmentOptions, setEquipmentOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([
    { label: 'All', value: 'all' },
    { label: 'New', value: 'new' },
    { label: 'Completed', value: 'completed' },
  ])
  const [confirmModal, setConfirmModal] = useState(false)
  const [currentId,setCurrentId] = useState(null)

  const [search, setSearch] = useState('');
  const searchRef = useRef();
  const [toolsFilters, setToolsFilters] = useState({
    facility_id: null,
  })
  const [calibrationFilters, setCalibrationFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
    equipment_type: null,
  })
  const [verificationFilters, setVerificationFilters] = useState({
    date_from: null,
    date_to: null,
    facility_id: null,
    equipment_id: null,
    equipment_type: null,
  })
  const debouncedSearchValue = useDebounce(search, 500);

  const [getTools, {data: toolsData, error: toolsError, isError: toolsIsError, isSuccess: toolsIsSuccess, isFetching: toolsIsLoading}] = useLazyGetToolsQuery();
  const [getCalibrations, {data: calibrationsData, error: calibrationsError, isError: calibrationsIsError, isSuccess: calibrationsIsSuccess, isFetching: calibrationsIsLoading}] = useLazyGetCalibrationsQuery();

  const [getFacility,
    {
      data: facilityData,
      isError: facilityIsError,
      error: facilityError,
      isSuccess: facilityIsSuccess,
      isFetching: facilityIsLoading,
    },] = useLazyGetFacilityQuery();

  const [getEquipment,
    {
      data: equipmentData,
      isError: equipmentIsError,
      isSuccess: equipmentIsSuccess,
      isFetching: equipmentIsLoading,
      error: equipmentError
    }] = useLazyGetEquipmentQuery();

  const [
    decommission,
    {
      data: decommissionData,
      isLoading: decommissionIsLoading,
      error: decommissionError,
    },
  ] = useEquipmentDecommissionMutation();

  const [
    activate,
    { data: activateData, isLoading: activateIsLoading, error: activateError },
  ] = useEquipmentActivateMutation();

  const [
    getReports,
    { data: reportsData, isLoading: reportsLoading, error: reportsError }
  ] = useLazyGetReportsQuery();
  const [
    getReportHistory,
    { data: reportHistoryData, isLoading: reportHistoryLoading, error: reportHistoryError }
  ] = useLazyGetReportHistoryQuery();

  const [
    createEquipment,
    { isFetching: createEquipmentIsLoading, error: createEquipmentError, isError: createEquipmentIsError, isSuccess: createEquipmentIsSuccess },
  ] = useCreateEquipmentMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([decommissionError]);


  useEffect(() => {
    getFacility();
    getEquipment({active: true, equipment_type_id: '44'});
  }, []);

  useEffect(() => {
    if (currentTab === 1) {
      getTools({page: currentPage, search: debouncedSearchValue, facility_id: toolsFilters?.facility_id, active: true})
    }
    if (currentTab === 4) {
      getTools({page: currentPage, search: debouncedSearchValue, facility_id: toolsFilters?.facility_id, active: false})
    }
    if (currentTab === 2) {
      getCalibrations({agency_id: currentFbo, inspection_type: 'calibration', search: debouncedSearchValue, date_from: calibrationFilters?.date_from, date_to: calibrationFilters?.date_to, equipment_id: calibrationFilters?.equipment_id, is_complete: calibrationFilters?.equipment_type})
    }
    if (currentTab === 3) {
      getCalibrations({agency_id: currentFbo, inspection_type: 'verification', search: debouncedSearchValue, date_from: verificationFilters?.date_from, date_to: verificationFilters?.date_to, equipment_id: verificationFilters?.equipment_id, is_complete: verificationFilters?.equipment_type})
    }
    if (currentTab === 5) {
      getReports({ agency_id: currentFbo })
    }
  }, [currentPage, currentTab, debouncedSearchValue, toolsFilters, calibrationFilters, verificationFilters])

  useEffect(() => {
    if (toolsIsLoading || calibrationsIsLoading || decommissionIsLoading || activateIsLoading || reportsLoading || reportHistoryLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [toolsIsLoading, calibrationsIsLoading, decommissionIsLoading, activateIsLoading, reportsLoading, reportHistoryLoading])

  useEffect(() => {
    if (facilityIsSuccess) {
      setFacilityOptions(facilityData?.map((item) => ({
        value: item.id,
        label: item.name,
      })))
    }
    if (equipmentIsSuccess) {
      setEquipmentOptions(equipmentData?.data?.map((item) => ({
        value: item.id,
        label: item.title,
      })))
    }
  }, [facilityIsSuccess, equipmentIsSuccess])

  const handleSearch = (e) => {
    setSearch(e.target.value);
  }

  const changeTab = (tabId) => {
    setCurrentTab(tabId);
    setSearch('');
    searchRef.current.value = '';
    setCurrentPage(1);
    setCalibrationFilters({
      date_from: null,
      date_to: null,
      facility_id: null,
      equipment_id: null,
    })
    setToolsFilters({facility_id: null})
  };

  const handlePageClick = (page) => {
    setCurrentPage(page.selected + 1);
    window.scrollTo(0, 0);
  };

  const decommissionEquipment = () => {
    decommission(currentId)
      .then((res) => {
        getTools({page: currentPage, search: debouncedSearchValue, facility_id: toolsFilters?.facility_id, active: true})
        setConfirmModal(false)
      })
  }

  const openReportModal = async (title, item) => {
    setReportTitle(title);
    setReportModal(true);
    dispatch(setLoader(true))
    await getReportHistory({equipment_id: item?.equipment_id}).unwrap()
      .then(resp => {
        setActiveReport(resp?.[0]);
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
      .finally(() => {
        dispatch(setLoader(false))
      })
  }

  const renderConfirmModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__content-head">
            <p></p>
            <img
              className="modal__content-exit"
              src="/assets/icons/close.svg"
              alt="close"
              onClick={() => setConfirmModal(false)}
            />
          </div>
          <div className="modal__content-body" style={{display: 'flex', justifyContent: 'center'}}>
            Are you sure you want to decommission this equipment?
          </div>
          <div className="modal__content-bottom">
            <button className="close" onClick={() => setConfirmModal(false)}>
              Close
            </button>
            <button className="save" onClick={() => decommissionEquipment()}>
              Yes
            </button>
          </div>
        </div>
        <div className="modal__bg"></div>
      </div>
    )
  }

  return (
    <Wrapper>
      <DashboardHeader type={"tools"} title={"Tools Management"} action={() => navigate('/tools/create')} />
      {confirmModal && renderConfirmModal()}
      {reportModal && (
        <ReportHistory
          exit={() => setReportModal(false)}
          data={activeReport}
          title={reportTitle}
        />
      )}
      <Helmet>
        <title>Tools Management | NDX</title>
      </Helmet>
      <div className='tools'>
        <div className="tools__header">
          <div
            className={`tools__header-tab ${currentTab == 5 ? "active" : ""}`}
            onClick={() => {
              changeTab(5)
            }}
          >
            Reports
          </div>
          <div
            className={`tools__header-tab ${currentTab == 1 ? "active" : ""}`}
            onClick={() => changeTab(1)}
          >
            Tools
          </div>
          <div
            className={`tools__header-tab ${currentTab == 4 ? "active" : ""}`}
            onClick={() => changeTab(4)}
          >
            Tools Decommissioned
          </div>
          <div
            className={`tools__header-tab ${currentTab == 2 ? "active" : ""}`}
            onClick={() => {
              changeTab(2)
            }}
          >
            Calibration
          </div>
          <div
            className={`tools__header-tab ${currentTab == 3 ? "active" : ""}`}
            onClick={() => {
              changeTab(3)
            }}
          >
            Verification
          </div>
        </div>
        <div className={`tools__filters ${currentTab === 5 ? 'reports' : ''}`}>
          {
            currentTab == 1 || currentTab == 4 ?
              <>
                <Input
                  innerRef={searchRef}
                  value={search}
                placeholder="Search by ID"
                image={"assets/icons/search.svg"}
                maxwidth={200}
                onChange={handleSearch}
              />
              <Select styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                  borderRadius: 8,
                  height: 42,
                  backgroundColor: '#F4F5F6',
                  marginTop: 8,
                  width: 200,
                }),
              }}
                      isClearable
                      options={facilityOptions}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setToolsFilters((old) => ({...old, facility_id: e?.value}))
                      }}
                      placeholder="Facility"
              />
            </> : currentTab == 2 ?
                <>
                  <Input
                    innerRef={searchRef}
                    value={search}
                    placeholder="Search by description"
                    image={"assets/icons/search.svg"}
                    maxwidth={200}
                    onChange={handleSearch}
                  />
                  <Datepicker
                    type={'from'}
                    setValue={(e) => setCalibrationFilters(old => ({...old, date_from: moment(e)?.format('YYYY-MM-DD')}))}
                    selected={calibrationFilters?.date_from}
                  />
                  <Datepicker
                    type={'to'}
                    setValue={(e) => setCalibrationFilters(old => ({...old, date_to: moment(e)?.format('YYYY-MM-DD')}))}
                    selected={calibrationFilters?.date_to}
                  />
                  <Select styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                      borderRadius: 8,
                      width: 200,
                      height: 42,
                      backgroundColor: '#F4F5F6'
                    }),
                  }} isClearable
                      key={JSON.stringify(equipmentOptions) + "1"}
                      placeholder={'Equipment'}
                      options={equipmentOptions}
                      defaultValue={equipmentOptions?.filter(item => item?.value == calibrationFilters?.equipment_id)[0]}
                      onChange={(e) => {setCalibrationFilters(old => ({...old, equipment_id: e?.value}))}}
                  />
                  <Select styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                      borderRadius: 8,
                      width: 200,
                      height: 42,
                      backgroundColor: '#F4F5F6'
                    }),
                  }}

                          key={JSON.stringify(typeOptions) + "1"}
                          placeholder={'Type'}
                          options={typeOptions}
                          defaultValue={typeOptions[0]}
                          onChange={(e) => {setCalibrationFilters(old => ({...old, equipment_type: e?.value}))}}
                  />
                </>
                    : currentTab == 3 ?
                        <>
                          <Input
                            innerRef={searchRef}
                            value={search}
                            placeholder="Search by description"
                            image={"assets/icons/search.svg"}
                            maxwidth={200}
                            onChange={handleSearch}
                          />
                          <Datepicker
                            type={'from'}
                            setValue={(e) => setVerificationFilters(old => ({...old, date_from: moment(e)?.format('YYYY-MM-DD')}))}
                            selected={verificationFilters?.date_from}
                          />
                          <Datepicker
                            type={'to'}
                            setValue={(e) => setVerificationFilters(old => ({...old, date_to: moment(e)?.format('YYYY-MM-DD')}))}
                            selected={verificationFilters?.date_to}
                          />
                          <Select styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                              borderRadius: 8,
                              width: 200,
                              height: 42,
                              backgroundColor: '#F4F5F6'
                            }),
                          }} isClearable

                                  key={JSON.stringify(equipmentOptions) + "1"}
                                  placeholder={'Equipment'}
                                  options={equipmentOptions}
                                  defaultValue={equipmentOptions?.filter(item => item?.value == verificationFilters?.equipment_id)[0]}
                                  onChange={(e) => {setVerificationFilters(old => ({...old, equipment_id: e?.value}))}}
                          />
                          <Select styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? '#E9EAEB' : '#E9EAEB',
                              borderRadius: 8,
                              width: 200,
                              height: 42,
                              backgroundColor: '#F4F5F6'
                            }),
                          }}

                                  key={JSON.stringify(typeOptions) + "1"}
                                  placeholder={'Type'}
                                  options={typeOptions}
                                  defaultValue={typeOptions[0]}
                                  onChange={(e) => {setVerificationFilters(old => ({...old, equipment_type: e?.value}))}}
                          />
            </>
                        : null
          }
        </div>
        <div className="tools__box">
          <div className="tools__table">
            <div className={`tools__table-head ${currentTab == 1 ? 'tool' : currentTab == 2 ? 'calibration' : currentTab == 3 ? 'verification' : currentTab == 4 ? 'decommission' : ''}`}>
              {
                currentTab == 1 ?
                  <>
                    <div className="tools__table-th">ID</div>
                    <div className="tools__table-th">Tool Description</div>
                    <div className="tools__table-th">Calibration date</div>
                    <div className="tools__table-th">Action</div>
                  </> :
                  currentTab == 4 ?
                    <>
                      <div className="tools__table-th">ID</div>
                      <div className="tools__table-th">Tool Description</div>
                      <div className="tools__table-th">Calibration date</div>
                      <div className="tools__table-th">Action</div>
                    </> :
                    currentTab === 2 ?
                      <>
                        <div className="tools__table-th">ID</div>
                        <div className="tools__table-th">Tool Description</div>
                        <div className="tools__table-th">Date of Calibration</div>
                        <div className="tools__table-th">Calibrated by</div>
                        <div className="tools__table-th">Calibration Due Date</div>
                        <div className="tools__table-th">Comment</div>
                        <div className="tools__table-th">Status</div>
                      </>
                      : currentTab === 3 ?
                        <>
                          <div className="tools__table-th">ID</div>
                          <div className="tools__table-th">Tool Description</div>
                          <div className="tools__table-th">Date of Verification</div>
                          <div className="tools__table-th">Verified by</div>
                          <div className="tools__table-th">Verification Due Date</div>
                          <div className="tools__table-th">Comment</div>
                          <div className="tools__table-th">Status</div>
                        </>
                        : null
              }
            </div>
            <div className={`tools__table-body ${currentTab == 1 ? 'full' : ''}`}>
              {
                currentTab === 1 || currentTab === 4 ?
                toolsData?.data?.length > 0 ?
                  toolsData?.data?.map((tool, index) => (
                    <ToolsRow
                      setCurrentId={setCurrentId}
                      setConfirmModal={setConfirmModal}
                      activate={activate}
                      tool={tool}
                      currentTool={currentTool}
                      setCurrentTool={setCurrentTool}
                      currentTab={currentTab}
                      key={index}
                      getToolsDecom={() => getTools({page: currentPage, search: debouncedSearchValue, facility_id: toolsFilters?.facility_id, active: false})}
                    />
                  )) :
                  <div style={{
                    width: '100%',
                    height: '205px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 100,
                    paddingBottom: 100,
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}>
                      <div className="expired__box-img" style={{margin: '0'}}>
                        <img src="/assets/no-data.svg" alt="no-data"/>
                      </div>
                      <p className="no-payment-text">
                        There is no Data!
                      </p>
                    </div>
                  </div>
                  :
                  currentTab === 2 || currentTab === 3 ?
                    calibrationsData?.data?.length > 0 ?
                      calibrationsData?.data?.map((tool, index) => (
                        <ToolsRow tool={tool} currentTool={currentTool} setCurrentTool={setCurrentTool} currentTab={currentTab} key={index}/>
                      )) :
                      <div style={{
                        width: '100%',
                        height: '205px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 100,
                        paddingBottom: 100,
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}>
                          <div className="expired__box-img" style={{margin: '0'}}>
                            <img src="/assets/no-data.svg" alt="no-data"/>
                          </div>
                          <p className="no-payment-text">
                            There is no Data!
                          </p>
                        </div>
                      </div>
                  : null
              }

            </div>
          </div>
          {
            currentTab === 5 ? (
              <div className="tools__reportsContent">
                <div>
                  <span className="tools__tableTitle">Calibration</span>
                  <div className="tools__table">
                    <div className={`tools__table-head reports`}>
                      <div className="tools__table-th">ID</div>
                      <div className="tools__table-th">Tool Description</div>
                      <div className="tools__table-th">Date of Calibration</div>
                      <div className="tools__table-th">Calibrated by</div>
                      <div className="tools__table-th">Calibration Due Date</div>
                    </div>
                    <div className="tools__table-body reports">
                      {
                        reportsData?.calibration?.map((item, index) => (
                          <div key={index} className="tools__table-row reports " onClick={() => openReportModal('Calibration', item)}>
                          {/*<div key={index} className="tools__table-row reports ">*/}
                            <div className="tools__table-bodyTh longText">
                              <p className="tools__table-subtitle">{item?.title}</p>
                            </div>
                            <div className="tools__table-bodyTh">
                            <p className="tools__table-subtitle">{item?.description}</p>
                          </div>
                          <div className="tools__table-bodyTh">
                          <p className="tools__table-subtitle">{item?.calibration_date}</p>
                          </div>
                          <div className="tools__table-bodyTh">
                          <p className="tools__table-subtitle">{item?.calibrated_by}</p>
                          </div>
                          <div className="tools__table-bodyTh">
                          <p className="tools__table-subtitle">{item?.calibration_due_date}</p>
                          </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div>
                  <span className="tools__tableTitle" >Verification</span>
                  <div className="tools__table">
                    <div className={`tools__table-head reports`}>
                      <div className="tools__table-th">ID</div>
                      <div className="tools__table-th">Unit Description</div>
                      <div className="tools__table-th">Date of Verification</div>
                      <div className="tools__table-th">Verified by</div>
                      <div className="tools__table-th">Verification Due Date</div>
                    </div>
                    <div className="tools__table-body">
                      {
                        reportsData?.verification?.map((item, index) => (
                          <div key={index} className="tools__table-row reports" onClick={() => openReportModal('Verification', item)}>
                          {/*<div key={index} className="tools__table-row reports">*/}
                            <div className="tools__table-bodyTh longText">
                              <p className="tools__table-subtitle">{item?.title}</p>
                            </div>
                            <div className="tools__table-bodyTh">
                              <p className="tools__table-subtitle">{item?.description}</p>
                            </div>
                            <div className="tools__table-bodyTh">
                              <p className="tools__table-subtitle">{item?.calibration_date}</p>
                            </div>
                            <div className="tools__table-bodyTh">
                              <p className="tools__table-subtitle">{item?.calibrated_by}</p>
                            </div>
                            <div className="tools__table-bodyTh">
                              <p className="tools__table-subtitle">{item?.calibration_due_date}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

              </div>
            ) : null
          }

          {
            currentTab === 1 ?
              <CustomPaginate
                pageCount={toolsData?.pages}
                handlePageClick={handlePageClick}
              /> :
              currentTab === 2 || currentTab === 3 ?
                <CustomPaginate
                  pageCount={calibrationsData?.pages}
                  handlePageClick={handlePageClick}
                />
                : null
          }

        </div>
      </div>

    </Wrapper>
  );
};

export default ToolsManagement;
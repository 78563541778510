import React, {useEffect, useState} from 'react';
import Input from "../Input";
import CustomSelect from "../CustomSelect";
import {useChangeCorrectiveActionMutation} from "../../store/builder/inspections.api";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setFields, setLoader} from "../../store/builder/reducer";
import { useErrorHandler } from '../../global/ErrorsHandler';
import {useNavigate} from "react-router-dom";
import FIllField from "../FIllField";
import log from "../../SVG/Log";
import moment from "moment";
import ViewImageModal from "./ViewImageModal";

export default function CorrectiveActionModal ({exit, action, currentTab}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fields = useSelector((state) => state?.rootReducer?.reducer?.fields);
  const loader = useSelector((state) => state?.rootReducer?.reducer?.loader);
  const [galleryStatus, setGalleryStatus] = useState(false)
  const [isHovering, setIsHovering] = useState(false);
  const [image, setImage] = useState(null)
  const [activeImage, setActiveImage] = useState(null)
  const [images, setImages] = useState([])
  const [viewModalStatus, setViewModalStatus] = useState(false);

  const [changeData, setChangeData] = useState({
    id: action.id,
    action: "",
    discrepancy: "",
    repair_time: ""
  })
  const [changeAction, {
    data: changeActionData,
    isError: changeActionIsError,
    isLoading: changeActionIsLoading,
    isSuccess: changeActionIsSuccess,
    error: changeActionError
  }] = useChangeCorrectiveActionMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([changeActionError])

  useEffect(() => {
    if (changeActionIsLoading) {
      dispatch(setLoader(true))
    }
    if (changeActionIsSuccess) {
      dispatch(setLoader(false))
      toast.success(changeActionData.message)
      exit()
    }
  },[changeActionIsSuccess, changeActionIsLoading])
  useEffect(() => {
    dispatch(setFields(action?.old_result?.field))
  }, [loader])
  useEffect(() => {
    if (action?.discrepancy) {
      setChangeData(old => ({...old, discrepancy: action?.discrepancy}))
    }
  }, [action])
  const handleChange = (e) => {
    setChangeData(old => ({...old, [e.target.name]: e.target.value}))
  }
  const closeAction = () => {
    if (changeData.action.length > 1 && changeData.discrepancy.length > 1) {
      let form = new FormData();
      form.append('id', changeData.id)
      form.append('discrepancy', changeData.discrepancy)
      form.append('action', changeData.action)
      form.append('repair_time', changeData.repair_time)
      if (action?.millipore_required) {
        if (!image?.data) {
          toast.error('Please upload millipore test file!')
        } else {
          if (Object?.values(image?.data)?.length > 0) {
            for (let i = 0; i < Object?.values(image?.data)?.length; i++) {
              form.append(`images[${i}]`, Object?.values(image?.data)[i])
            }
          }
          changeAction(form)
        }
      } else {
        if (image?.data) {
          if (Object?.values(image?.data)?.length > 0) {
            for (let i = 0; i < Object?.values(image?.data)?.length; i++) {
              form.append(`images[${i}]`, Object?.values(image?.data)[i])
            }
          }
        }
        changeAction(form)
      }

    } else {
      toast.error('Fill all the fields!')
    }
  }

  const handleImage = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array?.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setImage({ preview: imagesArray, data: e.target.files });
  };

  const clickImage = (v) => {
    setActiveImage(v);
    setViewModalStatus(true);
    setImages(() => image?.preview)
  }

  return (
    <div className="modal">
      {viewModalStatus && (
        <ViewImageModal
          exit={() => setViewModalStatus(false)}
          activeImage={activeImage}
          images={images}
          setActiveImage={setActiveImage}
        />
      )}
      <div className="modal__content">
        <div className="modal__content-head">
          <p>Change Corrective Action</p>
          <img className="modal__content-exit" src="/assets/icons/close.svg" alt="close" onClick={exit}/>
        </div>
        <div className="modal__content-body corrective">
          {/*<FIllField readOnly={true} item={action?.old_result?.field} inspection_id={action?.inspection_id} />*/}
          {
            action?.old_result?.field?.type === 'radio'?
            <div className="corrective__fields">
              <p>{action?.old_result?.field?.label}</p>
              <p className="corrective__fields-label">{action?.old_result?.field?.options?.filter(el => el.value === action?.old_result?.field?.answers[0])[0]?.label}</p>
              {
                action?.old_result?.field?.comment_text &&
                <>
                  <p>Comment</p>
                  <p className="corrective__fields-label">{action?.old_result?.field?.comment_text}</p>
                </>
              }
              {
                action?.old_result?.field?.comment_files?.length > 0 &&
                <>
                  <p>File</p>
                  <p className="corrective__fields-label">
                    <span>{action?.old_result?.field?.comment_files[0]?.filename}</span>
                    <img
                      onClick={() => {
                        setViewModalStatus(true)
                        let files = action?.old_result?.field?.comment_files?.map(item => item?.url)
                        setImages(() => files)
                        setActiveImage(files[0]);

                      }}
                      src={action?.old_result?.field?.comment_files[0]?.url}
                      alt="icon"/>
                  </p>
                </>
              }
            </div>
               : action?.old_result?.field?.type === 'sump_sample' ?
                <div className="corrective__fields">
                  <p>{action?.old_result?.field?.label}</p>
                  <p className="corrective__fields-label">{action?.old_result?.field?.answers[0]}</p>
                  {
                    action?.old_result?.field?.comment_text &&
                    <>
                      <p>Comment</p>
                      <p className="corrective__fields-label">{action?.old_result?.field?.comment_text}</p>
                    </>
                  }
                  {
                    action?.old_result?.field?.comment_files?.length > 0 &&
                    <>
                      <p>File</p>
                      <p className="corrective__fields-label">
                        <span>{action?.old_result?.field?.comment_files[0]?.filename}</span>
                        <img src={action?.old_result?.field?.comment_files[0]?.url} alt="icon"/>
                      </p>
                    </>
                  }
                </div>
                : action?.old_result?.field?.type === 'select' ?
                  <div className="corrective__fields">
                    <p>{action?.old_result?.field?.label}</p>
                    <p className="corrective__fields-label">{action?.old_result?.field?.options?.filter(el => el.value === action?.old_result?.field?.answers[0])[0]?.label}</p>
                    {
                      action?.old_result?.field?.comment_text &&
                      <>
                        <p>Comment</p>
                        <p className="corrective__fields-label">{action?.old_result?.field?.comment_text}</p>
                      </>
                    }
                    {
                      action?.old_result?.field?.comment_files?.length > 0 &&
                      <>
                        <p>File</p>
                        <p className="corrective__fields-label">
                          <span>{action?.old_result?.field?.comment_files[0]?.filename}</span>
                          <img src={action?.old_result?.field?.comment_files[0]?.url} alt="icon"/>
                        </p>
                      </>
                    }
                  </div>

                  : action?.old_result?.field?.type === 'text' ?
                    <div className="corrective__fields">
                      <p>{action?.old_result?.field?.label}</p>
                      <p className="corrective__fields-label">{action?.old_result?.field?.value}</p>
                    </div>

                    : action?.old_result?.field?.type === 'textarea' ?
                      <div className="corrective__fields">
                        <p>{action?.old_result?.field?.label}</p>
                        <p className="corrective__fields-label">{action?.old_result?.field?.value}</p>
                      </div>
              : null
          }
          {
           ( currentTab === 0 || currentTab === 1) &&
            <div className="corrective__fields">
              <p>Created at</p>
              <p className="corrective__fields-label">
                {moment.parseZone(action?.created_at).format("MM/DD/YY HH:mm")}
              </p>
              { currentTab === 1 && (
                <>
                  <p>Closed at</p>
                  <p className="corrective__fields-label">
                    {moment.parseZone(action?.closed_time).format("MM/DD/YY HH:mm")}
                  </p>
                </>
              )
              }
              <p>Inspection Date</p>
              <p className="corrective__fields-label">{action?.date ? action?.date : "---"}</p>
              {
                action?.inspection_title !== 'Fuel Facility Checks' && (
                  <>
                    <p>Equipment Type</p>
                    <p className="corrective__fields-label">{action?.equipment_type_title ? action?.equipment_type_title : "---"}</p>
                    <p>Equipment ID</p>
                    <p className="corrective__fields-label">{action?.equipment_title ? action?.equipment_title : "---"}</p>
                  </>
                )
              }
              <p>Facility</p>
              <p className="corrective__fields-label">{action?.facility_title ? action?.facility_title : "---"}</p>
              { currentTab === 1 && (
                <>
                  <p>Closed by</p>
                  <p className="corrective__fields-label">{action?.closed_by_name ? action?.closed_by_name : "---"}</p>
                </>
              )
              }
            </div>
          }
          <Input
            id="discrepancy"
            name="discrepancy"
            placeholder="Discrepancy"
            label={'Discrepancy'}
            type={'text'}
            readOnly={true}
            onChange={handleChange} defaultValue={action?.discrepancy} />
          <Input
            id="action"
            name="action"
            placeholder="Corrective Action"
            label={'Corrective Action'}
            type={'text'}
            readOnly={currentTab === 1}
            onChange={handleChange} defaultValue={currentTab === 1 ? action?.action : ""} />
          <Input
            id="repair_time"
            name="repair_time"
            placeholder="Repair time"
            label={'Repair time'}
            type={'text'}
            readOnly={currentTab === 1}
            onChange={handleChange} defaultValue={currentTab === 1 ? action?.repair_time : ""} />


          {
            currentTab === 0 ?
              <>
                <span className="customInput">{action?.millipore_required ? 'Upload Millipore Test' : 'Upload file'}</span>
                <div className="fillInspection__field-commentFile">
                  <div style={{display: "flex", alignItems: 'center',gap: "5px", overflow: 'auto'}}>
                    {
                      image?.preview ? image?.preview?.map((item, index) => (
                          <img onClick={() => clickImage(item)} className="fillInspection__field-galleryPhoto" src={item} key={index} alt="img"/>
                        ))
                        : <span>None</span>

                    }
                  </div>
                  <label htmlFor="images">
                <span>
                  <img src="/assets/icons/camera.svg" alt="camera" />
                </span>
                    <p>Choose file</p>
                  </label>
                  <input
                    className="inputFile"
                    id="images"
                    name="images"
                    onChange={handleImage}
                    multiple
                    type="file"
                    accept="image/*"
                  />
                </div>
              </>
               :
              action?.images?.length > 0 ?
                <>
                  <span className="customInput">{action?.millipore_required ? 'Upload Millipore Test' : 'Upload file'}</span>
                  <div style={{display: "flex", alignItems: 'center',gap: "5px"}}>
                    {
                      action?.images?.map((item, index) => (
                          <img className="fillInspection__field-galleryPhoto" src={item} key={index} alt="img"/>
                        ))
                    }
                  </div>
                </>
                : <>
                  <span className="customInput">{action?.millipore_required ? 'Upload Millipore Test' : 'Upload file'}</span>
                  <div style={{display: "flex", alignItems: 'center',gap: "5px"}}>
                    <span>None</span>
                  </div>
                </>
          }
        </div>
        {
          currentTab === 0 &&
          <div className="modal__content-bottom">
            <button className="close" onClick={exit}>Close</button>
            <button className="save" onClick={closeAction}>Save</button>
          </div>
        }

      </div>
      <div className="modal__bg">
      </div>
    </div>
  );
};

